import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';

export type TestState = 'running' | 'stopped' | 'scheduled';

export interface StateParameters {
  state: TestState;
  runtimeVus: number;
}
export interface Test {
  id: string;
  metadata: TestMetadata;
  scheduled_for: Date;
  test_runs: TestRun[];
  created_at: Date;
  updated_at: Date;
}

export interface TestMetadata {
  name: string;
  description: string;
  references: string[];
}

export interface TestRun {
  id: string;
  script_slug: string;
  status_endpoint: string;
  run_options: RunOptions;
  test_id?: string;
  created_at: Date;
  updated_at: Date;
  state_parameters?: StateParameters;
  ramps?: Ramp[];
}

export interface RunOptions {
  parallelism: number;
  state: TestState;
  max_vus: number;
  vus: number;
  env?: { [key: string]: string };
}

export interface Ramp {
  id: string;
  testrun_id: string;
  target_vus: number;
  step_vus: number;
  interval_seconds: number;
  active: boolean;
}
export class HttpError extends Error {
  public response?: {
    status?: number;
    statusText?: string;
    data?: string;
  };
  public details?: string;

  constructor(
    message: string,
    response?: { status?: number; statusText?: string; data?: string },
    details?: string,
  ) {
    super(message);
    this.response = response;
    this.details = details;
  }
}

export interface LoadTestingApi {
  getTests(): Promise<{ data: Test[] }>;
  getTest(testId: string): Promise<{ data: Test[] }>;
  updateTestRunStateHandler(testRunId: string, state: TestState): Promise<void>;
  updateTestRunVirtualUsersHandler(
    testRunId: string,
    virtualUsers: number,
  ): Promise<void>;
  createRamp(
    testRunId: string,
    targetVus: number,
    stepVus: number,
    intervalSeconds: number,
  ): Promise<{ rampId: string }>;
  stopRamp(rampId: string): Promise<{ message: string }>;
}

export const loadTestingApiRef = createApiRef<LoadTestingApi>({
  id: 'plugin.load-testing',
});

function isParsable(res: Response): boolean {
  return !!res.headers.get('Content-Type')?.includes('json');
}

async function parse<T>(res: Response): Promise<any> {
  if (res.ok) {
    return isParsable(res) ? ((await res.json()) as Promise<T>) : undefined;
  }
  let message = `${res.statusText} (${res.status})`;
  const body = await res.text();
  message += body && body !== res.statusText ? `: ${body}` : '';
  throw new Error(message);
}

export class LoadTestingApiClient implements LoadTestingApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async getTests(): Promise<{ data: Test[] }> {
    try {
      const token = await this.oauth2Api.getAccessToken();
      const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
      const apiUrl = `${proxyUrl}/load-test-orchestrator-api/tests`;

      const response = await fetch(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        const errorText = await response.text();
        const errorResponse = {
          status: response.status,
          statusText: response.statusText,
          data: errorText,
        };

        if (response.status === 401) {
          throw new HttpError(
            'Access denied. Please reach out to the CW User Journey Load Testing #UJLT chat room if you need to be onboarded and get access.',
            errorResponse,
            `<a href="https://mail.google.com/mail/u/0/#chat/space/AAAACWysNlg" target="_blank" rel="noopener noreferrer">CW User Journey Load Testing #UJLT chat</a>`,
          );
        } else {
          throw new HttpError(
            `Request failed with status ${response.status}: ${response.statusText}`,
            errorResponse,
          );
        }
      }

      return parse<{ data: Test[] }>(response);
    } catch (error) {
      if (error instanceof HttpError) {
        throw error;
      } else {
        throw new HttpError('An unexpected error occurred', {
          status: 500,
          statusText: 'Internal Server Error',
          data: (error as Error).message,
        });
      }
    }
  }

  async getTest(testId: string): Promise<{ data: Test[] }> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/load-test-orchestrator-api/tests/${testId}`;
    const res = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return parse<{ data: Test[] }>(res);
  }

  async updateTestRunStateHandler(
    testRunId: string,
    state: string,
  ): Promise<void> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/load-test-orchestrator-api/testruns/${testRunId}/state/${state}`;
    const res = await fetch(apiUrl, {
      method: 'PATCH',
      headers: { Authorization: `Bearer ${token}` },
    });
    await parse<void>(res);
  }

  async updateTestRunVirtualUsersHandler(
    testRunId: string,
    virtualUsers: number,
  ): Promise<void> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/load-test-orchestrator-api/testruns/${testRunId}/state/running/virtual-users/${virtualUsers}`;
    const res = await fetch(apiUrl, {
      method: 'PATCH',
      headers: { Authorization: `Bearer ${token}` },
    });
    await parse<void>(res);
  }

  async createRamp(
    testrun_id: string,
    target_vus: number,
    step_vus: number,
    interval_seconds: number,
  ): Promise<{ rampId: string }> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/load-test-orchestrator-api/testrun/gradual-ramp`;
    const res = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        testrun_id,
        target_vus,
        step_vus,
        interval_seconds,
      }),
    });
    return parse<{ rampId: string }>(res);
  }

  async stopRamp(rampId: string): Promise<{ message: string }> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/load-test-orchestrator-api/testrun/gradual-ramp/${rampId}/stop`;
    const res = await fetch(apiUrl, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({ active: false }),
    });
    return parse<{ message: string }>(res);
  }
}
