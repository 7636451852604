import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Typography, Link } from '@material-ui/core';
import { RadioSelect } from '../common';
import { SectionCheckbox } from '../common/SectionCheckbox';

export function NamingAndInclusiveLanguage() {
  return (
    <section>
      <Typography>
        Please answer the following questions to assess how far the{' '}
        <Link
          target="_blank"
          href="/docs/default/Documentation/engineering/guidelines/naming-applications/"
        >
          Guidelines for Application ID and Naming <OpenInNewIcon />
        </Link>
        &nbsp;and&nbsp;
        <Link
          target="_blank"
          href="https://engineering.docs.zalando.net/guidelines/inclusive-language/"
        >
          Guidelines for Inclusive Language <OpenInNewIcon />
        </Link>
        &nbsp;are followed.
      </Typography>
      <Typography variant="h3">Application ID and Naming</Typography>
      <RadioSelect name="name_uses_english" />
      <RadioSelect name="name_contains_offensive_terms" />
      <RadioSelect name="name_contains_organization_identifiers" />
      <RadioSelect name="name_describes_purpose_or_functionality" />
      <RadioSelect name="name_uses_acronyms" />
      <RadioSelect name="name_meaningful_outside_department" />
      <RadioSelect name="name_is_local" />
      <RadioSelect name="name_includes_joke_or_culture" />
      <RadioSelect name="name_refers_temporary_concept_or_colloquialism" />
      <RadioSelect name="name_uses_functional_naming_registry" />
      <RadioSelect name="name_includes_brand" />
      <Typography variant="h3">Inclusive Language</Typography>
      <RadioSelect name="follows_inclusive_language_guidelines" />

      <SectionCheckbox name="naming_ok" />
    </section>
  );
}
