import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { Autocomplete } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { MLPipelineRunMetricsParams } from '../../../api/definitions';
import { getOptions } from './utils';
import {
  AWS_CONSOLE_URLS,
  DEFAULT_SELECT_OPTION,
} from '../../../constants/common';
import HelpIcon from '@material-ui/icons/Help';
import compact from 'lodash/compact';

export const getModelMetricsColumns = (
  metricNames: MLPipelineRunMetricsParams[] | undefined,
  selectedFilter:
    | {
        selectedMetricKey: string | undefined;
        selectedTrainingJob: string | undefined;
      }
    | undefined,
  setSelectedFilter: React.Dispatch<{
    selectedMetricKey: string | undefined;
    selectedTrainingJob: string | undefined;
  }>,
  classes: any,
): TableColumn[] => {
  const metricNameOptions = getOptions(metricNames, 'key');
  const metricTypeOptions = getOptions(metricNames, 'type');
  const allMetricNameOptions = compact(
    Array.from(new Set(metricNameOptions.concat(metricTypeOptions))),
  );
  const trainingJobNameOptions = compact(
    getOptions(allMetricNameOptions, 'training_job_id'),
  );

  return [
    {
      title: 'Name',
      field: 'key',
      render(row: any): React.ReactNode {
        return (
          <>
            {row.key ? (
              <Typography variant="subtitle2">{row.key}</Typography>
            ) : (
              <Typography variant="subtitle2">{row.type}</Typography>
            )}
          </>
        );
      },
      filtering: true,
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            style={{ maxWidth: '300px' }}
            onChange={(__, selected: any) =>
              setSelectedFilter({
                selectedMetricKey: selected,
                selectedTrainingJob: selectedFilter?.selectedTrainingJob,
              })
            }
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  selectedFilter?.selectedMetricKey || DEFAULT_SELECT_OPTION
                }
                variant="outlined"
                margin="dense"
                data-testid="metric-autocomplete-input"
              />
            )}
            data-testid="metric-autocomplete-select"
            options={allMetricNameOptions}
          />
        );
      },
    },
    {
      title: 'Value',
      field: 'value',
      filtering: false,
      render(row: any): React.ReactNode {
        return row?.value || '-';
      },
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Threshold
            <Tooltip title="The decision threshold the metric was computed on.">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'threshold',
      filtering: false,
      render(row: any): React.ReactNode {
        return row?.threshold || '-';
      },
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Slice
            <Tooltip title="The name of the slice this metric was computed on. By default, assume this metric is not sliced.">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'slice',
      filtering: false,
      render(row: any): React.ReactNode {
        return row?.slice || '-';
      },
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Confidence Interval
            <Tooltip title="The confidence interval of the metric.">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'confidence_interval',
      filtering: false,
      render(row: any): React.ReactNode {
        return row?.confidence_interval ? (
          <TableContainer component={Paper}>
            <Table aria-label="confidence interval table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Upper Bound</TableCell>
                  <TableCell>Lower Bound</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {row?.confidence_interval?.upper_bound || '-'}
                  </TableCell>
                  <TableCell align="center">
                    {row?.confidence_interval?.lower_bound || '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">-</Typography>
        );
      },
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Training Job
            <Tooltip title="The training job the metric was extracted from">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'training_job_id',
      filtering: true,
      render(row: any): React.ReactNode {
        return (
          <>
            {row.training_job_id ? (
              <Link
                to={`${AWS_CONSOLE_URLS.SAGEMAKER_TRAINING_JOB_URL}/${row.training_job_id}`}
              >
                {row.training_job_id}
              </Link>
            ) : (
              '-'
            )}
          </>
        );
      },
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            style={{ maxWidth: '300px' }}
            onChange={(__, selected: any) =>
              setSelectedFilter({
                selectedMetricKey: selectedFilter?.selectedMetricKey,
                selectedTrainingJob: selected,
              })
            }
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  selectedFilter?.selectedTrainingJob || DEFAULT_SELECT_OPTION
                }
                variant="outlined"
                margin="dense"
                data-testid="training-job-autocomplete-input"
              />
            )}
            data-testid="training-job-autocomplete-select"
            options={trainingJobNameOptions}
          />
        );
      },
    },
  ];
};
