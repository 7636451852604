import React, { ReactNode } from 'react';
import { ChangedLine } from './ChangedLine';

export enum ChangeTypes {
  added = 'added',
  deleted = 'deleted',
  changed = 'changed',
}

export type ChangedLineProps = {
  label: ReactNode;
  oldValue?: any;
  newValue?: any;
  changeType: ChangeTypes;
};

export function ChangeTracker(props: { changes: Array<ChangedLineProps> }) {
  return (
    <div>
      {props.changes.map((change, i) => (
        <ChangedLine key={i} {...change} />
      ))}
    </div>
  );
}
