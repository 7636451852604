import { action, makeObservable, observable } from 'mobx';
import { ZesClient } from '../api';

export interface ZesError {
  logs: string[];
  message: string;
  title: string;
  status?: number;
}

export class ZesService {
  private zesClient: ZesClient;
  details: ZesError = { logs: [], message: '', title: '' };

  constructor(zesClient: ZesClient) {
    this.zesClient = zesClient;
    makeObservable(this, {
      details: observable,
      getTaskDetails: action,
    });
  }

  getTaskDetails(cdp_deployment_id: string) {
    this.zesClient.getTaskDetails(cdp_deployment_id).then(res => {
      if (res.length > 0 && res[0].task_logs) {
        const task_logs: {
          message: string;
          title: string;
          details: any;
          status: number;
        } = res[0].task_logs;
        const details =
          task_logs.details && typeof task_logs.details === 'object'
            ? task_logs.details.details
            : task_logs.details;
        this.details = {
          message: task_logs.message,
          title: task_logs.title,
          logs: details ? details.split('\n') : [],
          status: task_logs.status,
        };
      }
    });
  }
}
