import React from 'react';
import { FormHelperText, Grid, TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  UseAutocompleteProps,
} from '@material-ui/lab';
import { config, FormFields } from './config';
import { ApplicationFormContext } from './context';

type Props<T> = Omit<
  AutocompleteProps<T, any, any, any> & UseAutocompleteProps<T, any, any, any>,
  'renderInput'
> & {
  name: FormFields;
  getValueOnChange?: (value: T) => string | number;
};

export function Autocomplete<T>({
  name,
  getValueOnChange,
  ...props
}: Props<T>) {
  const { helperText, ...inputConfig } = config[name] || {};
  const { appForm, setForm, disableForm } = React.useContext(
    ApplicationFormContext,
  );

  function handleChange(_ev: any, val: T) {
    const primitiveValue =
      (val && typeof val === 'object' ? String(val) : val) || '';
    setForm({
      ...appForm,
      [name]: getValueOnChange ? getValueOnChange(val) : primitiveValue,
    });
  }

  return (
    <Grid item xs={12} md={6}>
      <MuiAutocomplete
        onChange={handleChange}
        disabled={disableForm}
        {...(props as any)}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            margin="dense"
            autoComplete="off"
            autoCorrect="off"
            {...inputConfig}
          />
        )}
      />
      {helperText && (
        <FormHelperText component="div" variant="filled">
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
}
