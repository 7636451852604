import React, { useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { Content, Link, Table } from '@backstage/core-components';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { tableHeaderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

const buildReferenceName = (reference: string) => {
  const splitReference: string = reference.split('/').at(2) as string;
  return splitReference.replace(/\./g, ' ').toUpperCase();
};

export const ModelReferences = ({
  references,
}: {
  references: string[] | null;
}) => {
  let formattedReferences: any;

  const theme = useTheme();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  if (references) {
    formattedReferences = references.map(reference => {
      return {
        name: reference,
        reference: reference,
      };
    });
  } else {
    formattedReferences = null;
  }

  const columns = [
    {
      title: 'Name',
      field: 'key',
      filtering: false,
      render(row: any): React.ReactNode {
        return buildReferenceName(row.name);
      },
    },
    {
      title: 'Link',
      field: 'name',
      filtering: false,
      render(row: any): React.ReactNode {
        return (
          <>
            <Link to={row.reference}>{row.reference}</Link>
          </>
        );
      },
    },
  ];

  const tableData = {
    meta: {
      total: formattedReferences?.length,
    },
    references: formattedReferences,
  };

  return (
    <Content>
      <Grid>
        {references ? (
          <Table
            data={formattedReferences}
            columns={columns}
            options={{
              pageSize: PAGE_SIZE,
              search: true,
              filtering: false,
              padding: 'dense',
              showFirstLastPageButtons: true,
              emptyRowsWhenPaging: false,
              headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
            }}
            components={{
              Pagination: props => (
                <MLTablePagination
                  props={props}
                  page={page}
                  resource={tableData}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
                />
              ),
              Toolbar: props => <MLTableToolbar props={props} />,
            }}
          />
        ) : (
          <MissingFieldPlaceholder field="reference" type="model-card" />
        )}
      </Grid>
    </Content>
  );
};
