import React from 'react';
import { TabbedLayout } from '@backstage/core-components';
import { ProjectList } from '../components/Projects/ProjectList';
import { Layout } from '../components/common/Layout';
import { MLPlatformSearchPage } from './MLPlatformSearchPage';

export const MLPlatformHomePage = () => {
  return (
    <Layout>
      <TabbedLayout>
        <TabbedLayout.Route path="/projects" title="Projects">
          <ProjectList />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/search" title="Search">
          <MLPlatformSearchPage />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Layout>
  );
};
