import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import {
  RadarQuadrant,
  RadarRing,
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';

const rings: Array<RadarRing> = require('./data/rings.json');
const quadrants: Array<RadarQuadrant> = require('./data/quadrants.json');

export class TechRadarClientApi implements TechRadarApi {
  authApi: OAuthApi;
  discoveryApi: DiscoveryApi;

  constructor(oauth2Api: OAuthApi, discoveryApi: DiscoveryApi) {
    this.authApi = oauth2Api;
    this.discoveryApi = discoveryApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const token = await this.authApi.getAccessToken();
    const Authorization = `Bearer ${token}`;
    const backendPluginUrl = await this.discoveryApi.getBaseUrl('tech-radar');
    const response = await fetch(`${backendPluginUrl}/entries`, {
      headers: { Authorization },
    });

    if (response.ok && response.headers.get('Content-Type')?.includes('json')) {
      return {
        rings,
        quadrants,
        entries: await response.json(),
      };
    }

    throw new Error(response.statusText);
  }
}
