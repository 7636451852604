import { EventTrackerProps } from 'plugin-ui-components';

export const common: Omit<EventTrackerProps, 'eventLabel'> = {
  plugin: 'tech-insights',
  eventCategory: 'Scorecards Plugin',
  eventAction: 'click',
  interaction: 'onClick',
};

export const viewAllScorecards = (
  applicationName: string,
): EventTrackerProps => ({
  ...common,
  eventLabel: `View all scorecards for "${applicationName}"`,
});

export const expandCheck = (
  check: string,
  scorecard?: string,
  applicationName?: string,
): EventTrackerProps => ({
  ...common,
  eventLabel: `Expanding check "${check}" ${
    scorecard && `in scorecard "${scorecard}"`
  } ${applicationName && `for application "${applicationName}"`}`,
});

export const viewScorecardDetails = (
  applicationName: string,
): EventTrackerProps => {
  return {
    ...common,
    eventLabel: `Viewing scorecard overview for application "${applicationName}" in application catalog`,
  };
};

export const techInsightCardLinkClicked: EventTrackerProps = {
  ...common,
  eventLabel: 'Tech Insights card link clicked',
};

export const registerScorecard: EventTrackerProps = {
  ...common,
  eventLabel: 'Register scorecard button clicked',
};
