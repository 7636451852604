import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { DatabasesApi, databasesApiRef } from './api/DatabasesApi';

export const databasesPlugin = createPlugin({
  id: 'databases',
  apis: [
    createApiFactory({
      api: databasesApiRef,
      deps: {
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2api, discoveryApi }) =>
        new DatabasesApi({
          oauth2Api: oauth2api,
          discoveryApi: discoveryApi,
        }),
    }),
  ],
});
