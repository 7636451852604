import { EventTrackerProps } from 'plugin-ui-components';

export class Tracking {
  private static common: Omit<EventTrackerProps, 'eventLabel'> = {
    plugin: 'tooling-catalog',
    eventCategory: 'Tooling Catalog',
    interaction: 'onClick',
    eventAction: 'click',
  };

  static viewLink = (eventLabel: string): EventTrackerProps => ({
    ...this.common,
    eventLabel: eventLabel,
  });

  static hoverStatus = (status: string): EventTrackerProps => ({
    ...this.common,
    interaction: 'onMouseEnter',
    eventAction: 'hover',
    eventLabel: `User hovered on '${status}' status in Tooling Catalog`,
  });

  static createTooling = (): EventTrackerProps => ({
    ...this.common,
    eventLabel: `Create new tooling`,
  });

  static editTooling = (): EventTrackerProps => ({
    ...this.common,
    eventLabel: `Edit tooling`,
  });
}
