class BaseException implements Error {
  public name = 'BaseException';
  constructor(public message: string) {
    this.message = message;
  }
  toString() {
    return `${this.name}: ${this.message}`;
  }
}

export class BadRequestException extends BaseException {
  public name = 'BadRequestException';
}

export class ConflictException extends BaseException {
  public name = 'ConflictException';
}

export class NotFoundException extends BaseException {
  public name = 'NotFoundException';
}

export class NotOkException extends BaseException {
  public name = 'NotOkException';
}

export class ResourceAccessForbiddenException extends Error {
  public name = 'ResourceAccessForbiddenException';
}
