import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import axios from 'axios';

type KioApiType = {
  fetchKioApp(name: string): Promise<IKioAppApiResponse>;
};

export const kioApiRef = createApiRef<KioApiType>({
  id: 'plugin.catalog.kio-api',
});

interface Apis {
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;
}

export class KioApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(apis: Apis) {
    this.discoveryApi = apis.discoveryApi;
    this.oauth2Api = apis.oauth2Api;
  }

  async fetchKioApp(name: string): Promise<IKioAppApiResponse> {
    const proxy = await this.discoveryApi.getBaseUrl('proxy');
    const token = await this.oauth2Api.getAccessToken();
    return axios
      .get(`${proxy}/kio/apps/${name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.data);
  }
}
