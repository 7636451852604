import React from 'react';
import FormControl from '@mui/material/FormControl';
import Input, { InputProps as MuiInputProps } from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListIcon from '@mui/icons-material/FilterList';

type FilterInputProps = {
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  InputProps?: MuiInputProps;
};

export function FilterInput({ onKeyUp, InputProps = {} }: FilterInputProps) {
  return (
    <FormControl>
      <Input
        id="input-with-icon-adornment"
        placeholder="Filter"
        startAdornment={
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        }
        onKeyUp={onKeyUp}
        {...InputProps}
      />
    </FormControl>
  );
}
