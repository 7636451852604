import { RunStatus } from '../api/definitions';

const AWS_CONSOLE_BASE_URL = 'https://eu-central-1.console.aws.amazon.com';
const REGION = 'home?region=eu-central-1#';

export const AWS_CONSOLE_URLS = {
  PIPELINE_VIEW_DETAILS: `${AWS_CONSOLE_BASE_URL}/states/${REGION}/statemachines/view`,
  STEP_FUNCTIONS_EXECUTION_DETAILS: `${AWS_CONSOLE_BASE_URL}/states/${REGION}/executions/details`,
  SAGEMAKER_TRAINING_JOB_URL: `${AWS_CONSOLE_BASE_URL}/sagemaker/${REGION}/jobs`,
  SAGEMAKER_BATCH_TRANSFORM_JOB_URL: `${AWS_CONSOLE_BASE_URL}/sagemaker/${REGION}/transform-jobs`,
  SAGEMAKER_PROCESSING_JOB_URL: `${AWS_CONSOLE_BASE_URL}/sagemaker/${REGION}/processing-jobs`,
  SAGEMAKER_MODEL_URL: `${AWS_CONSOLE_BASE_URL}/sagemaker/${REGION}/models`,
  SAGEMAKER_TRAINING_JOB_LOGS_URL: `${AWS_CONSOLE_BASE_URL}/cloudwatch/${REGION}logStream:group=/aws/sagemaker/TrainingJobs;prefix=`,
  SAGEMAKER_BATCH_TRANSFORM_JOB_LOGS_URL: `${AWS_CONSOLE_BASE_URL}/cloudwatch/${REGION}logStream:group=/aws/sagemaker/TransformJobs;prefix=`,
  SAGEMAKER_PROCESSING_JOB_LOGS_URL: `${AWS_CONSOLE_BASE_URL}/cloudwatch/${REGION}logStream:group=/aws/sagemaker/ProcessingJobs;prefix=`,
};

export const DATA_REFRESH_FREQUENCY = 10000;

export const DEFAULT_SELECT_OPTION = 'All';

export const NOT_AVAILABLE = 'Not Available';

export const IN_PROGRESS_RUN_STATUSES = [
  RunStatus.Initialized,
  RunStatus.Running,
];
export const PAGE_REFRESH_WAIT_TIME = 1000;

export const SPECIAL_CHARACTERS = /[^a-zA-Z0-9\-\_]/;

export const AVERAGE_METRIC_TOOLIP =
  "This is an average of intermediate values and might be different from the final value. This run's training job output is missing the final metrics due to a Sagemaker issue. UPGRADE to zflow >= 1.2.1 for mitigation.";

export const STEP_NAME_PREFIX = 'Run ';

export const GOTO_LAMBDA_SUFFIXES = ['goto_lambda', 'goto_choice'];

export const PIPELINE_EXECUTION_ENABLED = false;
