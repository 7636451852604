import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, makeStyles } from '@material-ui/core';
import { ExternalTrackedButton } from 'plugin-ui-components';

import PetsIcon from '@material-ui/icons/Pets';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',

    '@media (max-width: 800px)': {
      padding: '20px',
    },

    '@media (min-width: 800px)': {
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginRight: '-8px',
    },
  },
  button: {
    marginTop: '8px',
    marginRight: '8px',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
});

const CDP_SUPPORT_URLS = [
  {
    name: 'Docs',
    link: 'https://cloud.docs.zalando.net/concepts/continuous/',
    icon: DescriptionIcon,
  },
  {
    name: 'Community support',
    link: 'https://mail.google.com/chat/u/0/#chat/space/AAAA_AUoRvk',
    icon: PetsIcon,
  },
];

export const DevConsoleLink = observer(() => {
  const styles = useStyles();

  return (
    <div className={styles.links}>
      {CDP_SUPPORT_URLS.map(supportUrl => {
        return (
          <Box
            display="grid"
            key={`${supportUrl.name}-support-url`}
            className={styles.button}
          >
            <ExternalTrackedButton
              plugin="cdp"
              size="small"
              target="_blank"
              href={supportUrl.link}
              variant="outlined"
              color="inherit"
              eventCategory={`Navigation to ${supportUrl.name}`}
              eventAction={`URL: ${supportUrl.link}`}
              eventLabel={`Location: ${supportUrl.name}`}
            >
              <span>{supportUrl.name}&nbsp;</span>
              <supportUrl.icon className={styles.icon} />
            </ExternalTrackedButton>
          </Box>
        );
      })}
    </div>
  );
});
