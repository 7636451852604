import { observable, action, computed, makeObservable } from 'mobx';
import { ErrorApi } from '@backstage/core-plugin-api';
import { CDPApi } from '../api';
import { RunModel, RunStepModel } from '../models';
import { State } from './helpers/state';
import { IPoll, PollingState } from './helpers/polling';
import { PIPELINE_PENDING_STATUSES } from '../constants';
import { NotFoundException, AbortException } from '../api/exceptions';
import { IRun } from '../api/types/payload';

export interface IRunService {
  actionState: State;
  runState: State;
  run: RunModel;
  stepState: State;
  step: RunStepModel;
  logFilename: string;
  pollingState: PollingState;
  stepOrdinal: number;
  setRun: (value: IRun) => void;
  setStep: (index: number) => void;
  getRun: (runId: string) => void;
  reload: () => void;
  retriggerRun: (
    useFreshDependencies: boolean,
    successCallback: () => void,
    parameters?: Array<{ name: string; value: string | boolean }>,
  ) => void;
  abortRun: () => void;
  abortStep: () => void;
  approveStep: () => void;
  rejectStep: () => void;
  skipStep: () => void;
  retriggerStep: () => void;
  retriggerStepAndUnblockSecurityError: () => void;
  pauseTraffic: () => void;
  resumeTraffic: (disableAutomatedRollback: boolean) => void;
  rollbackTraffic: () => void;
  cancelTraffic: () => void;
  promoteTraffic: () => void;
}

export class RunService implements IRunService, IPoll {
  // Observables
  runState: State;
  run: RunModel = new RunModel();
  stepState: State;
  actionState: State;
  pollingState: PollingState;
  stepOrdinal: number = -1;

  // Internal
  private runId: string = '';

  constructor(private cdpApi: CDPApi, private errorApi: ErrorApi) {
    // States
    this.runState = new State();
    this.actionState = new State();
    this.stepState = new State();
    this.pollingState = new PollingState();

    makeObservable<this, 'fetch'>(this, {
      run: observable,
      runState: observable,
      actionState: observable,
      pollingState: observable,
      stepOrdinal: observable,
      step: computed,
      logFilename: computed,
      getRun: action,
      reload: action,
      setRun: action,
      setStep: action,
      abortRun: action,
      abortStep: action,
      approveStep: action,
      rejectStep: action,
      skipStep: action,
      retriggerStep: action,
      retriggerStepAndUnblockSecurityError: action,
      fetch: action,
    });
  }

  // Actions
  getRun = (runId: string): void => {
    this.pollingState.hold();
    this.runId = runId;
    this.runState.setLoading();
    this.fetch();
  };

  reload = async (): Promise<void> => {
    this.pollingState.hold();
    this.runState.setReloading();
    await this.fetch();
  };

  retriggerRun = async (
    useFreshDependencies: boolean,
    successCallback: () => void,
    parameters?: Array<{ name: string; value: string | boolean }>,
  ): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.retriggerRun(this.runId, {
        use_fresh_dependencies: useFreshDependencies,
        parameters,
      });
    }, successCallback);

  abortRun = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.abortRun(this.runId);
    });

  abortStep = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.abortStep(this.runId, this.stepOrdinal);
    });

  approveStep = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.approveStep(this.runId, this.stepOrdinal);
    });

  rejectStep = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.rejectStep(this.runId, this.stepOrdinal);
    });

  skipStep = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.skipStep(this.runId, this.stepOrdinal);
    });

  retriggerStep = async (): Promise<void> =>
    this.execute(() => {
      return this.cdpApi.retriggerStep(this.runId, this.stepOrdinal);
    });

  retriggerStepAndUnblockSecurityError = async (): Promise<void> =>
    this.execute(() => {
      return this.cdpApi.retriggerStepAndUnblockSecurityError(
        this.runId,
        this.stepOrdinal,
      );
    });

  pauseTraffic = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.pauseTrafficSwitch(this.step.run.id);
    });

  resumeTraffic = async (
    disableAutomatedRollback: boolean = false,
  ): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.resumeTrafficSwitch(
        this.step.run.id,
        disableAutomatedRollback,
      );
    });

  rollbackTraffic = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.rollbackTrafficSwitch(this.step.run.id);
    });

  cancelTraffic = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.cancelTrafficSwitch(this.step.run.id);
    });

  promoteTraffic = async (): Promise<void> =>
    this.execute(async () => {
      return this.cdpApi.promoteTrafficSwitch(this.step.run.id);
    });

  setRun(value: IRun) {
    this.run = new RunModel(value);
  }

  setStep = (ordinal: number) => {
    if (this.run.steps.length > ordinal) {
      this.stepOrdinal = ordinal;
      this.stepState.setLoaded();
    } else {
      this.stepState.setNotFound();
    }
  };

  // Computed
  get step(): RunStepModel {
    return this.run.steps[this.stepOrdinal] ?? new RunStepModel();
  }

  get logFilename(): string {
    return `${this.run.deployment_unit}-${this.run.build_version}-${this.step.id}`;
  }

  // Internals

  private fetch = async (): Promise<void> => {
    try {
      const response = await this.cdpApi.getRun(
        this.runId,
        this.pollingState.abortController.signal,
      );

      this.setRun(response?.run ?? {});

      if (PIPELINE_PENDING_STATUSES.includes(this.run.status)) {
        this.pollingState.start();
      } else {
        this.pollingState.stop();
      }

      this.pollingState.resetConsecutiveFailedPolls();
      this.runState.setLoaded();
    } catch (error) {
      const e = error as Error;
      if (e instanceof AbortException) {
        return;
      }

      if (e instanceof NotFoundException) {
        this.runState.setNotFound();
        return;
      }

      this.pollingState.incrementConsecutiveFailedPolls();
      this.runState.setError();
    } finally {
      this.actionState.setLoaded();
      this.pollingState.resume();
    }
  };

  private execute = async (
    executeAction: () => Promise<Response>,
    callback?: () => void,
  ) => {
    try {
      this.pollingState.hold();
      this.actionState.setLoading();
      await executeAction();
      if (callback) callback();
    } catch (error) {
      const e = error as Error;
      this.errorApi.post(e);
    } finally {
      await this.fetch();
      this.actionState.setLoaded();
    }
  };
}
