import { Theme, styled } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const SELECT_SIZE: Record<string, string> = {
  small: '30px',
  medium: '36px',
};

export const Wrapper = styled('div')({
  cursor: 'text',
  display: 'inline-flex',
  position: 'relative',
  fontSize: '1rem',
  boxSizing: 'border-box',
  alignItems: 'center',
});

export const Select = styled('select')(
  (props: { theme: Theme; elementSize: 'medium' | 'small' }) => ({
    cursor: 'pointer',
    width: '100%',
    color: props.theme.palette.text.primary,
    minWidth: '16px',
    userSelect: 'none',
    border: `1px solid ${props.theme.palette.divider}`,
    height: SELECT_SIZE[props.elementSize],
    lineHeight: SELECT_SIZE[props.elementSize],
    padding: '0 30px 0 10px',
    position: 'relative',
    fontSize: '1rem',
    borderRadius: '4px',
    appearance: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    transition: 'all .1s ease-in-out',

    ['& > option']: {
      backgroundColor: props.theme.palette.background.paper,
    },

    ['&:hover']: {
      backgroundColor: props.theme.palette.divider,
    },
  }),
);

export const ArrowDown = styled(ArrowDropDown)((props: { theme: Theme }) => ({
  color: props.theme.palette.text.primary,
  top: 'calc(50% - 12px)',
  right: 0,
  position: 'absolute',
  pointerEvents: 'none',
}));
