import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { notebooksApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';

interface RunNowProps {
  id: string;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  setSnackBarMsg: React.Dispatch<React.SetStateAction<string>>;
}

export const RunNow = (props: RunNowProps) => {
  const { id, setError, setSnackBarMsg } = props;
  const notebookClient = useApi(notebooksApiRef);
  const location = useLocation();
  const navigate = useNavigate();

  const onRunNow = async () => {
    setError(undefined);
    setSnackBarMsg('Starting new run.');

    try {
      const notebook = await notebookClient.runScheduledNotebook(id);
      if (location.pathname.endsWith('runs')) {
        navigate(`${location.pathname}/${notebook.id}`);
      } else {
        const pathArr = location.pathname.split('/');
        pathArr.pop();
        const urlToNavigateBaseUrl = pathArr.join('/');
        navigate(`${urlToNavigateBaseUrl}/${notebook.id}`);
      }
    } catch (err) {
      setError(err as Error);
      setSnackBarMsg(`Something went wrong: ${err}`);
    }
  };

  return (
    <>
      <Button startIcon={<PlayArrowIcon />} onClick={onRunNow}>
        Run now
      </Button>
    </>
  );
};
