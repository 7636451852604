import { lighten } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export type NodeType = {
  color: string;
  id: string;
  index: number;
  status: string;
};

type Props = {
  node: NodeType;
};

export function CustomNode(props: Props) {
  const nodeRef = React.useRef<SVGTextElement | null>(null);
  const [nodeSize, setNodeSize] = useState({ width: 200, height: 35 });

  React.useLayoutEffect(() => {
    if (nodeRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        nodeRef.current.getBBox();

      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (
        renderedHeight !== nodeSize.height ||
        renderedWidth !== nodeSize.width
      ) {
        setNodeSize({ height: renderedHeight + 10, width: renderedWidth + 10 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <g>
      <Link to={`./../../steps/${props.node.index}`}>
        <rect
          width={nodeSize.width}
          height={nodeSize.height}
          rx={3}
          fill={lighten(props.node.color, 0.8)}
          stroke={props.node.color}
        />
        <title>{props.node.status}</title>
        <text
          ref={nodeRef}
          x={nodeSize.width / 2}
          y={nodeSize.height - 10}
          textAnchor="middle"
          alignmentBaseline="baseline"
        >
          {props.node.id}
        </text>
      </Link>
    </g>
  );
}
