import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { TestRun } from '../../api/LoadTestOrchestratorApi';

export interface StateComponentProps {
  testRun: TestRun;
  refetch: () => void;
}

export const StateComponent: React.FC<StateComponentProps> = ({ testRun }) => {
  return (
    <Grid container>
      <Grid item>
        <Typography
          variant="body2"
          style={{ marginRight: 8, fontWeight: 'bold' }}
        >
          {testRun.state_parameters?.state}
        </Typography>
      </Grid>
    </Grid>
  );
};
