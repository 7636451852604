import React from 'react';
import { useEntityFilterGroup } from '../../../hooks';

export function useFilter(
  buttonGroups: ICatalog.ButtonGroup[],
  initiallySelected: string,
): {
  currentFilter: string;
  setCurrentFilter: (filterId: string) => void;
  getFilterCount: (filterId: string) => number | undefined;
} {
  const [currentFilter, setCurrentFilter] = React.useState(initiallySelected);

  const filterGroup = React.useMemo<ICatalog.FilterGroup>(
    () => ({
      filters: Object.fromEntries(
        buttonGroups.flatMap(g => g.items).map(i => [i.id, i.filterFn]),
      ),
    }),
    [buttonGroups],
  );

  const { setSelectedFilters, state } = useEntityFilterGroup(
    'primary-sidebar',
    filterGroup,
    [initiallySelected],
  );

  const setCurrent = React.useCallback(
    (filterId: string) => {
      setCurrentFilter(filterId);
      setSelectedFilters([filterId]);
    },
    [setCurrentFilter, setSelectedFilters],
  );

  const getFilterCount = React.useCallback(
    (filterId: string) => {
      if (state.type !== 'ready') {
        return undefined;
      }
      return state.state.filters[filterId].matchCount;
    },
    [state],
  );

  return {
    currentFilter,
    setCurrentFilter: setCurrent,
    getFilterCount,
  };
}
