import React from 'react';
import IconButton from '@mui/material/IconButton';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface AsyncPaginationProps {
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onPageChange: (newPage: number) => void;
}

export function AsyncPagination({
  onPageChange,
  hasNextPage,
  hasPreviousPage,
  currentPage,
  icons,
}: AsyncPaginationProps & {
  icons: Record<string, OverridableComponent<SvgIconTypeMap>>;
}) {
  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleBackButtonClick = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!hasPreviousPage}
      >
        <icons.FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={!hasPreviousPage}>
        <icons.PreviousPage />
      </IconButton>
      <Typography variant="body2">{currentPage + 1}</Typography>
      <IconButton onClick={handleNextButtonClick} disabled={!hasNextPage}>
        <icons.NextPage />
      </IconButton>
    </Box>
  );
}
