import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { TabbedLayout } from '@backstage/core-components';
import { Layout } from '../components/common/Layout';
import { ModelGroupList } from '../components/Models/ModelGroups/ModelGroupList';
import { ProjectDetails } from '../components/Projects/ProjectDetails';
import { zflowApiRef } from '../api/zflowApiClient';
import { useUserTeams } from 'plugin-ui-components';
import { MLProject, MLResourceNotFound } from '../api/definitions';
import { PipelineList } from '../components/Pipelines/PipelineList';
import { ResourceNotFound } from '../components/common/ResourceNotFound';
import { ADMIN_TEAMS } from '../constants/adminTeams';
import { useAsync } from 'react-use';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const MLProjectPage = () => {
  const { projectId = '' } = useParams();

  const zflowApi = useApi(zflowApiRef);
  const {
    value: { userTeams = [] },
  } = useUserTeams();
  const [user, setUser] = useState('');
  const userTeam = userTeams[0];
  const [project, setProject] = useState<MLProject>();
  const [owner, setOwner] = useState<boolean | undefined>(false);
  const [admin, setAdmin] = useState<boolean | undefined>(false);
  const [resourceNotFound, setResourceNotFound] =
    useState<MLResourceNotFound>();

  const catalogApi = useApi(catalogApiRef);

  const { value: ADMINS = [] } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: ADMIN_TEAMS.map(name => ({
            kind: 'User',
            'relations.memberof': stringifyEntityRef({ name, kind: 'Group' }),
          })),
        })
        .then(res => res.items.map(member => member.metadata.name)),
    [catalogApi],
  );

  useEffect(() => {
    zflowApi
      .getProject(projectId)
      .then(setProject)
      .catch(_ => setResourceNotFound({ name: 'project', id: projectId }));
  }, [zflowApi, projectId]);

  useEffect(() => {
    zflowApi.getUserInfo().then(userInfo => setUser(userInfo.user_id));
  }, [zflowApi]);

  useEffect(() => {
    const isAdmin = ADMINS.includes(user);
    const isOwner =
      (userTeam && userTeam?.metadata.name === project?.team_id) ||
      (user && user === project?.created_by);

    if (isAdmin) setAdmin(true);
    if (isOwner) setOwner(true);
  }, [ADMINS, userTeam, user, project?.team_id, project?.created_by]);

  return (
    <Layout pageTitle={projectId}>
      {resourceNotFound ? (
        <ResourceNotFound {...resourceNotFound} />
      ) : (
        <TabbedLayout>
          <TabbedLayout.Route path="/details" title="Details">
            <ProjectDetails
              projectId={projectId}
              owner={owner}
              admin={admin}
              kpis={project?.impacted_kpis}
              createdBy={project?.created_by}
              description={project?.description}
              archived={project?.archived}
              projectTeam={project?.team}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/pipelines" title="Pipelines">
            <PipelineList projectId={projectId} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/model-groups" title="Model Groups">
            <ModelGroupList projectId={projectId} />
          </TabbedLayout.Route>
          <TabbedLayout.Route
            path="/notebooks/shared_notebooks"
            title="Notebooks"
          >
            <></>
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
    </Layout>
  );
};
