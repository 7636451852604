import React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Text, Tooltip } from '../../../../common';

export function ChartTitle({ text }: { text: string }) {
  return (
    <Text display="flex" alignItems="center">
      <Text paddingRight="5px">{text}</Text>
      <Tooltip title="Step data not avaliable yet">
        <InfoOutlined />
      </Tooltip>
    </Text>
  );
}
