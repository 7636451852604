import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { gitHubApiRef, GitHubClient } from './api';

export const githubPlugin = createPlugin({
  id: 'github',
  apis: [
    createApiFactory({
      api: gitHubApiRef,
      deps: { authApi: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ authApi, discoveryApi }) =>
        new GitHubClient({ authApi, discoveryApi }),
    }),
  ],
});
