import React from 'react';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { TestDetailsComponent } from './TestDetailsComponent';
import { NotFoundPage } from 'plugin-ui-components';

export const TestDetailsPageWrapper = () => {
  const featureFlags = useApi(featureFlagsApiRef);
  const loadTestingSource = featureFlags.isActive('load-testing');

  return loadTestingSource ? <TestDetailsComponent /> : <NotFoundPage />;
};
