import React from 'react';
import { CircularProgress, Link, Typography } from '@material-ui/core';
import { getAliasFromDomain } from 'plugin-cdp';
import { UserReference } from 'plugin-ui-components';

import { Grid, Item } from '../Grid';
import { getRepoURL } from './repo';
import { useStyles } from './styles';
import { ImageCIInfoModel } from '../../models';
import { PipelineData, usePipelineInfo } from './usePipelineInfo';

export function CIInfo({ ci }: { ci: ImageCIInfoModel }) {
  const repo = getRepoURL(ci.url);
  const classes = useStyles();
  const { loading, value } = usePipelineInfo(ci.pipelineId, ci.buildId);

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        CI info
      </Typography>

      <Grid>
        <Item title="Author">
          <UserReference
            user={ci.author}
            displayType="login"
            header="Author:"
          />
        </Item>

        <Item title="Pipeline">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/cdp/pipeline/${ci.pipelineId}`}
          >
            {ci.pipelineId}
          </Link>
        </Item>

        <BuildIDLink
          id={ci.buildId}
          loading={loading}
          value={value}
          pipelineID={ci.pipelineId}
        />

        <Item title="GitHub commit">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`${repo}/commit/${ci.revision}`}
          >
            {ci.revision.slice(0, 6)}
          </Link>
        </Item>

        {value?.tags?.x_zalando_team && (
          <Item title="Zappr team">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`/catalog/default/group/${value.tags.x_zalando_team}`}
            >
              {loading ? (
                <CircularProgress size="15px" />
              ) : (
                value?.tags.x_zalando_team
              )}
            </Link>
          </Item>
        )}

        {value?.tags?.application && (
          <Item title="Application">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`/catalog/default/Component/${value.tags.application}`}
            >
              {loading ? (
                <CircularProgress size="15px" />
              ) : (
                value.tags.application
              )}
            </Link>
          </Item>
        )}
      </Grid>
    </>
  );
}

function BuildIDLink({
  loading,
  value,
  id,
  pipelineID,
}: {
  loading: boolean;
  value: PipelineData;
  id: string;
  pipelineID: string;
}) {
  if (loading)
    return (
      <Item title="Build">
        <CircularProgress size="15px" />
      </Item>
    );

  const { tags, stepName, stepOrdinal } = value || {};
  if (!tags?.repo || !stepName || stepOrdinal < 0)
    return <Item title="Build">{id}</Item>;

  const repo: string = tags?.repo || '';
  const [domain, org, name] = repo.split('/');
  const link = `/cdp/${getAliasFromDomain(
    domain,
  )}/${org}/${name}/${pipelineID}/steps/${stepOrdinal}/artifacts`;

  return (
    <Item title="Build">
      <Link target="_blank" rel="noopener noreferrer" href={link}>
        {`${id} (${stepName})`}
      </Link>
    </Item>
  );
}
