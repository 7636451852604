import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { TechRadarClientApi } from './api/TechRadarClientApi';
import { oauth2ApiRef } from 'plugin-core';

export const rootRouteRef = createRouteRef({ id: 'tech-radar' });

export const plugin = createPlugin({
  id: 'tech-radar',
  routes: {
    techRadar: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: techRadarApiRef,
      deps: {
        oauth2Api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2Api, discoveryApi }) =>
        new TechRadarClientApi(oauth2Api, discoveryApi),
    }),
  ],
});

export const TechRadarPage = plugin.provide(
  createRoutableExtension({
    name: 'plugin-tech-radar',
    component: () =>
      import('./components/TechRadarPage').then(m => m.TechRadarPage),
    mountPoint: rootRouteRef,
  }),
);
