import { useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt';
import React, { useState } from 'react';
import { ModelApproval as ModelApprovalDef } from '../../api/definitions';
import { zflowApiRef } from '../../api/zflowApiClient';
import { approvalButtonStyles } from '../../styles';
import { ButtonTheme } from '../common/customTheme';

interface ModelApprovalProps {
  model_id: string;
  getModelDetails: () => void;
  approval: ModelApprovalDef | null;
}
export const ModelApproval = (props: ModelApprovalProps) => {
  const { model_id, getModelDetails, approval } = props;
  const zflowApi = useApi(zflowApiRef);

  const styles = approvalButtonStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [modelApprovalErr, setModelApprovalErr] = useState('');

  const onModelApproval = async (approvalType: string, comment: string) => {
    const promise =
      approvalType === 'approve'
        ? zflowApi.approveModel(model_id, comment)
        : zflowApi.rejectModel(model_id, comment);

    promise
      .then(() => getModelDetails())
      .catch(err => setModelApprovalErr(err.message));
    setOpenDialog(false);
  };

  const ApprovalCommentDialog = () => {
    const action = dialogType === 'approve' ? 'approval' : 'rejection';
    const [comment, setComment] = useState('');
    const [isCommentEmpty, setIsCommentEmpty] = useState(false);
    const onSave = () => {
      if (comment === '') {
        setIsCommentEmpty(true);
      } else {
        onModelApproval(dialogType, comment);
      }
    };
    const onChange = (e: any) => {
      if (isCommentEmpty) {
        setIsCommentEmpty(false);
      }
      setComment(e.target.value);
    };
    return (
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <TextField
            label="Comments"
            onChange={onChange}
            value={comment}
            fullWidth
            helperText={
              isCommentEmpty
                ? 'Comment cannot be empty'
                : `Enter reason for the ${action}`
            }
            required
            multiline
            minRows={5}
            style={{ width: 500 }}
            error={isCommentEmpty}
          />
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={ButtonTheme}>
            <Button color="inherit" onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={onSave}>
              Save
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    );
  };

  const PendingApproval = () => (
    <div className={styles.float}>
      <ThemeProvider theme={ButtonTheme}>
        <Button
          className={`${styles.m10}`}
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenDialog(true);
            setDialogType('approve');
          }}
        >
          <ThumbUpAlt className={styles.m5} /> Approve
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpenDialog(true);
            setDialogType('reject');
          }}
        >
          <ThumbDownAlt className={styles.m5} /> Reject
        </Button>
      </ThemeProvider>
      <ApprovalCommentDialog />
    </div>
  );

  const Approved = () => (
    <div className={styles.float}>
      <ThemeProvider theme={ButtonTheme}>
        <Button
          className={styles.m10}
          variant="contained"
          color="primary"
          disabled
        >
          <ThumbUpAlt className={styles.m5} /> Approved
        </Button>
      </ThemeProvider>
    </div>
  );

  const Rejected = () => (
    <div className={styles.float}>
      <ThemeProvider theme={ButtonTheme}>
        <Button className={styles.m10} variant="contained" disabled>
          <ThumbDownAlt className={styles.m5} /> Rejected
        </Button>
      </ThemeProvider>
    </div>
  );

  const getButtonGroup = (approval_status: string) => {
    switch (approval_status.toLowerCase()) {
      case 'approved':
        return <Approved />;
      case 'rejected':
        return <Rejected />;
      case 'pendingmanualapproval':
        return <PendingApproval />;
      default:
        return null;
    }
  };

  return (
    <>
      {getButtonGroup(approval?.approval_status || '')}
      <Snackbar
        open={modelApprovalErr !== ''}
        autoHideDuration={6000}
        onClose={() => setModelApprovalErr('')}
        message={modelApprovalErr}
      />
    </>
  );
};
