import React from 'react';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PeopleIcon from '@material-ui/icons/People';
import UpdateIcon from '@material-ui/icons/Update';
import InfoIcon from '@material-ui/icons/Info';
import AppsIcon from '@material-ui/icons/Apps';
import TableChartIcon from '@material-ui/icons/TableChart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { useStyles } from './styles';
import cn from 'classnames';
import { TrackedInteraction } from 'plugin-ui-components';
import { clickOnResourceLink } from '../../../../tracking';

interface ResourceLinkProps {
  type: string;
  disabled?: boolean;
  link: string;
  domain: string;
}

export const resourceLinkTypes: Array<{
  type: string;
  icon: React.ReactNode;
  linkText: string;
}> = [
  {
    type: 'EXTERNALDOC',
    icon: <MenuBookIcon />,
    linkText: 'External (informative) documentation',
  },
  {
    type: 'INTERNALDOC',
    icon: <LibraryBooksIcon />,
    linkText: 'Internal (collaborative) documentation',
  },
  {
    type: 'KPIS',
    icon: <TrendingUpIcon />,
    linkText: 'KPIs',
  },
  {
    type: 'CUSTOMERS',
    icon: <PeopleIcon />,
    linkText: 'Customers',
  },
  {
    type: 'UPDATES',
    icon: <UpdateIcon />,
    linkText: 'Updates feed (zLife)',
  },
  {
    type: 'SLOS',
    icon: <InfoIcon />,
    linkText: 'SLOs (Tracing)',
  },
  {
    type: 'ARCHITECTURE',
    icon: <AppsIcon />,
    linkText: 'Architecture design overview',
  },
  {
    type: 'ROADMAP',
    icon: <TableChartIcon />,
    linkText: 'Roadmap',
  },
];

export const ResourceLink = ({
  type,
  link,
  disabled = false,
  domain,
}: ResourceLinkProps) => {
  const resourceLink = resourceLinkTypes.find(item => item.type === type);
  const styles = useStyles();
  return (
    <TrackedInteraction
      interaction="onClick"
      {...clickOnResourceLink(type, domain)}
    >
      <Link to={link} className={cn(styles.link, { disabled })}>
        {resourceLink?.icon}
        <Typography className={styles.linkText}>
          {resourceLink?.linkText}
        </Typography>
      </Link>
    </TrackedInteraction>
  );
};
