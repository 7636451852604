import { useCallback, useRef } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../api';
import { NotFoundException } from '../../../../api/exceptions';
import { DeploymentResourceModel } from '../../../../models';
import { STEP_PRE_IN_PROGRESS_STATUSES } from '../../../../constants';

export function useDeploymentStatusResources(
  runId: string,
  ordinal: string,
  stepRunId: string,
  params: { stepId: string },
  status: string,
) {
  const api = useApi(cdpApiRef);
  const valueRef = useRef<Array<DeploymentResourceModel>>([]);

  const getDeploymentStatus = useCallback(async () => {
    if (!stepRunId || STEP_PRE_IN_PROGRESS_STATUSES.includes(status)) {
      return Promise.resolve();
    }

    try {
      const response = await api.getDeploymentStatus(
        runId,
        ordinal,
        stepRunId,
        params,
      );

      if (response.resources) {
        valueRef.current = response.resources.map(
          a => new DeploymentResourceModel(a),
        );
      }

      return Promise.resolve(valueRef.current);
    } catch (error) {
      const e = error as Error;
      if (!(e instanceof NotFoundException)) {
        return Promise.reject(e);
      }

      return Promise.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, stepRunId]);

  const { loading, error, value, retry } = useAsyncRetry(
    () => getDeploymentStatus(),
    [getDeploymentStatus, status],
  );

  return [
    { loading, error, value: value || [] },
    {
      retry,
      getDeploymentStatus,
    },
  ] as const;
}
