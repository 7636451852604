import React, { useEffect, useState } from 'react';
import { Layout } from '../../common/Layout';
import { useParams } from 'react-router';
import { modelsApiRef } from '../../../api/services/models';
import { Model } from '../../../api/interfaces/model';
import { useApi } from '@backstage/core-plugin-api';
import { Box, LinearProgress, List, ListItemText } from '@material-ui/core';
import { ModelOverview } from './ModelOverview';
import {
  InfoCard,
  MarkdownContent,
  TabbedLayout,
} from '@backstage/core-components';
import { ModelVersionList } from '../../ModelVersions/List';
import { IResourceNotFound } from '../../../api/interfaces/common';
import { ModelCard } from '../../../api/interfaces/model_card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ResourceNotFoundPage } from '../../common/ResourceNotFoundPage';
import { EditModel } from './EditModel';

export const ModelDetails = () => {
  const { modelId = '' } = useParams();
  const modelsApi = useApi(modelsApiRef);
  const [model, setModel] = useState<Model>();
  const [modelCard, setModelCard] = useState<ModelCard>();
  const [loading, setLoading] = useState<boolean>(true);
  const [resourceNotFound, setResourceNotFound] = useState<{
    model?: IResourceNotFound;
    modelVersion?: IResourceNotFound;
    modelCard?: IResourceNotFound;
  }>({});

  useEffect(() => {
    const fetchModel = async () => {
      try {
        const modelResponse = await modelsApi.getModelById(modelId);
        setModel(modelResponse);
      } catch {
        setResourceNotFound(prev => ({
          ...prev,
          model: { name: 'model', id: modelId },
        }));
      }
    };

    const fetchModelCard = async () => {
      try {
        const modelCardResponse = await modelsApi.getModelCardForModel(modelId);
        setModelCard(modelCardResponse);
      } catch {
        setResourceNotFound(prev => ({
          ...prev,
          modelCard: { name: 'model card', id: modelId },
        }));
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchModel();
      await fetchModelCard();
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, modelsApi]);

  const renderList = (
    title: string,
    items: string[],
    noItemsMessage: string,
  ) => (
    <InfoCard title={title}>
      {items.length > 0 ? (
        <List>
          {items.map((item, index) => (
            <ListItemText
              key={index}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                  {item}
                </Typography>
              }
            />
          ))}
        </List>
      ) : (
        <Typography>{noItemsMessage}</Typography>
      )}
    </InfoCard>
  );

  if (loading) {
    return <LinearProgress />;
  }

  const getFirstResourceNotFound = () => {
    if (resourceNotFound.model) return resourceNotFound.model;
    if (resourceNotFound.modelCard) return resourceNotFound.modelCard;
    return null;
  };

  const firstResourceNotFound = getFirstResourceNotFound();

  return (
    <Layout namedEntities={{ modelName: model?.name }}>
      {firstResourceNotFound && (
        <ResourceNotFoundPage
          name={firstResourceNotFound.name}
          id={firstResourceNotFound?.id}
        />
      )}
      {model && modelCard && (
        <TabbedLayout>
          <TabbedLayout.Route path="/card" title="Overview">
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ModelOverview
                    model={model}
                    links={modelCard.links}
                    inputFormat={modelCard.input_format}
                    outputFormat={modelCard.output_format}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title="Technical Specification">
                    {modelCard.technical_specification ? (
                      <MarkdownContent
                        content={modelCard.technical_specification}
                        dialect="gfm"
                      />
                    ) : (
                      <Typography>
                        No technical specification provided
                      </Typography>
                    )}
                  </InfoCard>
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title="Model Architecture">
                    {modelCard.model_architecture ? (
                      <MarkdownContent
                        content={modelCard.model_architecture}
                        dialect="gfm"
                      />
                    ) : (
                      <Typography>No model architecture provided</Typography>
                    )}
                  </InfoCard>
                </Grid>
                <Grid item xs={12}>
                  {renderList(
                    'Use cases',
                    modelCard.use_cases,
                    'No use cases provided',
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderList(
                    'Trade offs',
                    modelCard.tradeoffs,
                    'No trade offs provided',
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InfoCard title="Ethical Considerations">
                    {modelCard.ethical_considerations.length > 0 ? (
                      <List>
                        {modelCard.ethical_considerations.map((item, index) =>
                          Object.entries(item).map(([key, value]) => (
                            <ListItemText
                              key={`${index}-${key}`}
                              primary={
                                <Typography variant="h6">
                                  {key
                                    .replace(/_/g, ' ')
                                    .replace(/\b\w/g, char =>
                                      char.toUpperCase(),
                                    )}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: 'text.primary',
                                    display: 'inline',
                                  }}
                                >
                                  {value}
                                </Typography>
                              }
                            />
                          )),
                        )}
                      </List>
                    ) : (
                      <Typography>
                        No ethical considerations provided
                      </Typography>
                    )}
                  </InfoCard>
                </Grid>
                <Grid item xs={6}>
                  {renderList(
                    'References',
                    modelCard.references,
                    'No references provided',
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderList(
                    'Citations',
                    modelCard.citations,
                    'No citations provided',
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/versions" title="Versions">
            <ModelVersionList model={model} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/edit" title="Edit">
            <EditModel model={model} modelCard={modelCard} />
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
    </Layout>
  );
};
