import React from 'react';

type Props = {
  name: string;
  emoji: string;
};

export function Emoji({ name, emoji }: Props) {
  return (
    <span role="img" aria-label={name}>
      {emoji}
    </span>
  );
}
