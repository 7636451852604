export class LogsLineModel {
  content: string;
  timestamp: number;

  constructor(content?: string, timestamp?: string) {
    this.content = content ?? '';
    this.timestamp = Number(timestamp) ?? -1;
  }

  public isEqual = (fold: LogsLineModel) => {
    return this.content === fold.content && this.timestamp === fold.timestamp;
  };
}
