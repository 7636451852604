import React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { useServices } from '../../../../../services';
import { RepositoryModel } from '../../../../../models';

export const RepositoryFilter = observer(() => {
  const {
    repositoriesMainPageService: repositoriesService,
    filteringMainPageService: filteringService,
  } = useServices();

  const repositoriesToOptions = (repositories: Array<RepositoryModel>) =>
    repositories.map(item => ({
      label: item.name,
      value: item.fullPath,
      group: item.organization,
    }));

  const optionsToRepositories = (repositories: Array<IOption>) =>
    repositories.map(item => RepositoryModel.fromFullPath(item.value));

  const getOptionSelected = (option: IOption, value: IOption) => {
    return option.value === value.value;
  };

  const handleChange = (
    selectedOptions: Array<{ label: string; value: string }>,
  ) => {
    filteringService.persistHelper.enablePersist();
    filteringService.repository.setUserSelectedRepositories(
      optionsToRepositories(selectedOptions),
    );
  };

  return (
    <AutoComplete
      onChange={handleChange}
      isLoading={repositoriesService.repositoryTreeState.isLoading}
      hasError={repositoriesService.repositoryTreeState.hasError}
      options={repositoriesToOptions(
        filteringService.repository.filteredRepositories,
      )}
      defaultSelection={repositoriesToOptions(
        filteringService.repository.userSelectedRepositories,
      )}
      getOptionSelected={getOptionSelected}
      shouldGroup
      disabled={filteringService.repository.filteredRepositories.length === 0}
      uniqueId="repositories-for-organisations"
      placeholder="Repositories"
      noOptionsText="No repositories"
      noOptionsErrorText="Error occurred while fetching repositories"
      inputPlaceholder="Filter repositories"
    />
  );
});
