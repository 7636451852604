import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';

export class OnboardingApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async updateGithubAccountInUserApi(
    existingGithubAccounts: string[],
    newGitHubAccount: string | undefined,
    userLogin: string,
  ) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    let resp = { ok: true, statusText: '' };
    // In case there is a new account to set
    if (newGitHubAccount && newGitHubAccount !== '') {
      const proxyApiUrl = `${proxyUrl}/users-api/${userLogin}/account/github`;
      const body = {
        username: newGitHubAccount,
      };
      resp = await fetch(proxyApiUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!resp.ok) {
        return resp;
      }
    }
    // Delete all the existing accounts that are not the new ones
    for (const gitHubAccount of existingGithubAccounts) {
      if (gitHubAccount !== newGitHubAccount) {
        const proxyApiUrl = `${proxyUrl}/users-api/${userLogin}/account/github?username=${gitHubAccount}`;
        resp = await fetch(proxyApiUrl, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        });
        if (!resp.ok) {
          return resp;
        }
      }
    }
    return resp;
  }
}

export const onboardingApiRef = createApiRef<OnboardingApi>({
  id: 'plugin.settings-onboarding',
});
