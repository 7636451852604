import {
  OAuthApi,
  createApiRef,
  DiscoveryApi,
} from '@backstage/core-plugin-api';
import { buildProxyUrl } from 'plugin-core';
import {
  ResourceTypesResponse,
  ResourceTypeScopesResponse,
  DetailsResourceTypeResponse,
  DetailsResourceTypeScopeResponse,
} from './types/responses';

interface IEssentialsApi {
  getResourceTypes: () => Promise<ResourceTypesResponse>;
  getResourceTypeDetails: (
    resourceId: string,
  ) => Promise<DetailsResourceTypeResponse>;
  deleteResourceType: (resourceId: string) => Promise<any>;
  getScopesFromResource: (
    resourceType: string,
  ) => Promise<ResourceTypeScopesResponse>;
  getScopeDetails: (
    resourceId: string,
    scopeId: string,
  ) => Promise<DetailsResourceTypeScopeResponse>;
  deleteScope: (resourceId: string, scopeId: string) => Promise<any>;
  saveResourceType: (
    id: string,
    name: string,
    description: string,
  ) => Promise<{ ok: boolean; message: string }>;
  saveScope: (
    resourceId: string,
    id: string,
    summary: string,
    description: string,
    isResourceOwnerScope: boolean,
    userInformation: string,
  ) => Promise<{ ok: boolean; message: string }>;
}

export const essentialsApiRef = createApiRef<IEssentialsApi>({
  id: 'plugin-application-registry-essentials',
});

export class EssentialsApi implements IEssentialsApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getResourceTypes() {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      headers: { authorization: `Bearer ${token}` },
    });
    return await res.json();
  }

  async getResourceTypeDetails(resourceId: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceId}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      headers: { authorization: `Bearer ${token}` },
    });
    return await res.json();
  }

  async deleteResourceType(resourceId: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceId}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      method: 'DELETE',
      headers: { authorization: `Bearer ${token}` },
    });
    return await res.json();
  }

  async getScopesFromResource(resourceType: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceType}/scopes`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return await res.json();
  }

  async getScopeDetails(resourceId: string, scopeId: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceId}/scopes/${scopeId}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      headers: { authorization: `Bearer ${token}` },
    });
    return await res.json();
  }

  async deleteScope(resourceId: string, scopeId: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceId}/scopes/${scopeId}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      method: 'DELETE',
      headers: { authorization: `Bearer ${token}` },
    });
    if (res.ok) return await res.json();
    throw new Error('Failed to delete scope');
  }

  async saveResourceType(id: string, name: string, description: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${id}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        description,
        resource_owners: ['services'],
      }),
    });
    return { ok: res.ok, message: res.ok ? '' : (await res.json()).message };
  }

  async saveScope(
    resourceId: string,
    id: string,
    summary: string,
    description: string,
    is_resource_owner_scope: boolean,
    user_information: string,
  ) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `essentials/resource-types/${resourceId}/scopes/${id}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        summary,
        description,
        is_resource_owner_scope,
        user_information,
        criticality_level: 2,
      }),
    });
    return { ok: res.ok, message: res.ok ? '' : (await res.json()).message };
  }
}
