import React from 'react';
import { Link, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Analysis } from '../../../types';

type Props = {
  report?: Analysis;
};

export const CardBottom: React.FC<Props> = ({ report }) => {
  return (
    <Link
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
      href={`https://codacy.bus.zalan.do/ghe/${report?.repository.owner}/${report?.repository.name}/dashboard`}
    >
      <Typography color="primary" variant="body2">
        See more{' '}
      </Typography>
      <OpenInNewIcon fontSize="small" />
    </Link>
  );
};
