const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'app',
  eventCategory: 'Settings - About',
  eventAction: 'click',
};

export function versionClicked(dependency: string): IEventTracker {
  return {
    ...common,
    eventLabel: dependency,
  };
}
