import React, { useMemo } from 'react';
import { GCSSearchResultSnippet } from '../../types';
import Highlighter from 'react-highlight-words';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatGCSSnippet } from '../../utils';

interface Props {
  snippet?: GCSSearchResultSnippet;
}

/** Render highlights returned in ElasticSearch result highlights */
export function HighlightedGCSTexts({ snippet }: Props) {
  const { words, text } = useMemo((): { text: string; words: string[] } => {
    if (!snippet?.snippet) return { words: [], text: '' };
    const highlightedWords = new Set<string>();

    const highlightedText = snippet.snippet;
    snippet.matchRanges?.forEach(({ start, end }) => {
      if (typeof start === 'number' && typeof end === 'number') {
        highlightedWords.add(highlightedText.slice(start, end));
      }
    });

    return { text: highlightedText, words: Array.from(highlightedWords) };
  }, [snippet]);

  return (
    <Box marginTop={1}>
      <Typography variant="body2" color="textSecondary">
        <Highlighter
          searchWords={words}
          textToHighlight={formatGCSSnippet(text)}
          autoEscape
        />
      </Typography>
    </Box>
  );
}
