import React from 'react';
import { List, ListItem, Text } from '../../../common';
import { VALIDATION_PROBLEM_DOMAIN } from '../../../constants';
import {
  IDeploymentValidationResults,
  IValidationProblem,
} from '../../../api/types/payload';
import { IconError, IconWarning, Label, Reference } from './common';

type Props = {
  manifestName: string;
  result: IDeploymentValidationResults;
};

export function ValidationResult({ manifestName, result }: Props) {
  return (
    <div>
      <Text marginBottom="10px" display="block" bold>
        {manifestName}
      </Text>
      <List>
        {result.errors?.map((error: IValidationProblem, index) => (
          <ListItem key={index} icon={<IconError />}>
            <span>
              {error.message}

              {error.domain && <Label text={error.domain} title="Rule owner" />}

              {error.domain === VALIDATION_PROBLEM_DOMAIN.security &&
                error.rule_id && <Label text={error.rule_id} title="Rule ID" />}
              {error.unblocked_by && (
                <Label
                  text={`unblocked by: ${error.unblocked_by}`}
                  title="Unblocked by"
                />
              )}
            </span>
            {error.metadata?.reference_url && (
              <Reference URL={error.metadata.reference_url} />
            )}
          </ListItem>
        ))}
        {result.warnings?.map((warning, index) => (
          <ListItem key={index} icon={<IconWarning />}>
            <span>
              {warning.message}

              {warning.domain && (
                <Label text={warning.domain} title="Rule owner" />
              )}

              {warning.domain === VALIDATION_PROBLEM_DOMAIN.security &&
                warning.rule_id && (
                  <Label text={warning.rule_id} title="Rule ID" />
                )}
            </span>

            {warning.metadata?.reference_url && (
              <Reference URL={warning.metadata.reference_url} />
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
}
