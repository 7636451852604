export const sanitizeNulls = (obj: any) => {
  if (obj === null) {
    return 'null';
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        obj[key] = 'null';
      }
      if (typeof obj[key] === 'object') {
        obj[key] = sanitizeNulls(obj[key]);
      }
    }
  }
  return obj;
};
