import React from 'react';
import { Table } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { makeActions, makeColumns } from './table';

const widgetName = 'Connected Domains/Sub-Domains table';
const columns = makeColumns(widgetName);
const actions = makeActions(widgetName);

export function SubDomainsTable() {
  const { entity } = useEntity<IEntityDomain>();
  const catalogApi = useApi(catalogApiRef);

  const {
    value = [],
    loading,
    error,
  } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: [
            {
              kind: 'Domain',
              'relations.childOf': stringifyEntityRef({
                name: entity.metadata.name,
                namespace: entity.metadata.namespace,
                kind: 'Domain',
              }),
            },
          ],
        })
        .then(res => res.items),
    [catalogApi, entity],
  );

  return (
    <>
      {error && !loading && (
        <Alert severity="error">
          <Typography>{error?.message}</Typography>
        </Alert>
      )}

      <Table
        title={`Sub-Domains (${value.length})`}
        columns={columns}
        data={value}
        actions={actions}
        isLoading={loading}
        options={{
          padding: 'dense',
          paging: false,
          search: false,
        }}
      />
    </>
  );
}
