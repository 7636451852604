import {
  OAuthApi,
  DiscoveryApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { NSQLQuery, NSQLQueryAuthorization } from '../../domain/NSQLQueries';
import { http } from '../http-client';

export interface NakadiSQLAPIGateway {
  getNSQLQuery(queryId: string): Promise<NSQLQuery>;
  updateNSQLQueryAuthorization(
    queryId: string,
    authorization: NSQLQueryAuthorization,
  ): Promise<undefined>;
}

export const nsqlApiRef = createApiRef<NakadiSQLAPIGateway>({
  id: 'plugin.nakadi.nakadi-sql-api',
});

export class NakadiSQLAPIGatewayImpl implements NakadiSQLAPIGateway {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async updateNSQLQueryAuthorization(
    queryId: string,
    authorization: NSQLQueryAuthorization,
  ): Promise<undefined> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<undefined>(
      `${proxyUrl}/nakadi-sql/api/queries/${queryId}/authorization`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify(authorization),
      },
    );
  }

  async getNSQLQuery(queryId: string): Promise<NSQLQuery> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<NSQLQuery>(
      `${proxyUrl}/nakadi-sql/api/queries/${queryId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }
}
