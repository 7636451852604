import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import {
  AwsSecurityHubControlsDataPerCostCenter,
  AwsSecurityHubControlsDataPerTeam,
  AwsSecurityHubDetailedFinding,
  AwsSecurityHubFindingsTotalsByTeam,
} from './types';

export class ComplianceAndSecurityAwsSecurityHubApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchAwsSecurityHubFindingsTotalsByTeam(): Promise<
    Array<AwsSecurityHubFindingsTotalsByTeam>
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-findings-totals`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return await response.json();
  }

  async fetchAwsSecurityHubDetailedFindings(
    teamId?: string,
  ): Promise<AwsSecurityHubDetailedFinding[]> {
    if (!teamId) return [];

    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-detailed-findings/${
      teamId ?? ''
    }`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return await response.json();
  }

  async fetchAwsSecurityHubControlsByTeam(
    teamId?: string,
  ): Promise<
    AwsSecurityHubControlsDataPerTeam | AwsSecurityHubControlsDataPerTeam[]
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-controls-by-team-api${
      teamId ? `/${teamId}` : ''
    }`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await response.json();
  }

  async fetchAwsSecurityHubFailedControlsByCostCenter(
    costCenter?: string,
  ): Promise<
    | AwsSecurityHubControlsDataPerCostCenter
    | AwsSecurityHubControlsDataPerCostCenter[]
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-controls-by-cost-center-api${
      costCenter ? `/${costCenter}` : ''
    }`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await response.json();
  }
}

export const complianceAndSecurityAwsSecurityHubApiRef =
  createApiRef<ComplianceAndSecurityAwsSecurityHubApi>({
    id: 'plugin.compliance-and-security.aws-security-hub-controls-by-team',
  });
