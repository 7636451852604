import {
  FeatureFlagsApi,
  featureFlagsApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React from 'react';
import { isSunriseAdmin } from 'plugin-core';
import { useUserTeams } from './useUserTeams';

const shouldShowNavigation =
  ({
    isUserAdmin,
    featureFlags,
  }: {
    isUserAdmin: boolean;
    featureFlags: FeatureFlagsApi;
  }) =>
  ({ featureFlag, isAdminOnly, persist }: NavItem) => {
    if (featureFlag && !featureFlags.isActive(featureFlag) && !persist) {
      return false;
    }

    if (isAdminOnly && !isUserAdmin) {
      return false;
    }

    return true;
  };

export function useFilteredNav(allNavs: Array<NavItem>): Array<NavItem> {
  const featureFlags = useApi(featureFlagsApiRef);
  const {
    value: { userTeams = [] },
  } = useUserTeams({ include: { member: true, led: true } });
  const [navs, setNavs] = React.useState<NavItem[]>([]);

  const isUserAdmin = React.useMemo<boolean>(
    () => isSunriseAdmin(userTeams),
    [userTeams],
  );

  React.useEffect(() => {
    setNavs(
      allNavs.filter(shouldShowNavigation({ isUserAdmin, featureFlags })),
    );
  }, [allNavs, featureFlags, isUserAdmin]);

  return navs;
}
