import {
  OAuthApi,
  createApiRef,
  DiscoveryApi,
} from '@backstage/core-plugin-api';
import { buildProxyUrl } from 'plugin-core';

export interface IMintStorageApi {
  getApplicationById: (
    id: string,
  ) => Promise<IAccessControl.ApplicationType> | null;
  updateAppConfig: (
    id: string,
    appData: IAccessControl.ApplicationType,
    updatedScopes: Array<IAccessControl.UpdateDataType>,
  ) => Promise<boolean>;
  updateKubernetesClusters: (
    id: string,
    appData: IAccessControl.ApplicationType,
    kubernetesClusters: string[],
  ) => Promise<boolean>;
  updateOAuthData: (
    id: string,
    appData: IAccessControl.ApplicationType,
    newOAuthData: any,
  ) => Promise<boolean>;
  updateS3BucketsData: (
    id: string,
    appData: IAccessControl.ApplicationType,
    newS3BucketsData: any,
  ) => Promise<boolean>;
  getAppsFromScope: (
    resourceId: string,
    scopeId: string,
  ) => Promise<Array<{ id: string }>>;
  renewCredentials: (id: string) => Promise<boolean>;
}

export const mintStorageApiRef = createApiRef<IMintStorageApi>({
  id: 'plugin-application-registry',
});

export class MintStorageApi implements IMintStorageApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getApplicationById(id: string) {
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(serviceUrl, {
      headers: { authorization: `Bearer ${token}` },
    });
    if (res.status !== 404) {
      return await res.json();
    }
    return null;
  }

  async updateAppConfig(
    id: string,
    appData: IAccessControl.ApplicationType,
    updatedScopes: Array<IAccessControl.UpdateDataType>,
  ) {
    const newScopes = updatedScopes
      .filter(scope => scope.selected)
      .map(({ scopeId, resourceId }) => {
        return { scope_id: scopeId, resource_type_id: resourceId };
      });
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}`,
    );
    const token = await this.oauth2Api.getAccessToken();
    // Skip already selected scopes in the updated array, they should not be present in the final update array
    appData.scopes.map((item: any) => {
      const isRemoved = updatedScopes.some(
        scope =>
          scope.scopeId === item.scope_id &&
          scope.resourceId === item.resource_type_id,
      );
      if (!isRemoved) newScopes.push(item);
    });
    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redirect_url: appData.redirect_url,
        is_client_confidential: appData.is_client_confidential,
        s3_buckets: appData.s3_buckets,
        kubernetes_clusters: appData.kubernetes_clusters,
        scopes: newScopes,
      }),
    });
    return res.ok ? true : false;
  }

  async updateKubernetesClusters(
    id: string,
    appData: IAccessControl.ApplicationType,
    newKubernetesClusters: string[],
  ) {
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}`,
    );

    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redirect_url: appData.redirect_url,
        is_client_confidential: appData.is_client_confidential,
        s3_buckets: appData.s3_buckets,
        kubernetes_clusters: newKubernetesClusters,
        scopes: appData.scopes,
      }),
    });
    return res.ok;
  }

  async updateOAuthData(
    id: string,
    appData: IAccessControl.ApplicationType,
    newOAuthData: Partial<IAccessControl.ApplicationType>,
  ) {
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}`,
    );

    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redirect_url: newOAuthData.redirect_url ?? appData.redirect_url,
        is_client_confidential:
          newOAuthData.is_client_confidential ?? appData.is_client_confidential,
        s3_buckets: appData.s3_buckets,
        kubernetes_clusters: appData.kubernetes_clusters,
        scopes: newOAuthData.scopes,
      }),
    });
    return res.ok;
  }

  async updateS3BucketsData(
    id: string,
    appData: IAccessControl.ApplicationType,
    newS3BucketsData: Partial<IAccessControl.ApplicationType>,
  ) {
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}`,
    );

    const res = await fetch(serviceUrl, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redirect_url: appData.redirect_url,
        is_client_confidential: appData.is_client_confidential,
        s3_buckets: newS3BucketsData ?? appData.s3_buckets,
        kubernetes_clusters: appData.kubernetes_clusters,
        scopes: appData.scopes,
      }),
    });
    return res.ok;
  }

  async getAppsFromScope(resourceId: string, scopeId: string) {
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps?resource_type_id=${resourceId}&scope_id=${scopeId}`,
    );
    const res = await fetch(serviceUrl, {
      headers: { authorization: `Bearer ${token}` },
    });
    return await res.json();
  }

  async renewCredentials(id: string) {
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = await buildProxyUrl(
      this.discoveryApi,
      `mint-storage/apps/${id}/renewal`,
    );
    const res = await fetch(serviceUrl, {
      method: 'POST',
      headers: { authorization: `Bearer ${token}` },
    });
    return res.ok;
  }
}
