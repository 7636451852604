import React, { ChangeEvent, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  DialogActions,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import { useServices } from '../../../../../../services';
import { Dialog } from '../../../../../../common';
import { ErrorMessage } from './ErrorMessage';

import * as S from '../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const ValidateFormDialog = observer(({ isOpen, handleClose }: Props) => {
  const { secretsService } = useServices();

  const [secretID, setSecretID] = useState('');
  const [secretVersion, setSecretVersion] = useState('');
  const [secretValue, setSecretValue] = useState('');
  const [currentSecretID, setCurrentSecretID] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSecretID('');
      setSecretVersion('');
      setSecretValue('');
    }
  }, [isOpen]);

  useEffect(() => {
    setCurrentSecretID(secretID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretsService.validateState.state]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    secretsService.validate(secretID, secretVersion, secretValue);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        title="Validate secret"
        testID="validate-form-dialog"
        children={
          <S.Form onSubmit={handleSubmit}>
            <S.DialogContent dividers>
              {secretsService.validateState.isNotFound && (
                <ErrorMessage secretID={currentSecretID}>
                  404: No existing secret could be found for the given
                  parameters.
                </ErrorMessage>
              )}
              {secretsService.validateState.isForbiddenAccess && (
                <ErrorMessage secretID={currentSecretID}>
                  The values provided didn't match any existing secret.
                </ErrorMessage>
              )}
              {secretsService.validateState.hasError && (
                <ErrorMessage secretID={secretID}>
                  {secretsService.validateState.message}
                </ErrorMessage>
              )}
              <FormControl required fullWidth>
                <InputLabel htmlFor="secret-id">Secret ID</InputLabel>
                <Input
                  id="secret-id"
                  autoComplete="off"
                  inputProps={{
                    'data-testid': 'secret-id',
                  }}
                  value={secretID}
                  disabled={secretsService.validateState.isLoading}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSecretID(event.target.value)
                  }
                />
              </FormControl>
              <FormControl required fullWidth>
                <InputLabel htmlFor="secret-version">Secret Version</InputLabel>
                <Input
                  id="secret-version"
                  autoComplete="off"
                  inputProps={{
                    'data-testid': 'secret-version',
                    maxLength: 64,
                    minLength: 32,
                  }}
                  value={secretVersion}
                  disabled={secretsService.validateState.isLoading}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSecretVersion(event.target.value)
                  }
                />
              </FormControl>
              <FormControl required fullWidth>
                <InputLabel htmlFor="secret-value">Secret Value</InputLabel>
                <Input
                  id="secret-value"
                  autoComplete="off"
                  inputProps={{
                    'data-testid': 'secret-value',
                  }}
                  value={secretValue}
                  disabled={secretsService.validateState.isLoading}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSecretValue(event.target.value)
                  }
                />
              </FormControl>
            </S.DialogContent>
            <DialogActions>
              <Button
                disabled={secretsService.validateState.isLoading}
                type="submit"
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </S.Form>
        }
      />
    </>
  );
});
