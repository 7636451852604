import React from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Markdown from 'markdown-to-jsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  AboutField,
  EventTracker,
  TeamReference,
  ToerChip,
  useHoverAndCopy,
} from 'plugin-ui-components';
import { clickLink, clickToerChip } from './tracking';
import { Link } from '@backstage/core-components';
import { collectToer } from 'plugin-core';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';

export function AboutCardMetadata({ entity }: { entity: IEntityApp }) {
  const { spec, metadata } = entity || {};
  const { team_id: teamId } = spec?.spec || {};
  const isActive = spec?.spec?.active;
  const { copyText, hover, handleMouseOut, copyToClipboard, handleHover } =
    useHoverAndCopy();
  const toer = collectToer(entity);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  return (
    <Grid container rowGap={2}>
      <AboutField label="Name" xs={12} sm={6}>
        {spec?.spec?.name ?? spec?.name ?? metadata?.name}
      </AboutField>
      <AboutField label="ID" xs={12} sm={6}>
        {spec?.spec?.id ?? spec?.id ?? metadata?.id}
      </AboutField>
      <AboutField label="Subtitle" xs={12}>
        {spec?.spec?.subtitle ?? 'No subtitle'}
      </AboutField>
      <AboutField label="Description" xs={12}>
        <Markdown>{metadata?.description || 'No description'}</Markdown>
      </AboutField>
      <AboutField label="Owner" xs={12} sm={6}>
        <EventTracker {...clickLink('Owner')}>
          <TeamReference team={teamId} />
        </EventTracker>
      </AboutField>
      <AboutField
        label="URL"
        xs={12}
        sm={6}
        onMouseOver={handleHover}
        onFocus={handleHover}
        onMouseLeave={handleMouseOut}
      >
        {spec?.spec?.service_url ? (
          <>
            <EventTracker {...clickLink('Application URL')}>
              <Link to={spec?.spec?.service_url}>
                {spec?.spec?.service_url}
              </Link>
            </EventTracker>

            {hover && (
              <Tooltip placement="top" title={copyText}>
                <FileCopyIcon
                  onClick={() => copyToClipboard(spec?.spec?.service_url ?? '')}
                  fontSize="small"
                  sx={{
                    fontSize: 14,
                    opacity: 0.5,
                    cursor: 'pointer',
                    marginLeft: 8,
                    '&:hover': { opacity: 0.8 },
                  }}
                />
              </Tooltip>
            )}
          </>
        ) : (
          '-'
        )}
      </AboutField>
      <AboutField label="Criticality" xs={12} sm={6}>
        {spec?.lifecycle}
      </AboutField>
      <AboutField label="Incident Contact" xs={12} sm={6}>
        {spec?.spec?.incident_contact || 'N/A'}
      </AboutField>
      <AboutField label="Toer" xs={12} sm={6}>
        {toer === 'NONE' ? (
          <Tooltip title="No TOER available">
            <Chip
              className="info-chip"
              label="org:NONE"
              size="small"
              variant="outlined"
            />
          </Tooltip>
        ) : (
          <EventTracker {...clickToerChip()}>
            <ToerChip toer={toer} />
          </EventTracker>
        )}
      </AboutField>
      <AboutField label="Status" xs={12} sm={6}>
        <FiberManualRecordIcon
          className="dot-icon about-field"
          data-active={String(isActive)}
        />
        {isActive ? 'Active' : 'Inactive'}
      </AboutField>
      <AboutField label="Last Synced" xs={12} sm={6}>
        {metadata?.modifiedAt
          ? new Date(metadata?.modifiedAt).toISODateString()
          : ''}
      </AboutField>
      {featureFlagsApi.isActive('app-catalog-security-tier') &&
        entity.spec.spec.security_tier && (
          <AboutField label="Security Tier" xs={12} sm={6}>
            Tier {spec.spec.security_tier}
          </AboutField>
        )}
    </Grid>
  );
}
