import React from 'react';
import { Box, Typography } from '@material-ui/core';

export function Item({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <Box>
        <Typography variant="body2" color="inherit">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box>{children}</Box>
    </>
  );
}

export function Grid({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="grid"
      gridTemplateColumns="minmax(100px,auto) 2fr"
      gridTemplateRows="1fr"
      gridGap={15}
      alignItems="center"
    >
      {children}
    </Box>
  );
}
