import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Button } from '@backstage/core-components';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { ParentDomainsTable } from './ParentDomainsTable';
import { SubDomainsTable } from './SubDomainsTable';

export function ConnectedDomains() {
  return (
    <div>
      <Button
        to="#"
        variant="outlined"
        color="primary"
        disabled
        startIcon={<BubbleChartIcon />}
        style={{ marginBottom: '1rem' }}
      >
        View in Graph
      </Button>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper>
            <SubDomainsTable />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <ParentDomainsTable />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
