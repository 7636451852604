import { Theme, styled } from '@material-ui/core';
import { PRIcon } from '../../../../common';

const ELEMENT_LINE_HEIGHT = 2;

export const Title = styled('span')({
  display: 'flex',
  maxWidth: '100%',
});

export const Content = styled('p')({
  margin: 0,

  ['& a:hover']: {
    ['&, & > *']: {
      textDecoration: 'underline',
    },
  },
});

export const Text = styled('span')({
  display: 'inline-block',
  verticalAlign: 'middle',
  lineHeight: ELEMENT_LINE_HEIGHT,
});

export const TextBold = styled(Text)({
  fontWeight: 600,
});

export const TextShrink = styled(Text)({
  flexShrink: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TextLimited = styled(Text)({
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const TextLight = styled(Text)((props: { theme: Theme }) => ({
  margin: 0,
  flexShrink: 0,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: props.theme.palette.text.secondary,

  ['&:hover']: {
    textDecoration: 'underline',
  },
}));

export const Icon = styled(PRIcon)({
  fontSize: 14,
  marginRight: '5px',
  marginLeft: '15px',
  verticalAlign: 'middle',
  lineHeight: ELEMENT_LINE_HEIGHT,
});
