import { makeStyles, type Theme } from '@material-ui/core';

export const iconTitle = {
  marginRight: 6,
  height: 16,
  width: 16,
  color: 'var(--text-secondary)',
};

const useStyles = makeStyles((theme: Theme) => ({
  gutters: {
    paddingLeft: 24,
    paddingRight: 0,
  },
  boldTypography: {
    fontWeight: 700,
  },
  openNewIcon: {
    fontSize: '.8em',
    marginBottom: -1,
    marginLeft: 2,
  },
  hotIcon: {
    marginRight: 6,
    marginLeft: -4,
    color: 'var(--text-secondary)',
  },
  typography: {
    color: 'var(--text-secondary)',
  },
  dialogWidth: { width: 900 },
  modalChip: {
    opacity: '1',
    margin: 0,
    height: 20,
    color: '#000',
    textTransform: 'none',
    marginLeft: 8,
    cursor: 'pointer',
  },
  chipIcon: {
    verticalAlign: 'middle',
    fontSize: '1rem',
    marginTop: -1,
  },
  accordionSummary: {
    backgroundColor: 'var(--clr-error-main)',
    color: '#fff',
    borderRadius: 4,
  },
  expanded: {
    margin: '0!important',
    minHeight: 'auto!important',
  },
  accordionDetails: {
    paddingTop: 24,
  },
  dialogRoot: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default useStyles;
