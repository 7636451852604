import React from 'react';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../services';
import {
  ActionPanelLayout,
  LiveAction,
  EventAction,
  UserPreferencesAction,
} from '../../../common';
import { OrganizationFilter } from './OrganizationFilter';
import { RepositoryFilter } from './RepositoryFilter';
import * as S from './styles';

export const ActionPanel = observer(() => {
  const {
    runGroupsMainPageService: runGroupsService,
    repositoriesMainPageService: repositoriesService,
    filteringMainPageService: filteringService,
    preferencesService,
  } = useServices();

  return (
    <ActionPanelLayout
      filtersChildren={
        <>
          <OrganizationFilter />
          <RepositoryFilter />
          <EventAction filteringService={filteringService} />
        </>
      }
    >
      <S.Actions>
        <LiveAction
          service={runGroupsService}
          isReady={repositoriesService.repositoryTreeState.isLoaded}
        />
        <UserPreferencesAction service={preferencesService} />
      </S.Actions>
    </ActionPanelLayout>
  );
});
