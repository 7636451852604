import {
  CYBERWEEK_FIELDS,
  CYBERWEEK_SYSTEM_EVENT_FIELDS,
} from '../components/CyberWeek';

/**
 * Cyberweek form validation:
 * If any of the first 5 questions
 * are marked as "Yes" or "Not sure",
 * then the user must select at least one "Yes"
 * on the next 3 Event System questions.
 */
export function validateCyberweekStep(review: IAppReview.Review) {
  if (!review.cyberweek_ok) return false;

  const extractValues = (fields: string[]) => {
    const values: string[] = [];
    fields.forEach(field => {
      values.push(review[field]);
    });
    return values;
  };

  const cyberweekValues = extractValues(CYBERWEEK_FIELDS);

  if (cyberweekValues.includes('yes') || cyberweekValues.includes('not_sure')) {
    const cyberweekSystemEventValues = extractValues(
      CYBERWEEK_SYSTEM_EVENT_FIELDS,
    );

    if (!cyberweekSystemEventValues.some(v => v === 'yes')) return false;
  }
  return true;
}
