import {
  NetworkException,
  AbortException,
  NotFoundException,
  BadRequestException,
  ConflictException,
  NotOkException,
  ResourceAccessForbiddenException,
} from './exceptions';
import { SCALYR_400_STATUS } from '../constants';

export const fetchRetry = async (
  url: string,
  options = {},
  retries = 3,
): Promise<Response> => {
  try {
    const response = await fetch(url, options);

    if (response.ok) return response;

    if (retries > 0 && response.status !== 404) {
      return await fetchRetry(url, options, retries - 1);
    }

    return response;
  } catch (err) {
    const error = err as Error;
    if (error?.name === 'AbortError') {
      throw new AbortException('Pending request aborted');
    }
    if (retries === 0) {
      throw new NetworkException('It looks like you are offline');
    }

    return await fetchRetry(url, options, retries - 1);
  }
};

export async function parseResponse(response: Response) {
  const contentType = response.headers.get('content-type');
  let jsonResp;
  switch (true) {
    case response.status === 400:
      jsonResp = await response.json();
      throw new BadRequestException(jsonResp.detail || 'Bad request');
    case response.status === 403:
      throw new ResourceAccessForbiddenException(response.statusText);
    case response.status === 404:
      throw new NotFoundException(response.statusText);
    case response.status === 409:
      throw new ConflictException(response.statusText);
    case !response.ok:
      throw new NotOkException(response.statusText);
    case contentType && contentType.indexOf('application/json') !== -1:
      return await response.json();
    default:
      return await response.text();
  }
}

export async function parseScalyrResponse(response: Response) {
  const contentType = response.headers.get('content-type');

  if (response.status === 400) {
    throw new BadRequestException(response.statusText);
  }

  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = await response.json();

    if (json.status === SCALYR_400_STATUS) {
      throw new BadRequestException(json.message);
    }

    return json;
  }

  return await response.text();
}
