import { computed, observable, action, makeObservable } from 'mobx';
import { RunsMetadataModel } from '../../models';

export enum FetchTypeEnum {
  FirstTime,
  Reload,
  Next,
  Prev,
}

export interface IPaginate {
  getNextPage: () => Promise<void>;
  getPrevPage: () => Promise<void>;
  paginationHelper: PaginationHelper;
}

export interface IPaginationHelper {
  stack: Array<RunsMetadataModel>;
  update: (fetchType: FetchTypeEnum, metadata: RunsMetadataModel) => void;
  getCursor: (fetchType: FetchTypeEnum) => string;
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
}

export class PaginationHelper implements IPaginationHelper {
  stack: Array<RunsMetadataModel> = [];

  constructor() {
    makeObservable(this, {
      stack: observable,
      update: action,
      hasPrevPage: computed,
      hasNextPage: computed,
    });
  }

  update = (fetchType: FetchTypeEnum, metadata: RunsMetadataModel): void => {
    switch (fetchType) {
      case FetchTypeEnum.Next:
        this.stack.push(metadata);
        break;
      case FetchTypeEnum.FirstTime:
        this.stack = [metadata];
        break;
      case FetchTypeEnum.Prev:
        this.stack.pop();
        break;
      case FetchTypeEnum.Reload:
      default:
    }
  };

  getCursor = (fetchType: FetchTypeEnum): string => {
    switch (fetchType) {
      case FetchTypeEnum.Reload:
        return this.currentOrDefault().start_cursor;
      case FetchTypeEnum.Next:
        return this.currentOrDefault().end_cursor;
      case FetchTypeEnum.Prev:
        return this.prevOrDefault().start_cursor;
      case FetchTypeEnum.FirstTime:
      default:
        return '';
    }
  };

  get hasNextPage(): boolean {
    if (this.stack.length > 0) {
      return this.stack[this.stack.length - 1].has_next_page;
    }

    return false;
  }

  get hasPrevPage(): boolean {
    if (this.stack.length > 0) {
      return this.stack[this.stack.length - 1].has_previous_page;
    }

    return false;
  }

  // Internals
  currentOrDefault = (): RunsMetadataModel => {
    return this.stack[this.stack.length - 1] ?? new RunsMetadataModel();
  };

  prevOrDefault = (): RunsMetadataModel => {
    return this.stack[this.stack.length - 2] ?? new RunsMetadataModel();
  };
}
