export interface ValueLabelPair {
  value: number;
  label: string;
}

export const criticalityLevels: ValueLabelPair[] = [
  { value: 1, label: 'Tier 1' },
  { value: 2, label: 'Tier 2' },
  { value: 3, label: 'Tier 3' },
  { value: 4, label: 'Not Relevant' },
];

export const sunriseAdminTeams = [
  '50081172', // Pitchfork
  '50117786', // Developer Productivity
  '50109369', // Builder Infrastructure Product
];

export const SEARCH_DEBOUNCE_TIME = 800;

export const TRACK_SEARCH_TERM_DEBOUNCE_TIME = 3000;

export const SEARCH_TERM_MIN_LENGTH = 3;
