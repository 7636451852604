import { Theme, makeStyles } from '@material-ui/core';

export const useActionStyles = makeStyles<Theme, any>((theme: Theme) => ({
  root: props => ({
    alignSelf: 'flex-end',
    cursor: 'pointer',
    flexShrink: 0,
    width: '30px',
    height: '30px',
    color: props.color ? props.color : theme.palette.text.primary,

    '&:active': {
      transform: 'translateY(3px)',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.2,
    },

    '& svg': {
      width: '18px',
    },
  }),
}));
