import React from 'react';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import { RadioSelect, SectionRadio } from '../common';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ReviewContext } from '../context';

export function ServiceLevelObjectives() {
  const { entity } = useEntity();
  const { review } = React.useContext(ReviewContext);

  return (
    <section>
      <Typography>
        Every production application must have&nbsp;
        <Link to="https://slos.zalando.net/">
          SLOs <OpenInNewIcon />
        </Link>
        &nbsp;defined (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ). SLOs should be established as early as possible in the product life
        cycle. The SLO allows teams to assess engineering and system test effort
        and should have an influence over the design or architecture choices.
        Being transparent and publishing SLOs helps reduce confusion and manage
        expectations across application boundaries. Please select how SLOs are
        documented and tracked for the application "{entity.metadata.name}".
      </Typography>
      <br />

      <RadioSelect
        required={review.slos_ok !== 'n/a'}
        disabled={review.slos_ok === 'n/a'}
        name="slos_critical_business_operation"
      />
      <RadioSelect
        required={review.slos_ok !== 'n/a'}
        disabled={review.slos_ok === 'n/a'}
        name="slos_nobl9"
      />
      <RadioSelect
        required={review.slos_ok !== 'n/a'}
        disabled={review.slos_ok === 'n/a'}
        name="slos_documented"
      />
      <RadioSelect
        required={review.slos_ok !== 'n/a'}
        disabled={review.slos_ok === 'n/a'}
        name="slos_tracked_dashboards"
      />

      <SectionRadio name="slos_ok" />
    </section>
  );
}
