import React, { useState } from 'react';
import { ResourceTable } from '../tables/ResourceTable';
import { Metric, Param } from '../../api/interfaces/metric_param';
import {
  useMetricsColumns,
  useParamsColumns,
} from '../tables/columns/ModelVersions/metrics_params';
import { MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE } from '../../constants/paging';

export const Metrics = ({ metrics }: { metrics: Metric[] }) => {
  const columns = useMetricsColumns();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE,
  );

  return (
    <ResourceTable
      loading={false}
      paginationConfig={{
        setPage: setPage,
        rowsPerPage: rowsPerPage,
        setRowsPerPage: setRowsPerPage,
        page: page || 0,
        total: metrics.length,
      }}
      displayOptions={{
        showTitle: true,
        search: true,
      }}
      columns={columns}
      entity="metric"
      data={metrics as any}
    />
  );
};

export const Params = ({ params }: { params: Param[] }) => {
  const columns = useParamsColumns();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE,
  );

  return (
    <ResourceTable
      loading={false}
      paginationConfig={{
        setPage: setPage,
        rowsPerPage: rowsPerPage,
        setRowsPerPage: setRowsPerPage,
        page: page || 0,
        total: params.length,
      }}
      displayOptions={{
        showTitle: true,
        search: true,
      }}
      columns={columns}
      entity="param"
      data={params as any}
    />
  );
};
