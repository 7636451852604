import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { ApplicationFormContext } from './context';
import { config, TextFieldPropsExtended, FormFields } from './config';

type FormElementProps = TextFieldPropsExtended & {
  name: FormFields;
};

export const Input = ({ name, ...props }: FormElementProps) => {
  const { patternMessage, ...inputConfig } = config[name] || {};
  const { appForm, setForm, disableForm } = React.useContext(
    ApplicationFormContext,
  );

  const setCustomValidity = React.useCallback(
    (ev: React.FormEvent) => {
      const input = ev.target as HTMLInputElement;
      if (input.validity.patternMismatch && patternMessage) {
        input.setCustomValidity(patternMessage);
      }
    },
    [patternMessage],
  );

  const clearCustomValidity = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (ev.target.validity.customError && ev.target.setCustomValidity) {
        ev.target.setCustomValidity('');
      }
    },
    [],
  );

  return (
    <Grid item xs={12} md={6}>
      <TextField
        disabled={disableForm}
        {...props}
        {...inputConfig}
        name={name}
        value={appForm[name] || ''}
        autoComplete="off"
        autoCorrect="off"
        onInvalid={setCustomValidity}
        fullWidth
        margin="dense"
        onChange={ev => {
          ev.persist();
          clearCustomValidity(ev);
          setForm(prevState => ({
            ...prevState,
            [ev.target.name]: ev.target.value,
          }));
        }}
      />
    </Grid>
  );
};
