import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccessControlContext } from './context';

interface UseNavigationBackProps {
  page: keyof IAccessControl.EditState;
  searchParamsKeys: Array<string>;
}

export const useNavigationBack = ({
  page,
  searchParamsKeys,
}: UseNavigationBackProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { editState, setEditState } = React.useContext(AccessControlContext);

  const handleBack = React.useCallback(
    (ev?: React.MouseEvent) => {
      ev?.preventDefault();
      setEditState(state => ({
        ...state,
        [page]: false,
      }));
      setSearchParams({});
    },
    [setEditState, setSearchParams, page],
  );

  React.useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    if (!searchParamsKeys.every(key => params[key]) && editState[page]) {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return handleBack;
};
