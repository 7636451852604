import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  CardContent,
  DialogContentText,
  TextField,
  CardActions,
  Button,
  CircularProgress,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as Tracking from '../DocsAboutCard/tracking';
import { TrackedInteraction } from 'plugin-ui-components';
import { docHostApiRef } from '../../../api';

export interface TableRow {
  description: string;
  docsUrl: string;
  entityToDelete: IEntity;
  id: number;
  name: string;
  ownerLink: string;
  ownerName: string;
  tableData?: { id: number; uuid: string };
}

type EditDocsCategoryDialogProps = {
  entity?: IEntity;
  openEditCategory: boolean;
  setOpenEditCategory: React.Dispatch<React.SetStateAction<boolean>>;
  reload: () => Promise<void>;
};

export const EditDocsCategoryDialog = ({
  entity: entity,
  openEditCategory,
  setOpenEditCategory,
  reload,
}: EditDocsCategoryDialogProps) => {
  const [isCategoryEdited, setIsCategoryEdited] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [category, setCategory] = React.useState('');
  const docHostApi = useApi(docHostApiRef);
  const name = entity?.metadata.name || '';
  const [categoriesList, setCategoriesList] = React.useState<string[]>([]);

  const handleEditCategory = () => {
    setIsLoading(true);
    docHostApi
      .addDocCategory(name, category)
      .then(async isDocCategoryEdited => {
        if (isDocCategoryEdited && entity) {
          setIsCategoryEdited(true);
        } else {
          setError('Error editing document, please retry later');
        }
      })
      .catch(err => setError(err.message))
      .finally(() => {
        setIsLoading(false);
        reload();
      });

    if (error) {
      setOpenEditCategory(false);
    }
  };

  React.useEffect(() => {
    docHostApi
      .getDocCategories()
      .then(categories => {
        setCategoriesList(categories);
      })
      .catch(err => setError(err.message));
  }, [docHostApi, isLoading]);

  return (
    <>
      <Dialog
        open={openEditCategory}
        onClose={() => setOpenEditCategory(false)}
        aria-labelledby="responsive-dialog-title"
        classes={{ paperScrollPaper: 'overflow-visible' }}
      >
        <DialogContent>
          <CardContent>
            <DialogContentText>
              To change the documentation category, please choose from the
              options below.
            </DialogContentText>
            <Autocomplete
              onChange={(_, value) => setCategory(value || '')}
              options={categoriesList}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Choose documentation category"
                  variant="standard"
                  margin="dense"
                  error={false}
                  fullWidth
                />
              )}
            />
            <DialogContent>
              <DialogContentText>
                {error && error}
                {!error &&
                  isCategoryEdited &&
                  `The document ${name} has been edited successfully`}
              </DialogContentText>
            </DialogContent>
          </CardContent>
          <CardActions>
            <TrackedInteraction {...Tracking.confirmEditDocCategory(name)}>
              {isLoading && <CircularProgress size="1.5em" />}
              <Button
                onClick={() => setOpenEditCategory(false)}
                color="default"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleEditCategory}
                color="primary"
                variant="contained"
                disabled={isLoading}
              >
                Save
              </Button>
            </TrackedInteraction>
          </CardActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
