import React from 'react';
import { cronExpressionExtractor } from '../../utils';
import { getDateTimes } from './getDateTimes';

interface cronExpressionProps {
  cronExpression: string;
}

export const ScheduledNotebookNextRunDates = (props: cronExpressionProps) => {
  const pureCronExp = cronExpressionExtractor(props.cronExpression);
  try {
    if (!pureCronExp) return null;
    const nextRunDates = getDateTimes(pureCronExp, new Date());
    const header = 'Next trigger dates in UTC: ';
    const placeholder = (
      <div key="noExecution">
        There are no future executions scheduled for this notebook
      </div>
    );
    const dateMessage = (
      <div>
        {' '}
        {header} <br /> {nextRunDates}
      </div>
    );
    const content = nextRunDates.length ? dateMessage : placeholder;
    return <div>{content}</div>;
  } catch (error) {
    const invalidCronExpression = (
      <div>
        Invalid cron expression, please reschedule the notebook with a valid one
      </div>
    );
    return <div>{invalidCronExpression}</div>;
  }
};
