import React from 'react';
import {
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Divider,
} from '@material-ui/core';
import { config, FormFields } from '../config';
import { ReviewContext } from '../context';
import { Input } from './Input';

interface Props {
  name: FormFields;
}

export function SectionRadio({ name }: Props) {
  const { review, onReviewChange } = React.useContext(ReviewContext);

  const commentsName = name.replace('_ok', '_comments');

  return (
    <FormControl component="fieldset" fullWidth>
      <Divider style={{ marginBlock: '1rem' }} />
      <FormLabel style={{ color: 'inherit' }}>
        {config[name]?.label || ''}
      </FormLabel>
      <RadioGroup onChange={ev => onReviewChange(name, ev.target.value)}>
        <FormControlLabel
          control={<Radio required />}
          label="Yes, this is correct."
          value="yes"
          name={name}
          onChange={_ => onReviewChange(commentsName, undefined)}
          checked={review[name] === 'yes'}
        />
        <FormControlLabel
          control={<Radio required />}
          label="This is not applicable for this application."
          value="n/a"
          name={name}
          checked={review[name] === 'n/a'}
        />
        {review[name] === 'n/a' && (
          <Input
            name={commentsName}
            placeholder="Please provide a rationale"
            value={review[commentsName]}
            onChange={ev => onReviewChange(commentsName, ev.target.value)}
            multiline
            required
          />
        )}
      </RadioGroup>
    </FormControl>
  );
}
