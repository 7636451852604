import React, { useMemo, useRef } from 'react';
import { Model } from '../../api/interfaces/model';
import {
  CodeSnippet,
  Link,
  Table,
  WarningPanel,
  TableColumn,
} from '@backstage/core-components';
import { reachOutLink } from './utils';
import { Box, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { EventTracker } from 'plugin-ui-components';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';
import { ModelVersion } from '../../api/interfaces/model_version';
import { usePluginTheme } from '../../hooks/usePluginTheme';
import { MLTablePagination } from './TableComponents';
import { useNavigate } from 'react-router';

export const tableHeaderStyles = (theme: Theme) => {
  return {
    padding: theme.spacing(1, 2, 1, 2.5),
    borderTop: `1px solid ${theme.palette.grey.A100}`,
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
    color: (theme as Theme).palette.textSubtle,
    fontWeight: theme.typography.fontWeightBold,
    position: 'static',
    wordBreak: 'normal',
  };
};

export type ResourceTableProps = {
  loading: boolean;
  error?: any;
  data?: Model[] | ModelVersion[];
  entity:
    | 'model'
    | 'model version'
    | 'dataset'
    | 'pipeline'
    | 'deployment'
    | 'metric'
    | 'param'
    | 'artifact';
  columns: TableColumn<any>[];
  paginationConfig: {
    rowsPerPageOptions?: number[];
    setRowsPerPage: any;
    rowsPerPage: number;
    page: number;
    setPage: any;
    total: number;
  };
  rowClickConfig?: {
    disableRowClick?: boolean;
    resourceName?: string;
  };
  displayOptions?: {
    showTitle?: boolean;
    customTitle?: string;
    search?: boolean;
    padding?: string | undefined;
  };
  emptyStateOptions?: {
    padding?: string;
    alignItems?: string;
    textAlign?: string;
    display?: string;
    height?: string;
  };
};

const getTableTitle = (entity: string): string => {
  const entityLowerCase = entity.toLowerCase();
  switch (entityLowerCase) {
    case 'model':
      return 'Models';
    case 'pipeline':
      return 'Pipelines';
    case 'dataset':
      return 'Datasets';
    case 'model version':
      return 'Model Versions';
    case 'deployment':
      return 'Deployments';
    case 'metric':
      return 'Metrics';
    case 'param':
      return 'Params';
    default:
      return '';
  }
};

export const EmptyTable: React.FC<{
  error: any;
  entity: string;
  emptyTableStyleProps?: object;
}> = ({ error, entity, emptyTableStyleProps = {} }) => (
  <Box {...emptyTableStyleProps}>
    {error ? (
      <WarningPanel
        severity="error"
        title={`Failed to load ${entity}. Please try again by refreshing the page.`}
      >
        <CodeSnippet language="text" text={error.toString()} />
      </WarningPanel>
    ) : (
      <Typography>
        {`We couldn't find any ${entity}. If you believe this is an error, please reach out to the `}
        <EventTracker interaction="onClick" {...reachOutLink(entity)}>
          <Link
            to="https://github.bus.zalan.do/zai/issues/issues/new"
            color="primary"
          >
            ML Orchestration team
          </Link>
        </EventTracker>
      </Typography>
    )}
  </Box>
);

export const ResourceTable: React.FC<ResourceTableProps> = ({
  error,
  data = [],
  entity,
  columns,
  paginationConfig = {
    rowsPerPage: DEFAULT_PAGE_SIZE,
    page: 0,
    total: 0,
  },
  displayOptions = {
    search: false,
    showTitle: false,
    customTitle: '',
    padding: 'default',
    disableRowClick: false,
  },
  emptyStateOptions = {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '0 400px',
    height: '350px',
  },
  rowClickConfig = {
    disableRowClick: false,
    resourceName: '',
  },
  loading,
}) => {
  const tableRef = useRef<{
    dataManager?: { changePageSize: (size: number) => void };
  }>(null);

  const theme = useTheme();
  const pluginTheme = usePluginTheme();
  const navigate = useNavigate();

  const onRowClickNavigate = (_: any, rowData: any) => {
    if (rowClickConfig.disableRowClick) {
      return;
    }

    switch (entity) {
      case 'model':
        navigate(`/mlp/models/${rowData.model_id}`);
        break;
      case 'model version':
        if (rowClickConfig.resourceName?.length) {
          navigate(
            `/mlp/models/${rowData.model_id}/versions/${rowData.model_version_id}?model=${rowClickConfig.resourceName}`,
          );
          break;
        } else
          navigate(
            `/mlp/models/${rowData.model_id}/versions/${rowData.model_version_id}`,
          );
        break;
      case 'pipeline':
        navigate(`/mlp/pipelines/${rowData.pipeline_id}`);
        break;
      default:
        break;
    }
  };

  useMemo(() => {
    tableRef?.current?.dataManager?.changePageSize(
      paginationConfig.rowsPerPage,
    );
  }, [paginationConfig.rowsPerPage]);

  if (error || (data.length === 0 && !loading)) {
    return (
      <Paper variant="elevation" className="full-width">
        <EmptyTable
          error={error}
          entity={entity}
          emptyTableStyleProps={emptyStateOptions}
        />
      </Paper>
    );
  }

  return (
    <Paper variant="elevation" className="full-width">
      <Table
        tableRef={tableRef}
        title={
          <Box display="flex" alignItems="center">
            <Box style={{ height: '30px' }} boxSizing="inherit">
              <Typography variant="h4">
                {displayOptions.customTitle || getTableTitle(entity)} (
                {paginationConfig.total})
              </Typography>
            </Box>
          </Box>
        }
        onRowClick={onRowClickNavigate}
        options={{
          headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
          search: displayOptions.search,
          paging: !!paginationConfig,
          showTitle: displayOptions.showTitle,
          pageSize: paginationConfig.rowsPerPage,
          padding: 'dense',
          draggable: false,
          emptyRowsWhenPaging: false,
          loadingType: 'linear',
          rowStyle: () => ({
            backgroundColor: pluginTheme === 'dark' ? '#1E1E1E' : '#fff',
            borderBottom: '1px solid #E0E0E0',
          }),
        }}
        isLoading={loading}
        columns={columns}
        data={data as any}
        components={{
          Pagination: props => (
            <MLTablePagination
              props={props}
              page={paginationConfig.page}
              setPage={paginationConfig.setPage}
              rowsPerPage={paginationConfig.rowsPerPage}
              setRowsPerPage={paginationConfig.setRowsPerPage}
              total={paginationConfig.total}
            />
          ),
        }}
      />
    </Paper>
  );
};
