import { ISort, ParentFinder, TreeNode, TreeNodeWithChildren } from './types';

export function buildTree<T>(
  data: TreeNode<T>[],
  parentFinder: ParentFinder<T>,
): TreeNodeWithChildren<T>[] {
  const nodes = new Map<string | number | Symbol, TreeNodeWithChildren<T>>();
  data.forEach(item => {
    nodes.set(item.id, { ...item, children: [] });
  });
  const tree: TreeNodeWithChildren<T>[] = [];
  data.forEach(item => {
    const parent = parentFinder(item, data);
    const current = nodes.get(item.id)!;
    if (parent) {
      nodes.get(parent.id)?.children.push(current);
    } else {
      tree.push(current);
    }
  });

  return tree;
}

export function sortRecursively<T>(
  dataToSort: TreeNodeWithChildren<T>[],
  sortOptions: ISort,
): TreeNodeWithChildren<T>[] {
  const sortField = sortOptions.column.field;
  if (sortField === undefined) return dataToSort;
  let sortFunc = sortOptions.column.sortBy;
  if (!sortFunc) {
    sortFunc = (a, b) => {
      const [aVal, bVal] = [
        getValueOfCompoundName(a, sortField),
        getValueOfCompoundName(b, sortField),
      ];
      const [first, second] =
        sortOptions.order === 'asc' ? [aVal, bVal] : [bVal, aVal];

      const shouldIgnore = (v: any) => v === undefined || !String(v);
      if (shouldIgnore(first)) return sortOptions.order === 'asc' ? 1 : -1;
      if (shouldIgnore(second)) return sortOptions.order === 'asc' ? -1 : 1;

      if (first > second) return 1;
      if (first < second) return -1;
      return 0;
    };
  }
  return [...dataToSort].sort(sortFunc).map(item => {
    if (item.children?.length) {
      return {
        ...item,
        children: sortRecursively(item.children, sortOptions),
      };
    }
    return item;
  });
}

export function getValueOfCompoundName(obj: object, compositeName: string) {
  if (typeof compositeName === 'string') {
    let value = obj as any;
    let arrField = false;
    compositeName.split('.').forEach(field => {
      if (value) {
        if (field === '$') {
          arrField = true;
        } else {
          if (arrField && Array.isArray(value)) {
            value = value.map(i => i[field]);
          } else {
            value = value[field];
          }
          arrField = false;
        }
      }
    });
    return value;
  }
  return undefined;
}

export function paginate<T>(
  data: TreeNodeWithChildren<T>[],
  page: number,
  pageSize: number,
): TreeNodeWithChildren<T>[] {
  if (page === 0) return data.slice(0, pageSize);
  const start = page * pageSize;
  const end = start + pageSize;
  return data.slice(start, end);
}
