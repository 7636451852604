import { createApiRef } from '@backstage/core-plugin-api';
import {
  DashboardResponse,
  DashboardsResponse,
  DashboardVersionsResponse,
} from './types/responses';

export interface DashboardsApi {
  getVersions(
    dashboardId: string,
    pageNumber: number,
  ): Promise<DashboardVersionsResponse>;
  getDashboards(
    projectId: string,
    pageNumber: number,
  ): Promise<DashboardsResponse>;
  getDashboardDetail(dashboardId: string): Promise<DashboardResponse>;
}

export const dashboardsApiRef = createApiRef<DashboardsApi>({
  id: 'dashboards-api',
});
