import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';

interface Props {
  filterLabel: string;
  error: Error;
  retry: () => Promise<void>;
  loading?: boolean;
}

export function TabFilterError({ filterLabel, error, retry, loading }: Props) {
  const styles = useStyles();
  const [retryLoading, setRetryLoading] = useState(false);

  function retryFilter() {
    setRetryLoading(true);
    retry().finally(() => setRetryLoading(false));
  }

  const isLoading = loading || retryLoading;

  return (
    <Accordion classes={{ root: styles.errorAccordionRoot }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: styles.errorSummaryRoot,
          content: styles.errorSummaryContent,
          expandIcon: styles.errorSummaryExpandIcon,
        }}
      >
        <Typography variant="caption">
          Failed to initialize filter: {filterLabel}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gridGap="0.5rem"
        >
          <Typography variant="caption">{error.message}</Typography>
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="primary"
            onClick={retryFilter}
            disabled={isLoading}
            endIcon={
              isLoading && <CircularProgress size="1rem" color="inherit" />
            }
          >
            Retry
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
