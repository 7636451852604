import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import { AccessControlTableCard, RegisterMessage } from '../AccessControlCard';
import { essentialsApiRef } from '../../../../api';
import { ResourceTypesItem } from './ResourceTypesItem';
import { AccessControlContext } from '../../context';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

export function ResourceTypes() {
  const essentialApi = useApi(essentialsApiRef);
  const [_, setSearchParams] = useSearchParams();
  const { appName, hasEditPermission, setEditState } =
    React.useContext(AccessControlContext);

  // Fetch all resoure types that match the application name
  const getResourceTypes = async () => {
    const resources = await essentialApi.getResourceTypes();
    return resources.filter(item => {
      if (item.id) {
        return new RegExp(`^${appName}(?:[_\.]|$)`).test(
          item.id?.toLowerCase(),
        );
      }
      return false;
    });
  };

  const {
    data: resourceTypes,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery(`resourceTypes-${appName}`, getResourceTypes, {
    enabled: !!appName,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <AccessControlTableCard
        trackingPlugin="application-registry"
        isLoading={isLoading || isRefetching}
        isError={isError}
        title="Resource Types"
        data={resourceTypes || []}
        onCreate={() => {
          setEditState(state => ({ ...state, resourceTypeCreatePage: true }));
          setSearchParams({
            createResourceType: 'true',
          });
        }}
        render={app => (
          <ResourceTypesItem
            {...app}
            key={app.id}
            hasEditPermission={hasEditPermission}
            refreshAppResourceTypes={refetch}
          />
        )}
        hasEditPermission={hasEditPermission}
        renderEmpty={
          <Typography>
            This application does not have <strong>Resource Types</strong>{' '}
            registered.{' '}
            <RegisterMessage
              hasEditPermission={hasEditPermission}
              onClick={event => {
                event.preventDefault();
                setEditState(state => ({
                  ...state,
                  resourceTypeCreatePage: true,
                }));
              }}
            />
          </Typography>
        }
      />
    </>
  );
}
