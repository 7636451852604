import React, { useState } from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { StepActionProps } from './types';
import { Text, Tooltip, Dialog } from '../../../../common';

export const SkipAction: React.FC<StepActionProps> = ({
  action,
  actionProps,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  function handleSkipStep() {
    setOpen(false);
    action();
  }

  function openModal() {
    setOpen(true);
  }

  const textDefault =
    'Re-trigger or continue running this pipeline by skipping this step and continuing with the following steps.';
  const textBlocked =
    'The ability to skip this step is blocked in the delivery.yaml (allow_manually_skipping_step)';

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title="Are you sure you want to skip this step and continue with the next step in the pipeline?"
          children={
            <>
              <DialogContent>
                <Text>
                  <span>
                    {`Skipping a step might lead to higher-risk deployments, e.g. because a test
                verification is not run and potential bugs and issues remain undetected.
                Only continue if you know what you are doing and consider the
                consequences. `}
                  </span>

                  <Text info>
                    <a
                      href="https://cloud.docs.zalando.net/concepts/pipelines/#skipping-build-step"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Read more.
                    </a>
                  </Text>
                </Text>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleSkipStep}
                  type="button"
                  variant="outlined"
                  size="small"
                >
                  Skip
                </Button>
              </DialogActions>
            </>
          }
        />
      )}
      <Button
        onClick={openModal}
        disabled={disabled || actionProps.skippingStepDisabled}
        variant="outlined"
        size="small"
      >
        {actionProps.title}
      </Button>

      <Tooltip
        title={actionProps.skippingStepDisabled ? textBlocked : textDefault}
      >
        <InfoIcon fontSize="small" />
      </Tooltip>
    </>
  );
};
