import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  Button,
  ContentHeader,
  Table,
  TableColumn,
} from '@backstage/core-components';
import { useTheme } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { zflowApiRef } from '../../api/zflowApiClient';
import { useNavigate } from 'react-router';
import { GetMLProjectsResponse, UserTeam } from '../../api/definitions';
import {
  FIXED_PAGE_SIZE_OPTIONS,
  PAGE_SIZE,
} from '../../constants/PagingConstants';
import { getProjectListColumns } from '../common/columns/ProjectListColumns';
import { tableHeaderStyles, useTeamFilterBoxBorderStyles } from '../../styles';
import { MLTablePagination } from '../common/TableComponents';
import { notebooksApiRef, dashboardsApiRef } from 'plugin-ml-experimentation';
import {
  PLACEHOLDER_ML_PROJECTS,
  PLACEHOLDER_USER_TEAM,
} from '../../api/PlaceholderResponses';

export const ProjectList = () => {
  const zflowApi = useApi(zflowApiRef);
  const notebooksApi = useApi(notebooksApiRef);
  const dashboardsApi = useApi(dashboardsApiRef);
  const navigate = useNavigate();

  const classes = useTeamFilterBoxBorderStyles();

  const [projects, setProjects] = useState<GetMLProjectsResponse>(
    PLACEHOLDER_ML_PROJECTS,
  );
  const [teamsWithProjects, setTeamsWithProjects] = useState<Array<UserTeam>>();
  const [selectedTeam, setSelectedTeam] = useState<any>(
    localStorage.getItem('selected_team'),
  );
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    zflowApi.getTeamsWithProject().then(setTeamsWithProjects);
  }, [zflowApi]);

  useEffect(() => {
    zflowApi.getUserInfo().then(userInfo => {
      if (!selectedTeam) {
        const teams = userInfo.teams;
        if (teams && teams.length && teams[0].team_id) {
          setSelectedTeam(teams[0].team_id);
        } else {
          throw Error(
            `zflow-api returned invalid userInfo: ${JSON.stringify(userInfo)}`,
          );
        }
      }
    });
  }, [selectedTeam, zflowApi]);

  useEffect(() => {
    if (selectedTeam) {
      const offset = PAGE_SIZE * page;
      zflowApi
        .getProjects(PAGE_SIZE, selectedTeam, offset)
        .then(setProjects)
        .finally(() => setIsLoading(false));
    }
  }, [page, selectedTeam, zflowApi]);

  useEffect(() => {
    setIsLoading(true);
  }, [selectedTeam]);

  const theme = useTheme();

  const columns = getProjectListColumns(
    teamsWithProjects,
    selectedTeam,
    setSelectedTeam,
    classes,
    setPage,
  ) as TableColumn[];

  const getCount = (data: any) => data.meta.total;

  const onRowClickNavigate = async (_: any, rowData: any) => {
    const pipelinesCount = await zflowApi
      .getPipelines(rowData.id, 1)
      .then(getCount);
    if (pipelinesCount) {
      navigate(`/ml/projects/${rowData.id}/pipelines`);
    } else {
      const suffixCounts = [
        {
          suffix: 'notebooks/shared_notebooks',
          count: await notebooksApi
            .getSharedNotebooks(rowData.id, 1)
            .then(getCount),
        },
        {
          suffix: 'notebooks/scheduled_notebooks',
          count: await notebooksApi
            .getScheduledNotebooks(rowData.id, 1)
            .then(getCount),
        },
        {
          suffix: 'notebooks/r_shiny_apps',
          count: await dashboardsApi
            .getDashboards(rowData.id, 1)
            .then(getCount),
        },
      ];
      const suffix = suffixCounts.reduce(
        (acc, cur) => (cur.count > acc.count ? cur : acc),
        { suffix: 'pipelines', count: 0 },
      ).suffix;
      navigate(`/ml/projects/${rowData.id}/${suffix}`);
    }
  };

  useEffect(() => {
    if (selectedTeam === PLACEHOLDER_USER_TEAM.nickname) {
      localStorage.removeItem('selected_team');
    } else if (selectedTeam !== null) {
      localStorage.setItem('selected_team', selectedTeam);
    }
  }, [selectedTeam]);

  return (
    <>
      <ContentHeader
        title="" // empty, because it will display "Unknown Page" otherwise
      >
        <Button color="primary" to="/ml/register">
          <AddCircle style={{ marginRight: 8 }} />
          Register new project
        </Button>
      </ContentHeader>
      <Table
        data={projects.projects}
        columns={columns}
        isLoading={isLoading}
        options={{
          filtering: true,
          search: false,
          draggable: false,
          pageSize: PAGE_SIZE,
          emptyRowsWhenPaging: false,
          headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
        }}
        onRowClick={onRowClickNavigate}
        components={{
          Toolbar: () => <div style={{ height: '0px' }} />,
          Pagination: props => (
            <MLTablePagination
              props={props}
              page={page}
              resource={projects}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
            />
          ),
        }}
      />
    </>
  );
};
