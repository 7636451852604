import { action, makeObservable, observable } from 'mobx';
import { RepositoryFilteringModel } from '../models';
import { PersistHelper, IPersist } from './helpers/persist';

export interface IFilteringService {
  repository: RepositoryFilteringModel;
  events: string[];
  persistHelper: PersistHelper;
  setEvent: (event: string[]) => void;
}

export class FilteringService implements IFilteringService, IPersist {
  // Observables
  repository: RepositoryFilteringModel = new RepositoryFilteringModel();
  events: string[] = [];
  persistHelper: PersistHelper = new PersistHelper();

  constructor() {
    makeObservable(this, {
      repository: observable,
      events: observable,
      persistHelper: observable,
      setEvent: action,
    });
  }

  // Actions
  public setEvent = (value: string[]) => {
    this.events = value;
  };
}
