import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, CircularProgress, Container } from '@material-ui/core';
import { Content } from '@backstage/core-components';
import { useLibraries, NAME, VERSION } from '../../hooks';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Link } from 'react-router-dom';
import { AsyncDataTable } from 'plugin-ui-components';

export const DependenciesTab = () => {
  const pageSize = 10;
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );
  const { entity } = useEntity();
  const [libraryOptions, setLibraryOptions] = useState({
    limit: pageSize,
    application: [entity?.metadata?.name as string],
    name: queryParams.getAll(NAME),
    version: queryParams.getAll(VERSION),
  });
  const [currentPage, setCurrentPage] = useState(0);
  const { libraries, next, prev } = useLibraries(libraryOptions, currentPage);

  interface Library {
    name: string;
    version: string;
  }

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (data: Library) =>
        data ? <Link to={`/path/${data.name}`}>{data.name}</Link> : null,
    },
    {
      title: 'Version',
      field: 'version',
      render: (data: Library) =>
        data ? <Link to={`/path/${data.version}`}>{data.version}</Link> : null,
    },
  ];

  const handlePageChange = (newPage: number) => {
    if (newPage === 0) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: undefined,
      }));
    } else if (currentPage < newPage && next) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: next,
      }));
    } else if (currentPage > newPage && prev) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: prev,
      }));
    }
    setCurrentPage(newPage);
  };

  if (!libraries?.length) {
    return (
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: 100 }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Container>
      <Content>
        <AsyncDataTable<Library>
          title={`${entity?.metadata?.name} dependencies`}
          options={{
            pageSize: pageSize,
            search: false,
            sorting: false,
            padding: 'dense',
          }}
          data={libraries
            .sort((a, b) => b.applications.length - a.applications.length)
            .map((lib, index) => ({ ...lib, id: index }))}
          columns={columns}
          asyncPaginationProps={{
            currentPage,
            hasNextPage: !!next,
            hasPreviousPage: !!prev,
            onPageChange: handlePageChange,
          }}
        />
      </Content>
    </Container>
  );
};
