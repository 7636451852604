import { Link } from '@material-ui/core';
import React from 'react';

export const EditMessage = ({
  hasEditPermission,
  onEdit,
}: {
  hasEditPermission: boolean | undefined;
  onEdit: Function;
}) =>
  hasEditPermission ? (
    <>
      Please{' '}
      <Link
        onClick={event => {
          event.preventDefault();
          onEdit();
        }}
        href="#"
      >
        <strong>edit</strong>
      </Link>{' '}
      to register.
    </>
  ) : (
    <></>
  );

export const RegisterMessage = ({
  hasEditPermission,
  onClick,
}: {
  hasEditPermission: boolean | undefined;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}) =>
  hasEditPermission ? (
    <>
      Please{' '}
      <Link onClick={onClick} href="#">
        <strong>register</strong>
      </Link>
      .
    </>
  ) : (
    <></>
  );
