import React from 'react';
import { Model } from '../../../api/interfaces/model';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ModelCard } from '../../../api/interfaces/model_card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { EditModelForm } from '../../forms/EditModelForm';
import { EditModelCardForm } from '../../forms/EditModelCardForm';
import { useUserTeams, useUserTeamsApps } from 'plugin-ui-components';

export interface EditProps {
  model: Model;
  modelCard: ModelCard;
}

export const EditModel = ({ model, modelCard }: EditProps) => {
  const {
    value: { userTeams = [] },
  } = useUserTeams();
  const { value: userApps = [] } = useUserTeamsApps();

  const modelOwnersTeams = userTeams.filter(team =>
    userApps.some(
      app =>
        app.metadata.name === model.application_id &&
        app.spec.spec.team_id === team.spec.id,
    ),
  );
  const canEdit = modelOwnersTeams.length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion disabled={!canEdit}>
          <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
            <Typography>Model</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <EditModelForm
              name={model.name}
              description={model.description}
              license_type={model.license_type}
              license_link={model.license_link}
              model_id={model.model_id}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <EditModelCardForm modelCard={modelCard} canEdit={canEdit} />
    </Grid>
  );
};
