import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Outlet, useParams } from 'react-router';

import { NotFound, Tabs } from '../../../../common';
import { useServices } from '../../../../services';
import { Header } from '../Header';
import { RunPreview } from '../RunPreview';
import { RuntimeBanner } from '../../../common/RuntimeBanner';
import { getStepProblemsInfo, getTabsComponents } from './utils';

import * as S from './styles';

export const StepOverview = observer(() => {
  const { stepId = '', repo_name } = useParams();
  const { runService, metaService } = useServices();

  useEffect(() => {
    metaService.setTitle(
      `${
        runService.run.build_version
          ? runService.run.build_version
          : runService.run.id
      } | ${repo_name}`,
    );
    runService.setStep(parseInt(stepId, 10));
  }, [metaService, runService, stepId, repo_name]);

  if (runService.stepState.isNotFound) {
    return (
      <NotFound title="Step not found">
        <>
          <span>Step with ID</span>
          <strong>{` ${stepId} `}</strong>
          <span>doesn&apos;t exist. </span>

          <Link to="../../run">Go to pipeline overview.</Link>
        </>
      </NotFound>
    );
  }

  if (runService.stepState.isLoaded) {
    const problemsCount = getStepProblemsInfo(runService.step.run);
    const tabs = getTabsComponents(runService.step.tabs, problemsCount);

    return (
      <>
        <S.PipelineHint>
          <RunPreview />
        </S.PipelineHint>

        <Divider />

        <S.StepOverview>
          <S.Header>
            <Header />
            {runService.step.isOverlayBuild && <RuntimeBanner />}
          </S.Header>

          <S.Content>
            <Tabs>{tabs}</Tabs>
            <Outlet />
          </S.Content>
        </S.StepOverview>
      </>
    );
  }

  return <></>;
});
