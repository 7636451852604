import React from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

export interface StackProps extends BoxProps {
  /** Determines the spacing between the elements in the stack */
  spacing?: string;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
  hstack: {
    flexDirection: 'row',
    gap: (props: StackProps) => (props.spacing ? props.spacing : 0),
  },
  vstack: {
    flexDirection: 'column',
    gap: (props: StackProps) => (props.spacing ? props.spacing : 0),
  },
}));

function HStack(props: StackProps) {
  const classes = useStyles(props);
  return (
    <Box {...props} className={`${classes.wrapper} ${classes.hstack}`}>
      {props.children}
    </Box>
  );
}

function VStack(props: StackProps) {
  const classes = useStyles(props);
  return (
    <Box {...props} className={`${classes.wrapper} ${classes.vstack}`}>
      {props.children}
    </Box>
  );
}

export { HStack, VStack };
