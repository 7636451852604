import React from 'react';
import { ResourceTypeForm } from '../ResourceTypeForm';

export function ResourceTypeEditPage() {
  return (
    <article style={{ padding: '1.5rem 0rem' }}>
      <ResourceTypeForm edit />
    </article>
  );
}
