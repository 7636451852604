import React from 'react';
import { SectionRadio } from '../common';
import { RestApiStats } from './RestApiStats';

export function API({
  hideConfirmationSection,
}: {
  hideConfirmationSection?: boolean;
}) {
  return (
    <section>
      <dl>
        <dt>REST APIs</dt>
        <dd>
          <RestApiStats />
        </dd>
      </dl>

      {!hideConfirmationSection && <SectionRadio name="api_ok" />}
    </section>
  );
}
