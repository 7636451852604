import React, { useCallback, useState } from 'react';
import ChipInput from 'material-ui-chip-input';
import { ProjectForm } from '../../../hooks/useProjectForm';

export const KPIInput = ({
  setForm,
  label,
  projectKPIs,
}: {
  setForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  label: string;
  projectKPIs: string[];
}) => {
  const [kpis, setKPIs] = useState<Array<string>>(projectKPIs);
  const [error, setError] = useState<boolean>(false);

  const handleDeleteChip = useCallback(
    (index: number) => {
      const kpiCopy = Array.from(kpis);
      kpiCopy.splice(index, 1);
      setKPIs(kpiCopy);
      setError(kpiCopy.length === 1);
      setForm(prevState => ({ ...prevState, impacted_kpis: kpiCopy }));
    },
    [kpis, setForm],
  );

  const handleAddChip = useCallback(
    (chip: string) => {
      kpis.push(chip);

      const uniqueChips = Array.from(new Set(kpis));

      setKPIs(uniqueChips);

      setError(kpis.length === 0);

      setForm(prevState => ({ ...prevState, impacted_kpis: uniqueChips }));
    },
    [kpis, setForm],
  );

  const placeholder = 'e.g. Return Rate';

  const helperText =
    'What KPIs is your project working on improving? If multiple, type a KPI and PRESS ENTER.';

  return (
    <ChipInput
      fullWidth
      placeholder={placeholder}
      label={label}
      value={kpis}
      onAdd={chip => handleAddChip(chip)}
      helperText={helperText}
      error={error}
      onDelete={(_, index) => handleDeleteChip(index)}
      data-testid="register-project-chip"
      variant="outlined"
      margin="dense"
      InputProps={{
        id: 'register-project-chip-input',
      }}
    />
  );
};
