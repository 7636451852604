import React from 'react';
import cn from 'classnames';
import { TextField, TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './styles';

export function SearchBar(
  props: TextFieldProps & { hideClearButton?: boolean },
) {
  const styles = useStyles();
  const { hideClearButton, ...rest } = props;
  return (
    <div className={styles.wrapper}>
      <SearchIcon
        fontSize="small"
        color={props.disabled ? 'disabled' : undefined}
        classes={{ root: styles.searchIcon }}
      />
      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        label="Search terms"
        type="search"
        role="searchbox"
        inputMode="search"
        autoComplete="off"
        classes={{ root: styles.inputRoot }}
        inputProps={{
          className: cn({
            'hide-clear-button': hideClearButton,
          }),
        }}
        {...rest}
      />
    </div>
  );
}
