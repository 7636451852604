import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Teams';
const id = 'teams-filter';
export const TeamsFilter = React.memo(() => {
  const { availableTeams, teamsFilter, setTeamsFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl>
      <Autocomplete
        id={id}
        multiple
        options={availableTeams}
        value={teamsFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) => setTeamsFilter(value as string[])}
        renderInput={params => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
