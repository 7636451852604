import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Grid, useTheme } from '@material-ui/core';
import { Content, Table } from '@backstage/core-components';
import filter from 'lodash/filter';
import {
  GetModelMetrics,
  MLModel,
  MLPipelineRunMetricsParams,
} from '../../api/definitions';
import { PLACEHOLDER_ML_METRICS } from '../../api/PlaceholderResponses';
import { tableHeaderStyles, useTeamFilterBoxBorderStyles } from '../../styles';
import { zflowApiRef } from '../../api/zflowApiClient';
import { DEFAULT_SELECT_OPTION } from '../../constants/common';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { getModelMetricsColumns } from '../common/columns/ModelMetricColumns';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

const formatPerformanceMetrics = (
  performanceMetrics: MLPipelineRunMetricsParams[],
) => {
  return performanceMetrics.map(performanceMetric => {
    if (!performanceMetric.key && performanceMetric.type) {
      performanceMetric.key = performanceMetric.type;
    }
    return performanceMetric;
  });
};

export const ModelMetrics = ({
  model,
  trainingJobIds,
}: {
  model: MLModel;
  trainingJobIds: string[] | undefined;
}) => {
  const zflowApi = useApi(zflowApiRef);
  const theme = useTheme();
  const classes = useTeamFilterBoxBorderStyles();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedFilter, setSelectedFilter] = useState<{
    selectedMetricKey: string | undefined;
    selectedTrainingJob: string | undefined;
  }>();

  const [tableData, setTableData] = useState<{ metrics: GetModelMetrics }>({
    metrics: PLACEHOLDER_ML_METRICS,
  });

  const filterMetrics = useCallback(
    (metrics: MLPipelineRunMetricsParams[]) => {
      const filterConditions: any = {};

      if (
        selectedFilter?.selectedMetricKey &&
        selectedFilter?.selectedMetricKey !== DEFAULT_SELECT_OPTION
      ) {
        filterConditions.key = selectedFilter.selectedMetricKey;
      }

      if (
        selectedFilter?.selectedTrainingJob &&
        selectedFilter?.selectedTrainingJob !== DEFAULT_SELECT_OPTION
      ) {
        filterConditions.training_job_id = selectedFilter.selectedTrainingJob;
      }

      return filter(metrics, { ...filterConditions });
    },
    [selectedFilter?.selectedMetricKey, selectedFilter?.selectedTrainingJob],
  );

  const setUpMetrics = useCallback(
    (metricsResponse: GetModelMetrics) => {
      const metricsTableData: any = {};
      if (model.quantitative_analysis?.performance_metrics) {
        const performanceMetrics = formatPerformanceMetrics(
          model.quantitative_analysis.performance_metrics,
        );

        metricsResponse.metrics =
          metricsResponse.metrics.concat(performanceMetrics);
        metricsResponse.meta = {
          total: metricsResponse?.meta?.total + performanceMetrics.length,
        };
      }
      if (!selectedFilter) {
        metricsTableData.metrics = metricsResponse;
      } else {
        const filteredMetrics = filterMetrics(metricsResponse.metrics);
        metricsTableData.metrics = {
          meta: {
            total: metricsResponse.meta.total,
          },
          metrics: filteredMetrics,
        };
      }

      setIsLoading(false);
      setTableData(metricsTableData);
    },
    [
      filterMetrics,
      model?.quantitative_analysis?.performance_metrics,
      selectedFilter,
    ],
  );

  useEffect(() => {
    const offset: number = PAGE_SIZE * page;
    zflowApi
      .getModelMetrics(
        model.run_id,
        PAGE_SIZE,
        trainingJobIds,
        selectedFilter?.selectedMetricKey,
        offset,
      )
      .then(setUpMetrics);
  }, [
    zflowApi,
    trainingJobIds,
    page,
    model.run_id,
    setUpMetrics,
    selectedFilter?.selectedMetricKey,
  ]);

  useEffect(() => {
    setIsLoading(true);
  }, [selectedFilter]);

  const columns = getModelMetricsColumns(
    tableData.metrics.metrics,
    selectedFilter,
    setSelectedFilter,
    classes,
  );

  return (
    <Content>
      <Grid>
        {tableData?.metrics.metrics.length ? (
          <Table
            data={tableData.metrics.metrics}
            columns={columns}
            isLoading={isLoading}
            options={{
              pageSize: PAGE_SIZE,
              filtering: true,
              search: false,
              padding: 'dense',
              showFirstLastPageButtons: true,
              emptyRowsWhenPaging: false,
              headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
            }}
            components={{
              Pagination: props => (
                <MLTablePagination
                  props={props}
                  page={page}
                  resource={tableData.metrics}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
                />
              ),
              Toolbar: props => <MLTableToolbar props={props} />,
            }}
          />
        ) : (
          <MissingFieldPlaceholder field="metric" type="model-registry" />
        )}
      </Grid>
    </Content>
  );
};
