import React from 'react';
import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { colors } from 'plugin-ui-components';
import { TagSmall } from 'plugin-cdp';

type Props = {
  success: boolean;
  children: React.ReactNode;
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  icon: {
    display: 'flex',
    paddingRight: '5px',

    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
});

export function Status({ success, children }: Props) {
  const classes = useStyles();

  return (
    <TagSmall
      color={success ? colors.semantic.successBase : colors.semantic.errorBase}
    >
      <span className={classes.content}>
        <span className={classes.icon}>
          {success ? <CheckCircleIcon /> : <CancelIcon />}
        </span>
        {children}
      </span>
    </TagSmall>
  );
}
