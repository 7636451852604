import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
  const baseMenuItemStyles = {
    fontSize: theme.typography.body2.fontSize,
    '& > div:first-child': {
      minWidth: '36px',
    },
  };
  const ITEM_HEIGHT = 300;
  return {
    scopesWrapper: {
      marginLeft: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
    },
    menu: {
      '&::-webkit-scrollbar': {
        width: '8px',
        appearance: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: 'rgba(0,0,0,.5)',
      },
      overflowY: 'scroll',
      overscrollBehavior: 'none',
      height: '100%',
    },
    menuPaper: {
      maxHeight: ITEM_HEIGHT,
    },
    menuItem: baseMenuItemStyles,
    menuItemNoHoverEffect: {
      ...baseMenuItemStyles,
      paddingLeft: '0.5rem',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    mediumBold: {
      fontWeight: 500,
    },
    textCenter: {
      textAlign: 'center',
    },
    divider: {
      backgroundColor: theme.palette.action.hover,
    },
    description: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listIcon: {
      paddingLeft: '0.5rem',
    },
  };
});
