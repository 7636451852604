import React from 'react';
import { TextFieldFilter } from './FilterTypes';

const label = 'Resource Name';
const id = 'resource-filter';

export const ResourceNameFilter = React.memo(
  ({
    onChange,
    selected,
    loading,
  }: {
    onChange?: (value: string) => void;
    selected?: string;
    placeholder?: string;
    loading: boolean;
  }) => {
    return (
      <TextFieldFilter
        id={id}
        label={label}
        onChange={onChange}
        selected={selected}
        placeholder="Search by resource name"
        disabled={loading}
      />
    );
  },
);
