import { createStyles, TableCell, Theme, withStyles } from '@material-ui/core';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.text.primary,
      wordBreak: 'keep-all',
    },
  }),
)(TableCell);
