import unescape from 'lodash/unescape';
import type {
  ESSearchResponse,
  GCSSearchResponse,
  GlobalSearchFilter,
} from './types';
import { searchConfig } from './config';
import { cloudsearch_v1 } from '@googleapis/cloudsearch';

/** Returns the default filters for the Sunrise search */
export function getDefaultFilters(tab: string) {
  const currentTabIndex = searchConfig.indexes.findIndex(i =>
    i.isMatchingQuery(tab),
  );
  const defaultFilters: GlobalSearchFilter[] = [];
  searchConfig.indexes[currentTabIndex].filters?.forEach(filter => {
    if (filter.defaultValue?.length) {
      defaultFilters.push({
        indexId: filter.indexId,
        field: filter.field,
        value: filter.defaultValue.map(v => v.value),
      });
    }
  });
  return defaultFilters?.length ? defaultFilters : undefined;
}

/**
 * Returns the total number of results from the search query
 * @param response The response coming from either elasticsearch or GCS
 */
export function getResultTotal(
  response: ESSearchResponse | GCSSearchResponse,
): number {
  if (response.hasOwnProperty('hits')) {
    return getESResultsTotal(response as ESSearchResponse);
  }
  return getGCSResultsTotal(response as GCSSearchResponse);
}

/**
 * Returns the total number of results from the elasticsearch query
 * @param response The response coming from elasticsearch
 */
export function getESResultsTotal(response: ESSearchResponse): number {
  if (typeof response.total === 'number') {
    return response.total;
  }
  return response.total?.value ?? 0;
}

/**
 * Returns the total number of results from the GCS query
 * @param response The response coming from GCS
 */
export function getGCSResultsTotal(response: GCSSearchResponse): number {
  const total = response.resultCountExact ?? response.resultCountEstimate ?? 0;
  return +total;
}

/**
 * Returns the current page based on the offset and limit
 * @param offset Number of results to skip
 * @param limit Number of results per page
 */
export function getSearchPage(offset: number, limit: number): number {
  if (offset === 0) return 1;
  return Math.floor(offset / limit) + 1;
}

/**
 * Returns the offset based on the current page and limit
 * @param page Current page
 * @param limit Number of results per page
 */
export function exportPageToOffset(page: number, limit: number): number {
  if (page === 1) return 0;
  return (page - 1) * limit;
}

/**
 * Returns the available number of pages based on the offset and limit
 * @param total Total number of results
 * @param limit Number of results per page
 */
export function getPaginationCount(total: number, limit: number): number {
  return Math.ceil(total / limit);
}

export function getGCSFieldTextValue(
  field: GCSObjectValues,
): string | string[] | boolean {
  if (field.textValues?.values?.length) {
    return field.textValues?.values?.length > 1
      ? field.textValues.values
      : field.textValues.values[0];
  }
  if (field.integerValues?.values?.length) {
    return String(field.integerValues.values[0]);
  }
  if (field.booleanValue !== undefined && field.booleanValue !== null) {
    return field.booleanValue;
  }
  if (field.integerValues?.values?.length) {
    return String(field.integerValues.values[0]);
  }
  if (field.doubleValues?.values?.length) {
    return String(field.doubleValues.values[0]);
  }
  if (field.dateValues?.values?.length) {
    return String(field.dateValues.values[0]);
  }
  if (field.timestampValues?.values?.length) {
    return String(field.timestampValues.values[0]);
  }
  return field.name ?? '';
}

export function extractGCSFields<T = any>(
  hit: cloudsearch_v1.Schema$SearchResult,
): T {
  const fields = hit?.metadata?.fields;
  if (!fields) return {} as T;
  return fields.reduce((acc, field) => {
    if (field.name) {
      acc[field.name as keyof T] = getGCSFieldTextValue(field) as any;
    }
    return acc;
  }, {} as T);
}

export function formatGCSSnippet(text: string) {
  const parsed = unescape(text?.replaceAll('¶', '').replaceAll('&nbsp;', ' '));
  return parsed.endsWith('...') ? parsed : `${parsed}...`;
}

export function extractURLFromGoogleReferralURL(url?: string | null): string {
  if (!url) return '';
  if (url.startsWith('https://www.google.com/url')) {
    const queryUrl = new URLSearchParams(url).get('url');
    if (queryUrl) return queryUrl;
  }
  return url;
}

export function formatIndexLabelToID(label: string): string {
  return label.toLowerCase().replace(/ /g, '-');
}

export function getGheUrl(
  orgOrRepo: string,
  issueNumber?: number,
  isPull?: boolean,
) {
  const gheBaseUrl = 'https://github.bus.zalan.do';
  const urlParts = [gheBaseUrl, orgOrRepo];
  if (issueNumber) {
    const type = isPull ? 'pull' : 'issues';
    urlParts.push(type, issueNumber.toString());
  }
  return urlParts.join('/');
}
