import React from 'react';
import { Tooltip, styled } from '@material-ui/core';
import { getColorByStatus } from '../../../utils/pipeline';

type Props = {
  status: string;
  dense?: boolean;
};

const StyledStatus = styled('div')(
  ({ color, dense }: { color: string; dense?: boolean }) => ({
    cursor: 'help',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '10px',
    color: 'transparent',
    background: `${color}`,

    ...(dense && {
      left: '8px',
      height: '10px',
      top: 'calc(8px + 10px / 2)',
      borderRadius: '100%',
    }),
  }),
);

export function Status({ status, dense }: Props) {
  const color = getColorByStatus(status);

  return (
    <Tooltip title={status}>
      <StyledStatus color={color} dense={dense} />
    </Tooltip>
  );
}
