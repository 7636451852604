import React from 'react';
import { useAsync } from 'react-use';
import { LogoIcon } from '../Logo';

const zalandoInternalUrls = ['zalandoprod.service-now.com', 'zalando.net'];

interface Props {
  url: string;
}

export function URLIcon({ url }: Props) {
  const { value } = useAsync(() => {
    return new Promise(async resolve => {
      if (url.startsWith('http')) {
        const origin = new URL(url).origin;
        if (!!zalandoInternalUrls.find(item => url.includes(item))) {
          // Internal Zalando Site
          resolve('/zalando-favicon.ico');
        } else {
          // External site
          const imgUrl = `https://www.google.com/s2/favicons?domain=${origin}&sz=32`;
          resolve(imgUrl);
        }
      } else {
        // Sunrise relative path
        resolve('');
      }
    });
  }, [url]);

  return value ? <img src={value} alt="favicon" /> : <LogoIcon />;
}
