import React, { useEffect } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ALERT_DURATION } from './constants';
import { AlerterMessage } from './AlerterMessage';

export function Content() {
  const { enqueueSnackbar } = useSnackbar();
  const alertApi = useApi(alertApiRef);

  useEffect(() => {
    let unmounted = false;
    const sub = alertApi.alert$()?.subscribe(msg => {
      if (unmounted) return;
      enqueueSnackbar(msg.message, {
        variant: msg.severity,
        autoHideDuration: msg.display !== 'permanent' ? ALERT_DURATION : null,
      });
    });

    return () => {
      unmounted = true;
      sub?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function Alerter() {
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      Components={{
        success: AlerterMessage,
        error: AlerterMessage,
        warning: AlerterMessage,
        info: AlerterMessage,
        default: AlerterMessage,
      }}
    >
      <Content />
    </SnackbarProvider>
  );
}
