import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { HttpClient } from '../httpClient';
import { ApplicationResources, CatalogMeta } from '../interfaces/meta';
import applicationResourceQuery from '../graphql/application_resources';
import metaQuery from '../graphql/meta';
import { InputError } from '@backstage/errors';

export interface ApplicationResourceApi {
  getApplicationResources(applicationId: string): Promise<ApplicationResources>;
}

export class ApplicationResourceService implements ApplicationResourceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getMeta(): Promise<{ meta: CatalogMeta }> {
    return this.httpClient
      .post('/graphql', {
        operationName: 'Meta',
        query: metaQuery,
      })
      .then(response => {
        if (response.data.errors && !response.data.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the totals information.',
          );
        }
        return response.data;
      });
  }

  async getApplicationResources(
    applicationId: string,
  ): Promise<ApplicationResources> {
    const meta = await this.getMeta();
    const totals = meta.meta.totals;
    const maxTotal = Math.max(totals.models, totals.datasets, totals.pipelines);
    const variables = { application_id: applicationId, limit: maxTotal };

    return this.httpClient
      .post('/graphql', {
        operationName: 'ApplicationResources',
        query: applicationResourceQuery,
        variables,
      })
      .then(response => {
        if (response.data && !response.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the application data information.',
          );
        }
        return {
          models: response.data.models,
          pipelines: response.data.pipelines,
          datasets: response.data.datasets,
        } as ApplicationResources;
      });
  }
}

export const applicationResourceApiRef = createApiRef<ApplicationResourceApi>({
  id: 'ml-platform-application-resource-api',
});
