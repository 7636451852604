import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CircularProgress, FormHelperText } from '@material-ui/core';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { Issue } from '../common';
import { ReviewContext } from '../context';

export function LoadTestStats() {
  const { entity } = useEntity();
  const api = useApi(applicationReviewApiRef);
  const { issues } = React.useContext(ReviewContext);

  const { loading, value } = useAsync(
    () => api.getLastLoadTest(entity.metadata.name),
    [entity],
  );

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  const issue = issues.find(i => i.field === 'load_test_info');

  if (!value || !value?.hasLoadTest) {
    return (
      <>
        The application "{entity?.metadata?.name}" have not (yet) been load
        tested according to the&nbsp;
        <Link
          target="_blank"
          to="/catalog/default/api/load-test-reporting-13-0.1.0/definition"
        >
          Load Test Reporting API <OpenInNewIcon />
        </Link>
        .
        <FormHelperText>
          This information could be cached or outdated
        </FormHelperText>
        <Issue {...issue} />
      </>
    );
  }

  const lastLoadTest = new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(value.lastLoadTest));

  return (
    <>
      <time dateTime={value.lastLoadTest}>{lastLoadTest}</time>
      <FormHelperText>
        This information could be cached or outdated
      </FormHelperText>
      <Issue {...issue} />
    </>
  );
}
