export function setPendingUpdates(key: string): void {
  if (localStorage.getItem('pendingUpdates')) {
    const pending = JSON.parse(localStorage.getItem('pendingUpdates') || '{}');
    pending[key] = true;
    localStorage.setItem('pendingUpdates', JSON.stringify(pending));
  } else {
    localStorage.setItem('pendingUpdates', JSON.stringify({ [key]: true }));
  }
}

export function removePendingUpdates(key: string): void {
  if (localStorage.getItem('pendingUpdates')) {
    const pending = JSON.parse(localStorage.getItem('pendingUpdates') || '{}');
    delete pending[key];
    if (Object.keys(pending).length === 0) {
      localStorage.removeItem('pendingUpdates');
    } else {
      localStorage.setItem('pendingUpdates', JSON.stringify(pending));
    }
  }
}

export function hasPendingUpdates(key?: string): boolean {
  const pending = localStorage.getItem('pendingUpdates');
  if (key && pending) {
    const pendingKey = JSON.parse(pending)[key];
    return pendingKey ? true : false;
  }
  return pending ? true : false;
}

// Maps the query params with the appropriate page (create/edit...) to be displayed
export const mapQueryParamWithPage = (
  params: IAccessControl.StateQueryParams,
) => {
  const paramMapping = {
    resourceId: [
      {
        condition: params.scopeCreate,
        state: { resourceTypeScopeCreatePage: true },
      },
      { condition: params.scopeId, state: { resourceTypeScopeEditPage: true } },
      { condition: true, state: { resourceTypeEditPage: true } },
    ],
    editScopes: { appScopes: true },
    createResourceType: { resourceTypeCreatePage: true },
    editKubeClusters: { kubernetesClusters: true },
    editOAuthClient: { oAuthClient: true },
    editCredentialDistribution: { credentialDistribution: true },
  };

  for (const key in paramMapping) {
    if (params[key as keyof typeof paramMapping]) {
      const stateChanges = paramMapping[key as keyof typeof paramMapping];
      if (Array.isArray(stateChanges)) {
        for (const change of stateChanges) {
          if (change.condition) {
            return { hasEdit: true, page: change.state };
          }
        }
      } else {
        return { hasEdit: true, page: stateChanges };
      }
    }
  }

  return { hasEdit: undefined, page: {} };
};
