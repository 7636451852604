import React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { useServices } from '../../../../../services';
import { OrganizationModel } from '../../../../../models';

export const OrganizationFilter = observer(() => {
  const {
    repositoriesMainPageService: repositoriesService,
    filteringMainPageService: filteringService,
  } = useServices();

  const organizationsToOptions = (organizations: Array<OrganizationModel>) =>
    organizations.map(item => ({
      label: item.name,
      value: item.fullPath,
      group: item.domain,
    }));

  const optionsToOrganizations = (organizations: Array<IOption>) =>
    organizations.map(
      item => new OrganizationModel(item.group as string, item.label),
    );

  const getOptionSelected = (option: IOption, value: IOption) => {
    return option.value === value.value;
  };

  const handleChange = (
    selectedOptions: Array<{ label: string; value: string }>,
  ) => {
    filteringService.persistHelper.enablePersist();
    filteringService.repository.setUserSelectedOrganizations(
      optionsToOrganizations(selectedOptions),
    );
  };

  return (
    <AutoComplete
      onChange={handleChange}
      isLoading={repositoriesService.repositoryTreeState.isLoading}
      hasError={repositoriesService.repositoryTreeState.hasError}
      options={organizationsToOptions(
        filteringService.repository.organizations,
      )}
      defaultSelection={organizationsToOptions(
        filteringService.repository.userSelectedOrganizations,
      )}
      getOptionSelected={getOptionSelected}
      shouldGroup
      uniqueId="organizations-for-domain"
      placeholder="Organizations"
      noOptionsText="No Organizations"
      noOptionsErrorText="Error occurred while fetching organizations"
      inputPlaceholder="Filter organizations"
    />
  );
});
