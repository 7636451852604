import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { Content, Link, Table } from '@backstage/core-components';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { tableHeaderStyles } from '../../styles';
import { MLTableToolbar } from '../common/TableComponents';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';
import { MLModelData } from '../../api/definitions';

const getDatasetInfo = (dataset: MLModelData | undefined) => {
  const datasetInfo = [
    {
      set: 'Train',
      ...dataset?.train,
    },
  ];

  if (dataset?.eval) {
    datasetInfo.push({
      set: 'Eval',
      ...dataset?.eval,
    });
  }

  return datasetInfo;
};

export const ModelDataset = ({
  model_data,
}: {
  model_data: MLModelData | undefined;
}) => {
  const theme = useTheme();

  const datasetInfo = getDatasetInfo(model_data) || null;

  const columns = [
    {
      title: 'Set',
      field: 'set',
      filtering: false,
    },
    {
      title: 'Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Link',
      field: 'link',
      filtering: false,
      render(row: any): React.ReactNode {
        return <>{row.link ? <Link to={row.link}>{row.link}</Link> : null}</>;
      },
    },
    {
      title: 'Sensitive',
      field: 'sensitive',
      filtering: false,
      render(row: any): React.ReactNode {
        return (
          <Typography variant="subtitle2">
            {row.sensitive ? 'Yes' : 'No'}
          </Typography>
        );
      },
    },
  ];

  return (
    <Content>
      <Grid>
        {model_data ? (
          <Table
            data={datasetInfo}
            columns={columns}
            options={{
              pageSize: PAGE_SIZE,
              search: true,
              filtering: false,
              padding: 'dense',
              showFirstLastPageButtons: true,
              emptyRowsWhenPaging: false,
              pageSizeOptions: FIXED_PAGE_SIZE_OPTIONS,
              headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
            }}
            components={{
              Toolbar: props => <MLTableToolbar props={props} />,
            }}
          />
        ) : (
          <MissingFieldPlaceholder field="model data" type="model-card" />
        )}
      </Grid>
    </Content>
  );
};
