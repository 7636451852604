import React from 'react';
import { Link } from '@backstage/core-components';
import { Chip, ChipProps, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/ReportProblem';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  entity: IEntityApp;
}

export const ReviewChip = React.memo(({ entity }: Props) => {
  const reviewLink = `/catalog/default/component/${entity.metadata.name}/review`;
  const props: Partial<ChipProps> = { size: 'small', variant: 'outlined' };
  let status: IAppReview.ReviewStatus = 'reviewed';

  if (!entity.metadata?.lastReview?.reviewer) {
    status = 'never';
    return (
      <Tooltip title="This application has never been reviewed. Click to start reviewing">
        <Link to={reviewLink} color="textPrimary">
          <Chip
            {...props}
            className="info-chip error"
            label={
              <>
                <WarningIcon />
                {status}
              </>
            }
            clickable
          />
        </Link>
      </Tooltip>
    );
  }

  if (entity.metadata.lastReview.needsReview) {
    status = 'needs review';
    return (
      <Tooltip title="This applications needs to be reviewed. Click to start reviewing">
        <Link to={reviewLink} color="textPrimary">
          <Chip
            {...props}
            className="info-chip warning"
            label={
              <>
                <InfoOutlinedIcon />
                {status}
              </>
            }
            clickable
          />
        </Link>
      </Tooltip>
    );
  }

  const { lastReview } = entity.metadata;

  if (lastReview) {
    return (
      <Link to={reviewLink} color="textPrimary">
        <Chip
          {...props}
          clickable
          className="info-chip success"
          label={
            <>
              <CheckCircleIcon />
              {status}
            </>
          }
        />
      </Link>
    );
  }

  return (
    <Chip
      {...props}
      className="info-chip success"
      label={
        <>
          <CheckCircleIcon />
          {status}
        </>
      }
    />
  );
});
