import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@material-ui/core';
import { useStyles } from '../styles';
import { getHistogramData } from './utils';
import { prettifyTime } from '../../../utils/time';
import { ChartTitle } from '../components/ChartTitle';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  data?: ICDPMonitoring.PipelinePerformanceTimeseries;
  loading: boolean;
}

export function PerformanceOverTime({ data, loading }: Props) {
  const styles = useStyles();

  return (
    <Card elevation={3} className={styles.card}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <CardHeader
        title={
          <ChartTitle
            text={`Average pipeline execution time by ${data?.granularity}`}
          />
        }
      />
      <CardContent>
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: item =>
                    prettifyTime(Number(item.dataset.data[item.dataIndex])),
                },
              },
            },
          }}
          data={getHistogramData(data?.items)}
        />
      </CardContent>
    </Card>
  );
}
