import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, DialogContent, DialogActions, Link } from '@material-ui/core';
import { useServices } from '../../../../../../services';
import { Dialog, Well } from '../../../../../../common';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleValidateAnother: () => void;
}

export const ValidateSuccessDialog = observer(
  ({ isOpen, handleClose, handleValidateAnother }: Props) => {
    const { secretsService } = useServices();

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        title="Success!"
        testID="validate-success-dialog"
        children={
          <>
            <DialogContent dividers>
              Your secret has been validated successfully! See how to use
              secrets in{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://sunrise.zalando.net/docs/default/Documentation/cloud/concepts/builds/#secrets"
              >
                delivery.yaml
              </Link>
              <Well>{JSON.stringify(secretsService.secret, null, 2)}</Well>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleValidateAnother}
                data-testid="validate-another-button"
              >
                Validate Another Secret
              </Button>
            </DialogActions>
          </>
        }
      />
    );
  },
);
