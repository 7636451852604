import { IWeek } from './week.interface';

export const BANNERS: Array<IWeek> = [
  {
    name: 'Week. 42 (starts 2023-10-16)',
    from: '2023-10-16T00:00:00',
    to: '2023-10-22T23:59:59',
    arch: {
      pre: 'Amber',
      current: 'Amber',
      post: 'Green',
    },
    major: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    minor: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    patch: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
  },
  {
    name: 'Week. 43 (starts 2023-10-23)',
    from: '2023-10-23T00:00:00',
    to: '2023-10-29T23:59:59',
    arch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    patch: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
  },
  {
    name: 'Week. 44 (starts 2023-10-30)',
    from: '2023-10-30T00:00:00',
    to: '2023-11-07T23:59:59',
    arch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Amber',
      current: 'Green',
      post: 'Green',
    },
    patch: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
  },
  {
    name: 'Week. 45 (starts 2023-11-06)',
    from: '2023-11-06T00:00:00',
    to: '2023-11-12T23:59:59',
    arch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Amber',
      current: 'Amber',
      post: 'Green',
    },
    patch: {
      pre: 'Amber',
      current: 'Green',
      post: 'Green',
    },
  },
  {
    name: 'Week. 46 (starts 2023-11-13)',
    from: '2023-11-13T00:00:00',
    to: '2023-11-19T23:59:59',
    arch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Red',
      current: 'Red',
      post: 'Amber',
    },
    patch: {
      pre: 'Amber',
      current: 'Amber',
      post: 'Green',
    },
  },
  {
    name: 'Week. 47 (starts 2023-11-20)',
    from: '2023-11-20T00:00:00',
    to: '2023-11-26T23:59:59',
    arch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
    patch: {
      pre: 'Red',
      current: 'Red',
      post: 'Red',
    },
  },
  {
    name: 'Week. 48 (starts 2023-11-27)',
    from: '2023-11-27T00:00:00',
    to: '2023-12-03T23:59:59',
    arch: {
      pre: 'Green',
      current: 'Red',
      post: 'Red',
    },
    major: {
      pre: 'Green',
      current: 'Red',
      post: 'Red',
    },
    minor: {
      pre: 'Green',
      current: 'Red',
      post: 'Red',
    },
    patch: {
      pre: 'Green',
      current: 'Red',
      post: 'Red',
    },
  },
  {
    name: 'Week. 49 (starts 2023-12-04)',
    from: '2023-12-04T00:00:00',
    to: '2023-12-10T23:59:59',
    arch: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    major: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    minor: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
    patch: {
      pre: 'Green',
      current: 'Green',
      post: 'Green',
    },
  },
];
