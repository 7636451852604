import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Input as MuiInput,
  InputLabel,
  FormControl,
  Checkbox as MuiCheckbox,
  NativeSelect,
  FormControlLabel,
} from '@material-ui/core';

import { GenericFieldModel } from '../model';

export interface FieldProps {
  field: GenericFieldModel;
  onChange: (field: GenericFieldModel, value: string | boolean) => void;
  onKeyPress: React.KeyboardEventHandler<any>;
}

export const Input = observer(({ field, onChange, onKeyPress }: FieldProps) => {
  const [currentValue, setCurrentValue] = React.useState(field.value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    onChange(field, e.target.value);
  };

  return (
    <FormControl error={!field.isValid} fullWidth>
      <InputLabel id={`${field.name}-label`}>{field.name}</InputLabel>
      <MuiInput
        id={field.name}
        value={currentValue}
        key={field.name}
        onChange={handleChange}
        onKeyPress={onKeyPress}
      />
    </FormControl>
  );
});

export const Checkbox = observer(
  ({ field, onChange, onKeyPress }: FieldProps) => {
    const [currentValue, setCurrentValue] = React.useState(field.value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.target.checked);
      onChange(field, e.target.checked);
    };

    return (
      <FormControl error={!field.isValid} fullWidth>
        <FormControlLabel
          control={
            <MuiCheckbox
              id={field.name}
              checked={currentValue as boolean}
              key={field.name}
              onChange={handleChange}
              onKeyPress={onKeyPress}
            />
          }
          label={field.name}
        />
      </FormControl>
    );
  },
);

export const Select = observer(
  ({ field, onChange, onKeyPress }: FieldProps) => {
    const [currentValue, setCurrentValue] = React.useState(field.value);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setCurrentValue(event.target.value as string);
      onChange(field, event.target.value as string);
    };

    return (
      <FormControl error={!field.isValid} fullWidth>
        <InputLabel id={`${field.name}-label`}>{field.name}</InputLabel>
        <NativeSelect
          id={field.name}
          aria-labelledby={`${field.name}-label`}
          value={currentValue}
          key={field.name}
          onChange={handleChange}
          onKeyPress={onKeyPress}
        >
          {field.options &&
            field.options.map(option => (
              <option value={option}>{option}</option>
            ))}
        </NativeSelect>
      </FormControl>
    );
  },
);
