import { RunStatus, RunStepStatus } from '../api/definitions';

export const RunStatusColors = {
  [RunStatus.Aborted]: '#e589b3',
  [RunStatus.Failed]: '#df868a',
  [RunStatus.Initialized]: '#BB94F5',
  [RunStatus.NotStarted]: '#CCCCCC',
  [RunStatus.Running]: '#86C7E0',
  [RunStepStatus.SUCCESS]: '#85DD9C',
  [RunStatus.Succeeded]: '#85DD9C',
  [RunStatus.TimedOut]: '#DEB685',
  [RunStatus.Unknown]: '#DEB685',
  Default: '#EBEBEB',
};
