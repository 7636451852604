import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  configApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ZalandoIdentityApiRef, oauth2ApiRef } from 'plugin-core';
import {
  ApplicationReviewApi,
  applicationReviewApiRef,
} from './api/ApplicationReviewApi';
import { productivityApiRef, ProductivityApi } from './api/ProductivityApi';

export const rootRouteRef = createRouteRef({ id: 'start-page' });

export const plugin = createPlugin({
  id: 'start-page',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: applicationReviewApiRef,
      deps: {
        catalogApi: catalogApiRef,
        userApi: ZalandoIdentityApiRef,
        oauth2Api: oauth2ApiRef,
        configApi: configApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ catalogApi, userApi, oauth2Api, configApi, discoveryApi }) =>
        new ApplicationReviewApi({
          catalogApi,
          userApi,
          oauth2Api,
          configApi,
          discoveryApi,
        }),
    }),
    createApiFactory({
      api: productivityApiRef,
      deps: {
        catalogApi: catalogApiRef,
        userApi: ZalandoIdentityApiRef,
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ catalogApi, userApi, oauth2api, discoveryApi }) =>
        new ProductivityApi({
          catalogApi: catalogApi,
          userApi: userApi,
          oauth2Api: oauth2api,
          discoveryApi: discoveryApi,
        }),
    }),
  ],
});

export const StartPage = plugin.provide(
  createRoutableExtension({
    name: 'plugin-start-page',
    component: () => import('./components/StartPage').then(m => m.StartPage),
    mountPoint: rootRouteRef,
  }),
);
