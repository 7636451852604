import React from 'react';
import { Link } from '@backstage/core-components';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IlloSvgUrl from './illo.svg';

const useStyles = makeStyles(theme => ({
  notFoundImage: {
    maxWidth: '80%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      alignSelf: 'start',
    },
  },
}));

export const EntityNotFound = () => {
  const styles = useStyles();
  return (
    <Box
      display="grid"
      alignItems="center"
      gridColumn="span 3"
      gridRow="span 4"
      gridTemplateColumns="repeat(auto-fit, minmax(360px, 1fr))"
    >
      <Box textAlign="center">
        <Typography variant="h5">Entity not found :(</Typography>
        <Typography>
          Did you check if there's a <strong>typo</strong> in the entity's name?
        </Typography>
        <br />
        <Typography>
          If this is not the case, try{' '}
          <Link to="/search">
            <strong>searching for it</strong>
          </Link>{' '}
          in Sunrise.
        </Typography>
      </Box>
      <img src={IlloSvgUrl} className={styles.notFoundImage} alt="" />
    </Box>
  );
};
