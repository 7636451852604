export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
};

export const savingScope = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Creating new scope in access control',
  eventAction: `New scope is being saved`,
});

export const editingScope = (name: string): ITrackingEvent => ({
  ...common,
  eventLabel: 'Editing scope in access control',
  eventAction: `Scope '${name}' is being updated`,
});

export const cancelScope = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Cancel action on scope',
  eventAction: `Cancel button for scope (edit/save) clicked`,
});
