import { action, makeObservable, observable, runInAction } from 'mobx';
import { RepositoryModel } from '../models';
import { State } from './helpers/state';
import { CDP_ANNOTATION } from '../constants';
import { Entity } from '@backstage/catalog-model';

type EntityRepository = {
  source: string;
  url: string | null;
};

export interface IApplicationService {
  repositoriesState: State;
  repositories: Array<RepositoryModel>;
  extract: () => void;
}

export class ApplicationService implements IApplicationService {
  // Observables
  repositoriesState: State;
  repositories: Array<RepositoryModel>;

  constructor(private entity: Entity) {
    this.repositoriesState = new State();
    this.repositories = [];

    makeObservable(this, {
      repositoriesState: observable,
      repositories: observable,
      extract: action,
    });
  }

  // Actions
  extract(): void {
    if (!this.repositoriesState.isLoaded) {
      this.repositoriesState.setLoading();

      try {
        const annotation =
          this.entity.metadata.annotations?.[CDP_ANNOTATION] ?? '';
        const entityRepositories: Array<EntityRepository> =
          JSON.parse(annotation) ?? [];

        runInAction(() => {
          this.repositories =
            entityRepositories.reduce(
              (acc: Array<RepositoryModel>, entityRepo: EntityRepository) => {
                if (!entityRepo.url) return acc;
                const repository = RepositoryModel.fromUrl(entityRepo.url);

                return [...acc, repository];
              },
              [],
            ) ?? [];
        });

        this.repositoriesState.setLoaded();
      } catch (e) {
        this.repositoriesState.setError();
      }
    }
  }
}
