/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export interface Currency {
  kind: string | null;
  label: string;
  unit: string;
  prefix?: string;
  rate?: number;
}

export enum CurrencyType {
  USD = 'USD',
  CarbonOffsetTons = 'CARBON_OFFSET_TONS',
  Beers = 'BEERS',
  IceCream = 'PINTS_OF_ICE_CREAM',
}
