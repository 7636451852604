import { getRunTooltipTitle } from './getRunTooltipTitle';

export function getTooltipTitle(
  executionEnabled: boolean,
  gotoEnabledPipeline: boolean,
  gotoEnabledStep: boolean,
): string {
  if (!executionEnabled) {
    return getRunTooltipTitle(executionEnabled);
  } else if (!gotoEnabledPipeline) {
    return 'Set `should_enable_goto=True` to use this feature';
  } else if (!gotoEnabledStep) {
    return 'You cannot start the pipeline from this step.';
  }
  return '';
}
