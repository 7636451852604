import {
  SearchQuery,
  SearchResult,
  SearchResultSet,
} from '@backstage/plugin-search-common';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import debounce from 'lodash/debounce';
import { SEARCH_DEBOUNCE_TIME } from 'plugin-core';
import { PluginTracking } from 'plugin-ui-components';

export interface SearchApi {
  query(query: SearchQuery): Promise<SearchResultSet>;
}

type USResult = {
  metadata: any;
  snippet: {
    snippet: string;
  };
  title: string;
  url: string;
};

const sendSearchQueryToGA = debounce(
  PluginTracking.sendSearchQuery,
  SEARCH_DEBOUNCE_TIME,
);

export class SearchClient implements SearchApi {
  oauth2Api: OAuthApi;
  discoveryApi: DiscoveryApi;

  constructor(discoveryApi: DiscoveryApi, oauth2Api: OAuthApi) {
    this.discoveryApi = discoveryApi;
    this.oauth2Api = oauth2Api;
  }

  map(result: USResult): SearchResult {
    return {
      type: 'docs',
      document: {
        title: result.title,
        text: result.snippet.snippet,
        location: this.formatLocation(result),
      },
    } as SearchResult;
  }

  async query(query: SearchQuery): Promise<SearchResultSet> {
    if (!query.term.trim()) return { results: [], nextPageCursor: '' };
    sendSearchQueryToGA(query.term);

    const backendPluginUrl = await this.discoveryApi.getBaseUrl('search');
    const endpoint = '/gcs';
    const url = `${backendPluginUrl}${endpoint}`;
    const token = await this.oauth2Api.getAccessToken();

    const requestBody = this.generateRequestBody(query);

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    const allMaps: SearchResult[] = [];

    data.results.forEach((result: any) => {
      allMaps.push(this.map(result));
    });

    return {
      results: allMaps,
      nextPageCursor: '',
      previousPageCursor: ' ',
    };
  }

  generateRequestBody(query: SearchQuery) {
    return {
      query: query.term,
      indexes: [
        {
          name: 'zdocs',
          filters: [
            {
              objectType: 'page',
              filter: {
                valueFilter: {
                  operatorName: 'domain',
                  value: {
                    stringValue: query.filters?.name,
                  },
                },
              },
            },
          ],
        },
      ],
    };
  }

  formatLocation(result: USResult) {
    return result.metadata.fields.filter(
      (field: any) => field.name === 'filepath',
    )[0].textValues.values[0];
  }
}
