export const sanitizePackageName = (packageName: string) => {
  if (!packageName) return '';
  return packageName?.replace('github.com/', '');
};

export const removeDuplicates = <T>(strings?: Array<T>) => {
  if (!strings) return [];
  const uniqueStrings = new Set(strings);

  return Array.from(uniqueStrings);
};
