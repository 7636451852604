export async function http<T>(url: string, request: RequestInit): Promise<T> {
  const response = await fetch(url, request);
  if (!response.ok) {
    throw Error(await response.text());
  }
  const res = await response.text();
  if (res.length) {
    return JSON.parse(res);
  }
  return JSON.parse('{}');
}
