import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing(1.5),
    color: 'white',
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
  },
}));

export function Breadcrumbs({
  list,
}: {
  list: { name: React.ReactNode; to: string }[];
}) {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {list.map((item, index) => {
        if (index === list.length - 1)
          return (
            <li key={index}>
              <strong>{item.name}</strong>
            </li>
          );
        return (
          <li key={index}>
            <Link to={item.to}>{item.name}</Link>&nbsp;•&nbsp;
          </li>
        );
      })}
    </ul>
  );
}
