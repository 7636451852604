export function getTableResolvedReviewStatus(
  entity: IEntityApp | undefined,
): string {
  if (!entity) {
    return '';
  }
  if (!entity.metadata?.lastReview?.reviewer) {
    return 'never';
  }
  if (entity.metadata.lastReview.needsReview) {
    return 'needs review';
  }
  return 'reviewed';
}
