import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import * as S from '../styles';

interface Props {
  secretID: string;
  children: React.ReactNode;
}

export const ErrorMessage = observer(({ secretID, children }: Props) => {
  return (
    <S.ErrorWrapper>
      <Box display="flex" alignItems="center">
        <ErrorIcon color="error" />
        <Typography color="error">
          "{secretID}" failed to create or update:
        </Typography>
      </Box>
      <S.ErrorDescription>{children}</S.ErrorDescription>
    </S.ErrorWrapper>
  );
});
