import { computed, observable, action, makeObservable } from 'mobx';

enum PollingEnum {
  Live,
  Offline,
  Hold,
}

export interface IPoll {
  pollingState: PollingState;
  reload: () => Promise<void>;
  togglePolling?: () => void;
}

export class PollingState {
  currentState: PollingEnum;
  prevState: PollingEnum;
  abortController: AbortController = new AbortController();
  consecutiveFailedPolls: number;

  constructor() {
    this.currentState = PollingEnum.Live;
    this.prevState = PollingEnum.Live;
    this.consecutiveFailedPolls = 0;

    makeObservable(this, {
      currentState: observable,
      prevState: observable,
      consecutiveFailedPolls: observable,
      hold: action,
      resume: action,
      start: action,
      stop: action,
      toggle: action,
      incrementConsecutiveFailedPolls: action,
      resetConsecutiveFailedPolls: action,
      isLive: computed,
      isOnHold: computed,
      isCurrentlyLive: computed,
    });
  }

  hold = (): void => {
    if (this.currentState !== PollingEnum.Hold) {
      this.prevState = this.currentState;
      this.currentState = PollingEnum.Hold;
    }

    this.abortController.abort();
    this.abortController = new AbortController();
  };

  resume = (): void => {
    this.currentState = this.prevState;
  };

  start = (): void => {
    this.currentState = PollingEnum.Live;
    this.prevState = PollingEnum.Live;
    this.consecutiveFailedPolls = 0;
  };

  stop = (): void => {
    this.currentState = PollingEnum.Offline;
    this.prevState = PollingEnum.Offline;

    this.abortController.abort();
    this.abortController = new AbortController();
  };

  toggle = (): void => {
    if (this.isLive) {
      this.stop();
    } else {
      this.start();
    }
  };

  incrementConsecutiveFailedPolls = (): void => {
    this.consecutiveFailedPolls = this.consecutiveFailedPolls + 1;
  };

  resetConsecutiveFailedPolls = (): void => {
    this.consecutiveFailedPolls = 0;
  };

  public get isLive() {
    if (
      this.currentState === PollingEnum.Live ||
      (this.currentState === PollingEnum.Hold &&
        this.prevState === PollingEnum.Live)
    ) {
      return true;
    }

    return false;
  }

  public get isOnHold() {
    return this.currentState === PollingEnum.Hold;
  }

  public get isCurrentlyLive() {
    return this.currentState === PollingEnum.Live;
  }
}
