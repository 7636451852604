import React from 'react';
import { SearchConfigForGCS } from '../models';
import { AccentColors } from '../constants/AccentColors';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { GoogleDriveIcon } from 'plugin-ui-components';
import { formatIndexLabelToID } from '../utils';

const label = 'Google Drive';
const id = formatIndexLabelToID(label);
export const googleDriveIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'GOOGLE_DRIVE',
  isPredefinedSource: true,
  icon: GoogleDriveIcon,
  accentColor: AccentColors.Default,
  getUrl: hit => hit.url,
  getTitle: hit => hit.title,
  render: hit => <HighlightedGCSTexts snippet={hit.snippet} />,
});
