import React from 'react';
import { Link } from '@backstage/core-components';
import { IconButton, Tooltip } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import cn from 'classnames';
import { PluginTracking } from 'plugin-ui-components';

interface Props {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  to: string;
  disabled?: boolean;
  tooltip: string;
}

export function SocialButton({ Icon, disabled, to, tooltip }: Props) {
  const onSocialLinkClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    const { href } = ev.currentTarget;
    let type = 'unknown';
    if (href.startsWith('tel:')) type = 'phone';
    if (href.startsWith('mailto:')) type = 'email';
    if (href.includes('github')) type = 'github';

    PluginTracking.sendEvent({
      plugin: 'app',
      eventLabel: `User clicked on a social link in user reference card (type: ${type})`,
      eventCategory: 'User reference card',
      eventAction: 'click',
    });
  };

  return (
    <Tooltip title={tooltip}>
      <Link
        to={to}
        target="_blank"
        component={IconButton}
        className={cn({ 'Mui-disabled': disabled })}
        onClick={onSocialLinkClick}
      >
        <Icon fontSize="small" />
      </Link>
    </Tooltip>
  );
}
