import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Type';
const id = 'type-filter';
export const TypesFilter = React.memo(() => {
  const { availableTypes, typeFilter, setTypeFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        value={typeFilter}
        onChange={ev => setTypeFilter(String(ev.target.value))}
        label={label}
        labelId={id}
        classes={{ select }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {availableTypes.map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
