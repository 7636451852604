import React, { useState, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { EmptyState, Progress } from '@backstage/core-components';
import { notebookRendererApiRef } from '../../api';
import { Box, makeStyles } from '@material-ui/core';
import { NotebookContentResponse } from '../../api/types/responses/notebookContent';

interface NotebookSnapshotProps {
  s3Path: string;
  shouldHideCode?: boolean;
}

const useStyle = makeStyles({
  notebook_iframe: {
    display: 'block',
    border: 'none',
    height: '100vh',
    width: '100%',
  },
  empty_state: {
    fontWeight: 'lighter',
  },
});

export const NotebookSnapshot = (props: NotebookSnapshotProps) => {
  const { s3Path, shouldHideCode } = props;
  const [loading, setLoading] = useState(false);

  const notebookRendererClient = useApi(notebookRendererApiRef);

  const classes = useStyle();
  const [notebook, setNotebook] = useState<NotebookContentResponse>();

  useEffect(() => {
    setLoading(true);
    notebookRendererClient
      .getNotebookContent(s3Path, !!shouldHideCode)
      .then(setNotebook)
      .catch(() => setNotebook({ notebook_content: '' }))
      .finally(() => setLoading(false));
  }, [s3Path, shouldHideCode, notebookRendererClient]);

  return s3Path ? (
    <Box component="div" width={1}>
      {loading ? <Progress /> : null}
      <iframe
        id="notebookSnapshot"
        allowFullScreen
        title="notebook_snapshot"
        className={classes.notebook_iframe}
        srcDoc={notebook?.notebook_content}
      />
    </Box>
  ) : (
    <Box component="div" width={1} padding={3}>
      <EmptyState
        title=""
        missing="data"
        description="No content to display!"
      />
    </Box>
  );
};
