import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),

    '& > div': {
      borderRadius: '4px',
      border: `1px solid ${theme.palette.info.main}`,
    },

    '& a': {
      paddingTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },

    '& [class*=MuiChip-root]': {
      borderColor: `var(--info-chip-accent-clr, ${theme.palette.grey.A200})`,
      margin: 0,

      '&.error': {
        '--info-chip-accent-clr': theme.palette.error.main,
        color: theme.palette.common.white,
      },
      '&.warning': {
        '--info-chip-accent-clr': theme.palette.warning.light,
        color: theme.palette.common.black,
      },
      '&.success': {
        '--info-chip-accent-clr': theme.palette.success.main,
        color: theme.palette.common.black,
      },
      '&.filled': {
        backgroundColor: 'var(--info-chip-accent-clr, black)',
        '& span': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  },
  tag: {
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: '12px',
    borderRadius: '4px',
    padding: theme.spacing(0, 0.5),
    textTransform: 'uppercase',
  },
  icon: {
    verticalAlign: 'sub',
    fontSize: '1rem',
  },
  header: {
    backgroundColor: theme.palette.action.hover,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.info.main,

    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  title: {
    fontWeight: 'bold',
    padding: theme.spacing(0, 1),
  },
  details: {
    flexDirection: 'column',
  },
  table: {
    borderCollapse: 'collapse',

    '& thead': {
      '& th': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
      },
    },
    '& tbody': {
      '& td': {
        padding: theme.spacing(1.5, 0),
        textAlign: 'center',
        width: '20%',
      },
      '& tr': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '& th': {
        textAlign: 'left',
      },
    },
  },
}));
