import React, { RefObject, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { LogsLine } from '../Line';
import { LogsLineModel } from '../../../../../../models';

type Props = {
  lines: Array<LogsLineModel>;
  scrollableRef: RefObject<HTMLElement>;
};

export const LogsLines = observer(({ lines, scrollableRef }: Props) => {
  const atBottom = useRef(true);

  const tryToScrollDown = () => {
    if (scrollableRef.current && atBottom.current) {
      scrollableRef.current.scroll({
        top: scrollableRef.current.scrollHeight,
      });
    }
  };

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.onscroll = () => {
        if (scrollableRef.current) {
          const { scrollHeight, offsetHeight, scrollTop } =
            scrollableRef.current;
          atBottom.current = scrollHeight - (offsetHeight + scrollTop) < 5;
        }
      };

      tryToScrollDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines.length]);

  return (
    <ol>
      {lines.map((line, lineIndex) => (
        <LogsLine key={`line-${lineIndex}`} index={lineIndex + 1} line={line} />
      ))}
    </ol>
  );
});
