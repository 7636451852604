import React from 'react';
import { getColorByStatus } from '../../../utils/pipeline';

import * as S from './styles';

type Props = {
  status: string;
  title: string | React.ReactNode;
  actions: React.ReactNode;
  details: string | React.ReactNode;
};

export function DetailsHeader({ status, title, actions, details }: Props) {
  return (
    <S.Header>
      <S.Heading>
        <S.Tag variant="contained" color={getColorByStatus(status)}>
          {status}
        </S.Tag>

        <S.Title>{title}</S.Title>
      </S.Heading>

      <S.SubHeading>{details}</S.SubHeading>

      <S.Actions>{actions}</S.Actions>
    </S.Header>
  );
}
