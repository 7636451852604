import React from 'react';
import { Container, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApplicationAboutCard } from '../EntityPage/ApplicationAboutCard';
import { EntityProvider } from '@backstage/plugin-catalog-react';
import { transformKioAppIntoEntity } from 'plugin-core';

interface Props {
  loading: boolean;
  kioApp?: IKioAppApiResponse;
}

export function KioEntityPage({ loading, kioApp }: Props) {
  const [entity, setEntity] = React.useState<IEntityApp>(
    transformKioAppIntoEntity(kioApp),
  );

  React.useEffect(() => {
    setEntity(transformKioAppIntoEntity(kioApp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kioApp?.id]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Container style={{ padding: '1rem', gridArea: 'pageContent' }}>
      <Alert severity="info" style={{ marginBottom: '1rem' }}>
        This application is not in Sunrise's database and is fetched directly
        from KIO since it has been inactive for longer than 6 months.
      </Alert>

      <EntityProvider entity={entity}>
        <ApplicationAboutCard />
      </EntityProvider>
    </Container>
  );
}
