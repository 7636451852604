import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { HStack } from 'plugin-ui-components';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';

interface SingleStepPagerProps {
  offset: number;
  setOffset: (offset: number) => void;
  limit: number;
  hasMoreResults: boolean;
}

export const SingleStepPager = ({
  offset,
  limit,
  setOffset,
  hasMoreResults,
}: SingleStepPagerProps) => {
  const page = Math.ceil((offset + 1) / limit);
  return (
    <HStack spacing="1.5rem" alignItems="center">
      <Box>
        <IconButton
          onClick={() => setOffset(0)}
          style={{ display: page > 2 ? 'inline-block' : 'none' }}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          disabled={!offset}
          onClick={() => setOffset(offset - limit)}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Box>
      <Typography variant="body2">{page}</Typography>
      <Box>
        <IconButton
          onClick={() => setOffset(offset + limit)}
          disabled={!hasMoreResults}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </HStack>
  );
};
