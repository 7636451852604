import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { AboutField, InfoCard } from 'plugin-ui-components';
import { UpdateProjectDialog } from './UpdateProjectDialog';
import { ArchiveProject } from './ArchiveProject';
import { v4 as uuidv4 } from 'uuid';
import { TeamProfileLink, UserProfileLink } from '../common/ProfileLinks';
import { UserTeam } from '../../api/definitions';

export const ProjectDetails = ({
  projectId,
  owner,
  admin,
  kpis,
  description,
  createdBy,
  archived,
  projectTeam,
}: {
  projectId: string;
  owner: boolean | undefined;
  admin: boolean | undefined;
  kpis: Array<string> | undefined;
  description: string | undefined;
  createdBy: string | undefined;
  archived: boolean | undefined;
  projectTeam: UserTeam | undefined;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(true);
  };

  const [refreshPage, setRefreshPage] = useState<boolean>(false);

  useEffect(() => {
    if (refreshPage) window.location.reload();
  }, [refreshPage]);

  return (
    <Grid container spacing={3}>
      <UpdateProjectDialog
        projectId={projectId}
        open={open}
        setOpen={setOpen}
        setRefreshPage={setRefreshPage}
        projectTeam={projectTeam}
        description={description}
        impacted_kpis={kpis}
        name={projectId}
        admin={admin}
      />

      <Grid item xs={12}>
        <InfoCard
          title={`${projectId}`}
          cardHeaderProps={{
            action: (
              <IconButton
                aria-label="Edit"
                title="Edit Project"
                disabled={!(owner || admin) || archived}
                onClick={handleClick}
                data-testid="project-details-edit-field"
              >
                <EditIcon />
              </IconButton>
            ),
          }}
        >
          <CardContent>
            <Grid container spacing={3}>
              <AboutField
                label="Project ID"
                children={projectId}
                key={uuidv4()}
                xs={12}
                sm={6}
                lg={4}
                data-testid="project-details-project-id-field"
              />
              <AboutField
                key={uuidv4()}
                label="Created By"
                children={
                  createdBy && createdBy !== 'jdoe' ? (
                    <UserProfileLink userId={createdBy} />
                  ) : (
                    createdBy
                  )
                }
                xs={12}
                sm={6}
                lg={4}
                data-testid="project-details-created-by-field"
              />
              <AboutField
                key={uuidv4()}
                label="Team"
                xs={12}
                sm={6}
                lg={4}
                data-testid="project-details-team-field"
              >
                <TeamProfileLink team={projectTeam} />
              </AboutField>
              <AboutField
                label="Description"
                xs={12}
                sm={6}
                lg={4}
                key={uuidv4()}
                data-testid="project-details-description-field"
              >
                <Typography
                  variant="body2"
                  paragraph
                  sx={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                >
                  {description || 'No description'}
                </Typography>
              </AboutField>
              <AboutField
                label="Impacted KPIs"
                key={uuidv4()}
                children={
                  kpis && (
                    <Grid container spacing={1}>
                      {kpis.map(kpi => (
                        <Grid item key={uuidv4()}>
                          <Chip label={kpi} />
                        </Grid>
                      ))}
                    </Grid>
                  )
                }
                xs={12}
                sm={6}
                lg={4}
                data-testid="project-details-kpi-field"
              />
              <AboutField
                key={uuidv4()}
                label={archived ? 'Archived' : 'Archive'}
                children={
                  <>
                    <Typography variant="subtitle2">
                      <ArchiveProject
                        projectId={projectId}
                        owner={owner || admin}
                        archived={archived}
                        setRefreshPage={setRefreshPage}
                      />
                    </Typography>
                  </>
                }
                xs={12}
                sm={6}
                lg={4}
                data-testid="project-details-archive-field"
              />
            </Grid>
          </CardContent>
        </InfoCard>
      </Grid>
    </Grid>
  );
};
