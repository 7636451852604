import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
  Cost,
  Entity,
  MetricData,
  Project,
  TrustedAdvisorData,
  CostChange,
} from 'plugin-cloud-cost-insights';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { PersonalizationApi } from 'plugin-core';
import { CatalogApi } from '@backstage/catalog-client';

export class CostInsightsClient implements CostInsightsApi {
  oauth2Api: OAuthApi;
  personalizationApi: PersonalizationApi;
  catalogApi: CatalogApi;
  discoveryApi: DiscoveryApi;

  constructor(
    oauth2Api: OAuthApi,
    personalizationApi: PersonalizationApi,
    catalogApi: CatalogApi,
    discoveryApi: DiscoveryApi,
  ) {
    this.oauth2Api = oauth2Api;
    this.personalizationApi = personalizationApi;
    this.catalogApi = catalogApi;
    this.discoveryApi = discoveryApi;
  }

  async getLastCompleteBillingDate(): Promise<string> {
    return new Date().toISODateString('short'); // YYYY-MM-DD
  }

  async getGroupProjects(_group: string): Promise<Project[]> {
    return [];
  }

  async getAlerts(_group: string): Promise<Alert[]> {
    return [];
  }

  async getDailyMetricData(
    _metric: string,
    _intervals: string,
  ): Promise<MetricData> {
    return {
      id: 'metric-data',
      format: 'number',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getGroupDailyCost(
    group: string,
    intervals: string,
    mode: string,
  ): Promise<Cost> {
    const backendPluginUrl = await this.discoveryApi.getBaseUrl('cloud-costs');
    const token = await this.oauth2Api.getAccessToken();
    const Authorization = `Bearer ${token}`;
    if (mode === 'kubernetes' || mode === 'stups') {
      const response = await fetch(
        `${backendPluginUrl}/costs/${group}/aws?intervals=${intervals}&mode=${mode}`,
        {
          headers: { Authorization },
        },
      );
      const data = await response.json();
      return {
        id: 'daily-cost',
        aggregation: data.aggregation,
        trendline: data.trendline,
        change: data.change,
        groupedCosts: data.groupedCosts,
        account: data.account,
      };
    }

    if (mode === 'nakadi') {
      const response = await fetch(
        `${backendPluginUrl}/costs/${group}/nakadi?intervals=${intervals}`,
        {
          headers: { Authorization },
        },
      );
      const data = await response.json();
      return {
        id: 'nakadi-cost',
        aggregation: data.aggregation,
        trendline: data.trendline,
      };
    }

    if (mode === 'scalyr') {
      const response = await fetch(
        `${backendPluginUrl}/costs/${group}/scalyr?intervals=${intervals}`,
        {
          headers: { Authorization },
        },
      );
      const data = await response.json();
      return {
        id: 'scalyr-cost',
        aggregation: data.aggregation,
        trendline: data.trendline,
      };
    }

    const response = await fetch(
      `${backendPluginUrl}/costs/${group}/total?intervals=${intervals}`,
      {
        headers: { Authorization },
      },
    );
    const data = await response.json();
    return {
      id: 'total-cost',
      aggregation: data.aggregation,
      trendline: data.trendline,
      costBreakDown: data.costBreakDown,
      account: data.account,
    };
  }

  async getProjectDailyCost(
    _project: string,
    _intervals: string,
  ): Promise<Cost> {
    return {
      id: 'project-cost',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    const backendPluginUrl = await this.discoveryApi.getBaseUrl('cloud-costs');
    const token = await this.oauth2Api.getAccessToken();
    const Authorization = `Bearer ${token}`;
    const response = await fetch(
      `${backendPluginUrl}/costs/${options.group}/${options.product}?intervals=${options.intervals}`,
      {
        headers: { Authorization },
      },
    );
    const data = await response.json();
    return data;
  }

  async getTrustedAdvisorData(group: string): Promise<TrustedAdvisorData> {
    const backendPluginUrl = await this.discoveryApi.getBaseUrl('cloud-costs');
    const token = await this.oauth2Api.getAccessToken();
    const Authorization = `Bearer ${token}`;
    const response = await fetch(
      `${backendPluginUrl}/trusted-advisor/${group}/`,
      {
        headers: { Authorization },
      },
    );
    const data = await response.json();
    return data;
  }

  async getWeeklyChange(
    group: string,
    lastCompleteBillingDate: string,
  ): Promise<CostChange> {
    const backendPluginUrl = await this.discoveryApi.getBaseUrl('cloud-costs');
    const token = await this.oauth2Api.getAccessToken();
    const Authorization = `Bearer ${token}`;
    const response = await fetch(
      `${backendPluginUrl}/cost-change/${group}?date=${lastCompleteBillingDate}`,
      {
        headers: { Authorization },
      },
    );
    const data = await response.json();
    return data;
  }
}
