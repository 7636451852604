import React from 'react';
import {
  LinkProps,
  TableColumn,
  Link,
  SubvalueCell,
} from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IRepositoryVulnerability, useStyles } from 'plugin-github';
import * as S from '../../styles';
import { Typography } from '@material-ui/core';
import { sanitizePackageName } from '../utils';

const ExternalLink = ({ to, children }: LinkProps) => {
  const styles = useStyles();
  return (
    <Link className={styles.linkWithIcon} to={to}>
      {children}
      <OpenInNewIcon fontSize="small" />
    </Link>
  );
};

const getAlertLink = (vulnerability: IRepositoryVulnerability) => {
  const alertUrl = vulnerability?.gitHubAlertUrl;
  const alertUrlWithoutManifest = alertUrl?.split(
    vulnerability.vulnerableManifestFilename,
  )[0];
  return `${alertUrlWithoutManifest}${vulnerability.number}`;
};

export const columns: TableColumn<IRepositoryVulnerability>[] = [
  {
    title: 'Package',
    field: 'packageName',
    render: vulnerability => (
      <SubvalueCell
        value={
          <ExternalLink to={getAlertLink(vulnerability)}>
            {sanitizePackageName(vulnerability.packageName)}
          </ExternalLink>
        }
        subvalue={`Version: ${vulnerability.vulnerableRequirements.replace(
          '= ',
          '',
        )} found in ${vulnerability.vulnerableManifestPath}`}
      />
    ),
    highlight: true,
    width: '15%',
  },
  {
    title: 'Severity',
    field: 'severity',
    width: '10%',
    render: ({ severity }) => (
      <S.SeverityChip
        severity={severity}
        size="small"
        variant="outlined"
        label={severity.toLowerCase()}
      />
    ),
  },
  {
    title: 'Summary',
    field: 'summary',
    sorting: false,
    width: '20%',
    render: ({ summary }) => <Typography variant="body2">{summary}</Typography>,
  },
  {
    title: 'Detected',
    field: 'createdAt',
    sorting: true,
    width: '10%',
  },
  {
    title: 'Remediation',
    field: 'firstPatchedVersion',
    sorting: false,
    width: '25%',
    render: ({ vulnerableRequirements, firstPatchedVersion }) => (
      <Typography variant="body2">
        Upgrade package from version{' '}
        <strong>{vulnerableRequirements.replace('= ', '')}</strong> to{' '}
        <strong>{firstPatchedVersion}</strong> or later
      </Typography>
    ),
  },

  {
    title: 'Action',
    sorting: false,
    render: ({
      issueCreationUrl,
      summary,
      advisoryDescription,
      gitHubAlertUrl,
    }) => {
      const getIssueCreationLink = () => {
        let body = encodeURIComponent(advisoryDescription);

        if (body.length > 7500) {
          body = body.slice(0, 7000);
          body = `${body} \nFull description available here ${gitHubAlertUrl}`;
        }
        return `${issueCreationUrl}?title=${summary}&labels=Bug+report&body=${body}`;
      };

      return (
        <span>
          <Link
            component={ExternalLink}
            target="_blank"
            style={{ textTransform: 'none' }}
            color="primary"
            to={getIssueCreationLink()}
          >
            Create a bug report
          </Link>
        </span>
      );
    },
  },
];
