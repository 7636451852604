import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import {
  discoveryApiRef,
  errorApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { getPipelinesQuery, getPipelinesFromGroups } from './utils';
import { refreshAccessTokenIfExpiring, throwReadableError } from 'plugin-core';

export function useWidgetPipelines(repositories: string[]) {
  const errorApi = useApi(errorApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const query = getPipelinesQuery(repositories);

  const getPipelines = useCallback(async () => {
    if (!repositories.length) return Promise.resolve([]);

    try {
      const proxyUrl = await discoveryApi.getBaseUrl('proxy');
      const endpoint = `${proxyUrl}/cdp-graphql/api/graphql`;

      // @TODO Change way of fetching pipelines once endpoint is added to cdp-backend-plugin
      // Check and in case refresh expiring token
      await refreshAccessTokenIfExpiring(discoveryApi);
      // Authentication is made via SessionId Cookie (credentials: 'include' required)
      // in request so Authentication header with token is not needed
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ query }),
        credentials: 'include',
      });

      if (!response.ok) throwReadableError(response);

      const data = await response.json();
      const pipelines = getPipelinesFromGroups(
        data.data?.pipeline_groups || [],
      );

      return Promise.resolve(pipelines);
    } catch (error) {
      const e = error as Error;
      errorApi.post(e);
      return Promise.reject(e);
    }
  }, [errorApi, discoveryApi, query, repositories.length]);

  const { loading, value, error } = useAsyncRetry(
    () => getPipelines(),
    [getPipelines],
  );

  return [{ loading, value, error }, { getPipelines }] as const;
}
