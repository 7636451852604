import React from 'react';
import { Text } from '../../../../../../common';

export const NoLogsMessage = () => {
  return (
    <div id="scalyr-logs">
      <Text p={2}>No logs available.</Text>
    </div>
  );
};
