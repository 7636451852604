import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import axios from 'axios';

export const scorecardsApiRef = createApiRef<ScorecardsApis>({
  id: 'plugin-scorecards',
});

export class ScorecardsApis {
  private readonly http;
  DataSources = {
    create: (dto: TechInsights.CreateDataSourceDto) =>
      this.http.post('/data-sources', dto),
    getAll: () => this.http.get('/data-sources'),
    getById: (id: number) => this.http.get(`/data-sources/by-id/${id}`),
    getByName: (name: string) => this.http.get(`/data-sources/by-name/${name}`),
    updateById: (id: number, dto: Partial<TechInsights.CreateDataSourceDto>) =>
      this.http.patch(`/data-sources/by-id/${id}`, dto),
    deleteById: (id: number) => this.http.delete(`/data-sources/by-id/${id}`),
  };
  DataSourcesWithFacts = {
    create: (dto: TechInsights.CreateDataSourceWithFactsDto) =>
      this.http.post('/data-sources-facts', dto),
    updateById: (
      id: number,
      dto: Partial<TechInsights.CreateDataSourceWithFactsDto>,
    ) => this.http.patch(`/data-sources-facts/by-id/${id}`, dto),
  };
  Facts = {
    create: (dto: TechInsights.CreateFactDto) => this.http.post('/facts', dto),
    getAll: () => this.http.get('/facts'),
    getById: (id: number) => this.http.get(`/facts/by-id/${id}`),
    getByName: (name: string) => this.http.get(`/facts/by-name/${name}`),
    updateById: (id: number, dto: Partial<TechInsights.CreateFactDto>) =>
      this.http.patch(`/facts/by-id/${id}`, dto),
    deleteById: (id: number) => this.http.delete(`/facts/by-id/${id}`),
  };
  Checks = {
    create: (dto: TechInsights.CreateCheckDto) =>
      this.http.post('/checks', dto),
    getAll: () => this.http.get('/checks'),
    getById: (id: number) => this.http.get(`/checks/by-id/${id}`),
    getByName: (name: string) => this.http.get(`/checks/by-name/${name}`),
    updateById: (id: number, dto: Partial<TechInsights.CreateCheckDto>) =>
      this.http.patch(`/checks/by-id/${id}`, dto),
    deleteById: (id: number) => this.http.delete(`/checks/by-id/${id}`),
  };
  Scorecards = {
    create: (dto: TechInsights.CreateScorecardDto) =>
      this.http.post('/scorecards', dto),
    getAll: () => this.http.get('/scorecards'),
    getById: (id: number) => this.http.get(`/scorecards/by-id/${id}`),
    getByName: (name: string) => this.http.get(`/scorecards/by-name/${name}`),
    updateById: (id: number, dto: Partial<TechInsights.CreateScorecardDto>) =>
      this.http.patch(`/scorecards/by-id/${id}`, dto),
    deleteById: (id: number) => this.http.delete(`/scorecards/by-id/${id}`),
  };
  ScorecardTargets = {
    create: (dto: TechInsights.CreateTargetDto) =>
      this.http.post('/scorecard-targets', dto),
    getAll: () => this.http.get('/scorecard-targets'),
    getById: (id: number) => this.http.get(`/scorecard-targets/by-id/${id}`),
    getByName: (name: string) =>
      this.http.get(`/scorecard-targets/by-name/${name}`),
    updateById: (id: number, dto: Partial<TechInsights.CreateTargetDto>) =>
      this.http.patch(`/scorecard-targets/by-id/${id}`, dto),
    deleteById: (id: number) =>
      this.http.delete(`/scorecard-targets/by-id/${id}`),
  };
  Assessment = {
    assessById: (id: number) => this.http.post(`/assess/by-id/${id}`),
    assessByName: (name: string) => this.http.post(`/assess/by-name/${name}`),
  };
  Mediators = {
    hasGrafanaDashboards: (
      name: string,
      dashboard: 'kube_app' | 'kube_ingress',
    ) =>
      this.http.get(`/mediators/has-grafana-dashboards/${name}/${dashboard}`),
  };
  ScorecardExclusions = {
    create: (dto: TechInsights.CreateScorecardExclusionDto) =>
      this.http.post('/scorecard-exclusions', dto),
    getAll: () => this.http.get('/scorecard-exclusions'),
    getById: (id: number) => this.http.get(`/scorecard-exclusions/by-id/${id}`),
    getByName: (name: string) =>
      this.http.get(`/scorecard-exclusions/by-name/${name}`),
    updateById: (
      id: number,
      dto: Partial<TechInsights.CreateScorecardExclusionDto>,
    ) => this.http.patch(`/scorecard-exclusions/by-id/${id}`, dto),
    deleteById: (id: number) =>
      this.http.delete(`/scorecard-exclusions/by-id/${id}`),
  };

  constructor(configApi: ConfigApi) {
    const backendUrl = configApi.getString('backend.baseUrl');
    this.http = axios.create({
      baseURL: new URL('/tech-insights', backendUrl).toString(),
      withCredentials: true,
    });
  }
}
