import React, { memo, useEffect } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import useStyles from './styles';
import * as Tracking from '../../common/tracking';
import { SearchContent } from '../SearchContent';
import { useSearchContext } from '../../SearchContext';
import { useMatch, useNavigate } from 'react-router';
import { FILTERS_KEY, OFFSET_KEY, QUERY_KEY, TAB_KEY } from '../../constants';
import { Alert } from '@material-ui/lab';
import { AlertProps } from '@material-ui/lab/Alert/Alert';

type ClosedToast = { open: false };
type OpenToast = {
  open: true;
  message: string;
  severity: AlertProps['severity'];
};
type ToastProps = ClosedToast | OpenToast;

export const SearchModal = memo(() => {
  const [toast, setToast] = React.useState<ToastProps>({ open: false });
  const searchPageMatch = useMatch('/search');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { query, offset, filters, tab, setQuery } = useSearchContext();
  const navigate = useNavigate();

  // Change modal open state when keyboard combo is hit (cmd+K)
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent,
  ) => {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      /**
       * NOTE: Native window API is used here because this function is called
       *   inside a listener that has no access to the match state
       */
      if (window.location.pathname.startsWith('/search')) {
        setToast({
          open: true,
          message: 'Search modal is disabled in the search page',
          severity: 'info',
        });
      } else {
        event.preventDefault();
        setOpen(prev => !prev);
      }
    }
  };

  useEffect(() => {
    if (open && searchPageMatch) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPageMatch]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
  }, []);

  const handleClose: React.MouseEventHandler<HTMLElement> = e => {
    // Prevents browser from exiting window full screen
    if ((e.currentTarget as HTMLElement)?.tagName.toLowerCase() !== 'a') {
      e.preventDefault();
    }
    setOpen(false);
    setQuery('');
    Tracking.closeModal();
  };

  function handleExpand() {
    Tracking.expandModal(query);
    const url = new URL(`${window.location.origin}/search`);
    if (query) url.searchParams.set(QUERY_KEY, query);
    if (offset) url.searchParams.set(OFFSET_KEY, offset.toString());
    if (filters) url.searchParams.set(FILTERS_KEY, JSON.stringify(filters));
    if (tab) url.searchParams.set(TAB_KEY, tab);
    navigate(`${url.pathname}${url.search}`);
  }

  return (
    <>
      <Snackbar
        style={{ height: 'auto' }}
        open={toast.open}
        autoHideDuration={4e3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setToast({ open: false })}
      >
        <Alert
          onClose={() => setToast({ open: false })}
          severity={(toast as OpenToast).severity}
        >
          {(toast as OpenToast).message}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleClose}
        aria-labelledby="search-in-sunrise"
        maxWidth="lg"
        open={open}
        classes={{
          paper: classes.dialogRoot,
          paperScrollPaper: 'overflow-visible',
          paperWidthLg: classes.dialogWidth,
        }}
      >
        <DialogTitle id="search-in-sunrise" style={{ padding: 0 }}>
          <Box display="flex" alignItems="center" padding="0 1rem">
            <SearchIcon fontSize="large" />
            <TextField
              value={query}
              onChange={ev => setQuery(ev.target.value)}
              placeholder="Search documentation, applications, colleagues, or APIs"
              inputProps={{ 'aria-label': 'search zalando' }}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              id="standard-basic"
              variant="standard"
              label=""
              style={{
                padding: '1rem',
                width: '100%',
                fontSize: '18px',
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="Expand to Search Page"
                      placement="top"
                      arrow
                    >
                      <Chip
                        label={
                          <AspectRatioIcon
                            classes={{ root: classes.chipIcon }}
                          />
                        }
                        aria-label="Expand to Search Page"
                        size="small"
                        classes={{ root: classes.modalChip }}
                        onClick={handleExpand}
                        clickable
                      />
                    </Tooltip>
                    <Chip
                      label="esc x"
                      size="small"
                      classes={{ root: classes.modalChip }}
                      onClick={handleClose}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box width="100%" height="0.4px" bgcolor="#ddd">
            &nbsp;
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            minHeight: 'min(300px, 80vh)',
          }}
        >
          <SearchContent stripped />
        </DialogContent>
      </Dialog>
    </>
  );
});
