import { action, makeObservable, observable, runInAction } from 'mobx';
import { CDPApi } from '../api';
import { RunsMetadataModel, RunsModel } from '../models';
import { State } from './helpers/state';
import { PollingState, IPoll } from './helpers/polling';
import {
  FetchTypeEnum,
  PaginationHelper,
  IPaginate,
} from './helpers/pagination';
import { PIPELINE_RUNS_LIMIT } from '../constants';
import { AbortException, NotFoundException } from '../api/exceptions';

export interface IRunsService {
  runsState: State;
  runs: Array<RunsModel>;
  pollingState: PollingState;
  rollbackPipelineID: string | null;
  get: (repositoryURL: string, events: string[]) => Promise<void>;
  filter: (events: string[]) => Promise<void>;
  updateRollbackPipelineID: (id: string) => void;
  readonly currentEvent: string[];
}

export class RunsService implements IRunsService, IPaginate, IPoll {
  // Backstage Apis
  private cdpApi: CDPApi;

  // Observables
  runsState: State;
  runs: Array<RunsModel> = [];
  pollingState: PollingState;
  paginationHelper: PaginationHelper;
  rollbackPipelineID: string | null;

  // Internals
  private repositoryURL: string = '';
  private events: string[] = [];

  constructor(cdpApi: CDPApi) {
    // Backstage Apis
    this.cdpApi = cdpApi;

    // States
    this.runsState = new State();
    this.pollingState = new PollingState();
    this.paginationHelper = new PaginationHelper();
    this.rollbackPipelineID = null;

    makeObservable<this, 'fetch'>(this, {
      runs: observable,
      runsState: observable,
      pollingState: observable,
      rollbackPipelineID: observable,
      paginationHelper: observable,
      get: action,
      filter: action,
      reload: action,
      togglePolling: action,
      fetch: action,
    });
  }

  // Actions
  get = (repositoryURL: string, events: string[] = []): Promise<void> => {
    this.runsState.setLoading();
    if (this.repositoryURL !== repositoryURL) {
      this.runs = [];
      this.rollbackPipelineID = null;
    }
    this.repositoryURL = repositoryURL;
    this.events = events;
    return this.fetch();
  };

  filter = (events: string[]): Promise<void> => {
    this.runsState.setLoading();
    this.events = events;
    return this.fetch();
  };

  getNextPage = async (): Promise<void> => {
    if (this.paginationHelper.hasNextPage) {
      this.runsState.setLoading();
      this.fetch(FetchTypeEnum.Next);
    }
  };

  getPrevPage = async (): Promise<void> => {
    if (this.paginationHelper.hasPrevPage) {
      this.runsState.setLoading();
      this.fetch(FetchTypeEnum.Prev);
    }
  };

  reload = async (): Promise<void> => {
    if (!this.pollingState.isOnHold) {
      this.runsState.setReloading();
      this.fetch(FetchTypeEnum.Reload);
    }
  };

  togglePolling = (): void => {
    this.pollingState.toggle();
    if (this.pollingState.isCurrentlyLive) {
      this.reload();
    }
  };

  updateRollbackPipelineID = (id: string): void => {
    this.rollbackPipelineID = id;
  };

  // Internals
  private fetch = async (
    fetchType: FetchTypeEnum = FetchTypeEnum.FirstTime,
  ): Promise<void> => {
    try {
      this.pollingState.hold();

      const response = await this.cdpApi.getRuns(
        this.repositoryURL,
        this.paginationHelper.getCursor(fetchType),
        PIPELINE_RUNS_LIMIT,
        this.events,
        this.pollingState.abortController.signal,
      );

      runInAction(() => {
        this.runs = response.runs.map(run => new RunsModel(run));
      });
      this.paginationHelper.update(
        fetchType,
        new RunsMetadataModel(response.metadata),
      );

      this.pollingState.resetConsecutiveFailedPolls();
      this.runsState.setLoaded();
    } catch (error) {
      if (error instanceof AbortException) {
        return;
      }

      if (error instanceof NotFoundException) {
        this.runsState.setNotFound();
        return;
      }

      this.pollingState.incrementConsecutiveFailedPolls();
      this.runsState.setError();
    } finally {
      this.pollingState.resume();
    }
  };

  get currentEvent(): string[] {
    return this.events;
  }
}
