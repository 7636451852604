import React from 'react';
import { Link } from '@backstage/core-components';
import { Box, Typography } from '@material-ui/core';
import { TrackedInteraction } from '../../Tracking';

interface EmptyContentProps {
  action: string;
}
export const EmptyContent = ({ action }: EmptyContentProps) => {
  const watchTeamsLink = (): ITrackingEvent => ({
    plugin: 'catalog',
    eventCategory: 'Api Catalog',
    eventLabel: 'Redirect to watched teams',
    eventAction: `Watch teams link is clicked from empty owned ${action}`,
  });

  return (
    <Box
      height="350px"
      textAlign="center"
      display="flex"
      alignItems="center"
      padding="0 50px"
    >
      <Typography>
        We couldn't find any {`${action}`} owned by your team(s), but you can
        update your{' '}
        <TrackedInteraction interaction="onClick" {...watchTeamsLink()}>
          <Link to="/settings/personalization" color="primary">
            personalization settings
          </Link>{' '}
        </TrackedInteraction>
        to "watch" content from the delivery teams you collaborate with which
        will enable you to view them here.
      </Typography>
    </Box>
  );
};
