import {
  EventTrackerProps,
  TrackedInteractionProps,
} from 'plugin-ui-components';

export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'catalog',
  eventCategory: 'Documentation About Card',
};

export const viewSource = (name: string): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on View Source link',
  eventLabel: `View Source link of documentation ${name}`,
});

export const viewDocs: TrackedInteractionProps = {
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on View Documentation link',
  eventLabel: 'Service View Documentation link',
};

export const deleteDoc = (name: string): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on Delete Document',
  eventLabel: `Service Delete Document ${name}`,
});

export const confirmDeleteDoc = (name: string): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on Confirm Delete Document',
  eventLabel: `Service Confirm Delete Document ${name}`,
});

export const ownerLink: EventTrackerProps = {
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Documentation Owner link',
};

export const editCategoryDoc = (name: string): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on Edit Documents Category',
  eventLabel: `Service Edit Document ${name} Category`,
});

export const confirmEditDocCategory = (
  name: string,
): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'Clicked on Confirm Edit Documents Category',
  eventLabel: `Service Confirm Edit Document ${name} Category`,
});
