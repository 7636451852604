import React from 'react';
import { Alert } from '@material-ui/lab';

export interface FormState {
  status: 'loading' | 'success' | 'error' | '';
  message?: React.ReactNode;
}

export const FormStatus: React.FC<FormState> = ({ status, message }) => {
  if (status && status !== 'loading') {
    const getMessage = (alertStatus: string) => {
      if (alertStatus === 'success') {
        return message ? message : 'Application successfully updated.';
      }
      return (
        message ||
        'Something unexpected happened. Failed to update the application.'
      );
    };
    return (
      <Alert className="mb-1" severity={status}>
        {getMessage(status)}
      </Alert>
    );
  }
  return <></>;
};
