import ReactJson from 'react-json-view';
import React, { useEffect, useState } from 'react';
import {
  Table as MaterialTable,
  TableColumn,
} from '@backstage/core-components';
import { DLQApiRef } from '../../api/DLQApi/DLQApi';
import { useApi } from '@backstage/core-plugin-api';
import { DLQEvent } from '../../domain/DLQ';
import { Alert } from '@material-ui/lab';
import LinkIcon from '@material-ui/icons/Link';
import { onEventTypeClick } from '../../utils/tracking';
import { PluginTracking } from 'plugin-ui-components';

export function EventView({
  subscription_id,
  nakadiUiURL,
}: {
  subscription_id: string;
  nakadiUiURL: string;
}) {
  const dlqApi = useApi(DLQApiRef);
  const [dlqEvents, setDlqEvents] = useState<DLQEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const onDLQEventDelete = async (dlqEvent: DLQEvent) => {
    setIsLoading(true);
    await dlqApi.deleteDLQEvent(subscription_id, dlqEvent);
    setDlqEvents(dlqEvents.filter(e => e.eid !== dlqEvent.eid));
    setIsLoading(false);
  };

  const COLUMNS: TableColumn<DLQEvent>[] = [
    {
      title: 'EID',
      field: 'eid',
      type: 'string',
      cellStyle: {
        verticalAlign: 'top',
      },
    },
    {
      title: 'Error message',
      field: 'error_message',
      type: 'string',
      cellStyle: {
        verticalAlign: 'top',
      },
    },
    {
      title: 'Event',
      field: 'event',
      type: 'string',
      render: row => (
        <ReactJson
          src={row.event}
          enableClipboard={false}
          displayDataTypes={false}
          collapsed={1}
        />
      ),
    },
  ];

  useEffect(() => {
    dlqApi
      .getDLQEvents(subscription_id)
      .then(response => {
        setDlqEvents(response);
        setError('');
      })
      .catch(err => {
        setError(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [subscription_id, dlqApi]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <MaterialTable
      title={undefined}
      columns={COLUMNS}
      data={dlqEvents}
      isLoading={isLoading}
      options={{
        toolbar: false,
        paging: true,
        sorting: false,
        grouping: false,
        draggable: false,
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowDelete: onDLQEventDelete,
      }}
      actions={[
        {
          icon: () => <LinkIcon />,
          tooltip: 'View event type',
          onClick: (_, dlqEvent) => {
            PluginTracking.sendEvent(onEventTypeClick());
            window.open(
              `${nakadiUiURL}/#types/${(dlqEvent as DLQEvent).event_type}`,
              '_blank',
            );
          },
        },
      ]}
    />
  );
}
