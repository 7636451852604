import React from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import {
  BreadcrumbsItem,
  RepositoryBreadcrumb,
  RunBreadcrumb,
  StepBreadcrumb,
  Props,
} from './BreadcrumbsItem';
import * as S from './styles';

function createRoutes() {
  return [
    {
      element: ({ isActive }: Props) => (
        <BreadcrumbsItem
          text="Explore pipelines"
          path="/cdp"
          isActive={isActive}
        />
      ),
      path: 'cdp/*',
      caseSensitive: true,
    },
    {
      element: ({ params, isActive }: Props) => (
        <BreadcrumbsItem
          text={params.org_name}
          path={`/cdp/${params.domain_alias}/${params.org_name}`}
          isActive={isActive}
        />
      ),
      path: 'cdp/:domain_alias/:org_name/*',
      caseSensitive: true,
    },
    {
      element: (props: any) => <RepositoryBreadcrumb {...props} />,
      path: 'cdp/:domain_alias/:org_name/:repo_name/*',
      caseSensitive: true,
    },
    {
      element: (props: any) => <RunBreadcrumb {...props} />,
      path: 'cdp/:domain_alias/:org_name/:repo_name/:runId/run/*',
      caseSensitive: true,
    },
    {
      element: (props: any) => <StepBreadcrumb {...props} />,
      path: 'cdp/:domain_alias/:org_name/:repo_name/:runId/steps/:stepId/*',
      caseSensitive: true,
    },
  ];
}

export function BreadcrumbsComponent() {
  const location = useLocation();
  const breadcrumbs = createRoutes();

  const [route] =
    React.useMemo(
      () => matchRoutes(breadcrumbs, location.pathname),
      [breadcrumbs, location],
    ) || [];

  if (route) {
    const depth = breadcrumbs.findIndex(e => e.path === route.route.path);

    const elements = breadcrumbs.reduce(
      (acc: Array<React.ReactNode>, curr, i) => {
        if (i <= depth)
          acc.push(
            <curr.element
              params={route.params}
              isActive={i === depth}
              key={curr.path}
            />,
          );

        return acc;
      },
      [],
    );

    return <S.Breadcrumbs aria-label="breadcrumb">{elements}</S.Breadcrumbs>;
  }

  return <></>;
}
