import React, { ReactElement } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import { MLXBreadcrumbs } from './MLXBreadcrumbs';
import { SupportButtons } from '../SupportButtons';

interface BaseLayoutProps {
  children: ReactElement;
  notebookName?: string;
  executionName?: string;
}

export const BaseLayout = (props: BaseLayoutProps) => {
  const { children, notebookName, executionName } = props;
  return (
    <Page themeId="ml">
      <Header
        title="Machine Learning Platform"
        subtitle={
          <MLXBreadcrumbs
            notebookName={notebookName}
            executionName={executionName}
          />
        }
      >
        <SupportButtons />
      </Header>
      <Content noPadding>{children}</Content>
    </Page>
  );
};
