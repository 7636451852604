import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { Link } from '@backstage/core-components';
import { AboutField, InfoCard } from 'plugin-ui-components';
import { MLModel } from '../../api/definitions';
import { AWS_CONSOLE_URLS, NOT_AVAILABLE } from '../../constants/common';
import { CDPUtils } from '../../utils/cdp';
import { getFormattedDateTime } from '../../utils/time';
import { UserProfileLink } from '../common/ProfileLinks';
import { ModelContainers } from './ModelContainers';
import { ModelOwners } from './ModelOwners';
import { ModelReferences } from './ModelReferences';
import { ModelStatusBadge } from './ModelStatusBadge';
import { ModelTags } from './ModelTags';

export const ModelDetails = ({ model }: { model: MLModel }) => {
  const cdpUtils = new CDPUtils({}, model.run_id, 'model');

  const linkToK8SCluster = cdpUtils.linkToK8SCluster();

  return (
    <>
      <InfoCard title="Basic Information">
        <CardContent>
          <Grid container spacing={3}>
            <AboutField
              label="Name"
              children={model.name}
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              key={uuidv4()}
              label="AWS Console Link"
              children={
                <Link
                  to={`${AWS_CONSOLE_URLS.SAGEMAKER_MODEL_URL}/${model.name}`}
                >
                  {model.name}
                </Link>
              }
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              label="K8s Cluster"
              children={
                linkToK8SCluster ? (
                  <Link to={linkToK8SCluster}>
                    {cdpUtils.extractAWSAccountId(model.run_id)}
                  </Link>
                ) : (
                  NOT_AVAILABLE
                )
              }
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              label="Created at"
              children={
                model.created_at
                  ? `${getFormattedDateTime(model.created_at)}`
                  : NOT_AVAILABLE
              }
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              label="Pipeline ID"
              children={
                model.run_id ? (
                  <Link
                    to={`/ml/projects/${model.project_id}/pipelines/${model.pipeline_id}/runs/`}
                  >
                    {model.pipeline_id}
                  </Link>
                ) : (
                  NOT_AVAILABLE
                )
              }
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              label="Run ID"
              children={
                model.run_id ? (
                  <Link
                    to={`/ml/projects/${model.project_id}/pipelines/${model.pipeline_id}/runs/${model.run_id}`}
                  >
                    {model.run_id}
                  </Link>
                ) : (
                  NOT_AVAILABLE
                )
              }
              xs={12}
              sm={6}
              lg={4}
            />
            <AboutField
              label="Overview"
              children={model.overview || 'No overview provided'}
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Containers">
        <ModelContainers containers={model.containers} />
      </InfoCard>

      <Grid container>
        <Grid item xs={12}>
          {model.approval &&
            model.approval.approval_status !== 'PendingManualApproval' && (
              <InfoCard title="Approval Status">
                <CardContent>
                  <Grid container spacing={3}>
                    <AboutField
                      label="Status"
                      children={
                        <ModelStatusBadge
                          status={model.approval?.approval_status}
                        />
                      }
                      xs={12}
                      sm={6}
                      lg={4}
                    />
                    <AboutField
                      label="Updated at"
                      children={
                        model.approval?.approved_at
                          ? `${getFormattedDateTime(
                              model.approval.approved_at,
                            )}`
                          : NOT_AVAILABLE
                      }
                      xs={12}
                      sm={6}
                      lg={4}
                    />
                    <AboutField
                      label="Updated by"
                      children={
                        model.approval?.approved_by &&
                        model.approval?.approved_by !== 'jdoe' ? (
                          <UserProfileLink
                            userId={model.approval?.approved_by}
                          />
                        ) : (
                          NOT_AVAILABLE
                        )
                      }
                      xs={12}
                      sm={6}
                      lg={4}
                    />
                    {model.approval?.comments && (
                      <AboutField
                        label="Comments"
                        children={<span>{model.approval?.comments}</span>}
                        xs={12}
                        sm={6}
                        lg={4}
                      />
                    )}
                  </Grid>
                </CardContent>
              </InfoCard>
            )}
        </Grid>
        <Grid item xs={6}>
          {model.deployment && model.deployment?.is_deployed && (
            <InfoCard title="Deployment">
              <CardContent>
                <Grid container spacing={3}>
                  <AboutField
                    label="Status"
                    children={
                      <ModelStatusBadge
                        status={
                          model.deployment?.is_deployed
                            ? 'Deployed'
                            : 'Not deployed'
                        }
                      />
                    }
                    xs={12}
                    sm={6}
                    lg={4}
                  />
                  <AboutField
                    label="Deployed at"
                    children={
                      model.deployment?.deployed_at
                        ? `${getFormattedDateTime(
                            model.deployment.deployed_at,
                          )}`
                        : NOT_AVAILABLE
                    }
                    xs={12}
                    sm={6}
                    lg={4}
                  />
                  <AboutField
                    label="Deployed by"
                    children={
                      model.deployment?.deployed_by &&
                      model.deployment?.deployed_by !== 'jdoe' ? (
                        <UserProfileLink
                          userId={model.deployment?.deployed_by}
                        />
                      ) : (
                        NOT_AVAILABLE
                      )
                    }
                    xs={12}
                    sm={6}
                    lg={4}
                  />
                </Grid>
              </CardContent>
            </InfoCard>
          )}
        </Grid>
      </Grid>

      <InfoCard title="Tags">
        <ModelTags tags={model.tags} />
      </InfoCard>

      <InfoCard title="Owners">
        <ModelOwners owners={model.owners} />
      </InfoCard>

      <InfoCard title="References">
        <ModelReferences references={model.references} />
      </InfoCard>
    </>
  );
};
