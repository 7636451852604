import { createTheme, Theme } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';

export const ButtonTheme: Theme = createTheme({
  palette: {
    primary: purple,
    action: {
      disabledBackground: 'grey',
    },
  },
});
