import { action, makeObservable, observable } from 'mobx';
import { CDPApi } from '../api';
import { SecretState } from './helpers/secrets.state';
import { SecretModel } from '../models';
import {
  NotFoundException,
  ConflictException,
  ResourceAccessForbiddenException,
} from '../api/exceptions';

export interface ISecretsService {
  upsertState: SecretState;
  validateState: SecretState;
  secret: SecretModel;
  repoFullPath: string;
  setRepositoryFullPath: (value: string) => void;
  upsert: (
    secretName: string,
    secretValue: string,
    availableInPR: boolean,
  ) => Promise<void>;
  validate: (
    secretID: string,
    secretVersion: string,
    secretValue: string,
  ) => Promise<void>;
  clean: () => void;
}

export class SecretsService implements ISecretsService {
  upsertState: SecretState = new SecretState();
  validateState: SecretState = new SecretState();
  secret: SecretModel = new SecretModel();
  repoFullPath: string = '';

  constructor(private cdpApi: CDPApi) {
    makeObservable(this, {
      upsertState: observable,
      validateState: observable,
      secret: observable,
      upsert: action,
      clean: action,
    });
  }

  // Actions
  async upsert(
    secretName: string,
    secretValue: string,
    availableInPR: boolean,
  ): Promise<void> {
    this.upsertState.setLoading();

    try {
      const secretResponse = await this.cdpApi.upsertSecret(
        this.repoFullPath,
        secretName,
        {
          secret_value: secretValue,
          available_in_pull_requests: availableInPR,
        },
      );

      this.secret = new SecretModel(secretResponse);
      this.upsertState.setLoaded();
    } catch (error) {
      const e = error as Error;

      if (error instanceof NotFoundException) {
        this.upsertState.setNotFound();
        return;
      }

      if (error instanceof ConflictException) {
        this.upsertState.setConflict();
        return;
      }

      this.upsertState.setError(e.message);
    }
  }

  async validate(
    secretID: string,
    secretVersion: string,
    secretValue: string,
  ): Promise<void> {
    this.validateState.setLoading();

    try {
      const secretResponse = await this.cdpApi.validateSecret(
        this.repoFullPath,
        secretID,
        secretVersion,
        {
          reference_value: secretValue,
        },
      );

      this.secret = new SecretModel(secretResponse);
      this.validateState.setLoaded();
    } catch (error) {
      const e = error as Error;

      if (error instanceof NotFoundException) {
        this.validateState.setNotFound();
        return;
      }

      if (error instanceof ResourceAccessForbiddenException) {
        this.validateState.setForbiddenAccess();
        return;
      }

      this.validateState.setError(e.message);
    }
  }

  clean() {
    this.secret = new SecretModel();
    this.upsertState.setIdle();
    this.validateState.setIdle();
  }

  setRepositoryFullPath(value: string) {
    this.repoFullPath = value;
  }
}
