import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { isValidJSON } from '../../../utils/json';

export const RunInput = ({
  setRunInput: setRunInput,
  setDisabled,
  defaultInput,
}: {
  setRunInput: React.Dispatch<React.SetStateAction<string | null>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  defaultInput: string | null;
}) => {
  const [error, setError] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRunInput = event.target.value;
    const inputInvalid = newRunInput.length > 0 && !isValidJSON(newRunInput);
    setError(inputInvalid);
    if (inputInvalid) {
      setDisabled(true);
    } else {
      setRunInput(newRunInput);
      setDisabled(false);
    }
  };

  return (
    <TextField
      fullWidth
      label="Run Input (optional)"
      error={error}
      helperText="Enter run input in JSON format"
      onChange={handleChange}
      placeholder={'{"first_name" : "test"}'}
      defaultValue={
        !defaultInput ? '{"Comment": "Insert valid JSON"}' : defaultInput
      }
      required={false}
      multiline
      minRows={10}
    />
  );
};
