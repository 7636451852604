import React from 'react';
import { useAsync } from 'react-use';
import { Box } from '@material-ui/core';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { UserReference, colors } from 'plugin-ui-components';
import { TagSmall } from 'plugin-cdp';

import { containerImagesApiRef } from '../../api';
import { ImageModel } from '../../models';
import { useTagStyles } from './styles';

export function Tag({
  registry,
  path,
  tag,
}: {
  registry: string;
  path: string;
  tag: string;
}) {
  const api = useApi(containerImagesApiRef);
  const { value, loading, error } = useAsync(() =>
    api.getImage(registry, path, tag),
  );
  const classes = useTagStyles();

  if (loading)
    return (
      <Box className={classes.loader}>
        <Progress />
      </Box>
    );

  if (!!error || value === undefined)
    return (
      <div className={classes.error}>
        <strong>{tag}</strong>&nbsp;failed to fetch details.&nbsp;
        <span>{!!error && error.message}</span>
      </div>
    );

  const image = new ImageModel(value);

  return (
    <article className={classes.row}>
      <section>
        <strong>{tag}</strong>
      </section>
      <section>
        <UserReference
          user={image.ci.author}
          displayType="login"
          header="Author:"
        />
      </section>

      <section>
        <TagSmall
          color={
            image.compliance.status === 'production_ready'
              ? colors.semantic.successBase
              : colors.semantic.errorBase
          }
        >
          {image.compliance.status || 'missing status'}
        </TagSmall>
      </section>

      <section>{image.platforms[0].createdAt}</section>

      <section>
        {image.platforms.map(platform => (
          <TagSmall key={platform.platformType} className={classes.tag}>
            {platform.platformType}
          </TagSmall>
        ))}
      </section>
    </article>
  );
}
