import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { PluginTracking } from 'plugin-ui-components';

type Props = IconButtonProps & ITrackingEvent;

export const InternalTrackedIconButton = React.forwardRef<any, Props>(
  (props, ref) => {
    const {
      plugin,
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
      onClick,
      ...buttonProps
    } = props;

    const handleOnClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      if (onClick) {
        onClick(event);
      }
      PluginTracking.sendEvent({
        plugin,
        eventCategory,
        eventLabel,
        eventAction,
        eventValue,
      });
    };

    return <IconButton ref={ref} {...buttonProps} onClick={handleOnClick} />;
  },
);
