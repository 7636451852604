import { CatalogClient } from '@backstage/catalog-client';
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  discoveryApiRef,
  fetchApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  catalogApiRef,
  entityRouteRef,
  starredEntitiesApiRef,
} from '@backstage/plugin-catalog-react';
import { DefaultStarredEntitiesApi } from '@backstage/plugin-catalog';
import { CatalogClientWrapper } from './CatalogClientWrapper';
import { createComponentRouteRef, viewTechDocRouteRef } from './routes';
import {
  CatalogAdditionalApi,
  catalogAdditionalApiRef,
  DocHostApi,
  docHostApiRef,
  KioApi,
  kioApiRef,
  SoftwareCatalogApi,
  softwareCatalogApiRef,
} from './api';

export const catalogRouteRef = createRouteRef({ id: 'catalog-index' });

export const catalogPlugin = createPlugin({
  id: 'catalog',
  featureFlags: [{ name: 'catalog-graph' }],
  apis: [
    createApiFactory({
      api: catalogApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        oauth2Api: oauth2ApiRef,
      },
      factory: ({ discoveryApi, fetchApi, oauth2Api }) =>
        new CatalogClientWrapper({
          client: new CatalogClient({ discoveryApi, fetchApi }),
          oauth2Api,
        }),
    }),
    createApiFactory({
      api: starredEntitiesApiRef,
      deps: { storageApi: storageApiRef },
      factory: ({ storageApi }) =>
        new DefaultStarredEntitiesApi({ storageApi }),
    }),
    createApiFactory({
      api: softwareCatalogApiRef,
      deps: { discoveryApi: discoveryApiRef, oauth2Api: oauth2ApiRef },
      factory: ({ discoveryApi, oauth2Api }) =>
        new SoftwareCatalogApi({ discoveryApi, oauth2Api }),
    }),
    createApiFactory({
      api: docHostApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        catalogAdditionalApi: catalogAdditionalApiRef,
      },
      factory: ({ discoveryApi, catalogAdditionalApi }) =>
        new DocHostApi({ discoveryApi, catalogAdditionalApi }),
    }),
    createApiFactory({
      api: catalogAdditionalApiRef,
      deps: { discoveryApi: discoveryApiRef, oauth2Api: oauth2ApiRef },
      factory: ({ discoveryApi, oauth2Api }) =>
        new CatalogAdditionalApi({ discoveryApi, oauth2Api }),
    }),

    createApiFactory({
      api: kioApiRef,
      deps: { discoveryApi: discoveryApiRef, oauth2Api: oauth2ApiRef },
      factory: ({ discoveryApi, oauth2Api }) =>
        new KioApi({ discoveryApi, oauth2Api }),
    }),
  ],
  routes: {
    catalogIndex: catalogRouteRef,
    catalogEntity: entityRouteRef,
  },
  externalRoutes: {
    createComponent: createComponentRouteRef,
    viewTechDoc: viewTechDocRouteRef,
  },
});

export const CatalogEntityPage = catalogPlugin.provide(
  createRoutableExtension({
    name: 'CatalogEntityPage',
    component: () =>
      import('./components/EntityPage').then(m => m.CatalogEntityPage),
    mountPoint: entityRouteRef,
  }),
);
