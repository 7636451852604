import * as React from 'react';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import EmailIcon from '@material-ui/icons/Email';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  List,
  Typography,
  Link,
  styled,
} from '@material-ui/core';
import { useLibraryUsers } from '../../../hooks/useLibrariesUsers';
import { Library } from '../../../types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  overflow: 'scroll',

  '& .MuiDialogContent-root': {
    padding: theme.spacing(6),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(6),
  },
  '& .MuiDialogTitle-root': {
    border: '1px solid red',
    padding: 0,
    margin: 0,
  },
  '& .MuiPaper-root': {
    border: '1px solid red',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle({ children, onClose }: DialogTitleProps) {
  return (
    <DialogTitle style={{ width: 400 }}>
      {children}
      {onClose ? (
        <Button
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 0,
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({ library }: { library: Library }) {
  const [open, setOpen] = React.useState(false);
  const [emails, setEmails] = React.useState<string[]>([]);
  const { libraryUsers, fetchLibraryUsers } = useLibraryUsers(library);

  const handleClickOpen = async () => {
    await fetchLibraryUsers();
    const newEmails = libraryUsers
      ? libraryUsers
          .flatMap(item =>
            Array.isArray(item.spec?.mail) ? item.spec.mail : [],
          )
          .filter((email): email is string => typeof email === 'string')
      : [];

    setEmails(newEmails);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <EmailIcon />
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        style={{ minWidth: 400, maxHeight: 600 }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="body1">Teams:</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ overflow: 'scroll' }}>
          <List>
            {libraryUsers
              ? libraryUsers.map((item: { spec: { id: string } }) => {
                  return (
                    <ListItem>
                      <Link>{item.spec.id}</Link>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              generateMailtoLink(
                emails,
                `About Library ${library.name} ${library.version}`,
                'Hello Team...',
              )
            }
          >
            Send email
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

function generateMailtoLink(
  emailList: string[],
  subject?: string,
  body?: string,
): void {
  const recipientString = emailList.join(',');
  const subjectParam = subject ? `subject=${encodeURIComponent(subject)}` : '';
  const bodyParam = body ? `body=${encodeURIComponent(body)}` : '';

  const email = `mailto:${recipientString}?${subjectParam}${
    subject && body ? '&' : ''
  }${bodyParam}`;

  window.open(email);
}
