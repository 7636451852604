import { Theme } from '@material-ui/core';

import { STATUS_COLORS } from '../../utils/colors';

export const deploymentResourceCssVariables = (theme: Theme) => ({
  '@global': {
    ':root': {
      '--cdp-deployment-resource-colors-default': STATUS_COLORS.default,
      '--cdp-deployment-resource-colors-healthy': STATUS_COLORS.success,
      '--cdp-deployment-resource-colors-pending': STATUS_COLORS.progress,
      '--cdp-deployment-resource-colors-fatal': STATUS_COLORS.failed,
      '--cdp-deployment-resource-colors-failing': STATUS_COLORS.aborted,
      '--cdp-deployment-resource-colors-warning': STATUS_COLORS.warning,
      '--cdp-deployment-resource-colors-aborted': STATUS_COLORS.aborted,
      '--cdp-deployment-resource-background-default':
        theme.palette.background.default,
      '--cdp-deployment-resource-colors-link': (theme as Theme).palette.link,
    },
  },
});
