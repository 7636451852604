import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useSearchParams } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import InsertChartOutlinedOutlined from '@material-ui/icons/InsertChartOutlinedOutlined';
import { Tooltip } from '../../../../common';
import {
  ActionPanelLayout,
  LiveButton,
  UserPreferencesAction,
} from '../../../common';
import { useServices } from '../../../../services';
import { Secrets } from './Secrets';
import * as S from './styles';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { EVENT_OPTIONS } from '../../../../constants';
import { ApplicationsAction } from '../../../common/ApplicationsAction';
import { RollbackAction } from './Rollback';

type ActionPanelProps = {
  e: string[];
};

export const ActionPanel = observer(({ e }: ActionPanelProps) => {
  const { runsService, preferencesService } = useServices();
  const [_, setSearchParams] = useSearchParams({
    event: [],
  });

  const handleEventSelection = (option: IOption[]) => {
    const optionValue = option.map(item => item.value);
    setSearchParams({ event: optionValue }, { replace: true });
  };

  const getEventsSelected = (evt: string[]) => {
    return evt?.map(item => ({
      label: item,
      value: item,
    }));
  };

  return (
    <ActionPanelLayout
      filtersChildren={
        <AutoComplete
          onChange={handleEventSelection}
          options={EVENT_OPTIONS}
          defaultSelection={getEventsSelected(e)}
          uniqueId="events_type_repo"
          placeholder="Event type"
          inputPlaceholder="Filter event types"
          showSelectedFirst={false}
        />
      }
    >
      <S.Actions>
        <LiveButton service={runsService} />
        <RollbackAction />
        <Secrets />
        <ApplicationsAction />
        <UserPreferencesAction service={preferencesService} />

        <Tooltip title="Pipeline insights">
          <Link to="./insights">
            <IconButton>
              <InsertChartOutlinedOutlined />
            </IconButton>
          </Link>
        </Tooltip>
      </S.Actions>
    </ActionPanelLayout>
  );
});
