import React from 'react';
import { SectionRadio } from '../common';
import { LightstepStats } from './LightstepStats';

export function OpenTracing({
  hideConfirmationSection,
}: {
  hideConfirmationSection?: boolean;
}) {
  return (
    <section>
      <dl>
        <dt>LightStep</dt>
        <dd>
          <LightstepStats />
        </dd>
      </dl>

      {!hideConfirmationSection && <SectionRadio name="opentracing_ok" />}
    </section>
  );
}
