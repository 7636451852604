import { buildMatchingEntities } from '.';

/** Generate the state of each individual filter */
export function buildStates(
  filters: ICatalog.Filters,
  entities?: IEntityComponent[],
  error?: Error,
): { [filterGroupId: string]: ICatalog.FilterGroupStates } {
  // On error - all entries are an error state
  if (error) {
    return Object.fromEntries(
      Object.keys(filters.filterGroups).map(filterGroupId => [
        filterGroupId,
        { type: 'error', error },
      ]),
    );
  }

  // On startup - all entries are a loading state
  if (!entities) {
    return Object.fromEntries(
      Object.keys(filters.filterGroups).map(filterGroupId => [
        filterGroupId,
        { type: 'loading' },
      ]),
    );
  }

  const result: { [filterGroupId: string]: ICatalog.FilterGroupStates } = {};
  for (const [filterGroupId, filterGroup] of Object.entries(
    filters.filterGroups,
  )) {
    const otherMatchingEntities = buildMatchingEntities(
      filters,
      entities,
      filterGroupId,
    );
    const groupState: ICatalog.FilterGroupState = { filters: {} };
    for (const [filterId, filterFn] of Object.entries(filterGroup.filters)) {
      const isSelected =
        !!filters.selectedFilterKeys[filterGroupId]?.has(filterId);
      const matchCount = otherMatchingEntities.filter(entity =>
        filterFn(entity),
      ).length;
      groupState.filters[filterId] = { isSelected, matchCount };
    }
    result[filterGroupId] = { type: 'ready', state: groupState };
  }

  return result;
}
