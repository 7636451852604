import React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { IFilteringService } from '../../../services/filtering';
import { IPersist } from '../../../services/helpers/persist';
import { EVENT_OPTIONS } from '../../../constants';

interface Props {
  filteringService: IFilteringService & IPersist;
}

export const EventAction = observer(({ filteringService }: Props) => {
  const handleEventSelection = (selected: IOption[]) => {
    const events = selected.map(event => event.value);
    filteringService.persistHelper.enablePersist();
    filteringService.setEvent(events);
  };

  const getEventsSelected = (events: string[]) => {
    return events?.map(item => ({
      label: item,
      value: item,
    }));
  };

  return (
    <AutoComplete
      onChange={handleEventSelection}
      options={EVENT_OPTIONS}
      defaultSelection={getEventsSelected(filteringService.events)}
      uniqueId="events_type"
      placeholder="Event type"
      inputPlaceholder="Filter event types"
      showSelectedFirst={false}
    />
  );
});
