import React from 'react';
import { Link } from '@backstage/core-components';
import { criticalityLevels } from 'plugin-core';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { criticalityColors } from '../../config';
import { Pill } from '../../styles';
import { FieldProps } from '../types';

export function Criticality({ value, issue }: FieldProps<'criticality_level'>) {
  const label = criticalityLevels.find(l => l.value === value)?.label;
  const color = criticalityColors.get(label as IAppReview.CriticalityStates);

  return (
    <>
      <dt>Current criticality (value in Kio)</dt>
      <dd>
        {label ? <Pill data-color={color} label={label} /> : 'Unknown'}
        {!label?.startsWith('Tier') && (
          <span>
            — not relevant for the business at the moment, e.g. not in
            production or proof of concept
          </span>
        )}
        <InfoTooltip>
          How critical the application is (Tier-1: highest criticality,
          immediate impact to the business; Tier-2: imminent impact to the
          business; Tier-3: long-term or low impact to the business; Not
          Relevant: application is not in production or a proof of concept ).
          See the&nbsp;
          <Link
            color="secondary"
            to="https://docs.google.com/document/d/1m7FVdyuE9nohKx4Sorn_Ww9yc9ecCn70ZgVyHWRzkfw"
          >
            Application Tiers narrative
          </Link>
          &nbsp;for more information.
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
