import React, { useEffect } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { EntityLoadingStatus } from '@backstage/plugin-catalog-react';
import {
  getOpenVulnerabilities,
  gitHubApiRef,
  IAppRepositoryVulnerability,
  IRepositoryVulnerability,
  vulnerabilityAlertAction,
  vulnerabilityAlertDescription,
} from 'plugin-github';
import { EmptyState, MarkdownContent, Table } from '@backstage/core-components';
import { ContentWrapper, InfoCard } from 'plugin-ui-components';
import { useAsync } from 'react-use';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { columns } from './column';
import { Filters } from '../Filters';

const title = 'Dependabot Alerts';

export const DependabotAlerts = ({
  useEntityState,
}: {
  useEntityState: EntityLoadingStatus<IEntityApp>;
}) => {
  const [tableData, setTableData] =
    React.useState<IRepositoryVulnerability[]>();
  const gitHubApi = useApi(gitHubApiRef);
  const { entity } = useEntityState || ({} as EntityLoadingStatus<IEntityApp>);

  const {
    value: vulnerabilities = {} as IAppRepositoryVulnerability,
    loading,
    error,
  } = useAsync(async () => {
    if (entity?.metadata.name) {
      const response = await gitHubApi.fetchVulnerabilities(
        entity?.metadata?.name,
      );

      if (response.data?.length) return response.data[0];
    }
    return {} as IAppRepositoryVulnerability;
  }, [entity]);

  const hasVulnerabilities = !!vulnerabilities.totalCount;

  const handlePackageFilter = (
    value?: IRepositoryVulnerability[],
    severity?: string[],
    name?: string[],
  ) => {
    if (!severity?.length && !name?.length) {
      setTableData(getOpenVulnerabilities(vulnerabilities));
      return;
    }

    setTableData(value);
  };

  useEffect(() => {
    if (vulnerabilities.packages?.length) {
      setTableData(getOpenVulnerabilities(vulnerabilities));
    }
  }, [vulnerabilities]);

  if (error) {
    return (
      <InfoCard title={title}>
        <CardContent>
          <Alert severity="error">{error.message || String(error)}</Alert>
        </CardContent>
      </InfoCard>
    );
  }

  if (!loading) {
    if (!vulnerabilities.sunriseAppHasBeenInstalled) {
      return (
        <EmptyState
          missing="field"
          title="Missing Installation ID"
          description="It looks like the Sunrise 
          Github App has not been installed in your organization yet. Click the link below to install it."
          action={
            <Button
              color="primary"
              href="https://github.bus.zalan.do/github-apps/sunrise/installations/new"
              target="_blank"
              variant="contained"
            >
              Install Sunrise App
            </Button>
          }
        />
      );
    }
    if (!hasVulnerabilities) {
      return (
        <InfoCard title={title}>
          <CardContent>
            No open vulnerability reported by Dependabot
          </CardContent>
        </InfoCard>
      );
    }
  }

  return (
    <ContentWrapper>
      <Filters
        vulnerabilities={getOpenVulnerabilities(vulnerabilities)}
        handlePackageFilter={handlePackageFilter}
      />
      <Table
        title={title}
        subtitle={`${vulnerabilityAlertDescription}${vulnerabilityAlertAction}`}
        data={tableData?.length ? tableData : []}
        columns={columns}
        isLoading={loading}
        detailPanel={[
          {
            tooltip: 'Show Full Description',
            render: rowData => {
              return (
                <CardContent style={{ paddingLeft: 60 }}>
                  <Typography>
                    <MarkdownContent
                      content={rowData.rowData.advisoryDescription}
                      dialect="gfm"
                    />
                  </Typography>
                </CardContent>
              );
            },
          },
        ]}
        options={{
          paging: true,
          pageSize: 10,
          loadingType: 'linear',
          pageSizeOptions: [20, 50, 100],
          search: true,
          headerStyle: {
            whiteSpace: 'nowrap',
          },
        }}
      />
    </ContentWrapper>
  );
};
