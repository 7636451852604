import { QueryClient } from 'react-query';

/* Default options for the query client
 * - refetchOnWindowFocus: to avoid constant refetching when the window is refocused
 * - retry: to avoid retrying queries on failure
 * - cacheTime: set the cache time to a sensible 5s to avoid stale data (queries using personalization need to be fresh)
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 5000,
    },
  },
});
