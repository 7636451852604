import React, { useState } from 'react';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { Grid, useTheme } from '@material-ui/core';
import { Content, Table, TableColumn } from '@backstage/core-components';
import { tableHeaderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { MLModelContainer } from '../../api/definitions';
import { getModelContainerColumns } from '../common/columns/ModelContainerColumns';

export const ModelContainers = ({
  containers,
}: {
  containers: MLModelContainer[];
}) => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const tableData = {
    meta: {
      total: containers?.length,
    },
    containers: containers,
  };

  const columns: TableColumn[] = getModelContainerColumns();

  return (
    <Content>
      <Grid>
        <Table
          data={tableData.containers}
          columns={columns}
          options={{
            pageSize: PAGE_SIZE,
            filtering: false,
            showFirstLastPageButtons: true,
            emptyRowsWhenPaging: false,
            headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
          }}
          components={{
            Pagination: props => (
              <MLTablePagination
                props={props}
                page={page}
                resource={tableData}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
              />
            ),
            Toolbar: props => <MLTableToolbar props={props} />,
          }}
        />
      </Grid>
    </Content>
  );
};
