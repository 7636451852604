import { Theme, styled } from '@material-ui/core';
import { fontSize } from '../../../../common';
import { Link } from 'react-router-dom';

export const Title = styled(Link)((props: { theme: Theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  fontSize: fontSize.medium,
  color: props.theme.palette.text.hint,

  ['&, &:visited']: {
    color: props.theme.palette.text.primary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));
