/**
 * Backstage uses matchMedia to decide what the theme
 * is when the theme is set to Auto.
 */
export function getThemeId() {
  let current = localStorage.getItem('theme') as 'light' | 'dark';

  if (!current) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    current = prefersDark.matches ? 'dark' : 'light';
  }

  return current;
}
