import React, { useCallback, useEffect, useState } from 'react';
import { Page, Content } from '@backstage/core-components';
import { useParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import { loadTestingApiRef, Test } from '../../api/LoadTestOrchestratorApi';
import Alert from '@material-ui/lab/Alert';
import { LinearProgress } from '@material-ui/core';
import { FeatureFlagRibbon } from 'plugin-ui-components';
import { useStyles } from '../../StylingComponent/styles';
import { TestDetailsSnackbar } from './TestDetailsSnackbar';
import { TestDetailsContent } from './TestDetailsContent';
import { CreateRampForm } from './CreateRampForm';
import { TestDetailsHeader } from './TestDetailsHeader';

export const TestDetailsComponent = () => {
  const classes = useStyles();
  const { testId } = useParams<{ testId: string }>();
  const loadTestingApi = useApi(loadTestingApiRef);
  const [test, setTest] = useState<Test | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );
  const [snackbarClosedByUser, setSnackbarClosedByUser] = useState(false);
  const [createRampOpen, setCreateRampOpen] = useState(false);
  const [selectedTestRunId, setSelectedTestRunId] = useState<string | null>(
    null,
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarClosedByUser(true);
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchTestDetails = useCallback(async () => {
    try {
      const response = await loadTestingApi.getTest(testId!);
      const data = response.data.length ? response.data[0] : null;
      setTest(data);
      setLoading(false);
    } catch (err) {
      setError((err as Error).message);
      setLoading(false);
    }
  }, [loadTestingApi, testId]);

  useEffect(() => {
    const fetchAndUpdate = async () => {
      await fetchTestDetails();
    };

    fetchAndUpdate();

    const interval = setInterval(fetchAndUpdate, 5000);

    return () => clearInterval(interval);
  }, [fetchTestDetails]);

  useEffect(() => {
    if (
      test &&
      test.test_runs.some(run => run.state_parameters?.state === 'scheduled') &&
      !snackbarClosedByUser
    ) {
      setSnackbarMessage(
        `The test ${test.metadata.name} is scheduled. Please wait for the scheduled date and time for Kubernetes resources to be created and start running.`,
      );
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [test, snackbarClosedByUser]);

  if (loading) return <LinearProgress className={classes.linearProgress} />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Page themeId="tool">
      <FeatureFlagRibbon
        name="load-testing"
        theme="purple"
        page
        details="This whole load testing service is enabled by the feature flag"
        forceShow
      >
        <TestDetailsHeader testName={test?.metadata.name || 'Untitled Test'} />
      </FeatureFlagRibbon>

      <Content>
        {!loading && !error && test && (
          <TestDetailsContent
            test={test}
            showSnackbar={showSnackbar}
            fetchTestDetails={fetchTestDetails}
            setSelectedTestRunId={setSelectedTestRunId}
            setCreateRampOpen={setCreateRampOpen}
          />
        )}
        {!test && !loading && (
          <Alert severity="warning">No test data found</Alert>
        )}
        <TestDetailsSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />

        {selectedTestRunId && (
          <CreateRampForm
            currentVus={
              test?.test_runs.find(run => run.id === selectedTestRunId)
                ?.state_parameters?.runtimeVus || 0
            }
            testRunId={selectedTestRunId}
            open={createRampOpen}
            onClose={() => setCreateRampOpen(false)}
            showSnackbar={showSnackbar}
          />
        )}
      </Content>
    </Page>
  );
};
