import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TableColumn, Table } from '@backstage/core-components';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  MLPipelineRunMetricsParams,
  RunStatus,
} from '../../../api/definitions';
import { DEFAULT_SELECT_OPTION } from '../../../constants/common';
import { getFormattedDateTime } from '../../../utils/time';
import { RunStatusBadge } from '../../Runs/RunStatusBadge';
import {
  getMetricValueCell,
  getMetricValueCellStyle,
  getOptions,
} from './utils';

function renderRunParamsMetrics(data: MLPipelineRunMetricsParams[]) {
  const columns: TableColumn[] = [
    {
      field: 'key',
    },
    {
      field: 'value',
      render(row: any): any {
        return getMetricValueCell(row);
      },
      cellStyle: (_, rowData: { is_average?: boolean }) => {
        return getMetricValueCellStyle(rowData);
      },
    },
  ];

  if (data.length) {
    return (
      <Table
        data={data}
        columns={columns}
        options={{
          search: false,
          filtering: false,
          paging: false,
          padding: 'dense',
          header: false,
          toolbar: false,
        }}
      />
    );
  }
  return <></>;
}

export const getRunListColumns = (
  availableMetricsParamsStatus:
    | {
        metrics: MLPipelineRunMetricsParams[];
        params: MLPipelineRunMetricsParams[];
        statuses: RunStatus[];
      }
    | undefined,
  selectedFilter:
    | {
        selectedMetric: string | undefined;
        selectedParam: string | undefined;
        selectedStatus: string | undefined;
      }
    | undefined,
  setSelectedFilter: React.Dispatch<{
    selectedMetric: string | undefined;
    selectedParam: string | undefined;
    selectedStatus: string | undefined;
  }>,
  classes: any,
): TableColumn[] => {
  const metricOptions = getOptions(
    availableMetricsParamsStatus?.metrics,
    'key',
  );

  const paramOptions = getOptions(availableMetricsParamsStatus?.params, 'key');

  const statusOptions = getOptions(availableMetricsParamsStatus?.statuses);

  return [
    {
      title: 'Run Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Last modification',
      field: 'last_modified_at',
      filtering: false,
      align: 'center',
      render(row: any): React.ReactNode {
        return getFormattedDateTime(row.last_modified_at);
      },
    },
    {
      title: 'Run Status',
      field: 'status',
      filtering: true,
      render(row: any): React.ReactNode {
        return <RunStatusBadge status={row.status} />;
      },
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            fullWidth
            style={{ minWidth: '150px' }}
            onChange={(__, selected: any) =>
              setSelectedFilter({
                selectedParam: selectedFilter?.selectedParam,
                selectedStatus: selected,
                selectedMetric: selectedFilter?.selectedMetric,
              })
            }
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={selectedFilter?.selectedStatus || DEFAULT_SELECT_OPTION}
                variant="outlined"
                margin="dense"
                data-testid="run-status-autocomplete-input"
              />
            )}
            data-testid="run-status-autocomplete-select"
            options={statusOptions}
          />
        );
      },
    },
    {
      title: 'Metrics',
      align: 'center',
      field: 'metrics',
      render(row: any): React.ReactNode {
        return renderRunParamsMetrics(row.metrics);
      },
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            fullWidth
            onChange={(__, selected: any) =>
              setSelectedFilter({
                selectedParam: selectedFilter?.selectedParam,
                selectedMetric: selected,
                selectedStatus: selectedFilter?.selectedStatus,
              })
            }
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={selectedFilter?.selectedMetric || DEFAULT_SELECT_OPTION}
                variant="outlined"
                margin="dense"
                data-testid="run-metric-autocomplete-input"
              />
            )}
            data-testid="run-metric-autocomplete-select"
            options={metricOptions}
          />
        );
      },
    },
    {
      title: 'Params',
      align: 'center',
      field: 'params',
      render(row: any): React.ReactNode {
        return renderRunParamsMetrics(row.params);
      },
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            fullWidth
            onChange={(__, selected: any) =>
              setSelectedFilter({
                selectedMetric: selectedFilter?.selectedMetric,
                selectedParam: selected,
                selectedStatus: selectedFilter?.selectedStatus,
              })
            }
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={selectedFilter?.selectedParam || DEFAULT_SELECT_OPTION}
                variant="outlined"
                margin="dense"
                data-testid="run-param-autocomplete-input"
              />
            )}
            data-testid="run-param-autocomplete-select"
            options={paramOptions}
          />
        );
      },
    },
  ];
};
