import qs from 'query-string';

export type QueryName =
  | 'tags'
  | 'teams'
  | 'review'
  | 'dataClassification'
  | 'apec'
  | 'status'
  | 'criticality'
  | 'cyberweek'
  | 'support'
  | 'group'
  | 'type'
  | 'costCenter'
  | 'idsAndAliases'
  | 'lifecycle'
  | 'cyberWeekPhase'
  | 'cyberWeekToer'
  | 'prr'
  | 'category'
  | 'owner'
  | 'journey'
  | 'toolingStatus'
  | 'step'
  | 'scorecards';

class Query {
  static getQueryValue(key: 'tags'): string[];
  static getQueryValue(key: 'teams'): string[];
  static getQueryValue(key: 'lifecycle'): string;
  static getQueryValue(key: 'review'): IAppReview.ReviewStatus[];
  static getQueryValue(key: 'apec'): ApecStatusType[];
  static getQueryValue(key: 'status'): ICatalog.FilterYesOrNo;
  static getQueryValue(key: 'criticality'): string;
  static getQueryValue(key: 'prr'): ICatalog.FilterYesOrNever;
  static getQueryValue(key: 'cyberWeekPhase'): string[];
  static getQueryValue(key: 'cyberWeekToer'): string[];
  static getQueryValue(key: 'cyberweek'): ICatalog.FilterYesOrNo;
  static getQueryValue(key: 'support'): ICatalog.FilterYesOrNo;
  static getQueryValue(key: 'group'): ICatalog.GroupName;
  static getQueryValue(key: 'type'): string;
  static getQueryValue(key: 'costCenter'): string[];
  static getQueryValue(key: 'idsAndAliases'): string[];
  static getQueryValue(key: 'category'): string[];
  static getQueryValue(key: 'owner'): string[];
  static getQueryValue(key: 'journey'): string[];
  static getQueryValue(key: 'step'): string[];
  static getQueryValue(key: 'toolingStatus'): string[];
  static getQueryValue(
    key: QueryName,
  ): string | (string | null)[] | ICatalog.FilterYesOrNo | ICatalog.GroupName {
    const queryString = qs.parseUrl(location.href, {
      arrayFormat: 'comma',
    });
    switch (key) {
      case 'criticality':
      case 'prr':
      case 'status':
      case 'cyberweek':
      case 'support':
      case 'group':
      case 'type':
      case 'lifecycle':
        return queryString.query[key] || '';
      case 'tags':
      case 'cyberWeekPhase':
      case 'cyberWeekToer':
      case 'teams':
      case 'costCenter':
      case 'idsAndAliases':
      case 'review':
      case 'apec':
      case 'journey':
      case 'step':
      case 'toolingStatus':
      case 'owner':
      case 'dataClassification':
      case 'scorecards':
        /**
         * Value can be parsed as string if only one item is selected
         * it will be parsed into an array as soon as more items are added
         */
        // eslint-disable-next-line
        const value = queryString.query[key] || [];
        return Array.isArray(value) ? value : [value];
      default:
        return '';
    }
  }
}

const getQueryValue = Query.getQueryValue;
export { getQueryValue };

export type QueryNameObject = Record<QueryName, any>;

/**
 * Find the entity kind from the pathname
 * @throws if it doesn't match any of the predefined slugs
 * @returns The entity kind
 */
export function getKindFromPathname(): ICatalog.EntityType {
  const pageKind: Array<{ slug: string; kind: ICatalog.EntityType }> = [
    { slug: '/applications', kind: 'service' },
    { slug: '/api', kind: 'api' },
    { slug: '/docs', kind: 'documentation' },
    { slug: '/domains', kind: 'domain' },
    { slug: '/teams/toer-orgs', kind: 'toer' },
    { slug: '/teams/reporting-line', kind: 'reportingLine' },
    { slug: '/teams', kind: 'group' },
    { slug: '/tooling', kind: 'tooling' },
    { slug: '/compliance-and-security', kind: 'reportingLine' },
  ];
  // Add exception for page kind when in testing mode for cases where we can't manually change the location's pathname
  if (process.env.JEST_WORKER_ID && location.pathname === '/')
    pageKind.push({ slug: '/', kind: 'service' });

  const kind = pageKind.find(item =>
    location.pathname.startsWith(item.slug),
  )?.kind;

  if (!kind) {
    const allowedPathnames = pageKind.map(p => p.slug).join(',');
    throw new Error(
      `Unknown catalog pathname. Catalog pathname can only be ${allowedPathnames}`,
    );
  }

  return kind;
}
