import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { toolingApiRef, ToolingApis } from './api/ToolingApis';

export const toolingCatalogPlugin = createPlugin({
  id: 'tooling-catalog',
  apis: [
    createApiFactory({
      api: toolingApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }) => new ToolingApis(configApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ToolingCatalogPage = toolingCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ToolingCatalogPage',
    component: () =>
      import('./components/ToolingCatalogPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
