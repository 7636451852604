import { useEffect, useState } from 'react';
import { FetchLibrariesOptions, Library } from '../types';
import { useFetchLibraries } from './useFetchLibraries';

export const useLibraries = (
  options: FetchLibrariesOptions,
  pageNumber: number,
) => {
  const [libraries, setLibraries] = useState<Library[]>([]);
  const fetchLibraries = useFetchLibraries(options);
  const [next, setNext] = useState<string | null>(null);
  const [prev, setPrev] = useState<string | null>(null);

  useEffect(() => {
    fetchLibraries().then(newLibraries => {
      if (newLibraries && newLibraries.items) {
        const fetchedLibraries: Library[] = newLibraries.items.map(item => ({
          ...item,
          owners: [], // add the missing owners property
          applications: item.applications.filter(
            (app): app is IEntityApp => app !== undefined,
          ),
        }));
        setLibraries(fetchedLibraries);
        setNext(newLibraries.next);
        setPrev(newLibraries.prev);
      }
    });
  }, [fetchLibraries, pageNumber]);

  return {
    libraries,
    fetchLibraries,
    next,
    prev,
  };
};
