import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tableWrapper: {
    width: '100%',
  },
  table: {
    '& .tree-icon-btn': {
      position: 'relative',

      '& .icon-placeholder': {
        opacity: 0,
        visibility: 'hidden',
      },
      '& .icon-wrapper': {
        position: 'absolute',
        top: 5,
        left: 5,
        '& .icon': {
          position: 'absolute',
          top: 0,
          left: 0,
          transition: 'opacity 100ms linear, transform 100ms ease-in-out',
          opacity: 0,
          transform: 'rotate(90deg)',

          '&.show': {
            opacity: 1,
            transform: 'rotate(0deg)',
          },
        },
      },
    },
    '& th': {
      fontWeight: 700,
      color: 'inherit',
      paddingBlock: '1rem',
      wordBreak: 'keep-all',

      '& > button': {
        backgroundColor: 'transparent',
        padding: 0,
        border: 0,
        fontWeight: 'bold',
        display: 'inline-flex',
        color: theme.palette.text.primary,
        '&:active': {
          color: theme.palette.grey.A700,
        },
        '&:hover .sort-icon': {
          opacity: 1,
          '&:not(.sorted)': {
            color: theme.palette.text.disabled,
            fontWeight: 'normal',
          },
        },
        '&:not(:disabled)': {
          cursor: 'pointer',
        },
      },
      '& .sort-icon': {
        fontSize: '1.1em',
        transition: 'all 200ms linear',
        transformOrigin: 'center',
        opacity: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&.sorted': {
          opacity: 1,
          animation: 'bounce 200ms ease-in-out',
          '&.desc': {
            transform: 'rotateX(180deg)',
          },
        },
        '& > svg': {
          fontSize: 'inherit',
        },
      },
    },
    '& tr': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid var(--disabled-color, currentColor)',
      '&:nth-of-type(2n+1)': {
        backgroundColor: 'transparent',
      },
    },
    '& thead': {
      boxShadow:
        '0 1px 0px 0px var(--disabled-color, currentColor), 0 -1px 0px 0px var(--disabled-color, currentColor)',
    },
    '&.sticky thead': {
      top: -24,
      zIndex: 2,
      position: 'sticky',
      background: 'var(--paper-bg)',
    },
  },
  tableHeader: {
    padding: theme.spacing(2),
  },
  tableCell: {
    paddingBlock: '0.6rem',
  },
  tableCellExpand: {
    position: 'relative',
    paddingInlineEnd: '0.6rem',
    paddingInlineStart: 0,
    paddingBlock: '0.3rem 0.5rem',

    '&.show-guides > button': {
      '&:not([data-indent="1"])::before': {
        content: '""',
        position: 'absolute',
        height: '1000%',
        width: 1,
        bottom: '-200%',
        left: 'calc(-50% - 2px)',
        borderLeft: '1px solid var(--disabled-color)',
      },
      '&:not([data-has-children="false"])[data-expanded="true"]::after': {
        content: '""',
        position: 'absolute',
        height: '1000%',
        width: 1,
        top: '100%',
        left: '50%',
        borderLeft: '1px solid var(--disabled-color)',
      },
    },
  },
}));
