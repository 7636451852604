import { useAsync } from 'react-use';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';

export function useIframeURL(
  runId: string,
  stepId: string,
  stepRunId: string,
  src: string,
) {
  const discoveryApi = useApi(discoveryApiRef);

  async function getURL() {
    try {
      const url = await discoveryApi.getBaseUrl('cdp');
      return `${url}/runs/${runId}/steps/${stepId}/runs/${stepRunId}/testUploads/${src}`;
    } catch (error) {
      const e = error as Error;
      return Promise.reject(e);
    }
  }

  const { loading, error, value } = useAsync(getURL);

  return [{ loading, error, value }];
}
