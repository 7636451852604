import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import humanizeDuration from 'humanize-duration';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '30d',
    MM: '%d months',
    y: '1y',
    yy: '> 1y',
  },
});

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

type DateTimeObject = Date | string | number | undefined;

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const TIME_ZONE = dayjs.tz.guess();

export function timeAgo(time: DateTimeObject) {
  return dayjs(time).fromNow();
}

export function durationHumanized(
  startTime: DateTimeObject,
  endTime: DateTimeObject,
) {
  return shortEnglishHumanizer(
    dayjs.duration(dayjs(startTime).diff(dayjs(endTime))).asMilliseconds(),
    { round: true, spacer: '' },
  );
}

export function getFormattedDateTime(
  date: DateTimeObject,
  format = DEFAULT_DATE_TIME_FORMAT,
) {
  const time = dayjs(date).format(format);
  return `${time} ${TIME_ZONE}`;
}
