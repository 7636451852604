import { useAsync } from 'react-use';

export function useImagePreview(file: File | null | undefined) {
  return useAsync(async (): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(undefined);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }, [file]);
}
