const colors = new Map<string, { light: string; dark: string }>();

colors.set('--disabled-color', { light: '#b0b0b0', dark: '#909090' });
colors.set('--reference-chip-bg', { light: '#f0f0f0', dark: '#393939' });
colors.set('--reference-chip-hover-bg', { light: '#e3e3e3', dark: '#646464' });
colors.set('--reference-chip-border-color', {
  light: '#d7d7d7',
  dark: '#717171',
});
colors.set('--reference-chip-avatar-border-color', {
  light: '#a6a6a6',
  dark: '#e3e3e3',
});
colors.set('--mui-icon-color', { light: '#757575', dark: '#9a9a9a' });
colors.set('--mui-selected-list-item', { light: '#ebebeb', dark: '#616161' });
colors.set('--code-bg', { light: '#ececec', dark: '#272b30' });
colors.set('--code-font-color', { light: '#5f5f5f', dark: '#c5c5c5' });
colors.set('--mui-button-outlined-border', {
  light: 'currentColor',
  dark: 'currentColor',
});
colors.set('--swagger-ui-svg-filter', { light: 'none', dark: 'invert(1)' });
colors.set('--mui-pagination-page-selected', {
  light: '#e1e1e1',
  dark: '#717171',
});
colors.set('--mui-pagination-page-hover', {
  light: '#eaeaea',
  dark: '#686666',
});
colors.set('--mui-step-text-color', { light: '#fff', dark: '#000' });
colors.set('--settings-code-bg', { light: '#d9d9d9', dark: '#ebebeb' });
colors.set('--tr-bg', { light: '#f2f2f2', dark: '#1b1f22' });
colors.set('--mui-info-text', { light: '#2196f3', dark: '#a6d5fa' });
colors.set('--mui-info-border', { light: '#2196f3', dark: '#64b5f6' });
colors.set('--primary-button-disabled-color', {
  light: 'rgba(0, 0, 0, 0.26)',
  dark: '#909090',
});
colors.set('--primary-button-disabled-bg', {
  light: 'rgba(0, 0, 0, 0.12)',
  dark: '#616161',
});
colors.set('--tooling-accent', {
  light: 'rgb(15,80,140)',
  dark: 'rgb(58 160 255)',
});
colors.set('--zflow-accent', {
  light: '#9A22AD',
  dark: '#f093ff',
});

export { colors };
