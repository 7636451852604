import React from 'react';
import { VALIDATION_PROBLEM_DOMAIN } from '../../../constants';
import { IValidationProblem } from '../../../api/types/payload';
import { Label, Reference } from './common';

type Props = {
  validationProblem: IValidationProblem;
};

export function ValidationProblem({ validationProblem }: Props) {
  return (
    <div>
      <span>
        {validationProblem.message}
        {validationProblem.domain && (
          <Label text={validationProblem.domain} title="Rule owner" />
        )}
        {validationProblem.domain === VALIDATION_PROBLEM_DOMAIN.security &&
          validationProblem.rule_id && (
            <Label text={validationProblem.rule_id} title="Rule ID" />
          )}
        {validationProblem.metadata?.reference_url && (
          <Reference URL={validationProblem.metadata.reference_url} />
        )}
      </span>
    </div>
  );
}
