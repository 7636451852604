import React from 'react';
import { Button, ButtonProps, LinkProps } from '@material-ui/core';
import { PluginTracking } from 'plugin-ui-components';

type Props = ButtonProps & ITrackingEvent & Pick<LinkProps, 'target'>;

export const ExternalTrackedButton: React.FC<Props> = (props: Props) => {
  const {
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
    ...buttonProps
  } = props;

  const handleOnClick = (event: any) => {
    PluginTracking.sendEvent({
      plugin,
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
    if (buttonProps.onClick) {
      buttonProps.onClick(event);
    }
  };

  return <Button {...buttonProps} onClick={handleOnClick} />;
};
