import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../api';
import { NotFoundException } from '../../../../api/exceptions';
import { STEP_PRE_IN_PROGRESS_STATUSES } from '../../../../constants';

export function useTestUploads(
  runId: string,
  stepId: string,
  stepRunId: string,
  status: string,
) {
  const api = useApi(cdpApiRef);

  const getTestUploads = useCallback(async () => {
    if (!stepRunId || STEP_PRE_IN_PROGRESS_STATUSES.includes(status)) {
      return Promise.resolve();
    }

    try {
      const testResults = await api.getTestUploads(runId, stepId, stepRunId);

      return Promise.resolve(testResults);
    } catch (error) {
      const e = error as Error;
      if (!(e instanceof NotFoundException)) {
        return Promise.reject(e);
      }

      return Promise.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepRunId, status]);

  const { loading, error, value, retry } = useAsyncRetry(
    () => getTestUploads(),
    [getTestUploads, status],
  );

  return [
    { loading, error, value },
    { getTestUploads, retry },
  ] as const;
}
