import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

interface RouteGuardProps extends PropsWithChildren {
  canAccess: boolean;
  redirectUrl: string;
}

export function RouteGuard({
  canAccess,
  redirectUrl,
  children,
}: RouteGuardProps) {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!canAccess) navigate(redirectUrl);
  }, [canAccess, redirectUrl, navigate]);

  return <>{canAccess && children}</>;
}
