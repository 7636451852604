import { IStackset } from "../../api/types/payload"

export class StacksetModel {
  traffic: {
    [key: string]: {
      actual: number
      desired: number
    }
  }

  constructor(json: IStackset = {}) {
    this.traffic = {};

    json.spec?.traffic?.forEach(version => {
      if (!version.stackName) return;
      if (!this.traffic[version.stackName]) {
        this.traffic[version.stackName] = { actual: 0, desired: 0};
      }
      this.traffic[version.stackName].desired = version.weight || 0;
    })

    json.status?.traffic?.forEach(version => {
      if (!version.stackName) return;
      if (!this.traffic[version.stackName]) {
        this.traffic[version.stackName] = { actual: 0, desired: 0};
      }
      this.traffic[version.stackName].actual = version.weight || 0;
    })
  }
}