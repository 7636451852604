/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable no-extend-native */
import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

type LongOrShort = 'long' | 'short';
declare global {
  interface Date {
    toISODateString(type?: LongOrShort): string;
    getWeekNumber(): number;
  }
}

Date.prototype.toISODateString = function (type = 'long') {
  if (this.toString() === 'Invalid Date') return '';

  const [dateString, timestamp] = this.toISOString().split('T');
  switch (type) {
    case 'short':
      return dateString;
    case 'long':
      return dateString + ' ' + timestamp.split(':').slice(0, 2).join(':');
    default:
      throw new Error(
        'Date.toISODateString param "type" value should be either "long" or "short"',
      );
  }
};

Date.prototype.getWeekNumber = function () {
  if (this.toString() === 'Invalid Date') return 0;
  // Get the date of the first day of the year
  const yearStart = new Date(Date.UTC(this.getFullYear(), 0, 1));
  // Calculate the difference in milliseconds between the target date and the first day of the year
  const timeDifference = this.getTime() - yearStart.getTime();
  // Calculate the number of weeks by dividing the time difference by the milliseconds in a week
  return Math.ceil(timeDifference / (7 * 24 * 60 * 60 * 1000));
};

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
