import { createApiRef } from '@backstage/core-plugin-api';
import { LogFoldsResponse, LogLinesResponse } from './types/responses';

export const scalyrApiRef = createApiRef<ScalyrApi>({
  id: 'plugin.cdp.scalyr',
});

export interface ScalyrApi {
  getFolds(
    startTime: string,
    endTime: string,
    stepRunId: string,
  ): Promise<LogFoldsResponse>;

  getLines(
    fold: string,
    startTime: number,
    endTime: number,
    stepRunId: string,
    initialCount: number,
    limitCount: number,
  ): Promise<Array<LogLinesResponse>>;

  getAllLines(
    startTime: string,
    endTime: string,
    stepRunId: string,
    initialCount: number,
    limitCount: number,
  ): Promise<Array<LogLinesResponse>>;

  getDownloadLink(
    startTime: Date,
    endTime: Date,
    stepRunId: string,
  ): Promise<string>;
}
