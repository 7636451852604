import React from 'react';

export function makeTooltipText(
  name: string,
  details?: React.ReactNode,
  forceShow?: boolean,
) {
  const detailsText = details ? (
    <>
      <br />
      <br />
      Details:
      <br />
      {details}
    </>
  ) : null;

  if (forceShow) {
    return (
      <>
        This feature is in Open Beta testing.
        {detailsText}
      </>
    );
  }

  return (
    <>
      This feature is in Beta testing and it's enabled by{' '}
      <code style={{ backgroundColor: '#545454' }}>{name}</code> feature flag.
      {detailsText}
    </>
  );
}
