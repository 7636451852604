import { DiscoveryApi } from '@backstage/core-plugin-api';
import { escapeNestedParams } from '../utils/string';
import { ScalyrApi } from './scalyrApi';
import { LogLinesResponse, LogFoldsResponse } from './types/responses';
import { parseScalyrResponse } from './utils';
import { refreshAccessTokenIfExpiring } from 'plugin-core';

export class ScalyrClient implements ScalyrApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async getDownloadLink(
    startTime: Date,
    endTime: Date,
    stepRunId: string,
  ): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const params = {
      startTime: startTime.getTime().toString(),
      endTime: endTime.getTime().toString(),
      stepID: stepRunId,
    };
    const endpoint = `${proxyUrl}/scalyr/api/logfile?${new URLSearchParams(
      params,
    )}`;

    const downloadLogFileURL: { fileURL: string } = await this.get(endpoint);

    return downloadLogFileURL.fileURL;
  }

  async getFolds(
    startTime: string,
    endTime: string,
    stepRunId: string,
  ): Promise<LogFoldsResponse> {
    const params = {
      startTime,
      endTime,
      query: `cdp_step_id='${stepRunId}' | sort timestamp | group start=first(timestamp), end=last(timestamp) by cdp_log_fold | sort start`,
    };

    return this.get<LogFoldsResponse>(
      await this.powerQuery(new URLSearchParams(params)),
    );
  }

  async getLines(
    fold: string,
    startTime: number,
    endTime: number,
    stepRunId: string,
    initialCount: number,
    limitCount: number,
  ): Promise<Array<LogLinesResponse>> {
    return this.paginate(
      {
        startTime: startTime.toString(),
        endTime: endTime.toString(),
        maxCount: '5000',
        filter: `cdp_step_id='${stepRunId}' cdp_log_fold='${escapeNestedParams(
          fold,
        )}'`,
      },
      initialCount,
      limitCount,
    );
  }

  async getAllLines(
    startTime: string,
    endTime: string,
    stepRunId: string,
    initialCount: number,
    limitCount: number,
  ): Promise<Array<LogLinesResponse>> {
    return this.paginate(
      {
        startTime: startTime,
        endTime: endTime,
        maxCount: '5000',
        filter: `cdp_step_id='${stepRunId}'`,
      },
      initialCount,
      limitCount,
    );
  }

  private async paginate(
    params: {
      startTime: string;
      endTime: string;
      maxCount: string;
      filter: string;
    },
    initialCount: number,
    limitCount: number,
  ): Promise<Array<LogLinesResponse>> {
    const responses: Array<LogLinesResponse> = [];
    await this.reduce(
      async (continuationToken?: string) => {
        const paramsWithToken = continuationToken
          ? { ...params, continuationToken }
          : params;
        return await this.get<LogLinesResponse>(
          await this.query(new URLSearchParams(paramsWithToken)),
        );
      },
      responses,
      initialCount,
      limitCount,
    );

    return responses;
  }

  private async get<T>(endpoint: string): Promise<T> {
    // Check and in case refresh expiring token
    await refreshAccessTokenIfExpiring(this.discoveryApi);
    // Authentication is made via SessionId Cookie (credentials: 'include' required)
    // in request so Authentication header with token is not needed
    const response = await fetch(endpoint, {
      credentials: 'include',
    });

    return await parseScalyrResponse(response);
  }

  async powerQuery(urlParams: URLSearchParams): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}/scalyr/api/powerQuery?${urlParams}`;
  }

  async query(urlParams: URLSearchParams): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}/scalyr/api/query?${urlParams}&columns=message,timestamp`;
  }

  reduce = async (
    fn: (continuationToken?: string) => Promise<LogLinesResponse>,
    acc: Array<LogLinesResponse>,
    initialCount: number,
    limitCount: number,
  ) => {
    if (
      acc.reduce((sum, item) => sum + item.matches.length, initialCount) >=
      limitCount
    ) {
      return;
    }

    acc.push(await fn());

    if (acc[acc.length - 1].continuationToken) {
      await this.reduce(
        () => fn(acc[acc.length - 1].continuationToken),
        acc,
        initialCount,
        limitCount,
      );
    }
  };
}
