import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const toerOrgPlugin = createPlugin({
  id: 'toer-org',
  routes: {
    root: rootRouteRef,
  },
});

export const ToerOrgPage = toerOrgPlugin.provide(
  createRoutableExtension({
    name: 'ToerOrgPage',
    component: () =>
      import('./components/ToerOrgPage').then(m => m.ToerOrgPage),
    mountPoint: rootRouteRef,
  }),
);
