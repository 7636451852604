import React, { useMemo, useState } from 'react';

import { generateKey, getColorByStatus, parseNanoTime } from './utils';
import { IJUnitTestResult } from '../../../../../api/types/payload';

import * as S from './styles';
import { TestItem } from './TestItem';

export function TestFold({ data }: { data: IJUnitTestResult }) {
  const { name, tests, totals } = data;
  const [isOpen, setIsOpen] = useState(false);
  const styles = S.useStyles();

  function handleClick() {
    if (tests && tests.length) {
      setIsOpen(!isOpen);
    }
  }

  function handleKeyboardClick(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key || e.keyCode) === 'Enter') {
      setIsOpen(!isOpen);
    }
  }

  const tags = useMemo(() => {
    const statuses: Record<string, number> = {
      tests: totals.tests,
      passed: totals.passed,
      skipped: totals.skipped,
      failed: totals.failed,
      error: totals.error,
    };

    return Object.keys(statuses).map(status => (
      <S.Column key={status}>
        <span
          // NOTE: Inline styles have been added due to performance issue with using Material-UI
          style={{
            color: statuses[status]
              ? getColorByStatus(status)
              : 'rgba(0,0,0,0.4)',
          }}
        >
          {statuses[status]}
        </span>
      </S.Column>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.TestFold>
      <div
        role="button"
        className={isOpen ? styles.foldHeaderOpen : styles.foldHeader}
        style={{ cursor: !tests ? 'default' : 'pointer' }}
        onClick={handleClick}
        onKeyDown={handleKeyboardClick}
        tabIndex={0}
      >
        <S.Column>
          <S.TestFoldTitle title={name}>{name}</S.TestFoldTitle>
        </S.Column>

        <S.Column>
          <strong>{parseNanoTime(totals.duration)}</strong>
        </S.Column>

        {tags}
      </div>

      {tests &&
        isOpen &&
        tests.map((test, i) => (
          <TestItem
            {...test}
            key={generateKey(`${test.name}_${test.duration}_${i}`)}
          />
        ))}
    </S.TestFold>
  );
}
