import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    '&.disabled': {
      color: theme.palette.grey[500],
      textDecoration: 'none',
      cursor: 'default',
    },
  },
  linkText: {
    marginLeft: '.5rem',
    lineHeight: '1.2',
  },
}));
