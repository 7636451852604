import React from 'react';
import { NotFoundPage } from 'plugin-ui-components';
import { Route, Routes } from 'react-router';
import {
  eventsCatalogueRouterRef,
  nakadiSqlQueryLandingPageRoute,
} from './routes';
import { EventsTab } from './components/EventsTab';
import { NSQLQueryLandingPage } from './components/NSLQQueryLandingPage';

export const NakadiRouter = () => {
  return (
    <Routes>
      <Route
        path={`/${eventsCatalogueRouterRef.params}`}
        element={<EventsTab />}
      />
      <Route
        path={`/queries/${nakadiSqlQueryLandingPageRoute.params[0]}/edit`}
        element={<NSQLQueryLandingPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
