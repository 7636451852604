import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import { ChipLabel } from './ChipLabel';
import { UserReferenceCard } from './UserReferenceCard';
import { TrackedLink, TrackedLinkProps } from '../Tracking';
import { Link, LinkProps } from '@backstage/core-components';
import { PluginTracking } from 'plugin-ui-components';
import { useReferenceContext } from '../../context';
import { RequestState } from '../TeamReference/types';

interface Props {
  /**
   * @type {(string | IEntityUser)}
   */
  user?: string | IEntityUser;
  tracking?: ITrackingEvent;
  size?: 'small' | 'medium';
  displayType?: 'full_name' | 'login';
  header?: React.ReactNode;
}

export function UserReference({
  user,
  tracking,
  size = 'small',
  displayType = 'full_name',
  header,
}: Props) {
  const { getUser } = useReferenceContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const userCardRef = React.useRef<HTMLDivElement>(null);
  const chipRef = React.useRef<HTMLDivElement>(null);
  const [{ error, value, loading }, setRequestState] = React.useState<
    RequestState<IEntityUser>
  >({
    value: undefined,
    error: undefined,
    loading: true,
  });

  const open = Boolean(anchorEl);

  function onUserHover(ev: React.MouseEvent<HTMLDivElement>) {
    PluginTracking.sendEvent({
      plugin: 'app',
      eventLabel: `User hovered over user reference chip`,
      eventCategory: 'User reference chip',
      eventAction: 'hover',
    });
    setAnchorEl(ev.currentTarget);
  }

  function handleClose() {
    setAnchorEl(undefined);
  }

  const isEntity = typeof user !== 'string';

  React.useEffect(() => {
    if (!isEntity) {
      getUser(user)
        .then(data => {
          if (typeof data !== 'string') {
            setRequestState(prev => ({ ...prev, value: data, loading: false }));
          }
        })
        .catch(err =>
          setRequestState(prev => ({ ...prev, error: err, loading: false })),
        );
    } else {
      setRequestState(prev => ({ ...prev, value: user, loading: false }));
    }
  }, [getUser, isEntity, user]);

  if (!user) return <></>;

  const login = typeof user === 'string' ? user : value?.metadata.name;
  const link = `/catalog/default/user/${
    typeof user === 'string' ? user : login
  }`;
  const interactive = !loading && !error && !!value;
  const LinkComponent = tracking ? TrackedLink : Link;
  let linkProps: LinkProps | TrackedLinkProps = { to: link };
  if (tracking) linkProps = { ...linkProps, ...tracking };
  const full_name =
    value?.metadata.zalandoMetadata.name ||
    value?.metadata.zalandoMetadata.full_name;
  const picture = value?.spec.profile.picture;
  return (
    <div style={{ display: 'inline-block' }}>
      <LinkComponent {...(linkProps as any)}>
        <Chip
          ref={chipRef}
          size={size}
          avatar={
            <Avatar
              alt="Profile picture"
              src={
                value?.metadata.zalandoMetadata.deleted
                  ? '/deleted-user.png'
                  : picture
              }
            />
          }
          label={
            <ChipLabel
              loading={loading}
              error={error}
              value={displayType === 'full_name' ? full_name : login}
              defaultValue={String(user)}
              active={
                value?.metadata.zalandoMetadata.inactive ||
                value?.metadata.zalandoMetadata.deleted
              }
            />
          }
          clickable
          onMouseEnter={interactive ? onUserHover : undefined}
          className="reference-chip"
        />
      </LinkComponent>
      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        onClose={handleClose}
        onMouseMove={({ clientX, clientY }) => {
          const targets = document.elementsFromPoint(clientX, clientY);
          if (
            !targets.some(
              t =>
                chipRef.current?.contains(t) ||
                userCardRef.current?.contains(t),
            )
          ) {
            handleClose();
          }
        }}
      >
        {interactive && (
          <UserReferenceCard
            ref={userCardRef}
            user={value}
            tracking={tracking}
            header={header}
          />
        )}
      </Popover>
    </div>
  );
}
