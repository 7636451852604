import React from 'react';
import { Box, Button, Theme, Typography, makeStyles } from '@material-ui/core';
import { VersionsFilter } from './VersionsFilter';
import { LibrariesFilter } from './LibrariesFilter';
import { LibraryOwnerFilters } from './LibraryOwnerFilters';

const useStyles = makeStyles<Theme, {}>((theme: Theme) => ({
  filterWrapper: {
    borderRadius: 4,
  },
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

interface RefinedFiltersProps {
  handleVersionFilter: (value: any) => void;
  filteredVersions: string[];
  handleLibsFilter: (value: any) => void;
  filteredLibs: string[];
  onClearFilters: (ev: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const RefinedFilters: React.FunctionComponent<RefinedFiltersProps> = ({
  onClearFilters,
  handleLibsFilter,
  handleVersionFilter,
  filteredLibs,
  filteredVersions,
}) => {
  const { inputRoot } = useStyles();
  return (
    <>
      <LibraryOwnerFilters />
      <Box
        display="flex"
        flexDirection="column"
        marginTop="0.5rem"
        gridRowGap="0.5rem"
        padding="0.5rem"
        style={{ borderRadius: 4 }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          flexWrap="wrap"
        >
          <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
            Refine Results
          </Typography>
          <Button
            onClick={onClearFilters as any}
            style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
            variant="text"
          >
            Clear
          </Button>
          <LibrariesFilter
            handleLibsFilter={handleLibsFilter}
            filteredLibs={filteredLibs}
            inputRoot={inputRoot}
          />
          {!!filteredLibs.length && (
            <VersionsFilter
              inputRoot={inputRoot}
              handleVersionFilter={handleVersionFilter}
              filteredVersions={filteredVersions}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
