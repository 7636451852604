import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_ZONE = dayjs.tz.guess();

type DateTimeObject = Date | string | number | undefined;

export function getFormattedDateTime(
  date: DateTimeObject,
  format: string = DEFAULT_DATE_TIME_FORMAT,
) {
  const time = dayjs(date).format(format);
  return `${time} ${TIME_ZONE}`;
}

export function getDateTimeDiff(
  starttime: string,
  endtime: string,
  format: string = DEFAULT_DATE_TIME_FORMAT,
) {
  const starttime_formatted = dayjs(starttime).format(format);
  const endtime_formatted = dayjs(endtime).format(format);

  const starttime_parsed = Date.parse(starttime_formatted);
  const endtime_parsed = Date.parse(endtime_formatted);
  const runtime = endtime_parsed - starttime_parsed;
  return String(msToHMS(runtime));
}

function msToHMS(milliseconds: number) {
  const sec_in_ms = 1000;
  const min_in_ms = 60 * sec_in_ms;
  const hour_in_ms = 60 * min_in_ms;

  let hours_string = '';
  let minutes_string = '';
  let seconds_string = '';

  const hours = Math.floor(milliseconds / hour_in_ms);
  if (hours > 0) {
    hours_string = `${hours}h `;
  }
  const minutes = Math.floor((milliseconds - hours * hour_in_ms) / min_in_ms);
  if (minutes > 0) {
    minutes_string = `${minutes}m `;
  }
  const seconds =
    (milliseconds - (hours * hour_in_ms + minutes * min_in_ms)) / sec_in_ms;
  if (seconds > 0) {
    seconds_string = `${seconds}s `;
  }

  return `${hours_string}${minutes_string}${seconds_string}`;
}
