import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useServices } from '../../../../services';
import { Error, GridItem, StatusLog } from '../../../common';

export const ZesDetails = observer(
  ({
    cdpDeploymentId,
    cdpError,
  }: {
    cdpDeploymentId: string;
    cdpError: string;
  }) => {
    const { zesService } = useServices();

    useEffect(() => {
      zesService.getTaskDetails(cdpDeploymentId);
    }, [zesService, cdpDeploymentId]);

    const zesErrorMsg = zesService.details.title
      ? `ZES ${zesService.details.title}: ${zesService.details.message}`
      : '';
    const errorMsg = `${cdpError}\n\n${zesErrorMsg}`;

    return (
      <GridItem>
        <Error error={errorMsg} />
        {zesService.details && zesService.details.logs.length > 0 && (
          <StatusLog statusLog={zesService.details.logs} />
        )}
      </GridItem>
    );
  },
);
