import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Chip, CircularProgress } from '@material-ui/core';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../common';
import { ReviewContext } from '../context';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../api';
import { useAsync } from 'react-use';

// TODO: Should verify if all tech is in `ADOPT phase`
export function PlatformsAndLanguagesStats() {
  const { issues } = React.useContext(ReviewContext);
  const { entity } = useEntity();
  const api = useApi(applicationReviewApiRef);

  const { loading, value } = useAsync(
    () => api.getApplicationInfo(entity.metadata.name),
    [entity],
  );

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  const issue = issues.find(i => i.field === 'tech_info');

  if (value?.tech.length) {
    return (
      <>
        {value.tech.map((item, i) => (
          <Chip key={i} label={item} />
        ))}
        <InfoTooltip>
          Platforms and programming languages are detected via GHE/GitHub
          repository language detection and OpenTracing/LightStep reporter
          information. Note that the information might be outdated (e.g. if a
          new JRE version was deployed recently) or wrong (e.g. if the git
          repository link is wrong).
        </InfoTooltip>
        <Issue {...issue} />
      </>
    );
  }

  return (
    <>
      No tech stack could be determined (yet) for this application.{' '}
      <InfoTooltip>
        Platforms and programming languages are detected via GHE/GitHub
        repository language detection and OpenTracing/LightStep reporter
        information. Note that the information might be outdated (e.g. if a new
        JRE version was deployed recently) or wrong (e.g. if the git repository
        link is wrong).
      </InfoTooltip>
      <Issue {...issue} />
    </>
  );
}
