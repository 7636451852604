import React, { PropsWithChildren } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Children from 'react-children-utilities';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useStyles } from './styles';

interface Props extends PropsWithChildren<{ textToCopy?: string }> {}

export function CopyField({ children, textToCopy }: Props) {
  const styles = useStyles();
  const unmounted = React.useRef(false);
  const [copied, setCopied] = React.useState(false);

  function copyText() {
    const textContent = textToCopy ? textToCopy : Children.onlyText(children);
    navigator.clipboard.writeText(textContent).then(() => {
      setCopied(true);

      setTimeout(() => {
        if (!unmounted.current) setCopied(false);
      }, 3000);
    });
  }

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const Icon = copied ? DoneIcon : FileCopyIcon;

  return (
    <span className={styles.root}>
      {children}
      <Tooltip title={copied ? 'Copied!' : 'Copy'} arrow>
        <div className="copy-button">
          <IconButton size="small" onClick={copyText} disabled={copied}>
            <Icon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
    </span>
  );
}
