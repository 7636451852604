import { useMedia } from 'react-use';

export function useMediaMobile() {
  return useMedia('(max-width: 480px)');
}

export function useMediaTablet() {
  return useMedia('(max-width: 768px)');
}

export function useMediaDesktop() {
  return useMedia('(min-width: 769px)');
}
