import React, {
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApi } from '@backstage/core-plugin-api';
import {
  HStack,
  PluginTracking,
  TrackedInteraction,
  useConfirmationModal,
  VStack,
} from 'plugin-ui-components';
import { essentialsApiRef, mintStorageApiRef } from '../../../../api';
import {
  IResourceType,
  IResourceTypeScopes,
} from '../../../../api/types/payload';
import { useStyles } from './styles';
import {
  common as commonTracking,
  createScope,
  deleteResourceType,
  deleteScope,
  editResourceType,
  editScope,
  openResourceTypeMenu,
} from './tracking';
import ScopeDetails from '../ScopeDetails';
import { AccessControlContext } from '../../context';
import { useMutation, useQuery } from 'react-query';
import { Alert, Skeleton } from '@material-ui/lab';

function MenuGroup({ children, title }: PropsWithChildren<{ title: string }>) {
  return (
    <VStack padding="0.8rem">
      <Typography variant="body2">
        <b>{title}</b>
      </Typography>
      <VStack>{children}</VStack>
    </VStack>
  );
}

export function ResourceTypesItem({
  id = '',
  hasEditPermission,
  refreshAppResourceTypes,
}: IResourceType & {
  hasEditPermission: boolean | undefined;
  refreshAppResourceTypes: Function;
}) {
  const styles = useStyles();
  const { setEditState } = useContext(AccessControlContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [_, setSearchParams] = useSearchParams();
  const [selectedScope, setSelectedScope] = useState<{
    id: string;
    summary: string;
    resourceId: string;
  }>();
  const location = useLocation();
  const essentialApi = useApi(essentialsApiRef);
  const mintStorageApi = useApi(mintStorageApiRef);
  const searchParams = new URLSearchParams(location.search);
  const open = Boolean(anchorEl);

  // Delete confirmation modals
  const {
    ConfirmationDialog: ResourceTypeDeleteConfirmation,
    openConfirmation: openResourceTypeDeleteConfirmation,
    closeConfirmation: closeResourceTypeDeleteConfirmation,
  } = useConfirmationModal();
  const {
    ConfirmationDialog: ScopeDeleteConfirmation,
    openConfirmation: openScopeDeleteConfirmation,
    closeConfirmation: closeScopeDeleteConfirmation,
  } = useConfirmationModal();

  // Delete mutations resource types and scopes
  const deleteScopeMutation = useMutation({
    mutationFn: async (args: { scopeId: string; resourceTypeId: string }) => {
      // Check if the scope has linked applications before deleting
      const scopeApps = await mintStorageApi.getAppsFromScope(
        args.resourceTypeId,
        args.scopeId,
      );
      if (!scopeApps.length) {
        return essentialApi.deleteScope(args.resourceTypeId, args.scopeId);
      }
      // Tracking the event when the scope has linked applications
      PluginTracking.sendEvent({
        ...commonTracking,
        eventLabel: 'Invalid scope deletion in access conrol',
        eventAction: `Scope ${args.scopeId} deletion failed due to linked applications.`,
      });
      throw new Error(
        'This scope has linked applications. Please reach out to the consumers and ask them to remove their usage before deleting the scope.',
      );
    },
    onSuccess: () => {
      // Refreshing the resource types list after deletion
      refreshAppResourceTypes();
      closeScopeDeleteConfirmation();
    },
  });
  const deleteResourceTypeMutation = useMutation({
    mutationFn: (resourceId: string) =>
      essentialApi.deleteResourceType(resourceId),
    onSuccess: () => {
      // Refreshing the resource types list after deletion
      refreshAppResourceTypes();
      closeResourceTypeDeleteConfirmation();
    },
  });

  const handleEditResourceType = () => {
    setEditState(state => ({ ...state, resourceTypeEditPage: true }));
    setSearchParams({
      resourceId: id,
    });
  };
  const handleCreateScope = () => {
    setEditState(state => ({ ...state, resourceTypeScopeCreatePage: true }));
    setSearchParams({
      resourceId: id,
      scopeCreate: 'true',
    });
  };
  const handleEditScope = (scopeId: string = '') => {
    setEditState(state => ({ ...state, resourceTypeScopeEditPage: true }));
    setSearchParams({
      resourceId: id,
      scopeId,
    });
  };
  const handleScopeSelect = (
    scope: IResourceTypeScopes,
    resourceId: string,
  ) => {
    setSelectedScope({
      id: scope.id || '',
      summary: scope.summary || '',
      resourceId,
    });
    setDrawerOpen(true);
    // Updating URL with query strings to match selected scope
    setSearchParams({
      resourceId: resourceId,
      scope: scope.id || '',
    });
  };

  // Fetching resource type details and scopes
  const {
    data: details,
    isLoading: isLoadingDetails,
    isError: isErrorDetails,
  } = useQuery(
    `resource-details-${id}`,
    async () => await essentialApi.getResourceTypeDetails(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: scopes,
    isLoading: isLoadingScopes,
    isError: isErrorScopes,
    isRefetching: isRefetchingScopes,
  } = useQuery(
    `resource-scopes-${id}`,
    async () => await essentialApi.getScopesFromResource(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (details && scopes) {
      const resourceTypeQuery = searchParams.get('resourceId');
      const scopeQuery = searchParams.get('scope');
      if (resourceTypeQuery && scopeQuery) {
        const resourceTypeScope = scopes.find(scope => scope.id && scopeQuery);
        if (resourceTypeQuery === details.id && resourceTypeScope) {
          handleScopeSelect(resourceTypeScope, resourceTypeQuery);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, scopes]);

  const handleCloseDrawer = () => {
    navigate(location.pathname);
    setDrawerOpen(false);
  };

  return (
    <tr>
      {/* Loading data */}
      {(isLoadingDetails || isLoadingScopes || isRefetchingScopes) && (
        <td colSpan={3}>
          <Box padding={1}>
            <Skeleton height={15} animation="wave" />
          </Box>
        </td>
      )}

      {/*  Error handling */}
      {(isErrorDetails || isErrorScopes) && (
        <td colSpan={3}>
          <Alert severity="error">
            Error fetching Resource Type details. Try again.
          </Alert>
        </td>
      )}

      {!isRefetchingScopes && scopes && details && (
        <>
          <td>
            <Typography variant="body2" className={styles.mediumBold}>
              {id}
            </Typography>
            <Tooltip title={details?.description ?? ''} arrow placement="top">
              <Typography
                variant="body2"
                color="textSecondary"
                className={styles.description}
              >
                {details.description}
              </Typography>
            </Tooltip>
          </td>
          <td className={styles.textCenter}>
            {!scopes.length && (
              <Typography variant="body2">No scopes</Typography>
            )}
            {scopes.map(scope => (
              <HStack alignItems="center">
                <Button
                  color="primary"
                  onClick={() => handleScopeSelect(scope, id)}
                >
                  {scope.id}
                </Button>
              </HStack>
            ))}
            {selectedScope && (
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
              >
                <ScopeDetails scope={selectedScope} />
              </Drawer>
            )}
          </td>
          <td className={styles.textCenter}>
            <TrackedInteraction
              interaction="onClick"
              {...openResourceTypeMenu()}
            >
              {hasEditPermission ? (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={event => setAnchorEl(event.currentTarget)}
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>
              ) : (
                <Tooltip
                  title="Only the owner can edit the application"
                  placement="top"
                >
                  <span>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      size="small"
                      disabled
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </TrackedInteraction>
            <Menu
              id="long-menu"
              style={{ height: '500px' }}
              MenuListProps={{
                'aria-labelledby': 'long-button',
                classes: {
                  root: styles.menu,
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                classes: {
                  root: styles.menuPaper,
                },
              }}
            >
              <TrackedInteraction interaction="onClick" {...createScope()}>
                <MenuItem
                  className={styles.menuItem}
                  key="d-key"
                  onClick={handleCreateScope}
                  dense
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Create new Scope" />
                </MenuItem>
              </TrackedInteraction>
              <Divider className={styles.divider} />
              <MenuGroup title="Resource Type">
                <TrackedInteraction
                  interaction="onClick"
                  {...editResourceType()}
                >
                  <MenuItem
                    className={styles.menuItem}
                    disableGutters
                    key="a-key"
                    onClick={handleEditResourceType}
                    dense
                  >
                    <ListItemIcon className={styles.listIcon}>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                  </MenuItem>
                </TrackedInteraction>
                <TrackedInteraction
                  interaction="onClick"
                  {...deleteResourceType()}
                >
                  {/* Disable Resource Type deletion when it has linked scopes */}
                  {scopes.length ? (
                    <TrackedInteraction
                      interaction="onMouseEnter"
                      {...deleteResourceType('hover')}
                    >
                      <Tooltip
                        title="This Resource Type has linked scopes, delete them first."
                        placement="top"
                      >
                        <span>
                          <MenuItem
                            disableGutters
                            className={styles.menuItem}
                            dense
                            disabled
                          >
                            <ListItemIcon className={styles.listIcon}>
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                          </MenuItem>
                        </span>
                      </Tooltip>
                    </TrackedInteraction>
                  ) : (
                    <MenuItem
                      disableGutters
                      className={styles.menuItem}
                      key="a-key"
                      onClick={() => openResourceTypeDeleteConfirmation()}
                      dense
                    >
                      <ListItemIcon className={styles.listIcon}>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Delete" />
                    </MenuItem>
                  )}
                </TrackedInteraction>
              </MenuGroup>
              {!!scopes.length && (
                <>
                  <Divider className={styles.divider} />
                  <MenuGroup title="Scopes">
                    {scopes.map(scope => (
                      <MenuItem
                        className={styles.menuItemNoHoverEffect}
                        disableGutters
                        disableRipple
                        key={`scope-${scope.id}-menu`}
                        onClick={e => e.preventDefault()}
                        dense
                      >
                        <ListItemText
                          primary={`${scope.id?.toLocaleUpperCase()}`}
                        />
                        <HStack spacing=".3rem">
                          <TrackedInteraction
                            interaction="onClick"
                            {...editScope(scope.id)}
                          >
                            <IconButton
                              aria-label="edit-scope"
                              onClick={e => {
                                e.stopPropagation();
                                handleEditScope(scope.id);
                              }}
                              size="small"
                            >
                              <EditIcon
                                fontSize="small"
                                htmlColor="var(--mui-icon-color)"
                              />
                            </IconButton>
                          </TrackedInteraction>
                          <TrackedInteraction
                            interaction="onClick"
                            {...deleteScope(scope.id)}
                          >
                            <IconButton
                              aria-label="delete-scope"
                              onClick={e => {
                                e.stopPropagation();
                                setSelectedScope({
                                  id: scope.id || '',
                                  summary: scope.summary || '',
                                  resourceId: id,
                                });
                                openScopeDeleteConfirmation();
                              }}
                              size="small"
                            >
                              <DeleteIcon
                                fontSize="small"
                                htmlColor="var(--mui-icon-color)"
                              />
                            </IconButton>
                          </TrackedInteraction>
                        </HStack>
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </>
              )}
            </Menu>

            {/* Scope deletion modal/logic */}
            <ScopeDeleteConfirmation
              saveButtonText="Delete"
              onSave={() => {
                if (selectedScope) {
                  deleteScopeMutation.mutate({
                    scopeId: selectedScope.id,
                    resourceTypeId: id,
                  });
                }
              }}
            >
              {deleteScopeMutation.isLoading && <LinearProgress />}
              {deleteScopeMutation.isError && (
                <Alert severity="error">
                  <b>There was an error deleting this scope:</b>{' '}
                  {(deleteScopeMutation.error as Error).message}.
                </Alert>
              )}
              <Box mt={1}>
                <Typography variant="body1">
                  Are you sure you want to delete this Scope?
                </Typography>
              </Box>
            </ScopeDeleteConfirmation>

            {/* Resource Type deletion modal/logic */}
            <ResourceTypeDeleteConfirmation
              saveButtonText="Delete"
              onSave={() => deleteResourceTypeMutation.mutate(id)}
            >
              {deleteScopeMutation.isLoading && <LinearProgress />}
              {deleteResourceTypeMutation.isError && (
                <Alert severity="error">
                  There was an error deleting the Resource Type. Try again.
                </Alert>
              )}
              <Box mt={1}>
                <Typography variant="body1">
                  Are you sure you want to delete this Resource Type?
                </Typography>
              </Box>
            </ResourceTypeDeleteConfirmation>
          </td>
        </>
      )}
    </tr>
  );
}
