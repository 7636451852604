import React from 'react';
import { Content, InfoCard } from '@backstage/core-components';
import { NewMLProjectForm } from '../components/Forms/NewProjectForm';
import { Layout } from '../components/common/Layout';

export const RegisterMLProjectPage = () => {
  return (
    <Layout
      headerTitle="Register a new Machine Learning Project"
      pageTitle="Register new ML Project"
      subTitle="Projects group your ML resources in one place (e.g. notebooks, zflow pipelines)"
    >
      <Content>
        <InfoCard>
          <NewMLProjectForm />
        </InfoCard>
      </Content>
    </Layout>
  );
};
