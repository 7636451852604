import React from 'react';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../services';
import { STEP_STATUS, PIPELINE_STOPPED_STATUSES } from '../../../../constants';

import { getStepActions, getActionsByStepType } from './stepUtils';
import { StepActionType } from './types';

// @TODO
// - support different button colors

type Props = {
  type: string;
  status: string;
  pipelineStatus: string;
  skippingStepDisabled: boolean;
  currentPercentage: number;
  isAutomatedTraffic: boolean;
  isTrafficPaused: boolean;
  isAutomatedRollbackDisabled: boolean;
};

export const StepActions = observer((props: Props) => {
  const {
    type,
    status,
    pipelineStatus,
    skippingStepDisabled,
    currentPercentage,
    isAutomatedTraffic,
    isTrafficPaused,
    isAutomatedRollbackDisabled,
  } = props;

  const { runService } = useServices();

  if (
    !PIPELINE_STOPPED_STATUSES.includes(pipelineStatus) &&
    status === STEP_STATUS.SUCCEEDED
  ) {
    return <></>;
  }

  const stepActions = getStepActions({
    runService,
    skippingStepDisabled,
    currentPercentage,
    isAutomatedRollbackDisabled,
  });

  const availableActions: Array<StepActionType> =
    getActionsByStepType(
      stepActions,
      type,
      isAutomatedTraffic,
      isTrafficPaused,
      currentPercentage,
      runService.step.run.hasOnlySecurityDeploymentErrors,
    )[status] || [];

  return (
    <>
      {availableActions.map(action => (
        <action.component
          action={action.request}
          key={action.type}
          actionProps={action.actionProps}
        />
      ))}
    </>
  );
});
