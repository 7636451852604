import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core';
import React from 'react';

interface confimationProps {
  handleCancel: (e: React.SyntheticEvent) => void;
  handleConfirm: (e: React.SyntheticEvent) => void;
  bodyText: string;
  titleText: string;
  cancelText: string;
  confirmText: string;
  open: boolean;
}

export const Confirmation = (props: confimationProps) => (
  <Dialog
    open={props.open}
    onClose={props.handleCancel}
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
  >
    <DialogTitle id="dialog-title">{props.titleText}</DialogTitle>
    <DialogContent>
      <Typography>{props.bodyText}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        data-testid="archive-cancel"
        color="inherit"
        onClick={props.handleCancel}
      >
        {props.cancelText}
      </Button>
      <Button
        data-testid="archive-proceed"
        color="primary"
        onClick={props.handleConfirm}
      >
        {props.confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);
