function cleanAwsName(name: string) {
  return name.split(':').slice(-1)[0];
}

const DEFAULT_PAGE_TITLE = 'ML Platform';
const DEFAULT_HEADER_TITLE = 'Machine Learning Platform';
const pipelinePageTitle = (pipelineName: string) =>
  `${cleanAwsName(pipelineName)}`;
const runPageTitle = (runName: string) => `${cleanAwsName(runName)}`;
const modelGroupPageTitle = (modelGroupName: string) =>
  `${cleanAwsName(modelGroupName)}`;
const modelPageTitle = (modelName: string) => `${cleanAwsName(modelName)}`;

export {
  DEFAULT_PAGE_TITLE,
  DEFAULT_HEADER_TITLE,
  pipelinePageTitle,
  runPageTitle,
  modelGroupPageTitle,
  modelPageTitle,
};
