import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useRollbackPlans } from './hooks';
import { Box, DialogContent } from '@material-ui/core';

import { RollbackPlan } from './RollbackPlan';
import { Empty, Loader, RollbackError } from './RollbackState';
import { getDomainFromAlias } from '../../../../../utils/domains';
import { Dialog } from '../../../../../common';

import { useDialogStyles } from './styles';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export function RollbackDialog({ isOpen, handleClose }: Props) {
  const { domain_alias = '', org_name = '', repo_name = '' } = useParams();
  const domain = getDomainFromAlias(domain_alias);
  const [{ loading, error, value }, fetch] = useRollbackPlans(
    domain,
    org_name,
    repo_name,
  );
  const classes = useDialogStyles();

  useEffect(() => {
    if (isOpen) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        classes={{ paper: classes.dialog }}
        testID="rollback-dialog"
        title="Rollback"
        children={
          <DialogContent dividers>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              paddingBottom={3}
            >
              {loading && <Loader msg="Fetching rollback plans..." />}

              {!loading &&
                !error &&
                (!value || !value.plans || !value?.plans.length) && <Empty />}

              {!loading && !!error && (
                <RollbackError
                  title="Unable to fetch rollback plans"
                  details={error.message}
                  closeDialog={handleClose}
                />
              )}

              {!loading && !error && !!value && !!value.plans?.length && (
                <RollbackPlan plan={value} closeDialog={handleClose} />
              )}
            </Box>
          </DialogContent>
        }
      />
    </>
  );
}
