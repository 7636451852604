import React, { ReactNode } from 'react';
import { ErrorApi } from '@backstage/core-plugin-api';
import { GitHubApi } from 'plugin-github';
import { CDPApi, ScalyrApi, ZesClient } from '../api';
import { RunsService, IRunsService } from './runs';
import { RunGroupsService, IRunGroupsService } from './runGroups';
import { RunService, IRunService } from './run';
import { RepositoriesService, IRepositoriesService } from './repositories';
import { LogsService, ILogsService } from './logs';
import { LogsFlatService, ILogsFlatService } from './logsFlat';
import { FilteringService, IFilteringService } from './filtering';
import { SecretsService, ISecretsService } from './secrets';
import { MetaService, IMetaService } from './meta';
import { ApplicationService, IApplicationService } from './application';
import { PreferencesService, IPreferencesService } from './preferences';
import { IPaginate } from './helpers/pagination';
import { IPoll } from './helpers/polling';
import { IPersist } from './helpers/persist';
import { Entity } from '@backstage/catalog-model';
import { ZesService } from './zesService';
import { ITrafficService, TrafficService } from './traffic';

export interface IServices {
  runsService: IRunsService & IPaginate & IPoll;
  runGroupsMainPageService: IRunGroupsService & IPaginate & IPoll;
  runGroupsOrganizationPageService: IRunGroupsService & IPaginate & IPoll;
  repositoriesMainPageService: IRepositoriesService;
  repositoriesOrganizationPageService: IRepositoriesService;
  filteringMainPageService: IFilteringService & IPersist;
  filteringOrganizationPageService: IFilteringService & IPersist;
  runService: IRunService & IPoll;
  logsService: ILogsService;
  logsFlatService: ILogsFlatService;
  secretsService: ISecretsService;
  metaService: IMetaService;
  preferencesService: IPreferencesService;
  zesService: ZesService;
  trafficService: ITrafficService;
}

export interface INestedRouterServices {
  applicationService: IApplicationService;
  runGroupsService: IRunGroupsService & IPaginate & IPoll;
  preferencesService: IPreferencesService;
}

const getServices = (
  cdpApi: CDPApi,
  scalyrApi: ScalyrApi,
  errorApi: ErrorApi,
  gitHubApi: GitHubApi,
  zesClient: ZesClient,
): IServices => {
  return {
    runsService: new RunsService(cdpApi),
    runGroupsMainPageService: new RunGroupsService(cdpApi),
    runGroupsOrganizationPageService: new RunGroupsService(cdpApi),
    repositoriesMainPageService: new RepositoriesService(cdpApi, gitHubApi),
    repositoriesOrganizationPageService: new RepositoriesService(
      cdpApi,
      gitHubApi,
    ),
    filteringMainPageService: new FilteringService(),
    filteringOrganizationPageService: new FilteringService(),
    runService: new RunService(cdpApi, errorApi),
    logsService: new LogsService(scalyrApi, errorApi),
    logsFlatService: new LogsFlatService(scalyrApi, errorApi),
    secretsService: new SecretsService(cdpApi),
    metaService: new MetaService(),
    preferencesService: new PreferencesService(),
    zesService: new ZesService(zesClient),
    trafficService: new TrafficService(),
  };
};

const getNestedRouterServices = (entity: Entity, cdpApi: CDPApi) => {
  return {
    applicationService: new ApplicationService(entity),
    runGroupsService: new RunGroupsService(cdpApi),
    preferencesService: new PreferencesService(),
  };
};

const ServiceContext = React.createContext({} as IServices);
const NestedRouterServiceContext = React.createContext(
  {} as INestedRouterServices,
);

const ServiceProvider = ({
  value,
  children,
}: {
  value: IServices;
  children: ReactNode;
}) => (
  <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
);

const NestedRouterServiceProvider = ({
  value,
  children,
}: {
  value: INestedRouterServices;
  children: ReactNode;
}) => (
  <NestedRouterServiceContext.Provider value={value}>
    {children}
  </NestedRouterServiceContext.Provider>
);

const useServices = () => React.useContext(ServiceContext);
const useNestedRouterServices = () =>
  React.useContext(NestedRouterServiceContext);

export {
  ServiceProvider,
  NestedRouterServiceProvider,
  useServices,
  useNestedRouterServices,
  getServices,
  getNestedRouterServices,
};
export * from './runs';
export * from './runGroups';
export * from './run';
export * from './repositories';
export * from './logs';
export * from './logsFlat';
export * from './secrets';
export * from './application';
export * from './meta';
export * from './preferences';
export * from './helpers/pagination';
export * from './helpers/polling';
