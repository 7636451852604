import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import jwt from 'jsonwebtoken';

export const ZalandoIdentityApiRef = createApiRef<Sunrise.ZalandoIdentityApi>({
  id: 'zalandoidentity',
});

export class ZalandoIdentityApi implements ZalandoIdentityApi {
  private readonly profilePromise: Promise<Sunrise.ZalandoProfile>;

  public constructor(identityApi: IdentityApi) {
    this.profilePromise = new Promise((resolve: any, reject: any) => {
      identityApi.getCredentials().then(({ token }) => {
        if (token) {
          // TODO: pass flag to disable verifying token when decoding (although this seems default)
          resolve(jwt.decode(token) as Sunrise.ZalandoProfile);
        } else {
          reject(`failed to obtain idToken from identity API`);
        }
      });
    });
  }

  /* Unfortunately, the `IdentityApi` declares `getIdToken` as an async function */

  public async getLogin(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.login;
  }

  public async getEmail(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.email;
  }

  public async getTeams(): Promise<Array<string>> {
    const profile = await this.profilePromise;
    return profile.teams;
  }

  public async getFullName(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.full_name;
  }

  public async getFirstName(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.first_name;
  }

  public async getLastName(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.last_name;
  }

  public async getDepartment(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.department;
  }

  public async getExternalAccounts(): Promise<{
    [account: string]: Array<string>;
  }> {
    const profile = await this.profilePromise;
    return profile.external_accounts;
  }

  public async getJobTitle(): Promise<string> {
    const profile = await this.profilePromise;
    return profile.job_title;
  }
}

export interface ZalandoIdentityApi {
  getEmail(): Promise<string>;

  getLogin(): Promise<string>;

  getTeams(): Promise<Array<string>>;

  getFullName(): Promise<string>;

  getFirstName(): Promise<string>;

  getLastName(): Promise<string>;

  getJobTitle(): Promise<string>;

  getDepartment(): Promise<string>;

  getExternalAccounts(): Promise<{ [key: string]: Array<string> }>;
}
