import Crop from '@material-ui/icons/Crop';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Pageview from '@material-ui/icons/Pageview';
import Explore from '@material-ui/icons/Explore';
import Storage from '@material-ui/icons/Storage';
import Share from '@material-ui/icons/Share';
import TrackChanges from '@material-ui/icons/TrackChanges';

import { ModelIcon, OrchestrateIcon } from '../CustomIcons';

export default [
  {
    title: 'DISCOVER',
    Icon: Pageview,
    links: [
      {
        link: 'mailto:collibra@zalando.de',
        linkName: 'Collibra',
        channel: 'Email',
      },
    ],
  },
  {
    title: 'ACCESS',
    Icon: LocalLibrary,
    links: [
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Bigquery',
        channel: 'Jira',
      },
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Starburst (aka Trino/fka Presto)',
        channel: 'Jira',
      },
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Exasol',
        channel: 'Jira',
      },
      {
        link: 'mailto:data-repository@zalando.de',
        linkName: 'Raw S3 Access',
        channel: 'Email',
      },
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=16062&issuetype=15401',
        linkName: 'Data Access Request (DPR)',
        channel: 'Jira',
      },
    ],
  },
  {
    title: 'TRANSFORM',
    Icon: Crop,
    links: [
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Databricks',
        channel: 'Jira',
      },
    ],
  },
  {
    title: 'ANALYZE / EXPLORE',
    Icon: Explore,
    links: [
      {
        link: 'mailto:cherry-support@zalando.de',
        linkName: 'Cherry',
        channel: 'Email',
      },
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Exasol',
        channel: 'Jira',
      },
      {
        link: 'mailto:tableau-admins@zalando.de',
        linkName: 'Tableau',
        channel: 'Email',
      },
      {
        link: 'mailto:bi-microstrategy@zalando.de',
        linkName: 'MicroStrategy',
        channel: 'Email',
      },
      {
        link: 'mailto:superset@zalando.de',
        linkName: 'Superset',
        channel: 'Email',
      },
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Databricks',
        channel: 'Jira',
      },
      {
        link: 'https://chat.google.com/room/AAAAZIM2aIA',
        linkName: 'Datalab',
        channel: 'Google Chat',
      },
    ],
  },
  {
    title: 'MODEL & PREDICT',
    Icon: ModelIcon,
    links: [
      {
        link: 'https://chat.google.com/room/AAAAkAU3Nkk',
        linkName: 'SageMaker',
        channel: 'Google Chat',
      },
    ],
  },
  {
    title: 'SHARE / PUBLISH',
    Icon: Share,
    links: [
      {
        link: 'mailto:cherry-support@zalando.de',
        linkName: 'Cherry',
        channel: 'Email',
      },
      {
        link: 'mailto:team-virgil@zalando.de',
        linkName: 'Exasol',
        channel: 'Email',
      },
      {
        link: 'mailto:bi-microstrategy@zalando.de',
        linkName: 'MicroStrategy',
        channel: 'Email',
      },
      {
        link: 'mailto:superset@zalando.de',
        linkName: 'Superset',
        channel: 'Email',
      },
      {
        link: 'mailto:data-repository@zalando.de',
        linkName: 'Data Lake',
        channel: 'Email',
      },
    ],
  },
  {
    title: 'ORCHESTRATE',
    Icon: OrchestrateIcon,
    links: [
      {
        link: 'https://jira.zalando.net/secure/CreateIssue.jspa?pid=21141&issuetype=3',
        linkName: 'Airflow aaS',
        channel: 'Github',
      },
      {
        link: 'https://chat.google.com/room/AAAAxcSWJRg',
        linkName: 'zflow',
        channel: 'Google Chat',
      },
    ],
  },
  {
    title: 'TRACK',
    Icon: TrackChanges,
    links: [
      {
        link: 'https://chat.google.com/room/AAAAxcSWJRg',
        linkName: 'zflow',
        channel: 'Google Chat',
      },
    ],
  },
  {
    title: 'MANAGE DATA LIFECYCLE',
    Icon: Storage,
    links: [
      {
        link: 'mailto:data-integrity@zalando.de',
        linkName: 'Data Deprecation',
        channel: 'Email',
      },
    ],
  },
];
