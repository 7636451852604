import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CircularProgress, Typography } from '@material-ui/core';
import { plural } from '../../utils/language';
import { Issue } from '../common/Issue';
import { ReviewContext } from '../context';
import { KubeDashboardDetailsLink } from './KubeDashboardDetailsLink';

interface Props {
  stats?: IAppReview.KubeStats;
  loading?: boolean;
}

export function KubeStats({ stats, loading }: Props) {
  const { entity } = useEntity();
  const { issues } = React.useContext(ReviewContext);

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  const issue = issues.find(i => i.field === 'kubernetes_info');

  const ingresses = stats?.ingresses;
  const pods = stats?.pods;
  const cronjobs = stats?.cronjobs;
  const deployments = stats?.deployments;

  if (!ingresses && !pods && !cronjobs && !deployments) {
    return (
      <>
        <Typography>
          This application seems to have no resources on Kubernetes.
        </Typography>
        <Issue {...issue} />
      </>
    );
  }

  return (
    <>
      <Typography>This application has the following in Kubernetes:</Typography>
      <ul>
        {pods !== undefined && (
          <li>
            <b>{pods}</b> pod
            {plural(pods)}
            <KubeDashboardDetailsLink name={entity.metadata.name} type="pods" />
          </li>
        )}
        {ingresses !== undefined && (
          <li>
            <b>{ingresses}</b> ingress
            {plural(ingresses, 'es')}
            <KubeDashboardDetailsLink
              name={entity.metadata.name}
              type="ingresses"
            />
          </li>
        )}
        {cronjobs !== undefined && (
          <li>
            <b>{cronjobs}</b> cronjob
            {plural(cronjobs)}
          </li>
        )}
        {deployments !== undefined && (
          <li>
            <b>{deployments}</b> deployment
            {plural(deployments)}
          </li>
        )}
      </ul>
      <Issue {...issue} />
    </>
  );
}
