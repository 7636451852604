import React, { useEffect, useState } from 'react';
import { STEP_STOPPED_STATUSES } from '../../../constants';
import { durationHumanized } from '../../../utils/time';

type Props = JSX.IntrinsicElements['time'] & {
  status: string;
  startedAt: string;
};

export function Timer(props: Props) {
  const [nextDate, setNextDate] = useState<Date>(new Date());
  const intervalId = React.useRef<any>(null);

  useEffect(() => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => {
        setNextDate(new Date());
      }, 1000);
    }

    if (STEP_STOPPED_STATUSES.includes(props.status)) {
      clearInterval(intervalId.current);
    }

    return () => {
      if (intervalId) {
        return clearInterval(intervalId.current);
      }

      return undefined;
    };
  }, [props.status]);
  return (
    <time dateTime={props.startedAt}>
      {durationHumanized(props.startedAt, nextDate.toISOString())}
    </time>
  );
}
