import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { throwReadableError } from 'plugin-core';
import { AnalisysResponse, OrgRepoAnalysisResponse } from './types';

export type CodacyApiType = {
  getRepostoryAnalysis(params: {
    org: string;
    repo: string;
  }): Promise<AnalisysResponse>;
  getOrgRepositoriesAnalysis(param: {
    org: string;
  }): Promise<OrgRepoAnalysisResponse>;
};

export const codacyApiRef = createApiRef<CodacyApiType>({
  id: 'plugin-code-quality',
});
export class CodacyApi implements CodacyApiType {
  private readonly authApi: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { authApi: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.authApi = options.authApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getRepostoryAnalysis({
    org,
    repo,
  }: {
    org: string;
    repo: string;
  }): Promise<AnalisysResponse> {
    const path = `analysis/organizations/ghe/${org}/repositories/${repo}`;
    return this.get(path);
  }

  async getOrgRepositoriesAnalysis({
    org,
    limit = 100,
  }: {
    org: string;
    limit?: number;
  }): Promise<OrgRepoAnalysisResponse> {
    const path = `analysis/organizations/ghe/${org}/repositories?limit=${limit}`;
    return this.get(path);
  }

  private async get<T>(path: string): Promise<T> {
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url: string = `${baseUrl}/codacy/${path}`;
    const token: string = await this.authApi.getAccessToken();

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        credentials: 'include',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) throwReadableError(response);

    return await response.json();
  }
}
