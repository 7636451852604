import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  links: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  linkItem: {
    marginRight: '4px',
    marginBottom: '8px',
  },
  sectionHeader: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: '1.725rem',
    marginRight: '8px',
  },
  headerText: {
    fontWeight: 700,
  },
  sectionContainer: {
    margin: '24px 0 8px',
    '&:first-of-type': {
      marginTop: '24px',
    },
  },
  sectionHighlight: {
    borderRadius: '3px',
    backgroundColor: '#DE7C0250',
    padding: '8px 4px',
    margin: '24px 0 8px',
  },
  linkHighlight: {
    textDecoration: 'underline',
    color: '#fff',
    borderRadius: '3px',
    backgroundColor: '#DE7C02',
    marginRight: '2px',
    marginBottom: '16px',
    padding: '2px 4px',
    '&:first-of-type': {
      marginTop: '5px',
    },
  },
  copyIcon: {
    fontSize: '14px',
    opacity: '0.5',
    cursor: 'pointer',
    display: 'block',
    marginLeft: '8px',
    '&:hover': {
      opacity: '0.8',
    },
  },
});
