import { createContext, Dispatch, SetStateAction } from 'react';
import { IStep } from './steps';

export interface IReviewContext {
  review: Partial<IAppReview.Review>;
  onReviewChange: (
    name: keyof IAppReview.Review,
    value: IAppReview.Review[keyof IAppReview.Review],
  ) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  totalSteps: number;
  currentStep: IStep;
  issues: IAppReview.Issue[];
}

export interface IReviewSummaryContext {
  review: Partial<IAppReview.Review>;
  entity: Partial<IEntityApp>;
}

export const ReviewContext = createContext<IReviewContext>({
  review: {},
  onReviewChange: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  totalSteps: 0,
  currentStep: {} as IStep,
  issues: [],
});

export const ReviewSummaryContext = createContext<IReviewSummaryContext>({
  review: {},
  entity: {} as IEntityApp,
});
