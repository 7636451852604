import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import axios, { AxiosError } from 'axios';
import type { ValidationError } from 'express-validator';
import { parseEntityRef } from '@backstage/catalog-model';

export const techInsightsApiRef = createApiRef<TechInsightsApiClient>({
  id: 'tech-insights-api',
});

export class SunriseHttpError extends Error {
  readonly status: number;
  data?: ValidationError[];

  constructor(message: string, status: number, data?: ValidationError[]) {
    super(message);
    this.status = status;
    this.data = data;
  }

  forField(name: string): string | null {
    if (!this.data || !Array.isArray(this.data)) {
      return null;
    }
    const validationError = this.data.find(
      e => e.type === 'field' && e.path === name,
    );
    return validationError?.msg ?? null;
  }
}

// add other resources as needed
type ResourceTypes =
  | 'scorecards'
  | 'data-sources'
  | 'facts'
  | 'checks'
  | 'data-sources-facts';
type ResourceSchemas =
  | TechInsights.CreateDataSourceWithFactsDto
  | TechInsights.CreateFactDto
  | TechInsights.CreateCheckDto
  | Partial<TechInsights.CreateCheckDto>;

interface Options {
  configApi: ConfigApi;
  oauth2Api: OAuthApi;
  discoveryApi: DiscoveryApi;
}

export class TechInsightsApiClient {
  private readonly oauth2Api: OAuthApi;
  private readonly configApi: ConfigApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly http = axios.create();

  constructor(options: Options) {
    this.oauth2Api = options.oauth2Api;
    this.configApi = options.configApi;
    this.discoveryApi = options.discoveryApi;

    this.http.interceptors.request.use(
      async config => {
        const host = `${this.configApi.getString(
          'backend.baseUrl',
        )}/tech-insights`;
        config.url = `${host}${config.url}`;
        if (config.headers) {
          config.headers.Authorization = `Bearer ${await this.oauth2Api.getAccessToken()}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  async getResources<T>(resourceType: ResourceTypes): Promise<T> {
    const response = await this.http.get<T>(`/${resourceType}`);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async getResourceBy<T>(
    resourceType: ResourceTypes,
    identifier: string,
    getBy: 'name' | 'id' = 'id',
  ): Promise<T> {
    const response = await this.http.get<T>(
      `/${resourceType}/by-${getBy}/${identifier}`,
    );
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async createResource<T>(
    resourceType: ResourceTypes,
    data: ResourceSchemas,
  ): Promise<T> {
    const response = await this.http
      .post<T>(`/${resourceType}`, data)
      .catch((err: AxiosError) => err);

    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async updateResource<T>(
    resourceType: ResourceTypes,
    data: ResourceSchemas,
    identifier: number,
  ): Promise<T> {
    const response = await this.http.patch<T>(
      `/${resourceType}/by-id/${identifier}`,
      data,
    );
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async deleteResource<T>(
    resourceType: ResourceTypes,
    identifier: number,
  ): Promise<T> {
    try {
      const response = await this.http.delete<T>(
        `/${resourceType}/by-id/${identifier}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        throw this.reportError(err);
      }
      throw err;
    }
  }

  async getChecks() {
    const response = await this.http
      .get<TechInsights.Check[]>('/checks')
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async getScorecards() {
    const response = await this.http
      .get<TechInsights.Scorecard[]>('/scorecards')
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async getScorecardByName(name: string) {
    const response = await this.http
      .get<TechInsights.Scorecard>(`/scorecards/by-name/${name}`)
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async createScorecard(dto: TechInsights.CreateScorecardDto) {
    const response = await this.http
      .post<TechInsights.Scorecard>('/scorecards', dto)
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async updateScorecard(
    id: number,
    dto: Partial<TechInsights.CreateScorecardDto>,
  ) {
    const response = await this.http
      .patch<TechInsights.Scorecard>(`/scorecards/by-id/${id}`, dto)
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async createScorecardTarget(dto: TechInsights.CreateTargetDto) {
    const response = await this.http
      .post<TechInsights.Target>(`/scorecard-targets`, dto)
      .catch((err: AxiosError) => err);
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async updateScorecardTarget(
    id: number,
    dto: Omit<TechInsights.CreateTargetDto, 'scorecard_id'>,
  ) {
    const response = await this.http.patch<TechInsights.Target>(
      `/scorecard-targets/by-id/${id}`,
      dto,
    );
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  async deleteScorecardTarget(id: number) {
    const response = await this.http.delete<void>(
      `/scorecard-targets/by-id/${id}`,
    );
    if (response instanceof AxiosError) {
      throw this.reportError(response);
    }
    return response.data;
  }

  /**
   * FIXME: This function is copied from CatalogAdditionalApi because using the class directly has
   *  resulted in multiple issues during the build pipeline of storybook. This is due to the fact that
   *  our APIs are distributed across multiple plugins rather than hosting them in plugin-core which is
   *  meant to be shared and reused. This solution SHOULD be temporary until this issue is resolved.
   */
  async refreshEntity(
    ref: string,
    maxToWait?: number,
    createEntity?: boolean,
  ): Promise<{
    refreshed: boolean;
    error?: string;
  }> {
    const { namespace, kind, name } = parseEntityRef(ref);
    const catalogUrl = await this.discoveryApi.getBaseUrl('catalog');
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = new URL(
      `${catalogUrl}/refresh_entity/${namespace}/${kind}/${name}?wait=3`,
    );
    if (maxToWait) {
      // add wait=${maxToWait} to the url if defined
      serviceUrl.searchParams.append('wait', maxToWait.toString());
    }
    // add the create parameter to the nurl if defined
    if (createEntity) {
      serviceUrl.searchParams.append('create', createEntity.toString());
    }
    const response = await axios
      .post(
        serviceUrl.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .catch((res: AxiosError) => {
        throw res.response?.data;
      });

    return response.data;
  }

  private reportError(error: AxiosError<any>): SunriseHttpError {
    const message =
      error.response?.data?.message ??
      error.response?.statusText ??
      'Unknown error';
    const status = error.response?.status ?? error.status ?? 500;
    const data = error.response?.data;
    return new SunriseHttpError(message, status, data);
  }
}
