export const stringToPath = (arg: string) =>
  arg
    .toLocaleLowerCase()
    .replace(/[^\w+\s]/gi, '')
    .split(' ')
    .filter(item => item !== '')
    .join('-');

export const isTitleEqualToPath = ({
  title,
  path,
}: {
  title: string;
  path?: string;
}) => {
  return stringToPath(title) === path?.toLocaleLowerCase();
};

export const isLinkOnPath = ({
  linkName,
  isGroupActive,
  path,
}: {
  linkName: string;
  isGroupActive: boolean;
  path?: string;
}) => isGroupActive && path && stringToPath(linkName).includes(path);
