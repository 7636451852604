import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Content } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import { InfoCard } from 'plugin-ui-components';
import { zflowApiRef } from '../api/zflowApiClient';
import { MLPipeline, MLResourceNotFound } from '../api/definitions';
import { PLACEHOLDER_ML_PIPELINE } from '../api/PlaceholderResponses';
import { Layout } from '../components/common/Layout';
import { RunList } from '../components/Runs/RunList';
import { ResourceNotFound } from '../components/common/ResourceNotFound';
import { StartRunButton } from '../components/Runs/ManageRuns/StartRunButton';
import { pipelinePageTitle } from '../constants/PageTitles';
import { PIPELINE_EXECUTION_ENABLED } from '../constants/common';

export const MLPipelinePage = () => {
  const { pipeline_id = '' } = useParams();
  const zflowApi = useApi(zflowApiRef);

  const [pipeline, setPipeline] = useState<MLPipeline>(PLACEHOLDER_ML_PIPELINE);
  const [resourceNotFound, setResourceNotFound] =
    useState<MLResourceNotFound>();
  useEffect(() => {
    zflowApi
      .getPipeline(pipeline_id)
      .then(setPipeline)
      .catch(_ => setResourceNotFound({ name: 'pipeline', id: pipeline_id }));
  }, [zflowApi, pipeline_id]);

  return (
    <Layout pageTitle={pipelinePageTitle(pipeline.name)}>
      {resourceNotFound ? (
        <ResourceNotFound {...resourceNotFound} />
      ) : (
        <Content>
          <Grid item xs>
            <InfoCard
              title={pipeline.id}
              cardHeaderProps={{
                action: (
                  <StartRunButton
                    projectId={pipeline.project_id}
                    pipelineId={pipeline.id}
                    previousRunInput={null}
                    executionEnabled={PIPELINE_EXECUTION_ENABLED}
                  />
                ),
              }}
            >
              <RunList
                pipelineId={pipeline_id}
                projectId={pipeline.project_id}
              />
            </InfoCard>
          </Grid>
        </Content>
      )}
    </Layout>
  );
};
