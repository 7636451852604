import { styled } from '@material-ui/core';
import { TagSmall } from '../../../../common';

export const HEADER_HEIGHT = '50px';

export const List = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export const Item = styled('li')(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: props.theme.spacing(1),
  marginRight: props.theme.spacing(1),
  height: '35px',
  lineHeight: '35px',
  borderRadius: '5px',
  padding: props.theme.spacing(0, 1),
  background: props.theme.palette.background.paper,
  overflow: 'hidden',

  ['& span + span']: {
    marginLeft: props.theme.spacing(1),
  },

  ['&:hover']: {
    cursor: 'pointer',
    opacity: 0.7,
  },

  ['&:active ']: {
    transform: 'translateY(2px)',
  },
}));

export const Actions = styled('div')(props => ({
  display: 'flex',
  height: '100%',
  background: '#0000001c',
  marginLeft: props.theme.spacing(1),
  marginRight: props.theme.spacing(-1),
  fontSize: '14px',
}));

export const Tag = styled(TagSmall)(props => ({
  marginLeft: props.theme.spacing(1),
}));

export const TestResultsHeader = styled('div')(props => ({
  position: 'sticky',
  top: 0,
  padding: props.theme.spacing(0, 1),
  height: HEADER_HEIGHT,
  zIndex: 20,
  display: 'flex',
  justifyContent: 'flex-end',
  borderBottom: `4px solid ${props.theme.palette.divider}`,
  alignItems: 'center',
  backgroundColor: props.theme.palette.background.paper,

  ['& svg']: {
    color: props.theme.palette.text.primary,
  },
}));
