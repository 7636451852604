import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import * as S from '../../../../styles/table';
import Box from '@mui/material/Box';
import { Metric, Param } from '../../../../api/interfaces/metric_param';

export const useParamsColumns = () => {
  return useMemo(() => {
    return [
      {
        title: 'Key',
        field: 'key',
        sorting: true,
        render: (param: Param) => {
          return (
            <Tooltip title="Param name">
              <S.Title to="#">{param.key}</S.Title>
            </Tooltip>
          );
        },
      },
      {
        title: 'Value',
        field: 'value',
        sorting: true,
        render: (param: Param) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={param.key} style={{ width: '100%' }}>
              {param.value ? param.value : '-'}
            </div>
          </Box>
        ),
      },
    ];
  }, []);
};

export const useMetricsColumns = () => {
  return useMemo(() => {
    return [
      {
        title: 'Key',
        field: 'key',
        sorting: true,
        render: (metric: Metric) => {
          return (
            <Tooltip title="Metric name">
              <S.Title to="#">{metric.key}</S.Title>
            </Tooltip>
          );
        },
      },
      {
        title: 'Value',
        field: 'value',
        sorting: true,
        render: (metric: Metric) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={metric.metric_id} style={{ width: '100%' }}>
              {metric.value ? metric.value : '-'}
            </div>
          </Box>
        ),
      },
      {
        title: 'Threshold',
        field: 'threshold',
        sorting: true,
        render: (metric: Metric) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={metric.metric_id} style={{ width: '100%' }}>
              {metric.threshold ? metric.threshold : '-'}
            </div>
          </Box>
        ),
      },
      {
        title: 'Confidence Interval',
        field: 'confidence_interval',
        sorting: true,
        render: (metric: Metric) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={metric.metric_id} style={{ width: '100%' }}>
              {metric.confidence_interval
                ? `${metric.confidence_interval.lower_bound}:${metric.confidence_interval.upper_bound}`
                : '-'}
            </div>
          </Box>
        ),
      },
      {
        title: 'Slice',
        field: 'slice',
        sorting: true,
        render: (metric: Metric) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={metric.metric_id} style={{ width: '100%' }}>
              {metric.slice ? metric.slice : '-'}
            </div>
          </Box>
        ),
      },
      {
        title: 'Average',
        field: 'is_average',
        sorting: true,
        render: (metric: Metric) => (
          <Box flex={1} margin={0} padding={0}>
            <div key={metric.metric_id} style={{ width: '100%' }}>
              {metric.is_average ? metric.is_average : '-'}
            </div>
          </Box>
        ),
      },
    ];
  }, []);
};
