import {
  IAppRepositoryVulnerability,
  IPackageVulnerability,
  IRepositoryVulnerability,
  IRepositoryVulnerabilitySeverity,
} from '../api';

/**
 * Counts the quantity of critical, high, low and moderate
 * opened vulnerabilities of a GitHub repository
 * @example ```ts
 * updateVulnerabilitiesCount([{
 *   packageName: 'moment',
 *   status: 'open',
 *   vulnerabilities: [{
 *     ...
 *     packageName: 'moment',
 *     severity: 'HIGH',
 *   }]
 * }]);
 * // returns
 * {
 *   CRITICAL: 0,
 *   HIGH: 1,
 *   LOW: 0,
 *   MODERATE: 0,
 * }
 * ```
 * @param packages The list of vulnerable packages
 */
export const updateVulnerabilitiesCount = (
  packages: IPackageVulnerability[],
): Record<IRepositoryVulnerabilitySeverity, number> => {
  return packages.reduce(
    (count, p) => {
      p.vulnerabilities.forEach(v => {
        if (v.status === 'closed') return;
        count[v.severity] += 1;
      });
      return count;
    },
    {
      CRITICAL: 0,
      HIGH: 0,
      LOW: 0,
      MODERATE: 0,
    },
  );
};

export const getOpenVulnerabilities = (
  vulnerabilities: IAppRepositoryVulnerability,
) => {
  if (!vulnerabilities?.packages?.length) return [];
  const allVulnerabilities: IRepositoryVulnerability[] = [];
  vulnerabilities.packages.map(vulnerabilityPackage => {
    vulnerabilityPackage.vulnerabilities.map(vulnerability => {
      if (vulnerability.status === 'open') {
        allVulnerabilities.push(vulnerability);
      }
    });
  });
  return allVulnerabilities;
};
