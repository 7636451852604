import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CircularProgress, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { RadioSelect, SectionRadio } from '../common';
import { config, FormFields, GrafanaDefaultDashboards } from '../config';
import { createGrafanaApplicationDashboardUrlWithParameters } from './utils';
import { ReviewContext } from '../context';

export const GRAFANA_DASHBOARDS = [
  'dashboard_default_kubernetes-ingress-by-application-dashboard',
  'dashboard_default_kubernetes-application-dashboard',
];

export function Dashboards() {
  const api = useApi(applicationReviewApiRef);
  const { entity } = useEntity();
  const { review } = React.useContext(ReviewContext);

  const { loading, value } = useAsync(
    () => api.getGrafanaDashboards(entity.metadata.name),
    [entity],
  );

  return (
    <section>
      <Typography>
        Every production application must have a dashboard (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ), either a default dashboard or a custom one. Custom Grafana dashboards
        for this application must be tagged with&nbsp;
        <code>app:{entity?.metadata?.name}</code>. For each listed dashboard,
        please confirm that it is used and working ("Yes") or unused/broken
        (answer "No").
      </Typography>

      <Typography variant="h3">Default Dashboards</Typography>
      {GrafanaDefaultDashboards.map((item, i) => {
        const name = `dashboard_default_${item.id}` as FormFields;
        const urlName = `${name}_url` as FormFields;
        let url = item.url.replace('{{id}}', entity.metadata.name);
        // Add "cluster=*" and "namespace=All" to the Grafana dashboards url if cluster info is missing,
        // otherwise Grafana will use a default cluster (cart) and namespace (Default) so dashboard will not work as expected
        if (GRAFANA_DASHBOARDS.includes(name)) {
          url = createGrafanaApplicationDashboardUrlWithParameters(url);
        }

        return (
          <RadioSelect
            key={i}
            name={name}
            deps={[{ name: urlName, value: url }]}
            required={review.dashboards_ok !== 'n/a'}
            disabled={review.dashboards_ok === 'n/a'}
          >
            <Link to={url}>
              {config[name]?.label} <OpenInNewIcon />
            </Link>
          </RadioSelect>
        );
      })}

      <Typography variant="h3">Custom Grafana dashboards</Typography>
      {loading ? (
        <CircularProgress size="1.5em" />
      ) : (
        <>
          {value?.length ? (
            value.map((item, i) => {
              const name = `dashboard_grafana_${item.id}_url` as FormFields;
              return (
                <RadioSelect
                  required={review.dashboards_ok !== 'n/a'}
                  key={i}
                  name={name}
                  disabled={review.dashboards_ok === 'n/a'}
                >
                  <Link to={`https://grafana.zalando.net${item.url}`}>
                    {item.title} <OpenInNewIcon />
                  </Link>
                </RadioSelect>
              );
            })
          ) : (
            <Typography>
              No custom Grafana dashboards with tag&nbsp;
              <code>app:{entity.metadata.name}</code> found.
            </Typography>
          )}
        </>
      )}

      <SectionRadio name="dashboards_ok" />
    </section>
  );
}
