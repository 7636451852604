import React from 'react';
import { Box } from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

interface Props {
  isActive?: boolean;
}

export function ActiveState({ isActive }: Props) {
  const text = isActive ? 'Active' : 'Inactive';

  return (
    <Box display="inline-flex" alignItems="center">
      {text}
      <FiberManualRecord className="dot-icon" data-active={String(isActive)} />
    </Box>
  );
}
