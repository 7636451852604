export function parseRepositoryURL(
  url: string,
): null | { domain: string; org: string; name: string } {
  try {
    const { hostname, pathname } = new URL(url);
    const [, org, name] = pathname?.match(/([^/]+)\/([^./]+)(.git)?/) ?? [];

    return { domain: hostname, org, name };
  } catch (e) {
    return null;
  }
}
