import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Content, Progress, Table } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { ErrorBox } from 'plugin-ui-components';

import { containerImagesApiRef } from '../../api';
import { useStyles } from './styles';
import { Tag } from '../../components';

export function ImageTags({ tagAction }: { tagAction: (tag: string) => void }) {
  const api = useApi(containerImagesApiRef);
  const [prodOnly, setProdOnly] = useState<boolean>(false);
  const { registry = '', namespace = '', name = '', name2 = '' } = useParams();
  const classes = useStyles();

  const path = name2 ? `${namespace}/${name}/${name2}` : `${namespace}/${name}`;
  const { value, loading, error } = useAsync(() =>
    api.getImageTags(registry, path),
  );

  if (loading) return <Progress />;

  if (!!error) return <ErrorBox description={error.message} />;

  if (value === undefined)
    return <ErrorBox description="Failed to get image tags." />;

  let tags = value.tags;
  if (prodOnly) {
    tags = tags.filter(tag => !tag.startsWith('pr'));
  }

  return (
    <Content>
      <small>
        Please note that depending on the registr only a subset of latest image
        tags can be listed.
      </small>
      <Box padding="10px 0">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={prodOnly}
              onChange={() => setProdOnly(!prodOnly)}
            />
          }
          label={<Typography variant="body2">Production tags only</Typography>}
        />
      </Box>

      <Table
        columns={[
          {
            title: 'Tag details',
            field: 'title',
            render: (row: any) => {
              return (
                <button
                  className={classes.action}
                  onClick={() => tagAction(row.tag)}
                >
                  <Tag tag={row.tag} path={path} registry={registry} />
                </button>
              );
            },
          },
        ]}
        data={tags.map(tag => ({ tag: tag }))}
        options={{
          toolbar: false,
          search: false,
          paging: true,
          actionsColumnIndex: -1,
          padding: 'dense',
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [10],
        }}
        isLoading={false}
      />
    </Content>
  );
}
