import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import { ResultDataList } from '../components/ResultDataList';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { AccentColors } from '../constants/AccentColors';
import { SearchConfigForGCS } from '../models';
import {
  extractGCSFields,
  extractURLFromGoogleReferralURL,
  formatIndexLabelToID,
  getGheUrl,
} from '../utils';
import { Link } from '@backstage/core-components';
import {
  GitHubIssueIcon,
  GitHubPullRequestIcon,
  GitHubRepositoryIcon,
} from 'plugin-ui-components';

interface GitHubMetadata {
  repo: string;
  number: number;
  organization: string;
  name: string;
}

const label = 'Github';
const id = formatIndexLabelToID(label);
export const githubIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'github',
  icon: GitHubIcon,
  accentColor: AccentColors.Github,
  getUrl: hit => {
    if (!hit.metadata) return '';
    const { repo, number, organization, name } =
      extractGCSFields<GitHubMetadata>(hit);
    switch (hit?.metadata?.objectType) {
      case 'repo':
        return getGheUrl(`${organization}/${name}`);
      case 'issue':
        return getGheUrl(repo, number);
      case 'pull':
        return getGheUrl(repo, number, true);
      default:
        return extractURLFromGoogleReferralURL(hit.url ?? '');
    }
  },
  getTitle: hit => hit.title,
  getTitleIcon: hit => {
    switch (hit?.metadata?.objectType) {
      case 'repo':
        return GitHubRepositoryIcon;
      case 'issue':
        return GitHubIssueIcon;
      case 'pull':
        return GitHubPullRequestIcon;
      default:
        return GitHubIcon as any;
    }
  },
  render: hit => {
    const { repo, organization } = extractGCSFields<GitHubMetadata>(hit);

    return (
      <>
        <ResultDataList
          dense
          list={[
            {
              label: 'Organization',
              value: organization && (
                <Link to={`${getGheUrl(organization)}`}>{organization}</Link>
              ),
            },
            {
              label: 'Repository',
              value: repo && <Link to={`${getGheUrl(repo)}`}>{repo}</Link>,
            },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
      </>
    );
  },
});
