import { useState, useEffect, useCallback } from 'react';
import {
  settlePromises,
  findActiveApps,
  sortAndSliceApplications,
} from '../utils';
import { getTeamNameFromFullName } from 'plugin-core';
import { kioApiRef } from 'plugin-catalog';
import { useApi } from '@backstage/core-plugin-api';
import { Analysis } from '../types';

type LowestGrades = {
  fullName: string;
  team_id: string;
  analysis: { orgName: string; analysis: Analysis[] }[];
};

type Reports = {
  fullName: string;
  team_id: string;
  analysis: {
    [key: string]: Analysis[];
  };
};

export const useApplicationsWithLowestGrades = ({
  reports,
  userTeams,
}: {
  reports: Reports[];
  userTeams?: IEntityGroup[];
}) => {
  const kio = useApi(kioApiRef);
  const [lowestGrades, setLowestGrades] = useState<LowestGrades[]>([]);
  const [loadingGrades, setLoadingGrades] = useState(false);

  const getApplicationsWithLowestGrades = useCallback(async () => {
    try {
      const fetchAppIsActive = async (appName: string) => {
        const app = await kio.fetchKioApp(appName);
        return {
          isActive: app.active,
          name: app.id,
        };
      };

      const teamReports = [];
      if (reports?.length) {
        for (const team of reports) {
          const fullName = getTeamNameFromFullName(
            userTeams?.find(t => t.spec.team_id === team.team_id)?.spec
              .fullName || '',
          );

          const orgPromises = Object.keys(team.analysis).map(async orgName => {
            const teamAnalysis = team.analysis[orgName];
            const promises = teamAnalysis.map(app =>
              fetchAppIsActive(app.repository.name),
            );
            const fulfilledPromises = await settlePromises(promises);
            const activeApps = findActiveApps(teamAnalysis, fulfilledPromises);

            return {
              orgName,
              analysis: sortAndSliceApplications(activeApps, 3),
            };
          });

          const orgResults = await Promise.all(orgPromises);

          teamReports.push({
            fullName,
            team_id: team.team_id,
            analysis: orgResults,
          });
        }
      }

      return teamReports;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('failed to getApplicationsWithLowestGrades');
      return [];
    }
  }, [kio, reports, userTeams]);

  useEffect(() => {
    if (reports.length && userTeams) {
      setLoadingGrades(true);
      getApplicationsWithLowestGrades()
        .then(setLowestGrades)
        // eslint-disable-next-line no-console
        .catch(console.error)
        .finally(() => setLoadingGrades(false));
    }
  }, [getApplicationsWithLowestGrades, reports, userTeams]);

  return { lowestGrades, loadingGrades };
};
