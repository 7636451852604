import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'PRR';
const id = 'prr-filter';
export const ProductionReadinessReviewFilter = React.memo(() => {
  const { prrFilter, setPrrFilter } = React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id="prr-filter">{label}</InputLabel>
      <Select
        className="select-filter"
        title="Production readiness review"
        value={prrFilter}
        onChange={ev =>
          setPrrFilter(String(ev.target.value) as ICatalog.FilterYesOrNever)
        }
        classes={{ select }}
        label={label}
        labelId={id}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">yes</MenuItem>
        <MenuItem value="never">never</MenuItem>
      </Select>
    </FormControl>
  );
});
