import React from 'react';
import { Model } from '../../../../api/interfaces/model';
import { Tooltip } from '@material-ui/core';
import * as S from '../../../../styles/table';
import { TextWithLinkTag } from '../Tags';
import { SpacedTagGroup } from '../../../../styles/common';
import { TableColumn } from '@backstage/core-components';
import Box from '@mui/material/Box';

export function createNameColumn(): TableColumn<Model> {
  return {
    title: 'Name',
    field: 'name',
    sorting: true,
    filtering: false,
    render: (model: Model) => {
      return (
        <Box flex={1} margin={0} padding={0}>
          <div key={model.model_id}>
            <Box pt={2} pr={2} pl={1} key="model-item">
              <SpacedTagGroup>
                <Tooltip title="Model name">
                  <S.Title to={`/mlp/models/${model.model_id}`}>
                    {model.name}
                  </S.Title>
                </Tooltip>
              </SpacedTagGroup>
            </Box>
          </div>
        </Box>
      );
    },
  };
}

export function createModelLicenseColumn(): TableColumn<Model> {
  return {
    title: 'License',
    field: 'license_type',
    filtering: false,
    sorting: false,
    render: ({ license_type, license_link }) => {
      return (
        <TextWithLinkTag
          text={license_type as string}
          link={license_link as string}
          title="License Type"
        />
      );
    },
  };
}

export function createOriginColumn(): TableColumn<Model> {
  return {
    title: 'Origin',
    field: 'origin',
    filtering: false,
    sorting: false,
    render: ({ origin }) => {
      return <S.Text>{origin}</S.Text>;
    },
  };
}
