import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const initialState: Partial<IKioAppApiResponse> = {
  team_id: undefined,
  active: true,
  name: undefined,
  id: undefined,
  incident_contact: undefined,
  subtitle: undefined,
  description: undefined,
  service_url: undefined,
  scm_url: undefined,
  documentation_url: undefined,
  specification_url: undefined,
  support_url: undefined,
  criticality_level: undefined,
};

export const useForm = (
  spec: IKioAppApiResponse = initialState as IKioAppApiResponse,
): [IKioAppApiResponse, Dispatch<SetStateAction<IKioAppApiResponse>>] => {
  function cleanupSpec(s: IKioAppApiResponse) {
    const {
      specification_type: _specification_type,
      last_modified: _last_modified,
      last_modified_by: _last_modified_by,
      publicly_accessible: _publicly_accessible,
      created: _created,
      required_approvers: _required_approvers,
      created_by: _created_by,
      ...restSpec
    } = s;
    return restSpec;
  }

  const [data, setData] = useState<IKioAppApiResponse>(
    cleanupSpec(spec || initialState),
  );

  useEffect(() => setData(cleanupSpec(spec)), [spec]);

  return [data, setData];
};
