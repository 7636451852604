import React from 'react';
import { Alert, AlertProps } from '@material-ui/lab';
import { useStyles } from './styles';
import errorImage from './error.svg';
import { Typography } from '@material-ui/core';

interface Props extends AlertProps {
  /** The error message to show */
  error?: Error | string;
  /**
   * An optional description to explain to the user what went wrong.
   *
   * Example: "Failed to fetch user teams"
   */
  description?: string;
}

export function ErrorBox({
  error = 'Something went wrong',
  description,
  severity = 'error',
  ...props
}: Props) {
  const classes = useStyles();

  function getMessage(): React.ReactNode {
    const defaultMessage = 'Something went wrong';
    if (!error) return defaultMessage;
    if (typeof error === 'string') return error;
    if (!!(error as Error)?.message) {
      const name = /^error$/i.test(error.name) ? '' : `${error.name}: `;
      return `${name}${error.message}`;
    }
    if (typeof error === 'object') {
      return <pre>{JSON.stringify(error, null, 2)}</pre>;
    }
    return defaultMessage;
  }

  const errorMessage = getMessage();

  return (
    <Alert classes={classes} severity={severity} {...props}>
      <div className="message-wrapper">
        <div>
          <Typography variant="button" display="inline">
            Error!
          </Typography>
          {description && (
            <Typography variant="body2" display="inline">
              &ensp;{description}
            </Typography>
          )}
        </div>
        {typeof errorMessage === 'string' ? (
          <Typography variant="body2">
            <b>Reason:</b> {errorMessage}
          </Typography>
        ) : (
          errorMessage
        )}
      </div>
      <img height={48} src={errorImage} alt="" />
    </Alert>
  );
}
