import React from 'react';
import Grid from '@mui/material/Grid';
import { ToolingAboutCard } from './ToolingAboutCard';

export function ToolingOverviewPage() {
  return (
    <Grid container>
      <Grid item lg={6} xs={12}>
        <ToolingAboutCard />
      </Grid>
    </Grid>
  );
}
