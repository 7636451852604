import React from 'react';

interface IClassification {
  value: 'RED' | 'ORANGE' | 'YELLOW' | 'GREEN';
  caption: React.ReactNode;
  description: React.ReactNode;
}

export const classifications: IClassification[] = [
  {
    value: 'RED',
    caption: 'Critical confidential data',
    description:
      'e.g. criminal conviction, ethnic/racial origin, health, religious, trade union membership, sexual orientation/activity, political opinions; customer payment details/instruments',
  },
  {
    value: 'ORANGE',
    caption: 'Confidential data',
    description:
      'e.g. any personal or non-personal data (e.g. customer and/or employee data, live order/revenue statistics, financials, customer data shards/cubes, data lake)',
  },
  {
    value: 'YELLOW',
    caption: 'Internal data',
    description:
      'e.g. information in the corporate intranet, employee role, and organization details',
  },
  {
    value: 'GREEN',
    caption: 'Public data',
    description:
      'e.g. public website information, job descriptions, catalog info, press releases, etc.',
  },
];
