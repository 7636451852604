import React from 'react';
import { Text } from '../../../common';
import * as S from './styles';

export const NoRepositories = () => (
  <S.InfoWrapper>
    <Text py={2} data-testid="run-groups-info">
      No repositories associated with this entity.
    </Text>
  </S.InfoWrapper>
);
