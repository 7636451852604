import React from 'react';

export default function TechRadarIcon(
  props: React.JSX.IntrinsicElements['svg'],
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56403 7.67561C9.2822 6.82036 8.35185 6.35119 7.4966 6.63303C6.64135 6.91486 6.17218 7.8452 6.45401 8.70045C6.48462 8.79333 6.52287 8.88166 6.56785 8.96496L1.70555 14.0603C1.51491 14.2601 1.52232 14.5766 1.7221 14.7672C1.92188 14.9579 2.23837 14.9505 2.42901 14.7507L2.73002 14.4352C4.83163 16.2102 7.76865 16.8866 10.5711 15.9631C14.8629 14.5488 17.1983 9.91775 15.7841 5.62593C14.3698 1.33411 9.73874 -1.00128 5.44692 0.412996C2.57015 1.36097 0.581575 3.74875 0 6.50896L1.60129 6.84322C2.07224 4.63663 3.65793 2.72639 5.95934 1.968C9.39591 0.835554 13.0966 2.70178 14.2291 6.13835C15.3615 9.57492 13.4953 13.2756 10.0587 14.4081C7.85032 15.1358 5.54131 14.6189 3.8623 13.2487L4.9959 12.0608C6.24435 13.0292 7.93228 13.3849 9.54628 12.8531C12.1198 12.005 13.5221 9.22431 12.674 6.65077C11.826 4.07723 9.0453 2.67495 6.47176 3.52301C4.7457 4.0918 3.55513 5.52706 3.20514 7.18525L4.81421 7.51695C5.05357 6.41237 5.83347 5.45721 6.98418 5.07802C8.70246 4.51179 10.5528 5.44491 11.119 7.16319C11.6853 8.88148 10.7521 10.7318 9.03386 11.298C8.01311 11.6344 6.95754 11.4329 6.13942 10.8624L7.28996 9.65674C7.66109 9.83851 8.10036 9.8818 8.52144 9.74304C9.3767 9.46121 9.84586 8.53087 9.56403 7.67561ZM9.06953 14.3559C9.29558 14.3559 9.47884 14.1726 9.47884 13.9466C9.47884 13.7205 9.29558 13.5373 9.06953 13.5373C8.84347 13.5373 8.66021 13.7205 8.66021 13.9466C8.66021 14.1726 8.84347 14.3559 9.06953 14.3559ZM12.7533 4.12297C12.7533 4.34903 12.57 4.53229 12.3439 4.53229C12.1179 4.53229 11.9346 4.34903 11.9346 4.12297C11.9346 3.89691 12.1179 3.71366 12.3439 3.71366C12.57 3.71366 12.7533 3.89691 12.7533 4.12297ZM4.15742 4.53229C4.38347 4.53229 4.56673 4.34903 4.56673 4.12297C4.56673 3.89691 4.38347 3.71366 4.15742 3.71366C3.93136 3.71366 3.7481 3.89691 3.7481 4.12297C3.7481 4.34903 3.93136 4.53229 4.15742 4.53229Z"
        fill="currentColor"
      />
    </svg>
  );
}
