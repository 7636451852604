import React, { memo, type PropsWithChildren } from 'react';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { EntityPageLayout } from '../../../EntityPageLayout';
import { PluginTracking, useIsAdmin } from 'plugin-ui-components';
import {
  ScorecardForm,
  ScorecardOverview,
  ScorecardTargets,
  ScorecardTargetsForm,
  techInsightsApiRef,
} from 'plugin-scorecards';
import { useAsyncRetry } from 'react-use';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useMatch } from 'react-router';
import { Tabbed } from '../../../EntityPageLayout/Tabbed';
import { stringifyEntityRef } from '@backstage/catalog-model';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { scorecardTabClicked } from './tracking';

interface Props {
  scorecard?: TechInsights.Scorecard;
  retry?: VoidFunction;
}

const PageWrapper = memo(
  ({ entity, children }: PropsWithChildren<{ entity?: IEntityScorecard }>) => {
    if (entity) {
      return <EntityPageLayout>{children}</EntityPageLayout>;
    }
    return <Tabbed.Layout>{children}</Tabbed.Layout>;
  },
);

export function ScorecardEntityPage({
  scorecard: scorecardObj,
  retry: retryFn,
}: Props) {
  const techInsightsApi = useApi(techInsightsApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity } = useAsyncEntity<IEntityScorecard>();
  const isAdmin = useIsAdmin();
  const name = useMatch('/catalog/default/scorecard/:name/*')?.params.name;

  const { value: scorecard, retry } = useAsyncRetry((): Promise<
    undefined | TechInsights.Scorecard
  > => {
    if (scorecardObj) return Promise.resolve(scorecardObj);
    const scorecardName = entity?.metadata.name ?? name;
    if (!scorecardName) return Promise.resolve(undefined);
    return techInsightsApi.getScorecardByName(scorecardName);
  }, [entity, name, scorecardObj, retryFn]);

  const refresh = () => {
    if (scorecardObj) retryFn?.();
    else retry();
  };

  const catalogOrApiScorecard = entity ?? scorecard;
  if (!catalogOrApiScorecard) return null;

  const refreshScorecardEntity = (scorecardName: string) => {
    techInsightsApi
      .refreshEntity(
        stringifyEntityRef({
          kind: 'scorecard',
          name: scorecardName,
        }),
      )
      .catch((error: { refreshed: boolean; error: string }) => {
        alertApi.post({
          message: `Failed to refresh scorecard: ${scorecardName}. Reason: ${error.error}`,
          severity: 'error',
        });
      });
  };

  return (
    <PageWrapper entity={entity}>
      <EntityPageLayout.Content
        path="/"
        title="Overview"
        element={<ScorecardOverview scorecard={catalogOrApiScorecard} />}
        onTabSelected={() => {
          PluginTracking.sendEvent(scorecardTabClicked('Overview'));
        }}
      />
      {isAdmin && (
        <EntityPageLayout.Content
          path="/edit"
          title="Edit"
          element={
            <Container maxWidth="lg">
              <Box display="flex" flexDirection="column" gap="1rem">
                <ScorecardForm
                  scorecard={scorecard}
                  retry={updatedScorecard => {
                    refresh();
                    /* Refresh the entity only if targets are present */
                    if (updatedScorecard.targets.length) {
                      refreshScorecardEntity(updatedScorecard.name);
                    }
                  }}
                />
                <ScorecardTargetsForm
                  scorecard={scorecard}
                  retry={() => {
                    refresh();
                    // Refresh the entity if targets are deleted or added
                    if (scorecard) refreshScorecardEntity(scorecard.name);
                  }}
                />
              </Box>
            </Container>
          }
          onTabSelected={() => {
            PluginTracking.sendEvent(scorecardTabClicked('Edit'));
          }}
        />
      )}

      {/* If 'relations' property is present on data, we can fetch targets so display 'Applies to' tab */}
      {'relations' in catalogOrApiScorecard && (
        <EntityPageLayout.Content
          path="/targets"
          title="Applies to"
          element={
            <ScorecardTargets
              scorecard={catalogOrApiScorecard as IEntityScorecard}
            />
          }
        />
      )}
    </PageWrapper>
  );
}
