import React from 'react';
import { Link } from '@backstage/core-components';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './styles';

export function StepCell() {
  const classes = useStyles();
  return (
    <span className={classes.infoIcon}>
      Steps
      <Tooltip
        interactive
        title={
          <>
            We use the{' '}
            <Link to="https://engineering.docs.zalando.net/developer-journey/00-developer-journey/">
              Developer Journey model
            </Link>{' '}
            to organize the chapters of this manual. Each chapter is a phase of
            the application lifecycle from idea to retirement.{' '}
          </>
        }
      >
        <InfoIcon />
      </Tooltip>
    </span>
  );
}
