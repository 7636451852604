import { styled } from '@material-ui/core';

export const Tabs = styled('ul')(props => ({
  flexShrink: 0,
  display: 'flex',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  borderBottom: `1px solid ${props.theme.palette.divider}`,
  background: props.theme.palette.background.paper,
}));

export const Tab = styled('button')(
  (props: { 'data-active': boolean; theme: Theme }) => ({
    position: 'relative',
    padding: props.theme.spacing(3, 4),
    fontSize: '.8rem',
    fontWeight: 600,
    color: props['data-active']
      ? props.theme.palette.text.primary
      : props.theme.palette.textSubtle,
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '1.1',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',

    ['&:before']: {
      position: 'absolute',
      bottom: '-1px',
      left: 0,
      right: 0,
      width: '100%',
      height: '2px',
      content: `""`,
      borderRadius: '5px',
      background: props.theme.palette.primary.main,
      transition: 'transform .2s ease',
      transform: props['data-active'] ? 'scale(1)' : 'scale(0)',
    },

    ['&:hover']: {
      background: props.theme.palette.action.hover,
    },

    ['&:focus, &:active']: {
      background: props.theme.palette.action.hover,
      outline: 'none',
      transform: 'translateY(1px)',
    },
  }),
);
