import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { getFormattedDateTime } from 'plugin-ui-components';
import * as S from '../../../styles/table';
import { StyledGridColumn } from '../../../styles/grid';
import { Model } from '../../../api/interfaces/model';
import { TableColumn } from '@backstage/core-components';
import { ApplicationTag, UserTag } from './Tags';
import Box from '@mui/material/Box';

export const createTimeColumn = (): TableColumn<Model> => {
  return {
    title: 'Last Updated',
    field: 'created_at',
    filtering: false,
    sorting: false,
    render: ({ created_at, updated_at }) => {
      const title = updated_at ? 'Updated At' : 'Created At';
      const timeWithoutTimeZone = getFormattedDateTime(created_at).replace(
        'Europe/Berlin',
        '',
      );

      return (
        <Box flex={5} margin={0} padding={0}>
          <Box pt={2} pr={2} pl={1} key="model-item">
            <StyledGridColumn item>
              <Tooltip title={title}>
                <Typography variant="body1" style={{ fontSize: '0.80rem' }}>
                  {timeWithoutTimeZone}
                </Typography>
              </Tooltip>
            </StyledGridColumn>
          </Box>
        </Box>
      );
    },
  };
};

export const createApplicationColumn = (): TableColumn<Model> => {
  return {
    title: 'Application',
    field: 'application_id',
    filtering: false,
    sorting: false,
    render: ({ application_id }) => {
      return (
        <S.Text>
          <ApplicationTag name={application_id} />
        </S.Text>
      );
    },
  };
};

export function createdByColumn(): TableColumn<Model> {
  return {
    title: 'Created By',
    field: 'created_by',
    filtering: false,
    sorting: false,
    render: ({ created_by }) => {
      return <UserTag user={created_by.uid} />;
    },
  };
}

export function createDescriptionColumn(): TableColumn<any> {
  return {
    title: 'Description',
    field: 'description',
    filtering: false,
    sorting: false,
    render: ({ description }) => {
      if (!description) return null;
      const truncatedDescription =
        description.length > 200
          ? `${description.substring(0, 200)}...`
          : description;

      return (
        <Box flex={1} margin={0} padding={0}>
          <Tooltip title={description}>
            <Typography variant="body1" style={{ fontSize: '0.65rem' }}>
              {truncatedDescription}
            </Typography>
          </Tooltip>
        </Box>
      );
    },
  };
}

export const createMTableTimeColumn = (): TableColumn<Model> => {
  const timeColumn = createTimeColumn();
  return Object.assign({}, timeColumn, {
    children: timeColumn.title,
    title: undefined,
  });
};

export default createTimeColumn;
