// @ts-nocheck
import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { throwReadableError } from 'plugin-core';

export class ComplianceAndSecurityBaseImageVulnerabilitiesApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchComplianceAndSecurityBaseImageVulnerabilities(
    appsIds: string[],
    limit: number = 100,
  ): Promise<IBaseImages.IVulnerability[]> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/compliance-and-security-base-image-vulnerabilities-api?applications=${appsIds.join(
      ',',
    )}&limit=${limit}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });

    if (!response.ok) throwReadableError(response);

    const apps = await response.json();

    return apps.map(item => {
      return {
        ...item,
        due_date: new Date(item.due_date).toISODateString('short'),
      };
    });
  }
}

export const complianceAndSecurityBaseImageVulnerabilitiesApiRef =
  createApiRef<ComplianceAndSecurityBaseImageVulnerabilitiesApi>({
    id: 'plugin.compliance-and-security.base-image-vulerabilities',
  });
