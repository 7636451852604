import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import axios from 'axios';

export const toolingApiRef = createApiRef<ToolingApis>({
  id: 'plugin-tooling',
});

type CreateToolDto = Omit<
  IEntityTooling['spec'],
  'name' | 'lastUpdatedBy' | 'sapId' | 'updatedAt'
> & {
  sap_id: string;
};
type UpdateToolDto = Partial<CreateToolDto>;

export class ToolingApis {
  private readonly http;

  constructor(configApi: ConfigApi) {
    const backendUrl = configApi.getString('backend.baseUrl');
    this.http = axios.create({
      baseURL: new URL('/tooling', backendUrl).toString(),
      withCredentials: true,
    });
  }

  create(dto: CreateToolDto) {
    return this.http.post('', dto);
  }

  getAll() {
    return this.http.get('');
  }

  getById(id: number) {
    return this.http.get(`/${id}`);
  }

  update(id: number, dto: UpdateToolDto) {
    return this.http.patch(`/${id}`, dto);
  }

  delete(id: number) {
    return this.http.delete(`/${id}`);
  }
}
