import React from 'react';
import { CardContent } from '@material-ui/core';
import { SummaryCard } from '../common';
import { OpenTracing } from './OpenTracing';

export function OpenTracingSummary() {
  const category = 'OpenTracing';

  return (
    <SummaryCard category={category}>
      <CardContent>
        <OpenTracing hideConfirmationSection />
      </CardContent>
    </SummaryCard>
  );
}
