import { Theme, styled } from '@material-ui/core';

export const Well = styled('pre')((props: { theme: Theme }) => ({
  backgroundColor: props.theme.palette.background.default,
  borderRadius: '4px',
  padding: props.theme.spacing(1),
  border: `1px solid ${props.theme.palette.divider}`,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));
