export class DeploymentModel {
  public orgCode: string;
  public changeClass: string;
  public mergedAt: string;
  public applicationIDs: Array<string>;
  public zone: string;
  public repository: string;
  public targetBranch: string;
  public fromRef: string;
  public labels: Array<string>;
  public title: string;
  public url: string;
  public changedFiles: number;
  public comments: number;

  constructor(json: ICyberweek.Deployment) {
    this.orgCode = json['Org Code'] ?? '';
    this.changeClass = json['Change Class'] ?? '';
    this.mergedAt = json['Merged At'] ?? '';
    this.applicationIDs = json['Application IDs']?.split(',') ?? [];
    this.zone = json.Zone ?? '';
    this.repository = json.Repository ?? '';
    this.targetBranch = json['Target Branch'] ?? '';
    this.fromRef = json['From Ref'] ?? '';
    this.labels = json.Labels?.split(',') ?? [];
    this.title = json.Title ?? '';
    this.url = json.URL ?? '';
    this.changedFiles = Number(json['Changed Files']) ?? 0;
    this.comments = Number(json.Comments) ?? 0;
  }
}
