import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Link } from '@backstage/core-components';
import { CircularProgress, Typography } from '@material-ui/core';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { plural } from '../../utils/language';
import { Issue } from '../common/Issue';
import { ReviewContext } from '../context';

export function RestApiStats() {
  const api = useApi(applicationReviewApiRef);
  const { entity } = useEntity();
  const { issues } = React.useContext(ReviewContext);

  const { loading, value } = useAsync(
    () => api.getRestApiStatus(entity.metadata.name),
    [entity],
  );

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  const issue = issues.find(i => i.field === 'api_info');

  if (!value?.length) {
    return (
      <>
        <Typography>
          This application seems to not have published a REST API. See&nbsp;
          <Link
            target="_blank"
            to="/docs/default/Documentation/cloud/howtos/api-publishing/"
          >
            documentation on how to publish API specifications <OpenInNewIcon />
          </Link>
          .
        </Typography>
        <Issue {...issue} />
      </>
    );
  }

  return (
    <>
      Found <b>{value.length}</b> API definition{plural(value.length, 's')}:
      <ul>
        {value.map((apiName, i) => (
          <li key={i}>
            <Link
              target="_blank"
              to={`/catalog/default/api/${apiName}/definition`}
            >
              {apiName} <OpenInNewIcon />
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}
