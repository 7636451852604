import React from 'react';
import { ConfigFilter, SearchConfigForGCS } from '../models';
import { TeamReference } from 'plugin-ui-components';
import { ResultDataList } from '../components/ResultDataList';
import { ActiveState } from '../components/ActiveState';
import { AccentColors } from '../constants/AccentColors';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { LinkButton } from '@backstage/core-components';
import AppsIcon from '@mui/icons-material/Apps';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GitHubIcon from '@mui/icons-material/GitHub';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';

interface ApplicationMetadata {
  id: string;
  active: boolean;
  teamId: string;
  name: string;
  serviceUrl: string;
  supportUrl: string;
  documentationUrl: string;
  scmUrl: string;
  specificationUrl: string;
}

const label = 'Applications';
const id = formatIndexLabelToID(label);
export const applicationsIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'sunrise',
  objectTypes: ['application'],
  icon: AppsIcon,
  accentColor: AccentColors.Default,
  filters: [
    new ConfigFilter({
      indexId: id,
      label: 'Status',
      field: 'active',
      getOptions: async () => [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
      ],
      getDefaultValue: opts => [opts[0]],
    }),
  ],
  getUrl: hit => {
    const { id: appId } = extractGCSFields<ApplicationMetadata>(hit);
    if (!appId) return undefined;
    return `/catalog/default/component/${appId}`;
  },
  getTitle: hit => hit.title,
  render: hit => {
    const { active, teamId, documentationUrl, supportUrl, serviceUrl, scmUrl } =
      extractGCSFields<ApplicationMetadata>(hit);
    /** Max icon height */
    const maxHeight = 20;
    return (
      <>
        <ResultDataList
          dense
          list={[
            { label: 'Status', value: <ActiveState isActive={active} /> },
            { label: 'Owner', value: <TeamReference team={teamId} /> },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
        <Box display="flex" flexWrap="wrap" gap="0.5rem" marginTop="1rem">
          {documentationUrl && (
            <LinkButton
              to={documentationUrl}
              size="small"
              variant="outlined"
              color="primary"
            >
              <Box display="flex" gap="0.5rem" alignItems="center">
                <DescriptionIcon style={{ maxHeight }} />
                Documentation
              </Box>
            </LinkButton>
          )}
          {serviceUrl && (
            <LinkButton
              to={serviceUrl}
              size="small"
              variant="outlined"
              color="primary"
            >
              <Box display="flex" gap="0.5rem" alignItems="center">
                <LinkIcon style={{ maxHeight }} />
                URL
              </Box>
            </LinkButton>
          )}
          {scmUrl && (
            <>
              <LinkButton
                to={scmUrl}
                size="small"
                variant="outlined"
                color="primary"
              >
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <GitHubIcon style={{ maxHeight }} />
                  Github Repository
                </Box>
              </LinkButton>
              <LinkButton
                to={`${scmUrl}/issues`}
                size="small"
                variant="outlined"
                color="primary"
              >
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <AssignmentIcon fontSize="small" style={{ maxHeight }} />
                  Issues / Tickets
                </Box>
              </LinkButton>
            </>
          )}

          {supportUrl && (
            <LinkButton
              to={supportUrl}
              size="small"
              variant="outlined"
              color="primary"
            >
              <Box display="flex" gap="0.5rem" alignItems="center">
                <HelpOutlineIcon style={{ maxHeight }} />
                Support
              </Box>
            </LinkButton>
          )}
        </Box>
      </>
    );
  },
});
