interface TrackingParams {
  widget: string;
  plugin: string;
}

export const common = (
  plugin: string,
): Pick<ITrackingEvent, 'plugin' | 'eventCategory'> => ({
  plugin: plugin,
  eventCategory: 'Access Control Card',
});

export const editButton = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Edit action on access control',
  eventAction: `Edit button for ${widget} clicked`,
});

export const refreshButton = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Refresh action on access control',
  eventAction: `Refresh button for ${widget} clicked`,
});

export const searchField = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Search action on access control',
  eventAction: `Search field for ${widget} clicked`,
});

export const saveButton = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Save action on access control',
  eventAction: `Save button for ${widget} clicked`,
});

export const cancelButton = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Cancel action on access control',
  eventAction: `Cancel button for ${widget} clicked`,
});

export const addButton = ({
  widget,
  plugin,
}: TrackingParams): ITrackingEvent => ({
  ...common(plugin),
  eventLabel: 'Add action on access control',
  eventAction: `Add button for ${widget} clicked`,
});
