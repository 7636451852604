import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { DLQEvent } from '../../domain/DLQ';
import { http } from '../http-client';

export type DLQStatusRes = {
  count: number;
};

export interface DLQApiGateway {
  getDlqStatus(subscription_id: string): Promise<DLQStatusRes>;
  getDlqEvents(subscription_id: string): Promise<DLQEvent[]>;
  deleteDlqEvents(subscription_id: string, events: DLQEvent[]): Promise<void>;
  redriveDLQEvents(subscription_id: string, events: DLQEvent[]): Promise<void>;
}

export class DLQApiGatewayImpl implements DLQApiGateway {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getDlqStatus(subscription_id: string): Promise<DLQStatusRes> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<DLQStatusRes>(
      `${proxyUrl}/nakadi/dlq/subscriptions/${subscription_id}/failed-event-stats`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }

  async getDlqEvents(subscription_id: string): Promise<DLQEvent[]> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    type DLQEventsResp = {
      items: {
        error: { message: string };
        event: { [key: string]: any };
      }[];
      next: string;
    };

    const res = await http<DLQEventsResp>(
      `${proxyUrl}/nakadi/dlq/subscriptions/${subscription_id}/failed-events`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );

    // it needs pagination
    return res.items.map(e => ({
      eid: e.event.metadata.eid,
      event_type: e.event.metadata.event_type,
      error_message: e.error.message,
      event: e.event,
    }));
  }

  async deleteDlqEvents(
    subscription_id: string,
    events: DLQEvent[],
  ): Promise<void> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const eventsToDelete = events.map(e => ({
      event_type: e.event_type,
      eid: e.eid,
    }));

    return await http<void>(
      `${proxyUrl}/nakadi/dlq/subscriptions/${subscription_id}/failed-events`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ items: eventsToDelete }),
      },
    );
  }

  async redriveDLQEvents(
    subscription_id: string,
    events: DLQEvent[],
  ): Promise<void> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const eventsToRedrive = events.map(e => ({
      event_type: e.event_type,
      eid: e.eid,
    }));

    return await http<void>(
      `${proxyUrl}/nakadi/dlq/subscriptions/${subscription_id}/redrive`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ items: eventsToRedrive }),
      },
    );
  }
}
