import React from 'react';
import { DependencyGraph as Graph, Progress } from '@backstage/core-components';

import { CustomNode } from './CustomNode';
import * as S from './styles';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../services';
import { getEdges, getConcurrentEdges, getNodes, EdgeType } from './utils';
import { ConcurrencyInfo } from './ConcurrencyInfo';

export enum Direction {
  TOP_BOTTOM = 'TB',
  BOTTOM_TOP = 'BT',
  LEFT_RIGHT = 'LR',
  RIGHT_LEFT = 'RL',
}

export enum Alignment {
  UP_LEFT = 'UL',
  UP_RIGHT = 'UR',
  DOWN_LEFT = 'DL',
  DOWN_RIGHT = 'DR',
}

export const DependencyGraph = observer(() => {
  const {
    runService: { runState, run },
  } = useServices();
  if (runState.isLoading || runState.isIdle) {
    return <Progress />;
  }

  if (runState.isNotFound || !run.steps) {
    return <p>No steps found for this pipeline.</p>;
  }

  const nodes = getNodes(run.steps);
  let edges: EdgeType[] = [];

  if (run.areStepsConcurrent) {
    edges = getConcurrentEdges(run.steps);
  } else {
    edges = getEdges(run.steps);
  }

  return (
    <S.DependencyGraph>
      {!run.areStepsConcurrent && <ConcurrencyInfo />}

      <S.StyledGraph>
        <Graph
          direction={Direction.LEFT_RIGHT}
          align={Alignment.UP_LEFT}
          nodes={nodes}
          edges={edges}
          paddingX={10}
          paddingY={10}
          nodeMargin={30}
          edgeWeight={1}
          zoom="disabled"
          renderNode={props => <CustomNode node={props.node} />}
        />
      </S.StyledGraph>
    </S.DependencyGraph>
  );
});
