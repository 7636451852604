import { getTeamNameFromFullName } from './string';

export interface FormattedTeam {
  sapId: string;
  teamId: string;
  fullName: string;
  teamName: string;
}

export const formatAllTeamsOuput = (res: IEntityGroup[] | undefined) => {
  if (res) {
    return res
      .reduce<FormattedTeam[]>((result, team) => {
        const idName = team.spec?.id_name?.trim();
        const id = team.spec?.id?.trim();
        if (idName && id) {
          result.push({
            sapId: team.metadata.name,
            teamId: team.spec?.id?.trim(),
            fullName: team.spec?.fullName?.trim(),
            teamName: getTeamNameFromFullName(team.spec?.fullName),
          });
        }
        return result;
      }, [])
      .sort((a, b) => a.teamName.localeCompare(b.teamName));
  }
  return [];
};
