import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useTheme } from '@material-ui/core';
import filter from 'lodash/filter';
import { zflowApiRef } from '../../../api/zflowApiClient';
import { GetModelGroupsResponse, ModelGroup } from '../../../api/definitions';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../../constants/PagingConstants';
import { PLACEHOLDER_MODEL_GROUPS } from '../../../api/PlaceholderResponses';
import { tableHeaderStyles } from '../../../styles';
import {
  MLTablePagination,
  MLTableToolbar,
} from '../../common/TableComponents';
import { getModelGroupListColumns } from '../../common/columns/ModelGroupListColumns';
import { DEFAULT_SELECT_OPTION } from '../../../constants/common';

export const ModelGroupList = ({ projectId }: { projectId: string }) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [modelGroups, setModelGroups] = useState<GetModelGroupsResponse>(
    PLACEHOLDER_MODEL_GROUPS,
  );
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const [selectedDeploymentEnvironment, setSelectedDeploymentEnvironment] =
    useState<string>(DEFAULT_SELECT_OPTION);

  const filterGroups = useCallback(
    (groups: ModelGroup[]) => {
      return filter(groups, {
        deployment_environment: selectedDeploymentEnvironment,
      });
    },
    [selectedDeploymentEnvironment],
  );

  useEffect(() => {
    const offset = PAGE_SIZE * page;
    setIsLoading(true);
    zflowApi
      .getModelGroups(
        projectId,
        PAGE_SIZE,
        offset,
        selectedDeploymentEnvironment,
      )
      .then((modelGroupsResponse: GetModelGroupsResponse) => {
        setModelGroups({
          model_groups: modelGroupsResponse.model_groups,
          meta: {
            total: modelGroupsResponse.meta.total,
            deployment_environments:
              modelGroupsResponse.meta.deployment_environments,
          },
        });
        setIsLoading(false);
      });
  }, [zflowApi, projectId, filterGroups, selectedDeploymentEnvironment, page]);

  const theme = useTheme();

  return (
    <Table
      data={modelGroups.model_groups}
      columns={getModelGroupListColumns(
        modelGroups.meta.deployment_environments,
        selectedDeploymentEnvironment,
        setSelectedDeploymentEnvironment,
      )}
      options={{
        draggable: false,
        emptyRowsWhenPaging: false,
        filtering: true,
        headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
        pageSize: PAGE_SIZE,
        search: false,
        showFirstLastPageButtons: true,
      }}
      onRowClick={(event, rowData: any) => {
        event?.stopPropagation();
        navigate(`${rowData.model_group_id}/models`);
      }}
      isLoading={isLoading}
      components={{
        Pagination: props => (
          <MLTablePagination
            props={props}
            page={page}
            resource={modelGroups}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
          />
        ),
        Toolbar: props => <MLTableToolbar props={props} />,
      }}
    />
  );
};
