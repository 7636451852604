import {
  CompoundEntityRef,
  Entity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  AddLocationRequest,
  AddLocationResponse,
  CatalogApi,
  CatalogClient,
  CatalogRequestOptions,
  GetEntitiesByRefsRequest,
  GetEntitiesByRefsResponse,
  GetEntitiesRequest,
  GetEntitiesResponse,
  GetEntityAncestorsRequest,
  GetEntityAncestorsResponse,
  GetEntityFacetsRequest,
  GetEntityFacetsResponse,
  Location,
  QueryEntitiesRequest,
  QueryEntitiesResponse,
  ValidateEntityResponse,
} from '@backstage/catalog-client';
import { OAuthApi } from '@backstage/core-plugin-api';
import { Promise } from 'ts-toolbelt/out/Any/Promise';

/**
 * CatalogClient wrapper that injects identity token for all requests
 */
export class CatalogClientWrapper implements CatalogApi {
  private readonly oauthApi: OAuthApi;
  private readonly client: CatalogClient;

  constructor(options: { client: CatalogClient; oauth2Api: OAuthApi }) {
    this.client = options.client;
    this.oauthApi = options.oauth2Api;
  }

  async getLocationById(
    id: string,
    options?: CatalogRequestOptions,
  ): Promise<Location | undefined> {
    return await this.client.getLocationById(id, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getEntities(
    request?: GetEntitiesRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetEntitiesResponse> {
    return await this.client.getEntities(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getEntityByRef(
    compoundName: CompoundEntityRef,
    options?: CatalogRequestOptions,
  ): Promise<Entity | undefined> {
    return await this.client.getEntityByRef(compoundName, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async addLocation(
    request: AddLocationRequest,
    options?: CatalogRequestOptions,
  ): Promise<AddLocationResponse> {
    return await this.client.addLocation(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getOriginLocationByEntity(
    entity: Entity,
    options?: CatalogRequestOptions,
  ): Promise<Location | undefined> {
    const entityRef = stringifyEntityRef(entity);
    return await this.client.getLocationByRef(entityRef, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async removeLocationById(
    id: string,
    options?: CatalogRequestOptions,
  ): Promise<void> {
    return await this.client.removeLocationById(id, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async removeEntityByUid(
    uid: string,
    options?: CatalogRequestOptions,
  ): Promise<void> {
    return await this.client.removeEntityByUid(uid, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async refreshEntity(
    entityRef: string,
    options?: CatalogRequestOptions,
  ): Promise<void> {
    return await this.client.refreshEntity(entityRef, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getEntityAncestors(
    request: GetEntityAncestorsRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetEntityAncestorsResponse> {
    return await this.client.getEntityAncestors(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getEntityFacets(
    request: GetEntityFacetsRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetEntityFacetsResponse> {
    return await this.client.getEntityFacets(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getLocationByRef(
    locationRef: string,
    options?: CatalogRequestOptions,
  ): Promise<Location | undefined> {
    return await this.client.getLocationByRef(locationRef, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async validateEntity(
    entity: Entity,
    locationRef: string,
    options?: CatalogRequestOptions,
  ): Promise<ValidateEntityResponse> {
    return await this.client.validateEntity(entity, locationRef, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getEntitiesByRefs(
    request: GetEntitiesByRefsRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetEntitiesByRefsResponse> {
    return await this.client.getEntitiesByRefs(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async queryEntities(
    request: QueryEntitiesRequest = {},
    options?: CatalogRequestOptions,
  ): Promise<QueryEntitiesResponse> {
    return await this.client.queryEntities(request, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }

  async getLocationByEntity(
    entityRef: string | CompoundEntityRef,
    options?: CatalogRequestOptions,
  ): Promise<Location | undefined> {
    return await this.client.getLocationByEntity(entityRef, {
      token: options?.token ?? (await this.oauthApi.getAccessToken()),
    });
  }
}
