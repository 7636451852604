import React, { PropsWithChildren } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  RadioProps,
} from '@material-ui/core';
import { replaceIfNeeded } from '../../utils/language';
import { ReviewContext } from '../context';
import { RadioSelectLabel } from '../styles';
import { config, FormFields } from '../config';

type RadioSelectProps = Omit<RadioProps, 'onChange'> & {
  name: FormFields;
  helperText?: React.ReactNode;
  onChange?: (
    ev: React.ChangeEvent<HTMLInputElement>,
    val: IAppReview.YesNoNotSure,
  ) => void;
  /** Dependant fields that will get updated when this field gets updated */
  deps?: Array<{ name: FormFields; value: any }>;
};

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'Not sure', value: 'not_sure' },
  { label: 'No', value: 'no' },
];

export function RadioSelect({
  name,
  disabled,
  value,
  helperText,
  required,
  onChange,
  deps,
  children,
}: PropsWithChildren<RadioSelectProps>) {
  const { review, onReviewChange } = React.useContext(ReviewContext);
  const { entity } = useEntity<IEntityApp>();

  const onValueChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    val: IAppReview.YesNoNotSure,
  ) => {
    if (onReviewChange) {
      onReviewChange(ev.target.name, val);
      if (deps?.length) {
        deps.forEach(item => {
          onReviewChange(item.name, item.value);
        });
      }
      if (onChange) {
        onChange(ev, val);
      }
    }
  };

  return (
    <RadioGroup
      row
      name={name}
      value={value ?? (review[name] || '')}
      onChange={onValueChange as any}
    >
      <Grid container>
        <Grid item md={6}>
          <RadioSelectLabel>
            {replaceIfNeeded(children || config[name].label, entity.spec?.spec)}
          </RadioSelectLabel>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Grid>
        <Grid
          item
          md={6}
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          {options.map(item => (
            <FormControlLabel
              style={{ display: 'inline' }}
              key={item.value}
              {...item}
              control={
                <Radio
                  color="primary"
                  required={required ?? config[name]?.required ?? true}
                  disabled={disabled}
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </RadioGroup>
  );
}
