import React from 'react';
import { ToolingEditPage } from '../EditToolingPage';
import { ToolingOverviewPage } from './ToolingOverviewPage';
import { EntityPageLayout } from 'plugin-catalog';

export const ToolingEntityPage = () => {
  return (
    <EntityPageLayout>
      <EntityPageLayout.Content
        path="/"
        title="Overview"
        element={<ToolingOverviewPage />}
      />
      <EntityPageLayout.Content
        path="/edit"
        title="Edit"
        element={<ToolingEditPage />}
      />
    </EntityPageLayout>
  );
};
