import React from 'react';
import { EventTracker, IconLinkVertical } from 'plugin-ui-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ChatIcon } from '@backstage/core-components';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Tooltip from '@mui/material/Tooltip';
import PreviewIcon from '@mui/icons-material/Preview';
import Box from '@mui/material/Box';
import { clickLink } from './tracking';

type LinkProps = {
  label: string;
  href: string;
  icon: React.ReactElement;
  empty?: string | React.ReactElement;
};

export const buildAppLinks = (
  entity: IEntityTooling,
): Record<string, LinkProps> => {
  return {
    interface: {
      label: 'Interface',
      href: entity?.spec?.interface || '',
      icon: <PreviewIcon />,
      empty: 'No interface URL found',
    },
    training: {
      label: 'Training',
      href: entity?.spec?.training || '',
      icon: <SchoolIcon />,
      empty: 'No training information available',
    },
    support: {
      label: 'Contact Support',
      href: entity?.spec?.support || '',
      icon: <ContactSupportIcon />,
      empty: 'No support information available',
    },
    documentation: {
      label: 'Documentation',
      href: entity?.spec?.url || '',
      icon: <MenuBookIcon />,
      empty: 'No documentation available',
    },
    chat: {
      label: 'Community chat',
      href: entity?.spec?.chat || '',
      icon: <ChatIcon />,
      empty: 'No community chat available',
    },
  };
};

export function AboutCardLinks() {
  const { entity } = useEntity<IEntityTooling>();
  const [links, setLinks] = React.useState<Record<string, LinkProps>>({});

  React.useEffect(() => {
    setLinks(buildAppLinks(entity));
  }, [entity]);

  return (
    <Box
      component="nav"
      marginBottom="1rem"
      gap="1rem"
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(65px, min-content))"
      gridAutoColumns="min-content"
    >
      {Object.keys(links).map(key => (
        <EventTracker key={key} {...clickLink(links[key].label)}>
          <Tooltip
            title={(!links[key].href && links[key].empty) || ''}
            placement="top"
            arrow
          >
            <span>
              <IconLinkVertical
                {...links[key]}
                label={links[key].label}
                disabled={!links[key].href}
              />
            </span>
          </Tooltip>
        </EventTracker>
      ))}
    </Box>
  );
}
