import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function Subtitle({ value, issue }: FieldProps<'subtitle'>) {
  return (
    <>
      <dt>Subtitle</dt>
      <dd>
        {value || 'No subtitle set'}
        <InfoTooltip>
          The human-readable subtitle of the application. Will be displayed
          below the application name. This should be a short tagline, e.g.
          "Checks customer addresses" for the application "Address Service".
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
