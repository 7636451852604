import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Grid, IconButton, LinearProgress, Snackbar } from '@material-ui/core';
import { InfoCard, TabbedLayout } from '@backstage/core-components';
import { useSearchParams } from 'react-router-dom';
import { modelsApiRef } from '../../api/services/models';
import { ModelVersionOverview } from '../pages/ModelVersion/Overview';
import { ModelVersion } from '../../api/interfaces/model_version';
import { Layout } from '../common/Layout';
import { IResourceNotFound } from '../../api/interfaces/common';
import { ResourceNotFoundPage } from '../common/ResourceNotFoundPage';
import { Metrics, Params } from './MetricsParams';
import { Datasets } from './Datasets';
import { Deployments } from './Deployments';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { EditModelVersionForm } from '../forms/EditModelVersionForm';

export const ModelVersionDetailsPage = () => {
  const { modelVersionId = '', modelId = '' } = useParams();
  const modelsApi = useApi(modelsApiRef);
  const [loading, setLoading] = useState<boolean>(true);
  const [resourceNotFound, setResourceNotFound] = useState<IResourceNotFound>();
  const [modelVersion, setModelVersion] = useState<ModelVersion>();
  const [selectedVersionClicked, setSelectedVersionClicked] =
    useState<boolean>(false);
  const [updatedVersionForModelCard, setUpdatedVersionForModelCard] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setUpdatedVersionForModelCard(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const modelName = searchParams.get('model') || '';

  useEffect(() => {
    modelsApi
      .getModelVersionById(modelVersionId, modelId)
      .then(modelVersionResponse => {
        setModelVersion(modelVersionResponse);
      })
      .catch(_ =>
        setResourceNotFound({ name: 'model version', id: modelVersionId }),
      );
    setLoading(false);
  }, [modelId, modelVersionId, modelsApi]);

  useEffect(() => {
    if (selectedVersionClicked) {
      setLoading(true);
      modelsApi
        .updateModel(Number(modelId), {
          selected_version_for_model_card: modelVersionId,
        })
        .then(() => {
          setLoading(false);
          setUpdatedVersionForModelCard(true);
        });
    }
    setUpdatedVersionForModelCard(false);
  }, [
    modelId,
    modelVersion?.model_id,
    modelsApi,
    modelVersionId,
    selectedVersionClicked,
  ]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Layout
      namedEntities={{
        modelName: modelName,
        modelVersionName: modelVersion?.name,
      }}
    >
      {resourceNotFound && (
        <ResourceNotFoundPage name="model version" id={modelVersionId} />
      )}
      {modelVersion && (
        <TabbedLayout>
          <TabbedLayout.Route
            path={`/overview?model=${modelName}`}
            title="Overview"
          >
            <ModelVersionOverview
              modelVersion={modelVersion as ModelVersion}
              setSelectedVersionClicked={setSelectedVersionClicked}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/datasets" title="Datasets">
            <Datasets datasets={modelVersion?.datasets || []} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="deployments" title="Deployments">
            <Deployments deployments={modelVersion?.deployments || []} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="metrics-params" title="Metrics and Params">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Metrics metrics={modelVersion?.metrics || []} />
              </Grid>
              <Grid item xs={12}>
                <Params params={modelVersion?.params || []} />
              </Grid>
            </Grid>
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/edit" title="Edit">
            <InfoCard title="Edit Model Version">
              <EditModelVersionForm
                model_version_id={modelVersion.model_version_id as number}
                description={modelVersion.description}
                stage={modelVersion.stage.toString()}
                source_type={modelVersion.source_type}
                source_uri={modelVersion.source_uri}
              />
            </InfoCard>
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
      <Snackbar
        open={updatedVersionForModelCard}
        autoHideDuration={1500}
        action={action}
      >
        <Alert severity="success" variant="filled">
          Successfully set this as the default version for the model card.
        </Alert>
      </Snackbar>
    </Layout>
  );
};
