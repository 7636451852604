import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { handleResponse } from './utils';

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

interface HTTPRequestParameters {
  method: HTTPMethod;
  path: string;
  body?: {};
  params?: {};
}

export class BaseClient {
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;

  constructor(discoveryApi: DiscoveryApi, oauth2Api: OAuthApi) {
    this.discoveryApi = discoveryApi;
    this.oauth2Api = oauth2Api;
  }

  async getHeaders(): Promise<HeadersInit> {
    const token: string = await this.oauth2Api.getAccessToken();
    return new Headers({
      authorization: `Bearer ${token}`,
      accept: 'application/json',
      'content-type': 'application/json',
    });
  }

  private async makeRequest({
    method,
    path,
    body,
    params,
  }: HTTPRequestParameters) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    let url: string = `${proxyUrl}${path}`;
    let response: Response = {} as Response;

    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }
    response = await fetch(url, {
      method: method,
      headers: await this.getHeaders(),
      body: JSON.stringify(body),
      credentials: 'include',
    });
    return await handleResponse(response);
  }

  public get<T>(path: string, params?: {}): Promise<T> {
    return this.makeRequest({
      method: HTTPMethod.GET,
      path: path,
      params: params,
    });
  }

  public patch<T>(path: string, body: any = {}): Promise<T> {
    return this.makeRequest({
      method: HTTPMethod.PATCH,
      path: path,
      body: body,
    });
  }

  public post<T>(path: string, body: any = {}): Promise<T> {
    return this.makeRequest({
      method: HTTPMethod.POST,
      path: path,
      body: body,
    });
  }
}
