import React from 'react';
import { UserReference } from 'plugin-ui-components';

import * as S from './styles';

type Props = {
  user: string;
};

export function UserTag({ user }: Props) {
  return (
    <S.Text>
      <UserReference
        user={user}
        displayType="login"
        header="Accountable user:"
      />
    </S.Text>
  );
}
