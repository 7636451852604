import { Theme, styled } from '@material-ui/core';

export const Button = styled('label')((props: { theme: Theme }) => ({
  [props.theme.breakpoints.down('xs')]: {
    position: 'fixed',
    top: props.theme.spacing(2),
    right: props.theme.spacing(2),
    display: 'block',
    zIndex: 9999,
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    overflow: 'hidden',
    background: props.theme.palette.background.paper,
  },

  display: 'none',
}));

export const SVG = styled('svg')({
  width: '100%',
  height: '100%',
  transform: 'scale(.6)',

  [`& > path`]: {
    transition: 'transform .2s',
    fill: 'currentColor',
    transformOrigin: '0.75rem 0.75rem 0',
  },

  [`& > path:nth-child(1)`]: {
    transform: 'translate(0, -0.495rem)',
  },

  [`& > path:nth-child(3)`]: {
    transform: 'translate(0, 0.495rem)',
  },
});

export const Input = styled('input')({
  position: 'absolute',
  top: '5px',
  right: '5px',
  display: 'none',
  zIndex: 9999,

  [`&:checked ~ div`]: {
    transform: 'translateX(0)',
  },

  [`&:checked ~ label > svg > path:nth-child(1)`]: {
    transform: 'translate(0.0825rem, -0.0825rem) rotate(45deg)',
  },

  [`&:checked ~ label > svg > path:nth-child(2)`]: {
    transform: 'scale(0)',
  },

  [`&:checked ~ label > svg > path:nth-child(3)`]: {
    transform: 'translate(-0.0825rem, 0.0825rem) rotate(-45deg)',
  },
});
