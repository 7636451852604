import { Theme, styled } from '@material-ui/core';
import { PAGE_HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../constants';

export const RunDetails = styled('main')((props: { theme: Theme }) => ({
  position: 'relative',
  flex: '1',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  overflow: 'hidden',

  [props.theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${PAGE_HEADER_HEIGHT})`,
    overflow: 'hidden auto',

    scrollbarColor: props.theme.palette.background.default,

    ['&::-webkit-scrollbar']: {
      width: '15px',
    },

    ['&::-webkit-scrollbar-track']: {
      background: props.theme.palette.background.paper,
    },

    ['&::-webkit-scrollbar-thumb']: {
      backgroundColor: props.theme.palette.grey[400],
      width: '15px',
    },
  },
}));

export const Content = styled('div')((props: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  width: '100%',
  minHeight: '100%',
  margin: 0,
  padding: 0,

  [props.theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${SIDEBAR_WIDTH})`,
    marginLeft: SIDEBAR_WIDTH,
  },
}));

export const Sidebar = styled('div')((props: { theme: Theme }) => ({
  width: SIDEBAR_WIDTH,

  [props.theme.breakpoints.down('xs')]: {
    position: 'fixed',
    right: 0,
    top: 0,
    height: '100vh',
    paddingTop: props.theme.spacing(3),
    boxShadow: '-6px -1px 8px rgba(0,0,0,.06)',
    transform: 'translateX(120%)',
    zIndex: 9997,
    transition: 'transform .3s ease',
    backgroundColor: props.theme.palette.background.paper,
  },
  [props.theme.breakpoints.up('sm')]: {
    position: 'fixed',
    bottom: -1,
    height: `calc(100vh - ${PAGE_HEADER_HEIGHT})`,
    zIndex: 15,
    borderRight: `1px solid ${props.theme.palette.divider}`,
  },
}));
