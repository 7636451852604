import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { AWS_CONSOLE_URLS, NOT_AVAILABLE } from '../../../constants/common';
import HelpIcon from '@material-ui/icons/Help';

export const getModelContainerColumns = (): TableColumn[] => {
  return [
    {
      title: 'ECR Image URI',
      field: 'ecr_image_uri',
      filtering: false,
    },
    {
      title: 'Model Artifact Location',
      field: 'model_artifact_location',
      filtering: false,
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Container Mode
            <Tooltip title="The container host type">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'mode',
      filtering: false,
    },
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Training Job
            <Tooltip title="The training job the container was created from">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'training_job_id',
      filtering: false,
      render(row: any): React.ReactNode {
        return (
          <>
            {row.training_job_id ? (
              <Link
                to={`${AWS_CONSOLE_URLS.SAGEMAKER_TRAINING_JOB_URL}/${row.training_job_id}`}
              >
                {row.training_job_id}
              </Link>
            ) : (
              NOT_AVAILABLE
            )}
          </>
        );
      },
    },
  ];
};
