import React, { PropsWithChildren } from 'react';
import { Progress } from '@backstage/core-components';
import { LinearProgressProps } from '@material-ui/core';

export function TestableProgress(
  props: PropsWithChildren<LinearProgressProps>,
) {
  return (
    <div data-testid="testable-progress">
      <Progress {...props} />
    </div>
  );
}
