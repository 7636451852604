import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { SupportSections } from './SupportSections';

const SupportCategory = ({
  title,
  list = [],
}: {
  title: string;
  list: any;
}): any => {
  return (
    <InfoCard title={title}>
      <SupportSections list={list} />
    </InfoCard>
  );
};

export default SupportCategory;
