import { CatalogApi } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { Analysis } from '../types';

export const useUserEntity = ({
  catalogApi,
  report,
}: {
  catalogApi: CatalogApi;
  report?: Analysis;
}) => {
  const { value: userEntity } = useAsync(async () => {
    if (!report) return null;
    const response = await catalogApi.getEntities({
      filter: {
        kind: 'user',
        'spec.profile.email': report?.lastAnalysedCommit.authorEmail,
      },
    });
    return response.items[0];
  }, [report]);

  return {
    userEntity,
  };
};
