import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { codacyApiRef } from '../api';
import { Analysis } from '../types';

export const useOrgReposAnalysisReports = ({
  userTeams,
}: {
  userTeams?: IEntityGroup[];
}) => {
  const codacy = useApi(codacyApiRef);
  const orgs = userTeams?.flatMap(item => item.spec.alias || item.spec.id);

  const { value: reports, loading } = useAsync(async () => {
    if (!orgs) return null;

    const reportPromises = orgs.map(async org => {
      const res = await codacy.getOrgRepositoriesAnalysis({
        org,
      });
      return { org, data: res.data };
    });

    const settledPromises = await Promise.allSettled(reportPromises);

    const teamReports: {
      [key: string]: {
        fullName: string;
        team_id: string;
        analysis: { [key: string]: Analysis[] };
      };
    } = {};

    settledPromises
      .filter(({ status }) => status === 'fulfilled')
      .forEach(item => {
        const { org, data } = (
          item as PromiseFulfilledResult<{ org: string; data: Analysis[] }>
        ).value;

        const teamFullName =
          userTeams?.find(
            team => team.spec.alias?.includes(org) || team.spec.id === org,
          )?.spec.fullName || '';
        const team_id =
          userTeams?.find(
            team => team.spec.alias?.includes(org) || team.spec.id === org,
          )?.spec.team_id || '';

        if (!teamFullName || !team_id) {
          Object.values(teamReports);
          return;
        }

        if (teamReports.hasOwnProperty(team_id)) {
          teamReports[team_id].analysis[org] = data;
        } else {
          teamReports[team_id] = {
            fullName: teamFullName,
            team_id: team_id,
            analysis: { [org]: data },
          };
        }
      });

    const teamReportsArray = Object.values(teamReports);

    return teamReportsArray;
  }, [userTeams?.length]);

  return {
    reports: reports || [],
    loading,
  };
};
