import GoogleAnalytics from 'react-ga4';

export const PluginTracking = {
  initialize(
    trackingId: string,
    debug: boolean,
    uuid: string,
    dimensions: { [key: string]: string },
  ) {
    GoogleAnalytics.initialize(trackingId, {
      gaOptions: { debug, userId: uuid },
      gtagOptions: {
        ...dimensions,
      },
    });
  },
  setPlugin(plugin: string) {
    GoogleAnalytics.set({
      plugin,
    });
  },
  sendSearchQuery(query: string) {
    if (query?.trim()) {
      const search = new URLSearchParams();
      search.set('query', query);
      GoogleAnalytics.set({ page: window.location.pathname });
      GoogleAnalytics.send({
        hitType: 'pageview',
        page: `${window.location.pathname}?${search}`,
      });
    }
  },
  sendEvent(args: ITrackingEvent | IEventTracker | GATrackingEvent) {
    if (process.env.NODE_ENV === 'test') return;

    if (args.hasOwnProperty('name')) {
      const { name, parameters } = args as GATrackingEvent;
      GoogleAnalytics.event(name, parameters);
    } else {
      const { plugin, eventCategory, eventAction, eventLabel, eventValue } =
        args as ITrackingEvent | IEventTracker;
      GoogleAnalytics.event('custom_tracking_event', {
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
        value: eventValue,
        plugin: plugin,
      });
    }
  },
};
