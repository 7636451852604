import React from 'react';
import { LinkProps, Link } from '@backstage/core-components';
import { TrackedInteraction } from './TrackedInteraction';

export type TrackedLinkProps = Omit<LinkProps, 'to'> &
  ITrackingEvent & {
    href?: string;
    to?: string;
  };

export function TrackedLink(props: TrackedLinkProps) {
  const {
    href,
    to,
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
    ...linkProps
  } = props;

  const trackingProps: ITrackingEvent = {
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
  };

  return (
    <TrackedInteraction interaction="onClick" {...trackingProps}>
      <Link to={to || href || ''} {...linkProps} />
    </TrackedInteraction>
  );
}
