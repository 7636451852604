import {
  IDeploymentResource,
  IDeployStepResource,
  IDeploymentResourceLink,
  IDeploymentResourceWarning,
} from '../../api/types/payload';

export class DeploymentResourceModel {
  kind: string;
  name: string;
  namespace: string;
  region: string;
  status: string;
  userStatus: string;
  warnings: IDeploymentResourceWarning[];
  applications?: string[];
  links: IDeploymentResourceLink[];
  children: DeploymentResourceModel[];
  hasChildren: boolean;

  constructor(
    json: IDeploymentResource | IDeployStepResource = {} as
      | IDeploymentResource
      | IDeployStepResource,
  ) {
    this.kind = json.kind ?? '';
    this.name = json.name ?? '';
    this.namespace = json.namespace ?? '';
    this.region = json.region ?? '';
    this.status = json.status ?? '';
    this.userStatus =
      ((json as IDeploymentResource).userStatus ||
        (json as IDeployStepResource).user_status) ??
      '';
    this.warnings = json.warnings ?? [];
    this.applications = json.applications ?? [];
    this.links = json.links ?? [];
    this.children = (json.children ?? []).map(
      (child: IDeploymentResource | IDeployStepResource) =>
        new DeploymentResourceModel(child),
    );

    this.hasChildren = Boolean(json.children);
  }
}
