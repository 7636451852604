import { Theme, alpha, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Commit = styled('section')({
  width: '100%',
  overflow: 'hidden',
});

export const Title = styled(Link)((props: { theme: Theme }) => ({
  display: 'block',
  width: '100%',
  marginBottom: props.theme.spacing(1),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  fontSize: '.85rem',

  ['&, &:visited']: {
    color: props.theme.palette.text.primary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));

export const Text = styled('span')((props: { theme: Theme }) => ({
  marginRight: '10px',
  verticalAlign: 'middle',
  fontSize: '.8rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0,
  height: '20px',
  lineHeight: '20px',
  letterSpacing: '.2px',

  ['&, &:visited']: {
    color: props.theme.palette.text.secondary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },

  ['& svg']: {
    fontSize: 11,
    verticalAlign: 'middle',
    marginRight: '2px',
    marginBottom: '2px',
  },
}));

export const TextLabeled = styled(Text)((props: { theme: Theme }) => ({
  display: 'inline-block',
  flexShrink: 2,
  background: alpha(props.theme.palette.text.primary, 0.04),
  padding: '0 4px',
  borderRadius: '4px',

  ['&, &:visited, & svg']: {
    color: props.theme.palette.textSubtle,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));
