import React from 'react';
import { Grid } from '@material-ui/core';
import { ApplicationForm } from '../ApplicationForm';
import { Header } from '@backstage/core-components';

export function ApplicationCreatePage() {
  return (
    <Grid>
      <Header
        title="Register an application"
        subtitle="Make your application compliant by completing the form below to help your stakeholders discover it and collaborate better with you."
      />
      <article style={{ padding: '1.5rem 0rem' }}>
        <ApplicationForm />
      </article>
    </Grid>
  );
}
