import { NO_OF_ROWS_PER_PAGE } from '../config';
import { BaseClient } from './BaseClient';
import { DashboardsApi } from './DashboardsApi';
import {
  DashboardsQueryParams,
  DashboardVersionsQueryParams,
} from './types/queryParams';
import {
  DashboardResponse,
  DashboardsResponse,
  DashboardVersionsResponse,
} from './types/responses';

export class DashboardsApiClient extends BaseClient implements DashboardsApi {
  apiBaseUrl = '/zflow-dashboards/api';

  async getVersions(
    dashboardId: string,
    pageNumber: number,
  ): Promise<DashboardVersionsResponse> {
    const offset = pageNumber * NO_OF_ROWS_PER_PAGE;
    const params: DashboardVersionsQueryParams = {
      dashboard_id: dashboardId,
      offset: offset,
      limit: NO_OF_ROWS_PER_PAGE,
    };
    return await this.get(`${this.apiBaseUrl}/versions`, params);
  }

  async getDashboards(
    projectId: string,
    pageNumber: number,
  ): Promise<DashboardsResponse> {
    const offset = pageNumber * NO_OF_ROWS_PER_PAGE;
    const params: DashboardsQueryParams = {
      project_id: projectId,
      offset: offset,
      limit: NO_OF_ROWS_PER_PAGE,
    };
    return await this.get(`${this.apiBaseUrl}/dashboards`, params);
  }

  async getDashboardDetail(dashboardId: string): Promise<DashboardResponse> {
    return await this.get(`${this.apiBaseUrl}/dashboards/${dashboardId}`);
  }
}
