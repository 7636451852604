import { CompoundEntityRef, Entity } from '@backstage/catalog-model';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import { isOwnerOf } from 'plugin-core';

export function getCatalogFilterGroups(
  userTeams: IEntityGroup[],
  isStarredEntity: (
    entityOrRef: string | Entity | CompoundEntityRef,
  ) => boolean,
  orgName: string,
  page: string = 'applications',
) {
  function getMyLabel() {
    switch (page) {
      case 'apis':
        return 'My APIs';
      case 'docs':
        return 'My documentations';
      default:
        return 'My Applications';
    }
  }

  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'mine',
          label: getMyLabel(),
          icon: SettingsIcon as React.ComponentType<
            React.JSX.IntrinsicElements['svg']
          >,
          filterFn: (entity: Entity) =>
            !!userTeams && isOwnerOf(entity, userTeams),
        },
        {
          id: 'starred',
          label: 'Starred',
          icon: StarIcon as React.ComponentType<
            React.JSX.IntrinsicElements['svg']
          >,
          filterFn: isStarredEntity,
        },
      ],
    },
    {
      name: orgName,
      items: [
        {
          id: 'all',
          label: 'All',
          filterFn: () => true,
        },
      ],
    },
  ];
}
