import { Entity, UserEntity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

type User = IEntityUser | Entity | UserEntity;

export function useLeadTeams(
  user: User,
  processor?: (teams: IEntityGroup[] | undefined) => any,
) {
  const catalogApi = useApi(catalogApiRef);

  return useAsync<() => Promise<IEntityGroup[]>>(() => {
    if (catalogApi && user) {
      return catalogApi
        .getEntities({
          filter: {
            kind: 'Group',
            'spec.type': 'team',
            'spec.delivery_lead': user.metadata.name,
          },
        })
        .then(res => res.items as IEntityGroup[])
        .then(res => (processor ? processor(res) : res));
    }
    return Promise.resolve({
      loading: false,
      value: undefined,
      error: undefined,
    });
  }, [catalogApi, user]);
}
