import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsyncRetry } from 'react-use';
import { useUserTeams } from './useUserTeams';

export function useUserTeamsApps(user?: IEntityUser) {
  const {
    value: { userTeams },
  } = useUserTeams({ user: user?.metadata?.name });
  const catalogApi = useApi(catalogApiRef);

  const teamsFilter: string[] = userTeams
    ? userTeams
        .filter(t => t.spec.team_id)
        .map(t => stringifyEntityRef({ kind: 'group', name: t.spec.team_id }))
    : [];

  return useAsyncRetry(async () => {
    // If an empty array [] is passed to 'relations.ownedBy'
    // the filter returns all applications in the catalog
    if (userTeams) {
      return await catalogApi
        .getEntities({
          filter: {
            kind: 'Component',
            'spec.type': 'service',
            'relations.ownedBy': teamsFilter,
            'spec.spec.active': 'true',
          },
        })
        .then(res => res.items as IEntityApp[]);
    }
    return Promise.resolve([] as IEntityApp[]);
  }, [catalogApi, userTeams]);
}
