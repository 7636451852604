export class PipelineStatusCountModel {
  SUCCEEDED: number;
  FAILED: number;
  ABORTED: number;

  constructor(json: ICDPMonitoring.ExecutionCount) {
    this.SUCCEEDED = json.SUCCEEDED ?? 0;
    this.FAILED = json.FAILED ?? 0;
    this.ABORTED = json.ABORTED ?? 0;
  }
}
