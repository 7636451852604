import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { opsgenieApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { ResponderTitleFormatter } from './OnCallList';
import { OnCallParticipantRef, Schedule } from '../../types';
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

export type OnCallForScheduleListProps = {
  schedule: Schedule;
  responderFormatter?: ResponderTitleFormatter;
  date?: string;
};

const defaultResponderTitle = (
  responder: OnCallParticipantRef,
  _: Schedule,
) => {
  return responder.name;
};

export const OnCallForScheduleList = ({
  schedule,
  responderFormatter,
  date,
}: OnCallForScheduleListProps) => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const { value, loading, error } = useAsync(
    async () => await opsgenieApi.getOnCall(schedule.id, date),
    [date],
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <Alert data-testid="error-message" severity="error">
        {error.message}
      </Alert>
    );
  }

  const titleFormatter = responderFormatter || defaultResponderTitle;

  return (
    <List>
      {value!.map((responder, i) => (
        <ListItem
          key={i}
          button
          component="a"
          href={opsgenieApi.getUserDetailsURL(responder.id)}
          target="_blank"
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>

          <ListItemText primary={titleFormatter(responder, schedule)} />
        </ListItem>
      ))}

      {value!.length === 0 && (
        <ListItem>
          <ListItemText
            primary={`⚠️ No one on-call for schedule "${schedule.name}".`}
          />
        </ListItem>
      )}
    </List>
  );
};
