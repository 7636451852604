import React from 'react';
import { Table, TableProps } from '@backstage/core-components';
import { AsyncPagination, AsyncPaginationProps } from './AsyncPagination';

interface AsyncDataTableProps<T extends object> extends TableProps<T> {
  asyncPaginationProps?: AsyncPaginationProps;
}

export function AsyncDataTable<T extends object = {}>({
  asyncPaginationProps,
  ...props
}: AsyncDataTableProps<T>) {
  return (
    <Table<T>
      {...props}
      components={{
        Pagination: paginationProps => (
          <AsyncPagination {...paginationProps} {...asyncPaginationProps} />
        ),
      }}
    />
  );
}
