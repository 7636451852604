import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { TabbedLayout } from '@backstage/core-components';
import { Layout } from '../components/common/Layout';
import { ModelDetails } from '../components/Models/ModelDetails';
import { zflowApiRef } from '../api/zflowApiClient';
import { MLModel, MLResourceNotFound, ModelGroup } from '../api/definitions';
import { PLACEHOLDER_ML_MODEL } from '../api/PlaceholderResponses';
import { PageEntities } from '../components/Breadcrumbs/MLBreadcrumbs';
import { ResourceNotFound } from '../components/common/ResourceNotFound';
import { ModelArchitectureAndData } from '../components/Models/ModelArchitectureAndData';
import { ModelUseCasesAndEthicalConsiderations } from '../components/Models/ModelUseCasesAndConsiderations';
import { ModelGraphics } from '../components/Models/ModelGraphics/ModelGraphics';
import { ModelMetrics } from '../components/Models/ModelMetrics';
import { ModelApproval } from '../components/ModelApproval/ModelApproval';
import { modelPageTitle } from '../constants/PageTitles';

export const MLModelPage = () => {
  const { model_id = '' } = useParams();
  const zflowApi = useApi(zflowApiRef);
  const configApi = useApi(configApiRef);

  const [model, setModel] = useState<MLModel>(PLACEHOLDER_ML_MODEL);
  const [resourceNotFound, setResourceNotFound] =
    useState<MLResourceNotFound>();
  const [pageEntities, setPageEntities] = useState<PageEntities>({});

  const getModelDetails = useCallback(() => {
    zflowApi
      .getModel(model_id)
      .then((m: MLModel) => {
        setModel(m);
        const newEntities: PageEntities = {
          modelName: m.name,
        };
        if (m.model_group_id) {
          zflowApi.getModelGroup(m.model_group_id).then((mg: ModelGroup) => {
            newEntities.modelGroupName = mg.name;
            setPageEntities(newEntities);
          });
        } else {
          setPageEntities(newEntities);
        }
      })
      .catch(_ => setResourceNotFound({ name: 'model', id: model_id }));
  }, [zflowApi, model_id]);

  useEffect(() => getModelDetails(), [getModelDetails]);

  const trainingJobIds =
    model?.tags?.ZFLOW_TRAINING_JOB_IDS || model?.tags?.TRAINING_JOB_IDS;

  const enableModelApprovalFeature = configApi.getOptionalBoolean(
    'features.mlPlatform.enableModelApprovalFeature',
  );

  return (
    <Layout pageEntities={pageEntities} pageTitle={modelPageTitle(model.name)}>
      {resourceNotFound ? (
        <ResourceNotFound {...resourceNotFound} />
      ) : (
        <TabbedLayout>
          <TabbedLayout.Route path="/" title="Overview">
            <ModelDetails model={model} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/performance-metrics" title="Metrics">
            <ModelMetrics model={model} trainingJobIds={trainingJobIds} />
          </TabbedLayout.Route>
          <TabbedLayout.Route
            path="/architecture-and-data"
            title="Architecture and Data"
          >
            <ModelArchitectureAndData model={model} />
          </TabbedLayout.Route>
          <TabbedLayout.Route
            path="/use-cases-and-considerations"
            title="Use Cases and Ethical Considerations"
          >
            <ModelUseCasesAndEthicalConsiderations model={model} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/graphics" title="Graphics">
            <ModelGraphics model={model} />
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
      {enableModelApprovalFeature && (
        <ModelApproval
          model_id={model_id}
          getModelDetails={getModelDetails}
          approval={model.approval}
        />
      )}
    </Layout>
  );
};
