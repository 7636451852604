import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { PlanForm, RollbackStrategy } from './RollbackForm';
import { Loader, RollbackError, RollbackSuccess } from './RollbackState';
import { RollbackPlan as IRollbackPlan } from '../../../../../api/types/responses';
import { useServices } from '../../../../../services';
import { useRollback } from './hooks';

function RollbackPlanComponent({
  plan,
  closeDialog,
}: {
  plan: IRollbackPlan;
  closeDialog: () => void;
}) {
  const [{ error, loading, confirmation }, request] = useRollback();
  const { runsService } = useServices();

  useEffect(() => {
    if (!!confirmation?.result?.metadata?.pipeline_id) {
      runsService.updateRollbackPipelineID(
        confirmation?.result?.metadata?.pipeline_id,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation?.result?.metadata?.pipeline_id]);

  if (loading) {
    return <Loader msg="Implementing rollback plan..." />;
  }

  if (!!confirmation) {
    return <RollbackSuccess closeDialog={closeDialog} />;
  }

  if (!!error) {
    return (
      <RollbackError
        title="Unable to submit rollback plan"
        details={error.message}
        closeDialog={closeDialog}
      />
    );
  }

  return <PlanForm action={request} plan={plan as RollbackStrategy} />;
}

export const RollbackPlan = observer(RollbackPlanComponent);
