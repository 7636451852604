import { action, makeObservable, observable } from 'mobx';
import { ErrorApi } from '@backstage/core-plugin-api';
import { ScalyrApi } from '../api';
import { LogsLinesModel, RunStepRunModel } from '../models';
import { BadRequestException } from '../api/exceptions';
import {
  addMinutesToDateString,
  nanoTimestampToDateString,
} from '../utils/time';
import { LOGS_LINES_LIMIT } from '../constants';
import { LogsState } from '../models/logs/logs.state';
import { State } from './helpers/state';

enum ResponseStatusEnum {
  Idle,
  Failed,
  Successful,
}

export interface ILogsFlatService {
  logsState: LogsState;
  logs: LogsLinesModel;
  downloadLinkState: State;
  downloadLink: string;
  updateFinalState: () => void;
  getLines: (run: RunStepRunModel) => void;
  getDownloadLink: (run: RunStepRunModel) => Promise<void>;
  clean: () => void;
}

export class LogsFlatService implements ILogsFlatService {
  // Observables
  logsState: LogsState = new LogsState();
  logs: LogsLinesModel = new LogsLinesModel();
  downloadLinkState: State = new State();
  downloadLink: string = '';

  // Internals
  private lastRequestStatus: ResponseStatusEnum = ResponseStatusEnum.Idle;

  constructor(private scalyrApi: ScalyrApi, private errorApi: ErrorApi) {
    makeObservable(this, {
      logs: observable,
      logsState: observable,
      downloadLink: observable,
      downloadLinkState: observable,
      updateFinalState: action,
      getLines: action,
      getDownloadLink: action,
      clean: action,
    });
  }

  // Actions
  updateFinalState(): void {
    switch (this.lastRequestStatus) {
      case ResponseStatusEnum.Successful:
        if (this.logs.lines.length === 0) {
          this.logsState.setNotAvailable();
        }
        break;
      case ResponseStatusEnum.Failed:
        // Don't override existing Un-recoverable message
        if (!this.logsState.isUnrecoverable && this.logs.lines.length === 0) {
          this.logsState.setUnrecoverable(
            'Unable to fetch folds from Scalyr due to a client side connectivity issue.',
          );
        }
        break;
      default:
    }
  }

  async getLines(run: RunStepRunModel): Promise<void> {
    this.logsState.setLoading();

    try {
      const startTime =
        this.logs.lines.length > 0
          ? nanoTimestampToDateString(
              this.logs.lines[this.logs.lines.length - 1].timestamp,
            )
          : addMinutesToDateString(run.created_at, -1).toISOString();

      const endTime = run.isFinished
        ? run.finished_at
        : new Date().toISOString();

      const data = await this.scalyrApi.getAllLines(
        startTime,
        addMinutesToDateString(endTime, 1).toISOString(),
        run.id,
        this.logs.lines.length,
        LOGS_LINES_LIMIT,
      );

      data.forEach(response => this.logs.insertLines(response.matches, false));
      this.lastRequestStatus = ResponseStatusEnum.Successful;
      this.logsState.setLoaded();
    } catch (e) {
      this.lastRequestStatus = ResponseStatusEnum.Failed;

      if (e instanceof BadRequestException) {
        this.logsState.setUnrecoverable(e.message);

        return;
      }

      // This catch block silence all errors, including dev time errors.
      // Since there is no visual feedback when logs request fails and retries,
      // bugs can easily sneak by and be hard to locate.
      // For that reason added a console.error.
      // eslint-disable-next-line no-console
      console.error(e);
      this.logsState.setRecoverable();
    }
  }

  getDownloadLink = async (run: RunStepRunModel): Promise<void> => {
    this.downloadLinkState.setLoading();

    try {
      const endTime = run.isFinished
        ? run.finished_at
        : new Date().toISOString();

      this.downloadLink = await this.scalyrApi.getDownloadLink(
        addMinutesToDateString(run.created_at, -1),
        addMinutesToDateString(endTime, 1),
        run.id,
      );

      this.downloadLinkState.setLoaded();
    } catch (e) {
      this.errorApi.post(
        new Error(
          'An error occurred while trying to prepare the log file for download.',
        ),
      );
      this.downloadLinkState.setError();
    }
  };

  clean() {
    this.logs = new LogsLinesModel();
    this.logsState = new LogsState();
    this.downloadLinkState = new State();
    this.lastRequestStatus = ResponseStatusEnum.Idle;
  }
}
