import React from 'react';
import { ScopeForm } from '../ScopeForm';

export function ScopeCreatePage() {
  return (
    <article style={{ padding: '1.5rem 0rem' }}>
      <ScopeForm />
    </article>
  );
}
