import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { mintStorageApiRef } from '../../api';
import { AccessControlContext, IAccessControlContext } from './context';
import some from 'lodash/some';
import {
  ApplicationScopes,
  KubernetesClusters,
  OAuthClient,
  ResourceTypes,
  CredentialDistribution,
} from './components';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { isOwnerOf } from 'plugin-core';
import { useUserTeams } from 'plugin-ui-components';
import { Alert } from '@material-ui/lab';
import { ResourceTypeCreatePage } from './components/ResourceTypes/ResourceTypeCreatePage';
import { ResourceTypeEditPage } from './components/ResourceTypes/ResourceTypeEditPage';
import { ScopeCreatePage } from './components/ResourceTypes/ScopeCreatePage';
import { ScopeEditPage } from './components/ResourceTypes/ScopeEditPage';
import { useSearchParams } from 'react-router-dom';
import { mapQueryParamWithPage } from './utils';

export function ApplicationAccessControlPage({ appName }: { appName: string }) {
  const mintApi = useApi(mintStorageApiRef);
  const [appData, setAppData] = React.useState<
    IAccessControl.ApplicationType[]
  >([
    {
      id: '',
      username: '',
      client_id: '',
      redirect_url: '',
      is_client_confidential: false,
      s3_buckets: [],
      scopes: [],
      kubernetes_clusters: [],
      has_problems: false,
    },
  ]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [editState, setEditState] = React.useState<IAccessControl.EditState>({
    appScopes: false,
    kubernetesClusters: false,
    oAuthClient: false,
    credentialDistribution: false,
    resourceTypeCreatePage: false,
    resourceTypeEditPage: false,
    resourceTypeScopeCreatePage: false,
    resourceTypeScopeEditPage: false,
  });
  const [hasEdit, setHasEdit] = useState<boolean>(false);
  const [hasEditPermission, setHasEditPermission] = useState<
    boolean | undefined
  >(false);
  const { entity } = useAsyncEntity<IEntityApp>();
  const {
    value: { userTeams },
  } = useUserTeams({ include: { member: true, led: true } });

  const loadAppData = async () => {
    setIsLoading(true);
    try {
      const mintResponse = await mintApi.getApplicationById(appName);
      if (mintResponse) setAppData([mintResponse]);
    } catch (err: any) {
      setErrorMessage((err as Error).message);
    }
    setIsLoading(false);
  };
  const [searchParams, _] = useSearchParams();

  React.useEffect(() => {
    loadAppData();
    const params: IAccessControl.StateQueryParams = Object.fromEntries(
      searchParams.entries(),
    );
    const { hasEdit: applyEdit, page } = mapQueryParamWithPage(params);
    if (applyEdit) setEditState(prevState => ({ ...prevState, ...page }));
    setHasEdit(applyEdit ? true : some(editState, state => state));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintApi, appName]);

  React.useEffect(() => {
    setHasEdit(some(editState, state => state));
  }, [setHasEdit, editState]);

  React.useEffect(() => {
    if (userTeams) setHasEditPermission(entity && isOwnerOf(entity, userTeams));
  }, [entity, userTeams]);

  const context: IAccessControlContext = {
    appName,
    appData,
    loadAppData,
    editState,
    setEditState,
    hasEditPermission,
  };

  return isLoading ? (
    <Progress />
  ) : (
    <AccessControlContext.Provider value={context}>
      <Grid container justifyContent={hasEdit ? 'center' : 'flex-start'}>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">
              There was an error trying to load Access Control data for this
              Application: {errorMessage}
            </Alert>
          </Grid>
        )}
        {/* If one of the widgets is in edit mode, show only that one */}
        {hasEdit && (
          <Grid item lg={8} xs={12}>
            {editState.appScopes && <ApplicationScopes />}
            {editState.kubernetesClusters && <KubernetesClusters />}
            {editState.oAuthClient && <OAuthClient />}
            {editState.credentialDistribution && <CredentialDistribution />}
            {editState.resourceTypeCreatePage && <ResourceTypeCreatePage />}
            {editState.resourceTypeEditPage && <ResourceTypeEditPage />}
            {editState.resourceTypeScopeCreatePage && <ScopeCreatePage />}
            {editState.resourceTypeScopeEditPage && <ScopeEditPage />}
          </Grid>
        )}
        {/* Display all widgets if none of them is in edit mode */}
        <Grid item xs={12}>
          {!hasEdit && (
            <Box
              display="grid"
              gridTemplateRows="minmax(100px, auto)"
              gridTemplateColumns="minmax(auto, 50%) 1fr"
              gridGap={16}
            >
              <ApplicationScopes />
              <KubernetesClusters />
              <ResourceTypes />
              <OAuthClient />
              <CredentialDistribution />
            </Box>
          )}
        </Grid>
      </Grid>
    </AccessControlContext.Provider>
  );
}
