import React, { useContext } from 'react';
import {
  Theme,
  Chip,
  styled,
  Typography,
  Box,
  CardContent,
} from '@material-ui/core';
import * as S from '../styles';
import { classifications } from './classifications';
import { ReviewSummaryContext } from '../context';

const getColorForCaption = (caption: string, theme: Theme) => {
  switch (caption) {
    case 'GREEN':
      return theme.palette.success.main;
    case 'YELLOW':
      return theme.palette.gold;
    case 'ORANGE':
      return theme.palette.warning.dark;
    case 'RED':
      return theme.palette.error.main;
    default:
      return theme.palette.success.main;
  }
};

const StyledChip = styled(Chip)(
  ({ theme, label }: { theme: Theme; label: string }) => ({
    backgroundColor: getColorForCaption(label, theme),
  }),
);

export const DataClassificationSummary = () => {
  const category: IAppReview.ReviewCategory = 'Data classification';
  const { review } = useContext(ReviewSummaryContext);

  const caption = classifications.filter(
    c => c.value === review.data_classification,
  )[0]?.caption;

  return (
    <>
      {review.data_classification && (
        <S.StyledCard>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h2">{category}</Typography>
              <Box display="flex" gridGap={8} alignItems="center">
                <StyledChip label={review.data_classification} />
                <Typography style={{ fontWeight: 'bold' }}>
                  {caption}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </S.StyledCard>
      )}
    </>
  );
};
