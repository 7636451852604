import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Content, Link } from '@backstage/core-components';
import { Box, Card, CardContent, LinearProgress } from '@material-ui/core';
import { EventTracker, useIsAdmin, useUserTeams } from 'plugin-ui-components';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import Alert from '@mui/lab/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { alertApiRef, configApiRef, useApi } from '@backstage/core-plugin-api';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { catalogAdditionalApiRef } from 'plugin-catalog';
import { ToolingForm, ToolingFormInput } from '../ToolingForm';
import { useMutation } from 'react-query';
import { Tracking } from '../../tracking';
import Container from '@mui/material/Container';
import { toolingApiRef } from '../../api/ToolingApis';
import axios, { AxiosError } from 'axios';

export function ToolingEditPage() {
  const [deleteDialogToggle, setDeleteDialogToggle] = useState(false);
  const {
    entity,
    loading: entityLoading,
    error: entityError,
  } = useAsyncEntity<IEntityTooling>();
  const {
    value: { memberTeams = [], ledTeams = [] },
    loading: userTeamsLoading,
  } = useUserTeams({ include: { member: true, led: true, accountable: true } });
  const configApi = useApi(configApiRef);
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);
  const toolingApi = useApi(toolingApiRef);
  const alertApi = useApi(alertApiRef);
  const isLoading = entityLoading || userTeamsLoading;
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isOwner = [...memberTeams, ...ledTeams].some(team =>
    [team.spec.fullName, team.metadata.name].includes(entity?.spec.sapId || ''),
  );
  const canEdit = isOwner || isAdmin;

  /** Create a mutation to send the tooling object to the API */
  const editTool = useMutation({
    mutationFn: async (newTooling: ToolingFormInput) => {
      const formData = new FormData();
      // Clean empty fields to avoid validation error in backend
      for (const [key, value] of Object.entries(newTooling)) {
        if (key === 'logo' && typeof value === 'string') {
          // Ignore logo if it's a string
          continue;
        }
        // compare value with an empty value
        if (value !== '') {
          formData.append(key, value);
        }
      }
      const resp = await axios
        .patch(
          `${configApi.getString('backend.baseUrl')}/tooling/${
            entity?.spec.id
          }`,
          formData,
          { withCredentials: true },
        )
        .catch(err => err as AxiosError);
      if (resp instanceof Error) throw new Error('Failed to update tooling');

      // Refresh entity after update
      const entityRef = {
        kind: 'Tooling',
        namespace: 'default',
        name: `${entity?.metadata.name}`,
      };
      const toRefresh = [];
      toRefresh.push(
        catalogAdditionalApi.refreshEntity(stringifyEntityRef(entityRef), 5),
      );
      if (newTooling.name !== entity?.metadata.name) {
        toRefresh.push(
          catalogAdditionalApi.refreshEntity(
            stringifyEntityRef({
              ...entityRef,
              name: newTooling.name,
            }),
            5,
          ),
        );
        if (entity?.metadata.name) entity.metadata.name = newTooling.name;
      }
      const refreshResponses = await Promise.allSettled(toRefresh);
      refreshResponses.forEach(refreshResp => {
        if (refreshResp.status === 'rejected' || !refreshResp.value.refreshed) {
          throw new Error(
            'Tooling was updated but we failed to refresh the entity, so you may not see the changes immediately.',
          );
        }
      });
    },
  });

  const deleteTooling = useMutation({
    mutationFn: async () => {
      if (!isAdmin || !entity) return;
      await toolingApi.delete(entity.spec.id);
      catalogAdditionalApi
        .refreshEntity(stringifyEntityRef(entity))
        .catch(() => {});
    },
    onSuccess: async () => {
      alertApi.post({
        message: 'Tooling has been successfully deleted',
        severity: 'success',
      });
      navigate('/tooling');
    },
  });

  return (
    <Content>
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            {!canEdit && (
              <Box marginBottom={2}>
                <Alert severity="warning">
                  Only the owner of this tooling can edit it.
                </Alert>
              </Box>
            )}
            {editTool.isSuccess && (
              <EventTracker {...Tracking.editTooling()}>
                <Box marginBottom={2}>
                  <Alert severity="success">
                    Tooling has been successfully updated. If you changed the
                    title, please click on {/* TODO: get new Tooling name */}
                    <Link
                      to={`/catalog/default/Tooling/${entity?.metadata.name}`}
                    >
                      this link
                    </Link>{' '}
                    to refresh the page.
                  </Alert>
                </Box>
              </EventTracker>
            )}
            {editTool.isError && (
              <Box marginBottom={5}>
                <Alert severity="error">
                  An error occurred while submitting the form:{' '}
                  {(editTool.error as Error).message}
                </Alert>
              </Box>
            )}
            {isLoading && <LinearProgress color="primary" />}
            {entity && !isLoading && (
              <ToolingForm
                entity={entity}
                mode="edit"
                onSubmit={data => editTool.mutate(data)}
                isLoading={editTool.isLoading}
                canEdit={canEdit}
                onDelete={() => setDeleteDialogToggle(true)}
              />
            )}
            {entityError && (
              <h1>An error occurred while fetching this entity.</h1>
            )}
          </CardContent>
        </Card>
      </Container>
      <Dialog
        open={deleteDialogToggle}
        onClose={() => setDeleteDialogToggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete tooling</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{entity?.metadata.name}</b>{' '}
            tooling? This action is irreversible.
          </DialogContentText>
          {deleteTooling.isError && (
            <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
              An error occurred while deleting the tooling:{' '}
              {(deleteTooling.error as Error | undefined | null)?.message ??
                'Unknown error'}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => setDeleteDialogToggle(false)}
            variant="outlined"
            disabled={deleteTooling.isLoading}
          >
            Cancel
          </Button>
          <div className="spacer" />
          <Button
            onClick={() => deleteTooling.mutate()}
            color="error"
            variant="contained"
            disabled={deleteTooling.isLoading}
            endIcon={deleteTooling.isLoading && <CircularProgress size={20} />}
          >
            Confirm and delete
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
}
