import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { InfoCard, CodeSnippet, Progress } from '@backstage/core-components';
import { CardContent } from '@material-ui/core';
import { ScheduledNotebookNextRunDates } from '../../components/ScheduledNotebookNextRunDates';
import { notebooksApiRef } from '../../api';

interface runsPlaceholderProps {
  scheduledNotebookId: string;
}

export const RunsPlaceholder = (props: runsPlaceholderProps) => {
  const { scheduledNotebookId } = props;
  const [cronExpression, setCronExpression] = useState('');

  const titleText =
    'This notebook is scheduled to run with the below cron expression.' +
    ' All of your runs will appear here once they are completed.';

  const notebookClient = useApi(notebooksApiRef);

  useEffect(() => {
    notebookClient
      .getScheduledNotebookDetails(scheduledNotebookId)
      .then(e => e.cron_expression)
      .then(setCronExpression);
  }, [notebookClient, scheduledNotebookId]);

  return (
    <InfoCard title={titleText}>
      <CardContent style={{ textAlign: 'center' }}>
        <CodeSnippet text={cronExpression} language="text" />
        {cronExpression ? (
          <ScheduledNotebookNextRunDates cronExpression={cronExpression} />
        ) : (
          <Progress />
        )}
      </CardContent>
    </InfoCard>
  );
};
