import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import Alert from '@material-ui/lab/Alert';
import { useApi } from '@backstage/core-plugin-api';
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { AccessControlCard } from '../AccessControlCard';
import { ScopeItem } from './ScopeItem';
import ConfirmationDialog from '../ConfirmationDialog';
import { AccessControlContext } from '../../context';
import { essentialsApiRef, mintStorageApiRef } from '../../../../api';
import { hasPendingUpdates, removePendingUpdates } from '../../utils';
import { IResourceType } from '../../../../api/types/payload';
import { useInterval } from 'react-use';
import { useStyles } from './styles';
import { useSearchParams } from 'react-router-dom';
import { useNavigationBack } from '../../useNavigationBack';

const ScopeActions = ({
  setShowDeprecated,
}: {
  setShowDeprecated: Function;
}) => {
  const styles = useStyles();

  return (
    <>
      <FormControlLabel
        className={styles.switch}
        control={
          <Switch
            size="small"
            onChange={e => setShowDeprecated(e.target.checked)}
          />
        }
        label={<Typography variant="body2">Show deprecated</Typography>}
      />
    </>
  );
};

export function ApplicationScopes() {
  const mintApi = useApi(mintStorageApiRef);
  const essentialsApi = useApi(essentialsApiRef);
  const [showDeprecated, setShowDeprecated] = useState<boolean>(false);
  const [visibleData, setVisibleData] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasPendingNotification, setHasPendingNotification] = useState(false);
  const [_, setSearchParams] = useSearchParams();
  const [updateState, setUpdateState] = React.useState<{
    pending: boolean;
    success: boolean;
  }>({ pending: false, success: true });
  const [confirmationData, setConfirmationData] = React.useState<
    Array<IAccessControl.UpdateDataType>
  >([]);

  const {
    appName,
    appData,
    loadAppData,
    editState,
    setEditState,
    hasEditPermission,
  } = React.useContext(AccessControlContext);

  useInterval(() => {
    const pending = hasPendingUpdates('appScopes');
    if (pending && !hasPendingNotification) setHasPendingNotification(true);
    if (!pending) setHasPendingNotification(false);
  }, 500);

  const handleBack = useNavigationBack({
    page: 'appScopes',
    searchParamsKeys: ['editScopes'],
  });

  React.useEffect(() => {
    if (editState.appScopes) {
      setIsLoading(true);
      essentialsApi
        .getResourceTypes()
        .then(resourcesTypes => {
          resourcesTypes.forEach(
            (resource: IResourceType & { deprecated?: boolean }) => {
              resource.selectedScopes = appData[0].scopes;
              if (resource.name?.toLowerCase().includes('deprecated'))
                resource.deprecated = true;
            },
          );
          const sortedBySelected = sortBy(resourcesTypes, [
            function sort(resource) {
              return !resource.selectedScopes?.find(
                scope =>
                  scope.resource_type_id?.toLowerCase() ===
                  resource.name?.toLowerCase(),
              );
            },
          ]);
          setVisibleData(sortedBySelected);
        })
        .finally(() => setIsLoading(false));
    } else {
      setVisibleData(appData);
    }
    return () => {
      removePendingUpdates('appScopes');
    };
  }, [editState, appData, essentialsApi]);

  const onEdit = async () => {
    setEditState({ ...editState, appScopes: true });
    setSearchParams({
      editScopes: 'true',
    });
  };

  const showConfirmationModal = async (
    updatedData: Array<IAccessControl.UpdateDataType>,
  ) => {
    if (updatedData.length) {
      setOpen(true);
      setConfirmationData(updatedData);
    } else {
      setEditState({ ...editState, appScopes: false });
    }
  };

  const onCancel = async () => {
    handleBack();
    setConfirmationData([]);
    setIsLoading(false);
    setVisibleData(appData);
    removePendingUpdates('appScopes');
  };

  const onSave = async () => {
    setUpdateState(prev => ({ ...prev, pending: true }));
    mintApi
      .updateAppConfig(appName, appData[0], confirmationData)
      .then(ok => {
        if (ok) {
          setUpdateState(prev => ({ ...prev, success: true }));
          setEditState({ ...editState, appScopes: false });
          loadAppData();
          setOpen(false);
        } else {
          setUpdateState(prev => ({ ...prev, success: false }));
        }
      })
      .catch(() => setUpdateState(prev => ({ ...prev, success: false })))
      .finally(() => setUpdateState(prev => ({ ...prev, pending: false })));
  };

  return (
    <>
      <AccessControlCard
        trackingPlugin="application-registry"
        title={
          editState.appScopes ? 'Edit Application Scopes' : 'Application Scopes'
        }
        data={
          showDeprecated ? visibleData : visibleData.filter(i => !i.deprecated)
        }
        onSave={showConfirmationModal}
        onEdit={onEdit}
        onCancel={onCancel}
        canEdit={editState.appScopes}
        isLoading={isLoading}
        showPending={hasPendingNotification}
        render={(
          app,
          index,
          editable,
          updatedData,
          setUpdatedData,
          selectAll,
        ) => (
          <ScopeItem
            editable={editable}
            key={index}
            updatedData={updatedData}
            setUpdatedData={setUpdatedData}
            selectAll={selectAll}
            onEdit={onEdit}
            hasEditPermission={hasEditPermission}
            {...app}
          />
        )}
        actions={<ScopeActions setShowDeprecated={setShowDeprecated} />}
      />
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setUpdateState({ pending: false, success: true });
          setOpen(false);
        }}
        onSave={onSave}
      >
        <Box height="5px">{updateState.pending && <LinearProgress />}</Box>
        {!updateState.success && (
          <Alert severity="error">
            Failed updating configurations. Try again.
          </Alert>
        )}
        <Box maxHeight="300px" overflow="scroll" marginTop="1rem">
          {/* Showing to be added scopes if scopes were selected */}
          {!isEmpty(confirmationData.filter(item => item.selected)) && (
            <Box marginBottom="1rem">
              <Typography>
                These will be <strong>added</strong>:
              </Typography>
              <ul>
                {confirmationData
                  .filter(item => item.selected)
                  .map(({ scopeId, scopeSummary, resourceId }) => {
                    return (
                      <li>
                        <Typography>
                          {resourceId} - {scopeId} ({scopeSummary})
                        </Typography>
                      </li>
                    );
                  })}
              </ul>
            </Box>
          )}
          {/* Showing to be removed scopes if scopes were deselected */}
          {!isEmpty(confirmationData.filter(item => !item.selected)) && (
            <Box>
              <Typography>
                These will be <strong>removed</strong>:
              </Typography>
              <ul>
                {confirmationData
                  .filter(item => !item.selected)
                  .map(({ scopeId, scopeSummary, resourceId }) => {
                    return (
                      <li>
                        <Typography>
                          {resourceId} - {scopeId} ({scopeSummary})
                        </Typography>
                      </li>
                    );
                  })}
              </ul>
            </Box>
          )}
        </Box>
      </ConfirmationDialog>
    </>
  );
}
