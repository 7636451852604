import {
  Card,
  styled,
  Typography,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
} from '@material-ui/core';

export const Wrapper = styled(Card)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
  boxShadow: 'none',
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0, 0, 1),
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 'bold',
}));

export const GroupWrapper = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1, 1, 2, 1),
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minHeight: theme.spacing(6),
}));

export const MenuTitle = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 30,
  color: theme.palette.text.primary,
}));
