import React from 'react';
import { DeploymentInformation } from './DeploymentInformation';
import { CardContent } from '@material-ui/core';
import { SummaryCard } from '../common';

export function DeploymentInformationSummary() {
  const category = 'Deployment information';

  return (
    <SummaryCard category={category}>
      <CardContent>
        <DeploymentInformation hideConfirmationSection />
      </CardContent>
    </SummaryCard>
  );
}
