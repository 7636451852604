import { parseEntityRef } from '@backstage/catalog-model';
import { createApiRef } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/catalog-client';

export type TeamOwnedEntities = {
  getOwnedEntities(team: IEntityGroup, kind?: string): Promise<IEntity[]>;
};

export const teamOwnedEntitiesApiRef = createApiRef<TeamOwnedEntities>({
  id: 'team-owned-entities',
});

export class TeamOwnedEntitiesApi implements TeamOwnedEntities {
  private readonly catalogApi;

  constructor(catalogApi: CatalogApi) {
    this.catalogApi = catalogApi;
  }

  async getChildTeamsFromTeam(
    team: IEntityGroup,
    allTeams: IEntityGroup[],
  ): Promise<IEntityGroup[]> {
    const childRelations = team.relations
      ?.filter(relation => relation.type === 'parentOf')
      .map(relation => parseEntityRef(relation.targetRef).name);

    if (!childRelations) {
      return [];
    }

    const childTeams = allTeams.filter(childTeam =>
      childRelations.includes(childTeam.metadata.name),
    );
    let nextLevelChildren: IEntityGroup[] = [];

    for (const childTeam of childTeams) {
      nextLevelChildren = [
        ...nextLevelChildren,
        ...(await this.getChildTeamsFromTeam(
          childTeam as IEntityGroup,
          allTeams,
        )),
      ];
    }

    return [...childTeams, ...nextLevelChildren];
  }

  async getOwnedEntities(
    team: IEntityGroup,
    kind?: string,
  ): Promise<IEntityApp[]> {
    const allTeams = await this.catalogApi.getEntities({
      filter: {
        kind: 'Group',
      },
    });

    const teamsToSearch = await this.getChildTeamsFromTeam(
      team,
      allTeams.items as IEntityGroup[],
    ).then(data =>
      data.filter(
        childTeam =>
          childTeam.relations?.filter(relation => relation.type === 'ownerOf')
            .length !== 0,
      ),
    );

    const teams: string[] = [
      team.metadata.name,
      ...teamsToSearch.map(childTeam => childTeam.metadata.name),
    ];

    let filters: any = { filter: { 'spec.owner': teams } };

    if (kind) {
      filters = { filter: { kind: kind, 'spec.owner': teams } };
    }

    const response = await this.catalogApi.getEntities(filters);

    return response.items as IEntityApp[];
  }
}
