import {
  NotFoundException,
  ConflictException,
  NotOkException,
  ResourceAccessForbiddenException,
} from './exceptions';

async function raiseExceptionIfAny(response: Response) {
  switch (response.status) {
    case 403:
      throw new ResourceAccessForbiddenException(response.statusText);
    case 404:
      throw new NotFoundException(response.statusText);
    case 409:
      throw new ConflictException(response.statusText);
    default:
      if (!response.ok) throw new NotOkException(response.statusText);
  }
}

export async function handleResponse(response: Response) {
  await raiseExceptionIfAny(response);
  const contentType = response.headers.get('content-type');
  if (
    contentType &&
    ['application/json', 'application/problem+json'].includes(contentType)
  ) {
    return await response.json();
  }
  return await response.text();
}
