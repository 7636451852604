/**
 * Finds a value of a string path to a field in an entity
 * @param path The path to the field
 * @param entity The catalog entity
 * @example ```
 * findValueOfField('metadata.name', entity);
 * findValueOfField('spec.members.length', entity);
 * findValueOfField('spec.mail.0', entity);
 * ```
 */
export function findValueOfField(path: string, entity: IEntity) {
  const fields = path.split('.');
  let value: any = entity;

  fields.forEach(f => {
    value = value?.hasOwnProperty(f) ? value[f] : undefined;
  });

  return value;
}

/**
 * Sorts entities by a field while pushing empty values to the bottom
 * @param a First entity
 * @param b Second entity
 * @param field The field in the entity to use for sorting
 * @param order The order in which the current sorting is using. You can obtain this value using `onOrderChange`
 */
export function entitySort(
  a: IEntity,
  b: IEntity,
  field: string,
  order: 'asc' | 'desc' | '',
): number {
  const value1 = findValueOfField(field, a);
  const value2 = findValueOfField(field, b);
  const [first, second] = order === 'asc' ? [value1, value2] : [value2, value1];

  if (typeof first === 'number') {
    return value1 - value2;
  }

  if (!first || first.length === 0) {
    return 1;
  }

  if (!second || second.length === 0) {
    return -1;
  }

  if (typeof first === 'string') {
    return value1.localeCompare(value2);
  }

  // value are list of string
  return value1.join().localeCompare(value2.join());
}
