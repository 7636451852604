import { useCallback } from 'react';

export const useApplicationIDSelection = (
  selectedSidebarItem: string,
  userApps: any[],
  localStorage: any,
) => {
  return useCallback(() => {
    if (selectedSidebarItem === 'mine') {
      return userApps.map(app => app.metadata.name).join(',');
    }
    return localStorage?.application || '';
  }, [selectedSidebarItem, localStorage?.application, userApps]);
};
