import React from 'react';
import { observer } from 'mobx-react-lite';
import { LiveButton } from '../';
import { IPoll } from '../../../services';

interface Props {
  service: IPoll;
  isReady?: boolean;
}

export const LiveAction = observer(({ service, isReady = true }: Props) => {
  return <LiveButton service={service} isReady={isReady} />;
});
