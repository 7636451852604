import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import type { GetEntitiesResponse } from '@backstage/catalog-client';
import type { Entity } from '@backstage/catalog-model';
// TODO: This is a workaround to avoid racing conditions of setEntities.
import delay from 'lodash/delay';

type Batch = Record<'users' | 'teams', Array<string>>;
const LOADING_TEXT = 'LOADING...';

interface IReferenceContext {
  referenceUsers: Map<string, any>;
  referenceTeams: Map<string, any>;
  fetchBatch: {
    batch: Batch;
    updateBatch: React.Dispatch<React.SetStateAction<Batch>>;
    entities: {
      users: GetEntitiesResponse[] | string;
      teams: GetEntitiesResponse[] | string;
    };
  };
}

export const ReferenceContext = createContext<IReferenceContext>({
  referenceUsers: new Map(),
  referenceTeams: new Map(),
  fetchBatch: {
    batch: { users: [], teams: [] },
    updateBatch: () => {},
    entities: { users: LOADING_TEXT, teams: LOADING_TEXT },
  },
});

export function useReferenceContext() {
  const batchCalls = React.useRef({ users: 0, teams: 0 });
  const { referenceUsers, referenceTeams, fetchBatch } =
    useContext(ReferenceContext);

  const getUser = useCallback(
    async (userId: string): Promise<IEntityUser | string | undefined> => {
      if (!referenceUsers.has(userId)) {
        // Make sure users batch is only updated once to avoid multiple requests
        if (batchCalls.current.users === 0) {
          fetchBatch.updateBatch((prev: Batch) => ({
            ...prev,
            users: [...prev.users, userId],
          }));
          batchCalls.current.users = 1;
        }
        const data = fetchBatch.entities.users as
          | GetEntitiesResponse[]
          | string;
        if (typeof data === 'string') {
          return data;
        }

        let userEntity: Entity | undefined = undefined;
        data.some(value => {
          userEntity = value?.items.find(user =>
            [
              user.metadata.name,
              (user as IEntityUser).spec?.profile?.email,
            ].includes(userId),
          );
          return !!userEntity;
        });

        if (userEntity) {
          referenceUsers.set(userId, userEntity);
        } else {
          Promise.resolve(undefined);
        }
      }
      return referenceUsers.get(userId)!;
    },
    [referenceUsers, fetchBatch],
  );

  const getTeam = useCallback(
    async (teamId: string): Promise<IEntityGroup | string | undefined> => {
      if (!referenceTeams.has(teamId)) {
        // Make sure teams batch is only updated once to avoid multiple requests
        if (batchCalls.current.teams === 0) {
          fetchBatch.updateBatch((prev: Batch) => ({
            ...prev,
            teams: [...prev.teams, teamId],
          }));
          batchCalls.current.teams = 1;
        }
        // Return string value if data is being batched otherwise set requested team (data is ready)
        const data = fetchBatch.entities.teams as
          | GetEntitiesResponse[]
          | string;
        if (typeof data === 'string') {
          return data;
        }
        let teamEntity: Entity | undefined = undefined;
        data.some(value => {
          teamEntity = value?.items.find(team =>
            [
              ...((team.spec?.alias as string[]) || []),
              team.metadata.name,
              team.spec?.fullName,
              team.spec?.id,
            ].includes(teamId),
          );
          return !!teamEntity;
        });
        if (teamEntity) {
          referenceTeams.set(teamId, teamEntity);
        } else {
          Promise.resolve(undefined);
        }
      }
      return referenceTeams.get(teamId);
    },
    [referenceTeams, fetchBatch],
  );

  return { getUser, getTeam };
}

/* Wraps the provider with custom logic (handling batches and entities request...) */
export function ReferenceContextProvider(props: PropsWithChildren<{}>) {
  /**
   * This is a ref because no rendering should depend on it.
   * It is only used to store a value globally and fetched on-demand
   * It is designed to be mutated
   */
  const { current: referenceTeams } = React.useRef(new Map());
  const { current: referenceUsers } = React.useRef(new Map());
  const catalogApi = useApi(catalogApiRef);

  /* Batching calls of the ref components (users or teams) so we can fetch them all at once later */
  const [contextBatch, setContextBatch] = React.useState<Batch>({
    users: [],
    teams: [],
  });

  const [entities, setEntities] = React.useState<{
    users: GetEntitiesResponse[] | string;
    teams: GetEntitiesResponse[] | string;
  }>({ users: LOADING_TEXT, teams: LOADING_TEXT });

  /* Entities fetching is moved to provider level so we can get them all in one request */
  const getEntities = useCallback(
    async (type: 'teams' | 'users') => {
      if (type === 'teams') {
        const requests: Array<Promise<GetEntitiesResponse>> = [];
        const { withAliases, withoutAliases } = contextBatch.teams.reduce(
          (acc, team) => {
            if (team)
              if (isNaN(Number(team))) {
                acc.withAliases.push(team);
              } else {
                acc.withoutAliases.push(team);
              }
            return acc;
          },
          { withAliases: [] as string[], withoutAliases: [] as string[] },
        );

        // Construct request for cases where an alias is passed
        if (withAliases.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'Group',
                  'spec.id': withAliases,
                },
                {
                  kind: 'Group',
                  'spec.fullname': withAliases,
                },
                {
                  kind: 'Group',
                  'spec.alias': withAliases,
                },
              ],
            }),
          );
        }
        // Construct request for cases where SAP ID is passed
        if (withoutAliases.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'Group',
                  'metadata.name': withoutAliases,
                },
              ],
            }),
          );
        }
        const teamsResult = await Promise.all(requests);
        delay(() => setEntities(prev => ({ ...prev, teams: teamsResult })), 5);
      } else {
        const requests: Array<Promise<GetEntitiesResponse>> = [];
        const { byUserId, byEmail } = contextBatch.users.reduce(
          (acc, user) => {
            if (user)
              if (user.includes('@')) {
                acc.byEmail.push(user);
              } else {
                acc.byUserId.push(user);
              }
            return acc;
          },
          { byUserId: [] as string[], byEmail: [] as string[] },
        );
        // Construct request for cases where a user id is passed
        if (byUserId.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'user',
                  'metadata.name': byUserId,
                },
              ],
            }),
          );
        }
        // Construct request for cases where a user email is passed
        if (byEmail.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'user',
                  'spec.profile.email': byEmail,
                },
              ],
            }),
          );
        }
        const usersResult = await Promise.all(requests);
        delay(() => setEntities(prev => ({ ...prev, users: usersResult })), 5);
      }

      // Data fetching is done and batches can be cleared
      setContextBatch(prev => ({
        teams: type === 'teams' ? [] : prev.teams,
        users: type === 'users' ? [] : prev.users,
      }));
    },
    [contextBatch, catalogApi],
  );

  React.useEffect(() => {
    if (contextBatch.teams.length) {
      getEntities('teams').then(() =>
        setEntities(prev => ({ ...prev, teams: LOADING_TEXT })),
      );
    }
    if (contextBatch.users.length) {
      getEntities('users').then(() =>
        setEntities(prev => ({ ...prev, users: LOADING_TEXT })),
      );
    }
  }, [contextBatch, getEntities]);

  return (
    <ReferenceContext.Provider
      value={{
        referenceUsers,
        referenceTeams,
        fetchBatch: {
          batch: contextBatch,
          updateBatch: setContextBatch,
          entities,
        },
      }}
    >
      {props.children}
    </ReferenceContext.Provider>
  );
}
