import { createRouteRef } from '@backstage/core-plugin-api';

// Catalog Routes
export const catalogRouteRef = createRouteRef({
  id: 'CI-CD',
  params: [''],
});

// CDP routes
export const pipelinesRouteRef = createRouteRef({
  id: 'CDP',
  params: ['/cdp/'],
});

export const repositoriesRouteRef = createRouteRef({
  id: 'Repositories',
  params: [''],
});

export const organizationRouteRef = createRouteRef({
  id: 'Github organization',
  params: [':domain_alias/:org_name'],
});

export const repositoryRouteRef = createRouteRef({
  params: [':domain_alias/:org_name/:repo_name'],
  id: 'Repository',
});

export const runRouteRef = createRouteRef({
  params: [':domain_alias/:org_name/:repo_name/:runId/*'],
  id: 'Pipeline run details',
});
export const runShortRouteRef = createRouteRef({
  params: ['pipeline/:id'],
  id: 'Pipeline run details',
});

export const stepRoutes = {
  artifacts: 'artifacts',
  logs: 'logs',
  deployment: 'deployment_status',
  tests: 'test_results',
};

export const runRoutes = {
  pipeline: 'pipeline_graph',
  deliveryConfig: 'delivery_config',
};
