import React, { ReactElement, ReactNode } from 'react';
import type {
  ESSearchHit,
  GCSDynamicFiltersOptions,
  GCSSearchHit,
} from '../types';
import { ConfigFilter } from './ConfigFilter';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';

type IconType =
  | React.ComponentType<React.JSX.IntrinsicElements['svg']>
  | (OverridableComponent<SvgIconTypeMap> & { muiName: string });

/** Search index configuration */
interface ConfigIndexInitOptions {
  id: string;
  label: string;
  icon: IconType;
  accentColor: string;
  engine: SupportedEngines;
  filters?: ConfigFilter[];
  getTitle?: (hit: any) => ReactNode | undefined;
  getTitleIcon?: (hit: any) => ReactNode | null;
  getUrl?: (hit: any) => string | undefined | null;
  render: (hit: any) => ReactElement | null;
}

type SupportedEngines = 'gcs' | 'elastic';

export class ConfigIndex {
  private readonly _id: string;
  private readonly _label: string;
  private readonly _icon: IconType;
  private readonly _accentColor: string;
  private readonly _engine: SupportedEngines;
  private readonly _filters?: ConfigFilter[];
  private readonly _getTitle?: (hit: any) => ReactNode | undefined;
  private readonly _getTitleIcon?: (hit: any) => ReactNode | null;
  private readonly _getUrl?: (hit: any) => string | undefined | null;
  private readonly _render: (hit: any) => ReactElement | null;

  constructor(init: ConfigIndexInitOptions) {
    this._id = init.id;
    this._label = init.label;
    this._icon = init.icon;
    this._engine = init.engine;
    this._accentColor = init.accentColor;
    this._filters = init.filters;
    this._getTitle = init.getTitle;
    this._getUrl = init.getUrl;
    this._render = init.render;
    this._getTitleIcon = init.getTitleIcon;
  }

  get id() {
    return this._id;
  }

  get label() {
    return this._label;
  }

  get icon() {
    return this._icon;
  }

  get engine(): SupportedEngines {
    return this._engine;
  }

  get accentColor() {
    return this._accentColor;
  }

  get filters() {
    return this._filters;
  }

  get getTitle() {
    return this._getTitle;
  }

  get getTitleIcon() {
    return this._getTitleIcon;
  }

  get getUrl() {
    return this._getUrl;
  }

  get render() {
    return this._render;
  }

  isMatchingQuery(query: string): boolean {
    return this.id === query;
  }
}

type ConfigIndexInitOptionsForES<T = any> = Omit<
  ConfigIndexInitOptions,
  'engine' | 'render' | 'getUrl' | 'getTitle'
> & {
  render: (hit: ESSearchHit<T>) => ReactElement | null;
  getTitle?: (hit: ESSearchHit<T>) => ReactNode | undefined;
  getTitleIcon?: (hit: ESSearchHit<T>) => ReactNode | null;
  getUrl?: (hit: ESSearchHit<T>) => string | undefined;
};

/** Search config variant with Elasticsearch engine */
export class SearchConfigForES<T = any> extends ConfigIndex {
  constructor(init: ConfigIndexInitOptionsForES<T>) {
    super({ ...init, engine: 'elastic' });
  }
}

type ConfigIndexInitOptionsForGCS = Omit<
  ConfigIndexInitOptions,
  'engine' | 'render' | 'getUrl' | 'getTitle'
> & {
  render: (hit: GCSSearchHit) => ReactElement | null;
  getTitle: (hit: GCSSearchHit) => ReactNode;
  getTitleIcon?: (hit: GCSSearchHit) => ReactNode | null;
  getUrl: (hit: GCSSearchHit) => string | undefined | null;
  datasource: string;
  dynamicFilters?: boolean | GCSDynamicFiltersOptions;
  objectTypes?: string[];
  isPredefinedSource?: boolean;
};

/** Search config variant with Google Cloud Search engine */
export class SearchConfigForGCS extends ConfigIndex {
  private readonly _datasource: string;
  private readonly _dynamicFilters?: boolean | GCSDynamicFiltersOptions = false;
  private readonly _objectTypes?: string[];
  private readonly _isPredefinedSource?: boolean;

  constructor(init: ConfigIndexInitOptionsForGCS) {
    super({ ...init, engine: 'gcs' });
    this._datasource = init.datasource;
    this._dynamicFilters = init.dynamicFilters;
    this._objectTypes = init.objectTypes;
    this._isPredefinedSource = init.isPredefinedSource ?? false;
  }

  get datasource() {
    return this._datasource;
  }

  get dynamicFilters() {
    return this._dynamicFilters;
  }

  get objectTypes() {
    return this._objectTypes;
  }

  get isPredefinedSource() {
    return this._isPredefinedSource;
  }
}
