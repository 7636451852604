import {
  EventTrackerProps,
  HTMLEvent,
  PluginTracking,
} from 'plugin-ui-components';
import { QUERY_KEY, TAB_KEY } from '../constants';

const searchLocation =
  window.location.pathname === '/search' ? 'search_page' : 'search_modal';

const defaultParams: Pick<GATrackingEvent['parameters'], 'search_location'> = {
  search_location: searchLocation,
};

export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'search',
  eventCategory: searchLocation,
};

function getSearchTerm(): string | null {
  return new URLSearchParams(window.location.search).get(QUERY_KEY);
}

function getSearchTab(): string | null {
  return new URLSearchParams(window.location.search).get(TAB_KEY);
}

/**
 * Search for a term
 * @param term The term that was searched
 * @param tab The tab that was searched
 */
export function searchQuery(term: string, tab: string) {
  PluginTracking.sendEvent({
    name: 'search',
    parameters: { search_term: term, ...common, search_tab: tab ? tab : 'all' },
  });
}

/**
 * Search filter change
 * @param filters The filters that were changed
 */
export function filterChange(filters: object) {
  PluginTracking.sendEvent({
    name: 'search',
    parameters: { ...common, search_filters: JSON.stringify(filters) },
  });
}

/**
 * View a search result
 * @param href The URL of the result
 * @param tabKey The tab that was searched
 * @param action The type of result
 * @param totalResults The total number of results
 */
export function viewSource(
  href: string,
  tabKey: string,
  action: string,
  totalResults: number,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_tab: tabKey,
      search_interaction: `Click link: ${href}`,
      search_term: getSearchTerm(),
      search_action: action,
      search_results_count: totalResults,
    },
  };
}

/**
 * Click on a search result link
 * @param url The URL of the result
 */
export function resultClick(
  url: string,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_interaction: `Click link: ${url}`,
      search_tab: getSearchTab(),
      search_term: getSearchTerm(),
    },
  };
}

/**
 * Click on a search result link
 * @param term
 * @param index
 * @param tabKey
 * @param totalResults
 * @param link
 */
export function actionArea(
  term: string,
  index: number,
  tabKey: string,
  totalResults: number,
  link: string,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_term: term,
      search_interaction: `Click card title: ${link ? link : '--'}`,
      search_result_card_index: index,
      search_tab: tabKey,
      search_results_count: totalResults,
    },
  };
}

/**
 * View the owner of an entity
 * @param tabKey The tab that was searched
 * @param teamId The ID of the owner team
 * @param totalResults The total number of results
 */
export const viewOwner = (
  tabKey: string,
  teamId: string,
  totalResults: number,
): GATrackingEvent & { interaction: HTMLEvent } => ({
  interaction: 'onClick',
  name: 'search',
  parameters: {
    ...defaultParams,
    search_tab: tabKey,
    search_interaction: `View owner: ${teamId}`,
    search_action: 'Owner',
    search_term: getSearchTerm(),
    search_results_count: totalResults,
  },
});

/**
 * View the parent of a team
 * @param tab The tab that was searched
 * @param teamId The ID of the team
 * @param totalResults The total number of results
 */
export function viewParent(
  tab: string,
  teamId: string,
  totalResults: number,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_tab: tab,
      search_interaction: `View parent: ${teamId}`,
      search_action: 'Parent Team',
      search_term: getSearchTerm(),
      search_results_count: totalResults,
    },
  };
}

/**
 * View the owner of an API (application)
 * @param tab The tab that was searched
 * @param appId The ID of the application
 * TODO: Why is this needed?
 * @param totalResults The total number of results
 */
export function viewApiApp(
  tab: string,
  appId: string,
  totalResults: number,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_tab: tab,
      search_interaction: `View application: ${appId}`,
      search_action: 'Application',
      search_term: getSearchTerm(),
      search_results_count: totalResults,
    },
  };
}

/**
 * No results found for a search tab
 * @param tab The tab that was searched
 */
export function nullResult(tab: string) {
  return PluginTracking.sendEvent({
    name: 'search',
    parameters: {
      search_term: getSearchTerm(),
      search_tab: tab,
      search_results_count: 0,
      ...defaultParams,
    },
  });
}

/** User email is clicked */
export function userEmailClick(): EventTrackerProps {
  return {
    ...common,
    eventCategory: `Search result interaction for ${common.eventCategory}`,
    interaction: 'onClick',
    eventAction: 'click',
    eventLabel: `Tab: User, Email clicked`,
  };
}

/** User email is clicked */
export function teamEmailClick(): EventTrackerProps {
  return {
    ...common,
    eventCategory: `Search result interaction for ${common.eventCategory}`,
    interaction: 'onClick',
    eventAction: 'click',
    eventLabel: `Tab: Team, Email clicked`,
  };
}

/**
 * Search page changed
 * @param newPage The new page that was selected
 */
export function pageChange(newPage: number) {
  return PluginTracking.sendEvent({
    ...common,
    eventAction: 'click',
    eventLabel: `Page "${newPage}" selected`,
  });
}

/**
 * Change the search tab
 * @param newTab The new tab that was selected
 */
export function tabChange(newTab: string) {
  return PluginTracking.sendEvent({
    ...common,
    eventAction: 'click',
    eventLabel: `Tab "${newTab}" selected`,
  });
}

/**
 * Click on a link in the Recent Searches section
 * @param term The term that was clicked
 */
export function clickRecentSearches(term: string): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Recent Searches`,
  };
}

/**
 * Click on a link in the Top Searches section
 * @param term The term that was clicked
 */
export function clickTopSearches(term: string): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Top Searches`,
  };
}

/**
 * Click on a documentation link in the Top Searches section
 * @param term The term that was clicked
 */
export function clickTopSearchesDocs(term: string): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Top Searches Docs link`,
  };
}

/**
 * Expand or collapse the Hot Pages accordion
 * @param expanded Whether the accordion is expanded or collapsed
 */
export function expandHotPages(expanded: boolean): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Set Hot Pages Accordion ${
      expanded ? 'expanded' : 'collapsed'
    }}`,
  };
}

/**
 * Click on a link in the Hot Pages section
 * @param link The link that was clicked
 */
export function clickHotPagesLink(link: string): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${link}" link on Hot Pages`,
  };
}

/**
 * Expand the search modal
 * @param data The data that is being searched for
 */
export function expandModal(data: string): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Expand Modal with data: ${data}`,
  };
}

/** Close the search modal */
export function closeModal(): EventTrackerProps {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: 'Close Modal',
  };
}
