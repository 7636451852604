import React from 'react';
import { ReviewContext } from '../context';
import { Pill } from '../styles';
import {
  criticalityColors,
  criticalityScores,
  criticalityTierScores,
} from '../config';

const labels = new Map<IAppReview.CriticalityStates, string>([
  ['Unknown', '— please fill out the form above'],
  ['Tier 1', '— immediate impact to the business, e.g. checkout'],
  ['Tier 2', '— imminent impact to the business, e.g. recommendation systems'],
  [
    'Tier 3',
    '— long-term or low impact to the business, e.g. background jobs which can be retried, internal tools',
  ],
  [
    'Not Relevant',
    '— not relevant for the business at the moment, e.g. not in production or proof of concept',
  ],
]);

export function CalculatedCriticality() {
  const [tier, setTier] =
    React.useState<IAppReview.CriticalityStates>('Unknown');
  const { review } = React.useContext(ReviewContext);

  React.useEffect(() => {
    let score: number = 0;
    let checkedFields: number = 0;
    Object.keys(criticalityScores).forEach(key => {
      const field = key as keyof IAppReview.CriticalityScoreFields;
      if (review[field]) {
        score +=
          criticalityScores[field][review[field] as IAppReview.YesNoNotSure] ||
          0;
        checkedFields++;
      }
    });
    const isChecked = checkedFields === Object.keys(criticalityScores).length;
    if (review.in_production === 'no') {
      setTier('Not Relevant');
    } else if (!isChecked) {
      setTier('Unknown');
    } else if (score > criticalityTierScores['Tier 1']) {
      setTier('Tier 1');
    } else if (score > criticalityTierScores['Tier 2']) {
      setTier('Tier 2');
    } else if (score > criticalityTierScores['Tier 3']) {
      setTier('Tier 3');
    }
  }, [review]);

  return (
    <>
      <dt>Calculated criticality</dt>
      <dd>
        <Pill data-color={criticalityColors.get(tier)} label={tier} />
        &nbsp;
        {labels.get(tier)}
      </dd>
    </>
  );
}
