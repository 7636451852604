import { observable, action, makeObservable, computed } from 'mobx';

export enum StatesEnum {
  Idle,
  Loading,
  Reloading,
  Loaded,
  NotFound,
  Error,
}

export class State {
  state: StatesEnum;

  constructor() {
    this.state = StatesEnum.Idle;

    makeObservable(this, {
      state: observable,
      setIdle: action,
      setLoading: action,
      setReloading: action,
      setLoaded: action,
      setError: action,
      setNotFound: action,
      isIdle: computed,
      isLoading: computed,
      isReloading: computed,
      isLoaded: computed,
      hasError: computed,
      isNotFound: computed,
    });
  }

  public setIdle() {
    this.state = StatesEnum.Idle;
  }

  public setLoading() {
    this.state = StatesEnum.Loading;
  }

  public setReloading() {
    this.state = StatesEnum.Reloading;
  }

  public setLoaded() {
    this.state = StatesEnum.Loaded;
  }

  public setNotFound() {
    this.state = StatesEnum.NotFound;
  }

  public setError() {
    this.state = StatesEnum.Error;
  }

  public get isIdle() {
    return this.state === StatesEnum.Idle;
  }

  public get isLoading() {
    return this.state === StatesEnum.Loading;
  }

  public get isReloading() {
    return this.state === StatesEnum.Reloading;
  }

  public get isLoaded() {
    return this.state === StatesEnum.Loaded;
  }

  public get isNotFound() {
    return this.state === StatesEnum.NotFound;
  }

  public get hasError() {
    return this.state === StatesEnum.Error;
  }
}
