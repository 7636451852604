import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';

import { RollbackDialog } from './RollbackDialog';
import { Tooltip } from '../../../../../common';

function RollbackAction() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function open() {
    setIsDialogOpen(true);
  }

  function close() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <Tooltip title="Rollback plans">
        <IconButton onClick={open} data-testid="main-button">
          <UndoIcon />
        </IconButton>
      </Tooltip>

      <RollbackDialog isOpen={isDialogOpen} handleClose={close} />
    </>
  );
}

export const RollbackActionComponent = React.memo(RollbackAction, _ => true);
