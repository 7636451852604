import React from 'react';
import { Chip, ChipProps, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';
import cn from 'classnames';
import { PluginTracking } from '../Tracking';

interface Props extends ChipProps {
  category: string;
}

export function DocCategoryChip({ category, ...props }: Props) {
  const styles = useStyles();

  if (!category) return <></>;

  function sendHoverTracking() {
    PluginTracking.sendEvent({
      plugin: 'zalando-docs',
      eventAction: 'hover',
      eventLabel: 'User hovered over documentation category chip',
      eventCategory: 'Documentation category chip',
    });
  }

  const titleText =
    category === 'Not Set'
      ? 'Document category is not set'
      : `Document category: ${category}`;
  return (
    <Tooltip title={titleText}>
      <Chip
        {...props}
        size="small"
        variant="outlined"
        color="primary"
        className={cn(styles.chip, props.className)}
        label={category}
        onMouseEnter={sendHoverTracking}
      />
    </Tooltip>
  );
}
