import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function ScmUrl({ value, issue }: FieldProps<'scm_url'>) {
  return (
    <>
      <dt>SCM URL</dt>
      <dd>
        {value ? (
          <Link to={value}>
            {value} <OpenInNewIcon />
          </Link>
        ) : (
          'No SCM URL defined.'
        )}
        <InfoTooltip>
          The URL to the main source code (git) repository of the application.
          The SCM URL must point to a Zalando hosted git platform and have the
          form {`"https://{git - host}/{org}/{repo}"`}.
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
