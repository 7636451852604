import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Category';
const id = 'docs-category-filter';
export const DocsCategoryFilter = React.memo(() => {
  const { availableDocsCategory, docsCategoryFilter, setDocsCategoryFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl>
      <Autocomplete
        id={id}
        multiple
        options={availableDocsCategory}
        value={docsCategoryFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) => setDocsCategoryFilter(value as string[])}
        renderInput={params => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
