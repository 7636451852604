import React from 'react';
import { Divider, CardContent } from '@material-ui/core';
import * as S from '../styles';
import { Alert } from '@material-ui/lab';

export const IssuesCard = ({
  issues = [] as IAppReview.StoredReviewIssue[],
}) => (
  <>
    {!!issues.length && (
      <S.StyledCard>
        <S.StyledCardHeader
          title="Issues"
          titleTypographyProps={{ component: 'h2' } as any}
        />
        <Divider />
        <CardContent>
          {issues
            .filter(issue => issue.type !== 'success')
            .map(issue => (
              <Alert key={issue.message} severity={issue.type}>
                {issue.message}
              </Alert>
            ))}
        </CardContent>
      </S.StyledCard>
    )}
  </>
);
