import { ComponentType } from 'react';
import { Alerts } from './Alerts';
import { ApplicationMetadata } from './ApplicationMetadata';
import { Comments } from './Comments';
import { CriticalityAssessment } from './CriticalityAssessment';
import { CyberWeek } from './CyberWeek';
import { Dashboards } from './Dashboards';
import { LoadTests } from './LoadTests';
import { NamingAndInclusiveLanguage } from './NamingAndInclusiveLanguage';
import { DataClassification } from './DataClassification';
import { DeploymentInformation } from './DeploymentInformation';
import { OpenTracing } from './OpenTracing';
import { API } from './API';
import { Playbooks } from './Playbooks';
import { ProductionReadinessReview } from './ProductionReadinessReview';
import { ServiceLevelObjectives } from './ServiceLevelObjectives';
import { TechStack } from './TechStack';
import { validateCyberweekStep } from './validations';

export interface IStep {
  field: IAppReview.FormStepField;
  header: string;
  component: ComponentType<any>;
  fields: IAppReview.IssueCheckField[];
  optional?: boolean;
  validate?: (review: IAppReview.Review) => boolean;
}

export const steps: IStep[] = [
  {
    field: 'kio_ok',
    header: 'Application metadata',
    component: ApplicationMetadata,
    fields: [
      'id',
      'name',
      'subtitle',
      'active',
      'criticality_level',
      'team_id',
      'incident_contact',
      'description',
      'service_url',
      'scm_url',
      'documentation_url',
      'specification_url',
      'support_url',
    ],
  },
  {
    field: 'naming_ok',
    header: 'Naming and inclusive language',
    component: NamingAndInclusiveLanguage,
    fields: [],
  },
  {
    field: 'criticality_ok',
    header: 'Criticality assessment',
    component: CriticalityAssessment,
    fields: [],
  },
  {
    field: 'data_classification_ok',
    header: 'Data classification',
    component: DataClassification,
    fields: [],
  },
  {
    field: 'deployment_ok',
    header: 'Deployment information',
    component: DeploymentInformation,
    fields: ['kubernetes_info', 'stups_info', 'deployctl_info'],
  },
  {
    field: 'opentracing_ok',
    header: 'OpenTracing',
    component: OpenTracing,
    fields: ['lightstep_info'],
  },
  {
    field: 'api_ok',
    header: 'API',
    component: API,
    fields: ['api_info'],
  },
  {
    field: 'tech_ok',
    header: 'Tech Stack',
    component: TechStack,
    fields: ['tech_info'],
  },
  {
    field: 'dashboards_ok',
    header: 'Dashboards',
    component: Dashboards,
    fields: [],
  },
  {
    field: 'slos_ok',
    header: 'Service Level Objectives (SLOs)',
    component: ServiceLevelObjectives,
    fields: [],
  },
  {
    field: 'alerts_ok',
    header: 'Alerts',
    component: Alerts,
    fields: ['opsgenie_teams'],
  },
  {
    field: 'playbooks_ok',
    header: 'Playbooks',
    component: Playbooks,
    fields: ['playbooks'],
  },
  {
    field: 'load_tests_ok',
    header: 'Load tests',
    component: LoadTests,
    fields: ['load_test_info'],
  },
  {
    field: 'production_readiness_review_ok',
    header: 'Production readiness review (PRR)',
    component: ProductionReadinessReview,
    fields: [],
  },
  {
    field: 'cyberweek_ok',
    header: 'Cyber Week',
    component: CyberWeek,
    fields: [],
    validate: validateCyberweekStep,
  },
  {
    field: 'comments',
    header: 'Additional Comments',
    component: Comments,
    fields: [],
    optional: true,
  },
];
