import React from 'react';
import { Header, Breadcrumbs, LinkButton } from '@backstage/core-components';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from '../../StylingComponent/styles';
import DescriptionIcon from '@material-ui/icons/Description';

interface TestDetailsHeaderProps {
  testName: string;
}

export const TestDetailsHeader = ({ testName }: TestDetailsHeaderProps) => {
  const classes = useStyles();
  return (
    <Header
      title={testName || 'Test Details'}
      subtitle={
        <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
          <RouterLink to="/load-tests" style={{ textDecoration: 'none' }}>
            Load Tests
          </RouterLink>
          <span style={{ fontWeight: 'bold' }}>
            {testName || 'Test Details'}
          </span>
        </Breadcrumbs>
      }
    >
      <LinkButton
        to="https://continuous-load-testing.docs.zalando.net/"
        size="small"
        variant="outlined"
        style={{ color: 'white' }}
        className={classes.docsButton}
      >
        Docs &nbsp; <DescriptionIcon />
      </LinkButton>
    </Header>
  );
};
