import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { LicenseType } from '../../api/interfaces/model';
import { EnumFieldFilter } from '../tables/ResultsFilter/EnumFilter';
import { SPECIAL_CHARACTERS } from '../../constants/paging';
import { useApi } from '@backstage/core-plugin-api';
import { modelsApiRef } from '../../api/services/models';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@material-ui/lab';

interface EditModelFormProps {
  name?: string;
  description?: string | undefined;
  license_type?: LicenseType | undefined;
  license_link?: string | undefined;
  model_id?: number;
}

export const EditModelForm = ({
  name,
  description,
  license_type,
  license_link,
  model_id,
}: EditModelFormProps) => {
  const [errors, setErrors] = useState<{
    name?: string;
    licenseLink?: string;
    description?: string;
  }>({});

  const modelsApi = useApi(modelsApiRef);

  const [form, setForm] = React.useState({
    name: name,
    description: description,
    license_type: license_type?.toString(),
    license_link: license_link,
  });

  const [openAlert, setOpenAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [updateError, setUpdateError] = React.useState<string>();
  const [changeMade, setChangeMade] = React.useState(false);
  const [modelNameLength, setModelNameLength] = useState<number>(
    name?.length || 0,
  );

  const handleSubmit = () => {
    setLoading(true);
    modelsApi.updateModel(model_id as number, form).catch(error => {
      setUpdateError(`${error}`);
    });
    setLoading(false);
    setOpenAlert(true);
  };

  const validateName = (modelName: string) => {
    if (modelName.length < 4 || SPECIAL_CHARACTERS.test(modelName)) {
      return 'The model name must have at least 4 characters and not contain special characters other than _ and -.';
    }
    return '';
  };

  const handleNameChange = (field: string, ev: any) => {
    const value = ev.target.value;

    if (form.name === value) return;
    setForm(prevState => ({ ...prevState, [field]: value }));
    if (field === 'name') {
      setModelNameLength(value.length);
      const nameError = validateName(value);
      setErrors(prev => ({ ...prev, name: nameError }));
      setHasError(nameError.length > 0);
    }
    setChangeMade(true);
  };

  const handleLicenseLinkChange = (ev: any) => {
    const value = ev.target.value;

    if (form.license_link === value) return;
    setForm(prevState => ({ ...prevState, license_link: value }));
    if (!value.startsWith('http')) {
      setErrors(prev => ({
        ...prev,
        licenseLink: 'License link must start with "http".',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, license_link: '' }));
      setChangeMade(true);
    }
  };

  const handleDescriptionChange = (ev: any) => {
    const value = ev.target.value;

    if (form.description === value) return;
    setForm(prevState => ({ ...prevState, description: value }));
    if (value.length < 10) {
      setErrors(prev => ({
        ...prev,
        description: 'Description must be at least 10 characters long.',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, description: '' }));
      setChangeMade(true);
    }
  };

  const handleLicenseChange = (value: string) => {
    if (form.license_type === value) return;
    setForm(prev => ({ ...prev, license_type: value }));
    setChangeMade(true);
  };

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <form>
        {updateError && updateError.length > 0 && (
          <Alert color="error">{updateError}</Alert>
        )}
        <br />
        <FormControl fullWidth size="medium">
          <TextField
            value={form.name}
            onChange={ev => handleNameChange('name', ev)}
            fullWidth
            error={!!errors.name}
            helperText={errors.name || `Current Length: ${modelNameLength}`}
            disabled={loading}
            size="small"
            required
          />
        </FormControl>

        <FormControl fullWidth size="small">
          <EnumFieldFilter
            label="License"
            id="license"
            enumField={LicenseType}
            selected={form.license_type || ''}
            onChange={value => handleLicenseChange(value)}
            loading={loading}
          />
          <FormHelperText>
            Select the appropriate license for the model.
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth size="medium">
          <TextField
            value={form.license_link}
            onChange={handleLicenseLinkChange}
            fullWidth
            error={!!errors.licenseLink}
            helperText={errors.licenseLink || 'The link to the license'}
            disabled={loading}
            size="small"
            required
          />
        </FormControl>

        <FormControl fullWidth size="medium">
          <TextField
            value={form.description}
            onChange={handleDescriptionChange}
            fullWidth
            multiline
            rows={5}
            error={!!errors.description}
            helperText={
              errors.description ||
              'A description of the model. You can be as detailed as you want.'
            }
            disabled={loading}
            size="medium"
            required
          />
        </FormControl>

        <Grid item xs={3} alignItems="flex-end">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={loading || !changeMade || hasError}
            onClick={handleSubmit}
          >
            Update model
          </Button>
        </Grid>
      </form>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={updateError ? 'error' : 'success'}
          variant="filled"
        >
          {updateError ? 'Error updating model' : 'Model updated successfully'}
        </Alert>
      </Snackbar>
    </Box>
  );
};
