import {
  IDeploymentValidation,
  IStepRunProblems,
  IValidationProblem,
} from '../../api/types/payload';
import { VALIDATION_SEVERITY } from '../../constants';

type categorizedProblems = {
  resourceOverwrite: IValidationProblem[];
  others: IValidationProblem[];
};

// modifyMessageForResourceOverwriteProblems overrides all messages of resource overwrite problems (see problem domains in cdp-deployment-validator).
export function modifyMessageForResourceOverwriteProblems(
  validationResults: IDeploymentValidation,
): IDeploymentValidation {
  function modify(problems: IValidationProblem[]): IValidationProblem[] {
    const problemByCategory = problems.reduce(
      (categorized, p) => {
        if (p.rule_id?.startsWith('source-information-')) {
          categorized.resourceOverwrite.push(p);
        } else {
          categorized.others.push(p);
        }
        return categorized;
      },
      { resourceOverwrite: [], others: [] } as categorizedProblems,
    );

    const resourceOverwriteProblems = getResourceOverwriteMessages(
      problemByCategory.resourceOverwrite,
    );
    return [...problemByCategory.others, ...resourceOverwriteProblems];
  }

  const problems = Object.keys(validationResults).reduce(
    (acc, manifestName) => {
      const errors = modify(validationResults[manifestName].errors || []);
      const warnings = modify(validationResults[manifestName].warnings || []);

      acc[manifestName] = {
        errors: errors,
        warnings: warnings,
      };
      return acc;
    },
    {} as IDeploymentValidation,
  );

  return problems;
}

type groupedResourceOverwriteProblems = {
  [key: string]: Array<IValidationProblem>;
};

function getResourceOverwriteMessages(
  problems: IValidationProblem[],
): IValidationProblem[] {
  const problemByRuleID = problems.reduce((grouped, result) => {
    const ruleID = result.rule_id || '';
    (grouped[ruleID] = grouped[ruleID] || []).push(result);
    return grouped;
  }, {} as groupedResourceOverwriteProblems);

  return Object.values(problemByRuleID).map(results => {
    const resourcesStr = results
      .map(result => `${result.metadata?.kind}/${result.metadata?.name}`)
      .join(', ');
    const conflictType = results[0].rule_id
      ?.replace('source-information-', '')
      .replaceAll('-', ' ');
    const newValue = results[0].metadata?.new_value;
    const originalValue = results[0].metadata?.original_value;
    results[0].message = `Your ${conflictType} '${newValue}' in this PR is overwriting the resource(s) '${resourcesStr}' already deployed by '${originalValue}'.`;
    return results[0];
  });
}

export type ValidationProblemType =
  | typeof VALIDATION_SEVERITY.ERROR
  | typeof VALIDATION_SEVERITY.WARNING;

export function hasValidationProblems(
  problems: IStepRunProblems,
  type: ValidationProblemType,
): boolean {
  let hasDeploymentProblems = false;
  let hasScriptProblems = false;

  if (problems.script_validation) {
    hasScriptProblems = problems.script_validation.some(
      problem => problem.severity === type,
    );
  }

  if (problems.deployment_validation) {
    const problemArrType = `${type}s` as 'warnings' | 'errors';
    hasDeploymentProblems = Object.values(problems.deployment_validation).some(
      problem => problem[problemArrType]?.length,
    );
  }

  return hasDeploymentProblems || hasScriptProblems;
}

export function hasValidationErrors(problems: IStepRunProblems): boolean {
  return hasValidationProblems(problems, VALIDATION_SEVERITY.ERROR);
}

export function hasValidationWarnings(problems: IStepRunProblems): boolean {
  return hasValidationProblems(problems, VALIDATION_SEVERITY.WARNING);
}

export function hasOnlySecurityDeploymentErrors(
  problems: IStepRunProblems,
): boolean {
  if (problems.deployment_validation) {
    const deploymentProblems = Object.values(problems.deployment_validation);

    const hasSecurityErrors = deploymentProblems.some(problem =>
      problem?.errors?.some(error => error.domain === 'security'),
    );

    const hasOtherErrors = deploymentProblems.some(problem =>
      problem?.errors?.some(error => error.domain !== 'security'),
    );

    return hasSecurityErrors && !hasOtherErrors;
  }

  return false;
}
