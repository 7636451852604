import React from 'react';
import {
  StatusOK,
  StatusAborted,
  StatusPending,
  StatusError,
} from '@backstage/core-components';

export const ModelStatusBadge = ({
  status,
}: {
  status: string | undefined;
}) => {
  switch (status) {
    case 'Approved':
    case 'Deployed':
      return <StatusOK>{status}</StatusOK>;
    case 'PendingManualApproval':
    case 'Not deployed':
      return <StatusPending>{status}</StatusPending>;
    case 'TimedOut':
      return <StatusAborted>{status}</StatusAborted>;
    case 'Rejected':
      return <StatusError>{status}</StatusError>;
    default:
      return <>{status}</>;
  }
};
