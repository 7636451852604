import axios from 'axios';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import type {
  ESSearchQuery,
  GCSSearchQuery,
  GlobalSearchFilter,
  GlobalSearchQuery,
} from './types';
import { searchConfig } from './config';
import { SearchConfigForGCS } from './models';

export class SunriseSearchService {
  constructor(
    private readonly oauth2Client: OAuthApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  /**
   * Search in ElasticSearch
   * @param request The search request
   * @private
   */
  async searchInES(request: GlobalSearchQuery) {
    const backendUrl = await this.discoveryApi.getBaseUrl('search');
    const accessToken = await this.oauth2Client.getAccessToken();
    const indexesToSearch = request.indexes.filter(i => i.engine === 'elastic');

    const body: ESSearchQuery = {
      query: request.query,
      offset: request.offset,
      limit: request.limit,
      indexes: indexesToSearch.map(i => ({
        name: i.id,
        filters: this.makeESFilters(i.id, request.filters),
      })),
    };

    const response = await axios.post(`${backendUrl}/elastic`, body, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  /**
   * Search in Google Cloud Search
   * @param request The search request
   * @private
   */
  async searchInGCS(request: GlobalSearchQuery) {
    const backendUrl = await this.discoveryApi.getBaseUrl('search');
    const accessToken = await this.oauth2Client.getAccessToken();
    const indexesToSearch = (request.indexes as SearchConfigForGCS[])
      .filter(i => i.engine === 'gcs')
      // Split the name by ',' and a new list of indexes where each of them share the same filters
      .reduce(
        (
          acc,
          { id, datasource: compositeDatasource, isPredefinedSource, ...index },
        ) => {
          const datasources = compositeDatasource.split(',');
          datasources.forEach(datasource => {
            acc.push(
              new SearchConfigForGCS({
                ...(index as any),
                id,
                datasource,
                isPredefinedSource,
              }),
            );
          });
          return acc;
        },
        [] as SearchConfigForGCS[],
      );

    let query = request.query;
    const indexes = indexesToSearch.map(i => {
      const [objectTypeFilters, facets] = this.makeGCSFilters(
        i.id,
        request.filters,
      );
      if (facets) query = `${query} ${facets}`;

      return {
        name: i.datasource,
        filters: objectTypeFilters,
        isPredefinedSource: i.isPredefinedSource,
      };
    });

    const body: GCSSearchQuery = {
      query,
      offset: request.offset,
      limit: request.limit,
      indexes,
    };

    const response = await axios.post(`${backendUrl}/gcs`, body, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  /**
   * Search in all configured search engines
   * @param request The search request
   */
  async searchAll(request: Omit<GlobalSearchQuery, 'indexes'>) {
    const indexes = searchConfig.indexes;
    const elasticSearchPromise = this.searchInES({
      query: request.query,
      indexes: indexes.filter(i => i.engine === 'elastic'),
      offset: request.offset,
      limit: request.limit,
      filters: request.filters,
    });

    const gcsSearchPromise = this.searchInGCS({
      query: request.query,
      indexes: indexes.filter(i => i.engine === 'gcs'),
      offset: request.offset,
      limit: request.limit,
      filters: request.filters,
    });

    const [elasticSearchResults, gcsSearchResults] = await Promise.all([
      elasticSearchPromise,
      gcsSearchPromise,
    ]);

    const total = elasticSearchResults.total + gcsSearchResults.total;
    const results = elasticSearchResults.results.concat(
      gcsSearchResults.results,
    );
    return { total, results };
  }

  private makeESFilters(
    indexId: string,
    indexFilters: GlobalSearchFilter[],
  ): Elastic.QueryDslQueryContainer[] {
    const filters: Elastic.QueryDslQueryContainer[] = [];

    indexFilters?.forEach(filter => {
      if (filter.indexId !== indexId || !filter.value.length) {
        return;
      }
      filters.push({
        terms: {
          [filter.field]: filter.value,
        },
      });
    });

    return filters;
  }

  private makeGCSFilters(
    indexId: string,
    indexFilters: GlobalSearchFilter[],
  ): [GCSFilterOptions[], string | undefined] {
    if (!indexFilters?.length) return [[], undefined];

    const objectTypeFilters: GCSFilterOptions[] = indexFilters
      .filter(f => f.field === 'objectType')
      .map(f => ({ objectType: String(f.value) }));

    /**
     * Filters in Google Cloud Search can only be done through operators/facets
     * @see https://support.google.com/cloudsearch/answer/6172299
     */
    const facetFilters: string | undefined = indexFilters
      .filter(f => f.field !== 'objectType')
      .reduce((acc, f) => {
        if (f.indexId !== indexId || !f.value.length) {
          return acc;
        }
        // `OR` logical operator is used to combine multiple values for the same field (filter)
        return `${acc} ${f.field}:{${f.value.join(' OR ')}}`;
      }, '')
      .trim();

    return [objectTypeFilters, facetFilters];
  }
}
