import { Table, TableColumn } from '@backstage/core-components';
import { LinearProgress } from '@material-ui/core';
import BarChart from '@material-ui/icons/BarChart';
import { Alert } from '@material-ui/lab';
import { TrackedLink } from 'plugin-ui-components';
import React from 'react';
import { Database } from '../../domain/Databases';
import { Status } from '../../hooks/useApiCall';

export type DatabasesListProps = {
  databases: Database[];
  isLoading: boolean;
  application: string;
};

const columns: TableColumn<Database>[] = [
  {
    title: 'Database name',
    field: 'db_name',
    align: 'left',
  },
  {
    title: 'Namespace',
    field: 'namespace',
  },
  {
    title: 'Kubernetes Cluster',
    field: 'kubernetes_cluster',
  },
  {
    title: 'Volume Size',
    field: 'volume_size',
  },
  {
    title: 'CPU Requests',
    field: 'cpu_request',
  },
  {
    title: 'Memory Requests',
    field: 'memory_request',
  },
  {
    title: 'PGView',
    render: db => (
      <TrackedLink
        key="databases-pg-view"
        plugin="databases"
        eventCategory="databases-management"
        eventAction="Redirect to PGView"
        eventLabel="databases-pg-view"
        href={`https://pgview.${db.kubernetes_cluster}.zalan.do/#/clusters/${db.namespace}/${db.db_name}`}
        target="_blank"
      >
        <BarChart style={{ fontSize: '2em' }} />
      </TrackedLink>
    ),
  },
];

export function DatabasesList({
  databases,
  isLoading,
  status,
  application,
}: DatabasesListProps & Status) {
  if (isLoading) {
    return <LinearProgress data-testid="loading_indicator" />;
  }

  if (status.isError) {
    return <Alert severity="error">{status.error}</Alert>;
  }

  if (!databases.length) {
    return (
      <Alert severity="info">
        No databases found for {application}. Is your application already using
        a database that is not listed here, then please make sure that you have
        annotated your Postgresql cluster{' '}
        <TrackedLink
          key="databases-application-annotation-docs"
          plugin="databases"
          eventCategory="databases-management"
          eventAction="Redirect to Dbaas annotating application documentation"
          eventLabel="databases-documentation"
          href="https://database-as-a-service.docs.zalando.net/managed-postgres/first.html#annotating-application-ownership"
          target="_blank"
        >
          <strong>properly</strong>
        </TrackedLink>
        ?!
      </Alert>
    );
  }

  return <Table columns={columns} data={databases} />;
}
