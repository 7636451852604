import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ReviewContext } from '../../context';
import { IStep, steps } from '../../steps';
import { StepButtonStyles } from '../styles';

interface Props {
  toggle: boolean;
  onClose: VoidFunction;
  setActiveStep: (step: number) => void;
}

export function IncompleteFormDialog({
  toggle,
  onClose,
  setActiveStep,
}: Props) {
  const { review } = React.useContext(ReviewContext);
  const uncompleted = steps.filter(s => {
    if (s.validate) return !s.validate(review);
    return !s.optional && !review[s.field];
  });
  const completed = steps.filter(s => {
    if (s.optional) return false;
    return !uncompleted.find(_s => _s.field === s.field);
  });

  const handleStepClick = (step: IStep) => {
    setActiveStep(steps.indexOf(step));
    // Setting active step triggers a layout change, so we need to wait for it
    setTimeout(() => {
      document.getElementById(step.field)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 800);
    onClose();
  };
  return (
    <Dialog open={toggle}>
      <DialogTitle>Cannot submit review</DialogTitle>
      <DialogContent>
        Incomplete review! You <em>MUST</em> complete all the required sections:
        <ul className="checklist error" style={{ marginTop: '1rem' }}>
          {uncompleted.map(s => (
            <li key={s.field}>
              <Button
                onClick={() => handleStepClick(s)}
                style={StepButtonStyles}
              >
                {s.header}
              </Button>
            </li>
          ))}
        </ul>
        <ul className="checklist success">
          {completed.map(s => (
            <li key={s.field}>
              <Button
                onClick={() => handleStepClick(s)}
                style={StepButtonStyles}
              >
                {s.header}
              </Button>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
