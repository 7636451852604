import React from 'react';
import { Input, SectionCaption } from '../common';

export function Comments() {
  return (
    <section>
      <Input
        name="comments"
        placeholder="Your additional comments for this application review"
        multiline
        minRows={4}
      />
      <SectionCaption>
        Feel free to add any notes that you feel would be helpful for the reader
        (usually principal engineers) of this review to provide overarching
        context to your responses.
        <em>
          'e.g. This application has not been deployed for 18 months as it's
          stable and we're working on a migration and deprecation plan for its
          replacement.
        </em>
      </SectionCaption>
    </section>
  );
}
