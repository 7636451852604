import React from 'react';
import { NotFound } from '../../../common';

export function PipelineRunNotFound({ id }: { id: string }) {
  return (
    <NotFound title="Nothing was found">
      <>
        <span>Run with ID</span>
        <strong>{` ${id} `}</strong>
        <span>
          doesn&apos;t exist or has been deleted. Please, note that runs older
          than 30 days can be automatically deleted.
        </span>
      </>
    </NotFound>
  );
}
