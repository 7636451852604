import {
  OAuthApi,
  createApiRef,
  DiscoveryApi,
} from '@backstage/core-plugin-api';
import { IAWSAccount } from './types/responses/teams';

export interface ITeamsApi {
  getUserAWSAccounts: (userID: string) => Promise<Array<string>>;
}

export const teamsApiRef = createApiRef<ITeamsApi>({
  id: 'plugin-application-registry-teams-api',
});

export class TeamsApi implements ITeamsApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getUserAWSAccounts(userID: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const AWSRoles = ['PowerUser', 'Deployer'];
    const requests = AWSRoles.map(role =>
      fetch(
        `${proxyUrl}/teams-api/accounts/aws?member=${userID}&role=${role}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      ).then(response => response.json()),
    );

    return Promise.all(requests)
      .then(responses => {
        let accountsIDs: string[] = [];
        responses.flatMap(accounts => {
          accountsIDs = [
            ...accountsIDs,
            ...accounts.map((account: IAWSAccount) => account.id),
          ];
        });
        // Remove duplicates
        return [...new Set(accountsIDs)];
      })
      .catch(err => err);
  }
}
