/**
 * @param obj - object to get the property from
 * @param chainedProperties - string of properties separated by dots e.g. 'metadata.name'
 * @returns - the property value
 */
export function getPropertyValueFromObject(
  obj: any,
  chainedProperties: string,
) {
  try {
    // Handle both dot notation and bracket notation
    const properties = chainedProperties
      .replace(/\["(.*?)"\]/g, (_, p1) => `.${p1.replace(/\./g, '__DOT__')}`)
      .split('.')
      .map(prop => prop.replace(/__DOT__/g, '.'));
    return properties.reduce((acc, key) => acc && acc[key], obj);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error((e as Error).message);
    return null;
  }
}
