import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { RadioProps } from '@material-ui/core';

export type FormFields = Exclude<
  keyof IAppReview.Review,
  | 'id'
  | 'data_classification'
  | 'dashboard_default_kubernetes-application-dashboard_url'
  | 'dashboard_default_kubernetes-ingress-by-application-dashboard_url'
  | 'dashboard_default_lightstep-deployments-dashboard_url'
>;

export const criticalityColors = new Map<IAppReview.CriticalityStates, string>([
  ['Unknown', 'default'],
  ['Tier 1', 'error'],
  ['Tier 2', 'warning'],
  ['Tier 3', 'success'],
  ['Not Relevant', 'secondary'],
]);
interface InputProps extends RadioProps {
  label?: React.ReactNode;
  category: IAppReview.ReviewCategory;
  placeholder?: string;
}

export type FormConfig = Record<FormFields, InputProps>;

export const config: FormConfig = {
  kio_ok: {
    category: 'Application metadata',
    label: (
      <>
        Yes, this information is correct to the best of my knowledge and&nbsp;
        <b>I confirm the ownership</b>
      </>
    ),
  },
  naming_ok: {
    category: 'Naming and inclusive language',
    label:
      'Yes, I understand the guidelines and answered to the best of my knowledge.',
  },
  criticality_ok: {
    category: 'Criticality assessment',
    label:
      'Yes, the current criticality is correct to the best of my knowledge.',
  },
  data_classification_ok: {
    category: 'Data classification',
    label:
      'Yes, the data classification is correct to the best of my knowledge',
  },
  deployment_ok: {
    category: 'Deployment information',
    label:
      "I acknowledge the deployment information and have tickets in my team's  backlog to remediate outstanding issues",
  },
  deployment_comments: {
    category: 'Deployment information',
  },
  opentracing_ok: {
    category: 'OpenTracing',
    label:
      "I acknowledge the OpenTracing information and have tickets in my team's backlog to remediate outstanding issues.",
  },
  opentracing_comments: {
    category: 'OpenTracing',
  },
  api_ok: {
    category: 'API',
    label:
      "I acknowledge the API information and have tickets in my team's backlog to remediate outstanding issues.",
  },
  api_comments: {
    category: 'API',
  },
  tech_ok: {
    category: 'Tech stack',
    label:
      'I acknowledge this information and confirm that the tech stack is aligned with my department/BU.',
  },
  tech_comments: {
    category: 'Tech stack',
  },
  dashboards_ok: {
    category: 'Dashboards',
    label: 'I confirm that the dashboard information is correct.',
  },
  dashboards_comments: {
    category: 'Dashboards',
  },
  slos_ok: {
    category: 'Service Level Objectives (SLOs)',
    label: 'I confirm that the SLO information is correct.',
  },
  slos_comments: {
    category: 'Service Level Objectives (SLOs)',
  },
  alerts_ok: {
    category: 'Alerts',
    label: 'I confirm that the alert information is correct.',
  },
  alerts_comments: {
    category: 'Alerts',
  },
  playbooks_ok: {
    category: 'Playbooks',
    label: 'I confirm that the playbook information is correct',
  },
  playbooks_comments: {
    category: 'Playbooks',
  },
  load_tests_ok: {
    category: 'Load tests',
    label: 'I confirm that a load test was conducted or is planned.',
  },
  load_tests_comments: {
    category: 'Load tests',
  },
  production_readiness_review_ok: {
    category: 'Production readiness review (PRR)',
    label:
      'I confirm that a Production Readiness Review was conducted or is planned.',
  },
  production_readiness_review_comments: {
    category: 'Production readiness review (PRR)',
  },
  cyberweek_ok: {
    category: 'Cyber Week',
    label: 'I confirm that the Cyber Week scope is correct.',
  },
  name_uses_english: {
    category: 'Naming and inclusive language',
    label:
      "Do the application ID ('%id%') and name ('%name%') use plain English?",
  },
  name_contains_offensive_terms: {
    category: 'Naming and inclusive language',
    label: 'Is the ID/name offensive or does it contain offensive terms?',
  },
  name_contains_organization_identifiers: {
    category: 'Naming and inclusive language',
    label:
      'Does the ID/name contain organization, department, or team identifiers?',
  },
  name_describes_purpose_or_functionality: {
    category: 'Naming and inclusive language',
    label:
      "Does the ID/name describe the application's purpose or functionality?",
  },
  name_uses_acronyms: {
    category: 'Naming and inclusive language',
    label:
      'Does the ID/name use acronyms which are not well-known within Zalando?',
  },
  name_meaningful_outside_department: {
    category: 'Naming and inclusive language',
    label:
      'Is the ID/name meaningful to engineers and product managers outside of the department?',
  },
  name_is_local: {
    category: 'Naming and inclusive language',
    label:
      "Could another department/team register the same application ID/name? (e.g. 'order-service')",
  },
  name_includes_joke_or_culture: {
    category: 'Naming and inclusive language',
    label: 'Does the ID/name include a joke or culture reference?',
  },
  name_refers_temporary_concept_or_colloquialism: {
    category: 'Naming and inclusive language',
    label: 'Does the ID/name refer to temporary concepts or colloquialisms?',
  },
  name_uses_functional_naming_registry: {
    category: 'Naming and inclusive language',
    label: (
      <>
        Was the&nbsp;
        <Link to="https://github.bus.zalan.do/team-architecture/functional-component-registry">
          functional naming registry <OpenInNewIcon />
        </Link>
        &nbsp;consulted to construct the ID/name?
      </>
    ),
  },
  name_includes_brand: {
    category: 'Naming and inclusive language',
    label: 'Does the ID/name include a well-established brand?',
  },
  follows_inclusive_language_guidelines: {
    category: 'Naming and inclusive language',
    label: (
      <>
        Does all application source code and documentation follow the&nbsp;
        <Link to="https://engineering.docs.zalando.net/guidelines/inclusive-language/">
          guidelines for inclusive language <OpenInNewIcon />
        </Link>
        ?
      </>
    ),
  },
  in_production: {
    category: 'Criticality assessment',
    label:
      'Is the application used in production? Does it have access to any other production system(s) or environment and/or stores production data?',
  },
  in_direct_path_of_critical_business_operation: {
    category: 'Criticality assessment',
    label: (
      <>
        Is the application in the direct path of a&nbsp;
        <Link
          target="_blank"
          to="https://sre.docs.zalando.net/products/service-level-objectives/service-level-objectives/"
        >
          critical business operation <OpenInNewIcon />
        </Link>
        ?
      </>
    ),
  },
  application_has_fallback_strategy: {
    category: 'Criticality assessment',
    label:
      'Does the application have a fallback strategy to handle errors in dependencies?',
  },
  application_has_retry_strategy: {
    category: 'Criticality assessment',
    label:
      'Does the application have a retry strategy to handle errors in dependencies?',
  },
  all_clients_have_fallback_strategy: {
    category: 'Criticality assessment',
    label: 'Do all clients of the application have a fallback strategy?',
  },
  all_clients_have_retry_strategy: {
    category: 'Criticality assessment',
    label: 'Do all clients of the application have a retry strategy?',
  },
  can_cause_p1_incident: {
    category: 'Criticality assessment',
    label:
      'Can the application cause a SEV2 or SEV1 incident (by failing/ being unavailable)?',
  },
  can_cause_order_drop: {
    category: 'Criticality assessment',
    label: (
      <>
        Will a failure of the application cause a drop in the&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/digital-experience/domain/sales-orders.html"
        >
          sales orders <OpenInNewIcon />
        </Link>
        &nbsp;curve?'
      </>
    ),
  },
  can_lead_to_gmv_losss: {
    category: 'Criticality assessment',
    label:
      'Will a failure of the application lead to GMV/revenue loss (in the same hour, or over days)?',
  },
  can_prevent_employees_from_work: {
    category: 'Criticality assessment',
    label:
      'Will a failure of the application prevent employees from doing their work?',
  },
  can_lead_to_contractual_penalties: {
    category: 'Criticality assessment',
    label:
      'Can a failure of the application lead to contractual penalties/fees?',
  },
  can_affect_customer_experience: {
    category: 'Criticality assessment',
    label:
      'Will a failure of the application directly affect the customer experience?',
  },
  can_be_down_for_30_minutes_without_informing: {
    category: 'Criticality assessment',
    label:
      'Can the application be down for 30 minutes without informing customers/stakeholders?',
  },
  can_reduce_overall_redundancy_observabilitiy: {
    category: 'Criticality assessment',
    label:
      'Is a failure of the application reducing overall redundancy or observability?',
  },
  can_affect_incident_response: {
    category: 'Criticality assessment',
    label:
      'Can a failure affect the ability to solve incidents or prolong time-to-repair?',
  },
  data_classification: {
    category: 'Data classification',
    label: 'Data Classification',
  },
  'dashboard_default_kubernetes-application-dashboard': {
    category: 'Dashboards',
    label: 'Kubernetes Application Dashboard',
  },
  'dashboard_default_kubernetes-application-dashboard_url': {
    category: 'Dashboards',
    label: 'Kubernetes Application Dashboard URL',
  },
  'dashboard_default_kubernetes-ingress-by-application-dashboard': {
    category: 'Dashboards',
    label: 'Kubernetes Ingress by Application (Skipper) Dashboard',
  },
  'dashboard_default_kubernetes-ingress-by-application-dashboard_url': {
    category: 'Dashboards',
    label: 'Kubernetes Ingress by Application (Skipper) Dashboard URL',
  },
  'dashboard_default_lightstep-deployments-dashboard': {
    category: 'Dashboards',
    label: 'Lightstep Service Discovery Deployments Dashboard',
  },
  'dashboard_default_lightstep-deployments-dashboard_url': {
    category: 'Dashboards',
    label: 'Lightstep Service Discovery Deployments Dashboard URL',
  },
  slos_critical_business_operation: {
    category: 'Service Level Objectives (SLOs)',
    label: (
      <>
        SLOs are documented and tracked as part of&nbsp;
        <Link
          target="_blank"
          to="https://sre.docs.zalando.net/products/service-level-objectives/service-level-objectives/"
        >
          Critical Business Operations <OpenInNewIcon />
        </Link>
      </>
    ),
  },
  slos_nobl9: {
    category: 'Service Level Objectives (SLOs)',
    label: (
      <>
        Application is covered by&nbsp;
        <Link to="https://sre.docs.zalando.net/products/service-level-objectives/application-based-slos/application-based-slos/">
          Application Based SLOs <OpenInNewIcon />
        </Link>
      </>
    ),
  },
  slos_documented: {
    category: 'Service Level Objectives (SLOs)',
    label: (
      <>
        SLOs are documented as part of the&nbsp;
        <Link target="_blank" to="/docs/default/Documentation/cloud/">
          application's documentation <OpenInNewIcon />
        </Link>
      </>
    ),
  },
  slos_tracked_dashboards: {
    category: 'Service Level Objectives (SLOs)',
    label:
      'SLOs are tracked via a (custom) dashboard (see "Dashboards" section)',
  },
  alert_adaptive_paging: {
    category: 'Alerts',
    label: (
      <>
        Is the application covered by an&nbsp;
        <Link
          target="_blank"
          to="https://sre.docs.zalando.net/products/adaptive-paging/concepts/about/"
        >
          Adaptive Paging <OpenInNewIcon />
        </Link>
        &nbsp;alert?
      </>
    ),
  },
  alert_lightstep_custom: {
    category: 'Alerts',
    label: (
      <>
        Does the application use&nbsp;
        <Link to="https://docs.lightstep.com/docs/create-alert-conditions-and-rules">
          custom Lightstep alerts <OpenInNewIcon />
        </Link>
        &nbsp;with paging (OpsGenie integration)?
      </>
    ),
  },
  production_readiness_review_url: {
    category: 'Production readiness review (PRR)',
    label: 'Link to the latest PRR document',
  },
  production_readiness_review_date: {
    category: 'Production readiness review (PRR)',
    label: 'Date of last PRR or estimated date of future review',
    placeholder: 'Date of last PRR',
  },
  cyberweek_higher_traffic: {
    category: 'Cyber Week',
    label: (
      <>
        Does the application handle a higher volume of traffic/data during (or
        before/after) Cyber Week? The traffic increase can be attributed to
        factors such as customer traffic, sales campaigns, shipments and
        customer sales.
      </>
    ),
  },
  cyberweek_application_change: {
    category: 'Cyber Week',
    label: (
      <>
        Can a (intended or unintended) change on the application or its
        configuration impact the infrastructure or any other application which
        is relevant for the success of Cyber Week? Check if your service's
        dependencies are{' '}
        <Link to="/applications?cyberweek=yes&group=all&status=yes">
          Cyber Week relevant
        </Link>
        . For applications which depend on your application, check your HTTP
        API's{' '}
        <Link to="https://grafana.zalando.net/d/54nnMJIZz/kubernetes-api-monitoring-clients?orgId=1">
          clients
        </Link>{' '}
        or your{' '}
        <Link to="https://nakadi.zalando.net/#types">
          Nakadi EventType's consumers
        </Link>{' '}
        for any Cyber Week relevant applications.
      </>
    ),
  },
  cyberweek_outage: {
    category: 'Cyber Week',
    label: (
      <>
        Will an extended, multi-hour outage impact directly/indirectly any of
        the Cyber Week pre-event/event/post-event related activities?
      </>
    ),
  },
  cyberweek_failure: {
    category: 'Cyber Week',
    label: (
      <>
        Will a failure of the application impact the ability to steer and react
        to incidents during Cyber Week? Consider factors such as deployment
        infrastructure, configuration UIs and components that provide
        observability.
      </>
    ),
  },
  cyberweek_modify_data: {
    category: 'Cyber Week',
    label: (
      <>
        Does the application modify or delete any critical data which might
        affect Cyber Week, such as product data, price, offers?
      </>
    ),
  },
  cyberweek_pre_event_system: {
    category: 'Cyber Week',
    label: (
      <>
        <b>Pre-Cyber Week:</b> Is the application supporting the setup
        activities of Cyber Week? These activities may include the onboarding of
        Partners or the preprocessing of product information or the set up
        campaigns.
      </>
    ),
  },
  cyberweek_event_system: {
    category: 'Cyber Week',
    label: (
      <>
        <b>Cyber Week:</b> Is the application supporting the selling of products
        to customers, the sending of campaigns, the updating of pricing, or the
        processing of orders during Cyber Week?
      </>
    ),
  },
  cyberweek_post_event_system: {
    category: 'Cyber Week',
    label: (
      <>
        <b>Post-Cyber Week:</b> Is the application supporting the delivery of
        orders to customers after Cyber Week? This covers the systems that are
        involved in the processing orders or delivery (logistics).
      </>
    ),
  },
  comments: {
    category: 'Additional Comments',
  },
};

export const criticalityScores: Record<
  keyof IAppReview.CriticalityScoreFields,
  Record<IAppReview.YesNoNotSure, number>
> = {
  in_production: {
    yes: 0,
    not_sure: 0,
    no: -1000,
  },
  in_direct_path_of_critical_business_operation: {
    yes: 200,
    not_sure: 80,
    no: 10,
  },
  application_has_fallback_strategy: {
    yes: 10,
    not_sure: 40,
    no: 80,
  },
  application_has_retry_strategy: {
    yes: 10,
    not_sure: 40,
    no: 80,
  },
  all_clients_have_fallback_strategy: {
    yes: 5,
    not_sure: 40,
    no: 70,
  },
  all_clients_have_retry_strategy: {
    yes: 5,
    not_sure: 40,
    no: 70,
  },
  can_cause_p1_incident: {
    yes: 150,
    not_sure: 80,
    no: 10,
  },
  can_cause_order_drop: {
    yes: 120,
    not_sure: 50,
    no: 5,
  },
  can_lead_to_gmv_losss: {
    yes: 80,
    not_sure: 40,
    no: 15,
  },
  can_prevent_employees_from_work: {
    yes: 60,
    not_sure: 20,
    no: 5,
  },
  can_lead_to_contractual_penalties: {
    yes: 65,
    not_sure: 20,
    no: 5,
  },
  can_affect_customer_experience: {
    yes: 150,
    not_sure: 60,
    no: 20,
  },
  can_be_down_for_30_minutes_without_informing: {
    yes: 5,
    not_sure: 30,
    no: 10,
  },
  can_reduce_overall_redundancy_observabilitiy: {
    yes: 100,
    not_sure: 30,
    no: 5,
  },
  can_affect_incident_response: {
    yes: 80,
    not_sure: 30,
    no: 10,
  },
};

export const criticalityTierScores = {
  'Tier 1': 970,
  'Tier 2': 545,
  'Tier 3': 120,
};

interface IGrafanaDefaultDashboard {
  id: string;
  url: string;
}

export const GrafanaDefaultDashboards: IGrafanaDefaultDashboard[] = [
  {
    id: 'kubernetes-application-dashboard',
    url: 'https://grafana.zalando.net/d/EZfmMJIZz/kubernetes-application-dashboard?var-application={{id}}&var-cluster=*',
  },
  {
    id: 'kubernetes-ingress-by-application-dashboard',
    url: 'https://grafana.zalando.net/d/RpgvG1SZk/kubernetes-ingress-by-application-skipper?var-application={{id}}',
  },
  {
    id: 'lightstep-deployments-dashboard',
    url: 'https://app.lightstep.com/Production/service-directory/{{id}}/deployments',
  },
];
