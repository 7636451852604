export const sortSelectedFirst = (
  options: Array<any>,
  value: Array<any>,
  compareFn: (option: any, value: any) => boolean,
) => {
  return [...options].sort((a, b) => {
    let ai = value.findIndex(item => compareFn(item, a));
    ai = ai === -1 ? value.length + options.indexOf(a) : ai;
    let bi = value.findIndex(item => compareFn(item, b));
    bi = bi === -1 ? value.length + options.indexOf(b) : bi;
    return ai - bi;
  });
};

export const sortByGroup = (options: Array<any>, canGroup: boolean) => {
  if (!canGroup) return options;

  return [...options].sort((a, b) => {
    return a.group.localeCompare(b.group);
  });
};
