import React from 'react';
import { InfoCard, List, TagSmall } from '../../../../../common';
import { Element } from './Element';

import * as S from './styles';

type PythonArtifact = {
  name: string;
  type: string;
  summary: string;
  version: string;
  repository: string;
};

export function PythonArtifacts({ artifacts }: { artifacts: string[] }) {
  return (
    <InfoCard title="🐍 Python">
      <List>
        {artifacts.map((record, i) => {
          const { name, type, summary, version, repository } = JSON.parse(
            record,
          ) as PythonArtifact;

          return (
            <S.Artifact key={i}>
              <Element text={repository} label="Repository" />
              <Element text={name} label="Name" />
              <Element text={<TagSmall>{version}</TagSmall>} label="Version" />
              <Element text={type} label="Type" />
              <Element text={summary} label="Summary" />
            </S.Artifact>
          );
        })}
      </List>
    </InfoCard>
  );
}
