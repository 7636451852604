import { OAuthApi, DiscoveryApi } from '@backstage/core-plugin-api';
import { http } from '../http-client';
import { EventTypePublishingStats } from './types';

export interface NakadiReporterGateway {
  getEventTypePublishingStats(
    eventType: string,
    startTime: Date,
  ): Promise<EventTypePublishingStats>;
}

export class NakadiReporterGatewayImpl implements NakadiReporterGateway {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getEventTypePublishingStats(
    eventType: string,
    startTime: Date,
  ): Promise<EventTypePublishingStats> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<EventTypePublishingStats>(
      `${proxyUrl}/nakadi/reporter/event-types/${eventType}/publishing-stats?${startTime.toISOString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }
}
