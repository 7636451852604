import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../../api';
import { NotFoundException } from '../../../../../api/exceptions';
import { sortTestResults } from './utils';

export function useJunitTestUpload(
  runId: string,
  stepId: string,
  stepRunId: string,
  index: string,
  status: string,
) {
  const api = useApi(cdpApiRef);

  const getTestUpload = useCallback(async () => {
    if (!stepRunId) return Promise.resolve();

    try {
      const testResults = await api.getTestUpload(
        runId,
        stepId,
        stepRunId,
        index,
      );

      const sortedResults = sortTestResults(testResults);
      return Promise.resolve(sortedResults);
    } catch (error) {
      const e = error as Error;
      if (!(e instanceof NotFoundException)) {
        return Promise.reject(e);
      }

      return Promise.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, error, retry, value } = useAsyncRetry(
    () => getTestUpload(),
    [getTestUpload, status],
  );

  return [
    { loading, error, value },
    { getTestUpload, retry },
  ] as const;
}
