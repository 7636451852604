import React from 'react';
import { TrackedInteraction } from 'plugin-ui-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { softwareCatalogApiRef } from 'plugin-catalog';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useApi } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { CircularProgress, Typography } from '@material-ui/core';
import { useAsync } from 'react-use';
import { plural } from '../../utils/language';
import { WithIssue } from '../common';
import { Tracking } from './tracking';

// TODO: Find a way to display open tracing value
export function LightstepStats() {
  const { entity } = useEntity();
  const api = useApi(softwareCatalogApiRef);

  const { loading, value } = useAsync(
    () => api.getLightStepServices(entity?.metadata?.name),
    [entity?.metadata?.name],
  );

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  if (!value?.length) {
    return (
      <WithIssue field="lightstep_info">
        <Typography>
          This application does not seem to be instrumented or does not report
          to LightStep (production). See{' '}
          <Link
            target="_blank"
            to="/docs/default/Documentation/visibility/opentracing/"
          >
            Zalando's OpenTracing documentation <OpenInNewIcon />
          </Link>{' '}
          on how to instrument your application.
        </Typography>
      </WithIssue>
    );
  }

  return (
    <WithIssue field="lightstep_info">
      <Typography>
        Found <b>{value.length}</b> service{plural(value.length)}:
      </Typography>
      <ul>
        {value.map((service, i) => {
          const lightStepUrl =
            'https://app.lightstep.com/Production/service-directory';
          const link = `${lightStepUrl}/${encodeURIComponent(
            service.attributes.name,
          )}`;

          return (
            <li key={i}>
              <TrackedInteraction {...Tracking.viewLink(link)}>
                <Link to={link}>
                  {service.attributes.name} <OpenInNewIcon />
                </Link>
              </TrackedInteraction>{' '}
              (Last seen:{' '}
              <time dateTime={service.attributes.last_seen}>
                {new Date(service.attributes.last_seen).toLocaleDateString(
                  'de',
                )}
              </time>
              )
            </li>
          );
        })}
      </ul>
    </WithIssue>
  );
}
