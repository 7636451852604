import { Theme, styled } from '@material-ui/core';
import { fontSize } from '../../../common';

const Wrapper = styled('div')((props: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: props.theme.spacing(4),
  height: '100%',
  flexDirection: 'column',
  fontSize: fontSize.medium,
  '& svg': {
    width: '45px',
    height: '45px',
  },
}));

export const ErrorWrapper = styled(Wrapper)((props: { theme: Theme }) => ({
  color: props.theme.palette.error.main,
}));

export const InfoWrapper = styled(Wrapper)((props: { theme: Theme }) => ({
  color: props.theme.palette.info.main,
}));
