import React, { useContext } from 'react';
import { useAsync } from 'react-use';
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import {
  isCommentsField,
  isConfirmationField,
  isURLField,
} from '../../utils/fields';
import { applicationReviewApiRef } from '../../api';
import { config } from '../config';
import { ReviewSummaryContext } from '../context';
import { SummaryCard } from '../common';
import * as S from '../styles';

export const AlertsSummary = () => {
  const category: IAppReview.ReviewCategory = 'Alerts';
  const { entity } = useEntity();
  const { review } = useContext(ReviewSummaryContext);
  const api = useApi(applicationReviewApiRef);

  const categoryItems = Object.keys(review).filter(
    item =>
      config[item]?.category === category &&
      !isURLField(item) &&
      !isConfirmationField(item) &&
      !isCommentsField(item),
  );

  const { value, loading } = useAsync(() =>
    api.getAlerts(entity.metadata.name),
  );

  return (
    <SummaryCard category={category}>
      <CardContent>
        <Typography>
          <b>ZMON Alerts with Email, Google Chat or OpsGenie Notification</b>
        </Typography>
        {loading && <CircularProgress size={20} />}
        {!loading && !value?.length && (
          <Typography>
            No ZMON alerts linked to application "{entity?.metadata?.name}"
            found.
          </Typography>
        )}
        {value?.map(item => (
          <Box mb={2}>
            <Typography>
              <Link to={item.url}>{item.name}</Link>
            </Typography>
            <Typography variant="body2">
              (priority {item.priority}, team {item.team})
            </Typography>
          </Box>
        ))}
        <Typography>
          <b>Other Alerts</b>
        </Typography>
        {categoryItems.map(item => (
          <Grid container>
            <Grid item xs={10}>
              <S.Label>{config[item]?.label}</S.Label>
            </Grid>
            <Grid item xs={2}>
              <S.Label align="right" style={{ fontWeight: 'bold' }}>
                {review[item] === 'no' ? (
                  'No'
                ) : (
                  <Link to={review[`${item}_url`]}>
                    View <OpenInNewIcon />
                  </Link>
                )}
              </S.Label>
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </SummaryCard>
  );
};
