export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
};

export const createScope = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Creating new scope in access conrol',
  eventAction: `Create new scope button is clicked`,
});

export const editResourceType = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Editing new resource type in access conrol',
  eventAction: `Edit resource type button is clicked`,
});

export const deleteResourceType = (interaction = 'click'): ITrackingEvent => ({
  ...common,
  eventLabel: 'Deleting new resource type in access conrol',
  eventAction: `Delete resource type button is ${
    interaction === 'click' ? 'clicked' : 'hovered'
  }`,
});

export const deleteScope = (scopeId: string | undefined): ITrackingEvent => ({
  ...common,
  eventLabel: 'Deleting scope in access conrol',
  eventAction: `Delete scope button is clicked ${
    scopeId ? `for scope ${scopeId}` : ''
  }`,
});

export const openResourceTypeMenu = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Resource Type menu opened',
  eventAction: `User opened resource type menu (add/edit)`,
});

export const editScope = (scopeId: string | undefined): ITrackingEvent => ({
  ...common,
  eventLabel: 'Editing scope from resource type menu',
  eventAction: `Edit '${scopeId}' scope menu item is clicked`,
});
