import { IActivityContainer } from '../../api/types/payload';

export class PodContainerModel {
  exit_code: number | null;
  finished_at: string;
  json_output: string;
  output: string;
  message: string;
  name: string;
  started_at: string;
  status: string;
  status_reason: string;

  constructor(json: IActivityContainer = {} as IActivityContainer) {
    this.exit_code = json.exit_code ?? null;
    this.finished_at = json.finished_at ?? '';
    this.json_output = json.json_output ?? '';
    this.message = json.message ?? '';
    this.name = json.name ?? '';
    this.started_at = json.started_at ?? '';
    this.status = json.status ?? '';
    this.status_reason = json.status_reason ?? '';
    this.output = json.output ?? '';
  }
}
