import React, {
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type {
  GlobalSearchFilter,
  GlobalSearchQuery,
  SearchContextProps,
} from './types';
import { useDebounce } from 'react-use';
import { searchConfig } from './config';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import {
  oauth2ApiRef,
  SEARCH_DEBOUNCE_TIME,
  SEARCH_TERM_MIN_LENGTH,
} from 'plugin-core';
import { SunriseSearchService } from './SunriseSearchService';
import { getDefaultFilters } from './utils';
import {
  FILTERS_KEY,
  OFFSET_KEY,
  QUERY_KEY,
  rejectPromise,
  resolvePromise,
  TAB_KEY,
} from './constants';
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { useMediaMobile } from 'plugin-ui-components';
import { ConfigIndex, SearchConfigForGCS } from './models';
import cloneDeep from 'lodash/cloneDeep';
import { useMatch } from 'react-router';
import * as Tracking from './common/tracking';
import { useSearchLocalStorage } from './common';

const firstTabLabelAsQuery = searchConfig.indexes[0]?.id;

export const SearchContext = React.createContext<SearchContextProps>({
  query: '',
  setQuery: () => {},
  tab: firstTabLabelAsQuery,
  setTab: () => {},
  offset: 0,
  setOffset: () => {},
  limit: 10,
  results: [],
  setLimit: () => {},
  filters: [],
  onFilterChange: () => {},
  onFilterRemove: () => {},
  filterOrientation: 'vertical',
  retrySearch: () => {},
  filterOptionsInitialized: false,
  setFilterOptionsInitialized: () => {},
});

export const useSearchContext = () => React.useContext(SearchContext);

interface SearchParams {
  [QUERY_KEY]?: string | null;
  [TAB_KEY]?: string | null;
  [FILTERS_KEY]?: GlobalSearchFilter[] | null;
  [OFFSET_KEY]?: number | null;
}

export function SearchContextProvider({ children }: PropsWithChildren<any>) {
  const searchPageMatch = useMatch('/search');
  const oauth2Api = useApi(oauth2ApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const isMobile = useMediaMobile();
  const [limit, setLimit] = useState(10);
  const [queryParams, setQueryParams] = useQueryParams({
    [QUERY_KEY]: StringParam,
    [TAB_KEY]: StringParam,
    [FILTERS_KEY]: JsonParam,
    [OFFSET_KEY]: NumberParam,
  });
  const [localParams, setLocalParams] = useState<SearchParams>({});
  const [results, setResults] = useState<Promise<any>[]>([]);
  const searchService = useMemo(
    () => new SunriseSearchService(oauth2Api, discoveryApi),
    [oauth2Api, discoveryApi],
  );
  const [filterOptionsInitialized, setFilterOptionsInitialized] =
    useState(false);
  const { setTermLocalStorage } = useSearchLocalStorage();

  const params = searchPageMatch ? queryParams : localParams;
  function setLocalParamsHandler(newParams: SearchParams) {
    setLocalParams(oldParams => ({ ...oldParams, ...newParams }));
  }
  const setParams = searchPageMatch ? setQueryParams : setLocalParamsHandler;

  const query = params[QUERY_KEY];
  const filters = params[FILTERS_KEY];
  const tab = params[TAB_KEY];
  const offset = params[OFFSET_KEY] ?? 0;

  // Immediate triggers
  useEffect(() => {
    if (query?.trim()) {
      if (!filters && !offset) {
        searchAllTabs();
      } else {
        searchCurrentTab();
      }
      const searchInput = document.getElementById('search-input');
      searchInput?.scrollIntoView({ block: 'end' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, offset, limit]);

  // Delayed triggers
  useDebounce(
    () => {
      if (query?.trim()) {
        if (query?.trim().length >= SEARCH_TERM_MIN_LENGTH) {
          searchAllTabs();
          setTermLocalStorage(query);
          Tracking.searchQuery(query, tab ?? firstTabLabelAsQuery);
        }
      } else {
        setResults([]);
        setParams({
          [QUERY_KEY]: undefined,
          [FILTERS_KEY]: undefined,
          [OFFSET_KEY]: undefined,
        });
      }
    },
    SEARCH_DEBOUNCE_TIME,
    [query],
  );

  function searchAllTabs(): void {
    const promises: Promise<any>[] = [];
    searchConfig.indexes.forEach(index => {
      promises.push(buildEnginePromises(index));
    });
    setResults(promises);
  }

  function searchCurrentTab(): void {
    let promises: Promise<any>[] = [];
    const currentTabIndex = searchConfig.indexes.findIndex(i =>
      i.isMatchingQuery(tab ?? firstTabLabelAsQuery),
    );
    promises = cloneDeep(results);
    promises[currentTabIndex] = buildEnginePromises(
      searchConfig.indexes[currentTabIndex],
    );
    setResults(promises);
  }

  function buildEnginePromises(index: ConfigIndex) {
    const searchQuery: GlobalSearchQuery = {
      query: query?.trim() ?? '',
      limit,
      offset,
      indexes: [index],
      filters: cloneDeep(filters || []),
    };
    const limitToObjectTypes = (index as SearchConfigForGCS).objectTypes;
    if (limitToObjectTypes?.length) {
      searchQuery.filters.push({
        indexId: index.id,
        field: 'objectType',
        value: limitToObjectTypes,
      });
    }
    if (!filters) {
      const defaultFilters = getDefaultFilters(index.id);
      if (defaultFilters?.length) {
        searchQuery.filters.push(...defaultFilters);
        if (tab === index.id) {
          setParams({ [FILTERS_KEY]: defaultFilters });
        }
      }
    }
    switch (index.engine) {
      case 'gcs':
        return searchService
          .searchInGCS(searchQuery)
          .then(resolvePromise)
          .catch(rejectPromise);
      case 'elastic':
        return searchService
          .searchInES(searchQuery)
          .then(resolvePromise)
          .catch(rejectPromise);
      default:
        return Promise.resolve();
    }
  }

  function onFilterChange(filter: GlobalSearchFilter) {
    const newFilters = filters ? [...filters] : [];
    const index = newFilters.findIndex(
      f => f.field === filter.field && f.indexId === filter.indexId,
    );

    if (index === -1) {
      newFilters.push(filter);
    } else {
      newFilters[index] = filter;
    }

    setParams({ [FILTERS_KEY]: newFilters });
    Tracking.filterChange(filter);
  }

  function onFilterRemove(filter: GlobalSearchFilter) {
    const newFilters = [...filters];
    const index = newFilters.findIndex(
      f => f.field === filter.field && f.indexId === filter.indexId,
    );

    if (index !== -1) {
      newFilters.splice(index, 1);
    }

    setParams({ [FILTERS_KEY]: newFilters });
  }

  function getFilterOrientation(): 'horizontal' | 'vertical' {
    if (isMobile) return 'horizontal';
    if (searchConfig.layout?.filterOrientation === 'horizontal')
      return 'horizontal';
    return 'vertical';
  }

  function handleTabChange(val: string) {
    setParams({ [TAB_KEY]: val });
    if (offset !== undefined || filters !== undefined) {
      setParams({
        [OFFSET_KEY]: undefined,
        [FILTERS_KEY]: undefined,
      });
    }

    const defaultFilters = getDefaultFilters(val);
    if (!filters && defaultFilters?.length) {
      setParams({ [FILTERS_KEY]: defaultFilters });
    }
    Tracking.tabChange(val);
  }

  return (
    <SearchContext.Provider
      value={{
        query: query ?? '',
        setQuery: val => setParams({ [QUERY_KEY]: val }),
        tab: tab ?? firstTabLabelAsQuery,
        setTab: handleTabChange,
        offset,
        setOffset: val => {
          setParams({ [OFFSET_KEY]: val });
          Tracking.pageChange(val);
        },
        limit,
        setLimit,
        results,
        filters: filters ?? [],
        onFilterChange,
        onFilterRemove,
        filterOrientation: getFilterOrientation(),
        retrySearch: searchAllTabs,
        filterOptionsInitialized,
        setFilterOptionsInitialized,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
