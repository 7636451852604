export const PAGE_SIZE: number = 20;
export const SMALL_PAGE_SIZE: number = 10;
export const MEDIUM_PAGE_SIZE: number = 50;
export const MAX_PAGE_SIZE: number = 100;
export const PAGE_SIZE_OPTIONS = [
  SMALL_PAGE_SIZE,
  MEDIUM_PAGE_SIZE,
  MAX_PAGE_SIZE,
];
export const FIXED_PAGE_SIZE_OPTIONS = [SMALL_PAGE_SIZE];
