import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterButton } from './styles';

export const FilterCollapseButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler;
}) => (
  <FilterButton onClick={onClick} aria-label="Collapse Filter">
    <FilterListIcon />
  </FilterButton>
);
