import { DiscoveryApi } from '@backstage/core-plugin-api';
import { setNewSessionIdExpirationTime } from './setNewSessionIdExpirationTime';

const ONE_MINUTE_MS = 60000;

function isTokenStillValid() {
  // Read SessionId Expiration Time and return if token is going to expire within 5 minutes
  const sessionIdExpirationTimeName = 'session-token-expire';
  const sessionIdExpirationTime = window.sessionStorage.getItem(
    sessionIdExpirationTimeName,
  );
  if (sessionIdExpirationTime) {
    try {
      const expirationTime = new Date(sessionIdExpirationTime);
      if (new Date(Date.now() + ONE_MINUTE_MS * 5) > expirationTime) {
        return false;
      }
    } catch {
      // expiration Time set in sessionIdExpirationTime is not correct so refresh will not be applied

      // This catch block silence all errors, including dev time errors.
      // Since there is no visual feedback when refresh request fails on frontend side,
      // bugs can easily sneak by and be hard to locate.
      // For that reason added a console.error.
      // eslint-disable-next-line no-console
      console.error(
        `Error refreshing Token in CDP: sessionId ExpirationTime  "${sessionIdExpirationTime}" set in Session Storage is not a valid Date`,
      );
    }
  }
  return true;
}

export async function refreshAccessTokenIfExpiring(discoveryApi: DiscoveryApi) {
  if (!isTokenStillValid()) {
    // Refresh the access token
    try {
      const oauth2Url = await discoveryApi.getBaseUrl('auth');

      // Authentication is made via SessionId Cookie (credentials: 'include' required)
      // in request so Authentication header with token is not needed
      const response = await fetch(`${oauth2Url}/oauth2/refresh`, {
        headers: new Headers({
          'X-Requested-With': 'XMLHttpRequest',
        }),
        credentials: 'include',
      });
      if (!response.ok) throw new Error(response.statusText);
      // Refresh succeeded so set the new refresh time
      setNewSessionIdExpirationTime();
    } catch (err) {
      const error = err as Error;
      // This catch block silence all errors, including dev time errors.
      // Since there is no visual feedback when refresh request fails on frontend side,
      // bugs can easily sneak by and be hard to locate.
      // For that reason added a console.error.
      // eslint-disable-next-line no-console
      console.error(
        `Error refreshing Token in CDP: ${error.name} - ${error.message}`,
      );
    }
  }
}
