import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  scopesWrapper: {
    marginLeft: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(),
  },
  resourceType: {
    padding: 0,
    marginBottom: '0.3rem',
    textTransform: 'none',
    fontSize: '1.2rem',
  },
  scopeLoader: {
    marginTop: '0.3rem',
    marginLeft: theme.spacing(),
  },
  mediumBold: {
    fontWeight: 500,
  },
  description: {
    marginBottom: theme.spacing(),
  },
  switch: {
    marginRight: 0,
  },
  checkbox: {
    padding: 6,
  },
}));
