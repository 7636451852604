import { Entity, DEFAULT_NAMESPACE } from '@backstage/catalog-model';

export const headerProps = (
  kind: string,
  namespace: string | undefined,
  name: string,
  entity: Entity | undefined,
): { headerTitle: string; headerType: string } => {
  let headerTitle = `${name}${
    namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''
  }`;
  if (kind.toLowerCase() === 'domain') {
    headerTitle = entity?.metadata.title || name;
  }
  return {
    headerTitle,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec as { type: string }).type.toLocaleLowerCase('en-US');
      }
      return t;
    })(),
  };
};

export const getLifecycleLabelByKind = (kind: IEntityKind) =>
  kind === 'API' ? 'Lifecycle' : 'Criticality';
