import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Link,
  Table,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import { useAsync } from 'react-use';

type TableAction = TableProps<IEntityGroup>['actions'];

export function ContributingTeamsTable() {
  const catalogApi = useApi(catalogApiRef);

  const columns: TableColumn<IEntityGroup>[] = [
    {
      title: 'Team name',
      render: ({ metadata: { name }, spec: { fullName } }) => (
        <Link to="#">{fullName || name}</Link>
      ),
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Role of team</span>,
      render: () => <div>Owning</div>,
      width: 'fit-content',
    },
  ];

  const actions: TableAction = [
    {
      position: 'toolbar',
      icon: () => <EditIcon color="primary" />,
      tooltip: 'Edit contributing teams',
      disabled: true,
      onClick: () => {},
    },
  ];

  // TODO: This is just a mock, fetch applications relevant to domain
  const { value = [], loading } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: [
            { kind: 'Group', 'metadata.name': 'pitchfork' },
            { kind: 'Group', 'metadata.name': 'automata' },
          ],
        })
        .then(res => res.items),
    [catalogApi],
  );

  return (
    <Table
      title={`Contributing teams (${value.length})`}
      columns={columns}
      data={value}
      actions={actions}
      options={{
        padding: 'dense',
      }}
      isLoading={loading}
    />
  );
}
