import React from 'react';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import awsCronParser from 'aws-cron-parser';
import { MAX_NUMBER_OF_NEXT_RUNS } from './consants';

dayjs.extend(UTC);

export const getDateTimes = (cronExp: string, date: Date) => {
  const expression = awsCronParser.parse(cronExp);
  let occurrence = awsCronParser.next(expression, date);
  const items = [];
  while (occurrence && items.length < MAX_NUMBER_OF_NEXT_RUNS) {
    const formattedDate = dayjs.utc(occurrence).format('YYYY-MM-DD HH:mm ddd');
    occurrence = awsCronParser.next(expression, occurrence);
    const item = occurrence
      ? formattedDate
      : `Last scheduled run: ${formattedDate}`;
    items.push(<div key={formattedDate}>{item}</div>);
  }
  return items;
};
