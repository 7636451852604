import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    '& img': {
      alignSelf: 'center',
    },
    '& pre': {
      margin: 0,
      padding: '0.5rem 1rem',
      background: '#fde5e2',
      border: '1px solid #d7d7d7',
      borderRadius: 4,
      overflow: 'scroll',
    },
    '& .message-wrapper': {
      width: 'calc(100% - 100px)',
    },
  },
  message: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between',
    gap: '1rem',
  },
}));
