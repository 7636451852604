import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { codacyApiRef } from '../api';

export const useAnalysisReport = ({ entity }: { entity: IEntityApp }) => {
  const {
    metadata: { name },
    spec: { spec },
  } = entity;
  const org = spec?.scm_url?.split('/')[3] || spec.team_id;
  const codacy = useApi(codacyApiRef);
  const { value: report, loading } = useAsync(async () => {
    const res = await codacy.getRepostoryAnalysis({
      org,
      repo: name,
    });
    return res.data;
  }, [entity]);

  return {
    report,
    loading,
  };
};
