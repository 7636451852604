import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { FormControl, TextField } from '@material-ui/core';

type VersionsFilterProps = {
  handleVersionFilter: (value: any) => void;
  filteredVersions: string[];
  inputRoot: string;
};

const MockedVersions = ['1.0.0', '0.2', '3.2.1', '0.0.1'];

export const VersionsFilter: React.FunctionComponent<VersionsFilterProps> = ({
  handleVersionFilter,
  filteredVersions,
  inputRoot,
}) => {
  const uniqueValues = new Set<string>();

  MockedVersions.forEach(version => {
    uniqueValues.add(version);
  });

  const versions = Array.from(uniqueValues);

  return (
    <FormControl style={{ minWidth: '100%' }}>
      <Autocomplete
        id="versions-filter"
        multiple
        classes={{ inputRoot }}
        options={versions}
        value={filteredVersions}
        onChange={(_: any, value) => handleVersionFilter(value)}
        renderInput={params => {
          return (
            <TextField
              label="Version"
              {...params}
              variant="outlined"
              margin="dense"
            />
          );
        }}
      />
    </FormControl>
  );
};
