import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Progress } from '@backstage/core-components';
import { withStyles } from '@material-ui/core';
import { CyberWeekBanner } from 'plugin-ui-components';
import { useServices } from '../../services';
import { Layout, NotFound } from '../../common';
import { Pagination, GridLayout, NothingFound, ErrorOccurred } from '../common';
import { ActionPanel } from './components/ActionPanel';
import { RunList } from './components/RunList';
import { validateEventOrDefaultPushAndSchedule } from '../../validations/params';
import { getDomainFromAlias } from '../../utils/domains';
import { runsCssVariables } from '../../styles/css-variables';
import * as S from './styles';

// @TODO
// - add better 'not found' message

const RepositoryPageComponent = observer(() => {
  const { domain_alias = '', org_name = '', repo_name = '' } = useParams();
  const domain = getDomainFromAlias(domain_alias);

  const repositoryURL = `${domain}/${org_name}/${repo_name}`;
  const searchParams = new URLSearchParams(useLocation().search);
  const events = validateEventOrDefaultPushAndSchedule(
    searchParams.getAll('event'),
  );
  const { runsService, secretsService, metaService } = useServices();

  useEffect(() => {
    metaService.setTitle(repo_name);
  }, [metaService, repo_name]);

  useEffect(() => {
    runsService.get(repositoryURL, events);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runsService, repositoryURL, events.length]);

  useEffect(() => {
    secretsService.setRepositoryFullPath(repositoryURL);
  }, [secretsService, repositoryURL]);

  const shouldDisplayList = runsService.runs.length > 0;
  const shouldDisplayProgress = runsService.runsState.isLoading;

  return (
    <>
      <CyberWeekBanner />
      <S.Content>
        <S.Card>
          {runsService.runsState.isNotFound ? (
            <NotFound title="Repository not found">
              <span>Repository with url</span>
              <strong>{` ${repositoryURL} `}</strong>
              <span>doesn&apos;t exist. </span>

              <Link to="../../..">Go to repository list.</Link>
            </NotFound>
          ) : (
            <>
              <ActionPanel e={events} />

              <GridLayout
                columns={['Build', 'Commit', 'Time', 'Steps', 'Action']}
              >
                {shouldDisplayProgress && <Progress />}

                {runsService.runsState.isLoaded &&
                  runsService.runs.length === 0 && <NothingFound />}

                {runsService.runsState.hasError &&
                  runsService.runs.length === 0 && <ErrorOccurred />}

                {shouldDisplayList && (
                  <>
                    <RunList />
                    <Pagination service={runsService} />
                  </>
                )}
              </GridLayout>
            </>
          )}
        </S.Card>
      </S.Content>
    </>
  );
});

export const RepositoryPage = withStyles(runsCssVariables)(() => (
  <Layout render={<RepositoryPageComponent />} />
));
