import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';

export const MissingFieldPlaceholder = ({
  field,
  type,
}: {
  field: string;
  type: string;
}) => {
  return (
    <Typography variant="body2" align="center">
      No {field} information available for this model. You can find out more
      about adding this through the
      <Link
        to={`https://ml-platform.docs.zalando.net/zflow/concepts.html#${type}`}
      >
        {' '}
        zflow doc.{' '}
      </Link>
    </Typography>
  );
};
