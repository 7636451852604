import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { SectionRadio } from '../common';
import { KubeStats } from './KubeStats';
import { StupsDeployments } from './StupsDeployments';

export function DeploymentInformation({
  hideConfirmationSection,
}: {
  hideConfirmationSection?: boolean;
}) {
  const { entity } = useEntity();
  const api = useApi(applicationReviewApiRef);

  const { loading: stupsLoader, value: stupsDeployments } = useAsync(
    () => api.getStupsStatus(entity.metadata.name),
    [entity],
  );
  const { loading: kubeLoader, value: kubeStats } = useAsync(
    () => api.getDeploymentStats(entity.metadata.name),
    [entity],
  );

  return (
    <section>
      <dl>
        <dt>AWS/STUPS (deprecated)</dt>
        <dd>
          <StupsDeployments
            deployments={stupsDeployments?.instances}
            loading={stupsLoader}
          />
        </dd>

        <dt>Kubernetes</dt>
        <dd>
          <KubeStats stats={kubeStats} loading={kubeLoader} />
        </dd>
      </dl>

      {!hideConfirmationSection && <SectionRadio name="deployment_ok" />}
    </section>
  );
}
