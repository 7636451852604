import React, { useRef, ChangeEvent, SelectHTMLAttributes } from 'react';

import * as S from './styles';

type Props = {
  children?: React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  elementSize?: 'small' | 'medium';
};

export function Select({
  children,
  onChange,
  elementSize = 'medium',
  ...props
}: Props & SelectHTMLAttributes<HTMLSelectElement>) {
  const selectRef = useRef<HTMLSelectElement>(null);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (onChange) {
      selectRef?.current?.blur();
      onChange(event);
    }
  }

  return (
    <>
      <S.Wrapper>
        <S.Select
          ref={selectRef}
          {...props}
          elementSize={elementSize}
          onChange={handleChange}
        >
          {children}
        </S.Select>
        <S.ArrowDown />
      </S.Wrapper>
    </>
  );
}
