import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { AboutField, InfoCard } from 'plugin-ui-components';
import { ModelGroup } from '../../../api/definitions';
import { NOT_AVAILABLE } from '../../../constants/common';
import { getFormattedDateTime } from '../../../utils/time';

export const ModelGroupDetails = ({
  modelGroup,
}: {
  modelGroup: ModelGroup;
}) => {
  return (
    <Grid item xs={6}>
      <InfoCard title={modelGroup.name}>
        <CardContent>
          <Grid container spacing={3}>
            <AboutField
              label="Created at"
              children={
                modelGroup.created_at
                  ? `${getFormattedDateTime(modelGroup.created_at)}`
                  : NOT_AVAILABLE
              }
              xs={12}
              sm={6}
              lg={4}
            />
          </Grid>
        </CardContent>
      </InfoCard>
    </Grid>
  );
};
