import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { scorecardsApiRef, ScorecardsApis } from './api/ScorecardsApis';

export const scorecardsPlugin = createPlugin({
  id: 'scorecards',
  apis: [
    createApiFactory({
      api: scorecardsApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }) => new ScorecardsApis(configApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const TechInsightsPage = scorecardsPlugin.provide(
  createRoutableExtension({
    name: 'Router',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
