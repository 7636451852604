import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { SectionCheckbox } from '../common/SectionCheckbox';
import { ToggleButton } from '@material-ui/lab';
import { ReviewContext } from '../context';
import { classifications } from './classifications';
import { ToggleGroup, ToggleRadio, useToggleButtonClasses } from './styles';

export function DataClassification() {
  const toggleButtonClasses = useToggleButtonClasses();
  /**
   * This is an empty function because React throws an error if the attribute
   * `checked` is set without an `onChange` event. The `onChange` is not needed
   * here because a change handler is attached on the parent `ToggleGroup`.
   */
  const onRadioChange = () => {};

  return (
    <ReviewContext.Consumer>
      {({ review, onReviewChange }) => (
        <section>
          <Typography>
            What is the maximum class of data the application is storing and/or
            processing? See Zalando's&nbsp;
            <Link to="https://drive.google.com/file/d/1R16KoNoT06Y8LkxzfnvIjKt0q4IcaW0U">
              Data Classification Policy <OpenInNewIcon />
            </Link>
            .
          </Typography>
          <ToggleGroup
            value={review.data_classification}
            exclusive
            onChange={(_: any, val: IAppReview.DataClassification) => {
              onReviewChange('data_classification', val || '');
            }}
            aria-label="text alignment"
          >
            {classifications.map(item => (
              <ToggleButton
                value={item.value}
                key={item.value}
                classes={toggleButtonClasses}
              >
                <Typography variant="h4">
                  <ToggleRadio
                    type="radio"
                    name="data_classification"
                    checked={review.data_classification === item.value}
                    onChange={onRadioChange}
                    required
                  />
                  {item.value}
                </Typography>
                <Typography variant="caption">({item.caption})</Typography>
                <Typography variant="body2">{item.description}</Typography>
              </ToggleButton>
            ))}
          </ToggleGroup>
          <SectionCheckbox name="data_classification_ok" />
        </section>
      )}
    </ReviewContext.Consumer>
  );
}
