import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import humanizeDuration from 'humanize-duration';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '30d',
    MM: '%d months',
    y: '1y',
    yy: '> 1y',
  },
});

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

type DateTimeObject = Date | string | number | undefined;

export const DEFAULT_SHORT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const TIME_ZONE = dayjs.tz.guess();

export function relativeTimeTo(time: DateTimeObject, withoutSuffix = false) {
  return dayjs().to(dayjs(time), withoutSuffix);
}

export function timeAgo(time: DateTimeObject) {
  return dayjs(time).fromNow();
}

export function durationHumanized(
  startTime: DateTimeObject,
  endTime: DateTimeObject,
) {
  return shortEnglishHumanizer(
    dayjs.duration(dayjs(startTime).diff(dayjs(endTime))).asMilliseconds(),
    { round: true, spacer: '' },
  );
}

export function getDuration(durationDays: number) {
  return shortEnglishHumanizer(dayjs.duration(durationDays).asMilliseconds(), {
    round: true,
  });
}

export function getFormattedDateTime(
  date: DateTimeObject,
  format = DEFAULT_DATE_TIME_FORMAT,
) {
  const time = dayjs(date).format(format);
  return `${time} ${TIME_ZONE}`;
}

export function getFormattedShortDateTime(
  date: DateTimeObject,
  format = DEFAULT_SHORT_DATE_TIME_FORMAT,
) {
  return dayjs(date).format(format);
}

export function addMinutesToDateString(date: string, minutes: number): Date {
  return new Date(new Date(date).getTime() + minutes * 6e4);
}

export function addMinutesToNanoTimestamps(
  timestamp: number,
  minutes: number,
): number {
  return timestamp + minutes * 6e10;
}

export function getNowTimestampInNanoseconds(): number {
  return new Date().getTime() * 1e6;
}

export function nanoTimestampToDateString(value: number): string {
  return new Date(Math.round(value / 1e6)).toISOString();
}

export function dateStringToNanoTimestamp(value: string): number {
  return new Date(value).getTime() * 1e6;
}

export function prettifyTime(time: number = 0) {
  let unit = 'second';
  let divider = 1;

  // eslint-disable-next-line default-case
  switch (true) {
    case time > 60 * 60 * 24:
      unit = 'day';
      divider = 60 * 60 * 24;
      break;
    case time > 60 * 60:
      unit = 'hour';
      divider = 60 * 60;
      break;
    case time > 60:
      unit = 'minute';
      divider = 60;
      break;
  }

  return Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    style: 'unit',
    unit,
    unitDisplay: 'short',
  }).format(time / divider);
}
