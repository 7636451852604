import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { ProjectForm } from '../../../hooks/useProjectForm';

export const ProjectDescriptionInput = ({
  setForm,
  label,
  projectDescription = '',
}: {
  setForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  label: string | undefined;
  projectDescription: string | undefined;
}) => {
  const [descriptionLength, setDescriptionLength] = useState<number>(
    projectDescription?.length,
  );
  const [error, setError] = useState<boolean>(false);
  const [description, setDescription] = useState<string | undefined>(
    projectDescription,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const value = event.target.value;
    setDescription(value);
    setDescriptionLength(value.length);
    setForm(prevState => ({ ...prevState, description: value }));
    setError(value.length < 50);
  };

  const placeholder =
    'Please provide a short description of your project (e.g. its purpose, scope, time frame). ' +
    'Feel free to also add links to existing documentation.';

  const helperText = `Please provide at least 50 characters. Current length: ${descriptionLength}`;

  return (
    <TextField
      fullWidth
      required
      multiline
      minRows={4}
      maxRows={10}
      label={label}
      helperText={helperText}
      placeholder={placeholder}
      value={description}
      inputProps={{ minLength: 50, 'aria-label': label }}
      variant="outlined"
      margin="dense"
      onChange={handleChange}
      error={error}
    />
  );
};
