import { RunStepModel } from '../../../../models';
import { getColorByStatus } from '../../../../utils/pipeline';
import { NodeType } from './CustomNode';

export type EdgeType = { from: string; to: string };

export function getEdges(steps: RunStepModel[]) {
  const edges = steps.reduce((acc: EdgeType[], curr, i, arr) => {
    if (i < arr.length - 1) {
      const elem = { from: curr.id, to: arr[i + 1].id };
      return [...acc, elem];
    }
    return acc;
  }, []);

  return edges;
}

export function getConcurrentEdges(steps: RunStepModel[]) {
  let all_of_the_above: string[] = [];

  const edges = steps.reduce((acc: EdgeType[], curr) => {
    all_of_the_above.push(curr.id);
    all_of_the_above = all_of_the_above.filter(
      item => !curr.depends_on.includes(item),
    );

    // get edges from the current step to all steps the step depends on
    if (curr.depends_on.length && curr.depends_on[0] !== 'all_of_the_above') {
      const elems = curr.depends_on.map(item => ({ from: item, to: curr.id }));
      return [...acc, ...elems];
    }

    // get edge from the current step to the previous step only
    if (curr.depends_on[0] === 'all_of_the_above') {
      const elems = all_of_the_above
        .slice(0, -1)
        .map(item => ({ from: item, to: curr.id }));

      return [...acc, ...elems];
    }

    return acc;
  }, []);

  return edges;
}

export function getNodes(steps: RunStepModel[]): NodeType[] {
  return steps.map(step => ({
    id: step.id,
    color: getColorByStatus(step.run.status),
    index: step.ordinal,
    status: step.run.status,
  }));
}
