import React from 'react';
import {
  InfoCard,
  TrackedInteraction,
  TrackedLink,
} from 'plugin-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';
import { useStyles } from './styles';
import { attemptEditWhenDisabled, discoverDomainUser } from '../../../tracking';
import EditIcon from '@material-ui/icons/Edit';

const widgetName = 'Domain Overview/About';

export function AboutWidget() {
  const { entity } = useEntity<IEntityDomain>();
  const classes = useStyles();

  const modifiedAt = entity.metadata.modifiedAt
    ? new Date(entity.metadata.modifiedAt)
    : '';
  const createdAt = new Date('2022-06-17T03:24:00');

  return (
    <InfoCard
      title="About"
      cardHeaderProps={{
        action: (
          <TrackedInteraction
            interaction="onClick"
            {...attemptEditWhenDisabled(widgetName)}
          >
            <EditIcon color="disabled" />
          </TrackedInteraction>
        ),
      }}
      cardFooter={
        <Grid container style={{ padding: '1rem' }}>
          <Grid item xs={12}>
            <span className={classes.green}>●</span>
            <Typography display="inline" variant="body2">
              {modifiedAt ? (
                <time dateTime={modifiedAt.toISOString()}>
                  {modifiedAt.toISODateString()}
                </time>
              ) : (
                '-'
              )}
            </Typography>
            <Typography display="inline" variant="body2">
              &nbsp;Description update by&nbsp;
            </Typography>
            <TrackedLink
              href={`/catalog/default/User/${
                parseEntityRef('user:default/amosciatti').name
              }`}
              {...discoverDomainUser(entity.metadata.name, widgetName, 'owner')}
            >
              Andrea Mosciatti
            </TrackedLink>
          </Grid>
        </Grid>
      }
    >
      <Grid container style={{ padding: '1rem' }}>
        <Grid item xs={12}>
          <Typography variant="h6">Owner / Accountable for domain</Typography>
          <TrackedLink
            href={`/catalog/default/User/${
              parseEntityRef(entity.spec.owner).name
            }`}
            {...discoverDomainUser(entity.metadata.name, widgetName, 'owner')}
          >
            {entity.spec.spec.owner_name ||
              parseEntityRef(entity.spec.owner).name}
          </TrackedLink>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Description</Typography>
          <Typography variant="body2">
            {entity.metadata.description ?? '-'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Domain created</Typography>
          <Typography display="inline" variant="body2">
            {createdAt ? (
              <time dateTime={createdAt.toISOString()}>
                {createdAt.toISODateString()}
              </time>
            ) : (
              '-'
            )}
          </Typography>
          <Typography display="inline" variant="body2">
            &nbsp;by&nbsp;
          </Typography>
          <TrackedLink
            href={`/catalog/default/User/${
              parseEntityRef('user:default/lanagel').name
            }`}
            {...discoverDomainUser(entity.metadata.name, widgetName, 'owner')}
          >
            Lacey Nagel
          </TrackedLink>
        </Grid>
      </Grid>
    </InfoCard>
  );
}
