import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SupportPage } from '../components/SupportPage';
import {
  rootRouteRef,
  sectionRouteRef,
  sectionSubjectRouteRef,
} from './routes';

export const SupportRouter = () => {
  return (
    <>
      <Routes>
        <Route path={`/${rootRouteRef.params[0]}`} element={<SupportPage />} />
        <Route
          path={`/${sectionRouteRef.params[0]}`}
          element={<SupportPage />}
        />
        <Route
          path={`/${sectionSubjectRouteRef.params[0]}`}
          element={<SupportPage />}
        />
      </Routes>
    </>
  );
};
