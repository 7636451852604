import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { RadioSelect } from '../common';
import { SectionCaption } from '../common/SectionCaption';
import { SectionCheckbox } from '../common/SectionCheckbox';
import { Criticality } from '../ApplicationMetadata/fields';
import { FormFields } from '../config';
import { ReviewContext } from '../context';
import { CalculatedCriticality } from './CalculatedCriticality';

export const tierAssessmentFields: Array<FormFields> = [
  'in_direct_path_of_critical_business_operation',
  'application_has_fallback_strategy',
  'application_has_retry_strategy',
  'all_clients_have_fallback_strategy',
  'all_clients_have_retry_strategy',
  'can_cause_p1_incident',
  'can_cause_order_drop',
  'can_lead_to_gmv_losss',
  'can_prevent_employees_from_work',
  'can_lead_to_contractual_penalties',
  'can_affect_customer_experience',
  'can_be_down_for_30_minutes_without_informing',
  'can_reduce_overall_redundancy_observabilitiy',
  'can_affect_incident_response',
];

export function CriticalityAssessment() {
  const { entity } = useEntity<IEntityApp>();
  const { review } = React.useContext(ReviewContext);

  const isDisabled = review.in_production === 'no';

  return (
    <section>
      <Typography>
        Please answer the following questions to get a calculated criticality (
        <Link to="https://docs.google.com/document/d/1m7FVdyuE9nohKx4Sorn_Ww9yc9ecCn70ZgVyHWRzkfw">
          Application Tiers <OpenInNewIcon />
        </Link>
        ) for your application. Afterwards compare it to the current criticality
        and either change the value by{' '}
        <Link to={`/catalog/default/component/${entity.metadata.name}/edit`}>
          editing the application
        </Link>{' '}
        or confirm your current criticality assessment.
      </Typography>
      <br />

      <RadioSelect name="in_production" />
      {tierAssessmentFields.map(field => (
        <RadioSelect
          key={field}
          name={field}
          disabled={isDisabled}
          value={isDisabled ? '' : undefined}
        />
      ))}

      <dl>
        <Criticality
          value={entity.spec?.spec?.criticality_level || undefined}
        />
        <CalculatedCriticality />
      </dl>

      <SectionCheckbox name="criticality_ok" />
      <SectionCaption>
        You can change the criticality in the{' '}
        <Link to="../edit#reliability">edit tab</Link>
      </SectionCaption>
    </section>
  );
}
