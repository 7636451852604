import { EntityMeta } from '@backstage/catalog-model';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { subscriptionApiRef } from 'plugin-nakadi';
import { useState } from 'react';
import { useAsync } from 'react-use';

export const useEvents = (metadata: EntityMeta) => {
  const eventsApi = useApi(subscriptionApiRef);
  const [events, setEvents] = useState<{ eventType: string }[]>([]);
  const errorApi = useApi(errorApiRef);

  useAsync(async () => {
    try {
      const data = await eventsApi.getSubscriptions(metadata?.name as string);
      const ids = data.map(item => item.id);

      ids.forEach(async id => {
        const subs = await eventsApi.getSubscriptionStats(id);
        const types = subs.map(sub => ({
          eventType: sub.event_type,
        }));
        setEvents(prev => [...prev, ...types]);
      });
    } catch (err) {
      errorApi.post(err as Error);
    }
  }, [eventsApi, metadata?.name]);

  return { events };
};
