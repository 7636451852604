import { createApiRef } from '@backstage/core-plugin-api';
import { DLQEvent, DLQStats } from '../../domain/DLQ';
import { Subscriptions } from '../../domain/Subscriptions';
import { DLQApiGateway } from '../../gateway/DLQApiGateway/DLQApiGateway';

export interface DLQManager {
  getDLQStats(subscriptions: Subscriptions[]): Promise<DLQStats[]>;
  getDLQEvents(subscription_id: string): Promise<DLQEvent[]>;
  deleteDLQEvent(subscription_id: string, event: DLQEvent): Promise<void>;
  redriveDLQEvents(subscription_id: string, events: DLQEvent[]): Promise<void>;
}

export const DLQApiRef = createApiRef<DLQManager>({
  id: 'plugin.nakadi.nakadi-dlq-api',
});

export class DLQManagerImpl implements DLQManager {
  private readonly dlqApiGateway: DLQApiGateway;

  constructor(options: { dlqApiGateway: DLQApiGateway }) {
    this.dlqApiGateway = options.dlqApiGateway;
  }

  async getDlqStat(subscription_id: string): Promise<DLQStats> {
    const res = await this.dlqApiGateway.getDlqStatus(subscription_id);
    return {
      subscription_id: subscription_id,
      events_count: res.count,
    };
  }

  async getDLQStats(subscriptions: Subscriptions[]): Promise<DLQStats[]> {
    const allStats = [];
    for (const subscription of subscriptions) {
      allStats.push(this.getDlqStat(subscription.id));
    }

    return Promise.all(allStats);
  }

  async getDLQEvents(subscription_id: string): Promise<DLQEvent[]> {
    return await this.dlqApiGateway.getDlqEvents(subscription_id);
  }

  async deleteDLQEvent(
    subscription_id: string,
    event: DLQEvent,
  ): Promise<void> {
    await this.dlqApiGateway.deleteDlqEvents(subscription_id, [event]);
  }

  async redriveDLQEvents(
    subscription_id: string,
    events: DLQEvent[],
  ): Promise<void> {
    await this.dlqApiGateway.redriveDLQEvents(subscription_id, events);
  }
}
