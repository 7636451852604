import { Theme, styled } from '@material-ui/core';
import { SIDEBAR_HEADER_HEIGHT } from '../../../constants';
import { fontSize } from '../../../common';
import { NavLink } from 'react-router-dom';

export const Sidebar = styled('div')(props => ({
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  padding: props.theme.spacing(0),
}));

export const Item = styled(NavLink)(
  (props: { theme: Theme; 'data-active': boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
    transition: 'background .3s ease',
    position: 'relative',
    direction: 'ltr',
    borderRadius: '1px',
    overflow: 'hidden',
    flexShrink: 0,
    padding: '0',
    wordBreak: 'break-all',
    backgroundColor: props['data-active']
      ? props.theme.palette.background.paper
      : 'transparent',

    ['&:hover']: {
      backgroundColor: props.theme.palette.background.paper,
    },

    [props.theme.breakpoints.down('xs')]: {
      backgroundColor: props['data-active']
        ? props.theme.palette.background.default
        : 'transparent',

      ['&:hover']: {
        backgroundColor: props.theme.palette.background.default,
      },
    },
  }),
);

export const HeaderItem = styled(Item)({
  height: '100%',
  borderRadius: 0,
  fontSize: fontSize.tiny,
  padding: '0 10px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Heading = styled('small')((props: { theme: Theme }) => ({
  padding: props.theme.spacing(1, 1, 2, 0),
  color: props.theme.palette.textSubtle,
  fontWeight: 600,
  textTransform: 'uppercase',
}));

export const Header = styled('div')((props: { theme: Theme }) => ({
  height: SIDEBAR_HEADER_HEIGHT,
  borderBottom: `1px solid ${props.theme.palette.divider}`,
}));

export const Icon = styled('span')(props => ({
  display: 'flex',
  color: 'white',
  marginLeft: props.theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px',
  borderRadius: '1px',
  padding: 0,
  flexShrink: 0,
}));

export const StatusLine = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: '5px',
  height: '100%',
  content: `""`,
  transition: 'transform .2s ease',
});

export const Content = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: fontSize.small,
  padding: '10px 0',
  margin: '0 16px',
});

export const HeaderContent = styled(Content)({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Status = styled('figure')({
  display: 'inline-flex',
  flexShrink: 0,
  width: '8px',
  height: '8px',
  margin: '0',
  marginBottom: '-2px',
  borderRadius: '100%',
});
