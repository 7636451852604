import React from 'react';
import * as S from './styles';

export function MenuIcon() {
  return (
    <>
      <S.Input type="checkbox" id="menu_button" />

      <S.Button htmlFor="menu_button">
        <S.SVG width="28px" height="28px" viewBox="0 0 28 28">
          <path d="M26,12H2c-1.1,0-2,0.9-2,2s0.9,2,2,2h24c1.1,0,2-0.9,2-2S27.1,12,26,12z" />
          <path d="M26,12H2c-1.1,0-2,0.9-2,2s0.9,2,2,2h24c1.1,0,2-0.9,2-2S27.1,12,26,12z" />
          <path d="M26,12H2c-1.1,0-2,0.9-2,2s0.9,2,2,2h24c1.1,0,2-0.9,2-2S27.1,12,26,12z" />
        </S.SVG>
      </S.Button>
    </>
  );
}
