import React, { useCallback, useEffect, useState } from 'react';
import { Model } from '../../api/interfaces/model';
import { useApi } from '@backstage/core-plugin-api';
import { modelsApiRef } from '../../api/services/models';
import {
  ListModelVersionsParams,
  ModelVersion,
} from '../../api/interfaces/model_version';
import { useDebounce, useLocalStorage } from 'react-use';
import {
  ModelVersionFilterStore,
  ModelVersionFilterStoreDefault,
} from '../../api/interfaces/store';
import { Grid } from '@material-ui/core';
import { ResourceFilters } from '../tables/ResourceFilters';
import { useModelVersionColumns } from '../tables/columns/ModelVersions';
import { ResourceTable } from '../tables/ResourceTable';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';

export interface ModelVersionListProps {
  model: Model;
}

export const ModelVersionList: React.FC<ModelVersionListProps> = ({
  model,
}: ModelVersionListProps) => {
  const modelsApi = useApi(modelsApiRef);
  const [modelVersions, setModelVersions] = useState<ModelVersion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);

  const [modelVersionFilters, setModelVersionFilters] =
    useLocalStorage<ModelVersionFilterStore>(
      'model-version-filters',
      ModelVersionFilterStoreDefault,
    );

  const fetchModelVersions = useCallback(
    async (params: Record<string, any> = {}) => {
      setLoading(true);
      setError(null);

      try {
        const queryParameters = {
          ...params,
          model_id: model.model_id,
        } as ListModelVersionsParams;
        const response = await modelsApi.getModelVersions(queryParameters);
        setModelVersions(prevModelModelVersions => [
          ...prevModelModelVersions,
          ...(response.versions || []),
        ]);
        return response.versions || [];
      } catch (e) {
        setError(e as Error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelsApi],
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      setModelVersions([]);
      const offset = DEFAULT_PAGE_SIZE * page;
      await fetchModelVersions({ limit: DEFAULT_PAGE_SIZE, offset: offset });
    };
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useDebounce(
    () => {
      if (modelVersionFilters) {
        setModelVersions([]);
        const offset = DEFAULT_PAGE_SIZE * page;
        fetchModelVersions({
          limit: DEFAULT_PAGE_SIZE,
          offset: offset,
          ...modelVersionFilters,
        });
      }
    },
    800,
    [modelVersionFilters, fetchModelVersions],
  );

  const modelVersionColumns = useModelVersionColumns();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <div>
          <ResourceFilters
            fieldsToShow={['stage', 'tags']}
            selected={modelVersionFilters}
            setFilters={setModelVersionFilters}
            loading={loading}
            addQueryParams={false}
          />
        </div>
      </Grid>
      <Grid item xs={10}>
        <ResourceTable
          loading={loading}
          entity="model version"
          error={error}
          columns={modelVersionColumns}
          data={modelVersions}
          paginationConfig={{
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setRowsPerPage: setRowsPerPage,
            page: page || 0,
            total: modelVersions?.length || 0,
          }}
          rowClickConfig={{
            disableRowClick: false,
            resourceName: model.name,
          }}
          displayOptions={{
            showTitle: true,
            search: true,
            padding: 'dense',
          }}
        />
      </Grid>
    </Grid>
  );
};
