import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

interface KV {
  key: string;
  value: any;
}
interface KVTableProps {
  data: KV[];
}

export const KVTable = (props: KVTableProps) => {
  return (
    <Table>
      <TableBody>
        {props.data.map(kv => (
          <TableRow key={kv.key}>
            <TableCell component="th" scope="row">
              {kv.key}
            </TableCell>
            <TableCell component="th" scope="row">
              {kv.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
