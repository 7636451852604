import React from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from '../../../common';
import * as S from './styles';

interface Props {
  children: React.ReactNode;
  filtersChildren?: React.ReactNode;
}

export const ActionPanelLayout = observer(
  ({ children, filtersChildren }: Props) => {
    return (
      <S.ActionPanel>
        <S.Filters>
          {filtersChildren && (
            <>
              <Text bold>Filters: </Text>
              {filtersChildren}
            </>
          )}
        </S.Filters>
        {children}
      </S.ActionPanel>
    );
  },
);
