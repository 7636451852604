import React from 'react';
import { Theme, Box, styled } from '@material-ui/core';
import { fontSize } from '../Typography';

type Props = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
};

const Card = styled('article')(
  (props: { theme: Theme; 'data-active': boolean }) => ({
    padding: props.theme.spacing(2),
    borderRadius: '4px',
    backgroundColor: props.theme.palette.background.default,
    opacity: props['data-active'] ? '1' : '0.5',

    ['&:not(:first-child)']: {
      marginTop: props.theme.spacing(2),
    },

    '& > main': {
      breakInside: 'avoid',
    },
  }),
);

export function InfoCard({ title, subtitle, children }: Props) {
  return (
    <Card data-active={Boolean(children)}>
      <Box fontWeight={600} fontSize={fontSize.medium} component="header">
        {title}
      </Box>

      {subtitle && (
        <Box fontWeight={400} fontSize={fontSize.default} component="p">
          {subtitle}
        </Box>
      )}

      {children && (
        <Box paddingTop={2} fontSize={fontSize.default} component="main">
          {children}
        </Box>
      )}
    </Card>
  );
}
