import React, { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Link } from '@backstage/core-components';
import { MLBreadcrumbsStyle as Breadcrumbs } from 'plugin-ml-platform';

const beautifyNotebookType = (str: string) => {
  return str
    .split('_')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(' ');
};

interface MLXBreadcrumbsProps {
  notebookName?: string;
  executionName?: string;
}
const paths = [
  '/ml',
  '/ml/projects',
  '/ml/projects/:project_id',
  '/ml/projects/:project_id/notebooks',
  '/ml/projects/:project_id/notebooks/:notebook_type',
  '/ml/projects/:project_id/notebooks/:notebook_type/:notebook_id',
  '/ml/projects/:project_id/notebooks/:notebook_type/:notebook_id/:execution_type',
  '/ml/projects/:project_id/notebooks/:notebook_type/:notebook_id/:execution_type/:execution_id',
];

const createItems = (
  path: string,
  pathname: string,
  notebookName: string | undefined,
  executionName: string | undefined,
) => {
  const pathMatch = matchPath(path, pathname);
  if (!pathMatch) return [];
  const {
    project_id,
    notebook_type,
    notebook_id,
    execution_type,
    execution_id,
  } = pathMatch.params;

  interface BreadcrumbItem {
    path: string;
    text: string | undefined;
  }

  const items: BreadcrumbItem[] = [];

  items.push({ path: '/ml/projects', text: 'Projects' });
  items.push({ path: `/ml/projects/${project_id}`, text: project_id });

  if (notebook_type) {
    const notebookPath = `/ml/projects/${project_id}/notebooks/${notebook_type}`;
    const executionPath = `${notebookPath}/${notebook_id}/${execution_type}`;
    items.push({
      path: notebookPath,
      text: beautifyNotebookType(notebook_type),
    });
    items.push({ path: executionPath, text: notebookName });
    items.push({
      path: `${notebookPath}/${execution_id}`,
      text: executionName,
    });
  }
  return items;
};

export const MLXBreadcrumbs = (props: MLXBreadcrumbsProps) => {
  const pathname = useLocation().pathname;
  const { notebookName, executionName } = props;
  const items = useMemo(() => {
    const validPath = paths.find(path => matchPath(path, pathname)) || '';
    return createItems(validPath, pathname, notebookName, executionName).filter(
      x => x.text,
    );
  }, [pathname, notebookName, executionName]);
  return (
    <Breadcrumbs>
      {items.map(({ path, text }) => (
        <Link to={path} key={path} title={text}>
          {text}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
