import mermaid from 'mermaid';

export const SHADOW_ROOT_SELECTOR =
  "[data-testid='techdocs-native-shadowroot']";

export async function renderMermaidDiagrams(): Promise<void> {
  const shadowRoot = document.querySelector(SHADOW_ROOT_SELECTOR)?.shadowRoot;
  shadowRoot?.firstElementChild?.setAttribute('id', 'docs-shadow-root');
  const mermaidBlocks = shadowRoot?.querySelectorAll('.mermaid');
  if (mermaidBlocks) {
    for (let index = 0; index < mermaidBlocks.length; index++) {
      const block = mermaidBlocks[index];
      const mermaidCode = block.textContent;
      if (mermaidCode) {
        block.classList.add('loading');
        block.setAttribute('id', `mermaidDiagram${index}`);
        if (!mermaidCode.includes(`mermaidDiagram${index}`)) {
          const { svg, bindFunctions } = await mermaid.render(
            `mermaidDiagram${index}`,
            mermaidCode,
          );
          const node = new DOMParser().parseFromString(svg, 'text/html').body
            .firstElementChild;
          if (node) block.appendChild(node);
          if (bindFunctions) bindFunctions(block);
          block.classList.remove('loading');
        }
      }
    }
  }
}
