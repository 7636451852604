import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { Progress } from '@backstage/core-components';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { colors } from 'plugin-ui-components';
import { Layout } from '../../common';
import { NotFound } from '../common';
import { useServices } from '../../services';

function ErrorMessage() {
  return (
    <Layout
      render={
        <Box
          color={colors.semantic.errorBase}
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorIcon color="inherit" />
          <span>
            &nbsp;Failed to fetch pipeline data. Please try again by refreshing
            the page.
          </span>
        </Box>
      }
    />
  );
}

export const PipelineRedirect = observer(() => {
  const { id = '' } = useParams();
  const { runService, metaService } = useServices();

  useEffect(() => {
    metaService.setTitle(id);
    runService.getRun(id);
  }, [metaService, runService, id]);

  if (runService.runState.isLoading || runService.runState.isIdle) {
    return <Progress />;
  }

  if (runService.runState.isNotFound) {
    return <Layout render={<NotFound id={id} />} />;
  }

  if (runService.runState.hasError || !runService.run.target_repo) {
    return <ErrorMessage />;
  }

  const { alias, organization, name } = runService.run.repoInfo;

  return (
    <Navigate replace to={`/cdp/${alias}/${organization}/${name}/${id}`} />
  );
});
