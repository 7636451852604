export const runGroupsCssVariables = () => ({
  '@global': {
    ':root': {
      '--grid-template':
        'minmax(170px, 1fr) minmax(0px, 125px) minmax(180px, 1.2fr) 60px',
    },
  },
});

export const runsCssVariables = () => ({
  '@global': {
    ':root': {
      '--grid-template':
        'minmax(130px, 0.20fr) minmax(80px, 0.9fr) minmax(0px, 125px) minmax(180px, 1.2fr) 60px',
    },
  },
});
