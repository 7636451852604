import React, { useEffect, useState } from 'react';
import { useStyles } from '../../styles';
import { useSearchContext } from '../../SearchContext';
import type { SearchResult } from '../../types';
import { SearchResultsContent } from './SearchResultsContent';
import { searchConfig } from '../../config';

export function SearchResults() {
  const { tab, results } = useSearchContext();
  const styles = useStyles();
  const [tabResults, setTabResults] = useState<SearchResult>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const searchIndex = searchConfig.indexes.findIndex(i =>
    i.isMatchingQuery(tab),
  );
  const newTabResults = results[searchIndex];

  useEffect(() => {
    setError(undefined);

    if (!!newTabResults) {
      setLoading(true);
      newTabResults
        .then(res => {
          if (res.error) {
            setError(res.error);
            setTabResults(undefined);
          } else {
            setError(undefined);
            setTabResults(res.data);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setTabResults(undefined);
    }
  }, [newTabResults, tab]);

  return (
    <div className={styles.searchResults}>
      <SearchResultsContent
        results={tabResults}
        loading={loading}
        error={error}
      />
    </div>
  );
}
