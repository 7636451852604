import React from 'react';
import { Text } from '../../../common';
import * as S from './styles';

export const NothingFound = () => (
  <S.InfoWrapper>
    <Text py={2} data-testid="run-groups-info">
      0 pipelines found.
    </Text>
  </S.InfoWrapper>
);
