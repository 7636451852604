import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, ThemeProvider } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import {
  isValidFormData,
  ProjectForm,
  useProjectForm,
} from '../../hooks/useProjectForm';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { CreateMLProjectResponse, UserTeam } from '../../api/definitions';
import { ProjectNameInput } from './InputComponents/ProjectNameInput';
import { ProjectDescriptionInput } from './InputComponents/ProjectDescriptionInput';
import { KPIInput } from './InputComponents/KPIInput';
import { zflowApiRef } from '../../api/zflowApiClient';
import { v4 as uuidv4 } from 'uuid';
import { ButtonTheme } from '../common/customTheme';
import { PLACEHOLDER_USER_TEAM } from '../../api/PlaceholderResponses';
import { useTeamFilterBoxBorderStyles } from '../../styles';

export const NewMLProjectForm = () => {
  const zflowApi = useApi(zflowApiRef);

  const initialFormState: ProjectForm = {
    name: '',
    description: '',
    impacted_kpis: [],
  };

  const [form, setFormProperty] = useProjectForm(initialFormState);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [responseError, setResponseError] = useState<Array<string>>();
  const [selectedTeam, setSelectedTeam] = useState<UserTeam>();
  const [registeredProject, setRegisteredProject] = useState<
    CreateMLProjectResponse | undefined
  >();
  const [userTeams, setUserTeams] = useState<UserTeam[]>([
    PLACEHOLDER_USER_TEAM,
  ]);

  const classes = useTeamFilterBoxBorderStyles();

  useEffect(() => {
    setFormProperty(prevState => ({
      ...prevState,
      team_id: selectedTeam?.team_id,
    }));
  }, [selectedTeam, setFormProperty]);

  useEffect(() => {
    zflowApi.getUserInfo().then(userInfo => setUserTeams(userInfo.teams));
  }, [zflowApi]);

  useEffect(() => {
    if (userTeams.length === 1) {
      setSelectedTeam(userTeams[0]);
    }
  }, [userTeams]);

  useEffect(() => {
    if (isValidFormData(form.name, form.description, form.impacted_kpis)) {
      setDisableSubmitButton(false);
    } else setDisableSubmitButton(true);
  }, [form.description, form.impacted_kpis, form.name]);

  function showSuccessMessage() {
    return (
      <Alert
        action={
          <Button
            color="inherit"
            size="small"
            href={`projects/${registeredProject?.project_id}`}
          >
            Go to Project
          </Button>
        }
      >
        Your project was successfully registered!
      </Alert>
    );
  }

  function showErrorMessage() {
    return (
      <Alert severity="error">
        <AlertTitle>Error creating project</AlertTitle>
        {responseError && (
          <ul>
            {responseError.map(violation => {
              return <li key={uuidv4()}>{violation}</li>;
            })}
          </ul>
        )}
      </Alert>
    );
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    zflowApi
      .createProject(form)
      .then((data: Array<string> | CreateMLProjectResponse) => {
        setDisableSubmitButton(true);
        if (data instanceof Array) {
          setResponseError(data);
        } else {
          setRegisteredProject(data);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {registeredProject && showSuccessMessage()}
      {responseError && showErrorMessage()}
      <Grid container>
        <Grid item md={6} sm={12}>
          <ProjectNameInput setForm={setFormProperty} label="Project Name" />
        </Grid>
        <Grid item md={6} sm={12}>
          <Autocomplete
            autoHighlight
            disableClearable
            key={uuidv4()}
            data-testid="register-project-autocomplete-select"
            options={userTeams}
            classes={classes}
            getOptionLabel={option => option.nickname}
            value={selectedTeam}
            onChange={(_, selected: UserTeam) => setSelectedTeam(selected)}
            renderInput={params => (
              <TextField
                {...params}
                value={selectedTeam}
                label="Select Team"
                variant="outlined"
                margin="dense"
                data-testid="register-project-autocomplete-input"
              />
            )}
          />
        </Grid>
        <Grid item sm={12}>
          <ProjectDescriptionInput
            setForm={setFormProperty}
            label="Description"
            projectDescription=""
          />
        </Grid>
        <Grid item sm={12}>
          <KPIInput setForm={setFormProperty} label="KPI *" projectKPIs={[]} />
        </Grid>
      </Grid>
      <br />
      <br />
      <ThemeProvider theme={ButtonTheme}>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSubmitButton}
          type="submit"
          data-testid="register-project-submit-button"
        >
          Register Project
        </Button>
      </ThemeProvider>
    </form>
  );
};
