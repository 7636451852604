import React, { type JSX } from 'react';
import {
  matchRoutes,
  RouteMatch,
  RouteObject,
  useNavigate,
  useParams,
  useRoutes,
} from 'react-router';
import { Content, HeaderTabs, Tab } from '@backstage/core-components';
import { Helmet } from 'react-helmet';

export interface ContentProps {
  path: string;
  title: React.ReactNode;
  element: JSX.Element;
  onTabSelected?: VoidFunction;
}

const getSelectedIndexOrDefault = (
  matchedRoute: RouteMatch,
  tabs: Tab[],
  defaultIndex = 0,
) => {
  if (!matchedRoute) return defaultIndex;
  const tabIndex = tabs.findIndex(t => t.id === matchedRoute.route.path);
  return ~tabIndex ? tabIndex : defaultIndex;
};

const NotFound = React.lazy(() =>
  import('plugin-ui-components').then(module => ({
    default: module.NotFoundPage,
  })),
);

/**
 * Compound component, which allows you to define layout
 * for EntityPage using Tabs as a sub-navigation mechanism
 * Consists of 2 parts: Tabbed.Layout and Tabbed.Content.
 * Takes care of: tabs, routes, document titles, spacing around content
 *
 * @example
 * ```jsx
 * <Tabbed.Layout>
 *   <Tabbed.Content
 *      title="Example tab"
 *      route="/example/*"
 *      element={<div>This is rendered under /example/anything-here route</div>}
 *   />
 * </TabbedLayout>
 * ```
 */
export const Tabbed = {
  Layout: ({ children }: { children: React.ReactNode }) => {
    const routes: Partial<RouteObject>[] = [];
    const tabs: Tab[] = [];
    const params = useParams();
    const navigate = useNavigate();
    //
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) {
        // Skip conditionals resolved to falses/nulls/undefineds etc
        return;
      }
      if (child.type !== Tabbed.Content) {
        throw new Error(
          'This component only accepts Content elements as direct children. Check the code of the EntityPage.',
        );
      }
      const childProps = (child as JSX.Element).props as ContentProps;
      const pathAndId = childProps.path;

      // Child here must be then always a functional component without any wrappers
      tabs.push({
        id: pathAndId,
        label: childProps.title as any,
        tabProps: {
          onClick: () => {
            const currentPath = `/${params['*']}`;
            if (childProps.onTabSelected && pathAndId !== currentPath) {
              childProps.onTabSelected();
            }
          },
        },
      });

      routes.push({
        path: pathAndId,
        element: child.props.element,
      });
    });

    // Add catch-all for incorrect sub-routes
    if ((routes?.[0]?.path ?? '') !== '')
      routes.push({
        path: '/*',
        element: (
          <React.Suspense fallback={<div>Loading...</div>}>
            <Content>
              <NotFound />
            </Content>
          </React.Suspense>
        ),
      });

    const [matchedRoute] =
      matchRoutes(routes as RouteObject[], `/${params['*']}`) ?? [];
    const selectedIndex = getSelectedIndexOrDefault(matchedRoute, tabs);
    const currentTab = tabs[selectedIndex];
    const title = currentTab?.label;

    const onTabChange = (index: number) =>
      // Remove trailing /*
      // And remove leading / for relative navigation
      // Note! route resolves relative to the position in the React tree,
      // not relative to current location
      navigate(tabs[index].id.replace(/\/\*$/, '').replace(/^\//, ''));

    const currentRouteElement = useRoutes(routes);

    /** Converts a React node to string */
    const nodeToString = (t: React.ReactNode): string => {
      function getNodeText(node: React.ReactNode): string {
        if (['string', 'number'].includes(typeof node)) {
          return node as string;
        }
        if (node instanceof Array) {
          return node.map(getNodeText).join('');
        }
        if (typeof node === 'object' && node) {
          return getNodeText((node as React.ReactElement).props.children);
        }
        return '';
      }

      return getNodeText(t);
    };

    if (!currentTab) return null;
    return (
      <>
        <HeaderTabs
          tabs={tabs}
          selectedIndex={selectedIndex}
          onChange={onTabChange}
        />
        <Content>
          <Helmet title={nodeToString(title)} />
          {currentRouteElement}
        </Content>
      </>
    );
  },
  Content: (_props: ContentProps) => null,
};
