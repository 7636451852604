import { createApiRef } from '@backstage/core-plugin-api';
import {
  PullRequestsResponse,
  IssuesResponse,
  OrganizationsResponse,
  RepositoriesResponse,
  RepositoryVulnerabilityResponse,
} from './types/responses';

export const gitHubApiRef = createApiRef<GitHubApi>({
  id: 'plugin.github.service',
});

export interface GitHubApi {
  fetchRepositories(orgs: string): Promise<RepositoriesResponse>;
  fetchOrganizations(): Promise<OrganizationsResponse>;
  fetchPullRequests(): Promise<PullRequestsResponse>;
  fetchIssues(): Promise<IssuesResponse>;
  fetchVulnerabilities(
    appName?: string,
  ): Promise<RepositoryVulnerabilityResponse>;
}
