import React from 'react';

import { getColorByStatus, parseNanoTime } from './utils';
import { IJUnitTest } from '../../../../../api/types/payload';
import * as S from './styles';

export function TestItem({ name, duration, status, error }: IJUnitTest) {
  return (
    <S.TestItem>
      <S.TestItemSummary>
        <S.TestItemHeader>
          <S.TestItemIcon
            style={{ backgroundColor: getColorByStatus(status) }}
            title={status}
          />

          <span title={name}>{name}</span>
          <strong>&nbsp;{parseNanoTime(duration)}</strong>
        </S.TestItemHeader>
      </S.TestItemSummary>

      {error && (
        <S.TestItemError>
          <span>{error.message || error.body || error.type}</span>
        </S.TestItemError>
      )}
    </S.TestItem>
  );
}
