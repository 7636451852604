import React from 'react';
import {
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { config, FormFields } from '../config';
import { ReviewContext } from '../context';

type Props = TextFieldProps & {
  name: FormFields;
};

export function Input({ name, children, helperText, ...props }: Props) {
  const hasLabel = children || config[name]?.label;
  return (
    <ReviewContext.Consumer>
      {({ review, onReviewChange }) => (
        <Grid container>
          {hasLabel && (
            <Grid item md={3}>
              <FormLabel>{children || config[name]?.label || ''}</FormLabel>
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Grid>
          )}
          <Grid item md={hasLabel ? 9 : 12}>
            <TextField
              {...props}
              label={props.placeholder}
              name={name}
              value={review[name]}
              onChange={ev => onReviewChange(name, ev.target.value)}
              variant="outlined"
              margin="dense"
              style={{ marginTop: 0 }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </ReviewContext.Consumer>
  );
}
