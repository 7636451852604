import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  itemAction: {
    visibility: 'hidden',
    '&.show': {
      visibility: 'visible',
    },
  },
}));
