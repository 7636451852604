import React from 'react';
import { mapChanges } from './mapChanges';
import { ChangeTracker } from 'plugin-ui-components';

interface CompareChangesProps {
  original: IKioAppApiResponse;
  updated: IKioAppApiResponse;
}

export const CompareChanges = ({ original, updated }: CompareChangesProps) => {
  const changes = mapChanges(original, updated);
  return (
    <div>
      <ChangeTracker changes={changes} />
    </div>
  );
};
