import { IRunsTags } from '../../api/types/payload';

export class RunsTagsModel {
  accountable_user: string;
  application: string;
  branch: string;
  deployment_unit: string;
  event: string;
  repo: string;
  x_zalando_team: string;
  labels: string[];

  constructor(json: IRunsTags = {} as IRunsTags) {
    this.accountable_user = json.accountable_user ?? '';
    this.application = json.application ?? '';
    this.branch = json.branch ?? '';
    this.deployment_unit = json.deployment_unit ?? '';
    this.event = json.event ?? '';
    this.repo = json.repo ?? '';
    this.x_zalando_team = json.x_zalando_team ?? '';
    this.labels = filterVisibleLabels(json.pr_labels ?? []);
  }
}

const VISIBLE_PR_LABELS = ['major', 'architectural', 'bugfix', 'minor'];

function filterVisibleLabels(labels: string[]): string[] {
  return labels.filter(label => VISIBLE_PR_LABELS.includes(label));
}
