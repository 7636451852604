import React from 'react';
import { Box } from '@material-ui/core';

import { RunStepRunModel } from '../../../../models';
import { getColorByStatus } from '../../../../utils/pipeline';
import { getFormattedDateTime } from '../../../../utils/time';
import { Emoji, InfoCard, List, ListItem } from '../../../../common';
import { StatusIcons } from '../../../common';
import { UserReference } from 'plugin-ui-components';

export function StepRunHistory({ history }: { history: RunStepRunModel[] }) {
  return (
    <InfoCard
      title={
        <span>
          <Emoji name="info" emoji="📜 " /> Run history
        </span>
      }
    >
      <List>
        {history.map(run => (
          <ListItem
            key={run.created_at}
            icon={
              <Box color={getColorByStatus(run.status)}>
                <StatusIcons status={run.status} size="small" />
              </Box>
            }
          >
            {getFormattedDateTime(run.created_at)} | Triggered by&ensp;
            <UserReference user={run.accountable_user} displayType="login" />
          </ListItem>
        ))}
      </List>
    </InfoCard>
  );
}
