import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import { InfoCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { analyticsApiRef, Context } from '../../analytics';
import { SaveAction } from './SaveAction';
import { FilterZeroTooltip } from './FilterZeroTooltip';

const Graph = ({ context }: { context: Context }) => {
  const analyticsApi = useApi(analyticsApiRef);
  const dataPoints = analyticsApi.incidentsByWeekAndSeverity(context);

  return (
    <div
      id="weekly-incidents-severity"
      style={{
        width: '100%',
        height: 300,
        paddingTop: '1.2rem',
        paddingRight: '1.2rem',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart data={dataPoints}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />
          <YAxis />
          <Bar
            dataKey="s1"
            fill="#bf2600"
            name="SEV1 - Critical"
            stackId="a"
            barSize={30}
          />
          <Bar
            dataKey="s2"
            fill="#ff7452"
            name="SEV2 - High"
            stackId="a"
            barSize={30}
          />
          <Bar
            dataKey="s3"
            fill="#ffab00"
            name="SEV3 - LOW"
            stackId="a"
            barSize={30}
          />
          <Tooltip content={<FilterZeroTooltip />} />
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export const WeeklyIncidentsSeverity = ({ context }: { context: Context }) => {
  return (
    <InfoCard
      title="Incidents by week and severity"
      action={<SaveAction targetRef="weekly-incidents-severity" />}
    >
      <Graph context={context} />
    </InfoCard>
  );
};
