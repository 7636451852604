import React from 'react';
import { useAsync } from 'react-use';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import { RELATION_PROVIDES_API } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  APIsIcon,
  EventTracker,
  IconLinkVertical,
  KubeIcon,
} from 'plugin-ui-components';
import { softwareCatalogApiRef } from '../../../api';
import { clickLink } from './tracking';

type LinkProps = {
  label: string;
  href: string;
  icon: React.ReactElement;
  empty?: string | React.ReactElement;
};

const EXTERNAL_LINKS_HOSTS = {
  kube: 'https://kube-resource-report.stups.zalan.do',
  grafana:
    'https://grafana.zalando.net/d/tVu7GJSZz/kubernetes-team-and-application-costs',
};

export const buildAppLinks = (
  entity: IEntityApp,
  hasKubeResource?: boolean,
): Record<string, LinkProps> => {
  const grafanaQuery = new URLSearchParams({
    orgId: '1',
    'var-team': entity?.spec?.spec?.team_id || '',
    'var-application': entity?.metadata?.name || '',
  }).toString();

  return {
    source: {
      label: 'View Source',
      href: entity?.spec?.spec?.scm_url || '',
      icon: <GitHubIcon />,
    },
    documentation: {
      label: 'View Documentation',
      href: entity?.spec?.spec?.documentation_url || '',
      icon: <MenuBookIcon />,
      empty: 'No documentation available',
    },
    apis: {
      label: 'View APIs',
      href: entity?.relations?.some(r => r.type === RELATION_PROVIDES_API)
        ? 'api'
        : '',
      icon: <APIsIcon fontSize="1.7em" />,
      empty: 'No APIs available',
    },
    support: {
      label: 'Contact Support',
      href: entity?.spec?.spec?.support_url?.includes('@')
        ? `mailto:${entity?.spec?.spec?.support_url}`
        : entity?.spec?.spec?.support_url || '',
      icon: <HelpOutlineIcon />,
      empty: 'No support information available',
    },
    kube: {
      label: 'Kube Resource Report',
      href: hasKubeResource
        ? `${EXTERNAL_LINKS_HOSTS.kube}/application-${entity?.metadata?.name}.html`
        : '',
      icon: <KubeIcon fontSize="1.7em" />,
      empty: (
        <>
          Kubernetes saves you time and Zalando money by centralizing management
          of infrastructure. Don't miss out,{' '}
          <Link to="/docs/default/Documentation/cloud/#getting-started">
            get started
          </Link>{' '}
          now!
        </>
      ),
    },
    cost: {
      label: 'Cost Dashboard',
      href: `${EXTERNAL_LINKS_HOSTS.grafana}?${grafanaQuery}`,
      icon: <MonetizationOnIcon />,
    },
  };
};

export function AboutCardLinks({ entity }: { entity: IEntityApp }) {
  const [appLinks, setAppLinks] = React.useState<Record<string, LinkProps>>({});
  const softwareCatalogApi = useApi(softwareCatalogApiRef);

  const { value: hasKubeResource } = useAsync(
    () => softwareCatalogApi.getDeployments(entity?.metadata?.name),
    [entity],
  );

  React.useEffect(() => {
    setAppLinks(buildAppLinks(entity, !!hasKubeResource));
  }, [entity, hasKubeResource]);

  return (
    <Box
      component="nav"
      sx={{
        mb: 2,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(65px, max-content))',
        gridAutoColumns: 'min-content',
        gap: 3,
      }}
    >
      {Object.keys(appLinks).map(key => (
        <EventTracker key={key} {...clickLink(appLinks[key].label)}>
          <Tooltip
            title={(!appLinks[key].href && appLinks[key].empty) || ''}
            placement="top"
            arrow
          >
            <span>
              <IconLinkVertical
                {...appLinks[key]}
                label={
                  <>
                    {appLinks[key].label}{' '}
                    {key === 'kube' && !hasKubeResource && (
                      <InfoIcon fontSize="inherit" />
                    )}
                  </>
                }
                disabled={!appLinks[key].href}
              />
            </span>
          </Tooltip>
        </EventTracker>
      ))}
    </Box>
  );
}
