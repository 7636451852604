import { MLPipelineRun } from '../../../api/definitions';
import jmespath from 'jmespath';

enum SORT_ORDER {
  TIME = 'Chronological',
  ASC = 'Ascending',
  DESC = 'Descending',
}

enum GRAPH_TYPE {
  LINE = 'line',
  DOT = 'dot',
  BAR = 'bar',
}

const getDistinct = (array: Array<any>) => Array.from(new Set(array));
const getDefault = (array: Array<any>) =>
  array && array.length ? [array[0]] : [];

const extractKeys = (runs: Array<MLPipelineRun>, keyType: string): string[] => {
  const keys: string = jmespath.search(runs, `[*].${keyType}[*].key[]`);
  return [...new Set(keys)];
};

const sortData = (sortBy: string, sortOrder: string, data: Array<any>) => {
  const comparator = (a: any, b: any) => {
    if (sortOrder === SORT_ORDER.ASC) {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    if (sortOrder === SORT_ORDER.DESC) {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    }
    return 1;
  };
  return data.sort(comparator);
};

export {
  GRAPH_TYPE,
  SORT_ORDER,
  extractKeys,
  getDefault,
  getDistinct,
  sortData,
};
