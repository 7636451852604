import React, { useContext } from 'react';
import { Link } from '@backstage/core-components';
import { CardContent, Grid, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { isURLField } from '../../utils/fields';
import { replaceIfNeeded } from '../../utils/language';
import { config } from '../config';
import { ReviewSummaryContext } from '../context';
import * as S from '../styles';
import { useCategoryKeys } from '../../hooks';
import { SummaryCard } from '../common';

const options: Record<any, IAppReview.ItemResponseHuminazed> = {
  not_sure: 'Not sure',
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown',
  green: 'Green',
  red: 'Red',
  amber: 'Amber',
  notrelevant: 'Not relevant',
};

export const DefaultSummary = ({
  category,
}: {
  category: IAppReview.ReviewCategory;
}) => {
  const { review, entity } = useContext(ReviewSummaryContext);
  const { categoryKeys } = useCategoryKeys(category);

  return (
    <SummaryCard category={category}>
      {!!categoryKeys.length ? (
        <CardContent>
          {categoryKeys.map(item => (
            <Grid container key={item}>
              <Grid item xs={10}>
                <S.Label>
                  {replaceIfNeeded(config[item]?.label, entity.spec?.spec)}
                </S.Label>
              </Grid>
              <Grid item xs={2}>
                {isURLField(item) ? (
                  <S.Label align="right" style={{ fontWeight: 'bold' }}>
                    {review[item] === 'no' ? (
                      'No'
                    ) : (
                      <Link to={review[item]}>
                        View <OpenInNewIcon />
                      </Link>
                    )}
                  </S.Label>
                ) : (
                  <Typography align="right" style={{ fontWeight: 'bold' }}>
                    {options[review[item]] || review[item]}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ))}
        </CardContent>
      ) : (
        <CardContent>
          <Typography>No data available</Typography>
        </CardContent>
      )}
    </SummaryCard>
  );
};
