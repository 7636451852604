import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FilterGroupsContext } from '../context';

export type EntityFilterGroupOutput = {
  state: ICatalog.FilterGroupStates;
  setSelectedFilters: (filterIds: string[]) => void;
};

/**
 * Hook that exposes the relevant data and operations for a single filter
 * group.
 */
export const useEntityFilterGroup = (
  filterGroupId: string,
  filterGroup: ICatalog.FilterGroup,
  initialSelectedFilters?: string[],
): EntityFilterGroupOutput => {
  const context = useContext(FilterGroupsContext);
  if (!context) {
    throw new Error(`Must be used inside an EntityFilterGroupsProvider`);
  }
  const { register, unregister, setGroupSelectedFilters, filterGroupStates } =
    context;

  // on state changes unregisters and registers the filtergroup
  // ensure that it re-registers with the correct filter as the prop changes and not the default
  // eslint-disable-next-line
  const initialMemo = useMemo(() => {
    return initialSelectedFilters?.slice();
  }, [initialSelectedFilters]);

  // Register the group on mount, and unregister on unmount
  useEffect(() => {
    register(filterGroupId, filterGroup, initialMemo);
    return () => unregister(filterGroupId);
    // eslint-disable-next-line
  }, [register, unregister, filterGroupId, filterGroup]);

  const setSelectedFilters = useCallback(
    (filters: string[]) => {
      setGroupSelectedFilters(filterGroupId, filters);
    },
    [setGroupSelectedFilters, filterGroupId],
  );

  let state = filterGroupStates[filterGroupId];
  if (!state) {
    state = { type: 'loading' };
  }
  return { state, setSelectedFilters };
};
