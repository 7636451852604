import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsyncRetry } from 'react-use';

export function useAllTeams<T = IEntityGroup[]>(
  processor?: (teams: IEntityGroup[] | undefined) => any,
) {
  const catalogApi = useApi(catalogApiRef);

  return useAsyncRetry<T>(
    () =>
      catalogApi
        .getEntities({
          filter: {
            kind: 'Group',
            'spec.type': 'team',
          },
        })
        .then(res => res.items as IEntityGroup[])
        .then(res => (processor ? processor(res) : res)),
    [catalogApi],
  );
}
