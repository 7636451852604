import React from 'react';

import { Header, Column } from './styles';

type Props = {
  statuses: Record<string, number>;
  total: number;
};

export function TestHeader({ statuses, total }: Props) {
  return (
    <Header>
      <Column>
        <strong>Name</strong>
      </Column>

      <Column>
        <strong>Duration</strong>
      </Column>

      <Column>
        <strong>{`Total (${total})`}</strong>
      </Column>

      <Column>{`Passed (${statuses.passed})`}</Column>

      <Column>{`Skipped (${statuses.skipped})`}</Column>

      <Column>{`Failed (${statuses.failed})`}</Column>

      <Column>{`Error (${statuses.error})`}</Column>
    </Header>
  );
}
