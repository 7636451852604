import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useAsync } from 'react-use';

export function CyberWeekInScopeBanner() {
  const reviewApi = useApi(applicationReviewApiRef);
  const {
    entity: { metadata },
  } = useEntity<IEntityApp>();

  const { value: applicationsInfo } = useAsync(
    () => reviewApi.getApplicationInfo(metadata.name),
    [reviewApi],
  );

  const toerOrg = applicationsInfo?.cyberweek_toer_organization_code;

  return (
    <Alert severity="info">
      <Typography>
        This application is in scope for Cyber Week and belongs to the&nbsp;
        <Link to="https://teams.docs.zalando.net/cyberweek-toer-organizations.html">
          Cyber Week TOER Organization{toerOrg ? ` "${toerOrg}"` : ''}{' '}
          <OpenInNewIcon />
        </Link>
        . Please contact the Cyber Week TOER coordinator for your organization
        (see&nbsp;
        <Link to="https://teams.docs.zalando.net/cyberweek-toer-organizations.html">
          list of coordinators
        </Link>
        ) if something is wrong or unclear.
      </Typography>
      <br />
      <Typography>
        To get ready for Cyber Week, please follow the&nbsp;
        <Link to="https://engineering.docs.zalando.net/guidelines/apec/">
          Application Production Engineering Checklist (APEC) <OpenInNewIcon />
        </Link>
        :
      </Typography>
      <ul>
        <li>
          Check the&nbsp;
          <Link to="https://github.bus.zalan.do/risk-register/toer-2022">
            Cyber Week Technical Risk Register <OpenInNewIcon />
          </Link>
          : are there any risks which could involve "{metadata.name}"?
        </li>
        <li>
          Make sure to document SLOs: use&nbsp;
          <Link to="https://sre.docs.zalando.net/slos/#tooling">
            existing tools <OpenInNewIcon />
          </Link>
          &nbsp;or document manually
        </li>
        <li>
          Link any custom Grafana dashboards: tag Grafana dashboards with&nbsp;
          <code className="tag">app:{metadata.name}</code>
        </li>
        <li>
          Instrument with tracing: all applications involved in&nbsp;
          <Link to="https://sre.docs.zalando.net/products/service-level-objectives/service-level-objectives/">
            Critical Business Operations <OpenInNewIcon />
          </Link>
          &nbsp;must have tracing
        </li>
        <li>
          Link ZMON/OpsGenie alerts: use the "application" argument of
          "notify_opsgenie" (
          <code>notify_opsgenie(teams="…", application='{metadata.name}')</code>
          , see&nbsp;
          <Link to="https://visibility.docs.zalando.net/zmon/notifications/#opsgenie">
            docs <OpenInNewIcon />
          </Link>
          )
        </li>
        <li>
          Link incident playbooks: add "{metadata.name}" to the "Applications"
          field (see&nbsp;
          <Link to="https://incident.docs.zalando.net/documentation/playbook-guidance/">
            playbook guidance <OpenInNewIcon />
          </Link>
          )
        </li>
        <li>
          Load test: follow the&nbsp;
          <Link to="https://cloud.docs.zalando.net/howtos/load-testing-protocol/">
            Load Testing Protocol <OpenInNewIcon />
          </Link>
          &nbsp;and use the&nbsp;
          <Link to="https://cloud.docs.zalando.net/howtos/load-test/#load-test-reporting">
            Load Test Reporting API <OpenInNewIcon />
          </Link>
        </li>
        <li>
          Conduct a&nbsp;
          <Link to="https://engineering.docs.zalando.net/practices/reviews/production-readiness/">
            Production Readiness Review
          </Link>
          : use the&nbsp;
          <Link to="https://docs.google.com/document/d/1F2Q1YlV40dsuZ3E0bWgrpZuK-GDleemEvKOwb8d5_24/edit">
            Production Readiness Review template <OpenInNewIcon />
          </Link>
        </li>
        <li>Complete this review form and submit it</li>
      </ul>
    </Alert>
  );
}
