import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { useLocalStorage } from 'react-use';
import { InternalTrackedIconButton } from '../Tracking';

interface Props {
  message: React.ReactNode;
  variant: 'standard' | 'filled' | 'outlined';
  severity: 'success' | 'info' | 'warning' | 'error';
  id: string;
  shouldUseLocalStorage?: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

    '& [class*=MuiAlert-root]': {
      marginBottom: theme.spacing(2),
      borderColor: 'var(--mui-info-border)',
      color: 'var(--mui-info-text)',
    },
  },
}));

export const DismissibleBanner = ({
  message,
  variant,
  severity,
  id,
  onClose,
  shouldUseLocalStorage = true,
  ...trackingProps
}: Props & ITrackingEvent) => {
  const classes = useStyles();
  const [open, setOpen] = useLocalStorage<boolean>(`${id}Dismissible`, true);

  return (
    <div className={classes.root}>
      <Collapse in={!shouldUseLocalStorage || open}>
        <Alert
          action={
            <InternalTrackedIconButton
              {...trackingProps}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onClose?.();
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </InternalTrackedIconButton>
          }
          variant={variant}
          severity={severity}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};
