import { action, makeObservable, observable, runInAction } from 'mobx';
import { GitHubApi } from 'plugin-github';
import { CDPApi } from '../api';
import { OrganizationModel, RepositoryTreeModel } from '../models';
import { State } from './helpers/state';
import { GITHUB_DOMAINS, GITHUB_ENTERPRISE_DOMAIN } from '../constants';

export interface IRepositoriesService {
  repositoryTreeState: State;
  repositoryTree: RepositoryTreeModel;
  userOrganizationPreference: Array<OrganizationModel>;
  get: (hasAnyFilteringSelection: boolean) => Promise<void>;
  filter: (organisation: string, alias: string) => Promise<void>;
}

export class RepositoriesService implements IRepositoriesService {
  // Observables
  repositoryTreeState: State;
  repositoryTree: RepositoryTreeModel;
  userOrganizationPreference: Array<OrganizationModel> = [];

  // Internal
  private organization: string = '';
  private alias: string = '';

  constructor(private cdpApi: CDPApi, private gitHubApi: GitHubApi) {
    this.repositoryTreeState = new State();
    this.repositoryTree = new RepositoryTreeModel([], []);

    makeObservable(this, {
      repositoryTreeState: observable,
      repositoryTree: observable,
      userOrganizationPreference: observable,
      get: action,
      filter: action,
    });
  }

  // Actions
  async get(fetchUserPreference: boolean): Promise<void> {
    if (!this.repositoryTreeState.isLoaded) {
      this.repositoryTreeState.setLoading();

      try {
        const repositoriesResponse = await this.cdpApi.getRepositories();
        runInAction(() => {
          this.repositoryTree =
            RepositoryTreeModel.fromFullTree(repositoriesResponse);
        });

        if (fetchUserPreference) {
          const userOrganizationsResponse =
            await this.gitHubApi.fetchOrganizations();
          runInAction(() => {
            this.userOrganizationPreference = (
              userOrganizationsResponse?.data ?? []
            ).map(
              name => new OrganizationModel(GITHUB_ENTERPRISE_DOMAIN, name),
            );
          });
        }

        this.repositoryTreeState.setLoaded();
      } catch (e) {
        this.repositoryTreeState.setError();
      }
    }
  }

  async filter(organization: string, alias: string): Promise<void> {
    if (
      !this.repositoryTreeState.isLoaded ||
      this.organization !== organization ||
      this.alias !== alias
    ) {
      this.repositoryTreeState.setLoading();
      this.organization = organization;
      this.alias = alias;

      try {
        const domain = GITHUB_DOMAINS[alias];
        const response = await this.cdpApi.filterRepositories(
          domain,
          organization,
        );
        runInAction(() => {
          this.repositoryTree = RepositoryTreeModel.fromBranchTree(
            response ?? [],
            organization,
            domain,
          );
        });
        this.repositoryTreeState.setLoaded();
      } catch (e) {
        this.repositoryTreeState.setError();
      }
    }
  }
}
