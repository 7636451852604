import { IActivity } from '../../api/types/payload';
import { PodModel } from './pod.model';

export class DeploymentStatusModel {
  created_at: string;
  key: string;
  pods: PodModel[];

  constructor(json: IActivity = {} as IActivity) {
    this.created_at = json.created_at ?? '';
    this.key = json.key ?? '';
    this.pods = (json.pods ?? []).map(pod => new PodModel(pod));
  }
}
