import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

export const getObjectDiff = (a: any, b: any, skip?: string[]) => {
  return reduce(
    a,
    function diff(result: any, value: any, key: string) {
      if (skip) {
        return !skip.includes(key) && !isEqual(value, b[key])
          ? result.concat({ [key]: value })
          : result;
      }
      return isEqual(value, b[key]) ? result : result.concat({ [key]: value });
    },
    [],
  );
};
