import React from 'react';
import logo480 from './logo480.png';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { IResourceNotFound } from '../../api/interfaces/common';
import { reachOutLink } from '../tables/utils';
import { Link } from '@backstage/core-components';
import { EventTracker } from 'plugin-ui-components';

export const useNotFoundPageStyles = makeStyles({
  grid: {
    paddingTop: '2rem',
    gridColumn: '1/3',
    alignItems: 'center',
    justifyContent: 'space-around',

    '& > *': {
      textAlign: 'center',
    },
  },
});

export const ResourceNotFoundPage = ({ name, id }: IResourceNotFound) => {
  const classes = useNotFoundPageStyles();

  const resourceLinkPath = decodeURIComponent(window.location.pathname)
    /** Split by the invalid resource id and select the path before it */
    .split(id)[0]
    /** Remove trailing slash */
    .replace(/\/$/, '');

  return (
    <Grid container className={classes.grid}>
      <div>
        <Typography>
          We could not find any {name} with ID {id}
        </Typography>
        {`If you believe this is an error, please reach out to the `}
        <EventTracker interaction="onClick" {...reachOutLink('model-version')}>
          <Link
            to="https://github.bus.zalan.do/zai/issues/issues/new"
            color="primary"
          >
            ML Orchestration team.
          </Link>
        </EventTracker>
        <br />
        <br />
        <Button variant="outlined" color="default" href={resourceLinkPath}>
          Browse available {name}s
        </Button>
      </div>
      <img src={logo480} alt="Illustration on entity not found page" />
    </Grid>
  );
};
