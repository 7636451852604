import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  useTheme,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { zflowApiRef } from '../../api/zflowApiClient';
import { ProjectForm, useProjectForm } from '../../hooks/useProjectForm';
import { UpdateMLProjectResponse, UserTeam } from '../../api/definitions';
import { InputWrapper } from '../Forms/InputComponents/InputUtils';
import { v4 as uuidv4 } from 'uuid';
import { success } from '../common/alerts';
import { ProjectDescriptionInput } from '../Forms/InputComponents/ProjectDescriptionInput';
import { KPIInput } from '../Forms/InputComponents/KPIInput';
import { ButtonTheme } from '../common/customTheme';
import { PLACEHOLDER_USER_TEAM } from '../../api/PlaceholderResponses';
import { useTeamFilterBoxBorderStyles } from '../../styles';

export const UpdateProjectDialog = ({
  projectId,
  projectTeam,
  description,
  impacted_kpis,
  name,
  open,
  setOpen,
  setRefreshPage,
  admin,
}: {
  projectId: string;
  projectTeam: UserTeam | undefined;
  description: string | undefined;
  impacted_kpis: string[] | undefined;
  name: string | undefined;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
  admin: boolean | undefined;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const zflowApi = useApi(zflowApiRef);

  const initialFormState: ProjectForm = useMemo(() => {
    return {
      projectTeam: projectTeam,
      name: name || '',
      description: description || '',
      impacted_kpis: impacted_kpis || [],
    };
  }, [projectTeam, description, impacted_kpis, name]);
  const [form, setFormProperty] = useProjectForm(initialFormState);

  const [responseError, setResponseError] = useState<Array<string>>();

  const [selectedTeam, setSelectedTeam] = useState<UserTeam | undefined>(
    projectTeam,
  );

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);

  const [updatedProject, setUpdatedProject] =
    useState<UpdateMLProjectResponse>();

  const [userTeams, setUserTeams] = useState<UserTeam[]>([
    PLACEHOLDER_USER_TEAM,
  ]);

  const classes = useTeamFilterBoxBorderStyles();

  const handSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    zflowApi
      .updateProject(projectId, form)
      .then(data => {
        setUpdatedProject(data);
        setRefreshPage(true);
      })
      .catch(error => {
        setResponseError(error);
      });
  };

  useEffect(() => {
    setFormProperty(prevState => ({
      ...prevState,
      impacted_kpis: impacted_kpis || [],
      description: description || '',
      projectTeam: selectedTeam,
      team_id: selectedTeam?.team_id,
    }));
  }, [description, impacted_kpis, selectedTeam, setFormProperty]);

  useEffect(() => {
    if (admin) {
      zflowApi.getTeamsWithProject().then(teams => setUserTeams(teams));
    } else {
      zflowApi.getUserInfo().then(userInfo => setUserTeams(userInfo.teams));
    }
  }, [zflowApi, admin]);

  useEffect(() => {
    zflowApi.getProject(projectId).then(p => setSelectedTeam(p.team));
  }, [zflowApi, projectId]);

  useEffect(() => {
    function isValidFormData() {
      return (
        form.description.length >= 50 &&
        form.impacted_kpis.length >= 1 &&
        form.projectTeam
      );
    }
    setDisableSubmitButton(!isValidFormData());
  }, [form.description, form.impacted_kpis, form.projectTeam]);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <div>
      <form>
        {updatedProject && success('Successfully updated project')}
        <Dialog
          open={open}
          onClose={closeDialog}
          fullScreen={fullScreen}
          fullWidth
        >
          <DialogTitle id="dialog-title">Update {projectId}</DialogTitle>
          {responseError && (
            <InputWrapper>
              <Alert severity="error">
                <AlertTitle>Unable to update project</AlertTitle>
                {responseError && (
                  <ul>
                    {responseError.map(violation => {
                      return <li key={uuidv4()}>{violation}</li>;
                    })}
                  </ul>
                )}
              </Alert>
            </InputWrapper>
          )}
          <DialogContent>
            <div>
              <InputWrapper>
                <Autocomplete
                  autoHighlight
                  disableClearable
                  data-testid="create-project-autocomplete-select"
                  options={userTeams}
                  classes={classes}
                  getOptionLabel={option => option.nickname}
                  value={selectedTeam}
                  onChange={(_, selected: UserTeam) =>
                    setSelectedTeam(selected)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={selectedTeam}
                      label="Select Team"
                      variant="outlined"
                      margin="dense"
                      data-testid="create-project-autocomplete-input"
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <ProjectDescriptionInput
                  setForm={setFormProperty}
                  projectDescription={description}
                  label="Project Description"
                />
              </InputWrapper>
              <InputWrapper>
                <KPIInput
                  setForm={setFormProperty}
                  label="Project KPIs"
                  projectKPIs={impacted_kpis || []}
                />
              </InputWrapper>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="inherit"
              onClick={closeDialog}
              type="reset"
            >
              Cancel
            </Button>
            <ThemeProvider theme={ButtonTheme}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handSubmit}
                disabled={disableSubmitButton}
              >
                Update Project
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};
