import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadTestingPageWrapper } from './components/TestsTableComponent/LoadTestingPageWrapper';
import { TestDetailsPageWrapper } from './components/TestDetailsComponent/TestDetailsPageWrapper';

export const LoadTestingRouter = () => (
  <Routes>
    <Route path="/" element={<LoadTestingPageWrapper />} />
    <Route path=":testId" element={<TestDetailsPageWrapper />} />
  </Routes>
);
