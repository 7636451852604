import { createDescriptionColumn, createTimeColumn } from '../common';
import React, { useMemo } from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { ModelVersion } from '../../../../api/interfaces/model_version';
import { Box, Tooltip } from '@material-ui/core';
import { SpacedTagGroup } from '../../../../styles/common';
import * as S from '../../../../styles/table';
import { TextTag } from '../Tags';

export function createModelVersionNameColumn(): TableColumn<ModelVersion> {
  return {
    title: 'Name',
    field: 'name',
    sorting: true,
    render: (model_version: ModelVersion) => {
      return (
        <Box flex={1} margin={0} padding={0}>
          <div key={model_version.model_version_id}>
            <Box pt={2} pr={2} pl={1} key="model-item">
              <SpacedTagGroup>
                <Tooltip title="Model name">
                  <S.Text>{model_version.name}</S.Text>
                </Tooltip>
              </SpacedTagGroup>
            </Box>
          </div>
        </Box>
      );
    },
  };
}

export const useModelVersionColumns = () => {
  return useMemo(() => {
    return [
      createModelVersionNameColumn(),
      {
        title: 'Stage',
        field: 'stage',
        sorting: true,
        render: (model_version: ModelVersion) => {
          return (
            <div key={model_version.model_version_id}>
              <Box pt={2} pr={2} pl={1} key="model-item">
                <TextTag
                  text={model_version.stage}
                  title="Stage"
                  textTransform="upper"
                />
              </Box>
            </div>
          );
        },
      },
      {
        title: 'Source',
        field: 'source_uri',
        sorting: true,
        render: (model_version: ModelVersion) => {
          return (
            <div key={model_version.model_version_id}>
              <Box pt={2} pr={2} pl={1} key="model-item">
                <Link to={model_version.source_uri as string}>
                  {model_version.source_type}
                </Link>
              </Box>
            </div>
          );
        },
      },
      createTimeColumn(),
      createDescriptionColumn(),
    ];
  }, []);
};
