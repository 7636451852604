import { TrackedInteractionProps } from 'plugin-ui-components';

export function sideBarClick(name: string): TrackedInteractionProps {
  return {
    interaction: 'onClick',
    plugin: 'app',
    eventCategory: 'App main navigation',
    eventLabel: `User visited (${name}) using the main navigation`,
    eventAction: 'User clicked on a link in the main navigation',
  };
}
