import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import axios from 'axios';
import { CatalogAdditionalApiType } from './CatalogAdditionalApi';
import { stringifyEntityRef } from '@backstage/catalog-model';

type docHostApiType = {
  isUserCollaboratorOfDocRepo(domain: string): Promise<boolean>;
  deleteDocRepo(domain: string): Promise<boolean>;
  addDocCategory(domain: string, category: string): Promise<boolean>;
  deleteDocCategory(domain: string): Promise<boolean>;
  getDocCategories(): Promise<string[]>;
  convertCategoryNameToId(categoryName: string): Promise<string | undefined>;
};

export const docHostApiRef = createApiRef<docHostApiType>({
  id: 'plugin.catalog.dochost',
});

interface Apis {
  discoveryApi: DiscoveryApi;
  catalogAdditionalApi: CatalogAdditionalApiType;
}

export class DocHostApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly catalogAdditionalApi: CatalogAdditionalApiType;

  constructor(apis: Apis) {
    this.discoveryApi = apis.discoveryApi;
    this.catalogAdditionalApi = apis.catalogAdditionalApi;
  }

  async isUserCollaboratorOfDocRepo(domain: string): Promise<boolean> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/zalando-docs/delete/domain-paths/${domain}`;
    const response = await axios.get(serviceUrl, {
      withCredentials: true,
    });

    if (response.status !== 200) {
      return false;
    }
    return response.data.is_authorized;
  }

  async deleteDocRepo(domain: string): Promise<boolean> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/zalando-docs/delete/domain-paths/${domain}`;

    const response = await axios.delete(serviceUrl, {
      withCredentials: true,
    });
    return response.status === 204;
  }

  async addDocCategory(domain: string, category: string): Promise<boolean> {
    // Convert the category name to a category ID to be set in Docs-hosting
    const categoryId = await this.convertCategoryNameToId(category);
    if (!categoryId) return false;

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/zalando-docs/delete/domain-paths/${domain}/categories`;
    const body = `{"category": "${categoryId}"}`;

    const response = await axios.put(serviceUrl, body, {
      withCredentials: true,
    });
    if (response.status !== 204) {
      return false;
    }
    const ref = stringifyEntityRef({
      namespace: 'default',
      kind: 'Documentation',
      name: domain,
    });
    await this.catalogAdditionalApi.refreshEntity(ref, 5);
    return true;
  }

  async deleteDocCategory(domain: string): Promise<boolean> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/zalando-docs/delete/domain-paths/${domain}/categories`;

    const response = await axios.delete(serviceUrl, {
      withCredentials: true,
    });
    if (response.status !== 204) {
      return false;
    }
    const ref = stringifyEntityRef({
      namespace: 'default',
      kind: 'Documentation',
      name: domain,
    });
    await this.catalogAdditionalApi.refreshEntity(ref, 5);
    return true;
  }

  async getDocCategories(): Promise<string[]> {
    const techDocsUrl = await this.discoveryApi.getBaseUrl('techdocs');
    const serviceUrl = `${techDocsUrl}/docs/categories`;

    const response = await axios.get(serviceUrl, {
      withCredentials: true,
    });
    if (response.status !== 200) {
      return [];
    }
    return response.data.categories as string[];
  }

  async convertCategoryNameToId(
    categoryName: string,
  ): Promise<string | undefined> {
    const techDocsUrl = await this.discoveryApi.getBaseUrl('techdocs');
    const serviceUrl = `${techDocsUrl}/docs/categories/${categoryName}`;

    const response = await axios.get(serviceUrl, {
      withCredentials: true,
    });
    if (response.status !== 200) {
      return undefined;
    }
    return response.data.categoryId;
  }
}
