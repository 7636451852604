import React from 'react';
import { useSearchContext } from '../SearchContext';
import { SearchBar } from 'plugin-ui-components';
import { useStyles } from '../styles';

export function SearchField() {
  const styles = useStyles();
  const { query, setQuery } = useSearchContext();

  return (
    <div className={styles.searchField}>
      <SearchBar
        value={query}
        onChange={ev => setQuery(ev.target.value)}
        size="small"
        id="search-input"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </div>
  );
}
