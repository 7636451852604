import React, { useEffect, useState } from 'react';
import {
  Content,
  EmptyState,
  Table,
  TableColumn,
} from '@backstage/core-components';
import {
  Select,
  MenuItem,
  Button,
  Grid,
  ThemeProvider,
} from '@material-ui/core';
import {
  MLPipelineRun,
  MLPipelineRunMetricsParams,
} from '../../api/definitions';
import { ButtonTheme } from '../common/customTheme';
import {
  getMetricValueCell,
  getMetricValueCellStyle,
} from '../common/columns/utils';

export const RunMetricsAndParams = ({
  run,
  loading,
}: {
  run: MLPipelineRun;
  loading: boolean;
}) => {
  const { metrics, params } = run;
  const [selectedKey, setSelectedKey] = useState<string>('all');
  const [tableData, setTableData] = useState<MLPipelineRunMetricsParams[]>([]);
  const filterOptions = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Metrics',
      value: 'metric',
    },
    {
      label: 'Params',
      value: 'param',
    },
  ];

  useEffect(() => {
    metrics.map(metric => {
      metric.type = 'metric';
    });
    params.map(param => {
      param.type = 'param';
    });
    const paramsAndMetrics = [...metrics, ...params];

    if (selectedKey === 'all') {
      setTableData(paramsAndMetrics);
    } else {
      const filteredData = paramsAndMetrics.filter(
        paramsAndMetric => paramsAndMetric.type === selectedKey,
      );
      setTableData(filteredData || []);
    }
  }, [metrics, params, selectedKey]);

  const columns: TableColumn[] = [
    {
      title: 'Type',
      field: 'type',
      filtering: true,
      filterComponent: _ => {
        return (
          <Select
            onChange={ev => setSelectedKey(ev.target.value as string)}
            value={selectedKey || 'all'}
            variant="outlined"
            margin="dense"
            fullWidth
            style={{ maxWidth: 200 }}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              getContentAnchorEl: null,
            }}
          >
            {filterOptions.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Name',
      field: 'key',
      filtering: false,
    },
    {
      title: 'Value',
      field: 'value',
      filtering: false,
      render(row: any): any {
        return getMetricValueCell(row);
      },
      cellStyle: (_, rowData: { is_average?: boolean }) => {
        return getMetricValueCellStyle(rowData);
      },
    },
  ];

  return (
    <Content>
      <Grid>
        {selectedKey === 'all' && !tableData.length ? (
          <div>
            <EmptyState
              missing="data"
              title="No metrics or params logged"
              description="You have not added any metrics or params to this run. To learn more about adding metrics and params to runs, check out the zflow docs."
              action={
                <ThemeProvider theme={ButtonTheme}>
                  <Button
                    color="primary"
                    href="https://ml-platform.docs.zalando.net/zflow/howtos.html#return-output-from-lambda-functions"
                    data-testid="metrics-params-empty"
                    variant="contained"
                  >
                    ZFLOW DOCS
                  </Button>
                </ThemeProvider>
              }
            />
          </div>
        ) : (
          <Table
            title="Params and Metrics"
            data={tableData}
            columns={columns}
            isLoading={loading}
            options={{
              filtering: true,
              showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
            }}
          />
        )}
      </Grid>
    </Content>
  );
};
