import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const personalizationPlugin = createPlugin({
  id: 'personalization',
});

export const PersonalizationPage = personalizationPlugin.provide(
  createComponentExtension({
    name: 'PersonalizationPage',
    component: {
      lazy: () =>
        import('./components/Personalization').then(m => m.PersonalizationPage),
    },
  }),
);
