import React from 'react';
import { Text } from '../../../../../../common';
import 'style-loader!css-loader!sass-loader!./styles.scss';

type Props = {
  message: string;
};

export const ErrorMessage = ({ message }: Props) => {
  return (
    <div id="scalyr-logs">
      <div className="error-wrapper">
        <Text>Something went wrong, refresh the page to try again.</Text>
        <br />
        <br />
        <Text>More info: </Text>
        <Text error breakAll flexWrap="wrap">
          {message}
        </Text>
      </div>
    </div>
  );
};
