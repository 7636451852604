import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import JSONTree from 'react-json-tree';
import { useServices } from '../../../../services';
import { TERMINAL_COLORS } from '../../../../utils/colors';

export const DeliveryConfig = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <Box bgcolor={TERMINAL_COLORS.background} p={1} flex={1}>
      <JSONTree
        data={run.delivery_configuration}
        collectionLimit={1000}
        sortObjectKeys
        shouldExpandNode={() => true}
        invertTheme={false}
        theme={{ base00: TERMINAL_COLORS.background }}
      />
    </Box>
  );
});
