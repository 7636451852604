import {
  Box,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import DocsIcon from '@material-ui/icons/MenuBook';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import cn from 'classnames';
import { EventTracker, TrackedLink } from 'plugin-ui-components';
import React from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { useSearchLocalStorage } from '../../common';
import * as Tracking from '../../common/tracking';
import { HotPages } from '../SearchModal/HotPages';
import { useStyles } from './styles';

type TopSearchTerm = { term: string; docsUrl: string };

const TOP_SEARCH_TERMS: Array<TopSearchTerm> = [
  { term: 'nakadi', docsUrl: '/docs/default/Documentation/nakadi' },
  { term: 'zmon', docsUrl: '/docs/default/Documentation/visibility/zmon/' },
  {
    term: 'podman',
    docsUrl:
      '/docs/default/Documentation/cloud/tutorials/docker-desktop-alternatives/',
  },
  { term: 'octopus', docsUrl: 'https://experimentation.docs.zalando.net/' },
  {
    term: 'superset',
    docsUrl: '/docs/default/Documentation/superset/',
  },
];

const RecentSearchItem = ({
  term,
  removeTerm,
  setParams,
}: {
  term: string;
  removeTerm: (term: string) => void;
  setParams: SetURLSearchParams;
}) => {
  const [hover, setHover] = React.useState(false);
  const styles = useStyles({});
  return (
    <EventTracker {...Tracking.clickRecentSearches(term)}>
      <ListItem
        button
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setParams({ term })}
      >
        <ListItemIcon>
          <HistoryIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{term}</ListItemText>
        <ListItemSecondaryAction
          className={cn(styles.itemAction, { show: hover })}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Tooltip title="Remove from recent searches" placement="top" arrow>
            <IconButton
              edge="end"
              aria-label="delete"
              color="secondary"
              onClick={() => removeTerm(term)}
            >
              <RemoveCircleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </EventTracker>
  );
};

const TopSearchItem = ({
  term,
  docsUrl,
  index,
  setParams,
}: {
  term: string;
  docsUrl: string;
  index: number;
  setParams: SetURLSearchParams;
}) => {
  const styles = useStyles({});

  return (
    <EventTracker key={term} {...Tracking.clickTopSearches(term)}>
      <ListItem button onClick={() => setParams({ term })}>
        <ListItemIcon style={{ fontSize: '1rem' }}>{index + 1}.</ListItemIcon>
        <ListItemText>{term}</ListItemText>
        <ListItemSecondaryAction
          className={cn(styles.itemAction, { show: !!docsUrl })}
        >
          <TrackedLink
            to={docsUrl}
            target="_blank"
            {...Tracking.clickTopSearchesDocs(term)}
          >
            <Tooltip title="Open documentation" placement="top" arrow>
              <IconButton edge="end" aria-label="documentation" color="primary">
                <DocsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </TrackedLink>
        </ListItemSecondaryAction>
      </ListItem>
    </EventTracker>
  );
};

export const EmptyState = () => {
  const { searchLocalStorage, removeTermLocalStorage } =
    useSearchLocalStorage();
  const [_, setParams] = useSearchParams();

  return (
    <Grid item lg={10} xl={8} style={{ margin: '0 auto' }}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr));"
        justifyContent="center"
        alignItems="middle"
        gridGap={24}
        margin="0 auto"
      >
        <Card>
          <Box
            display="flex"
            alignItems="center"
            color="#fff"
            bgcolor="var(--clr-warning-main)"
            paddingY={1}
            paddingX={2}
          >
            <Typography variant="body1">
              <strong>Your recent searches</strong>
            </Typography>
          </Box>
          <List dense>
            {!searchLocalStorage?.recentSearchTerms?.length && (
              <ListItem>
                <ListItemText>
                  Your most recent search terms will appear here 😉
                </ListItemText>
              </ListItem>
            )}
            {searchLocalStorage?.recentSearchTerms
              .slice(0, 5)
              .map((term: string) => (
                <RecentSearchItem
                  key={term}
                  term={term}
                  removeTerm={removeTermLocalStorage}
                  setParams={setParams}
                />
              ))}
          </List>
        </Card>
        <Card>
          <Box
            display="flex"
            alignItems="center"
            color="#fff"
            bgcolor="var(--clr-warning-light)"
            paddingY={1}
            paddingX={2}
          >
            <Typography variant="body1">
              <strong>Top searches</strong>
            </Typography>
          </Box>
          <List dense>
            {TOP_SEARCH_TERMS.map(
              ({ term, docsUrl }: TopSearchTerm, index: number) => (
                <TopSearchItem
                  index={index}
                  key={term}
                  term={term}
                  docsUrl={docsUrl}
                  setParams={setParams}
                />
              ),
            )}
          </List>
        </Card>
      </Box>
      <Box marginTop={3}>
        <HotPages />
      </Box>
    </Grid>
  );
};
