import React from 'react';
import { observer } from 'mobx-react-lite';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { Checkbox, Input, Select, FieldProps } from './Fields';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warnings: {
      color: theme.palette.warning.main,
    },
    errors: {
      color: theme.palette.error.main,
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: theme.spacing(1, 0, 0, 0),
    },
    field: {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const Field = observer(({ field, ...props }: FieldProps) => {
  const classes = useStyles();

  const getComponent = () => {
    switch (field.fieldType) {
      case 'boolean':
        return <Checkbox {...{ field, ...props }} />;
      case 'select':
        return <Select {...{ field, ...props }} />;
      case 'string':
      default:
        return <Input {...{ field, ...props }} />;
    }
  };

  return (
    <div className={classes.field}>
      {getComponent()}
      {!!field.warnings.length && (
        <ul className={`${classes.list} ${classes.warnings}`}>
          {field.warnings.map((warning: string, index: number) => (
            <li key={index}>{warning}</li>
          ))}
        </ul>
      )}
      {!!field.errors.length && (
        <ul className={`${classes.list} ${classes.errors}`}>
          {field.errors.map((error: string, index: number) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
});
