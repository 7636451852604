import { DateTime } from 'luxon';
import { WebStorage } from '@backstage/core-app-api';
import {
  createApiRef,
  DiscoveryApi,
  ErrorApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  Announcement,
  AnnouncementsList,
  Category,
  CreateAnnouncementRequest,
  CreateCategoryRequest,
  UpdateAnnouncementRequest,
} from '@k-phoen/backstage-plugin-announcements-common';
import { FetchApi } from '@backstage/core-plugin-api';

const lastSeenKey = 'user_last_seen_date';

export interface AnnouncementsApi {
  announcements(opts: {
    max?: number;
    page?: number;
    category?: string;
  }): Promise<AnnouncementsList>;

  announcementByID(id: string): Promise<Announcement>;

  createAnnouncement(request: CreateAnnouncementRequest): Promise<Announcement>;

  updateAnnouncement(
    id: string,
    request: UpdateAnnouncementRequest,
  ): Promise<Announcement>;

  deleteAnnouncementByID(id: string): Promise<void>;

  categories(): Promise<Category[]>;

  createCategory(request: CreateCategoryRequest): Promise<void>;

  lastSeenDate(): DateTime;

  markLastSeenDate(date: DateTime): void;
}

export const announcementsApiRef = createApiRef<AnnouncementsApi>({
  id: 'plugin.announcements.service',
});

type Options = {
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;
  errorApi: ErrorApi;
  fetchApi: FetchApi;
};

export class DefaultAnnouncementsApi implements AnnouncementsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;
  private readonly webStorage: WebStorage;
  private readonly fetchApi: FetchApi;

  constructor(opts: Options) {
    this.discoveryApi = opts.discoveryApi;
    this.oauth2Api = opts.oauth2Api;
    this.webStorage = new WebStorage('announcements', opts.errorApi);
    this.fetchApi = opts.fetchApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('announcements');
    const accessToken = await this.oauth2Api.getAccessToken();

    const headers: HeadersInit = new Headers(init?.headers);
    if (accessToken && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    // New Announcements backend version mount his routes under "/announcements" path, so we need to call "/announcements/announcements" endpoint
    return this.fetchApi
      .fetch(`${baseApiUrl}/announcements${input}`, {
        ...init,
        headers,
      })
      .then(async response => {
        if (!response.ok) {
          throw await ResponseError.fromResponse(response);
        }

        return response.json() as Promise<T>;
      });
  }

  private async delete(input: string, init?: RequestInit): Promise<void> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('announcements');
    const accessToken = await this.oauth2Api.getAccessToken();

    const headers: HeadersInit = new Headers(init?.headers);
    if (accessToken && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    // New Announcements backend version mount his routes under "/announcements" path, so we need to call "/announcements/announcements" endpoint
    return this.fetchApi
      .fetch(`${baseApiUrl}/announcements${input}`, {
        ...{ method: 'DELETE' },
        headers,
      })
      .then(async response => {
        if (!response.ok) {
          throw await ResponseError.fromResponse(response);
        }
      });
  }

  async announcements({
    max,
    page,
    category,
  }: {
    max?: number;
    page?: number;
    category?: string;
  }): Promise<AnnouncementsList> {
    const params = new URLSearchParams();
    if (category) {
      params.append('category', category);
    }
    if (max) {
      params.append('max', max.toString());
    }
    if (page) {
      params.append('page', page.toString());
    }

    return this.fetch<AnnouncementsList>(`/?${params.toString()}`);
  }

  async announcementByID(id: string): Promise<Announcement> {
    return this.fetch<Announcement>(`/${id}`);
  }

  createAnnouncement(
    request: CreateAnnouncementRequest,
  ): Promise<Announcement> {
    return this.fetch<Announcement>(`/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  updateAnnouncement(
    id: string,
    request: UpdateAnnouncementRequest,
  ): Promise<Announcement> {
    return this.fetch<Announcement>(`/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  async deleteAnnouncementByID(id: string): Promise<void> {
    return this.delete(`/${id}`, { method: 'DELETE' });
  }

  async categories(): Promise<Category[]> {
    return this.fetch<Category[]>('/categories');
  }

  async createCategory(request: CreateCategoryRequest): Promise<void> {
    await this.fetch<Category>(`/categories`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  lastSeenDate(): DateTime {
    const lastSeen = this.webStorage.get<string>(lastSeenKey);
    if (!lastSeen) {
      // magic default date, probably enough in the past to consider every announcement as "not seen"
      return DateTime.fromISO('1990-01-01');
    }

    return DateTime.fromISO(lastSeen);
  }

  markLastSeenDate(date: DateTime): void {
    this.webStorage.set<string>(lastSeenKey, date.toISO() as string);
  }
}
