import React from 'react';
import { Route, Routes } from 'react-router';
import { DependenciesTab } from './components/DependenciesTab';
import { LibrariesTab } from './components/LibraryPage';

export const DependenciesRouter = () => (
  <Routes>
    <Route path="/" element={<DependenciesTab />} />
  </Routes>
);

export const DependenciesStandAloneRouter = () => (
  <Routes>
    <Route path="/" element={<LibrariesTab />} />
  </Routes>
);
