import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { OnboardingApi, onboardingApiRef } from './api';
import { oauth2ApiRef } from 'plugin-core';

export const settingsOnboardingPlugin = createPlugin({
  id: 'settings-onboarding',
  apis: [
    createApiFactory({
      api: onboardingApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new OnboardingApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});

export const SettingsOnboardingPage = settingsOnboardingPlugin.provide(
  createComponentExtension({
    name: 'SettingsOnboardingPage',
    component: {
      lazy: () => import('./components/Onboarding').then(m => m.OnboardingPage),
    },
  }),
);
