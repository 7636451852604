import { useState } from 'react';
import { UserTeam } from '../api/definitions';
import { SPECIAL_CHARACTERS } from '../constants/common';

export type ProjectForm = {
  projectTeam?: UserTeam;
  name: string;
  description: string;
  team_id?: string;
  impacted_kpis: Array<string>;
};

export const useProjectForm = (initState: ProjectForm) => {
  return useState<ProjectForm>(initState);
};

export function isValidFormData(
  name: string,
  description: string,
  impacted_kpis: string[],
) {
  return (
    name?.length > 3 &&
    !SPECIAL_CHARACTERS.test(name) &&
    description?.length >= 50 &&
    impacted_kpis?.length > 0
  );
}
