export type Subscriptions = {
  id: string;
  owning_application: string;
  authorization?: {
    readers: {
      data_type: string;
      value: string;
    }[];
  };
};

export const deserializeSubscriptions = ({
  id,
  owning_application,
  authorization,
}: Subscriptions): Subscriptions => ({ id, owning_application, authorization });

export type SubscriptionStat = {
  event_type: string;
  partitions: {
    partition: string;
    unconsumed_events?: number;
    consumer_lag_seconds: number;
  }[];
};
