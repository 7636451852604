import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Grid, useTheme } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { Content, Table } from '@backstage/core-components';

import {
  GetPipelineRunsResponse,
  MLPipelineRun,
  MLPipelineRunMetricsParams,
  RunStatus,
} from '../../api/definitions';
import { PLACEHOLDER_PIPELINE_RUNS } from '../../api/PlaceholderResponses';
import { zflowApiRef } from '../../api/zflowApiClient';
import { DEFAULT_SELECT_OPTION } from '../../constants/common';
import {
  SMALL_PAGE_SIZE,
  MAX_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { tableHeaderStyles, useTeamFilterBoxBorderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { getRunListColumns } from '../common/columns/RunListColumns';
import RunMetricsPlot from './RunMetricsPlot/RunMetricsPlot';

export const RunList = ({
  pipelineId,
  projectId,
}: {
  pipelineId: string;
  projectId: string;
}) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();
  const classes = useTeamFilterBoxBorderStyles();
  const [selectedFilter, setSelectedFilter] = useState<{
    selectedMetric: string | undefined;
    selectedParam: string | undefined;
    selectedStatus: string | undefined;
  }>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(SMALL_PAGE_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();

  const [tableData, setTableData] = useState<{
    runs: GetPipelineRunsResponse;
    availableMetricsParamsStatus:
      | {
          metrics: MLPipelineRunMetricsParams[];
          params: MLPipelineRunMetricsParams[];
          statuses: RunStatus[];
        }
      | undefined;
  }>({
    availableMetricsParamsStatus: undefined,
    runs: PLACEHOLDER_PIPELINE_RUNS,
  });

  const filterRuns = useCallback(
    (runs: MLPipelineRun[]) => {
      const filterConditions: any = {};

      if (
        selectedFilter?.selectedMetric &&
        selectedFilter?.selectedMetric !== DEFAULT_SELECT_OPTION
      ) {
        filterConditions.metrics = [{ key: selectedFilter?.selectedMetric }];
      }
      if (
        selectedFilter?.selectedParam &&
        selectedFilter?.selectedParam !== DEFAULT_SELECT_OPTION
      ) {
        filterConditions.params = [{ key: selectedFilter?.selectedParam }];
      }
      if (
        selectedFilter?.selectedStatus &&
        selectedFilter.selectedStatus !== DEFAULT_SELECT_OPTION
      ) {
        filterConditions.status = selectedFilter.selectedStatus;
      }
      return filter(runs, { ...filterConditions });
    },
    [
      selectedFilter?.selectedMetric,
      selectedFilter?.selectedParam,
      selectedFilter?.selectedStatus,
    ],
  );

  function sortRuns(runsResponse: GetPipelineRunsResponse) {
    return orderBy(
      runsResponse.runs,
      [(run: MLPipelineRun) => new Date(run?.last_modified_at ?? '')],
      ['desc'],
    );
  }

  const setupRuns = useCallback(
    (runsResponse: GetPipelineRunsResponse) => {
      const runsTableData: any = {};

      runsTableData.availableMetricsParamsStatus = {
        metrics: uniqBy(
          flatten(
            runsResponse.runs.map(currentRun => {
              return currentRun.metrics;
            }),
          ),
          'key',
        ),
        params: uniqBy(
          flatten(
            runsResponse.runs.map(currentRun => {
              return currentRun.params;
            }),
          ),
          'key',
        ),
        statuses: runsResponse.runs.map(currentRun => {
          return currentRun.status;
        }),
      };

      const sortedRuns = sortRuns(runsResponse);

      if (!selectedFilter) {
        runsTableData.runs = {
          meta: {
            total: runsResponse.meta.total,
          },
          runs: sortedRuns,
        };
      } else {
        const filteredRuns = filterRuns(sortedRuns);

        runsTableData.runs = {
          meta: {
            total: runsResponse.meta.total,
          },
          runs: filteredRuns,
        };
      }
      setIsLoading(false);
      setTableData(runsTableData);
    },
    [filterRuns, selectedFilter],
  );

  useEffect(() => {
    const offset = rowsPerPage * page;
    zflowApi.getPipelineRuns(pipelineId, rowsPerPage, offset).then(setupRuns);
  }, [zflowApi, pipelineId, page, selectedFilter, setupRuns, rowsPerPage]);

  useEffect(() => {
    setIsLoading(true);
  }, [selectedFilter]);

  const columns = getRunListColumns(
    tableData.availableMetricsParamsStatus,
    selectedFilter,
    setSelectedFilter,
    classes,
  );

  return (
    <Content>
      <RunMetricsPlot runs={tableData.runs.runs} />
      <Grid item xs>
        <Table
          title="Runs"
          data={tableData.runs.runs}
          columns={columns}
          options={{
            pageSize: MAX_PAGE_SIZE,
            filtering: true,
            search: false,
            showFirstLastPageButtons: true,
            emptyRowsWhenPaging: false,
            headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
          }}
          onRowClick={(_, rowData: any) => {
            navigate(
              `/ml/projects/${projectId}/pipelines/${pipelineId}/runs/${rowData.run_id}`,
            );
          }}
          isLoading={isLoading}
          components={{
            Pagination: props => (
              <MLTablePagination
                props={props}
                page={page}
                resource={tableData.runs}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              />
            ),
            Toolbar: props => <MLTableToolbar props={props} />,
          }}
        />
      </Grid>
    </Content>
  );
};
