import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useParams } from 'react-router';
import { Tabs, Tab } from '../../../../common';

import * as S from './styles';
import { Header } from '../Header';
import { runRoutes } from '../../../../router';
import { useServices } from '../../../../services';
import { FeatureFlagIcon } from 'plugin-ui-components';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { GRAPH_FEATURE_FLAG } from '../../../../constants';

export const RunOverview = observer(() => {
  const { repo_name } = useParams();
  const { runService, metaService } = useServices();
  const featureFlags = useApi(featureFlagsApiRef);

  useEffect(() => {
    metaService.setTitle(
      `${
        runService.run.build_version
          ? runService.run.build_version
          : runService.run.id
      } | ${repo_name}`,
    );
  }, [metaService, runService, repo_name]);

  return (
    <S.RunOverview>
      <S.Header>
        <Header />
      </S.Header>

      <S.Content>
        <Tabs>
          <Tab to=".">Details</Tab>
          <Tab to={`./${runRoutes.deliveryConfig}`}>Delivery configuration</Tab>
          {featureFlags.isActive(GRAPH_FEATURE_FLAG) && (
            <Tab to={`./${runRoutes.pipeline}`}>
              <span>Pipeline graph&nbsp;</span>
              <FeatureFlagIcon name="cdp-pipeline-graph" />
            </Tab>
          )}
        </Tabs>

        <Outlet />
      </S.Content>
    </S.RunOverview>
  );
});
