import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { EventType } from '../../domain/EventTypes';
import {
  deserializeSubscriptions,
  Subscriptions,
  SubscriptionStat,
} from '../../domain/Subscriptions';
import { http } from '../http-client';
import { Partition } from './types';

export interface NakadiApiGateway {
  getEventTypesByWriter(applicationName: String): Promise<EventType[]>;
  getEventTypesByOwningApp(applicationName: String): Promise<EventType[]>;
  getSubscriptionsByReader(applicationName: String): Promise<Subscriptions[]>;
  getSubscriptionsByOwningApp(
    applicationName: String,
  ): Promise<Subscriptions[]>;
  getPartitions(eventType: string): Promise<Partition[]>;
  getSubscriptionStats(subscriptionId: string): Promise<SubscriptionStat[]>;
}

type SubscriptionsResponse = {
  _links: {
    next?: {
      href: string;
    };
  };
};

type ItemsResponse<T> = {
  items: T[];
};
export class NakadiApiGatewayImpl implements NakadiApiGateway {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getEventTypesByWriter(applicationName: String) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<EventType[]>(
      `${proxyUrl}/nakadi/api/event-types?writer=service:${applicationName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }

  async getEventTypesByOwningApp(applicationName: String) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<EventType[]>(
      `${proxyUrl}/nakadi/api/event-types?owning_application=${applicationName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }

  async getSubscriptionsByReader(applicationName: String) {
    return (await this.getAllSubscriptions(applicationName)).map(
      deserializeSubscriptions,
    );
  }

  async getSubscriptionsByOwningApp(applicationName: String) {
    return (await this.getAllSubscriptions(undefined, applicationName)).map(
      deserializeSubscriptions,
    );
  }

  async getPartitions(eventType: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return await http<Partition[]>(
      `${proxyUrl}/nakadi/api/event-types/${eventType}/partitions`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      },
    );
  }

  async getSubscriptionStats(subscriptionId: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    return (
      await http<ItemsResponse<SubscriptionStat>>(
        `${proxyUrl}/nakadi/api/subscriptions/${subscriptionId}/stats?show_time_lag=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      )
    ).items;
  }

  private async getAllSubscriptions(
    reader: String = '',
    owningApp: String = '',
  ): Promise<Subscriptions[]> {
    let queries = ['offset=0', 'limit=1000'];
    if (reader) {
      queries = [...queries, `reader=service:${reader}`];
    }
    if (owningApp) {
      queries = [...queries, `owning_application=${owningApp}`];
    }
    const nakadiApiCall = async (
      path: string = `/subscriptions?${queries.join('&')}`,
      initialSubscriptions: Subscriptions[] = [],
    ): Promise<Subscriptions[]> => {
      const token = await this.oauth2Api.getAccessToken();
      const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

      return http<SubscriptionsResponse & ItemsResponse<Subscriptions>>(
        `${proxyUrl}/nakadi/api${path}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      ).then(response => {
        if (response?._links?.next?.href) {
          return nakadiApiCall(response._links.next.href, [
            ...initialSubscriptions,
            ...response.items,
          ]);
        }
        return [...initialSubscriptions, ...response.items];
      });
    };
    return nakadiApiCall();
  }
}
