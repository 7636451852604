import { useState, useEffect } from 'react';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';

enum PluginThemeName {
  light = 'light',
  dark = 'dark',
}

const useBrowserColorSchemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: any) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
};

const usePluginTheme = (): PluginThemeName => {
  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = appThemeApi.getActiveThemeId();
  const usesDarkScheme = useBrowserColorSchemeDetector();

  if (activeThemeId) {
    return activeThemeId as PluginThemeName;
  }
  return usesDarkScheme ? PluginThemeName.dark : PluginThemeName.light;
};

export { usePluginTheme };
