import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { ContributorsTable } from './ContributorsTable';
import { ContributingTeamsTable } from './ContributingTeamsTable';

export function DomainPeople() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper>
          <ContributingTeamsTable />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <ContributorsTable />
        </Paper>
      </Grid>
    </Grid>
  );
}
