import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { InfoTooltip, TeamReference } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function OwningTeam({ value, issue }: FieldProps<'team_id'>) {
  const catalogApi = useApi(catalogApiRef);

  const { value: teamEntity, loading } = useAsync(() => {
    if (value) {
      return catalogApi.getEntityByRef({
        kind: 'Group',
        namespace: 'default',
        name: value,
      });
    }
    return Promise.resolve({ value: '' });
  }, [catalogApi, value]);
  const team = teamEntity as IEntityGroup;

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  return (
    <>
      <dt>Owning team</dt>
      <dd>
        {team && (
          <>
            <TeamReference team={team} format="nameOnly" />

            {team.spec?.zalando?.type && ` (${team.spec?.zalando?.type} team)`}

            {team.spec?.fullName && `, ${team.spec.fullName}`}

            {!!team.spec?.mail?.length && (
              <>
                ,&nbsp;
                <Link to={`mailto:${team.spec.mail[0]}`} target="_blank">
                  {team.spec.mail[0]}
                </Link>
              </>
            )}
            <InfoTooltip>
              The responsible and accountable team for this application. Must be
              a valid tech team ID.
            </InfoTooltip>
          </>
        )}
        <Issue {...issue} />
      </dd>
    </>
  );
}
