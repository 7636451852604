import React from 'react';
import { useSearchContext } from '../../SearchContext';
import { searchConfig } from '../../config';
import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import type {
  ESSearchHit,
  ESSearchResponse,
  GCSSearchHit,
  GCSSearchResponse,
  SearchResult,
} from '../../types';
import { Pagination } from '@material-ui/lab';
import {
  exportPageToOffset,
  getPaginationCount,
  getSearchPage,
} from '../../utils';
import { useMediaMobile } from 'plugin-ui-components';
import { useStyles } from '../../styles';
import { SearchResultCard } from './SearchResultCard';
import { EmptyState } from '../EmptyState';
import FishSvg from './Fish.svg';
import { SearchConfigForGCS } from '../../models';
import { SingleStepPager } from '../SingleStepPager';

interface SearchResultsContentProps {
  results?: SearchResult;
  loading: boolean;
  error?: Error;
}

export function SearchResultsContent({
  results,
  loading,
  error,
}: SearchResultsContentProps) {
  const styles = useStyles();
  const isMobile = useMediaMobile();
  const { tab, offset, setOffset, limit, query, retrySearch } =
    useSearchContext();
  const config =
    searchConfig.indexes[
      searchConfig.indexes.findIndex(i => i.isMatchingQuery(tab))
    ];
  if (loading) return <LinearProgress />;
  if (error) {
    return (
      <Alert severity="error">
        <Box display="flex" justifyContent="space-between">
          <Typography>Error: {error?.message}</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={retrySearch}
          >
            Retry
          </Button>
        </Box>
      </Alert>
    );
  }
  if (!(query && results && config)) return <EmptyState />;

  function getTotal(): number {
    const resultFromGCS = results as GCSSearchResponse;
    const resultFromES = results as ESSearchResponse;
    switch (true) {
      case resultFromGCS.hasOwnProperty('resultCountExact'):
        return +(resultFromGCS.resultCountExact ?? '0');
      case resultFromGCS.hasOwnProperty('resultCountEstimate'):
        return +(resultFromGCS.resultCountEstimate ?? '0');
      case resultFromES.hasOwnProperty('total'):
        if (typeof resultFromES.total === 'number') {
          return resultFromES.total ?? 0;
        }
        return resultFromES.total?.value ?? 0;
      default:
        return 0;
    }
  }

  const total = getTotal();

  function NoResults() {
    return (
      <div className={styles.noResults}>
        <img src={FishSvg} alt="No results found" />
        <Typography variant="subtitle1">
          No results found matching your query
        </Typography>
      </div>
    );
  }

  const hits: GCSSearchHit[] | ESSearchHit[] = results.hasOwnProperty('hits')
    ? (results as ESSearchResponse).hits ?? []
    : (results as GCSSearchResponse).results ?? [];

  const hasNoResults =
    !total && !results.hasOwnProperty('hasMoreResults') && !hits.length;

  if (hasNoResults) return <NoResults />;

  if (!hits.length) {
    setOffset(0);
    return <NoResults />;
  }

  function localizeUrl(url: string) {
    if (url.startsWith('https://sunrise.zalando.net')) {
      return url.replace('https://sunrise.zalando.net', '');
    }
    return url;
  }

  const Component = config.render;

  return (
    <>
      <div className={styles.searchResultsWrapper}>
        {hits?.map((hit, i) => {
          const title = config.getTitle && config.getTitle(hit);
          const url = localizeUrl((config.getUrl && config.getUrl(hit)) ?? '');
          return (
            <SearchResultCard
              title={title}
              url={url}
              key={i}
              icon={
                config.getTitleIcon
                  ? (config.getTitleIcon(hit) as any)
                  : config.icon
              }
              accentColor={config.accentColor}
            >
              <Component {...hit} />
            </SearchResultCard>
          );
        })}
      </div>
      <Box display="flex" justifyContent="center" paddingY={2}>
        {(config as SearchConfigForGCS).isPredefinedSource ? (
          <SingleStepPager
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            hasMoreResults={
              (results as GCSSearchResponse).hasMoreResults || false
            }
          />
        ) : (
          <Pagination
            count={getPaginationCount(total, limit)}
            page={getSearchPage(offset, limit)}
            onChange={(_, val) => setOffset(exportPageToOffset(val, limit))}
            siblingCount={isMobile ? 0 : undefined}
          />
        )}
      </Box>
    </>
  );
}
