import React, { ReactNode } from 'react';
import { useStyles } from '../styles';
import Children from 'react-children-utilities';
import cn from 'classnames';
import Typography from '@mui/material/Typography';

interface DataListItem {
  label: ReactNode;
  value: ReactNode;
}

interface Props {
  list: DataListItem[];
  multiColumn?: boolean;
  dense?: boolean;
}

export function ResultDataList({ list, multiColumn, dense }: Props) {
  const styles = useStyles();

  if (!list?.length) return <></>;
  if (list.every(item => !item.value)) return <></>;

  const longestLabel = list.reduce((acc, item) => {
    if (!item.label) return acc;
    const labelLength = Children.onlyText(item.label).toString().length;
    return labelLength > acc ? labelLength : acc;
  }, 0);
  const minWidth = `${longestLabel * 1.25}ch`;

  return (
    <dl
      className={cn(styles.resultDatalist, {
        'multi-column': multiColumn,
        dense,
      })}
    >
      {list.map((item, i) => {
        if (!item.value) return null;

        return (
          <div key={i} style={{ minWidth, whiteSpace: 'nowrap' }}>
            <Typography variant="body2">
              {item.label}: {item.value}
            </Typography>
          </div>
        );
      })}
    </dl>
  );
}
