import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { useApi } from '@backstage/core-plugin-api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  CircularProgress,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { RadioSelect, SectionRadio } from '../common';
import { ReviewContext } from '../context';

export function Alerts() {
  const { entity } = useEntity();
  const app = useApi(applicationReviewApiRef);
  const { review } = React.useContext(ReviewContext);

  const { value, loading } = useAsync(() =>
    app.getAlerts(entity.metadata.name),
  );

  return (
    <section>
      <Typography>
        Every production application must be alarmed (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ). ZMON OpsGenie alerts must be linked to applications (
        <code>
          notify_opsgenie(teams="…", application='${entity?.metadata?.name}'
        </code>
        ), see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/visibility/zmon/notifications.html#opsgenie"
        >
          docs <OpenInNewIcon />
        </Link>
        ). For each alert, please confirm that it is reviewed and working
        ("Yes") or wrong/outdated (answer "No").
      </Typography>

      <Typography variant="h3">
        ZMON Alerts with Email, Google Chat or OpsGenie Notification
      </Typography>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <CircularProgress />
      ) : value?.length ? (
        value.map(alert => (
          <RadioSelect
            required={review.alerts_ok !== 'n/a'}
            disabled={review.alerts_ok === 'n/a'}
            name={`alert_zmon_${alert.id}_url`}
          >
            <Link to={alert.url}>{alert.name}</Link>
            <FormHelperText>
              (priority {alert.priority}, team {alert.team})
            </FormHelperText>
          </RadioSelect>
        ))
      ) : (
        <Typography>
          No ZMON alerts with Email, Google Chat or OpsGenie notification linked
          to application <em>"{entity.metadata.name}"</em> found.
          <InfoTooltip>
            Please use the "application" argument for "notify_opsgenie" (
            <code>
              notify_opsgenie(teams="…", application='
              {entity.metadata.name}')
            </code>
            ) and fall back to tagging the alert with
            <code>app:test-application-sunrise</code> if you don't have 24/7
            on-call and OpsGenie. Only alerts with Email, Google Chat or
            OpsGenie notification will be considered in this section.
          </InfoTooltip>
        </Typography>
      )}

      <Typography variant="h3">Other Alerts</Typography>
      <RadioSelect
        required={review.alerts_ok !== 'n/a'}
        disabled={review.alerts_ok === 'n/a'}
        name="alert_adaptive_paging"
      />
      <RadioSelect
        required={review.alerts_ok !== 'n/a'}
        disabled={review.alerts_ok === 'n/a'}
        name="alert_lightstep_custom"
      />

      <SectionRadio name="alerts_ok" />
    </section>
  );
}
