/** Mobile regex taken from http://detectmobilebrowsers.com/ */
const mobileRegex = new RegExp(
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/,
  'i',
);

/** Returns true if device is mobile */
export function isMobileDevice(): boolean {
  return mobileRegex.test(navigator.userAgent);
}
