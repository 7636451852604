import { Theme, withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';

export const StyledToggleButton = withStyles((theme: Theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey.A200,
    },
  },
}))(ToggleButton);
