import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { EmptyTable } from '../tables/ResourceTable';
import { Table, TableColumn } from 'plugin-ui-components';
import { ModelArtifact } from '../../api/interfaces/model_artifact';
import * as S from '../../styles/table';
import { createMTableTimeColumn } from '../tables/columns/common';
import { UserTag } from '../tables/columns/Tags';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';

const buildS3LinkFromArnOrUri = (input: string) => {
  const arnMatch = input.match(/^arn:aws:s3:::([^/]+)\/(.+)$/);
  if (arnMatch) {
    const [, bucket, key] = arnMatch;
    return `https://s3.console.aws.amazon.com/s3/buckets/${bucket}?prefix=${key}`;
  }
  const uriMatch = input.match(/^s3:\/\/([^\/]+)\/(.+)$/);
  if (uriMatch) {
    const [, bucket, key] = uriMatch;
    return `https://s3.console.aws.amazon.com/s3/buckets/${bucket}?prefix=${key}`;
  }
  return input;
};

const getFilenameFromUriOrArn = (input: string) => {
  const s3UriMatch = input.match(/^s3:\/\/[^\/]+\/(.+)/);
  const s3ArnMatch = input.match(/^arn:aws:s3:::[^\/]+\/(.+)/);
  return (
    s3UriMatch?.[1].split('/').pop() ||
    s3ArnMatch?.[1].split('/').pop() ||
    input
  );
};

export const Artifacts = ({ artifacts }: { artifacts: ModelArtifact[] }) => {
  const columns = [
    {
      children: 'URI',
      field: 'uri',
      sorting: true,
      render: (modelArtifact: ModelArtifact) => (
        <Box flex={1} margin={0} padding={0}>
          <div key={modelArtifact.uri} style={{ width: '100%' }}>
            <S.Title to={buildS3LinkFromArnOrUri(modelArtifact.uri)}>
              {getFilenameFromUriOrArn(modelArtifact.uri)}
            </S.Title>
          </div>
        </Box>
      ),
    },
    createMTableTimeColumn() as TableColumn<ModelArtifact>,
    {
      children: 'Created By',
      field: 'created_by',
      sorting: true,
      render: (modelArtifact: ModelArtifact) => (
        <Box flex={1} margin={0} padding={0}>
          <div key={modelArtifact.model_artifact_id} style={{ width: '100%' }}>
            <UserTag user={modelArtifact.created_by.uid} />
          </div>
        </Box>
      ),
    },
  ];

  return (
    <Paper variant="elevation" className="full-width">
      <Table<ModelArtifact>
        style={{
          width: '100%',
          overflow: 'hidden',
          tableLayout: 'fixed',
        }}
        header={
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4">
                Artifacts ({artifacts.length})
              </Typography>
            </Box>
          </Box>
        }
        columns={columns}
        options={{
          showSearch: false,
          pageSize: DEFAULT_PAGE_SIZE,
          pagination: false,
          emptyNode: <EmptyTable error={null} entity="artifact" />,
        }}
        stickyHeader
        data={artifacts as any}
      />
    </Paper>
  );
};
