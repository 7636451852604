import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    padding: '10px',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  title: {
    fontSize: '0.85rem',
    marginRight: '10px',
    color: theme.palette.text.primary,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end',
    flexFlow: 'row wrap',
  },
  filter: {
    '& div': {
      fontSize: '.8rem',
    },

    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  linkTitle: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    fontSize: '.85rem',
    marginBottom: '4px',
    color: theme.palette.text.hint,

    ['&, &:visited']: {
      color: theme.palette.text.primary,
    },

    ['&:hover, &:active']: {
      color: theme.palette.link,
    },
  },
  text: {
    maxWidth: '100%',
    margin: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '.85rem',

    ['& a:hover']: {
      color: theme.palette.link,
    },
  },
  resourceList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  resourceHeader: {
    display: 'none',
    padding: '0',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    borderBottom: `2px solid ${theme.palette.divider}`,
    borderTop: `2px solid ${theme.palette.divider}`,

    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1.5fr .5fr .5fr .6fr 1fr .5fr',
      gridGap: '5px',
      alignItems: 'flex-start',
    },
  },
  button: {
    width: '100%',
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  filterWrapper: {
    borderRadius: 4,
  },
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
