import { styled, IconButton, Dialog as MuiDialog } from '@material-ui/core';

export const CloseButton = styled(IconButton)(props => ({
  position: 'absolute',
  right: props.theme.spacing(0.5),
  top: props.theme.spacing(0.5),
  color: props.theme.palette.grey[500],
}));

export const Dialog = styled(MuiDialog)(() => ({
  minWidth: 350,
  // zIndex is automatically set as an inline style.
  zIndex: '9999 !important' as any,
}));
