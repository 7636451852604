import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryParams } from './useQueryParams';

export const useFilters = (
  setLibraryOptions: Dispatch<
    SetStateAction<{ limit: number; name: string[]; version: string[] }>
  >,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const initialLibs = queryParams.get('name')?.split(',') || [];
  const initialVersions = queryParams.get('version')?.split(',') || [];

  const [filteredLibs, setFilteredLibs] = useState<string[]>(initialLibs);
  const [filteredVersions, setFilteredVersions] =
    useState<string[]>(initialVersions);

  useQueryParams({
    queryParams,
    filteredLibs,
    filteredVersions,
  });

  const onClearFilters = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setFilteredLibs([]);
    setFilteredVersions([]);
    navigate('', { replace: true });
    window.history.replaceState({}, '', '');

    setLibraryOptions(prevOptions => ({
      ...prevOptions,
      name: [],
      version: [],
    }));

    queryParams.delete('name');
    queryParams.delete('version');
  };

  const handleLibsFilter = (value: string[]) => {
    setFilteredLibs(value);

    if (!value.length) {
      queryParams.set('name', '');
      return;
    }

    queryParams.set('name', value.join(','));
  };

  const handleVersionFilter = (versions: string[]) => {
    setFilteredVersions(versions);

    if (versions.length) {
      queryParams.set('version', versions.join(','));
    } else {
      queryParams.set('version', '');
      if (filteredLibs.length) {
        queryParams.set('name', filteredLibs.join(','));
      } else {
        queryParams.set('name', '');
      }
    }
  };

  useEffect(() => {
    if (filteredLibs.length || filteredVersions.length) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        name: filteredLibs,
        version: filteredVersions,
      }));
    }
  }, [filteredLibs, filteredVersions, setLibraryOptions]);

  return {
    filteredLibs,
    filteredVersions,
    onClearFilters,
    handleLibsFilter,
    handleVersionFilter,
  };
};
