import React from 'react';
import { API } from './API';
import { CardContent } from '@material-ui/core';
import { SummaryCard } from '../common';

export function APISummary() {
  const category = 'API';
  return (
    <SummaryCard category={category}>
      <CardContent>
        <API hideConfirmationSection />
      </CardContent>
    </SummaryCard>
  );
}
