import { ISecret } from '../../api/types/payload';

export class SecretModel {
  domain: string;
  repo_id: string;
  name: string;
  version: string;
  created_by: string;
  org: string;
  repo_name: string;
  available_in_pull_requests: boolean;

  constructor(json: ISecret = {} as ISecret) {
    this.domain = json.domain ?? '';
    this.repo_id = json.repo_id ?? '';
    this.name = json.name ?? '';
    this.version = json.version ?? '';
    this.created_by = json.created_by ?? '';
    this.org = json.org ?? '';
    this.repo_name = json.repo_name ?? '';
    this.available_in_pull_requests = json.available_in_pull_requests ?? false;
  }
}
