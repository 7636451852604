import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { removeDuplicates, sanitizePackageName } from '../utils';
import { IRepositoryVulnerability } from '../../api';

type FilterState = {
  severity: string[];
  name: string[];
};

type FiltersProps = {
  vulnerabilities: IRepositoryVulnerability[];
  handlePackageFilter: (
    value?: IRepositoryVulnerability[],
    severity?: string[],
    name?: string[],
  ) => void;
};

const useStyles = makeStyles<Theme, {}>((theme: Theme) => ({
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export const Filters: React.FunctionComponent<FiltersProps> = ({
  vulnerabilities,
  handlePackageFilter,
}) => {
  const { inputRoot } = useStyles();
  const [filterState, setFilterState] = useState<FilterState>({
    name: [],
    severity: [],
  });

  const handleFilter = (name: string[], severity: string[]) => {
    const noFilters = !severity.length && !name.length;
    const onlyNameFilter = !severity.length && name.length;
    const onlySeverityFilter = severity.length && !name.length;
    const combinedFilters = severity.length && name.length;

    if (noFilters) {
      handlePackageFilter([], [], []);
      return;
    }

    if (onlyNameFilter) {
      const filtered = vulnerabilities.filter(item =>
        name.includes(sanitizePackageName(item.packageName)),
      );
      handlePackageFilter(filtered, filterState.severity, name);
      return;
    }

    if (onlySeverityFilter) {
      const filtered = vulnerabilities.filter(item =>
        severity.includes(item.severity.toLowerCase()),
      );
      handlePackageFilter(filtered, severity, filterState.name);
      return;
    }

    if (combinedFilters) {
      const filtered = vulnerabilities
        .filter(item => severity.includes(item.severity.toLowerCase()))
        .filter(item => name.includes(sanitizePackageName(item.packageName)));
      handlePackageFilter(filtered, severity, name);
      return;
    }
  };

  const handleNameChange = (value: string[]) => {
    setFilterState({ ...filterState, name: value });
    handleFilter(value, filterState.severity);
  };

  const handleSeverityChange = (value: string[]) => {
    setFilterState({ ...filterState, severity: value });
    handleFilter(filterState.name, value);
  };

  const handleClearFilters = () => {
    setFilterState({ name: [], severity: [] });
    handlePackageFilter([], [], []);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="1.5rem"
      gridRowGap="0.5rem"
      padding="0.5rem"
      style={{ borderRadius: 4 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        flexWrap="wrap"
      >
        <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
          FILTERS
        </Typography>
        <Button
          onClick={handleClearFilters}
          style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
          variant="text"
        >
          Clear
        </Button>
        <FormControl style={{ minWidth: '100%' }}>
          <Autocomplete
            id="vulnerabilities-filter"
            multiple
            classes={{ inputRoot }}
            options={removeDuplicates(
              vulnerabilities?.map((item: { packageName: string }) =>
                sanitizePackageName(item?.packageName),
              ),
            )}
            value={removeDuplicates(filterState?.name?.map(item => item)) || []}
            onChange={(_: any, value) => handleNameChange(value)}
            renderInput={params => (
              <TextField
                label="Package Name"
                {...params}
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </FormControl>
        <FormControl style={{ minWidth: '100%' }}>
          <Autocomplete
            id="severity-filter"
            multiple
            classes={{ inputRoot }}
            options={removeDuplicates(
              vulnerabilities?.map((item: { severity: string }) =>
                item?.severity?.toLowerCase(),
              ),
            )}
            value={
              removeDuplicates(
                filterState?.severity?.map(item => item.toLowerCase()),
              ) || []
            }
            onChange={(_: any, value) => handleSeverityChange(value)}
            renderInput={params => (
              <TextField
                label="Severity"
                {...params}
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
