import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  MintStorageApi,
  mintStorageApiRef,
  EssentialsApi,
  essentialsApiRef,
  clusterRegistryApiRef,
  ClusterRegistryApi,
  teamsApiRef,
  TeamsApi,
} from './api';

export const applicationReviewPlugin = createPlugin({
  id: 'plugin-application-registry',
  apis: [
    createApiFactory({
      api: mintStorageApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new MintStorageApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
    createApiFactory({
      api: essentialsApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new EssentialsApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
    createApiFactory({
      api: clusterRegistryApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ClusterRegistryApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
    createApiFactory({
      api: teamsApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new TeamsApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});
