import React, { useEffect, useState } from 'react';
import { Table } from '@backstage/core-components';
import { useNavigate } from 'react-router';
import { TablePagination, useTheme } from '@material-ui/core';
import { NO_OF_ROWS_PER_PAGE } from '../../config';
import { tableHeaderStyles } from 'plugin-ml-platform';
import { Warning } from '../Warning';

interface Column {
  title: string;
  field?: string;
  filtering?: boolean;
  render?: (row: any) => React.ReactNode;
  width?: string;
}

interface ListEntitiesProps {
  getEntitiesPromise: Promise<any>;
  entityKey: string;
  columns: Column[];
  childEntityKey: string;
  title?: string;
  refreshList?: boolean;
  page?: number;
  onPageChange: (pageNumber: number) => void;
  skipNavigateOnClick?: boolean;
  error?: Error;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

export const ListEntities = (props: ListEntitiesProps) => {
  const {
    getEntitiesPromise,
    entityKey,
    columns,
    childEntityKey,
    title,
    refreshList,
    page,
    onPageChange,
    skipNavigateOnClick,
    error,
    setError,
  } = props;
  const [entities, setEntities] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    async function getEntities() {
      const res = await getEntitiesPromise;
      setTotalCount(res.meta.total);
      setEntities(res[entityKey]);
    }

    setLoading(true);
    getEntities().finally(() => setLoading(false));
  }, [getEntitiesPromise, entityKey, columns, childEntityKey, refreshList]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => {
    event.persist();
    onPageChange(newPage);
  };

  const filtering: boolean = columns.reduce(
    (acc: boolean, cur: any) => acc || cur.filtering,
    false,
  );
  return (
    <>
      {error?.name ? (
        <Warning
          name={error.name}
          message={error.message}
          setError={setError}
        />
      ) : null}
      <Table
        title={title || ''}
        isLoading={loading}
        options={{
          filtering,
          search: false,
          draggable: false,
          pageSize: NO_OF_ROWS_PER_PAGE,
          emptyRowsWhenPaging: false,
          headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
        }}
        data={entities}
        columns={columns.map(x => ({ filtering: false, ...x }))}
        onRowClick={(event, rowData: any) => {
          event?.stopPropagation();
          if (!skipNavigateOnClick) {
            const urlToNavigate = childEntityKey
              ? `${rowData.id || rowData.run_id}/${childEntityKey}`
              : rowData.id || rowData.run_id;
            navigate(urlToNavigate);
          }
        }}
        components={{
          Pagination: (paginationProps: any) => (
            <TablePagination
              {...paginationProps}
              page={page}
              rowsPerPageOptions={[NO_OF_ROWS_PER_PAGE]}
              count={totalCount}
              onPageChange={handleChangePage}
            />
          ),
          Toolbar: () => null,
        }}
      />
    </>
  );
};
