import { Entity } from '@backstage/catalog-model';
import { getPropertyValueFromObject } from './getPropertyValueFromObject';

/* Injects the string with the entity properties */
export function injectEntityProperties(str: string, entity: IEntity | Entity) {
  let formattedUrl = str;
  // find and replace all ${} in the url with entity properties
  const matches = str.match(/\${(.*?)}/g) ?? [];
  matches.forEach(match => {
    const property = match.replace('${', '').replace('}', '');
    formattedUrl = formattedUrl.replace(
      match,
      getPropertyValueFromObject(entity, property),
    );
  });
  return formattedUrl;
}
