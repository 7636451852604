import { Theme, styled } from '@material-ui/core';
import { STATUS_COLORS } from '../../utils/colors';

type StyledProps = {
  theme: Theme;
  color?: string;
  variant?: 'contained' | 'outlined';
};

export const Tag = styled('div')(
  ({
    theme,
    color = STATUS_COLORS.default,
    variant = 'contained',
  }: StyledProps) => ({
    position: 'relative',
    display: 'inline-block',
    letterSpacing: '0.6px',
    borderRadius: '3px',
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    transition: 'color 0.3s ease-in-out',
    background: 'transparent',
    fontWeight: 600,
    lineHeight: '35px',
    fontSize: '15px',
    padding: '0 14px',
    textTransform: 'lowercase',
    border: '1px solid transparent',
    flexGrow: 0,
    flexShrink: 0,

    ...(variant === 'contained' && {
      color: theme.palette.background.paper,
      backgroundColor: color,
    }),

    ...(variant === 'outlined' && {
      color: color,
      borderColor: color,
    }),
  }),
);

export const TagMedium = styled(Tag)({
  lineHeight: '22px',
  fontSize: '13px',
  padding: '0 6px',
});

export const TagSmall = styled(Tag)({
  lineHeight: '17px',
  fontSize: '11px',
  padding: '0 5px',
});
