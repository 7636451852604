import { CompoundEntityRef } from '@backstage/catalog-model';
import { Config } from '@backstage/config';
import {
  DiscoveryApi,
  IdentityApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import {
  SyncResult,
  TechDocsStorageApi,
} from '@backstage/plugin-techdocs-react';

/**
 * API which talks to TechDocs storage to fetch files to render.
 * TODO: Refactor to utilise the new Storage Definition instead of custom implementation
 */
export class ZalandoDocsStorageApi implements TechDocsStorageApi {
  configApi: Config;
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  private readonly oauth2Api: OAuthApi;

  constructor({
    configApi,
    discoveryApi,
    oauth2Api,
    identityApi,
  }: {
    configApi: Config;
    discoveryApi: DiscoveryApi;
    oauth2Api: OAuthApi;
    identityApi: IdentityApi;
  }) {
    this.configApi = configApi;
    this.discoveryApi = discoveryApi;
    this.oauth2Api = oauth2Api;
    this.identityApi = identityApi;
  }

  async getStorageUrl(): Promise<string> {
    return await this.getApiOrigin();
  }

  async syncEntityDocs(_entityId: CompoundEntityRef): Promise<SyncResult> {
    return 'cached';
  }

  async getBuilder() {
    return this.configApi.getString('techdocs.builder');
  }

  /**
   * Fetch HTML content as text for an individual docs page in an entity's docs site.
   *
   * @param entityId Object containing entity data like name, namespace, etc.
   * @param path The unique path to an individual docs page e.g. overview/what-is-new
   * @returns HTML content of the docs page as string
   * @throws Throws error when the page is not found.
   */
  async getEntityDocs(
    entityId: CompoundEntityRef,
    path: string,
  ): Promise<string> {
    const { kind, namespace, name } = entityId;
    const accessToken = await this.oauth2Api.getAccessToken();
    let url = `${await this.getApiOrigin()}/docs/${namespace}/${kind}/${name}/${path}`;

    if (!url.endsWith('.html')) {
      url = `${url.endsWith('/') ? url : `${url}/`}index.html`;
    }

    const request = await fetch(url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    if (request.status === 404) {
      let errorMessage = 'Page not found. ';
      // path is empty for the home page of an entity's docs site
      if (!path) {
        errorMessage +=
          'This could be because there is no index.md file in the root of the docs directory of this repository.';
      }
      throw new Error(errorMessage);
    }

    return request.text();
  }

  async getBaseUrl(
    oldBaseUrl: string,
    entityId: CompoundEntityRef,
    path: string,
  ): Promise<string> {
    const { kind, namespace, name } = entityId;

    return new URL(
      oldBaseUrl,
      `${await this.getApiOrigin()}/docs/${namespace}/${kind}/${name}/${path}`,
    ).toString();
  }

  async getApiOrigin(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('techdocs');
  }
}
