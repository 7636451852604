import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../api';
import { useAsync } from 'react-use';

export function useLatestPipeline(
  domain: string,
  org: string,
  repo: string,
  branch: string,
  event: string,
  status: string,
) {
  const cdpAPI = useApi(cdpApiRef);

  const {
    loading,
    error,
    value: pipeline,
  } = useAsync(async () => {
    const response = await cdpAPI.getLatestPipeline(
      domain,
      org,
      repo,
      branch,
      event,
      status,
    );

    if (!response.pipelines[0]) return null;
    return {
      id: response.pipelines[0].id,
      build_version: response.pipelines[0].build_version,
    };
  }, []);

  return {
    loading,
    pipeline,
    error,
  };
}
