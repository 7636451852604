import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { SectionRadio } from '../common';
import { PlaybooksList } from './PlaybooksList';

export function Playbooks() {
  return (
    <section>
      <Typography>
        Every production application with on-call must have a playbook
        (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ). Playbooks must be linked to affected applications, see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/incident/documentation/playbook-guidance/"
        >
          Playbook Guidance <OpenInNewIcon />
        </Link>
        . For each listed playbook, please confirm that it is reviewed and
        working ("Yes") or wrong/outdated (answer "No").
      </Typography>

      <br />
      <PlaybooksList />

      <SectionRadio name="playbooks_ok" />
    </section>
  );
}
