import { EventTypePublishingStats } from '../gateway/NakadiReporterGateway/types';

export type EventType = {
  name: string;
  owning_application: string;
  authorization?: {
    writers: {
      data_type: string;
      value: string;
    }[];
    admins: {
      data_type: string;
      value: string;
    }[];
  };
  cleanup_policy?: 'delete' | 'compact' | 'compact_and_delete';
};

export function isNakadiSQLAdminOf(eventType: EventType): boolean {
  return Boolean(
    eventType.authorization?.admins?.find(
      auth => auth.value === 'stups_nakadi-sql',
    ),
  );
}

export type EventTypeStats = {
  costs: number;
  partition_count: number;
} & EventTypePublishingStats;
