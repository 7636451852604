import { createContext, Dispatch, SetStateAction } from 'react';

export interface IAccessControlContext {
  appName: string;
  appData: IAccessControl.ApplicationType[];
  loadAppData: () => void;
  editState: IAccessControl.EditState;
  setEditState: Dispatch<SetStateAction<IAccessControl.EditState>>;
  hasEditPermission: boolean | undefined;
}

export const AccessControlContext = createContext<IAccessControlContext>({
  appName: '',
  appData: [] as IAccessControl.ApplicationType[],
  loadAppData: () => {},
  editState: {
    appScopes: false,
    kubernetesClusters: false,
    oAuthClient: false,
    credentialDistribution: false,
    resourceTypeCreatePage: false,
    resourceTypeEditPage: false,
    resourceTypeScopeCreatePage: false,
    resourceTypeScopeEditPage: false,
  },
  setEditState: () => {},
  hasEditPermission: false,
});
