import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { FormControl, TextField } from '@material-ui/core';

type LibrariesFilterProps = {
  handleLibsFilter: (value: any) => void;
  filteredLibs: string[];
  inputRoot: string;
};

// to call api to get all names
const MockedLibsNames = [
  'accepts',
  'react',
  'react-dom',
  'react-router',
  'react-router-dom',
  'react-redux',
  'redux',
];

export const LibrariesFilter: React.FunctionComponent<LibrariesFilterProps> = ({
  handleLibsFilter,
  filteredLibs,
  inputRoot,
}) => {
  const uniqueValues = new Set<string>();

  MockedLibsNames.forEach(lib => {
    uniqueValues.add(lib);
  });
  const names = Array.from(uniqueValues);

  return (
    <FormControl style={{ minWidth: '100%' }}>
      <Autocomplete
        id="libraries-filter"
        multiple
        classes={{ inputRoot }}
        options={names}
        value={filteredLibs}
        onChange={(_: any, value) => handleLibsFilter(value)}
        renderInput={params => (
          <TextField
            label="Library"
            {...params}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
};
