import { computed, observable, makeObservable, action } from 'mobx';
import { addMinutesToNanoTimestamps } from '../../utils/time';
import { LogsLinesModel } from './lines.model';

export enum LogsFoldStatusEnum {
  Queued,
  Finished,
  InProgress,
}

export class LogsFoldModel extends LogsLinesModel {
  public currentEventTimestamp: number;
  public error: string;
  public manualModeEnabled: boolean;
  public isPriority: boolean;

  constructor(
    public name: string,
    public firstEventTimestamp: number,
    public lastEventTimestamp: number,
    public status: LogsFoldStatusEnum,
    public isOpen: boolean,
  ) {
    super();

    this.error = '';
    this.currentEventTimestamp = this.firstEventTimestamp;
    this.manualModeEnabled = false;
    this.isPriority = this.isOpen;

    makeObservable(this, {
      status: observable,
      lastEventTimestamp: observable,
      error: observable,
      isOpen: observable,
      isPriority: observable,
      toggleOpen: action,
      close: action,
      shouldPoll: computed,
    });
  }

  update = (lastEventTimestamp: number, status: LogsFoldStatusEnum) => {
    this.lastEventTimestamp = lastEventTimestamp;
    this.status = status;
  };

  public get shouldPoll() {
    return (
      this.isOpen &&
      (this.linesState.isClean ||
        this.linesState.isLoaded ||
        this.linesState.isRecoverable) &&
      this.hasPendingLines
    );
  }

  public get hasPendingLines(): boolean {
    return (
      this.lines.length === 0 ||
      this.lines[this.lines.length - 1].timestamp < this.lastEventTimestamp
    );
  }

  shouldFetch = () => {
    return (
      this.isOpen &&
      (this.linesState.isClean ||
        this.linesState.isLoaded ||
        this.linesState.isRecoverable) &&
      this.lines.length === 0
    );
  };

  public toggleOpen = () => {
    this.manualModeEnabled = true;
    this.isOpen = !this.isOpen;
  };

  public close = () => {
    this.manualModeEnabled = true;
    this.isOpen = false;
  };

  public get getFromTimestamp(): number {
    if (this.lines.length > 0) {
      return this.lines[this.lines.length - 1].timestamp;
    }

    return addMinutesToNanoTimestamps(this.firstEventTimestamp, -1);
  }
}
