export const matchType =
  (type: string, message?: string) => (value: string | boolean) => {
    return typeof value === type
      ? undefined
      : message ?? `Must match type ${type}`;
  };

export const matchPattern = (pattern: string) => (value: string | boolean) => {
  if (typeof value === 'boolean') return undefined;

  if (value !== undefined) {
    return new RegExp(pattern).test(value)
      ? undefined
      : `Must follow pattern: ${pattern}`;
  }

  return undefined;
};
