import React from 'react';
import cn from 'classnames';
import { LinkButton, LinkButtonProps } from '@backstage/core-components';
import { useStyles } from './styles';

export function CardFooterLink(props: LinkButtonProps) {
  const styles = useStyles();

  return (
    <LinkButton
      variant="text"
      disableRipple
      className={cn(styles.showAllBtn, props.className)}
      data-testid="card-footer-link"
      {...props}
    >
      {props.children}
    </LinkButton>
  );
}
