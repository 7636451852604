import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { Pill } from '../../styles';
import { FieldProps } from '../types';

export function Status({ value, issue }: FieldProps<'active'>) {
  return (
    <>
      <dt>Status</dt>
      <dd>
        <Pill label={value ? 'Active' : 'Inactive'} data-success={value} />
        <InfoTooltip>
          Whether the application is "active" or not. Inactive applications
          cannot be deployed to production clusters and cannot acquire OAuth
          credentials.
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
