import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select, MenuItem } from '@material-ui/core';
import { TableColumn } from '@backstage/core-components';
import { DeploymentEnvironment, RunStatus } from '../../../api/definitions';
import { RunStatusBadge } from '../../Runs/RunStatusBadge';
import { getStatusOptions, getDeploymentEnvironmentOptions } from './utils';

export const getPipelineListColumns = (
  runStatuses: RunStatus[] | undefined,
  selectedStatus: string,
  setSelectedStatus: React.Dispatch<any>,
  deploymentEnvironments: DeploymentEnvironment[] | undefined,
  selectedDeploymentEnvironment: string | undefined,
  setSelectedDeploymentEnvironment: React.Dispatch<any>,
): TableColumn[] => {
  const statusOptions = getStatusOptions(runStatuses);

  const deploymentEnvironmentsOptions = getDeploymentEnvironmentOptions(
    deploymentEnvironments,
  );

  function getEnvironmentName(environmentId: string) {
    const environment = deploymentEnvironments?.find(env => {
      return env.id === environmentId;
    });
    return environment?.name || '';
  }

  return [
    {
      title: 'Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Deployment Environment',
      field: 'deployment_environment',
      filterComponent: _ => {
        return (
          <div data-testid="environment-filter">
            <Select
              key={uuidv4()}
              value={selectedDeploymentEnvironment}
              onChange={ev => setSelectedDeploymentEnvironment(ev.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
              style={{ maxWidth: 200 }}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                getContentAnchorEl: null,
              }}
            >
              {deploymentEnvironmentsOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      },
      render(row: any): React.ReactNode {
        return getEnvironmentName(row.deployment_environment);
      },
    },
    {
      title: 'Last Run Status',
      field: 'last_run.status',
      filterComponent: _ => {
        return (
          <div data-testid="run-status-filter">
            <Select
              key={uuidv4()}
              value={selectedStatus}
              onChange={ev => setSelectedStatus(ev.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
              style={{ maxWidth: 200 }}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                getContentAnchorEl: null,
              }}
            >
              {statusOptions.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      },
      render(row: any): React.ReactNode {
        return <RunStatusBadge status={row.last_run?.status} />;
      },
    },
  ];
};
