import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { rootRouteRef } from './routes';
import { modelsApiRef, ModelService } from './api/services/models';
import {
  applicationResourceApiRef,
  ApplicationResourceService,
} from './api/services/application';

const pluginDeps = {
  oauth2Api: oauth2ApiRef,
  discoveryApi: discoveryApiRef,
  storageApi: storageApiRef,
};

export const mlPlugin = createPlugin({
  id: 'ml',
  featureFlags: [{ name: 'ml-platform' }],
  apis: [
    createApiFactory({
      api: modelsApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new ModelService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: applicationResourceApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new ApplicationResourceService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const MlPage = mlPlugin.provide(
  createRoutableExtension({
    name: 'MlPage',
    component: () =>
      import('./components/pages/ModelsPage').then(m => m.ModelsPage),
    mountPoint: rootRouteRef,
  }),
);
