import React, { useState } from 'react';
import { AboutField } from 'plugin-ui-components';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { MarkdownContent } from '@backstage/core-components';

export interface AboutFieldWithDialogProps {
  label: string;
  description: string;
  wordLimit?: number;
}

const AboutFieldWithDialog = ({
  label,
  description,
  wordLimit = 100,
}: AboutFieldWithDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const descriptionWords = description ? description.split(' ') : [];

  return (
    <AboutField label="Description" xs={12}>
      <Box display="flex" flexDirection="column">
        {descriptionWords.length <= wordLimit
          ? description
          : `${descriptionWords.slice(0, wordLimit).join(' ')}... `}
        {descriptionWords.length > wordLimit && (
          <Typography
            component="span"
            variant="body2"
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={handleOpen}
          >
            Read all
          </Typography>
        )}
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <MarkdownContent content={description} />
          </Typography>
        </DialogContent>
      </Dialog>
    </AboutField>
  );
};

export default AboutFieldWithDialog;
