import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  Chip,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link, Table, TableColumn } from '@backstage/core-components';
import { mintStorageApiRef } from '../../../api';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { EventTracker, EventTrackerProps } from 'plugin-ui-components';

type ScopeDetailsDrawerProps = {
  scope: {
    id: string;
    summary: string;
    resourceId: string;
  };
};

const trackingInfo: EventTrackerProps = {
  plugin: 'application-registry',
  eventCategory: 'Access Control',
  eventAction: 'click',
  interaction: 'onClick',
  eventLabel: '',
};

const columns: TableColumn<IEntityApp>[] = [
  {
    title: 'Name',
    field: 'metadata.name',
    defaultSort: 'asc',
    render: ({ metadata }) => (
      <EventTracker
        {...trackingInfo}
        eventLabel="Application details on list of apps using scope"
      >
        <Link to={`/catalog/default/Component/${metadata.name}`}>
          {metadata.name}
        </Link>
      </EventTracker>
    ),
  },
  {
    title: 'Owning Team',
    field: 'spec.spec.owner_name',
    defaultSort: 'asc',
    render: ({ spec }) => (
      <EventTracker
        {...trackingInfo}
        eventLabel="Application owner on list of apps using scope"
      >
        <Link to={`/catalog/default/group/${spec.owner}`}>
          {spec.spec.owner_name}
        </Link>
      </EventTracker>
    ),
  },
  {
    title: 'Criticality',
    field: 'spec.lifecycle',
    defaultSort: 'asc',
    render: ({ spec }) => (
      <div className="flex-wrapped">
        {spec.lifecycle && (
          <Chip
            className="info-chip"
            label={spec.lifecycle}
            size="small"
            variant="outlined"
          />
        )}
        {spec.spec.incident_contact && (
          <Tooltip title="Has 24/7 support">
            <Chip
              className="info-chip"
              label="24/7"
              size="small"
              variant="outlined"
            />
          </Tooltip>
        )}
      </div>
    ),
  },
];

const ScopeDetails: FunctionComponent<ScopeDetailsDrawerProps> = ({
  scope,
}) => {
  const catalogApi = useApi(catalogApiRef);
  const mintApi = useApi(mintStorageApiRef);
  const [rows, setRows] = useState<Array<IEntityApp>>([]);
  const [isTableLoading, setTableLoading] = useState<boolean>(true);

  // Get applications extra information from catalog
  const getCatalogApp = async (scopeApps: Array<{ id: string }>) => {
    const catalogData: Array<IEntityApp> = [];
    for (let i = 0; i < scopeApps.length; i++) {
      const resp = await catalogApi.getEntities({
        filter: {
          kind: 'Component',
          'metadata.name': `${scopeApps[i].id}`,
        },
      });
      catalogData.push(resp.items[0] as IEntityApp);
    }
    return catalogData;
  };

  const { loading, value: scopeApps } = useAsync(
    async () => await mintApi.getAppsFromScope(scope.resourceId, scope.id),
  );

  React.useEffect(() => {
    if (!loading && scopeApps) {
      getCatalogApp(scopeApps)
        .then(setRows)
        .finally(() => setTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeApps, loading]);

  return (
    <Box padding="2rem">
      <Typography variant="h2">{`${scope.resourceId}.${scope.id}`}</Typography>
      <Typography> {scope.summary} </Typography>
      <Box mt="2rem">
        {isTableLoading && <LinearProgress />}
        {!isTableLoading && (
          <Table
            title={<Typography variant="subtitle1">Applications</Typography>}
            columns={columns}
            data={rows}
            options={{
              paging: true,
              pageSize: 15,
              loadingType: 'linear',
              padding: 'dense',
              pageSizeOptions: [15, 30, 100],
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ScopeDetails;
