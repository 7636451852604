import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'load-tests',
});

export const testDetailsRouteRef = createRouteRef({
  id: 'test-details',
  params: ['id'],
});
