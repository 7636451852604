import {
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { getTableResolvedReviewStatus } from './getTableResolvedReviewStatus';

export function getTableRawsFromEntities(
  entities: Entity[],
): ICatalog.EntityRow[] {
  return entities.map(entity => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
    const toerByRelations = getEntityRelations(entity, 'toerBy');
    const reviewStatus = getTableResolvedReviewStatus(entity as IEntityApp);
    const specSpec = entity?.spec?.spec as IEntityAPPSpecSpec;
    const ownerTeamName = specSpec?.owner_name || entity?.spec?.owner;
    const ownerTeamId = specSpec?.team_id || entity?.spec?.owner;
    return {
      entity: entity as any,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        toerByRelationsTitle: toerByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'toer' }))
          .join(', '),
        toerByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
        reviewStatus: reviewStatus,
        ownerTeamNameAndId: [ownerTeamName, ownerTeamId].join(', '),
      },
    };
  });
}
