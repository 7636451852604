import React from 'react';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../services';
import { Params } from 'react-router-dom';

import { BreadcrumbItem } from './styles';

export type Props = {
  isActive: boolean;
  params: Params<string>;
};

export function BreadcrumbsItem({
  text,
  isActive,
  path,
  search = '',
  disabled = false,
}: {
  text?: string;
  path: string;
  isActive?: boolean;
  search?: string;
  disabled?: boolean;
}) {
  return (
    <BreadcrumbItem
      title={text}
      aria-selected={isActive ? 'true' : 'false'}
      disabled={disabled}
      to={`${path}${search}`}
    >
      {text}
    </BreadcrumbItem>
  );
}

export const RepositoryBreadcrumb = observer(
  ({ params: { domain_alias, org_name, repo_name }, isActive }: Props) => {
    const {
      runsService: { currentEvent },
    } = useServices();
    const search = currentEvent.length
      ? `?event=${currentEvent.join('&event=')}`
      : '';

    return (
      <BreadcrumbsItem
        isActive={isActive}
        text={repo_name}
        path={`/cdp/${domain_alias}/${org_name}/${repo_name}${search}`}
      />
    );
  },
);

export const RunBreadcrumb = observer(
  ({
    params: { domain_alias, org_name, repo_name, runId },
    isActive,
  }: Props) => {
    const {
      runService: {
        run: { build_version, id },
      },
    } = useServices();

    const text = id === runId && !!build_version ? build_version : '...';

    return (
      <BreadcrumbsItem
        isActive={isActive}
        text={text}
        path={`/cdp/${domain_alias}/${org_name}/${repo_name}/${runId}/run`}
      />
    );
  },
);

export const StepBreadcrumb = observer(
  ({
    params: { domain_alias, org_name, repo_name, runId, stepId },
    isActive,
  }: Props) => {
    const {
      runService: { step, runState },
    } = useServices();
    const id = step?.id;

    const text = !(runState.isIdle || runState.isLoading) && !!id ? id : '...';

    return (
      <BreadcrumbsItem
        isActive={isActive}
        text={text}
        path={`/cdp/${domain_alias}/${org_name}/${repo_name}/${runId}/steps/${stepId}`}
      />
    );
  },
);
