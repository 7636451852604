import React from 'react';
import { InfoCard, List, TagSmall } from '../../../../../common';
import { Element } from './Element';

import * as S from './styles';

type NPMArtifact = {
  name: string;
  description: string;
  version: string;
  repository: string;
};

export function NPMArtifacts({ artifacts }: { artifacts: string[] }) {
  return (
    <InfoCard title="📦 NPM">
      <List>
        {artifacts.map((record, i) => {
          const { name, description, version, repository } = JSON.parse(
            record,
          ) as NPMArtifact;

          return (
            <S.Artifact key={i}>
              <Element text={repository} label="Respoitroy" />
              <Element text={name} label="Name" />
              <Element text={<TagSmall>{version}</TagSmall>} label="Version" />
              <Element text={description} label="Description" />
            </S.Artifact>
          );
        })}
      </List>
    </InfoCard>
  );
}
