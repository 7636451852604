import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { notebooksApiRef } from '../../api';
import { ListEntities, ActionsTableCell } from '../../components';
import { constructLinkToDataLabNotebook } from '../../utils';
import { NotebooksProps } from '../NotebooksProps';
import { SubvalueCell } from '@backstage/core-components';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';

export const SharedNotebooks = (props: NotebooksProps) => {
  const { projectId } = props;
  const notebookClient = useApi(notebooksApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [archiveNotebookError, setArchiveNotebookError] = useState<Error>();
  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await notebookClient.deleteSharedNotebook(id);
    } catch (err) {
      setArchiveNotebookError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const COLUMNS = [
    {
      title: 'Shared Notebook',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="shared_notebooks"
          id={row.id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="versions"
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <ListEntities
      getEntitiesPromise={notebookClient.getSharedNotebooks(projectId, page)}
      entityKey="shared_notebooks"
      columns={COLUMNS}
      childEntityKey="versions"
      refreshList={refreshList}
      page={page}
      onPageChange={setPage}
      error={archiveNotebookError}
      setError={setArchiveNotebookError}
    />
  );
};
