import React from 'react';

import { Tooltip, BranchIcon } from '../../../common';
import * as S from './styles';

type Props = {
  branch: string;
  repo: string;
};

export function BranchTag({ branch, repo }: Props) {
  return (
    <S.TextLabeled>
      <BranchIcon />

      <Tooltip title={`Branch name: ${branch}`} placement="bottom-start">
        <a
          href={`https://${repo}/tree/${branch}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {branch}
        </a>
      </Tooltip>
    </S.TextLabeled>
  );
}
