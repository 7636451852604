import { EventTrackerProps } from '../Tracking';

export const common: Pick<EventTrackerProps, 'plugin' | 'eventCategory'> = {
  plugin: 'plugin-ui-components',
  eventCategory: 'Product Tour',
};

export const startTour = (
  tourId: string,
  totalSteps: number,
): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Starting tour ${tourId} with ${totalSteps} steps`,
});

export const closeTour = (
  tourId: string,
  totalSteps: number,
  currentStep: string,
): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Tour with id ${tourId} closed at ${currentStep}/${totalSteps}`,
});

export const completeTour = (
  tourId: string,
  totalSteps: number,
): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Tour with id ${tourId} completed ${totalSteps}/${totalSteps}`,
});
