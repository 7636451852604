import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Progress } from '@backstage/core-components';
import { Box } from '@material-ui/core';

import { Text } from '../../../../../common';
import { JUnitTestFolds } from './JUnitTestFolds';
import { useJunitTestUpload } from './useJunitTestUpload';
import { useServices } from '../../../../../services';
import { TestResultsHeader } from '../TestResultsHeader';

export const JUnitTestResults = observer(
  ({ url, navigateBack }: { url: string; navigateBack: () => void }) => {
    const { runId = '', stepId = '' } = useParams();
    const { runService } = useServices();

    const { id: stepRunId, status } = runService.step?.run;

    const [{ loading, error, value }] = useJunitTestUpload(
      runId,
      stepId,
      stepRunId,
      url,
      status,
    );

    if (error) {
      return (
        <Text error padding={2}>
          An error occurred while retrieving test results, refresh the page to
          try again.
        </Text>
      );
    }

    if (loading) {
      return (
        <Box padding={2}>
          <Progress />
        </Box>
      );
    }

    return (
      <>
        <TestResultsHeader back={navigateBack} />

        {value ? (
          <JUnitTestFolds stepId={stepId} results={value} />
        ) : (
          <Box padding={2}>No test results to display.</Box>
        )}
      </>
    );
  },
);
