import { Link } from '@material-ui/core';
import React from 'react';

export function ConcurrencyInfo() {
  return (
    <div>
      <small>
        <span>
          This pipeline does not run concurrent steps. Visit documentation on
          &nbsp;
        </span>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://cloud.docs.zalando.net/reference/cdp-concurrent-steps/"
        >
          Concurrent step execution in CDP
        </Link>
        <span>&nbsp;to learn more.</span>
      </small>
    </div>
  );
}
