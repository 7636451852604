import React, { type ReactNode, useEffect, useState } from 'react';
import { useStyles } from '../styles';
import { CircularProgress, Tab, Tabs, Tooltip } from '@material-ui/core';
import { searchConfig } from '../config';
import { useSearchContext } from '../SearchContext';
import { getResultTotal } from '../utils';
import ErrorIcon from '@material-ui/icons/Error';
import { useMediaMobile } from 'plugin-ui-components';
import cn from 'classnames';
import { GCSSearchResponse } from '../types';

const initialTotals = Array(searchConfig.indexes.length).fill(0);

interface Props {
  overrideOrientation?: 'horizontal' | 'vertical';
}

const MORE_THAN_LIMIT = 'MORE_THAN_LIMIT';

export function SearchTabs({ overrideOrientation }: Props) {
  /**
   * String represents an error
   * Number represents a total
   * Undefined represents loading
   */
  const [totals, setTotals] =
    useState<Array<number | undefined | string>>(initialTotals);
  const { tab, setTab, results } = useSearchContext();
  const styles = useStyles();
  const isMobile = useMediaMobile();
  const { filterOrientation, limit } = useSearchContext();

  useEffect(() => {
    if (results?.length) {
      setTotals(Array(results.length).fill(undefined));

      results.forEach((result, i) => {
        result.then(({ data, error }) => {
          let newValue: string | number | undefined;

          if (error)
            newValue = `Error! ${
              (error as Error).message ?? 'Could not load results'
            }`;
          else if (data) newValue = getResultTotal(data);
          else newValue = 0;

          setTotals(prev => {
            const newTotals = prev.slice();
            newTotals[i] = newValue;

            const gcsResult = data as GCSSearchResponse;
            if (newValue === 0 && gcsResult.results?.length) {
              if (gcsResult.hasMoreResults) {
                newTotals[i] = MORE_THAN_LIMIT;
              } else {
                newTotals[i] = gcsResult.results.length;
              }
            }
            return newTotals;
          });
        });
      });
    } else {
      setTotals(initialTotals);
    }
  }, [results]);

  const orientation = overrideOrientation ?? filterOrientation;

  return (
    <div className={cn(styles.searchTabsContainer, orientation)}>
      <Tabs
        value={tab}
        onChange={(_ev, val) => setTab(val)}
        orientation={orientation}
        scrollButtons={isMobile ? 'auto' : 'on'}
        variant={orientation === 'horizontal' ? 'scrollable' : 'standard'}
        className={cn(styles.searchTabs, orientation)}
        classes={{
          indicator:
            orientation === 'vertical' ? styles.searchTabsIndicator : undefined,
          scrollButtons: styles.searchTabsScrollButtons,
        }}
      >
        {searchConfig.indexes.map((index, i) => {
          let tabLabelSuffix: ReactNode;
          if (totals[i] === undefined)
            tabLabelSuffix = (
              <span className={styles.searchTabBadge}>
                <CircularProgress color="inherit" size={10} />
              </span>
            );
          else if (totals[i] === MORE_THAN_LIMIT)
            tabLabelSuffix = (
              <span className={styles.searchTabBadge}>{limit}+</span>
            );
          else if (typeof totals[i] === 'string')
            tabLabelSuffix = (
              <Tooltip title={totals[i] as string}>
                <ErrorIcon color="error" />
              </Tooltip>
            );
          else
            tabLabelSuffix = (
              <span className={styles.searchTabBadge}>{String(totals[i])}</span>
            );

          return (
            <Tab
              key={index.id + i}
              value={index.id}
              label={
                <div className={styles.searchTabLabel}>
                  <span>{index.label}</span>
                  {tabLabelSuffix}
                </div>
              }
              icon={
                <index.icon
                  fontSize="small"
                  style={{
                    color: index.accentColor,
                    minWidth: 20,
                    height: 'auto',
                    aspectRatio: '1/1',
                  }}
                />
              }
              disableRipple
              className={orientation}
              classes={{
                root: cn(styles.searchTab, orientation),
                selected: styles.searchTabSelected,
                labelIcon: styles.searchTabLabelIcon,
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
}
