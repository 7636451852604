import { createApiRef } from '@backstage/core-plugin-api';
import {
  ApplicationTemplate,
  CustomizedRepositoryStatus,
  Organization,
  Repository,
  RepositoryStatus,
  ApplicationTemplateForm,
} from './types';

export const repoApiRef = createApiRef<RepositoryApi>({
  id: 'plugin.create-repository.service',
});

export interface RepositoryApi {
  createRepository(repository: Repository): Promise<RepositoryStatus>;
  getStatus(status: RepositoryStatus): Promise<CustomizedRepositoryStatus>;
  getOrganizations(): Promise<Organization[]>;
  getApplicationTemplates(): Promise<ApplicationTemplate[]>;
  getApplicationTemplateForm(name: string): Promise<ApplicationTemplateForm>;
}
