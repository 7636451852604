import React from 'react';
import { Text } from '../../../../../../common';

type Props = {
  isRunning: boolean;
  wasRunning: boolean;
};

export const LoadingMessage = ({ isRunning, wasRunning }: Props) => {
  return (
    <div id="scalyr-logs">
      <Text p={2}>
        {isRunning || wasRunning ? 'No logs available yet...' : 'Loading...'}
      </Text>
    </div>
  );
};
