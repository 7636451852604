import React from 'react';
import { useMutation, useQuery } from 'react-query';

interface UseFormProps<T, K> {
  /**
   * Used to identify the form and properly cache queries
   */
  name: string;
  /**
   * Callback function called when the form is submitted
   */
  onSubmit: (formData: T) => void;
  /**
   * Callback function called when the mutation is successful
   */
  onSuccess?: Function;
  /**
   * Callback function called when the mutation fails
   */
  onError?: Function;

  /**
   * Callback function called when the mutation is settled (failed or successful)
   */
  onSettled?: Function;
  /**
   * Function used to fetch initial values for the form
   */
  getInitialValues?: () => Promise<K | undefined>;
}

export const useForm = <T, K>({
  name,
  onSubmit,
  onSuccess,
  onError,
  onSettled,
  getInitialValues,
}: UseFormProps<T, K>) => {
  // Query object used to fetch initial values in case of an edit form for example.
  const {
    data: initialValues,
    isLoading: initialValuesLoading,
    error: initialValuesError,
    refetch,
  } = useQuery(
    [`initialValues${name}`],
    async () => {
      if (getInitialValues) {
        return await getInitialValues();
      }
      return undefined;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!getInitialValues,
      cacheTime: 0,
    },
  );
  // Mutation object used to submit form and handle states
  const mutation = useMutation({
    mutationKey: `submit-${name}`,
    mutationFn: async (data: T) => {
      await onSubmit(data);
    },
    onSuccess: async () => {
      if (onSuccess) {
        await onSuccess();
      }
    },
    onError: async () => {
      if (onError) {
        await onError();
      }
    },
    onSettled: async () => {
      if (onSettled) {
        await onSettled();
      }
    },
  });

  return {
    ...mutation,
    initialValues,
    initialValuesLoading,
    initialValuesError,
    refetchInitialValues: refetch,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const data = Object.fromEntries(formData.entries());
      mutation.mutate(data as T);
    },
  };
};
