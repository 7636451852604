import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from '@material-ui/core';
import { Grid, Item } from '../Grid';
import { Status } from './Status';
import { useStyles } from './styles';
import { InfoButton } from '../InfoButton';
import { ImageBaseImageInfoModel } from '../../models';

function getOutdatedImageMsg(imageMsg: string): JSX.Element {
  return (
    <div>
      <strong>🛑 Outdated base image</strong>
      <p>{imageMsg}</p>
      <p>Any production deployment relying on this image will be blocked.</p>
      <p>
        To create a compliant Docker image of your application, you must
        reference an allowed Docker Image as its base image in your Dockerfile.
        This base image must come from the container registry namespace library
        and use a recommended version as listed in&nbsp;
        <Link
          rel="noopener noreferrer"
          href="https://cloud.docs.zalando.net/howtos/compliant-images/#use-of-allowed-docker-base-images"
        >
          the documentation
        </Link>
        .
      </p>
    </div>
  );
}

export function BaseImageInfo({ image }: { image: ImageBaseImageInfoModel }) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function handleClose() {
    setIsModalOpen(false);
  }

  function handleOpen() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Base image
      </Typography>

      <Grid>
        <Item title="Name">{image.names[0]}</Item>

        <Item title="Validity days">{image.validityDays}</Item>

        <Item title="Status">
          <Box display="flex" alignItems="center">
            <Status success={image.allowed}>
              {image.allowed ? 'allowed' : 'not_allowed'}
            </Status>
            <InfoButton action={handleOpen}>details</InfoButton>
          </Box>
        </Item>
      </Grid>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="Base imagee status"
        aria-describedby="Markdown describing a status of a docker image"
      >
        <DialogContent>
          <Typography variant="body2" className={classes.text}>
            {image.allowed ? image.message : getOutdatedImageMsg(image.message)}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
