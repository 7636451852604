import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function ID({ value, issue }: FieldProps<'id'>) {
  return (
    <>
      <dt>ID</dt>
      <dd>
        {value}
        <InfoTooltip>
          The unique identifier of the application. Cannot be changed.
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
