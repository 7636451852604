import { EventTrackerProps } from 'plugin-ui-components';

const common: Omit<EventTrackerProps, 'eventLabel'> = {
  plugin: 'catalog',
  eventCategory: 'Application Overview',
  eventAction: 'click',
  interaction: 'onClick',
};

export const clickLink = (label: string) => ({
  ...common,
  eventLabel: `${label}`,
});

export const clickToerChip = (): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Toer chip',
});
