import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { MainPage } from '../pages/MainPage';
import { ImagePage } from '../pages';

export function ContainerImagesRouter() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path=":registry/:namespace/:name" element={<ImagePage />} />
      <Route path=":registry/:namespace/:name/:name2" element={<ImagePage />} />
    </Routes>
  );
}
