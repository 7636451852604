import React from 'react';
import { AutocompleteResourceFilter } from './FilterTypes';

const label = 'Account';
const id = 'account-filter';

export const AccountFilter = React.memo(
  ({
    onChange,
    selected,
    accounts,
    loading,
  }: {
    onChange?: (value: string) => void;
    selected?: string;
    accounts?: { label: string; value: string }[] | undefined;
    loading: boolean;
  }) => {
    return (
      <AutocompleteResourceFilter
        values={accounts}
        onChange={onChange}
        selected={selected}
        id={id}
        label={label}
        disabled={loading}
      />
    );
  },
);
