import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dataWrapper: {
    '&::-webkit-scrollbar': {
      width: '8px',
      appearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0,0,0,.5)',
    },
    overflowY: 'scroll',
    overscrollBehavior: 'none',
    maxHeight: 250,
  },
  header: {
    fontSize: '1.6rem',
    marginBottom: '1rem',
  },
  loader: {
    alignSelf: 'center',
  },
  searchBar: {
    margin: '.5rem 0',
    '& label, & div': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  stripedTable: {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,

    '& thead tr td': {
      fontWeight: 500,
    },

    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.palette.background.default,
    },

    '& td': {
      padding: theme.spacing(),
    },
  },
  firstColumn: {
    width: '65%',
    paddingBottom: theme.spacing(),
  },
  alignCenter: {
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(),
  },
  card: {
    height: '100%',
  },
  cardContent: {
    paddingTop: 0,
  },
  alert: {
    padding: '2px 10px',
    width: '100%',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  backButton: {
    marginBottom: '1rem',
  },
  cardIcon: {
    padding: 10,
  },
  checkbox: {
    '& span': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));
