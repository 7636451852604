import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useUserTeamsApps } from 'plugin-ui-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Content } from '@backstage/core-components';
import { getQueryValue } from 'plugin-core';
import { Model } from '../../api/interfaces/model';
import { modelsApiRef } from '../../api/services/models';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';
import { CatalogMeta } from '../../api/interfaces/meta';
import { Box, Grid } from '@material-ui/core';
import { GroupFilters } from '../tables/GroupFilters';
import { getMLFilterGroups } from '../tables/FilterGroups';
import { ResourceFilters } from '../tables/ResourceFilters';
import { ResourceTable } from '../tables/ResourceTable';
import { useModelColumns } from '../tables/columns/Models';
import { useApplicationIDSelection } from '../../hooks/useApplicationIdSelection';
import { useDebounce, useLocalStorage } from 'react-use';
import { Layout } from '../common/Layout';
import { useSearchParams } from 'react-router-dom';
import {
  ModelFilterStore,
  ModelFilterStoreDefault,
} from '../../api/interfaces/store';

export const ModelsPage = () => {
  const configApi = useApi(configApiRef);
  const modelsApi = useApi(modelsApiRef);
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const defaultSelectedFilter = getQueryValue('group') || 'mine';

  const [modelFilters, setModelFilters] = useLocalStorage<ModelFilterStore>(
    'model-filters',
    ModelFilterStoreDefault,
  );
  const [models, setModels] = useState<Model[]>([]);
  const [meta, setMeta] = useState<CatalogMeta>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<{
    id: string;
    label: string;
  }>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const { value: userApps = [] } = useUserTeamsApps();

  const applicationIDSelection = useApplicationIDSelection(
    defaultSelectedFilter,
    userApps,
    modelFilters,
  );

  const [search, setSearchParams] = useSearchParams({
    tag_name: modelFilters?.tag_name || '',
    name: modelFilters?.name || '',
    application: modelFilters?.application || '',
    deployment_environment_id: modelFilters?.deployment_environment_id || '',
    origin: modelFilters?.origin || '',
    license_type: modelFilters?.license_type || '',
  });

  const fetchModels = useCallback(
    async (params: Record<string, any> = {}) => {
      setLoading(true);
      setError(null);

      try {
        const queryParameters = {
          ...params,
          application_id: applicationIDSelection(),
        };

        const response = await modelsApi.getModels(queryParameters);
        setModels(response.models);
        setMeta(response.meta);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    },
    [applicationIDSelection, modelsApi],
  );

  useEffect(() => {
    setModels([]);
    const offset = DEFAULT_PAGE_SIZE * page;
    fetchModels({ limit: DEFAULT_PAGE_SIZE, offset });
  }, [page, fetchModels]);

  useDebounce(
    () => {
      if (modelFilters) {
        setModels([]);
        const offset = DEFAULT_PAGE_SIZE * page;
        fetchModels({
          limit: DEFAULT_PAGE_SIZE,
          offset,
          tag_name: search.get('tag_name'),
          name: search.get('name'),
          application: search.get('application'),
          deployment_environment_id: search.get('deployment_environment_id'),
          origin: search.get('origin'),
          license_type: search.get('license_type'),
        });
      }
    },
    800,
    [modelFilters, page, fetchModels, search],
  );

  const modelColumns = useModelColumns();
  const filterGroups = useMemo(
    () =>
      getMLFilterGroups('models', orgName, {
        all: meta?.totals.models || 0,
        mine: 0, // Update 'mine' total if needed
      }),
    [meta?.totals.models, orgName],
  );

  return (
    <Layout>
      <Content>
        <Box paddingTop={1}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <GroupFilters
                groups={filterGroups}
                initiallySelected={defaultSelectedFilter}
                onChange={setSelectedSidebarItem}
                setFilters={setModelFilters}
              />
              <ResourceFilters
                applicationIds={meta?.application_ids}
                deploymentEnvironments={meta?.deployment_environments}
                setFilters={setModelFilters}
                fieldsToShow={[
                  'application',
                  'license_type',
                  'tag_name',
                  'name',
                  'origin',
                  'deployment_environment_id',
                ]}
                selected={modelFilters}
                selectedSidebarItem={selectedSidebarItem}
                loading={loading}
                addQueryParams
                searchProps={{ searchParams: search, setSearchParams }}
              />
            </Grid>
            <Grid item xs={10}>
              <ResourceTable
                loading={loading}
                error={error}
                entity="model"
                data={models}
                columns={modelColumns}
                paginationConfig={{
                  setPage,
                  rowsPerPage,
                  setRowsPerPage,
                  page: page || 0,
                  total: meta?.totals.models || 0,
                }}
                displayOptions={{
                  showTitle: true,
                  search: false,
                  padding: 'dense',
                  customTitle: `${selectedSidebarItem?.label || 'Models'}`,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Content>
    </Layout>
  );
};
