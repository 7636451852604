import { action, makeObservable, observable } from 'mobx';

export interface IPreferencesService {
  absoluteDate: boolean;
  setAbsoluteDate: (value: boolean) => void;
}

export class PreferencesService implements IPreferencesService {
  // Observables
  absoluteDate: boolean = false;

  constructor() {
    makeObservable(this, {
      absoluteDate: observable,
      setAbsoluteDate: action,
    });
  }

  // Actions
  public setAbsoluteDate = (value: boolean) => {
    this.absoluteDate = value;
  };
}
