import { CatalogApi } from '@backstage/catalog-client';

type ConfigFilterOptionValue = string | number | boolean;

export interface ConfigFilterOption {
  label: string;
  value: ConfigFilterOptionValue;
}

interface ConfigFilterOptionApis {
  catalogApi: CatalogApi;
}

interface ConfigFilterProps {
  indexId: string;
  field: string;
  label: string;
  /** @default false */
  isMultiple?: boolean;
  getOptions: (apis: ConfigFilterOptionApis) => Promise<ConfigFilterOption[]>;
  getDefaultValue?: (options: ConfigFilterOption[]) => ConfigFilterOption[];
}

export class ConfigFilter {
  private readonly _indexId: string;
  private readonly _field: string;
  private readonly _label: string;

  private readonly _isMultiple: boolean;
  private readonly _getOptions: (
    apis: ConfigFilterOptionApis,
  ) => Promise<ConfigFilterOption[]>;
  private _cachedOptions?: ConfigFilterOption[] | Error;
  private readonly _getDefaultValue?: (
    options: ConfigFilterOption[],
  ) => ConfigFilterOption[];
  private _cachedDefaultValue?: ConfigFilterOption[];

  constructor({
    indexId,
    field,
    label,
    isMultiple,
    getOptions,
    getDefaultValue,
  }: ConfigFilterProps) {
    this._indexId = indexId;

    this._field = field;
    this._label = label;
    this._isMultiple = isMultiple === true;
    this._getOptions = getOptions;
    this._getDefaultValue = getDefaultValue;
  }

  get indexId() {
    return this._indexId;
  }

  get field() {
    return this._field;
  }

  get label() {
    return this._label;
  }

  get isMultiple(): boolean {
    return this._isMultiple;
  }

  get defaultValue(): ConfigFilterOption[] {
    return this._cachedDefaultValue ?? [];
  }

  async initOptions(apis: ConfigFilterOptionApis) {
    if (!this._cachedOptions || this._cachedOptions instanceof Error) {
      try {
        let value = await this._getOptions(apis);
        value = value.map(v => Object.freeze(v));
        this._cachedOptions = Object.freeze(value) as any;
      } catch (error) {
        this._cachedOptions = new Error(error as any);
      }
    }
    if (!this._cachedDefaultValue && this._getDefaultValue) {
      if (this._cachedOptions instanceof Error) {
        throw this._cachedOptions;
      }
      const value = Object.freeze(
        this._getDefaultValue(this._cachedOptions ?? []),
      );
      if (Array.isArray(value)) {
        value.forEach(v => Object.freeze(v));
      }
      this._cachedDefaultValue = Array.isArray(value) ? value : [value];
    }
  }

  getOptions(): ConfigFilterOption[] | Error | undefined {
    return this._cachedOptions;
  }
}
