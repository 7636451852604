import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../api';
import { NotFoundException } from '../../../../api/exceptions';
import { DeploymentStatusModel } from '../../../../models';
import { STEP_PRE_IN_PROGRESS_STATUSES } from '../../../../constants';

export function useDeploymentStatus(
  runId: string,
  ordinal: string,
  stepRunId: string,
  params: { stepId: string },
  status: string,
) {
  const api = useApi(cdpApiRef);

  const getDeploymentStatus = useCallback(async () => {
    if (!stepRunId || STEP_PRE_IN_PROGRESS_STATUSES.includes(status)) {
      return Promise.resolve();
    }

    try {
      const response = await api.getDeploymentStatus(
        runId,
        ordinal,
        stepRunId,
        params,
      );

      const data = (response.activities ?? []).map(
        a => new DeploymentStatusModel(a),
      );

      return Promise.resolve(data);
    } catch (error) {
      const e = error as Error;
      if (!(e instanceof NotFoundException)) {
        return Promise.reject(e);
      }

      return Promise.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, stepRunId]);

  const { loading, error, value, retry } = useAsyncRetry(
    () => getDeploymentStatus(),
    [getDeploymentStatus, status],
  );

  return [
    { loading, error, value: value || [] },
    {
      retry,
      getDeploymentStatus,
    },
  ] as const;
}
