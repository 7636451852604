import React from 'react';
import { InfoCard } from '../InfoCard';
import { ErrorBox } from '../ErrorBox';
import { Divider, LinearProgress, List, ListItem } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { CardFooterLink } from '../CardFooterLink';
import { HomeWidgetProps } from './types';
// This import is specifically made this way because importing from the index file won't work in Storybook
import { EventTracker } from '../Tracking/EventTracker';

export function HomeWidget<T extends object = {}>({
  title,
  value,
  render,
  error,
  errorDescription,
  loading = false,
  footerLink,
  emptyState = 'No content',
  children,
  ...props
}: HomeWidgetProps<T>) {
  const viewAllComponent = footerLink && (
    <CardFooterLink to={footerLink.href}>
      {footerLink.text} {footerLink.icon || <ArrowForward />}
    </CardFooterLink>
  );

  const isEmpty = !value?.length && !children;

  return (
    <InfoCard title={title} {...props}>
      {loading && <LinearProgress />}
      {!loading && (
        <>
          {error && <ErrorBox error={error} description={errorDescription} />}
          {!error && children}
          {!error && !!value?.length && render && (
            <List disablePadding={!!value.length}>{value.map(render)}</List>
          )}
          {!error && isEmpty && (
            <List>
              <ListItem>{emptyState}</ListItem>
            </List>
          )}
        </>
      )}
      {footerLink && (
        <>
          <Divider />
          {footerLink.tracking ? (
            <EventTracker {...footerLink.tracking}>
              {viewAllComponent}
            </EventTracker>
          ) : (
            viewAllComponent
          )}
        </>
      )}
    </InfoCard>
  );
}
