import React, { useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core';
import { CyberWeekBanner } from 'plugin-ui-components';

import { useServices } from '../../services';
import { OrganizationModel, RepositoryModel } from '../../models';
import { getDomainFromAlias } from '../../utils/domains';
import { validateEventOrDefaultPushAndSchedule } from '../../validations/params';
import { Layout, NotFound, TestableProgress } from '../../common';
import { Pagination, GridLayout, NothingFound, ErrorOccurred } from '../common';
import { ActionPanel } from './components/ActionPanel';
import { RunGroupsList } from './components/RunGroupsList';

import { runGroupsCssVariables } from '../../styles/css-variables';
import * as S from './styles';

const OrganizationPageComponent = observer(() => {
  const navigate = useNavigate();
  const {
    runGroupsOrganizationPageService: runGroupsService,
    repositoriesOrganizationPageService: repositoriesService,
    filteringOrganizationPageService: filteringService,
    metaService,
  } = useServices();
  const [searchParams, setSearchParams] = useSearchParams({
    repositories: '',
    organizations: '',
    event: [],
  });

  const repositoriesSearchParams = searchParams.getAll('repo');
  const { domain_alias = '', org_name = '' } = useParams();
  const domain = getDomainFromAlias(domain_alias);
  const path = `${domain}/${org_name}`;
  const eventSearchParams = validateEventOrDefaultPushAndSchedule(
    searchParams.getAll('event'),
  );

  const [filtering, setFiltering] = useLocalStorage<{
    repositories: Array<string>;
    events: string[];
  }>(`${path}Filters`, { repositories: [], events: [] });

  useEffect(() => {
    metaService.setTitle(org_name);
  }, [metaService, org_name]);

  useEffect(() => {
    filteringService.repository.setRepositoryTree(
      repositoriesService.repositoryTree,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositoriesService.repositoryTree]);

  useEffect(() => {
    if (repositoriesSearchParams.length > 0 || eventSearchParams) {
      const repositories = repositoriesSearchParams
        ? repositoriesSearchParams.map(item =>
            RepositoryModel.fromFullAliasPath(item),
          )
        : [];

      filteringService.persistHelper.disablePersist();
      filteringService.repository.setUserSelection(repositories, [
        OrganizationModel.fromFullPath(path),
      ]);
    } else {
      if (filtering) {
        const { repositories, events } = filtering;

        filteringService.persistHelper.enablePersist();
        filteringService.setEvent(events);
        filteringService.repository.setUserSelection(
          repositories.map(item => RepositoryModel.fromFullAliasPath(item)),
          [OrganizationModel.fromFullAliasPath(`${domain_alias}/${org_name}`)],
        );
      }
    }

    repositoriesService.filter(org_name, domain_alias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filteringService.persistHelper.canPersist) {
      setFiltering({
        ...filteringService.repository.userSelection,
        events: filteringService.events,
      });
    }

    if (
      filteringService.repository.hasRepositorySelection ||
      filteringService.events
    ) {
      setSearchParams(
        {
          repo: filteringService.repository.userSelection.repositories,
          event: filteringService.events,
        },
        { replace: true },
      );
    } else {
      navigate(
        {
          pathname: '.',
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringService.repository.userSelection, filteringService.events]);

  useEffect(() => {
    if (repositoriesService.repositoryTreeState.isLoaded) {
      if (filteringService.repository.hasRepositorySelection) {
        runGroupsService.getByRepositories(
          filteringService.repository.selected,
          filteringService.events,
        );
      } else {
        runGroupsService.getByOrganization(
          org_name,
          domain_alias,
          filteringService.events,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    repositoriesService.repositoryTreeState.isLoaded,
    filteringService.repository.selected,
    filteringService.events,
  ]);

  const shouldDisplayList = runGroupsService.runGroups.length > 0;
  const shouldDisplayProgress =
    repositoriesService.repositoryTreeState.isLoading ||
    runGroupsService.runGroupsState.isLoading;
  const shouldDisplayNothingFound =
    runGroupsService.runGroupsState.isLoaded &&
    runGroupsService.runGroups.length === 0;
  const shouldDisplayError =
    (repositoriesService.repositoryTreeState.hasError ||
      runGroupsService.runGroupsState.hasError) &&
    runGroupsService.runGroups.length === 0;

  return (
    <>
      <CyberWeekBanner />
      <S.Content>
        <S.Card>
          {runGroupsService.runGroupsState.isNotFound ? (
            <NotFound title="Organisation not found">
              <span>Organisation</span>
              <strong>{` ${org_name} `}</strong>
              <span>doesn&apos;t exist. </span>

              <Link to="../..">Go to home page.</Link>
            </NotFound>
          ) : (
            <>
              <ActionPanel />

              <GridLayout
                columns={['Latest pipeline run', 'Time', 'Steps', 'Action']}
              >
                {shouldDisplayProgress && <TestableProgress />}
                {shouldDisplayNothingFound && <NothingFound />}
                {shouldDisplayError && <ErrorOccurred />}
                {shouldDisplayList && (
                  <>
                    <RunGroupsList />
                    <Pagination service={runGroupsService} />
                  </>
                )}
              </GridLayout>
            </>
          )}
        </S.Card>
      </S.Content>
    </>
  );
});

export const OrganizationPage = withStyles(runGroupsCssVariables)(() => (
  <Layout render={<OrganizationPageComponent />} />
));
