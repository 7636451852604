import React from 'react';
import { observer } from 'mobx-react-lite';
import { Content, Header, Page } from '@backstage/core-components';
import { Theme, styled } from '@material-ui/core';
import { useServices } from '../../services';
import { PAGE_HEADER_HEIGHT } from '../../constants';
import { Breadcrumbs } from '../Breadcrumbs';
import { DevConsoleLink } from './Actions';

type Props = {
  render: JSX.Element;
  flat?: boolean;
};

const StyledContent = styled(Content)(
  (props: { theme: Theme; flat: number }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: props.flat ? 0 : props.theme.spacing(3),

    [props.theme.breakpoints.up('sm')]: {
      ...(props.flat && {
        height: `calc(100vh - ${PAGE_HEADER_HEIGHT})`,
        overflow: 'hidden',
      }),
    },
  }),
);

export const Layout = observer(({ render, flat = false }: Props) => {
  const { metaService } = useServices();

  return (
    <Page themeId="cdp">
      <Header
        title="Continuous Delivery Platform"
        pageTitleOverride={metaService.title}
        subtitle={<Breadcrumbs />}
      >
        <DevConsoleLink />
      </Header>
      <StyledContent flat={flat ? 1 : 0}>{render}</StyledContent>
    </Page>
  );
});
