import React from 'react';
import { InfoCard, List, TagSmall } from '../../../../../common';
import { Element } from './Element';

import * as S from './styles';

type MavenArtifact = {
  version: string;
  group_id: string;
  extension: string;
  classifier?: string;
  repository: string;
  artifact_id: string;
  base_version?: string;
};

export function MavenArtifacts({ artifacts }: { artifacts: string[] }) {
  return (
    <InfoCard title="📦 Maven">
      <List>
        {artifacts.map((record, i) => {
          const {
            version,
            group_id,
            extension,
            classifier,
            repository,
            artifact_id,
            base_version,
          } = JSON.parse(record) as MavenArtifact;

          return (
            <S.Artifact key={i}>
              <Element text={repository} label="Repository" />
              <Element text={group_id} label="Group id" />
              <Element text={artifact_id} label="Artifact id" />
              <Element
                text={<TagSmall>{base_version || version}</TagSmall>}
                label="Version"
              />
              <Element
                text={`${artifact_id}-${version}${
                  !!classifier ? `-${classifier}` : ''
                }.${extension}`}
                label="Filename"
              />
            </S.Artifact>
          );
        })}
      </List>
    </InfoCard>
  );
}
