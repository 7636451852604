import React from 'react';
import {
  makeStyles,
  Tooltip as MaterialTooltip,
  TooltipProps,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    whiteSpace: 'pre-line',
  },
  popper: {
    // zindex prevents tooltip from being invisible
    // if used inside of existing popover components
    zIndex: 10000,
  },
}));

export function Tooltip(props: TooltipProps) {
  const classes = useStyles();
  return <MaterialTooltip arrow classes={classes} {...props} />;
}
