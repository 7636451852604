import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core';
import { CyberWeekBanner } from 'plugin-ui-components';

import { useServices } from '../../services';
import { OrganizationModel, RepositoryModel } from '../../models';
import { Layout, TestableProgress } from '../../common';
import { validateEventOrDefaultPushAndSchedule } from '../../validations/params';
import { ErrorOccurred, GridLayout, NothingFound, Pagination } from '../common';
import { ActionPanel } from './components/ActionPanel';
import { RunGroupsList } from './components/RunGroupsList';

import { runGroupsCssVariables } from '../../styles/css-variables';
import * as S from './styles';

const MainPageComponent = observer(() => {
  const navigate = useNavigate();
  const {
    runGroupsMainPageService: runGroupsService,
    repositoriesMainPageService: repositoriesService,
    filteringMainPageService: filteringService,
    metaService,
  } = useServices();

  const [searchParams, setSearchParams] = useSearchParams({
    org: [],
    repo: [],
    event: [],
  });

  const eventSearchParams = validateEventOrDefaultPushAndSchedule(
    searchParams.getAll('event'),
  );
  const organizationsSearchParams = searchParams.getAll('org');
  const repositoriesSearchParams = searchParams.getAll('repo');

  const [filtering, setFiltering] = useLocalStorage<{
    repositories: Array<string>;
    organizations: Array<string>;
    events: string[];
  }>('mainPageFilters', {
    repositories: [],
    organizations: [],
    events: eventSearchParams,
  });

  useEffect(() => {
    metaService.setTitle('Explore');
  }, [metaService]);

  useEffect(() => {
    filteringService.repository.setRepositoryTree(
      repositoriesService.repositoryTree,
    );
  }, [filteringService, repositoriesService.repositoryTree]);

  useEffect(() => {
    filteringService.repository.setUserOrganizationPreference(
      repositoriesService.userOrganizationPreference,
    );
  }, [filteringService, repositoriesService.userOrganizationPreference]);

  useEffect(() => {
    if (
      organizationsSearchParams.length ||
      repositoriesSearchParams.length ||
      eventSearchParams.length
    ) {
      const repositories = repositoriesSearchParams
        ? repositoriesSearchParams.map(item =>
            RepositoryModel.fromFullAliasPath(item),
          )
        : [];

      const organizations = organizationsSearchParams
        ? organizationsSearchParams.map(item =>
            OrganizationModel.fromFullAliasPath(item),
          )
        : [];

      filteringService.persistHelper.disablePersist();
      filteringService.setEvent(eventSearchParams);
      filteringService.repository.setUserSelection(repositories, organizations);
    } else {
      if (filtering) {
        const { repositories, organizations, events } = filtering;

        filteringService.persistHelper.enablePersist();
        if (events?.length) {
          filteringService.setEvent(events);
        }
        filteringService.repository.setUserSelection(
          repositories.map(item => RepositoryModel.fromFullAliasPath(item)),
          organizations.map(item => OrganizationModel.fromFullAliasPath(item)),
        );
      }
    }

    repositoriesService.get(!filteringService.repository.hasAnySelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringService, repositoriesService]);

  useEffect(() => {
    if (filteringService.persistHelper.canPersist) {
      setFiltering({
        ...filteringService.repository.userSelection,
        events: filteringService.events,
      });
    }

    if (
      filteringService.repository.hasAnySelection ||
      filteringService.events.length
    ) {
      setSearchParams(
        {
          org: filteringService.repository.userSelection.organizations,
          repo: filteringService.repository.userSelection.repositories,
          event: filteringService.events,
        },
        { replace: true },
      );
    } else {
      navigate(
        {
          pathname: '.',
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringService.repository.userSelection, filteringService.events]);

  useEffect(() => {
    if (repositoriesService.repositoryTreeState.isLoaded) {
      if (filteringService.repository.userSelectedRepositories.length > 0) {
        runGroupsService.getByRepositories(
          filteringService.repository.selected,
          filteringService.events,
        );
      } else {
        runGroupsService.getByOrganizations(
          filteringService.repository.selected,
          filteringService.events,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    repositoriesService.repositoryTreeState.isLoaded,
    filteringService.repository.selected,
    filteringService.events,
  ]);

  const shouldDisplayList = runGroupsService.runGroups.length > 0;
  const shouldDisplayProgress =
    repositoriesService.repositoryTreeState.isLoading ||
    runGroupsService.runGroupsState.isLoading;
  const shouldDisplayNothingFound =
    runGroupsService.runGroupsState.isLoaded &&
    runGroupsService.runGroups.length === 0;
  const shouldDisplayError =
    (repositoriesService.repositoryTreeState.hasError ||
      runGroupsService.runGroupsState.hasError) &&
    runGroupsService.runGroups.length === 0;

  return (
    <>
      <CyberWeekBanner />
      <S.Content>
        <S.Card>
          <ActionPanel />

          <GridLayout
            columns={['Latest pipeline run', 'Time', 'Steps', 'Action']}
          >
            {shouldDisplayProgress && <TestableProgress />}
            {shouldDisplayNothingFound && <NothingFound />}
            {shouldDisplayError && <ErrorOccurred />}
            {shouldDisplayList && (
              <>
                <RunGroupsList />
                <Pagination service={runGroupsService} />
              </>
            )}
          </GridLayout>
        </S.Card>
      </S.Content>
    </>
  );
});

export const MainPage = withStyles(runGroupsCssVariables)(() => (
  <Layout render={<MainPageComponent />} />
));
