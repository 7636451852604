import React from 'react';

/** display plural character when number is more than 1 */
export function plural(num: number, ch: string = 's') {
  return new Intl.PluralRules('en-US').select(num) === 'other' ? ch : '';
}

/** Replaces string tokens with values from the spec, if any */
export const replaceIfNeeded = (
  content: React.ReactNode,
  spec?: IKioAppApiResponse,
) => {
  if (spec && typeof content === 'string') {
    let result = content;
    const match = content.matchAll(/%\w+%/g);
    let item = match.next();

    while (!item.done) {
      const matcher = item.value[0] || '';
      const key = matcher.replace(/%/g, '') as keyof IKioAppApiResponse;

      if (key && spec[key]) {
        result = result.replace(matcher, String(spec[key]));
      }
      item = match.next();
    }

    return result;
  }
  return content;
};
