import { Button, Switch } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

interface RuleSwitchProps {
  id: string;
  disabled: boolean;
  onSwitch: (scheduledNotebookId: string, disabled: boolean) => any;
  text?: string;
}

export const RuleSwitch = (props: RuleSwitchProps) => {
  const { id, disabled, onSwitch, text } = props;
  const [isDisabled, setIsDisabled] = useState(disabled);
  const onChange = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const newState = !isDisabled;
    setIsDisabled(newState);
    onSwitch(id, newState);
  };
  const ruleSwitch = <Switch checked={!isDisabled} color="primary" />;

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <Button onClick={onChange}>
      {text ? (
        <>
          {ruleSwitch}
          <span>{isDisabled ? `Enable ${text}` : `Disable ${text}`}</span>
        </>
      ) : (
        <>
          <span>off</span>
          {ruleSwitch}
          <span>on</span>
        </>
      )}
    </Button>
  );
};
