import React from 'react';
import { Progress } from '@backstage/core-components';
import { ErrorBox } from 'plugin-ui-components';
import { ImageDetails } from './ImageDetails';
import { ImageModel } from '../../models';

type Props = {
  image: ImageModel | undefined;
  isLoading: boolean;
  error: Error | undefined;
};

export function ImageOverview({ image, isLoading, error }: Props) {
  if (isLoading) {
    return <Progress />;
  }

  if (!!error) {
    return (
      <ErrorBox title="Failed to fetch data" description={error.message} />
    );
  }

  if (image === undefined) {
    return <ErrorBox title="Could not find image details" />;
  }

  return <ImageDetails image={image} />;
}
