import { IAppRepositoryVulnerability } from './types/payload';

export * from './gitHubClient';
export * from './gitHubApi';
export * from './types/payload';
export * from './types/responses';

export const sortByTotalCount = (
  a: IAppRepositoryVulnerability,
  b: IAppRepositoryVulnerability,
) => b.totalCount - a.totalCount;
