import React from 'react';
import { Link } from '@backstage/core-components';
import { Chip, ListItem, makeStyles, Typography } from '@material-ui/core';
import { TrackedInteraction } from 'plugin-ui-components';
import { StatusIcons } from '../common';
import { getColorByStatus } from '../../utils/pipeline';
import { getAliasFromDomain } from '../../utils/domains';
import { WidgetPipeline } from './utils';

export const useStyles = makeStyles(theme => ({
  icon: {
    flexShrink: 0,
    height: '40px',
    width: '40px',
    borderRadius: '100%',
    border: `3px solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 'auto 1 1',
    margin: theme.spacing(0, 2),
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}));

export const PipelineItem = (props: WidgetPipeline) => {
  const { commit_message, pr_title, status, target_repo, id } = props;

  const classes = useStyles();
  const [domain, org, repo] = target_repo.split('/');
  const domainAlias = getAliasFromDomain(domain);

  return (
    <TrackedInteraction
      plugin="start-page"
      eventLabel="Click on pipeline activity"
      eventAction={`Click on pipeline activity ${target_repo}`}
      eventCategory="Start Page Widget 'Pipeline Activity'"
      interaction="onClick"
    >
      <ListItem
        button
        component={Link}
        to={`/cdp/${domainAlias}/${org}/${repo}/${id}`}
        color="inherit"
      >
        <div
          className={classes.icon}
          title={status}
          style={{ borderColor: getColorByStatus(status) }}
        >
          <StatusIcons status={status} />
        </div>

        <div className={classes.content}>
          <Typography
            className={classes.title}
            title={pr_title || commit_message || 'n/a'}
          >
            {pr_title || commit_message}
          </Typography>

          <div className={classes.text}>
            <Chip
              className={classes.chip}
              size="small"
              label={status.toLowerCase()}
            />

            <Typography variant="body2">{target_repo}</Typography>
          </div>
        </div>

        <Typography classes={{ root: 'review-text' }} variant="button">
          View
        </Typography>
      </ListItem>
    </TrackedInteraction>
  );
};
