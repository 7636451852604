import React from 'react';
import { Alert, AlertProps, Color } from '@material-ui/lab';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

const markdownOptions: MarkdownToJSX.Options = {
  overrides: {
    a: {
      props: {
        target: 'blank',
        rel: 'noopener noreferrer nofollow',
      },
    },
  },
};

type Props = Partial<IAppReview.Issue> & AlertProps;

export const Issue: React.FC<Props> = ({ message, type, ...props }) => {
  const severity: Color = type === 'blocker' ? 'error' : (type as Color);

  if (message && severity) {
    return (
      <Alert {...props} severity={severity}>
        <Markdown options={markdownOptions}>{message}</Markdown>
      </Alert>
    );
  }

  return <></>;
};
