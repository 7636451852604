import React from 'react';

import AdjustIcon from '@material-ui/icons/Adjust';
import TodayIcon from '@material-ui/icons/Today';
import LaptopIcon from '@material-ui/icons/Laptop';
import TrafficIcon from '@material-ui/icons/Traffic';
import HotelIcon from '@material-ui/icons/Hotel';
import StorageIcon from '@material-ui/icons/Storage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { UserReference } from 'plugin-ui-components';

import { UserIcon } from '../../../../common';
import { getFormattedDateTime } from '../../../../utils/time';
import { getIconByStepType } from '../../../../utils/pipeline';
import { STEP_TYPE } from '../../../../constants';
import { RunStepModel } from '../../../../models';

export function getStepDetails(step: RunStepModel) {
  const common = [
    {
      icon: <AdjustIcon />,
      value: step.run.id,
      text: `Run ID: ${step.run.id}`,
    },
    {
      icon: <AdjustIcon />,
      value: step.run.status,
      text: `Status: ${step.run.status} ${
        step.traffic_paused ? '(paused)' : ''
      }`,
    },
    {
      icon: getIconByStepType(step.type),
      value: step.type,
      text: `Type: ${step.type}`,
    },
    {
      icon: <UserIcon />,
      value: step.accountable_user,
      text: (
        <>
          Accountable user:&nbsp;
          <UserReference user={step.accountable_user} displayType="login" />
        </>
      ),
    },
    {
      icon: <TodayIcon />,
      value: step.run.created_at,
      text: `Started at: ${getFormattedDateTime(step.run.created_at)}`,
    },
    {
      icon: <TodayIcon />,
      value: step.run.finished_at,
      text: `Finished at: ${getFormattedDateTime(step.run.finished_at)}`,
    },
  ];

  const deployment = {
    icon: <CloudUploadIcon />,
    value: step.id,
    text: `Deployment: ${step.id}`,
  };

  const cluster = {
    icon: <StorageIcon />,
    value: step.target_cluster,
    text: `Target: ${step.target_cluster}`,
  };

  const trafficData = [
    deployment,
    cluster,
    {
      icon: <TrafficIcon />,
      value: step.target_percentage,
      text: `Target traffic: ${step.target_percentage}%`,
    },
    {
      icon: <AdjustIcon />,
      value: step.stackset,
      text: `Stackset: ${step.stackset}`,
    },
    {
      icon: <AdjustIcon />,
      value: step.stack_version,
      text: `Stack version: ${step.stack_version}`,
    },
  ];

  switch (step.type) {
    case STEP_TYPE.SCRIPT:
      return [
        ...common,
        {
          icon: <LaptopIcon />,
          value: step.vm,
          text: `VM: ${step.vm}`,
        },
      ];
    case STEP_TYPE.PROCESS:
    case STEP_TYPE.DEPLOY:
      return [...common, deployment, cluster];
    case STEP_TYPE.TRAFFIC:
      return [...common, ...trafficData];
    case STEP_TYPE.SLEEP:
      return [
        ...common,
        {
          icon: <HotelIcon />,
          value: step.run.sleep_until,
          text: `Sleep until: ${getFormattedDateTime(step.run.sleep_until)}`,
        },
      ];
    default:
      return common;
  }
}
