import axios from 'axios';
import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

/** Personalization API */
export class PersonalizationApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: {
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
    identityApi: Sunrise.ZalandoIdentityApi;
  }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  /**
   * Retrieve the list of watched teams for the current user
   * @returns The list of teams watched by the current user
   */
  async getWatchedTeams(): Promise<IEntityGroup[]> {
    const url = `${await this.personalizationUrl}/watched-teams`;
    const headers = await this.authHeader;
    return axios.get<IEntityGroup[]>(url, { headers }).then(d => d.data);
  }

  /**
   * Update the list of watched teams for the current user
   * @param value The list of team IDs to be synced
   * @returns The new list of teams watched by the current user after the update
   */
  async updateWatchedTeams(value: string[]): Promise<IEntityGroup[]> {
    const url = `${await this.personalizationUrl}/watched-teams`;
    const headers = await this.authHeader;
    return axios.put<IEntityGroup[]>(url, value, { headers }).then(d => d.data);
  }

  /**
   * Remove a team or more from the current user's watched list
   * @param value A team ID or a list of team IDs to be removed
   * @returns The new list of teams watched by the current user after the update
   */
  async removeWatchedTeams(value: string | string[]) {
    const url = `${await this.personalizationUrl}/watched-teams`;
    const headers = await this.authHeader;
    const teams = Array.isArray(value) ? value.join(',') : value;
    return axios.delete(url, { headers, params: { teams } });
  }

  /**
   * Retrieve the list of unfollowed accountable teams for the current user
   * @returns The list of accountable teams unfollowed by the current user
   */
  async getUnfollowedTeams(): Promise<IEntityGroup[]> {
    const url = `${await this.personalizationUrl}/unwatched-accountable-teams`;
    const headers = await this.authHeader;
    return axios.get<IEntityGroup[]>(url, { headers }).then(d => d.data);
  }

  /**
   * Update the list of unfollowed accountable teams for the current user
   * @param value The list of team IDs to be synced
   * @returns The new list of accountable teams unfollowed by the current user after the update
   */
  async updateUnfollowedTeams(value: string[]): Promise<IEntityGroup[]> {
    const url = `${await this.personalizationUrl}/unwatched-accountable-teams`;
    const headers = await this.authHeader;
    return axios.put<IEntityGroup[]>(url, value, { headers }).then(d => d.data);
  }

  /**
   * Remove a team or more from the current user's unfollowed accountable teams list
   * @param value A team ID or a list of team IDs to be removed
   * @returns The new list of accountable teams unfollowed by the current user after the update
   */
  async removeUnfollowedTeams(value: string | string[]) {
    const url = `${await this.personalizationUrl}/unwatched-accountable-teams`;
    const headers = await this.authHeader;
    const teams = Array.isArray(value) ? value.join(',') : value;
    return axios.delete(url, { headers, params: { teams } });
  }

  /**
   * Retrieve the list of all teams that falls under
   * a team or a list of teams' responsibility
   * @param {string[]} teamsRefs A list of teams refs
   */
  async getAccountableTeams(teamsRefs: string[]) {
    const token = await this.oauth2Api.getAccessToken();
    const catalogUrl = await this.discoveryApi.getBaseUrl('catalog');
    return axios
      .get<IEntityGroup[]>(`${catalogUrl}/hierarchy?ref=${teamsRefs}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(d => d.data);
  }

  /** Backend personalization plugin base URL */
  private get personalizationUrl() {
    return new Promise(async resolve =>
      resolve(await this.discoveryApi.getBaseUrl('personalization')),
    );
  }

  /** Auth headers with bearer token */
  private get authHeader(): Promise<object> {
    return new Promise(async resolve =>
      resolve({
        Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
      }),
    );
  }
}

export const personalizationApiRef = createApiRef<PersonalizationApi>({
  id: 'personalization',
});
