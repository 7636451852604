import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useApi } from '@backstage/core-plugin-api';
import {
  techRadarApiRef,
  TechRadarComponent,
} from '@backstage-community/plugin-tech-radar';
import { PluginTracking } from 'plugin-ui-components';
import { useAsync } from 'react-use';
import { TechRadarWrapper } from './styles';
import { Header, Page } from '@backstage/core-components';

type TeamsOverviewProps = { asPage?: boolean };
export const TechRadar = ({ asPage = true }: TeamsOverviewProps) => {
  const techRadarClient = useApi(techRadarApiRef);

  const { loading, value, error } = useAsync(
    () => techRadarClient.load(undefined),
    [techRadarClient],
  );

  const clickListener = React.useCallback(
    async (event: React.MouseEvent) => {
      const anchor = (event.target as HTMLElement).closest('a');

      if (anchor && anchor.getAttribute('href')) {
        event.preventDefault();
        const href: string = anchor.getAttribute('href') as string;

        if (href && href !== '#') {
          const entry = value?.entries.find(e => e.url === href);

          if (entry) {
            PluginTracking.sendEvent({
              plugin: 'tech-radar',
              eventCategory: 'Tech Radar Page',
              eventLabel: `${entry.quadrant} > ${entry.title}`,
              eventAction: 'click',
            });
          }

          window.open(href, '_blank');
        }
      }
    },
    [value],
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        Something went wrong <pre>{error.message}</pre>
      </Alert>
    );
  }

  return (
    <Page themeId="techradar">
      {asPage && (
        <Header
          title="Zalando Tech Radar"
          subtitle="Discover & learn more about the Technology used at Zalando"
        />
      )}
      <TechRadarWrapper
        onClickCapture={clickListener}
        style={{ padding: asPage ? '1.5rem' : 'auto' }}
      >
        <TechRadarComponent width={1500} height={800} />
      </TechRadarWrapper>
    </Page>
  );
};
