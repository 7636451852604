import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import qs from 'query-string';
import { QueryName, QueryNameObject } from 'plugin-core';

export function useUpdateQueryString() {
  const navigate = useNavigate();

  return useCallback(
    (value: Partial<QueryNameObject>, reset: boolean = false) => {
      const url = new URL(location.href);
      const parsedQuery = {
        ...qs.parse(reset ? '' : location.search),
        ...value,
      };
      for (const key in parsedQuery) {
        if (!parsedQuery[key as QueryName]) {
          delete parsedQuery[key as QueryName];
        }
      }
      url.search = qs.stringify(parsedQuery, { arrayFormat: 'comma' });
      navigate(url.pathname + url.search === '#' ? '' : url.search);
    },
    [navigate],
  );
}
