import { Entity } from '@backstage/catalog-model';

export function makeEntityUrl(entity: IEntity | Entity) {
  const {
    kind,
    metadata: { name, namespace = 'default' },
  } = entity;

  return `/catalog/${namespace}/${kind}/${name}`;
}
