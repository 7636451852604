import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    borderRadius: 4,
  },
  callToActionButton: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  darkSnackbar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  orangeSnackbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  linearProgress: {
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textField: {
    width: '100px',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  docsButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
