import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@backstage/core-plugin-api';
import { TestsTableComponent } from './TestsTableComponent';
import { NotFoundPage } from 'plugin-ui-components';

export const LoadTestingPageWrapper = () => {
  const featureFlags = useApi(featureFlagsApiRef);
  const loadTestingSource = featureFlags.isActive('load-testing');

  return loadTestingSource ? <TestsTableComponent /> : <NotFoundPage />;
};
