import {
  OAuthApi,
  createApiRef,
  DiscoveryApi,
} from '@backstage/core-plugin-api';

export interface IClusterRegistryApi {
  getCluster: (id: string) => Promise<IAccessControl.KubernetesCluster>;
  getClusters: () => Promise<
    Record<string, IAccessControl.KubernetesCluster[]>
  >;
  getAccounts: (
    environment?: string,
    status?: string,
  ) => Promise<Record<string, IAccessControl.InfrastructureAccount[]>>;
}

export const clusterRegistryApiRef = createApiRef<IClusterRegistryApi>({
  id: 'plugin-application-registry-cluster-registry',
});

export class ClusterRegistryApi implements IClusterRegistryApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getCluster(id: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const response = await fetch(
      `${proxyUrl}/cluster-registry/kubernetes-clusters/${id}`,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );
    return await response.json();
  }

  async getClusters() {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const response = await fetch(
      `${proxyUrl}/cluster-registry/kubernetes-clusters?lifecycle_status=ready&verbose=false`,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );
    return await response.json();
  }

  async getAccounts(environment?: string, status?: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const envParam = environment ? `environment=${environment}` : '';
    const statusParam = status ? `lifecycle_status=${status}` : '';
    const response = await fetch(
      `${proxyUrl}/cluster-registry/infrastructure-accounts?${envParam}&${statusParam}`,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );
    return await response.json();
  }
}
