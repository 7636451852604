import axios, { AxiosError } from 'axios';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { DocQuestions, SearchResult, type Namespace } from './types';

export class NLInterfaceApiClient {
  public readonly oauth2Api: OAuthApi;
  public readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getDocQuestions(count: number): Promise<DocQuestions> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.get(`${baseUrl}/questions?count=${count}`, {
        headers: {
          Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async getNamespaces(): Promise<Namespace[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.get(`${baseUrl}/namespaces`, {
        headers: {
          Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async search(
    text: string,
    { namespace = 'build' }: Namespace,
  ): Promise<SearchResult> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.post(
        `${baseUrl}/search`,
        { text, namespace },
        {
          headers: {
            Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  private reportError(error: AxiosError) {
    return new Error(
      (error?.response?.data as string) ?? error?.message ?? 'Unknown error',
    );
  }
}
