import { getTeamNameFromFullName } from '../string';
import { getEntityRelations } from '@backstage/plugin-catalog-react';

/** Returns true if tag related to cyber week */
export function isCyberWeekRelatedTag(tag: string): Boolean {
  return ['event', 'post-event', 'pre-event'].includes(tag);
}

/** Returns the cyber week Toer org code associated with the entity */
export function collectToer(entity: IEntity): string {
  const toerRelation = getEntityRelations(entity, 'toerBy');
  if (toerRelation.length === 0) return 'NONE';
  return toerRelation[0].name.toUpperCase();
}

/** Find all possible Toers and provide them in a sorted list. */
export function collectToers(entities?: IEntity[]): string[] {
  const toers = new Set<string>();
  (entities || []).forEach(e => {
    const toer = collectToer(e);
    if (toer !== '') toers.add(toer);
  });
  // Add also the entry for no Toer available
  toers.add('NONE');
  return Array.from(toers).sort();
}

/** Find all possible tags and provide them in a sorted list. */
export function collectTags(entities?: IEntityComponent[]): string[] {
  const tags = new Set<string>();
  (entities || []).forEach(e => {
    if (e.metadata?.tags) {
      (e.metadata?.tags || []).forEach(t => tags.add(t));
    }
  });

  const filteredTags = Array.from(tags).filter(v => !isCyberWeekRelatedTag(v));

  return filteredTags.sort();
}

/** Find all possible teams and provide them in a sorted list. */
export function collectTeams(entities?: IEntityComponent[]): string[] {
  const teams = new Set<string>();
  (entities || []).forEach(e => {
    const teamName =
      (e.spec?.spec as IEntityAPPSpecSpec)?.owner_name ||
      getTeamNameFromFullName((e.spec as IEntityGroupSpec)?.fullName) ||
      '';
    const teamId = (e.spec?.spec as IEntityAPPSpecSpec)?.team_id;
    if (teamName) teams.add(teamName);
    if (teamId) teams.add(teamId);
  });

  if (
    entities &&
    entities.length > 0 &&
    (entities[0].kind === 'Component' ||
      entities[0].kind === 'API' ||
      entities[0].kind === 'Documentation')
  ) {
    teams.add('Invalid Team');
  }
  return Array.from(teams).sort();
}

/** Find all possible Document CCategories and provide them in a sorted list. */
export function collectDocsCategory(entities?: IEntity[]): string[] {
  const docsCategories = new Set<string>();
  (entities || []).forEach(e => {
    const docsCategoriesName = (e.spec as IEntityDOCSpec)?.category;
    if (docsCategoriesName && docsCategoriesName !== 'unknown')
      docsCategories.add(docsCategoriesName);
  });
  return Array.from(docsCategories).sort();
}

/** Find all possible lifecycles and provide them in a sorted list. */
export function collectLifecycles(entities?: IEntityComponent[]): string[] {
  const lifecycles = new Set<string>();
  (entities || []).forEach(e => {
    if (e.spec?.owner) {
      lifecycles.add((e.spec as IEntityAppSpec)?.lifecycle);
    }
  });
  return Array.from(lifecycles).sort();
}

/** Find all possible types of API and provide them in a sorted list. */
export function collectTypes(entities?: IEntityComponent[]): string[] {
  const types = new Set<string>();
  (entities || []).forEach(e => {
    if (e.spec?.type) {
      types.add(e.spec?.type as string);
    }
  });
  return Array.from(types).sort();
}

export function collectCostCenters(entities?: IEntityComponent[]): string[] {
  const costCenters = new Set<string>();
  (entities || []).forEach(e => {
    const costCenter =
      (e.spec?.zalando as IEntityZalandoSpec)?.costCenter ?? '';
    if (costCenter) costCenters.add(costCenter);
  });
  return Array.from(costCenters).sort();
}

export function collectIdsAndAliases(
  entities?: IEntityComponent[],
): (string | number)[] {
  const idsAndAliases = new Set<string | number>();
  (entities || []).forEach(e => {
    const alias = (e.spec as IEntityGroupSpec)?.alias;
    const teamId = (e.spec as IEntityGroupSpec)?.team_id;
    if (typeof alias === 'string') {
      idsAndAliases.add(alias);
    } else if (Array.isArray(alias)) {
      alias.forEach(a => idsAndAliases.add(a));
    }
    if (teamId) idsAndAliases.add(teamId);
  });
  return Array.from(idsAndAliases).sort();
}

export function collectJourney(entities?: IEntityComponent[]): string[] {
  const journeySet = new Set<string>();
  ((entities as IEntityTooling[]) || []).forEach(entity => {
    if (entity.spec && entity.spec.journey)
      entity.spec.journey.forEach(step => journeySet.add(step));
  });
  return Array.from(journeySet).sort();
}

export function collectToolingStatus(entities?: IEntityComponent[]): string[] {
  const statusSet = new Set<string>();
  ((entities as IEntityTooling[]) || []).forEach(entity => {
    if (entity.spec) statusSet.add(entity.spec.status);
  });
  return Array.from(statusSet).sort();
}

export function collectStep(entities?: IEntityComponent[]): string[] {
  const stepsSet = new Set<string>();
  ((entities as IEntityTooling[]) || []).forEach(entity => {
    if (entity.spec && entity.spec.step)
      entity.spec.step.forEach(step => stepsSet.add(step));
  });
  return Array.from(stepsSet).sort();
}

export function collectToolingOwners(entities?: IEntityComponent[]): string[] {
  const ownerSet = new Set<string>();
  ((entities as IEntityTooling[]) || []).forEach(entity => {
    if (entity.spec) ownerSet.add(entity.spec.owner);
  });
  return Array.from(ownerSet).sort();
}

export function collectScorecards(
  entities: IEntityComponent[] | undefined,
): string[] {
  const scorecards = new Set<string>();
  for (const entity of entities || []) {
    const scorecardRelations = getEntityRelations(entity, 'consumesScorecard');
    scorecardRelations.forEach(rel => scorecards.add(rel.name));
  }
  return Array.from(scorecards).sort();
}
