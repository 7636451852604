import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { TeamsFilter } from './filters';
import { FilterGroupsContext } from 'plugin-ui-components';
import { DocsCategoryFilter } from './filters/DocsCategoryFilter';

/** The additional results filter in the sidebar. */
export const DocsResultsFilter = () => {
  const {
    setTypeFilter,
    setTeamsFilter,
    setLifecycleFilter,
    setDocsCategoryFilter,
  } = React.useContext(FilterGroupsContext);

  const onClearFilters = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setTypeFilter('');
    setTeamsFilter([]);
    setDocsCategoryFilter([]);
    setLifecycleFilter('');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="1.5rem"
      gridRowGap="0.5rem"
      padding="0.5rem"
    >
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
          Refine Results
        </Typography>
        <Button
          onClick={onClearFilters as any}
          style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
          variant="text"
        >
          Clear
        </Button>
      </Box>
      <TeamsFilter />
      <DocsCategoryFilter />
    </Box>
  );
};
