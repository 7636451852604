import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { LoadTestStats } from './LoadTestStats';
import { SummaryCard } from '../common';

export function LoadTestsSummary() {
  const category = 'Load tests';

  return (
    <SummaryCard category={category}>
      <CardContent>
        <Grid container>
          <Grid item xs={3}>
            <Typography>Time of last load test</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              <LoadTestStats />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </SummaryCard>
  );
}
