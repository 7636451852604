import React from 'react';
import { Tooltip } from '../../../../../common';

import * as S from './styles';

export function Element({
  text,
  label,
}: {
  text: string | React.ReactNode;
  label: string;
}) {
  return (
    <S.Element>
      <Tooltip title={label}>
        <span>{text}</span>
      </Tooltip>
    </S.Element>
  );
}
