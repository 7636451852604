import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ModelDetails } from './components/pages/ModelDetails';
import { ModelVersionDetailsPage } from './components/ModelVersions/Details';
import { ModelsPage } from './components/pages/ModelsPage';

export const MLRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ModelsPage />} />
      <Route path="/models?group=mine" element={<ModelsPage />} />
      <Route path="/models/:modelId/*" element={<ModelDetails />} />
      <Route
        path="/models/:modelId/versions/:modelVersionId/*"
        element={<ModelVersionDetailsPage />}
      />
    </Routes>
  );
};
