interface FileValidationOptions {
  types: string[];
  fileSize: number;
  dimensions: {
    width: number;
    height: number;
  };
}

export function getReadableFileSize(size: number): string {
  let unit: string;
  let division: number;
  switch (true) {
    case size < 1024:
      unit = 'B';
      division = 1;
      break;
    case size < 1024 ** 2:
      unit = 'KB';
      division = 1024;
      break;
    case size < 1024 ** 3:
      unit = 'MB';
      division = 1024 ** 2;
      break;
    default:
      unit = 'GB';
      division = 1024 ** 3;
  }
  return `${size / division} ${unit}`;
}

export async function validateFile(
  file: File,
  options?: FileValidationOptions,
): Promise<void> {
  const types = options?.types ?? ['image/jpeg', 'image/jpg', 'image/png'];
  const size = options?.fileSize ?? 1024 ** 2;
  const width = options?.dimensions?.width ?? 100;
  const height = options?.dimensions?.height ?? 100;

  if (file.size > size) {
    throw new Error(
      `File size is too large. Max size is ${getReadableFileSize(size)}`,
    );
  }
  if (!types.includes(file.type)) {
    throw new Error(
      `File type is not supported. Supported types are ${types.join(', ')}`,
    );
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width === 100 && img.height === 100) {
        resolve();
      } else {
        reject(new Error(`Image must be ${width}x${height} px`));
      }
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });
}
