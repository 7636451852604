import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Link, makeStyles } from '@material-ui/core';
import { MarkdownContent } from '@backstage/core-components';

import AdjustIcon from '@material-ui/icons/Adjust';
import TodayIcon from '@material-ui/icons/Today';
import DescriptionIcon from '@material-ui/icons/Description';
import TimerIcon from '@material-ui/icons/Timer';
import MessageIcon from '@material-ui/icons/Message';

import {
  List,
  ListItem,
  InfoCard,
  BranchIcon,
  CommitIcon,
  Emoji,
  PRIcon,
  UserIcon,
  Tooltip,
  PipelineIcon,
} from '../../../../common';

import { Warnings, Error, Grid, GridItem } from '../../../common';
import { ProvidedParameters } from './ProvidedParameters';

import { useServices } from '../../../../services';
import { getDuration, getFormattedDateTime } from '../../../../utils/time';
import { RunModel } from '../../../../models';
import { UserReference } from 'plugin-ui-components';
import { PIPELINE_STATUS } from '../../../../constants';
import { PendingStateMessage } from '../../../common/PendingState';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const ASSOC_PR_NUMBER_INFO = `The number of the first pull request associated with the commit of this push pipeline.`;

const ASSOC_BUILD_VERSION_INFO = `CDP_BUILD_VERSION of the pull request pipeline associated with this push pipeline.`;

const useStyles = makeStyles({
  info: {
    fontSize: '15px',
    verticalAlign: 'middle',
    marginBottom: '1px',
  },
});

function RunInfo({ run }: { run: RunModel }) {
  const styles = useStyles();

  return (
    <InfoCard
      title={
        <span>
          <Emoji name="info" emoji="ℹ️" /> Pipeline run info
        </span>
      }
    >
      <List>
        <ListItem icon={<AdjustIcon />}>Status: {run.status}</ListItem>

        <ListItem icon={<DescriptionIcon />}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${run.source_repo}/blob/${run.commit_id}/delivery.yaml`}
          >
            delivery.yaml
          </Link>
        </ListItem>

        <ListItem icon={<UserIcon />}>
          {!!run.triggered_by_dependency ? (
            `Triggered by dependency: ${run.triggered_by_dependency}`
          ) : (
            <>
              Triggered by:&nbsp;
              <UserReference user={run.accountable_user} displayType="login" />
            </>
          )}
        </ListItem>

        {run.isRetriggered && (
          <ListItem icon={<AdjustIcon />}>
            <span>Retriggered from:&nbsp;</span>

            <Link href={`../${run.retriggered_from_pipeline}/run`}>
              {run.retriggered_from_pipeline_build_version}
            </Link>
          </ListItem>
        )}

        {run.pr_title && (
          <ListItem icon={<PRIcon />}>
            <span>
              {run.pr_title}&nbsp;
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${run.target_repo}/pull/${run.pr_number}`}
              >
                #{run.pr_number}
              </Link>
            </span>
          </ListItem>
        )}

        <ListItem icon={<BranchIcon />}>
          {run.isPullRequest
            ? `${run.target_branch} ← ${run.branch}`
            : `${run.target_repo}`}
        </ListItem>

        {!!run.env_vars.CDP_ASSOCIATED_PR_NUMBER && (
          <ListItem icon={<PRIcon />}>
            <div>
              <span>Associated PR number:&nbsp;</span>

              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${run.target_repo}/pull/${run.env_vars.CDP_ASSOCIATED_PR_NUMBER}`}
              >
                #{run.env_vars.CDP_ASSOCIATED_PR_NUMBER}
              </Link>

              <Tooltip title={ASSOC_PR_NUMBER_INFO}>
                <span>
                  &nbsp;
                  <InfoOutlined color="inherit" className={styles.info} />
                </span>
              </Tooltip>
            </div>
          </ListItem>
        )}

        {!!run.env_vars.CDP_ASSOCIATED_PR_BUILD_VERSION && (
          <ListItem icon={<PipelineIcon />}>
            <div>
              <span>&nbsp;Associated build version:&nbsp;</span>

              {run.env_vars.CDP_ASSOCIATED_PR_BUILD_VERSION}

              <Tooltip title={ASSOC_BUILD_VERSION_INFO}>
                <span>
                  &nbsp;
                  <InfoOutlined color="inherit" className={styles.info} />
                </span>
              </Tooltip>
            </div>
          </ListItem>
        )}

        {run.commit_id && (
          <ListItem icon={<CommitIcon />}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${run.source_repo}/commit/${run.commit_id}`}
            >
              {run.commit_id.slice(0, 6)}
            </Link>
          </ListItem>
        )}

        {run.commit_message && (
          <ListItem icon={<MessageIcon />}>
            <Box margin="-1rem 0">
              <MarkdownContent content={run.commit_message} dialect="gfm" />
            </Box>
          </ListItem>
        )}

        {run.created_at && (
          <ListItem icon={<TodayIcon />}>
            Started at: {getFormattedDateTime(run.created_at)}
          </ListItem>
        )}

        {run.triggered_at && (
          <ListItem icon={<TodayIcon />}>
            Last triggered at: {getFormattedDateTime(run.triggered_at)}
          </ListItem>
        )}

        {run.finished_at && (
          <ListItem icon={<TodayIcon />}>
            Finished at: {getFormattedDateTime(run.finished_at)}
          </ListItem>
        )}

        {Boolean(run.runtime.wall) && (
          <ListItem icon={<TimerIcon />}>
            Wall time: {getDuration(run.runtime.wall)}
          </ListItem>
        )}

        {Boolean(run.runtime.total) && (
          <ListItem icon={<TimerIcon />}>
            Total steps runtime: {getDuration(run.runtime.total)}
          </ListItem>
        )}

        {Boolean(run.runtime.idle) && (
          <ListItem icon={<TimerIcon />}>
            Idle time: {getDuration(run.runtime.idle)}
          </ListItem>
        )}
      </List>
    </InfoCard>
  );
}

export const Details = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <Box padding={2}>
      <Grid>
        <GridItem>
          <RunInfo run={run} />
        </GridItem>

        {run.status === PIPELINE_STATUS.PENDING && (
          <GridItem>
            <InfoCard
              title={
                <span>
                  <Emoji name="list" emoji="⏳" /> Pipeline is pending
                </span>
              }
            >
              <PendingStateMessage
                domain={run.repoInfo.domain}
                org={run.repoInfo.organization}
                repo={run.repoInfo.name}
                branch={run.branch}
              />
            </InfoCard>
          </GridItem>
        )}

        {run.hasProvidedParams && (
          <GridItem>
            <ProvidedParameters parameters={run.provided_parameters} />
          </GridItem>
        )}

        {!!run.error && (
          <GridItem>
            <Error error={run.error} />
          </GridItem>
        )}

        {!!run.warnings.length && (
          <GridItem>
            <Warnings warnings={run.warnings} />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
});
