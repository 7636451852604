import axios from 'axios';
import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

// Repository represents CDP repository, i.e. it contains data of repository that triggers CDP pipeline.
export interface Repository {
  id: string;
  domain: string;
  organization: string;
  name: string;
}

export class CDPRepositoryApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  // getRepository returns the repository known to CDP
  // and should not be used to get GitHub repository information but to understand the context of a CDP pipeline.
  async getRepository(
    domain: string,
    org: string,
    name: string,
  ): Promise<Repository> {
    const path = `cdp/repositories/${domain}/${org}/${name}`;
    const url = `${await this.apiURL}/${path}`;
    const headers = await this.authHeader;
    return axios.get<Repository>(url, { headers }).then(d => d.data);
  }

  private get apiURL() {
    return this.discoveryApi.getBaseUrl('proxy');
  }

  private get authHeader(): Promise<object> {
    return new Promise(async resolve =>
      resolve({
        Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
      }),
    );
  }
}

export const cdpRepositoryApiRef = createApiRef<CDPRepositoryApi>({
  id: 'cdp-repository',
});
