import React from 'react';
import {
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { config, FormFields } from '../config';
import { ReviewContext } from '../context';

type Props = Partial<TextFieldProps> & {
  name: FormFields;
};

function stringToISO(str: string) {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return '';
  }

  return date.toISOString();
}

function isoToSimpleDate(str: string) {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return '';
  }

  // This locale outputs yyyy-mm-dd
  return date.toLocaleDateString('lt');
}

export function DatePicker({
  name,
  helperText,
  children,
  placeholder,
  label,
  ...props
}: Props) {
  const { review, onReviewChange } = React.useContext(ReviewContext);
  const value = isoToSimpleDate(review[name]);

  return (
    <Grid container>
      <Grid item md={3}>
        <FormLabel>{children || config[name]?.label || ''}</FormLabel>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Grid>
      <Grid item md={9}>
        <TextField
          {...props}
          type="date"
          name={name}
          label={placeholder || config[name]?.placeholder || ''}
          value={value}
          onChange={ev => onReviewChange(name, stringToISO(ev.target.value))}
          variant="outlined"
          margin="dense"
          style={{ marginTop: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
