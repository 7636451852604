import React from 'react';
import { Route, Routes } from 'react-router';

import { RunOverview } from './components/RunOverview';
import { DependencyGraph } from './components/DependencyGraph';
import { Details } from './components/Details';
import { DeliveryConfig } from './components/DeliveryConfig';
import { runRoutes } from '../../router';

export function RunDetailsPage() {
  return (
    <Routes>
      <Route path="/" element={<RunOverview />}>
        <Route path="/" element={<Details />} />
        <Route path={runRoutes.pipeline} element={<DependencyGraph />} />
        <Route path={runRoutes.deliveryConfig} element={<DeliveryConfig />} />
      </Route>
    </Routes>
  );
}
