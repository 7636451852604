import { Analysis } from '../types';

export async function settlePromises<T>(promises: Promise<T>[]): Promise<T[]> {
  const settledResults = await Promise.allSettled(promises);
  const fulfilledResults = settledResults.filter(
    result => result.status === 'fulfilled',
  );
  return fulfilledResults.map(
    result => (result as PromiseFulfilledResult<T>).value,
  );
}

export const findActiveApps = (
  reports: Analysis[],
  isActiveList: { isActive: boolean; name: string }[],
): Analysis[] => {
  return reports.filter(report => {
    return isActiveList.some(
      app => app.name === report.repository.name && app.isActive,
    );
  });
};

export const sortAndSliceApplications = (
  applications: Analysis[],
  limit: number,
): Analysis[] => {
  const sortedApplications = applications.sort((a, b) => a.grade - b.grade);
  return sortedApplications.slice(0, limit);
};
