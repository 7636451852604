import { IRepositoryVulnerabilitySeverity } from '../api';
import * as S from '../styles';
import { Typography } from '@material-ui/core';
import React from 'react';

export const DependabotAlertSeveritiesChip = ({
  vulnerabilitiesCount,
}: {
  vulnerabilitiesCount: { [char: string]: number };
}) => {
  return (
    <>
      {Object.keys(vulnerabilitiesCount).map(key => {
        const [severity, count] = [
          key as IRepositoryVulnerabilitySeverity,
          vulnerabilitiesCount[key as IRepositoryVulnerabilitySeverity],
        ];
        return (
          count > 0 && (
            <S.SeverityChip
              severity={severity}
              size="small"
              variant="outlined"
              label={
                <Typography variant="caption">
                  <S.SeverityText severity={severity}>{count}</S.SeverityText>{' '}
                  {severity.toLowerCase()}
                </Typography>
              }
            />
          )
        );
      })}
    </>
  );
};
