import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';

export const getModelOwnerColumns = (): TableColumn[] => {
  return [
    {
      title: 'Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Contact',
      field: 'contact',
      filtering: false,
      render(row: any): React.ReactNode {
        return <Link to={`mailto:${row.contact}`}>{row.contact}</Link>;
      },
    },
  ];
};
