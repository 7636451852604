import React, { useEffect, useState } from 'react';
import { Table } from '@backstage/core-components';
import { useTheme } from '@material-ui/core';
import { zflowApiRef } from '../../api/zflowApiClient';
import { useNavigate } from 'react-router';
import { GetModelsResponse } from '../../api/definitions';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { PLACEHOLDER_ML_MODELS } from '../../api/PlaceholderResponses';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { tableHeaderStyles } from '../../styles';
import { getModelListColumns } from '../common/columns/ModelListColumns';
import { useApi } from '@backstage/core-plugin-api';

export const ModelList = ({ modelGroupId }: { modelGroupId: string }) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();

  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [models, setModels] = useState<GetModelsResponse>(
    PLACEHOLDER_ML_MODELS,
  );
  useEffect(() => {
    const offset = PAGE_SIZE * page;
    zflowApi
      .getModels(modelGroupId, PAGE_SIZE, offset)
      .then(setModels)
      .then(() => setIsLoading(false));
  }, [zflowApi, modelGroupId, page]);

  return (
    <Table
      data={models?.models}
      columns={getModelListColumns()}
      options={{
        pageSize: PAGE_SIZE,
        showTitle: false,
        filtering: true,
        search: false,
        showFirstLastPageButtons: true,
        emptyRowsWhenPaging: false,
        headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
      }}
      onRowClick={(_, rowData: any) => {
        navigate(`${rowData.model_id}`);
      }}
      isLoading={isLoading}
      components={{
        Pagination: props => (
          <MLTablePagination
            props={props}
            page={page}
            resource={models}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
          />
        ),
        Toolbar: props => <MLTableToolbar props={props} />,
      }}
    />
  );
};
