import { useApi } from '@backstage/core-plugin-api';
import { librariesApiRef } from '../api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useCallback } from 'react';
import { FetchLibrariesOptions } from '../types';

export const useFetchLibraries = (options: FetchLibrariesOptions) => {
  const { limit, name, version, application, cursor: optionsCursor } = options;

  const catalogApi = useApi(catalogApiRef);
  const librariesApi = useApi(librariesApiRef);

  const fetchLibraries = useCallback(
    async (nextCursor?: string) => {
      let cursor: string | undefined;
      if (nextCursor) {
        cursor = new URL(nextCursor).searchParams.get('cursor') || '';
      } else if (optionsCursor) {
        cursor = new URL(optionsCursor).searchParams.get('cursor') || '';
      }

      const librariesResponse = await librariesApi.getLibraries({
        limit,
        name,
        version,
        application,
        cursor: cursor || undefined,
      });

      // If there is no response or items are empty, return right away
      if (
        !librariesResponse ||
        !librariesResponse.items ||
        librariesResponse.items.length === 0
      ) {
        return librariesResponse;
      }

      // Mock the total to always be 20
      librariesResponse.total = 3000;

      const uniqueApplications = new Set(
        librariesResponse.items.flatMap(library =>
          library.applications.map(app => `${app.replace(/[{}]/g, '')}`),
        ),
      );

      const applicationEntitiesResponse = await catalogApi.getEntitiesByRefs({
        entityRefs: Array.from(uniqueApplications).map(
          app => `component:default/${app}`,
        ),
      });

      const librariesWithApplications = (
        await Promise.all(
          librariesResponse.items.map(async library => {
            const applicationEntities = library.applications
              .map((app: string) => {
                const foundEntity = applicationEntitiesResponse?.items?.find(
                  item =>
                    item &&
                    item.metadata.name === `${app.replace(/[{}]/g, '')}`,
                );
                return foundEntity;
              })
              .filter(Boolean);

            return { ...library, applications: applicationEntities };
          }),
        )
      )
        .filter(library => library.applications.length > 0) // filter out libraries with 0 applications
        .filter(library => library.name && library.name.trim() !== ''); // filter out libraries with no name or empty name

      return { ...librariesResponse, items: librariesWithApplications };
    },
    [
      limit,
      name,
      version,
      application,
      optionsCursor,
      catalogApi,
      librariesApi,
    ],
  );

  return fetchLibraries;
};
