import { observable, makeObservable, action } from 'mobx';

import { LOGS_LINES_LIMIT } from '../../constants';
import { LogsLineModel } from './line.model';
import { ILogFoldLines } from '../../api/types/payload';
import { LogsState } from './logs.state';

export class LogsLinesModel {
  linesState: LogsState;
  lines: Array<LogsLineModel>;
  warning: boolean;

  constructor() {
    this.linesState = new LogsState();
    this.lines = [];
    this.warning = false;

    makeObservable(this, {
      linesState: observable,
      lines: observable,
      warning: observable,
      insertLines: action,
    });
  }

  insertLines = (lines: Array<ILogFoldLines>, isFlat: boolean) => {
    // Do not filter empty lines because can be the last one and we need it to check if we retrieved all the logs or not.
    const newLines = lines.flatMap(line => {
      let strContent = '';

      if (line.message) {
        try {
          const { content } = JSON.parse(line.message);
          strContent = isFlat ? content : line.message;
        } catch (error) {
          strContent = line.message;
        }
      }

      return new LogsLineModel(strContent, line.timestamp);
    });

    if (
      this.lines.length > 0 &&
      newLines.length > 0 &&
      this.lines[this.lines.length - 1].isEqual(newLines[0])
    ) {
      newLines.shift();
    }

    this.lines = this.lines.concat(newLines);
    this.warning = this.lines.length >= LOGS_LINES_LIMIT;

    if (this.warning) {
      this.lines = this.lines.splice(0, LOGS_LINES_LIMIT);
    }
  };
}
