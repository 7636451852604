import { OAuthApi, DiscoveryApi } from '@backstage/core-plugin-api';
import {
  PullRequestsResponse,
  IssuesResponse,
  OrganizationsResponse,
  RepositoriesResponse,
  RepositoryVulnerabilityResponse,
} from './types/responses';
import { GitHubApi } from './gitHubApi';
import { throwReadableError } from 'plugin-core';

export class GitHubClient implements GitHubApi {
  private readonly authApi: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { authApi: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.authApi = options.authApi;
    this.discoveryApi = options.discoveryApi;
  }

  async fetchRepositories(orgs: string): Promise<RepositoriesResponse> {
    const path = '/repositories';
    return await this.get<RepositoriesResponse>(path, { orgs });
  }

  async fetchOrganizations(): Promise<OrganizationsResponse> {
    const path = '/organizations';
    return await this.get<OrganizationsResponse>(path);
  }

  async fetchPullRequests(): Promise<PullRequestsResponse> {
    const path = '/pull-requests';
    return await this.get<PullRequestsResponse>(path);
  }

  async fetchIssues(): Promise<IssuesResponse> {
    const path = '/issues';
    return await this.get<IssuesResponse>(path);
  }

  async fetchVulnerabilities(
    appName?: string,
  ): Promise<RepositoryVulnerabilityResponse> {
    let path = '/vulnerabilities';
    if (appName) path = `${path}/${appName}`;
    return await this.get<RepositoryVulnerabilityResponse>(path);
  }

  private async get<T>(path: string, params: any = {}): Promise<T> {
    const baseUrl = await this.discoveryApi.getBaseUrl('github');
    const url: string = `${baseUrl}${path}`;
    const token: string = await this.authApi.getAccessToken();
    const urlParams: URLSearchParams = new URLSearchParams(params);

    const response = await fetch(`${url}?${urlParams}`, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    });

    if (!response.ok) throwReadableError(response);

    return await response.json();
  }
}
