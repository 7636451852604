import React from 'react';
import * as S from './styles';

interface Props {
  children: React.ReactNode;
  columns: Array<string>;
}

export function GridLayout({ children, columns }: Props) {
  return (
    <S.RunListLayout>
      <div>
        <S.RunHeader>
          {columns.map((column, index) => (
            <S.StyledGridColumn header key={index}>
              <S.TextFlat>{column}</S.TextFlat>
            </S.StyledGridColumn>
          ))}
        </S.RunHeader>
      </div>

      <S.RunListContent>{children}</S.RunListContent>
    </S.RunListLayout>
  );
}
