import React from 'react';
import { Grid } from '@material-ui/core';
import { Header, Page, Content, HeaderLabel } from '@backstage/core-components';
import SupportCategory from '../SupportCategory';
import softwareDevelopmentSupportList from '../supportLists/softwareDevelopment';
import dataEngineeringScienceAnalysisSupportList from '../supportLists/dataEngineeringScienceAnalysis';

export const SupportPage = (): JSX.Element => (
  <Page themeId="support">
    <Header
      title="Support for Builder tooling & Services"
      subtitle="These links are provided by the owners of the tooling and therefore your most reliable channel for support."
    >
      <HeaderLabel label="Owner" value="Team Pitchfork" />
    </Header>
    <Content>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <SupportCategory
            title="Software Development"
            list={softwareDevelopmentSupportList}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SupportCategory
            title="Data Engineering, Science and Analysis"
            list={dataEngineeringScienceAnalysisSupportList}
          />
        </Grid>
      </Grid>
    </Content>
  </Page>
);
