import React from 'react';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../services';

import { DeploymentStatusDeploy } from './DeploymentStatusDeploy';
import { DeploymentStatusProcess } from './DeploymentStatusProcess';
import { STEP_TYPE } from '../../../../constants';

export const DeploymentStatus = observer(() => {
  const { runService } = useServices();

  switch (runService.step.type) {
    case STEP_TYPE.PROCESS:
      return <DeploymentStatusProcess />;
    case STEP_TYPE.DEPLOY:
      return <DeploymentStatusDeploy />;
    default:
      return <></>;
  }
});
