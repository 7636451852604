import React from 'react';
import { SectionRadio } from '../common';
import { PlatformsAndLanguagesStats } from './PlatformsAndLanguagesStats';

export function TechStack({
  hideConfirmationSection,
}: {
  hideConfirmationSection?: boolean;
}) {
  return (
    <section>
      <dl>
        <dt>Platforms/Languages</dt>
        <dd>
          <PlatformsAndLanguagesStats />
        </dd>
      </dl>

      {!hideConfirmationSection && <SectionRadio name="tech_ok" />}
    </section>
  );
}
