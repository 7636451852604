import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    fontSize: 14,
    '& > div:nth-of-type(1)': {
      zIndex: 11,
    },
  },
  icon: {
    fontSize: '1.1em',
    verticalAlign: 'bottom',
    cursor: 'help',
    color: theme.palette.primary.main,
  },
  ribbonWrapper: {
    '& > div': {
      zIndex: 200,
      pointerEvents: 'none',
      '& svg': {
        filter: `drop-shadow(0 3px 3px rgba(0,0,0,0.2))`,
      },
      '& span': {
        width: 'fit-content',
        height: 'fit-content',
        top: '30%',
        right: '20%',
        pointerEvents: 'auto',
        '& > span': {
          cursor: 'help',
        },
      },
    },
    '&.page > div': {
      top: 0,
      right: 0,
      '& svg': {
        '& polygon': {
          '&:first-child': {
            display: 'none',
          },
          '&:last-child': {
            display: 'none',
          },
        },
      },
    },
  },
}));
