import { useContext, useState, useEffect } from 'react';
import { ReviewSummaryContext } from '../components/context';
import { isCommentsField, isConfirmationField } from '../utils/fields';
import { config } from '../components/config';

interface IData {
  okKey: string;
  commentsKey: string;
  categoryKeys: string[];
}

export function useCategoryKeys(category: IAppReview.ReviewCategory) {
  const { review } = useContext(ReviewSummaryContext);
  const [data, setData] = useState<IData>({
    okKey: '',
    commentsKey: '',
    categoryKeys: [],
  });

  useEffect(() => {
    let okKey: string = '';
    let commentsKey: string = '';
    const categoryKeys: string[] = [];

    Object.keys(review).forEach(key => {
      if (config[key]?.category === category) {
        if (isCommentsField(key)) {
          commentsKey = key;
        } else if (isConfirmationField(key)) {
          okKey = key;
        } else {
          categoryKeys.push(key);
        }
      }
    });

    setData({ okKey, commentsKey, categoryKeys });
  }, [category, review]);

  return data;
}
