import { Theme, styled } from '@material-ui/core';

export const Text = styled('span')((props: { theme: Theme }) => ({
  marginRight: '10px',
  verticalAlign: 'middle',
  fontSize: '.8rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0,
  height: '20px',
  lineHeight: '20px',
  letterSpacing: '.2px',

  ['&, &:visited']: {
    color: props.theme.palette.text.secondary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },

  ['& svg']: {
    fontSize: 11,
    verticalAlign: 'middle',
    marginRight: '2px',
    marginBottom: '2px',
  },
}));

export const TagGroup = styled('span')({
  display: 'flex',
  width: '100%',
  alignItems: 'baseline',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& > *': {
    marginRight: '8px',
  },
});
