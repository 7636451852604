import React from 'react';
import {
  PluginTracking,
  TrackedInteraction,
  TrackedLink,
} from 'plugin-ui-components';
import { Link, TableColumn, TableProps } from '@backstage/core-components';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  attemptEditWhenDisabled,
  discoverDomain,
  discoverDomainUser,
} from '../../../tracking';
import { DisabledEditIcon } from './styles';

export const makeColumns = (
  widgetName: string,
): TableColumn<IEntityDomain>[] => [
  {
    title: 'Name',
    render: ({ metadata: { name, title } }) => (
      <TrackedInteraction {...discoverDomain(name, widgetName)}>
        <Link to={`/catalog/default/Domain/${name}`}>{title}</Link>
      </TrackedInteraction>
    ),
  },
  {
    title: 'Owner',
    render: ({
      spec: {
        owner,
        spec: { owner_name },
      },
      metadata: { name },
    }) => (
      <TrackedLink
        href={`/catalog/default/User/${parseEntityRef(owner).name}`}
        {...discoverDomainUser(name, widgetName, 'owner')}
      >
        {owner_name || parseEntityRef(owner).name}
      </TrackedLink>
    ),
    width: 'fit-content',
  },
  {
    title: 'Sub-domains',
    render: ({ relations = [] }) =>
      relations.filter(relation => relation.type === 'parentOf').length,
    width: 'fit-content',
  },
  {
    title: 'Applications',
    render: ({ relations = [] }) =>
      relations.filter(relation => relation.type === 'hasPart').length,
    width: 'fit-content',
  },
];

export const makeActions = (
  widgetName: string,
): TableProps<IEntityDomain>['actions'] => [
  {
    position: 'toolbar',
    icon: () => <DisabledEditIcon />,
    tooltip: 'Coming Soon!',
    disabled: false,
    onClick: () =>
      PluginTracking.sendEvent(attemptEditWhenDisabled(widgetName)),
  },
];
