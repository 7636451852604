import { createApiRef } from '@backstage/core-plugin-api';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Incident, Team } from './types';

dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
const DayOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const UNKNOWN_TEAM_NAME = 'Unknown';

export const DEFAULT_BUSINESS_HOURS_START = 9;
export const DEFAULT_BUSINESS_HOURS_END = 18;

export const analyticsApiRef = createApiRef<Analytics>({
  id: 'plugin.opsgenie.analytics',
});

const teamName = (teams: Team[], teamId: string): string => {
  for (const team of teams) {
    if (team.id === teamId) {
      return team.name;
    }
  }

  return UNKNOWN_TEAM_NAME;
};

export const respondingTeam = (teams: Team[], incident: Incident): string => {
  if (incident.extraProperties.responders) {
    return incident.extraProperties.responders;
  }

  const teamResponders = incident.responders.filter(
    responderRef => responderRef.type === 'team',
  );

  if (teamResponders.length === 0) {
    return UNKNOWN_TEAM_NAME;
  }

  return teamName(teams, teamResponders[0].id);
};

const sortByDate = (data: DateSortable[]): void => {
  data.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }

    return 0;
  });
};

interface DateSortable {
  date: Dayjs;
}

interface HourlyIncidents {
  hour: string;
  total: number;
}

interface DailyIncidents {
  day: string;
  total: number;
}

interface WeeklyIncidentsBySeverity {
  week: string;
  s1: number;
  s2: number;
  s3: number;
  date: Dayjs;
}

interface WeeklyIncidentsByHour {
  week: string;
  businessHours: number;
  onCallHours: number;
  total: number;
  date: Dayjs;
}

export interface IncidentsByResponders {
  dataPoints: { period: string; total: number; date: Dayjs }[];
  responders: string[];
}

export interface Context {
  from: Dayjs;
  to: Dayjs;
  incidents: Incident[];
  teams: Team[];
}

export interface Analytics {
  incidentsByHour(context: Context): HourlyIncidents[];
  incidentsByDay(context: Context): DailyIncidents[];
  incidentsByWeekAndHours(context: Context): WeeklyIncidentsByHour[];
  incidentsByWeekAndSeverity(context: Context): WeeklyIncidentsBySeverity[];
  incidentsByDayAndResponder(context: Context): IncidentsByResponders;
  incidentsByWeekAndResponder(context: Context): IncidentsByResponders;
  incidentsByMonthAndResponder(context: Context): IncidentsByResponders;
  incidentsByQuarterAndResponder(context: Context): IncidentsByResponders;
  impactByWeekAndResponder(context: Context): IncidentsByResponders;
}

interface BusinessHours {
  start: number;
  end: number;
}

export class AnalitycsApi implements Analytics {
  private readonly businessHours: BusinessHours;

  constructor(opts: { businessHours: BusinessHours }) {
    this.businessHours = opts.businessHours;
  }

  incidentsByHour(context: Context): HourlyIncidents[] {
    const incidentsBuckets: Record<string, number> = {};

    // add empty buckets for hours with no incident
    for (let h = 0; h <= 23; h++) {
      incidentsBuckets[h] = 0;
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);

      incidentsBuckets[incidentDate.hour()] += 1;
    });

    const data = Object.keys(incidentsBuckets).map(hour => ({
      hour: hour,
      total: incidentsBuckets[hour],
    }));

    data.sort((a, b) => parseInt(a.hour, 10) - parseInt(b.hour, 10));

    return data;
  }

  incidentsByDay(context: Context): DailyIncidents[] {
    const incidentsBuckets: Record<string, number> = {};

    // add empty buckets for days with no incident
    for (let d = 0; d < 7; d++) {
      incidentsBuckets[d] = 0;
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);

      incidentsBuckets[incidentDate.day()] += 1;
    });

    const data = Object.keys(incidentsBuckets).map(day => ({
      day: DayOfWeek[parseInt(day, 10)],
      dayNum: parseInt(day, 10),
      total: incidentsBuckets[day],
    }));

    // Mondays first.
    data.sort((a, b) => ((a.dayNum + 6) % 7) - ((b.dayNum + 6) % 7));

    return data;
  }

  incidentsByWeekAndSeverity(context: Context): WeeklyIncidentsBySeverity[] {
    const incidentsBuckets: Record<
      string,
      {
        s1: number;
        s2: number;
        s3: number;
        date: Dayjs;
      }
    > = {};

    let minDate = context.from.clone().startOf('isoWeek');
    const maxDate = context.to.clone().startOf('isoWeek');

    // add empty buckets for weeks with no incident
    while (minDate <= maxDate) {
      const week = `w${minDate.isoWeek()} - ${minDate.isoWeekYear()}`;

      if (!incidentsBuckets[week]) {
        incidentsBuckets[week] = {
          s1: 0,
          s2: 0,
          s3: 0,
          date: minDate.clone(),
        };
      }

      minDate = minDate.add(1, 'weeks');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const week = `w${incidentDate.isoWeek()} - ${incidentDate.isoWeekYear()}`;

      if (incident.tags.includes('SEV1')) {
        incidentsBuckets[week].s1 += 1;
      } else if (incident.tags.includes('SEV2')) {
        incidentsBuckets[week].s2 += 1;
      } else if (incident.tags.includes('SEV3')) {
        incidentsBuckets[week].s3 += 1;
      }
    });

    const data = Object.keys(incidentsBuckets).map(week => ({
      week: week,
      s1: incidentsBuckets[week].s1,
      s2: incidentsBuckets[week].s2,
      s3: incidentsBuckets[week].s3,
      date: incidentsBuckets[week].date,
    }));

    sortByDate(data);

    return data;
  }

  incidentsByWeekAndHours(context: Context): WeeklyIncidentsByHour[] {
    const incidentsBuckets: Record<
      string,
      {
        businessHours: number;
        onCallHours: number;
        total: number;
        date: Dayjs;
      }
    > = {};

    let minDate = context.from.clone().startOf('isoWeek');
    const maxDate = context.to.clone().startOf('isoWeek');

    // add empty buckets for weeks with no incident
    while (minDate <= maxDate) {
      const week = `w${minDate.isoWeek()} - ${minDate.isoWeekYear()}`;

      if (!incidentsBuckets[week]) {
        incidentsBuckets[week] = {
          businessHours: 0,
          onCallHours: 0,
          total: 0,
          date: minDate.clone(),
        };
      }

      minDate = minDate.add(1, 'weeks');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const week = `w${incidentDate.isoWeek()} - ${incidentDate.isoWeekYear()}`;

      incidentsBuckets[week].total += 1;

      if (this.isBusinessHours(incidentDate)) {
        incidentsBuckets[week].businessHours += 1;
      } else {
        incidentsBuckets[week].onCallHours += 1;
      }
    });

    const data = Object.keys(incidentsBuckets).map(week => ({
      week: week,
      businessHours: incidentsBuckets[week].businessHours,
      onCallHours: incidentsBuckets[week].onCallHours,
      total: incidentsBuckets[week].total,
      date: incidentsBuckets[week].date,
    }));

    sortByDate(data);

    return data;
  }

  incidentsByDayAndResponder(context: Context): IncidentsByResponders {
    const incidentsBuckets: Record<
      string,
      { responders: Record<string, number>; total: number }
    > = {};
    const respondersMap: Record<string, boolean> = {};

    // add empty buckets for days with no incident
    for (let d = 0; d < 7; d++) {
      incidentsBuckets[d] = {
        total: 0,
        responders: {},
      };
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const day = incidentDate.day();
      const responder = respondingTeam(context.teams, incident);

      respondersMap[responder] = true;

      if (!incidentsBuckets[day].responders[responder]) {
        incidentsBuckets[day].responders[responder] = 0;
      }

      incidentsBuckets[day].responders[responder] += 1;
      incidentsBuckets[day].total += 1;
    });

    const data = Object.keys(incidentsBuckets).map(day => {
      const dataPoint: any = {
        period: DayOfWeek[parseInt(day, 10)],
        dayNum: parseInt(day, 10),
        total: incidentsBuckets[day].total,
      };

      Object.keys(respondersMap).forEach(responder => {
        dataPoint[responder] = incidentsBuckets[day].responders[responder] || 0;
      });
      return dataPoint;
    });

    // Mondays first.
    data.sort((a, b) => ((a.dayNum + 6) % 7) - ((b.dayNum + 6) % 7));

    return {
      dataPoints: data,
      responders: Object.keys(respondersMap),
    };
  }

  incidentsByMonthAndResponder(context: Context): IncidentsByResponders {
    const incidentsBuckets: Record<
      string,
      {
        responders: Record<string, number>;
        total: number;
        date: Dayjs;
      }
    > = {};
    const respondersMap: Record<string, boolean> = {};

    let from = context.from.clone();
    const to = context.to.clone();

    // add empty buckets for months with no incident
    while (from <= to) {
      const month = `${from.month() + 1}/${from.year()}`;

      if (!incidentsBuckets[month]) {
        incidentsBuckets[month] = {
          responders: {},
          total: 0,
          date: from.clone(),
        };
      }

      from = from.add(1, 'month');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const month = `${incidentDate.month() + 1}/${incidentDate.year()}`;
      const responder = respondingTeam(context.teams, incident);

      respondersMap[responder] = true;

      if (!incidentsBuckets[month].responders[responder]) {
        incidentsBuckets[month].responders[responder] = 0;
      }

      incidentsBuckets[month].responders[responder] += 1;
      incidentsBuckets[month].total += 1;
    });

    const data = Object.keys(incidentsBuckets).map(month => {
      const dataPoint: any = {
        period: month,
        total: incidentsBuckets[month].total,
        date: incidentsBuckets[month].date,
      };

      Object.keys(respondersMap).forEach(responder => {
        dataPoint[responder] =
          incidentsBuckets[month].responders[responder] || 0;
      });

      return dataPoint;
    });

    sortByDate(data);

    return {
      dataPoints: data,
      responders: Object.keys(respondersMap),
    };
  }

  incidentsByWeekAndResponder(context: Context): IncidentsByResponders {
    const incidentsBuckets: Record<
      string,
      {
        responders: Record<string, number>;
        total: number;
        date: Dayjs;
      }
    > = {};
    const respondersMap: Record<string, boolean> = {};

    let minDate = context.from.clone().startOf('isoWeek');
    const maxDate = context.to.clone().startOf('isoWeek');

    // add empty buckets for weeks with no incident
    while (minDate <= maxDate) {
      const week = `w${minDate.isoWeek()} - ${minDate.isoWeekYear()}`;

      if (!incidentsBuckets[week]) {
        incidentsBuckets[week] = {
          responders: {},
          total: 0,
          date: minDate.clone(),
        };
      }

      minDate = minDate.add(1, 'weeks');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const week = `w${incidentDate.isoWeek()} - ${incidentDate.isoWeekYear()}`;
      const responder = respondingTeam(context.teams, incident);

      respondersMap[responder] = true;

      if (!incidentsBuckets[week].responders[responder]) {
        incidentsBuckets[week].responders[responder] = 0;
      }

      incidentsBuckets[week].responders[responder] += 1;
      incidentsBuckets[week].total += 1;
    });

    const data = Object.keys(incidentsBuckets).map(week => {
      const dataPoint: any = {
        period: week,
        total: incidentsBuckets[week].total,
        date: incidentsBuckets[week].date,
      };

      Object.keys(respondersMap).forEach(responder => {
        dataPoint[responder] =
          incidentsBuckets[week].responders[responder] || 0;
      });

      return dataPoint;
    });

    sortByDate(data);

    return {
      dataPoints: data,
      responders: Object.keys(respondersMap),
    };
  }

  incidentsByQuarterAndResponder(context: Context): IncidentsByResponders {
    const incidentsBuckets: Record<
      string,
      {
        responders: Record<string, number>;
        total: number;
        date: Dayjs;
      }
    > = {};
    const respondersMap: Record<string, boolean> = {};

    let from = context.from.clone();
    const to = context.to.clone();

    // add empty buckets for quarters with no incident (let's be hopeful, might happen)
    while (from <= to) {
      const quarter = `Q${from.quarter()} - ${from.year()}`;

      if (!incidentsBuckets[quarter]) {
        incidentsBuckets[quarter] = {
          responders: {},
          total: 0,
          date: from.clone(),
        };
      }

      from = from.add(1, 'quarter');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const quarter = `Q${incidentDate.quarter()} - ${incidentDate.year()}`;
      const responder = respondingTeam(context.teams, incident);

      respondersMap[responder] = true;

      if (!incidentsBuckets[quarter].responders[responder]) {
        incidentsBuckets[quarter].responders[responder] = 0;
      }

      incidentsBuckets[quarter].responders[responder] += 1;
      incidentsBuckets[quarter].total += 1;
    });

    const data = Object.keys(incidentsBuckets).map(quarter => {
      const dataPoint: any = {
        period: quarter,
        total: incidentsBuckets[quarter].total,
        date: incidentsBuckets[quarter].date,
      };

      Object.keys(respondersMap).forEach(responder => {
        dataPoint[responder] =
          incidentsBuckets[quarter].responders[responder] || 0;
      });

      return dataPoint;
    });

    sortByDate(data);

    return {
      dataPoints: data,
      responders: Object.keys(respondersMap),
    };
  }

  impactByWeekAndResponder(context: Context): IncidentsByResponders {
    const incidentsBuckets: Record<
      string,
      {
        responders: Record<string, number[]>;
        durations: number[];
        date: Dayjs;
      }
    > = {};
    const respondersMap: Record<string, boolean> = {};

    let minDate = context.from.clone().startOf('isoWeek');
    const maxDate = context.to.clone().startOf('isoWeek');

    const average = (durations: number[]) =>
      durations.length === 0
        ? 0
        : durations.reduce((a, b) => a + b, 0) / durations.length;

    // add empty buckets for weeks with no incident
    while (minDate <= maxDate) {
      const week = `w${minDate.isoWeek()} - ${minDate.isoWeekYear()}`;

      if (!incidentsBuckets[week]) {
        incidentsBuckets[week] = {
          responders: {},
          durations: [],
          date: minDate.clone(),
        };
      }

      minDate = minDate.add(1, 'weeks');
    }

    context.incidents.forEach(incident => {
      const incidentDate = dayjs(incident.impactStartDate);
      const incidentEnd = dayjs(incident.impactEndDate);
      const week = `w${incidentDate.isoWeek()} - ${incidentDate.isoWeekYear()}`;
      const responder = respondingTeam(context.teams, incident);
      const impactDuration = incidentEnd.diff(incidentDate, 'minutes');

      respondersMap[responder] = true;

      if (!incidentsBuckets[week].responders[responder]) {
        incidentsBuckets[week].responders[responder] = [];
      }

      incidentsBuckets[week].responders[responder].push(impactDuration);
      incidentsBuckets[week].durations.push(impactDuration);
    });

    const data = Object.keys(incidentsBuckets).map(week => {
      const dataPoint: any = {
        period: week,
        total: average(incidentsBuckets[week].durations),
        date: incidentsBuckets[week].date,
      };

      Object.keys(respondersMap).forEach(responder => {
        dataPoint[responder] = incidentsBuckets[week].responders[responder]
          ? average(incidentsBuckets[week].responders[responder])
          : 0;
      });

      return dataPoint;
    });

    sortByDate(data);

    return {
      dataPoints: data,
      responders: Object.keys(respondersMap),
    };
  }

  isBusinessHours(incidentStartedAt: Dayjs): boolean {
    return (
      incidentStartedAt.hour() >= this.businessHours.start &&
      incidentStartedAt.hour() < this.businessHours.end
    );
  }
}
