import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Lifecycle';
const id = 'lifecycle-filter';
export const LifecyclesFilter = React.memo(() => {
  const { availableLifecycles, lifecycleFilter, setLifecycleFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        value={lifecycleFilter}
        onChange={ev => setLifecycleFilter(String(ev.target.value))}
        label={label}
        labelId={id}
        classes={{ select }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {availableLifecycles.map(lifecycle => (
          <MenuItem key={lifecycle} value={lifecycle}>
            {lifecycle}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
