import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button, DialogActions } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { modelsApiRef } from '../../api/services/models';
import { UpdateModelCard, ModelCard } from '../../api/interfaces/model_card';
import { Alert } from '@material-ui/lab';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

const Section: React.FC<{
  title: string;
  children: React.ReactNode;
  buttonLabel: string;
  onSubmit: () => void;
  disabled: boolean;
  size: number;
  canEdit: boolean;
}> = ({ title, children, buttonLabel, onSubmit, disabled, size, canEdit }) => (
  <Grid item xs={size}>
    <Accordion disabled={!canEdit}>
      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={disabled}
          onClick={onSubmit}
        >
          {buttonLabel}
        </Button>
      </AccordionDetails>
    </Accordion>
  </Grid>
);

interface EditModelCardFormProps {
  modelCard: ModelCard;
  canEdit: boolean;
}

export const EditModelCardForm = ({
  modelCard,
  canEdit,
}: EditModelCardFormProps) => {
  const modelsApi = useApi(modelsApiRef);

  const [modelCardForm, setModelCardForm] = useState<UpdateModelCard>({
    input_format: '',
    limitations: [],
    model_architecture: '',
    output_format: '',
    use_cases: [],
    technical_specification: modelCard.technical_specification,
    links: modelCard.links || [],
    tradeoffs: modelCard.tradeoffs || [],
    references: modelCard.references || [],
    citations: modelCard.citations || [],
  });
  const [changeMade, setChangeMade] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    technical_specification?: string;
    model_architecture?: string;
    output_format?: string;
    use_cases?: string;
    links?: string;
    tradeoffs?: string;
    references?: string;
    citations?: string;
    limitations?: string;
  }>({
    technical_specification: '',
    limitations: '',
    tradeoffs: '',
    references: '',
    citations: '',
    links: '',
    model_architecture: '',
  });
  const [updateError, setUpdateError] = React.useState<string>();
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleSubmit = () => {
    setLoading(true);
    modelsApi
      .updateModelCard(modelCard.model_card_id, modelCardForm)
      .catch(error => {
        setUpdateError(`${error}`);
      });
    setLoading(false);
    setOpenAlert(true);
  };

  const handleInputChange = (
    section: 'links' | 'tradeoffs' | 'references' | 'citations' | 'limitations',
    index: number,
    key:
      | 'title'
      | 'url'
      | 'tradeoffs'
      | 'references'
      | 'citations'
      | 'limitations',
    value: string,
  ) => {
    setModelCardForm(prevState => {
      let updatedSection;

      if (section === 'links') {
        updatedSection = prevState.links.map((item, i) =>
          i === index ? { ...item, [key]: value } : item,
        );
      } else if (section === 'tradeoffs') {
        updatedSection = prevState.tradeoffs.map((item, i) =>
          i === index ? value : item,
        );
      }

      return {
        ...prevState,
        [section]: updatedSection,
      };
    });
    setChangeMade(true);
  };

  const handleAddItem = (
    section: 'links' | 'tradeoffs' | 'references' | 'citations' | 'limitations',
  ) => {
    setModelCardForm(prevState => ({
      ...prevState,
      [section]: [
        ...prevState[section],
        section === 'links' ? { title: '', url: '' } : '',
      ],
    }));
    setChangeMade(true);
  };

  const handleRemoveItem = (
    section: 'links' | 'tradeoffs' | 'references' | 'citations' | 'limitations',
    index: number,
  ) => {
    setModelCardForm(prevState => ({
      ...prevState,
      [section]: prevState[section].filter((_, i) => i !== index),
    }));
    setChangeMade(true);
  };

  const renderInputs = (
    section: 'links' | 'tradeoffs' | 'references' | 'citations' | 'limitations',
    fields: {
      label: string;
      key:
        | 'title'
        | 'url'
        | 'tradeoffs'
        | 'references'
        | 'citations'
        | 'limitations';
      size: number;
    }[],
  ) => (
    <>
      {modelCardForm[section].map((item: any, index) => {
        return (
          <Box key={`${section}-${index}`}>
            <Grid container spacing={2}>
              {fields.map(({ label, key, size }) => {
                return (
                  <Grid item xs={size} key={key}>
                    <TextField
                      value={section === 'links' ? item[key] : item}
                      onChange={ev =>
                        handleInputChange(section, index, key, ev.target.value)
                      }
                      label={section === 'links' ? label : ''}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                );
              })}
            </Grid>
            <DialogActions>
              {index === modelCardForm[section].length - 1 && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => handleAddItem(section)}
                >
                  Add
                </Button>
              )}
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={modelCardForm[section].length === 1}
                onClick={() => handleRemoveItem(section, index)}
              >
                Remove
              </Button>
            </DialogActions>
          </Box>
        );
      })}
    </>
  );

  const handleModelArchitectureChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = ev.target.value;
    setModelCardForm(prevState => ({
      ...prevState,
      model_architecture: value,
    }));

    if (value.length < 10) {
      setErrors(prev => ({
        ...prev,
        model_architecture: 'Must have at least 10 characters.',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, model_architecture: '' }));
      setChangeMade(true);
    }
  };

  const handleTechnicalSpecificationChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = ev.target.value;
    setModelCardForm(prevState => ({
      ...prevState,
      technical_specification: value,
    }));

    if (value.length < 10) {
      setErrors(prev => ({
        ...prev,
        technical_specification: 'Must have at least 10 characters.',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, technical_specification: '' }));
      setChangeMade(true);
    }
  };

  return (
    <>
      {updateError && updateError.length > 0 && (
        <Alert color="error">{updateError}</Alert>
      )}
      <br />
      <Section
        title="Technical Specifications"
        buttonLabel="Update Technical Specifications"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={12}
        canEdit={canEdit}
      >
        <FormControl fullWidth>
          <TextField
            value={modelCardForm.technical_specification}
            onChange={handleTechnicalSpecificationChange}
            fullWidth
            multiline
            rows={30}
            error={!!errors.technical_specification}
            helperText={
              errors.technical_specification || (
                <>
                  <Typography variant="caption">
                    The detailed technical specification of the model. It could
                    include the architecture, the training data, the training
                    process, input and output format, etc. A good technical
                    specification can help other users understand the model
                    better. An example of a technical specification can be found{' '}
                    <Link
                      to="https://ai.google.dev/gemma/docs/model_card_2"
                      target="_blank"
                    >
                      here
                    </Link>
                    . You can use markdown.
                  </Typography>
                </>
              )
            }
            size="medium"
          />
        </FormControl>
      </Section>

      <Section
        title="Model Architecture"
        buttonLabel="Update Model Architecture"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={12}
        canEdit={canEdit}
      >
        <FormControl fullWidth>
          <TextField
            value={modelCardForm.model_architecture}
            onChange={handleModelArchitectureChange}
            fullWidth
            multiline
            rows={15}
            error={!!errors.technical_specification}
            helperText={
              errors.technical_specification || 'The model architecture'
            }
            size="medium"
          />
        </FormControl>
      </Section>

      <Section
        title="Limitations"
        buttonLabel="Update Limitations"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={6}
        canEdit={canEdit}
      >
        {renderInputs('limitations', [
          { label: 'Limitation', key: 'limitations', size: 12 },
        ])}
      </Section>

      <Section
        title="Trade Offs"
        buttonLabel="Update Trade Offs"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={6}
        canEdit={canEdit}
      >
        {renderInputs('tradeoffs', [
          { label: 'Trade Off', key: 'tradeoffs', size: 12 },
        ])}
      </Section>

      <Section
        title="Links"
        buttonLabel="Update Links"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={6}
        canEdit={canEdit}
      >
        {renderInputs('links', [
          { label: 'Title', key: 'title', size: 4 },
          { label: 'URL', key: 'url', size: 8 },
        ])}
      </Section>

      <Section
        title="References"
        buttonLabel="Update References"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={6}
        canEdit={canEdit}
      >
        {renderInputs('references', [
          { label: 'Reference', key: 'references', size: 12 },
        ])}
      </Section>

      <Section
        title="Citations"
        buttonLabel="Update Citations"
        onSubmit={handleSubmit}
        disabled={loading || !changeMade || hasError}
        size={6}
        canEdit={canEdit}
      >
        {renderInputs('citations', [
          { label: 'Citation', key: 'citations', size: 12 },
        ])}
      </Section>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={updateError ? 'error' : 'success'}
          variant="filled"
        >
          {updateError
            ? 'Error updating model card'
            : 'Model card updated successfully'}
        </Alert>
      </Snackbar>
    </>
  );
};
