import { AwareDatetime, Tag } from './common';
import { ModelVersionReviewStatus } from './model_version_review';
import { User } from './user';
import { Dataset } from './dataset';
import { ModelArtifact } from './model_artifact';
import { Metric, Param } from './metric_param';

export enum ModelLifecycleStage {
  EXPERIMENTAL = 'EXPERIMENTAL',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
  ARCHIVED = 'ARCHIVED',
}

export enum Origin {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface ListModelVersionsParams {
  model_id: number;
  name?: string;
  stage?: string;
  origin?: string;
  review_status?: string;
  limit?: number;
  offset?: number;
  tag_name?: string;
}

export enum ModelDeploymentType {
  KUBERNETES = 'KUBERNETES',
  SAGEMAKER = 'SAGEMAKER',
}

export interface ModelVersionDeployment {
  name: string;
  deployment_id: number;
  model_version_id: number;
  image_uri: string;
  resource_id: string;
  type: ModelDeploymentType;
  meta: Record<string, string>;
  created_at: AwareDatetime;
  created_by: User;
}

export type ModelVersion = {
  model_version_id?: number;
  model_id: number;
  description?: string;
  name: string;
  meta?: Record<string, string>;
  stage: ModelLifecycleStage;
  source_type?: string;
  source_uri?: string;
  tags: Array<Tag>;
  datasets: Array<Dataset>;
  created_by: User;
  created_at: AwareDatetime;
  updated_at?: AwareDatetime;
  updated_by?: User;
  version: number;
  metrics: Array<Metric>;
  params: Array<Param>;
  artifacts: Array<ModelArtifact>;
  status: ModelVersionReviewStatus;
  deployments: Array<ModelVersionDeployment>;
};

export interface ListModelVersionsResponse {
  versions: ModelVersion[];
  total: number;
}

export interface UpdateModelVersion {
  description?: string;
  stage?: string;
  source_type?: string;
  source_uri?: string;
}
