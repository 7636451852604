import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React from 'react';
import {
  Link,
  Table,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import { useAsync } from 'react-use';

type TableAction = TableProps<IEntityUser>['actions'];

export function ContributorsTable() {
  const catalogApi = useApi(catalogApiRef);
  const columns: TableColumn<IEntityUser>[] = [
    {
      title: 'Name',
      render: ({ metadata: { name } }) => <Link to="#">{name}</Link>,
    },
    {
      title: 'Role',
      render: () => <div>Owner</div>,
      width: 'fit-content',
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Job title</span>,
      field: 'metadata.zalandoMetadata.job_title',
      width: 'fit-content',
    },
    {
      title: 'Team',
      render: ent => <Link to="#">{ent.metadata.zalandoMetadata.team}</Link>,
      width: 'fit-content',
    },
  ];

  const actions: TableAction = [
    {
      position: 'toolbar',
      icon: () => <EditIcon color="primary" />,
      tooltip: 'Edit contributors',
      disabled: true,
      onClick: () => {},
    },
  ];

  // TODO: This is just a mock, fetch applications relevant to domain
  const { value = [], loading } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: [
            { kind: 'User', 'relations.memberof': 'group:default/50081172' },
          ],
        })
        .then(res => res.items),
    [catalogApi],
  );

  return (
    <Table
      title={`Contributors (${value.length})`}
      columns={columns}
      data={value}
      actions={actions}
      options={{
        padding: 'dense',
        headerStyle: { whiteSpace: 'nowrap' },
      }}
      isLoading={loading}
    />
  );
}
