import { lighten, makeStyles, styled, Theme } from '@material-ui/core';
import { fontSize } from '../../../../../common';
import { HEADER_HEIGHT } from '../styles';

const getHeaderStyles = (theme: Theme): {} => ({
  padding: theme.spacing(1),
  fontSize: fontSize.small,
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns:
    'minmax(20px, 3fr) minmax(0px, 70px) repeat(5, minmax(10px, 100px))',
  gridGap: theme.spacing(1),
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '3px',
});

export const useStyles = makeStyles(theme => ({
  foldHeader: {
    ...getHeaderStyles(theme),
    position: 'relative',
    top: 'unset',
    zIndex: 0,
    fontSize: fontSize.small,
    borderBottom: 'unset',

    ['&:hover']: {
      background: lighten(theme.palette.background.default, 0.2),
    },
  },
  foldHeaderOpen: {
    ...getHeaderStyles(theme),
    position: 'sticky',
    top: HEADER_HEIGHT,
    zIndex: 10,
    fontSize: fontSize.small,
    background: lighten(theme.palette.background.default, 0.2),
    borderBottom: 'unset',
  },
}));

export const JUnitTestFolds = styled('div')(props => ({
  position: 'relative',
  width: '100%',
  padding: props.theme.spacing(2),
}));

export const Title = styled('h3')(props => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row wrap',
  fontSize: fontSize.medium,
  margin: 0,

  ['& > *']: {
    marginRight: props.theme.spacing(1),
  },
}));

export const Subtitle = styled('p')(props => ({
  marginTop: props.theme.spacing(1),
  marginBottom: props.theme.spacing(2),
  fontSize: fontSize.small,
}));

export const TestFolds = styled('ul')({
  position: 'relative',
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

export const Column = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'right',

  ['&:nth-of-type(1)']: {
    textAlign: 'left',
  },
});

export const Header = styled('div')(props => ({
  ...getHeaderStyles(props.theme),
  position: 'sticky',
  top: HEADER_HEIGHT,
  zIndex: 10,
}));

export const TestFold = styled('li')(props => ({
  borderBottom: `1px solid ${props.theme.palette.divider}`,
}));

export const TestFoldTitle = styled('p')({
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  direction: 'rtl',
});

export const TestItem = styled('details')({
  padding: '5px 10px',
  overflow: 'hidden',

  ['&[open] > summary:before']: {
    transform: 'rotate(45deg)',
  },
});

export const TestItemSummary = styled('summary')(props => ({
  ['&::-webkit-details-marker']: {
    display: 'none',
    color: 'white',
    transform: 'scale(0)',
  },

  ['&:before']: {
    width: '0px',
    height: '0px',
    content: `" "`,
    border: `solid ${props.theme.palette.divider}`,
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '0 5px 5px 0',
    transform: 'rotate(-45deg)',
    verticalAlign: 'middle',
  },
}));

export const TestItemHeader = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  maxWidth: '96%',
  fontSize: fontSize.small,

  ['&, & > span']: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const TestItemIcon = styled('span')(props => ({
  flexShrink: 0,
  height: '10px',
  width: '10px',
  lineHeight: 0,
  marginRight: props.theme.spacing(1),
  marginLeft: props.theme.spacing(1),
  borderRadius: '100%',
}));

export const TestItemError = styled('pre')(props => ({
  marginTop: props.theme.spacing(1),
  padding: props.theme.spacing(3),
  fontSize: '12px',
  color: props.theme.palette.grey[800],
  backgroundColor: props.theme.palette.grey[200],
  overflowX: 'scroll',
}));
