import { ChangedLineProps, ChangeTypes } from 'plugin-ui-components';
import { config, FormFields } from '../ApplicationForm/config';

export const mapChanges = (
  original: IKioAppApiResponse,
  newChanges: IKioAppApiResponse,
): ChangedLineProps[] => {
  const changes: ChangedLineProps[] = [];

  Object.keys(newChanges).forEach(key => {
    const oldValue = original[key as keyof IKioAppApiResponse];
    const newValue = newChanges[key as keyof IKioAppApiResponse];

    if (oldValue === newValue || !config[key as FormFields]) {
      return;
    }

    let changeType;
    if (oldValue && !newValue) {
      changeType = ChangeTypes.deleted;
    } else if (oldValue && newValue && oldValue !== newValue) {
      changeType = ChangeTypes.changed;
    } else {
      changeType = ChangeTypes.added;
    }

    changes.push({
      label: config[key as FormFields]!.label,
      oldValue,
      newValue,
      changeType,
    });
  });
  return changes;
};
