import { Theme, Chip, makeStyles, styled, Typography } from '@material-ui/core';
import { IRepositoryVulnerabilitySeverity } from './api';

export const useStyles = makeStyles(() => ({
  linkWithIcon: {
    display: 'inline-flex',
    gap: '0.2rem',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1em',
    },
  },
}));

export const getSeverityColor = (
  severity: IRepositoryVulnerabilitySeverity,
  theme: Theme = {} as Theme,
) => {
  switch (severity) {
    case 'CRITICAL':
      return theme.palette.error.main;
    case 'HIGH':
      return theme.palette.warning.dark;
    case 'LOW':
      return theme.palette.gold;
    case 'MODERATE':
      return theme.palette.warning.main;
    default:
      return 'var(--disabled-color)';
  }
};

export const SeverityChip = styled(Chip)(
  ({
    theme,
    severity,
  }: {
    theme: Theme;
    severity: IRepositoryVulnerabilitySeverity;
  }) => ({
    borderColor: getSeverityColor(severity, theme),
  }),
);

export const SeverityText = styled('span')(
  ({
    theme,
    severity,
  }: {
    theme: Theme;
    severity: IRepositoryVulnerabilitySeverity;
  }) => ({
    color: getSeverityColor(severity, theme),
    fontWeight: 'bold',
  }),
);

export const ItemContent = styled('div')(({ theme }: { theme: Theme }) => ({
  flex: 'auto 1 1',
  margin: theme.spacing(0, 2),
  width: '100%',
  overflow: 'hidden',
}));

export const ItemTitle = styled(Typography)(() => ({
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ItemText = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginBottom: 4,
  '& > div': {
    marginBottom: 0,
  },
}));
