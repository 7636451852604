import React from 'react';
import { ChatIcon, Table, TableColumn } from '@backstage/core-components';
import { SeverityChip } from '../UI/SeverityChip';
import { Incident } from '../../types';
import { StatusChip } from '../UI/StatusChip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Button, Tooltip } from '@material-ui/core';

export const IncidentsTable = ({ incidents }: { incidents: Incident[] }) => {
  const smallColumnStyle = {
    width: '5%',
    maxWidth: '5%',
  };
  const mediumColumnStyle = {
    width: '10%',
    maxWidth: '10%',
  };
  const buttonStyle = {
    minWidth: '30px',
    padding: 0,
  };

  const columns: TableColumn<Incident>[] = [
    {
      title: 'Severity',
      field: 'severity',
      cellStyle: smallColumnStyle,
      headerStyle: smallColumnStyle,
      render: rowData => (
        <SeverityChip
          severity={rowData?.tags.find(item => item.includes('SEV')) || ''}
        />
      ),
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: smallColumnStyle,
      headerStyle: smallColumnStyle,
      render: rowData => <StatusChip status={rowData.status} />,
    },
    {
      title: 'Incident Description',
      field: 'message',
    },
    {
      title: 'Owner',
      render: data => data.extraProperties['Owning Team name'],
    },

    {
      title: 'Resources',
      render: data => (
        <div>
          <Tooltip
            title={
              !!data.extraProperties['24/7 Incident Chat']
                ? '24/7 Incident Chat'
                : ' No Incident Chat available'
            }
          >
            <span>
              <Button
                style={{ ...buttonStyle }}
                color="primary"
                disabled={
                  !data.extraProperties.hasOwnProperty('24/7 Incident Chat')
                }
                target="_blank"
                href={data.extraProperties['24/7 Incident Chat'] || '#'}
                startIcon={<AssignmentIcon fontSize="large" />}
              />
            </span>
          </Tooltip>
          <Tooltip
            title={
              !!data.extraProperties['Post Mortem']
                ? 'Post Mortem'
                : 'No Post Mortem available'
            }
          >
            <span>
              <Button
                style={{ ...buttonStyle }}
                color="primary"
                disabled={!data.extraProperties.hasOwnProperty('Post Mortem')}
                target="_blank"
                href={data.extraProperties['Post Mortem'] || '#'}
                startIcon={<ChatIcon fontSize="large" />}
              />
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Created At',
      field: 'createdAt',
      cellStyle: mediumColumnStyle,
      headerStyle: mediumColumnStyle,
      render: date => new Date(date.createdAt).toISODateString(),
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      cellStyle: mediumColumnStyle,
      headerStyle: mediumColumnStyle,
      render: date => new Date(date.updatedAt).toISODateString(),
    },
  ];

  return (
    <Table
      options={{
        sorting: true,
        search: true,
        paging: true,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50, 100, 150, 200],
        padding: 'dense',
      }}
      localization={{ header: { actions: undefined } }}
      columns={columns}
      data={incidents}
    />
  );
};
