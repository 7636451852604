import React from 'react';
import { Tooltip } from '@material-ui/core';

export const TeamReferenceError = ({
  error,
  team,
}: {
  error: Error | undefined;
  team: string;
}) => {
  const title = error
    ? `An error occurred when fetching Team data: ${
        error?.message || String(error) || 'Unknown error'
      }`
    : `Team ${team || ''} was not found in Sunrise`;
  return (
    <Tooltip title={title} placement="top" arrow>
      <span>{team || 'Unknown'}</span>
    </Tooltip>
  );
};
