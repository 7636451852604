import { eventTypesApiRef, EventTypesManagerImpl } from './api/EventTypesApi';
import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  subscriptionApiRef,
  SubscriptionsManagerImpl,
} from './api/SubscriptionsApi';
import { NakadiApiGatewayImpl } from './gateway/NakadiApiGateway/NakadiApiGateway';
import { NakadiReporterGatewayImpl } from './gateway/NakadiReporterGateway/NakadiReporterGateway';
import { rootRouteRef } from './routes';
import {
  NakadiSQLAPIGatewayImpl,
  nsqlApiRef,
} from './gateway/NakadiSQLApiGateway/NakadiSQLAPIGateway';
import { DLQApiGatewayImpl } from './gateway/DLQApiGateway/DLQApiGateway';
import { DLQApiRef, DLQManagerImpl } from './api/DLQApi/DLQApi';

export const nakadiPlugin = createPlugin({
  id: 'nakadi',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'nakadi-dlq' }],
  apis: [
    createApiFactory({
      api: eventTypesApiRef,
      deps: {
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2api, discoveryApi }) =>
        new EventTypesManagerImpl({
          nakadiApiGateway: new NakadiApiGatewayImpl({
            oauth2Api: oauth2api,
            discoveryApi: discoveryApi,
          }),
          nakadiReporterGateway: new NakadiReporterGatewayImpl({
            oauth2Api: oauth2api,
            discoveryApi: discoveryApi,
          }),
        }),
    }),
    createApiFactory({
      api: subscriptionApiRef,
      deps: {
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2api, discoveryApi }) =>
        new SubscriptionsManagerImpl({
          nakadiApiGateway: new NakadiApiGatewayImpl({
            oauth2Api: oauth2api,
            discoveryApi: discoveryApi,
          }),
        }),
    }),
    createApiFactory({
      api: nsqlApiRef,
      deps: {
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2api, discoveryApi }) =>
        new NakadiSQLAPIGatewayImpl({
          oauth2Api: oauth2api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: DLQApiRef,
      deps: {
        oauth2api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2api, discoveryApi }) =>
        new DLQManagerImpl({
          dlqApiGateway: new DLQApiGatewayImpl({
            oauth2Api: oauth2api,
            discoveryApi: discoveryApi,
          }),
        }),
    }),
  ],
});
