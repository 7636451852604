import { useState } from 'react';

export const useHoverAndCopy = () => {
  const [copyText, setCopyText] = useState('Copy shareable link');

  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
    setCopyText('Copy shareable link');
  };

  const copyToClipboard = (path: string) => {
    setCopyText('Copied!');
    navigator.clipboard.writeText(path);
  };

  return { copyText, hover, handleHover, handleMouseOut, copyToClipboard };
};
