import axios from 'axios';
import {
  createApiRef,
  OAuthApi,
  DiscoveryApi,
} from '@backstage/core-plugin-api';

type SoftwareCatalogApiType = {
  getDeployments(id: string): Promise<KubeResourceReport>;
  getLightStepServices(id: string): Promise<LightStepService[]>;
};

export const softwareCatalogApiRef = createApiRef<SoftwareCatalogApiType>({
  id: 'plugin.catalog.software-catalog',
});

const isDev: boolean = process.env.NODE_ENV === 'development';

interface Apis {
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;
}

export class SoftwareCatalogApi implements SoftwareCatalogApiType {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(apis: Apis) {
    this.discoveryApi = apis.discoveryApi;
    this.oauth2Api = apis.oauth2Api;
  }

  async getDeployments(id: string) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/kube-resource-report`;

    const response = await axios.get(`${serviceUrl}/application-${id}.json`, {
      withCredentials: true,
    });

    return response.data;
  }

  async getLightStepServices(id: string) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const project = isDev ? 'Test' : 'Production';
    const serviceUrl = `${proxyUrl}/lightstep/api/public/v0.2/Zalando/projects/${project}/directory/services`;

    return axios
      .get<LightStepServiceResponse>(serviceUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const regex = new RegExp(`^(${id})(\\/.+)?$`);
        return (
          response.data?.data?.items?.filter(service => {
            const { name } = service.attributes;
            return name.match(regex);
          }) || []
        );
      });
  }
}
