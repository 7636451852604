import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const reportingLinePlugin = createPlugin({
  id: 'reporting-line',
  routes: {
    root: rootRouteRef,
  },
});

export const ReportingLinePage = reportingLinePlugin.provide(
  createRoutableExtension({
    name: 'ReportingLinePage',
    component: () =>
      import('./components/ReportingLinesPage').then(m => m.ReportingLinesPage),
    mountPoint: rootRouteRef,
  }),
);
