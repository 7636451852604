import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AboutField, InfoCard, TeamReference } from 'plugin-ui-components';
import CardContent from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { chipBg } from '../ToolingCatalogPage/columns';
import { StepCell } from '../ToolingCatalogPage/StepCell';
import { AboutCardLinks } from './AboutCardLinks';
import { ProductStatus } from '../ToolingCatalogPage/ProductStatus';
import { ProductOrServiceStatus } from '../ToolingCatalogPage/types';

export function ToolingAboutCard() {
  const { entity } = useEntity<IEntityTooling>();
  const owner =
    entity.relations?.find(r => r.type === 'ownedBy')?.target.name ?? 'unknown';

  return (
    <InfoCard title="About">
      <CardContent style={{ padding: '1rem' }}>
        <AboutCardLinks />

        <Grid container gap="1rem">
          <AboutField label="Name" xs={12} sm={5}>
            {entity.spec?.title}
          </AboutField>
          <AboutField label="Owner" xs={12} sm={5}>
            <TeamReference team={owner} />
          </AboutField>
          <AboutField label="Status" xs={12}>
            <ProductStatus
              status={entity.spec?.status as ProductOrServiceStatus}
            />
          </AboutField>
          <AboutField label="Purpose" xs={12}>
            {entity.spec?.purpose}
          </AboutField>
          <AboutField label="Journey" xs={12} sm={5}>
            <div className="spaced-flex">
              {entity.spec.journey.map((item, i) => (
                <Chip
                  key={i}
                  style={{ backgroundColor: chipBg(item as any) }}
                  color="primary"
                  label={item}
                />
              ))}
            </div>
          </AboutField>
          <AboutField label={<StepCell />} xs={12} sm={5}>
            <div className="spaced-flex">
              {entity.spec.step.map((item, i) =>
                item ? (
                  <Chip
                    key={i}
                    style={{
                      backgroundColor: chipBg(entity.spec.journey[0] as any),
                    }}
                    color="primary"
                    label={item}
                  />
                ) : (
                  ''
                ),
              )}
            </div>
          </AboutField>
        </Grid>
      </CardContent>
    </InfoCard>
  );
}
