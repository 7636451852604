import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Popover,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';

export interface SavedShortcut {
  url: string;
  title: string;
  id: string;
}

interface Props {
  open: boolean;
  anchorEl: any;
  onClose: VoidFunction;
  editId: string;
}

export const SHORTCUTS_STORE_KEY = '/items';

function getSavedShortcuts(): SavedShortcut[] {
  return JSON.parse(localStorage.getItem(SHORTCUTS_STORE_KEY) || '[]');
}

function saveShortcuts(shortcuts: SavedShortcut[]): void {
  localStorage.setItem(SHORTCUTS_STORE_KEY, JSON.stringify(shortcuts));
}

export function AddShortcutPopup({ open, anchorEl, onClose, editId }: Props) {
  const [url, setUrl] = React.useState('');
  const [title, setTitle] = React.useState('');

  function clear() {
    setUrl('');
    setTitle('');
  }

  function onSave(e: React.FormEvent) {
    e.preventDefault();

    const shortcuts = getSavedShortcuts();
    if (editId) {
      const index = shortcuts.findIndex(i => i.id === editId);
      if (index !== -1) {
        shortcuts[index].url = url.trim();
        shortcuts[index].title = title.trim();
      } else {
        shortcuts.push({ url: url.trim(), title: title.trim(), id: uuid() });
      }
    } else {
      shortcuts.push({ url: url.trim(), title: title.trim(), id: uuid() });
    }
    saveShortcuts(shortcuts);
    clear();
    onClose();
  }

  function onUseCurrentPage() {
    setUrl(window.location.pathname);
    setTitle(document.title.replace('| Sunrise', '').trim());
  }

  function onCancel() {
    clear();
    onClose();
  }

  function onRemove() {
    const shortcuts = getSavedShortcuts().filter(item => item.id !== editId);

    saveShortcuts(shortcuts);
    clear();
    onClose();
  }

  const onTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.currentTarget.value);
  };

  React.useEffect(() => {
    if (editId) {
      const shortcuts = getSavedShortcuts();
      const shortcut = shortcuts.find(i => i.id === editId);
      if (shortcut) {
        setUrl(shortcut.url);
        setTitle(shortcut.title);
      }
    }
  }, [editId]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      id="add-shortcut-popup"
    >
      <Card>
        <form onSubmit={onSave} onResetCapture={onCancel}>
          <CardHeader
            title="Add Shortcut"
            titleTypographyProps={{ variant: 'subtitle2' }}
            action={
              editId ? (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  type="button"
                  startIcon={<DeleteIcon />}
                  onClick={onRemove}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  type="button"
                  onClick={onUseCurrentPage}
                >
                  Use current page
                </Button>
              )
            }
          />
          <CardContent>
            <TextField
              type="text"
              placeholder="Enter a URL"
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Shortcut URL"
              variant="outlined"
              autoComplete="off"
              required
              inputProps={{ pattern: '(https?|/).*?' }}
              value={url}
              onChange={e => setUrl(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Enter a display name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Display Name"
              variant="outlined"
              autoComplete="off"
              required
              value={title}
              onChange={onTitleChange}
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Save
            </Button>
            <Button variant="outlined" size="large" type="reset">
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </Popover>
  );
}
