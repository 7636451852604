import React from 'react';
import { Link } from '@material-ui/core';
import { UserTeam } from '../../api/definitions';

const SUNRISE_USER_PROFILE_LINK =
  'https://sunrise.zalando.net/catalog/default/user';
const SUNRISE_TEAM_PROFILE_LINK =
  'https://sunrise.zalando.net/catalog/default/group';

const UserProfileLink = ({ userId }: { userId: string }) => {
  return (
    <Link
      href={`${SUNRISE_USER_PROFILE_LINK}/${userId}`}
      underline="none"
      title="User profile page"
    >
      {userId}
    </Link>
  );
};

const isValidTeamId = (teamId: string) => {
  return !!teamId.match(/^\d+$/);
};

const TeamProfileLink = ({ team }: { team: UserTeam | undefined }) => {
  if (!team || !team.team_id || !team.nickname) {
    return null;
  }
  // This check is needed until zflow-api returns valid numerical team ID for all teams
  // https://github.bus.zalan.do/zai/issues/issues/551
  if (!isValidTeamId(team.team_id)) {
    return <>{team.nickname}</>;
  }
  return (
    <Link
      href={`${SUNRISE_TEAM_PROFILE_LINK}/${team.team_id}`}
      underline="none"
      title="Team profile page"
    >
      {team.nickname}
    </Link>
  );
};

export { UserProfileLink, TeamProfileLink };
