import React from 'react';
import { Content, Header, HeaderLabel, Page } from '@backstage/core-components';
import { styled } from '@material-ui/core';

type Props = {
  title: string;
  subtitle: string;
  render: React.ReactNode;
  breadcrumbs?: JSX.Element;
};

const StyledContent = styled(Content)((props: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: props.theme.spacing(0),
}));

export function Layout({ render, title, subtitle, breadcrumbs }: Props) {
  return (
    <Page themeId="container-image">
      <Header title={title} subtitle={breadcrumbs ? breadcrumbs : subtitle}>
        <HeaderLabel label="Owner" value="Team Automata" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>

      <StyledContent>{render}</StyledContent>
    </Page>
  );
}
