import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { Box } from '@material-ui/core';
import { FavouriteEntity } from 'plugin-ui-components';

export const Title = ({
  entity,
  title,
}: {
  title: string;
  entity?: Entity;
}) => (
  <Box display="inline-flex" alignItems="center" height="1em">
    {title}
    {entity && <FavouriteEntity entity={entity} />}
  </Box>
);
