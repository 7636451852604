import { Model } from '../../../../api/interfaces/model';
import { createNameColumn } from './common';
import {
  createApplicationColumn,
  createdByColumn,
  createDescriptionColumn,
  createTimeColumn,
} from '../common';
import { useMemo } from 'react';
import { TableColumn } from '@backstage/core-components';

export const useModelColumns = (): TableColumn<Model>[] => {
  return useMemo(() => {
    return [
      createNameColumn(),
      createdByColumn(),
      createApplicationColumn(),
      createTimeColumn(),
      createDescriptionColumn(),
    ];
  }, []);
};
