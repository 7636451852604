import React from 'react';
import { Table } from '@backstage/core-components';
import { useStyles } from '../styles';

interface Props {
  data: ICDPMonitoring.PipelineStats | undefined;
  loading: boolean;
}

export function StepsFailRanking({ data, loading }: Props) {
  const styles = useStyles();
  const [ranking, setRanking] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (data?.step_info?.length) {
      const newRanking = data.step_info.map(item => {
        const failed = item?.execution_count?.FAILED || 0;
        const succeeded = item?.execution_count?.SUCCEEDED || 0;
        const total = succeeded + failed || 1;
        const successRatio = succeeded / total;

        return {
          name: item.step_name,
          failed,
          successRatio,
        };
      });

      setRanking(newRanking);
    }
  }, [data]);

  return (
    <div className={styles.failedRankingTable}>
      <Table
        title="Steps fail ranking"
        isLoading={loading}
        columns={[
          { title: 'Step name', field: 'name' },
          { title: '# failed', field: 'failed', defaultSort: 'desc' },
          {
            title: 'Success ratio',
            field: 'successRatio',
            render: item =>
              Intl.NumberFormat('en', {
                style: 'percent',
                maximumFractionDigits: 1,
              }).format(item.successRatio),
          },
        ]}
        data={ranking}
        options={{
          rowStyle: { background: 'transparent' },
          headerStyle: { whiteSpace: 'nowrap' },
          padding: 'dense',
          search: false,
          pageSize: 2,
          minBodyHeight: 2,
          pageSizeOptions: [],
          emptyRowsWhenPaging: false,
        }}
      />
    </div>
  );
}
