import React from 'react';
import { Grid } from '@material-ui/core';
import { Content } from '@backstage/core-components';
import { MLModelGraphics } from '../../../api/definitions';
import { MissingFieldPlaceholder } from '../../common/MissingFieldPlaceholder';

export const ModelGraphic = ({
  model_set_graphic,
  name,
}: {
  model_set_graphic: MLModelGraphics | undefined;
  name: string;
}) => {
  let datasetGraphics: any;

  if (model_set_graphic) {
    datasetGraphics = {
      name: name,
      collections: model_set_graphic?.collection,
      description: model_set_graphic?.description,
    };
  } else {
    datasetGraphics = null;
  }

  return (
    <Content>
      <Grid container>
        {datasetGraphics ? (
          datasetGraphics.collections.map((collection: any) => (
            <Grid item xs={6}>
              <img
                src={`data:image/png;base64,${collection.image}`}
                alt={`${name.toLowerCase()} data graphics`}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <MissingFieldPlaceholder
              field={`model ${name.toLowerCase()} graphic`}
              type="model-card"
            />
          </Grid>
        )}
      </Grid>
    </Content>
  );
};
