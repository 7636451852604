import React from 'react';
import { StacksetModel } from '../../../../models';
import { useTrafficStyles } from './styles';

export function StacksetTrafficMap({
  stackset,
  currentVersion,
}: {
  stackset: StacksetModel;
  currentVersion: string;
}) {
  const styles = useTrafficStyles();

  return (
    <div className={styles.container}>
      <p className={styles.header}>stack version</p>
      <p className={styles.header}>actual</p>
      <p className={styles.header}>desired</p>

      {Object.entries(stackset.traffic).map(([name, weight]) => {
        if (name.includes(currentVersion)) {
          return (
            <>
              <p className={styles.itemHighlighted}>{name}</p>
              <p className={styles.itemHighlighted}>{weight.actual}</p>
              <p className={styles.itemHighlighted}>{weight.desired}</p>
            </>
          );
        }
        return (
          <>
            <p className={styles.item}>{name}</p>
            <p className={styles.item}>{weight.actual}</p>
            <p className={styles.item}>{weight.desired}</p>
          </>
        );
      })}
    </div>
  );
}
