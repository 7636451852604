import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'nakadi-events',
});

// Catalog Routes
export const eventsCatalogueRouterRef = createRouteRef({
  id: 'Nakadi Events',
});

// Nakadi SQL Queries Routes
export const nakadiSqlQueryLandingPageRoute = createRouteRef({
  id: 'Queries',
  params: [':query_id'],
});
