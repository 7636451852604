import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';

export const pipelineApiRef = createApiRef<ICDPMonitoring.ClientType>({
  id: 'plugin.cdp-pipeline',
});

interface Apis {
  discoveryApi: DiscoveryApi;
}

export class PipelineClient implements ICDPMonitoring.ClientType {
  private readonly discoveryApi: DiscoveryApi;

  constructor(apis: Apis) {
    this.discoveryApi = apis.discoveryApi;
  }

  async getPipelineStats(path: string): Promise<ICDPMonitoring.PipelineStats> {
    const url = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${url}/pipeline-monitoring/pipelines${path}`;

    const response = await fetch(serviceUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await response.json();
    return data;
  }

  async getPipelineTimeseries(
    path: string,
  ): Promise<ICDPMonitoring.PipelinePerformanceTimeseries> {
    const url = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${url}/pipeline-monitoring/pipelines${path}`;

    const response = await fetch(serviceUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await response.json();

    return data;
  }
}
