import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  LoadTestingApiClient,
  loadTestingApiRef,
} from './api/LoadTestOrchestratorApi';
import { LoadTestingRouter } from './Router';
import { rootRouteRef } from './routes';

export const loadTestingPlugin = createPlugin({
  id: 'load-testing',
  featureFlags: [{ name: 'load-testing' }],
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: loadTestingApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new LoadTestingApiClient({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});

export const LoadTestingPage = loadTestingPlugin.provide(
  createRoutableExtension({
    name: 'LoadTestingPage',
    component: async () => LoadTestingRouter,
    mountPoint: rootRouteRef,
  }),
);
