import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { SPECIAL_CHARACTERS } from '../../../constants/common';

export const RunNameInput = ({
  setNewRunName,
  disableStart,
  randomRunID,
}: {
  setNewRunName: React.Dispatch<React.SetStateAction<string | null>>;
  disableStart: React.Dispatch<React.SetStateAction<boolean>>;
  randomRunID: string;
}) => {
  const [error, setError] = useState<boolean>(false);
  const [runNameLength, setRunNameLength] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const runName = event.target.value;
    setRunNameLength(runName.length);
    const isInvalid = runName.length > 80 || SPECIAL_CHARACTERS.test(runName);
    setError(isInvalid);
    if (isInvalid) {
      disableStart(true);
    } else {
      disableStart(false);
      setNewRunName(runName);
    }
  };

  const helperText = `The run name must not contain spaces, emoji, or special characters except for _ and -.
   The length should be between 1 and 80 characters. 
   Current length: ${runNameLength}`;

  return (
    <TextField
      fullWidth
      label="Run Name (optional)"
      error={error}
      placeholder={randomRunID}
      helperText={helperText}
      onChange={handleChange}
      required={false}
    />
  );
};
