import React from 'react';
import { watchTeamsLink } from './tracking';
import { Typography } from '@material-ui/core';
import { TrackedLink } from 'plugin-ui-components';

export function EmptyState() {
  return (
    <Typography>
      We couldn't find any pipeline activity by your team(s), but you can update
      your{' '}
      <TrackedLink to="/settings/personalization" {...watchTeamsLink}>
        personalization settings
      </TrackedLink>{' '}
      to "watch" content from the delivery teams you collaborate with, which
      will enable you to view it here.
    </Typography>
  );
}
