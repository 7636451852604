import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../api';
import { StacksetResponse } from '../../../../api/types/responses';

export function useStacksetDetails(
  stepRunId: string,
  stackName: string,
  setFn: (id: string, v: string, s: StacksetResponse) => void,
) {
  const api = useApi(cdpApiRef);

  const getStackset = useCallback(async () => {
    if (!stepRunId) {
      return Promise.resolve();
    }

    try {
      const response = await api.getTrafficStackset(stepRunId);
      setFn(stepRunId, stackName, response);
      return Promise.resolve(response);
    } catch (error) {
      const e = error as Error;
      return Promise.reject(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepRunId, stackName]);

  const { loading, error, value, retry } = useAsyncRetry(
    () => getStackset(),
    [getStackset, stepRunId, stackName],
  );

  return [
    { loading, error, value: value || [] },
    {
      retry,
      getStackset,
    },
  ] as const;
}
