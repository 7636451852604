import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import GetAppIcon from '@material-ui/icons/GetApp';
import { oauth2ApiRef } from 'plugin-core';

import {
  Emoji,
  InfoCard,
  AuthenticatedLink,
  CopyAction,
  Tooltip,
} from '../../../../common';
import { STEP_STATUS, TEST_TYPES } from '../../../../constants';
import { getColorByStatus } from '../../../../utils/pipeline';
import { groupUploads } from './utils';
import { IGropuedUploads, ITestUpload } from '../../../../api/types/payload';
import { JUnitTestResults } from './JUnitTestResults';
import { HTMLTestResults } from './HTMLTestResults';
import { useServices } from '../../../../services';

import * as S from './styles';

type Props = {
  defaultIndex: string;
  defaultType: string;
  uploads: ITestUpload[];
  stepRunId: string;
};

type Selection = {
  index: string;
  type: string;
};

function ErrorListSection({ list }: { list: ITestUpload[] }) {
  return (
    <InfoCard title="Errors">
      <S.List>
        {list.map((item, i) => (
          <S.Item key={i}>
            <Emoji emoji="🛑" name="Stop sign" />

            <span>
              {`Error with ${item.type} upload of ${item.name}: ${item.error}`}
            </span>
          </S.Item>
        ))}
      </S.List>
    </InfoCard>
  );
}

function HtmlListSection({
  list,
  action,
}: {
  list: ITestUpload[];
  action: (selection: Selection) => void;
}) {
  return (
    <InfoCard title="HTML">
      <S.List>
        {list.map((item, i) => (
          <S.Item key={i}>
            <Box onClick={() => action({ index: item.index, type: item.type })}>
              <Emoji emoji="🗂️" name="Folder" />

              <span>{item.name}</span>
            </Box>

            <S.Actions>
              <Download file={item.index} name={item.name} />
              <CopyAction
                text={`curl https://cdp-toster.stups.zalan.do/files/${item.index} -H "Authorization: Bearer $(ztoken)" > /tmp/${item.name}.html && open /tmp/${item.name}.html`}
              />
            </S.Actions>
          </S.Item>
        ))}
      </S.List>
    </InfoCard>
  );
}

function JunitListSection({
  list,
  action,
}: {
  list: ITestUpload[];
  action: (selection: Selection) => void;
}) {
  return (
    <InfoCard title="JUnit">
      <S.List>
        {list.map((item, i) => (
          <S.Item
            key={i}
            onClick={() => action({ index: item.index, type: item.type })}
          >
            <Emoji emoji={!item.failed ? '✅' : '❌'} name="Status" />

            <span>{`${item.name} (${item.total})`}</span>

            {!!item.failed && (
              <S.Tag color={getColorByStatus(STEP_STATUS.FAILED)}>
                {item.failed}
              </S.Tag>
            )}
          </S.Item>
        ))}
      </S.List>
    </InfoCard>
  );
}

function TestList({
  uploads: { errors, junit, html },
  onClick,
}: {
  uploads: IGropuedUploads;
  onClick: (selection: Selection) => void;
}) {
  return (
    <Box padding={2}>
      {!!errors.length && <ErrorListSection list={errors} />}

      {!!junit.length && <JunitListSection list={junit} action={onClick} />}

      {!!html.length && <HtmlListSection list={html} action={onClick} />}
    </Box>
  );
}

export function TestUploadsList({ defaultIndex, defaultType, uploads }: Props) {
  const [showTestList, setShowTestList] = useState<boolean>(uploads.length > 1);
  const [selectedTest, setSelectedTest] = useState<Selection>({
    index: defaultIndex,
    type: defaultType,
  });

  function handleClick(selection: Selection) {
    setSelectedTest(selection);
    setShowTestList(false);
  }

  function navigateBack() {
    setShowTestList(true);
  }

  return (
    <>
      {showTestList && (
        <TestList uploads={groupUploads(uploads)} onClick={handleClick} />
      )}

      {!showTestList && selectedTest.type === TEST_TYPES.html && (
        <HTMLTestResults src={selectedTest.index} navigateBack={navigateBack} />
      )}

      {!showTestList && selectedTest.type === TEST_TYPES.junit && (
        <JUnitTestResults
          url={selectedTest.index}
          navigateBack={navigateBack}
        />
      )}
    </>
  );
}

function Download({ file, name }: { file: string; name: string }) {
  const { runId = '', stepId = '' } = useParams();
  const discoveryApi = useApi(discoveryApiRef);
  const oauth2Api = useApi(oauth2ApiRef);

  const { runService } = useServices();
  const { id: stepRunId } = runService.step?.run;

  function download() {
    return discoveryApi.getBaseUrl('cdp').then(url => {
      return oauth2Api.getAccessToken().then(token => {
        return fetch(
          `${url}/runs/${runId}/steps/${stepId}/runs/${stepRunId}/testUploads/${file}`,
          {
            headers: new Headers({ Authorization: `Bearer ${token}` }),
            credentials: 'include',
          },
        );
      });
    });
  }

  return (
    <Box
      display="flex"
      justifyItems="center"
      alignItems="center"
      paddingLeft={1.5}
      paddingRight={1.5}
      borderLeft="1px solid white"
      borderRight="1px solid white"
      fontSize="17px"
    >
      <AuthenticatedLink filename={name} action={download}>
        <Tooltip title="Download file" placement="top">
          <span style={{ lineHeight: 0 }}>
            <GetAppIcon fontSize="inherit" color="inherit" />
          </span>
        </Tooltip>
      </AuthenticatedLink>
    </Box>
  );
}
