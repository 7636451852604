const defaultEntity: IEntityApp = {
  kind: 'Component',
  relations: [],
  apiVersion: 'kio',
  metadata: {
    name: '',
  },
} as any;

export const transformKioAppIntoEntity = (
  kioApp?: IKioAppApiResponse,
): IEntityApp => {
  if (!kioApp) return defaultEntity;
  return {
    ...defaultEntity,
    metadata: {
      name: kioApp.name,
      uid: kioApp.id,
      description: kioApp.description || '',
      namespace: 'default',
      tags: [],
      apecStatus: undefined as any,
      cyberweekInScope: false,
      productionReadinessReviewDate: undefined as any,
      lastReview: undefined as any,
    },
    spec: {
      name: kioApp.name,
      owner: kioApp.team_id,
      type: 'service',
      lifecycle:
        typeof kioApp.criticality_level === 'number'
          ? (`Tier ${kioApp.criticality_level}` as any)
          : 'Tier unknown',
      spec: {
        name: kioApp.name,
        scm_url: kioApp.scm_url,
        service_url: kioApp.service_url,
        id: kioApp.id,
        active: kioApp.active,
        team_id: kioApp.team_id,
        owner_name: kioApp.team_id,
        last_modified: kioApp.last_modified,
        subtitle: kioApp.subtitle,
        criticality_level: kioApp.criticality_level,
        support_url: kioApp.support_url,
        documentation_url: kioApp.documentation_url,
        description: kioApp.description,
        required_approvers: kioApp.required_approvers,
        specification_url: kioApp.specification_url,
        specification_type: kioApp.specification_type,
        incident_contact: kioApp.incident_contact,
        publicly_accessible: kioApp.publicly_accessible,
      },
    },
  };
};
