import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { DatePicker, Input, SectionRadio } from '../common';

export function ProductionReadinessReview() {
  return (
    <section>
      <Typography>
        For major and architectural changes complete a&nbsp;
        <Link to="https://engineering.docs.zalando.net/practices/reviews/production-readiness/">
          Production Readiness Review
        </Link>
        &nbsp;with Principal Engineers from your organization.&nbsp;
        <span className="badge error">Tier-1</span>
        &nbsp;applications MUST conduct the Production Readiness Review.&nbsp;
        <span className="badge warning">Tier-2</span>
        &nbsp;applications SHOULD be reviewed (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ). Please use the&nbsp;
        <Link to="https://docs.google.com/document/d/1F2Q1YlV40dsuZ3E0bWgrpZuK-GDleemEvKOwb8d5_24/copy">
          PRR template <OpenInNewIcon />
        </Link>
        &nbsp;to facilitate reviews. Contact Principal Engineers in your
        organization for additional information about the review process.
      </Typography>

      <br />

      <Input
        name="production_readiness_review_url"
        type="url"
        placeholder="Google document URL"
      />
      <br />
      <DatePicker name="production_readiness_review_date" />

      <SectionRadio name="production_readiness_review_ok" />
    </section>
  );
}
