import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function IncidentContact({
  value,
  issue,
}: FieldProps<'incident_contact'>) {
  return (
    <>
      <dt>Incident Contact (24/7 on-call)</dt>
      <dd>
        {value || (
          <i>
            No incident contact, i.e. this application has no 24/7 on-call
            support.
          </i>
        )}
        <InfoTooltip>
          The incident contact should be the responsible 24/7 on-call (OpsGenie)
          team. Leave this field empty if the application does not need 24/7
          on-call support.
        </InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
