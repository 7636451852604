import { Content, TableColumn } from '@backstage/core-components';
import React from 'react';
import { CatalogFilter } from '../CatalogFilter';
import {
  apiColumns,
  CatalogTable,
  applicationColumns,
  docsColumns,
} from '../CatalogTable';
import { APIResultsFilter, ApplicationResultsFilter } from '../ResultsFilter';
import { EmptyContent } from './EmptyContent';
import * as S from './styles';
import { TableButtons } from './TableButtons';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { getQueryValue } from 'plugin-core';
import { useFilteredEntities } from '../../../hooks';
import { DocsResultsFilter } from '../ResultsFilter/DocsResultsFilter';

type CatalogPageProps = {
  type: 'api' | 'application' | 'docs';
  filterGroups: ICatalog.ButtonGroup[];
  initiallySelectedFilter?: string;
};

export function CatalogPage({
  type,
  filterGroups,
  ...props
}: CatalogPageProps) {
  const { loading, error, matchingEntities } = useFilteredEntities();
  const [order, setOrder] = React.useState<'asc' | 'desc' | ''>('asc');
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const showScorecardsColumn = featureFlagsApi.isActive('tech-insights');
  const [selectedSidebarItem, setSelectedSidebarItem] = React.useState<{
    id: string;
    label: string;
  }>();
  const initiallySelectedFilter =
    selectedSidebarItem?.id ??
    props.initiallySelectedFilter ??
    (getQueryValue('group') || 'mine');

  function getColumns(): TableColumn<ICatalog.EntityRow>[] {
    switch (type) {
      case 'application':
        return applicationColumns(
          order,
          showScorecardsColumn ? [] : ['scorecards'],
        );
      case 'api':
        return apiColumns;
      case 'docs':
        return docsColumns;
      default:
        return [];
    }
  }

  const tableProps = {
    titlePreamble: selectedSidebarItem?.label ?? '',
    columns: getColumns(),
    entities: matchingEntities,
    loading: loading,
    error: error,
    emptyContent: selectedSidebarItem?.id === 'mine' && (
      <EmptyContent action={type} />
    ),
    ...(type === 'application' && { setOrder }),
    canExport: type === 'application',
  };

  return (
    <Content>
      {type === 'application' && <TableButtons showRegister />}
      <S.ContentWrapper>
        <div>
          <CatalogFilter
            buttonGroups={filterGroups}
            onChange={setSelectedSidebarItem}
            initiallySelected={initiallySelectedFilter}
          />
          {type === 'application' && <ApplicationResultsFilter />}
          {type === 'api' && <APIResultsFilter />}
          {type === 'docs' && <DocsResultsFilter />}
        </div>

        <CatalogTable {...tableProps} />
      </S.ContentWrapper>
    </Content>
  );
}
