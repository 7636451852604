import { useApi } from '@backstage/core-plugin-api';
import React, { useState, useEffect, useCallback } from 'react';
import { dashboardsApiRef } from '../../api';
import { BaseLayout, ListEntities } from '../../components';
import { useParams } from 'react-router';
import { Box, Link, Typography } from '@material-ui/core';
import { getDatalabUrl } from '../../utils';
import { Content, SubvalueCell } from '@backstage/core-components';
import { TagGroup, UserTag } from '../../components/Tags';

export const DashboardVersions = () => {
  const { dashboardId = '' } = useParams();
  const dashboardsApiClient = useApi(dashboardsApiRef);
  const [page, setPage] = useState(0);
  const [dashboardName, setDashboardName] = useState('');
  const [error, setError] = useState<Error>();

  const getDetails = useCallback(async () => {
    const res = await dashboardsApiClient.getDashboardDetail(dashboardId);
    setDashboardName(res.name);
  }, [dashboardId, dashboardsApiClient]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const COLUMNS = [
    {
      title: 'Commit Message',
      field: 'commit_message',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.commit_message}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Created At',
      field: 'created_at',
    },
    {
      title: 'Link',
      render: (row: any) => {
        const datalabUrl = getDatalabUrl();
        const href = `${datalabUrl}/services/zflow_dashboards/user/${row.created_by}/zflow/dashboards/rshiny/${dashboardName}/${row.id}/`;
        return (
          <Link target="_blank" href={href}>
            Open version {row?.id}
          </Link>
        );
      },
    },
  ];

  return (
    <BaseLayout notebookName={dashboardName}>
      <Content>
        <Box paddingBottom={2}>
          <Typography variant="h4">
            <Box component="span" fontWeight="fontWeightLight">
              Versions
            </Box>
          </Typography>
        </Box>
        <ListEntities
          getEntitiesPromise={dashboardsApiClient.getVersions(
            dashboardId,
            page,
          )}
          entityKey="versions"
          columns={COLUMNS}
          childEntityKey=""
          title="Versions"
          page={page}
          onPageChange={setPage}
          skipNavigateOnClick
          error={error}
          setError={setError}
        />
      </Content>
    </BaseLayout>
  );
};
