import React from 'react';
import { SignInPageProps } from '@backstage/core-plugin-api';
import { SignInProviderConfig, SignInPage } from '@backstage/core-components';

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

const CustomSignInPage = (props: SingleSignInPageProps) => {
  function getCookie(name: string) {
    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${name}=([^;]*)`));
    return match ? match[1] : null;
  }

  function shouldAutoLogin() {
    const isUserLoggedOut = Boolean(getCookie(`oauth2-logout`));
    document.cookie =
      'oauth2-logout=; Max-Age=0; SameSite=Lax; domain=.zalando.net';
    return isUserLoggedOut ? false : true;
  }

  return <SignInPage auto={shouldAutoLogin()} {...props} />;
};

export default CustomSignInPage;
