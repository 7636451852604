/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { CostOverviewChart } from './CostOverviewChart';
import { CostOverviewHeader } from './CostOverviewHeader';
import { MetricSelect } from '../MetricSelect';
import { PeriodSelect } from '../PeriodSelect';
import { useConfig, useFilters } from '../../hooks';
import { mapFiltersToProps } from './selector';
import { DefaultNavigation } from '../../utils/navigation';
import { findAlways } from '../../utils/assert';
import { Cost, Duration, Group, Maybe, MetricData } from '../../types';
import { ScrollAnchor } from '../../utils/scroll';
import { CostOverviewModeSelect } from '../CostOverviewModeSelect';
import { colors } from 'plugin-ui-components';

export type CostOverviewCardProps = {
  dailyCostData: Cost;
  metricData: Maybe<MetricData>;
  group?: Group;
};

export const CostOverviewCard = ({
  dailyCostData,
  metricData,
  group,
}: CostOverviewCardProps) => {
  const config = useConfig();
  const { setDuration, setProject, setMetric, setOverviewMode, ...filters } =
    useFilters(mapFiltersToProps);

  const metric = filters.metric
    ? findAlways(config.metrics, m => m.kind === filters.metric)
    : null;

  let title = group ? group.name : '';
  switch (filters.duration) {
    case Duration.P1W:
    case Duration.P6W:
    case Duration.P7D:
      title += ' Weekly ';
      break;
    case Duration.P30D:
      title += ' Daily ';
      break;
    default:
      title += ' Daily ';
  }
  switch (filters.overviewMode) {
    case 'total':
      title = `* ${title} Total Costs`;
      break;
    case 'stups':
      title += ' STUPS Costs';
      break;
    case 'kubernetes':
      title = `* ${title} Kubernetes Costs`;
      break;
    case 'nakadi':
      title += ' Nakadi Costs';
      break;
    case 'scalyr':
      title += ' Scalyr Costs';
      break;
    default:
  }

  let subtitle = '';
  switch (filters.overviewMode) {
    case 'stups':
      subtitle = `Team: ${
        dailyCostData.account && dailyCostData.account.team
          ? dailyCostData.account.team
          : ''
      }`;
      break;
    case 'kubernetes':
    case 'total':
      subtitle = `Cost Center: ${
        dailyCostData.account && dailyCostData.account.costCenter
          ? dailyCostData.account.costCenter
          : ''
      }`;
      break;
    default:
  }

  // Metrics can only be selected on the total cost graph
  const showMetricSelect =
    config.metrics.length && dailyCostData.aggregation.length > 0;

  return (
    <Card style={{ position: 'relative', overflow: 'visible' }}>
      <ScrollAnchor id={DefaultNavigation.CostOverviewCard} />
      <CardContent>
        <CostOverviewHeader title={title} subtitle={subtitle}>
          <Box mr={1}>
            <PeriodSelect
              mode={filters.overviewMode}
              duration={filters.duration}
              onSelect={setDuration}
            />
          </Box>
          <CostOverviewModeSelect
            mode={filters.overviewMode}
            onSelect={setOverviewMode}
          />
        </CostOverviewHeader>
        <Divider />
        {dailyCostData.aggregation && dailyCostData.aggregation.length > 0 ? (
          <Box ml={2} my={1} display="flex" flexDirection="column">
            <CostOverviewChart
              dailyCostData={dailyCostData}
              metric={metric}
              metricData={metricData}
            />
            {(filters.overviewMode === 'total' ||
              filters.overviewMode === 'kubernetes') && (
              <Typography variant="caption">
                *{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Kubernetes costs can currently only be displayed for the
                  entire cost center.
                </span>{' '}
                Hosting costs cannot be broken down to a team or application
                level because there are{' '}
                <span style={{ fontWeight: 'bold' }}>
                  still untagged resources
                </span>{' '}
                in{' '}
                <a
                  href="https://sunrise.zalando.net/infrastructure/my-cost-center"
                  style={{ color: colors.brand.primaryOrange }}
                >
                  your accounts
                </a>{' '}
                / clusters. Please{' '}
                <a
                  href="https://sunrise.zalando.net/docs/default/Documentation/cloud/reference/aws-tags/"
                  style={{ color: colors.brand.primaryOrange }}
                >
                  take action to tag your resources
                </a>
                .
              </Typography>
            )}
          </Box>
        ) : (
          <Typography>
            There are no costs within this time frame for your team's projects.
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {showMetricSelect === true && (
            <MetricSelect
              metric={filters.metric}
              metrics={config.metrics}
              onSelect={setMetric}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
