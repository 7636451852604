import { Theme, styled } from '@material-ui/core';
import { fontSize, TagMedium } from '../../../common';

export const Header = styled('article')((props: { theme: Theme }) => ({
  [props.theme.breakpoints.up('md')]: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
}));

export const Heading = styled('section')((props: { theme: Theme }) => ({
  display: 'flex',
  minHeight: '35px',
  alignItems: 'center',
  overflow: 'hidden',

  [props.theme.breakpoints.up('md')]: {
    flex: '1 1 0%',
    maxWidth: '60%',
  },
}));

export const Tag = styled(TagMedium)({
  flexShrink: 0,
});

export const SubHeading = styled('section')((props: { theme: Theme }) => ({
  padding: props.theme.spacing(1, 0),
  fontSize: fontSize.default,
  lineHeight: 2,

  ['& svg']: {
    verticalAlign: 'middle',
    marginBottom: '2px',
  },

  [props.theme.breakpoints.up('md')]: {
    order: 3,
    flex: '0 0 100%',
  },
}));

export const Actions = styled('section')((props: { theme: Theme }) => ({
  [props.theme.breakpoints.up('md')]: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: props.theme.spacing(2),
  },

  ['& > * + *']: {
    marginLeft: props.theme.spacing(1),
  },
}));

export const Title = styled('h1')((props: { theme: Theme }) => ({
  flexShrink: 1,
  margin: 0,
  padding: props.theme.spacing(0, 2),
  fontSize: fontSize.large,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
