import React from 'react';
import {
  Chip,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useStyles } from '../../../styles/catalog';
import Autocomplete from '@mui/material/Autocomplete';

interface ResourceFilterProps {
  label: string;
  id: string;
  values?: { label: string; value: string }[];
  onChange?: (value: string) => void;
  selected?: string;
  tags?: string[];
  placeholder?: string;
  disabled: boolean;
}

export const TextFieldFilter: React.FC<ResourceFilterProps> = React.memo(
  ({ label, id, onChange, selected, placeholder }) => {
    const { inputRoot: select } = useStyles();

    return (
      <TextField
        key={id}
        fullWidth
        label={label}
        value={selected}
        defaultValue={selected}
        placeholder={placeholder}
        onChange={e => onChange?.(e.target.value)}
        variant="outlined"
        size="small"
        classes={{ root: select }}
        margin="dense"
      />
    );
  },
);

export const TagInputFilter: React.FC<ResourceFilterProps> = React.memo(
  ({ label, id, onChange, selected, disabled }) => {
    const { inputRoot: select } = useStyles();

    const formatSelected = (value: string) => (value ? value.split(',') : []);

    const handleAutocompleteChange = (value: string[] | null) => {
      const selectedValue = value ? value.join(',') : '';
      onChange?.(selectedValue);
    };

    return (
      <Autocomplete
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        disabled={disabled}
        value={formatSelected(selected || '')}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
        onChange={(_, value) => handleAutocompleteChange(value)}
        renderInput={params => (
          <TextField
            key={id}
            label={label}
            placeholder="Filter by tags"
            variant="outlined"
            classes={{ root: select }}
            {...params}
            margin="dense"
          />
        )}
      />
    );
  },
);

export const AutocompleteResourceFilter: React.FC<ResourceFilterProps> =
  React.memo(({ label, id, onChange, selected, values, disabled }) => {
    const { inputRoot: select } = useStyles();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string | string[];
      const selectedValue = Array.isArray(value) ? value.join(',') : value;
      onChange?.(selectedValue);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    return (
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          value={selected || ''}
          onChange={handleChange}
          labelId={id}
          label={label}
          classes={{ root: select }}
          disabled={disabled}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          }}
        >
          {values?.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  });
