import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { zflowApiRef, ArtifactDetails } from 'plugin-ml-platform';
import { AboutField } from 'plugin-ui-components';

interface ArtifactProps {
  type: string;
  id: string;
}

const renderArtifact = (type: string, artifact: string, name: string) => {
  switch (type) {
    case 'image':
      return <img src={`data:image/png;base64, ${artifact}`} alt={name} />;
    default:
      return <div>{artifact}</div>;
  }
};
export const Artifact = (props: ArtifactProps) => {
  const zflowApiClient = useApi(zflowApiRef);

  const [details, setDetails] = useState<ArtifactDetails>();

  useEffect(() => {
    const getArtifactDetails = async () => {
      const res = await zflowApiClient.getArtifactDetails(props.id);
      setDetails(res);
    };
    getArtifactDetails();
  }, [props, zflowApiClient]);

  return (
    <>
      {details ? (
        <AboutField
          style={{ textAlign: 'center' }}
          xs={4}
          label={details.name}
          children={renderArtifact(
            details.type,
            details.artifact,
            details.name,
          )}
        />
      ) : null}
    </>
  );
};
