import { useState } from 'react';
import {
  RollbackConfirmation,
  RollbackPlan,
} from '../../../../../api/types/responses';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../../api';

export function useRollbackPlans(domain: string, org: string, repo: string) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | Error>(null);
  const [value, setValue] = useState<null | RollbackPlan>(null);
  const cdpAPI = useApi(cdpApiRef);

  const fetch = async () => {
    setLoading(true);
    setValue(null);
    setError(null);

    try {
      const plan = await cdpAPI.getRollbackPlan(domain, org, repo);
      setLoading(false);
      setValue(plan);
    } catch (e) {
      const err = e as Error;
      setError(err);
      setLoading(false);
    }
  };

  return [{ loading, error, value }, fetch] as const;
}

export function useRollback() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | Error>(null);
  const [confirmation, setConfirmation] = useState<RollbackConfirmation | null>(
    null,
  );
  const cdpAPI = useApi(cdpApiRef);

  const request = async (actionURL: string) => {
    setLoading(true);
    setConfirmation(null);
    setError(null);

    try {
      const response = await cdpAPI.rollbackDeployment(actionURL);
      setConfirmation(response);
      setLoading(false);
    } catch (e) {
      const err = e as Error;
      setError(err);
      setLoading(false);
    }
  };

  return [{ loading, error, confirmation }, request] as const;
}
