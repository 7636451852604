import React from 'react';
import { Link, LinkProps } from '@backstage/core-components';
import { Button, ButtonProps } from '@material-ui/core';
import { PluginTracking } from 'plugin-ui-components';

type Props = ButtonProps & ITrackingEvent & LinkProps;

export const InternalTrackedButton = React.forwardRef<any, Props>(
  (props, ref) => {
    const {
      plugin,
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
      ...buttonProps
    } = props;

    const handleOnClick = () => {
      PluginTracking.sendEvent({
        plugin,
        eventCategory,
        eventLabel,
        eventAction,
        eventValue,
      });
    };

    return (
      <Button
        ref={ref}
        {...buttonProps}
        component={Link}
        onClick={handleOnClick}
      />
    );
  },
);
