import { observable, computed, action, makeObservable } from 'mobx';
import isEqual from 'lodash/isEqual';
import {
  OrganizationModel,
  RepositoryModel,
  RepositoryTreeModel,
} from '../repository';

export class RepositoryFilteringModel {
  repositories: Array<RepositoryModel> = [];
  organizations: Array<OrganizationModel> = [];
  userPreferenceOrganizations: Array<OrganizationModel> = [];
  userSelectedOrganizations: Array<OrganizationModel> = [];
  userSelectedRepositories: Array<RepositoryModel> = [];

  constructor() {
    makeObservable<this, 'shake'>(this, {
      repositories: observable,
      organizations: observable,
      userPreferenceOrganizations: observable,
      userSelectedOrganizations: observable,
      userSelectedRepositories: observable,
      setRepositoryTree: action,
      setFilteredRepositories: action,
      setUserSelection: action,
      setUserOrganizationPreference: action,
      setUserSelectedOrganizations: action,
      setUserSelectedRepositories: action,
      shake: action,
      filteredRepositories: computed,
      selected: computed,
      userSelection: computed,
    });
  }

  // Actions
  private shake() {
    this.setUserSelectedRepositories(
      this.userSelectedRepositories.filter(
        repository =>
          this.userSelectedOrganizations.findIndex(
            organization =>
              organization.fullPath === repository.fullOrganizationPath,
          ) > -1,
      ),
    );
  }

  public setRepositoryTree = (repositoryTree: RepositoryTreeModel) => {
    this.organizations = repositoryTree.organizations;
    this.repositories = repositoryTree.repositories;
  };

  public setFilteredRepositories = (
    names: Array<string>,
    organization: string,
    domain: string,
  ) => {
    this.repositories = names.map(
      name => new RepositoryModel(name, organization, domain),
    );
  };

  public setUserOrganizationPreference = (
    userOrganizations: Array<OrganizationModel>,
  ) => {
    this.userPreferenceOrganizations = userOrganizations.filter(
      userOrganization =>
        this.organizations.findIndex(
          organization => organization.fullPath === userOrganization.fullPath,
        ) !== -1,
    );

    this.setUserSelectedOrganizations(this.userPreferenceOrganizations);
  };

  public setUserSelection = (
    repositories: Array<RepositoryModel>,
    organizations: Array<OrganizationModel>,
  ) => {
    this.setUserSelectedOrganizations(organizations);
    this.setUserSelectedRepositories(repositories);
  };

  public setUserSelectedOrganizations = (value: Array<OrganizationModel>) => {
    if (!isEqual(this.userSelectedOrganizations, value)) {
      this.userSelectedOrganizations = value;
      this.shake();
    }
  };

  public setUserSelectedRepositories = (value: Array<RepositoryModel>) => {
    if (!isEqual(this.userSelectedRepositories, value)) {
      this.userSelectedRepositories = value;
      this.shake();
    }
  };

  // Computed
  public get filteredRepositories() {
    return this.repositories.filter(
      repository =>
        this.userSelectedOrganizations.findIndex(
          organization =>
            organization.fullPath === repository.fullOrganizationPath,
        ) > -1,
    );
  }

  public get selected(): Array<string> {
    if (this.userSelectedRepositories.length > 0) {
      return this.userSelectedRepositories.map(
        repository => repository.fullPath,
      );
    }

    return this.userSelectedOrganizations.map(
      organization => organization.name,
    );
  }

  public get userSelection(): {
    repositories: Array<string>;
    organizations: Array<string>;
  } {
    return {
      repositories: this.userSelectedRepositories.map(
        item => item.fullAliasPath,
      ),
      organizations: this.userSelectedOrganizations.map(
        item => item.fullAliasPath,
      ),
    };
  }

  // Helpers (Non Computed)
  public get hasRepositorySelection(): boolean {
    return this.userSelectedRepositories.length > 0;
  }

  public get hasAnySelection(): boolean {
    return (
      this.userSelectedOrganizations.length > 0 ||
      this.userSelectedRepositories.length > 0
    );
  }
}
