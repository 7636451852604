import React, { useState } from 'react';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LaunchIcon from '@material-ui/icons/Launch';

import 'style-loader!css-loader!sass-loader!./deploymentStatusResource.scss';

import { DEPLOYMENT_RESOURCE_STATUS, STEP_STATUS } from '../../../../constants';
import {
  allHealthy,
  allPending,
  getFinalStatus,
  getStatusIcon,
  stepNotRunning,
} from './utils';
import { DeploymentResourceModel } from '../../../../models';
import WarningIcon from '@material-ui/icons/Warning';

type Props = {
  stepStatus: string;
  resource: DeploymentResourceModel;
};

const renderLinks = (links: { title: string; url: string }[]) => {
  const linkNodes = links.map(({ title, url }) => (
    <a
      className="external"
      key={title}
      href={url}
      title={title}
      target="_blank"
      rel="noreferrer noopener"
    >
      {title}
      <LaunchIcon />
    </a>
  ));

  return linkNodes;
};

export function DeploymentStatusResource({ resource, stepStatus }: Props) {
  const [touchedByUser, setTouchedByUser] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  function toggle() {
    setIsOpened(touchedByUser ? !isOpened : !shouldBeOpened());
    setTouchedByUser(true);
  }

  function shouldBeOpened() {
    return (
      (stepNotRunning(stepStatus) &&
        !allHealthy(resource) &&
        !allPending(resource)) ||
      (stepStatus === STEP_STATUS.IN_PROGRESS && !allHealthy(resource))
    );
  }

  const {
    kind,
    namespace,
    region,
    name,
    status,
    children,
    links,
    userStatus,
    warnings,
    hasChildren,
  } = resource;

  const expanded = touchedByUser ? isOpened : shouldBeOpened();
  const expandedClass = expanded && hasChildren ? 'expanded' : '';
  const hasChildrenClass = hasChildren ? 'has-children' : '';

  return (
    <ul
      className={`deployment-status-resource ${getFinalStatus(
        status,
        stepStatus,
      )} ${expandedClass}`}
      data-testid="resource"
    >
      <li>
        <div
          className={`item ${hasChildrenClass}`}
          role="presentation"
          onClick={() => hasChildren && toggle()}
        >
          <span
            className={`status-icon ${getFinalStatus(status, stepStatus)}`}
            title={userStatus}
          >
            {getStatusIcon(status)}
          </span>

          <div className="content">
            {kind} {Boolean(region) && `${region}/`}
            {Boolean(namespace) && `${namespace}/`}
            <strong>{name}</strong>
            {renderLinks(links)}
            {Boolean(userStatus) && (
              <div className="user-status">{userStatus}</div>
            )}
            {warnings.map(warning => (
              <div className="user-status">
                <span
                  className={`status-icon ${DEPLOYMENT_RESOURCE_STATUS.warning}`}
                >
                  <WarningIcon />
                </span>

                <span className="content">
                  {`Warning: ${warning.text} `}

                  {renderLinks([{ title: 'docs', url: warning.link }])}
                </span>
              </div>
            ))}
          </div>

          {hasChildren && (
            <span className="collapse-icon">
              {expanded ? (
                <RemoveCircleOutlineIcon />
              ) : (
                <AddCircleOutlineIcon />
              )}
            </span>
          )}
        </div>
      </li>

      {expanded &&
        hasChildren &&
        children.map(child => (
          <li key={`li-${child.kind}-${child.name}`}>
            <DeploymentStatusResource
              key={`${child.kind}-${child.name}`}
              resource={child}
              stepStatus={stepStatus}
            />
          </li>
        ))}
    </ul>
  );
}
