import React from 'react';
import { TableColumn } from '@backstage/core-components';
import { getFormattedDateTime } from '../../../utils/time';

export const getModelListColumns = (): TableColumn[] => {
  return [
    {
      title: 'Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Created at',
      field: 'created_at',
      filtering: false,
      render(row: any): React.ReactNode {
        return getFormattedDateTime(row.created_at);
      },
    },
  ];
};
