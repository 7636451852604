export function getRepoURL(url: string): string {
  const matches = url.match(/^(?:[^@]+@)?([^\/]+):([^\/]+\/[^\/]+)\.git$/);
  if (matches && matches.length === 3) {
    const host = matches[1];
    const repositoryPath = matches[2];
    return `https://${host}/${repositoryPath}`;
  }
  return '';
}

export function getRegistryName(url: string): string {
  switch (url) {
    case 'container-registry.zalando.net':
    case 'container-registry-test.zalando.net':
    case 'eu-west-1.container-registry.zalando.net':
    case 'eu-west-1.container-registry-test.zalando.net':
      return 'ZCR';
    case 'pierone.stups.zalan.do':
      return 'pierone';
    case 'registry.opensource.zalan.do':
      return 'os-registry';
    default:
      return 'unknown';
  }
}
