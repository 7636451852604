import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import HelpIcon from '@material-ui/icons/Help';
import { ProgressSpinIcon } from '../../../common';

import {
  STEP_STATUS,
  DEPLOYMENT_RESOURCE_STATUS as STATUS,
  STEP_STOPPED_STATUSES,
} from '../../../../constants';
import {
  DeploymentStatusModel,
  DeploymentResourceModel,
} from '../../../../models';
import { IDeploymentResource } from '../../../../api/types/payload';

export const getFinalStatus = (status: string, stepStatus: string) => {
  if (STEP_STOPPED_STATUSES.includes(stepStatus) && status === STATUS.pending) {
    return stepStatus.toLowerCase();
  }

  return status;
};

export const getMessage = (status: string): string => {
  switch (status) {
    case STEP_STATUS.PENDING_APPROVAL:
      return 'Waiting for manual approval…';
    case STEP_STATUS.ABORTED:
      return 'Deployment was aborted.';
    case STEP_STATUS.FAILED:
      return 'Deployment has failed.';
    case STEP_STATUS.ERROR:
      return 'Deployment has failed.';
    case STEP_STATUS.SKIPPED:
      return 'Deployment step was skipped.';
    case STEP_STATUS.NOT_STARTED:
      return 'Deployment step was not reached yet.';
    case STEP_STATUS.SUCCEEDED:
      return 'Deployment succeeded.';
    default:
      return 'Please wait, deployment is starting…';
  }
};

export function getStatusIcon(status: string) {
  switch (status) {
    case STATUS.pending:
      return <ProgressSpinIcon />;
    case STATUS.healthy:
      return <CheckCircleIcon />;
    case STATUS.fatal:
      return <CancelIcon />;
    case STATUS.failing:
      return <ErrorIcon />;
    case STATUS.warning:
      return <WarningIcon />;
    default:
      return <HelpIcon />;
  }
}

export function allHealthy(resource: DeploymentResourceModel): boolean {
  if (resource.status !== STATUS.healthy) {
    return false;
  }

  if (resource && resource.children) {
    return resource.children.every(child => allHealthy(child));
  }

  return true;
}

export function allPending(resource: DeploymentResourceModel): boolean {
  if (resource.status !== STATUS.pending) {
    return false;
  }

  if (resource && resource.children) {
    return resource.children.every(child => allPending(child));
  }

  return true;
}

export function stepNotRunning(status: string) {
  return STEP_STOPPED_STATUSES.includes(status);
}

export const getJsonStringFromStep = (activities: DeploymentStatusModel[]) => {
  let jsonString: null | string = null;

  activities.forEach(a => {
    a.pods.forEach(p => {
      p.containers.forEach(c => {
        if (Boolean(c.json_output.length)) {
          if (!jsonString || !jsonString.length) {
            jsonString = c.json_output;
          }
        }
      });
    });
  });

  return jsonString;
};

export const getResources = (
  activities: DeploymentStatusModel[],
): DeploymentResourceModel[] => {
  const jsonString = getJsonStringFromStep(activities) || '{}';

  try {
    const data = jsonString.length ? JSON.parse(jsonString) : null;

    return ((data?.resources as IDeploymentResource[]) ?? []).map(
      resource => new DeploymentResourceModel(resource),
    );
  } catch (e) {
    console.info(e); // eslint-disable-line no-console
    console.info(`Failed to parse JSON string >> ${jsonString} <<`); // eslint-disable-line no-console

    return [];
  }
};
