import { AWS_CONSOLE_URLS } from '../constants/common';

export class CDPUtils {
  public metadata: any;
  public resourceId: string | undefined;
  public resourceType: string | undefined;

  constructor(
    metadata: object | null,
    resourceId: string,
    resourceType: string,
  ) {
    this.metadata = metadata;
    this.resourceId = resourceId;
    this.resourceType = resourceType;
  }

  public zflowVersion(): string {
    return this.metadata?.ZFLOW_VERSION;
  }

  public zflowProjectID(): string {
    return this.metadata?.ZFLOW_PROJECT_ID;
  }

  public accountableUser(): string {
    return this.metadata?.ZFLOW_CDP_ACCOUNTABLE_USER;
  }

  public buildVersion(): string {
    return this.metadata?.ZFLOW_CDP_BUILD_VERSION;
  }

  public cdpConfigurationSource() {
    return this.metadata?.ZFLOW_CDP_CONFIGURATION_SOURCE;
  }

  public pipelineId() {
    return this.metadata ? this.metadata['pipeline-id'] : '';
  }

  public repositoryLink() {
    return (
      this.cdpConfigurationSource() &&
      `https://${this.cdpConfigurationSource().split(':')[0]}`
    );
  }

  public convertCDPConfigurationSourceToCommitLink() {
    return (
      this.cdpConfigurationSource() &&
      this.cdpConfigurationSource().split(':')[1]
    );
  }

  public getCDPRepoLink(cdpConfigurationSource: string | undefined) {
    return (
      cdpConfigurationSource &&
      `/cdp/ghe/${cdpConfigurationSource.split('/')[1]}/${
        cdpConfigurationSource.split('/')[2]
      }`
    );
  }

  public extractAWSAccountId = (arn: string | undefined) =>
    arn && arn.startsWith('arn:aws:states:eu-central-1:')
      ? arn.split(':')[4]
      : null;

  private isPR() {
    return (
      this.buildVersion() && this.buildVersion().toLowerCase().startsWith('pr')
    );
  }

  public pullRequestLink() {
    return (
      this.isPR() &&
      this.buildVersion() &&
      this.repositoryLink() &&
      `${this.repositoryLink()}/pull/${this.buildVersion().split('-')[1]}`
    );
  }

  public commitToMasterLink() {
    return (
      !this.isPR() &&
      this.buildVersion() &&
      this.cdpConfigurationSource() &&
      `${this.repositoryLink()}/commit/${this.convertCDPConfigurationSourceToCommitLink()}`
    );
  }

  public linkToCDPPipeline() {
    return (
      this.pipelineId() &&
      this.cdpConfigurationSource() &&
      `${this.getCDPRepoLink(
        this.cdpConfigurationSource(),
      )}/${this.pipelineId()}`
    );
  }

  public linkToAWSConsole() {
    const base_url =
      this.resourceType === 'run'
        ? AWS_CONSOLE_URLS.STEP_FUNCTIONS_EXECUTION_DETAILS
        : AWS_CONSOLE_URLS.PIPELINE_VIEW_DETAILS;
    return (
      this.resourceId &&
      this.resourceId.startsWith('arn:') &&
      `${base_url}/${this.resourceId}`
    );
  }

  public linkToK8SCluster() {
    const awsAccountId = this.extractAWSAccountId(this.resourceId);
    return (
      awsAccountId &&
      `https://kube-resource-report.stups.zalan.do/cluster-aws:${awsAccountId}:eu-central-1:kube-1.html`
    );
  }
}
