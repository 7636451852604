import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export const applicationReviewApiRef = createApiRef<ReviewApi>({
  id: 'plugin.application-review',
});

function isParsable(res: Response): boolean {
  return !!res.headers.get('Content-Type')?.includes('json');
}

async function parse<T>(res: Response): Promise<any> {
  if (res.ok) {
    return isParsable(res) ? ((await res.json()) as Promise<T>) : undefined;
  }
  let message = `${res.statusText} (${res.status})`;
  const body = await res.text();
  message += body && body !== res.statusText ? `: ${body}` : '';
  throw new Error(message);
}

export class ReviewApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;
  private backendReviewPluginUrl: string = '';

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi.getBaseUrl('review').then(url => {
      this.backendReviewPluginUrl = url;
    });
  }

  /** Retrieves information about all resources in Kubernetes */
  async getDeploymentStats(name: string) {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/deployments/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return await parse(res);
  }

  /** Retrieves information about running pods in AWS/STUPS. */
  async getStupsStatus(name: string) {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/stups/applications/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return await parse(res);
  }

  async getRestApiStatus(name: string): Promise<string[]> {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(`${this.backendReviewPluginUrl}/rest-api/${name}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return (await parse(res)) || [];
  }

  async getGrafanaDashboards(
    name: string,
  ): Promise<IAppReview.GrafanaDashboard[]> {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/grafana/dashboards/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return (await parse(res)) || [];
  }

  async getPlaybooks(name: string): Promise<IAppReview.Playbook[]> {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/playbooks/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return (await parse(res)) || [];
  }

  async getApplicationInfo(name: string): Promise<IAppReview.ApplicationInfo> {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/application-info/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return await parse(res);
  }

  async getReviews(name: string, limit?: number) {
    const token = await this.oauth2Api.getAccessToken();
    let requestUrl = `${this.backendReviewPluginUrl}/reviews/${name}`;
    if (limit) {
      requestUrl += `?limit=${limit}`;
    }
    const res = await fetch(requestUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await parse(res);
  }

  async getIssues(name: string): Promise<IAppReview.Issue[]> {
    const token = await this.oauth2Api.getAccessToken();
    const requestUrl = `${this.backendReviewPluginUrl}/issues/${name}`;
    const res = await fetch(requestUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await parse(res);
  }

  async getAlerts(name: string): Promise<IAppReview.ZmonAlert[]> {
    const token = await this.oauth2Api.getAccessToken();
    const requestUrl = `${this.backendReviewPluginUrl}/alerts/${name}`;
    const res = await fetch(requestUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await parse(res);
  }

  async getLastLoadTest(name: string) {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/last-load-test/${name}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    return await parse(res);
  }

  async submitNewReview(name: string, review: IAppReview.Review) {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(`${this.backendReviewPluginUrl}/${name}`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(review),
    });

    const parsedResponse: IAppReview.IssuesResponse | Error = await parse(res);

    if (parsedResponse instanceof Error) {
      return parsedResponse;
    }

    return parsedResponse?.issues || [];
  }

  async patchExistingReview(
    name: string,
    reviewId: string,
    partialReview: Partial<IAppReview.Review>,
  ) {
    const token = await this.oauth2Api.getAccessToken();
    const res = await fetch(
      `${this.backendReviewPluginUrl}/${name}/${reviewId}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(partialReview),
      },
    );

    const parsedResponse: IAppReview.IssuesResponse | Error = await parse(res);

    if (parsedResponse instanceof Error) {
      return parsedResponse;
    }

    return parsedResponse?.issues || [];
  }
}
