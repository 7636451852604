import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { getFormattedDateTime } from 'plugin-ui-components';
import { TableColumn } from '@backstage/core-components';
import { ResourceTable } from '../tables/ResourceTable';
import { TextTag } from '../tables/columns/Tags';
import * as S from '../../styles/table';
import { MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE } from '../../constants/paging';
import { ModelVersionDeployment } from '../../api/interfaces/model_version';
import Grid from '@mui/material/Grid';

export const Deployments = ({
  deployments,
}: {
  deployments: ModelVersionDeployment[];
}) => {
  const columns: TableColumn<ModelVersionDeployment>[] = [
    {
      field: 'name',
      title: 'Name',
      render: ({ name }) => {
        return (
          <Tooltip title="Dataset name">
            <S.Title to="#" target="_blank">
              {name}
            </S.Title>
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      title: 'Type',
      render: ({ type }) => {
        return <TextTag text={type as string} title="Deployment Type" />;
      },
    },
    {
      field: 'created_at',
      title: 'Added On',
      render: ({ created_at }) => {
        return getFormattedDateTime(created_at);
      },
    },
  ];

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ResourceTable
          loading={false}
          columns={columns}
          entity="deployment"
          data={deployments as any}
          paginationConfig={{
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setRowsPerPage: setRowsPerPage,
            page: page || 0,
            total: deployments.length,
          }}
          displayOptions={{
            showTitle: true,
            search: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
