import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { MLPlatformHomePage } from './pages/MLPlatformHomePage';
import { MLProjectPage } from './pages/MLProjectPage';
import { MLModelGroupPage } from './pages/MLModelGroupPage';
import { MLModelPage } from './pages/MLModelPage';
import { MLPipelinePage } from './pages/MLPipelinePage';
import { MLRunPage } from './pages/MLRunPage';
import { ml_experimentation_routes } from 'plugin-ml-experimentation';
import { DEFAULT_PAGE_TITLE } from './constants/PageTitles';
import { RegisterMLProjectPage } from './pages/RegisterMLProjectPage';

export const MLPlatformRouter = () => {
  React.useEffect(() => {
    const documentTitle = document.querySelector('title');
    if (documentTitle) {
      new MutationObserver(() => {
        if (document.title.includes('| Sunrise')) {
          document.title = document.title.replace(
            '| Sunrise',
            `| ${DEFAULT_PAGE_TITLE}`,
          );
        }
        if (document.title.includes(`${DEFAULT_PAGE_TITLE} | `)) {
          document.title = document.title.replace(
            `${DEFAULT_PAGE_TITLE} | `,
            '',
          );
        }
      }).observe(documentTitle, {
        subtree: true,
        characterData: true,
        childList: true,
      });
    }
  }, []);

  return (
    <Routes>
      {ml_experimentation_routes.map(props => (
        <Route {...props} />
      ))}
      <Route path="/*" element={<MLPlatformHomePage />} />
      <Route path="/register" element={<RegisterMLProjectPage />} />
      <Route path="/projects/:projectId/*" element={<MLProjectPage />} />
      <Route
        path="/projects/:project_id/model-groups/:model_group_id/*"
        element={<MLModelGroupPage />}
      />
      <Route
        path="/projects/:project_id/model-groups/:model_group_id/models/:model_id/*"
        element={<MLModelPage />}
      />
      <Route
        path="/projects/:project_id/pipelines/:pipeline_id/*"
        element={<MLPipelinePage />}
      />
      <Route
        path="/projects/:project_id/pipelines/:pipeline_id/runs/:run_id/*"
        element={<MLRunPage />}
      />
      <Route path="*" element={<Navigate to="/ml/projects" />} />
    </Routes>
  );
};
