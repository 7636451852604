import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const autoHideDuration = 5000;

export function success(message: string) {
  return (
    <Snackbar open autoHideDuration={autoHideDuration}>
      <Alert severity="success">{message}</Alert>
    </Snackbar>
  );
}
