export const stepsValues = {
  Development: [
    '01-Discover',
    '02-Plan',
    '03-Setup',
    '04-Design',
    '05-Code',
    '06-Build',
    '07-Test',
    '08-Deploy',
    '09-Monitor',
    '10-Operate',
  ],
  Data: [
    '01-Discover',
    '02-Access',
    '03-Transform',
    '04-Analyze/Explore',
    '05-ML-Model',
    '06-ML-Predict',
    '07-Share/Publish',
    '08-Orchestrate',
    '09-Track',
    '10-Manage Data Lifecycle',
  ],
};
