import { createApiRef } from '@backstage/core-plugin-api';
import { NotebookContentResponse } from './types/responses/notebookContent';

export interface NotebookRendererApi {
  getNotebookContent(
    s3Path: string,
    shouldHideCode: boolean,
    notebookFormat?: string,
  ): Promise<NotebookContentResponse>;
}

export const notebookRendererApiRef = createApiRef<NotebookRendererApi>({
  id: 'notebook-renderer-api',
});
