import {
  Button,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import AddCircle from '@material-ui/icons/AddCircle';
import React from 'react';

export const TableButtons = React.memo(
  ({ showRegister }: { showRegister: boolean }) => (
    // NOTE: The title is empty because providing it will have redundant titles in the page while not providing it will display `Unknown page` 🤷🏻‍
    <ContentHeader title="">
      {showRegister && (
        <Button color="primary" to="/create/application">
          <AddCircle style={{ marginRight: 8 }} />
          Register
        </Button>
      )}
      <SupportButton>All your software catalog entities</SupportButton>
    </ContentHeader>
  ),
);
