import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { AnalysisChip } from './AnalysisChip';
import { AboutField } from 'plugin-ui-components';
import { Analysis } from '../../../types';

export const AnalysisGrade = ({
  report,
  row = false,
}: {
  report?: Analysis;
  row?: boolean;
}) => {
  return (
    <Tooltip
      style={{ cursor: 'pointer' }}
      title={`Represents a weighted average of the available code
       quality metrics (issues, complexity, duplication, and coverage), and range from A to F.`}
    >
      <Grid item>
        <AboutField label={row ? '' : 'grade'}>
          {report && <AnalysisChip grade={report!.gradeLetter} />}
        </AboutField>
      </Grid>
    </Tooltip>
  );
};
