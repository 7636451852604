import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AsyncDataTable, ContentWrapper } from 'plugin-ui-components';
import { useFilters, useUserLibs, NAME, VERSION } from '../../hooks';
import { Filters } from './Filters';
import { Library } from '../../types';
import { columnsMyLibs } from './columns';

export const MyLibraries = () => {
  const pageSize = 10;
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );
  const [libraryOptions, setLibraryOptions] = useState({
    limit: pageSize,
    name: queryParams.getAll(NAME),
    version: queryParams.getAll(VERSION),
  });
  const [currentPage, setCurrentPage] = useState(0);
  const { userLibs, next, prev } = useUserLibs(libraryOptions, currentPage);

  const handlePageChange = (newPage: number) => {
    if (newPage === 0) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: undefined,
      }));
    } else if (currentPage < newPage && next) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: next,
      }));
    } else if (currentPage > newPage && prev) {
      setLibraryOptions(prevOptions => ({
        ...prevOptions,
        cursor: prev,
      }));
    }
    setCurrentPage(newPage);
  };

  const {
    filteredLibs,
    filteredVersions,
    onClearFilters,
    handleLibsFilter,
    handleVersionFilter,
  } = useFilters(setLibraryOptions);

  if (!userLibs?.length) {
    return (
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: 100 }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <ContentWrapper>
      <Filters
        handleVersionFilter={handleVersionFilter}
        onClearFilters={onClearFilters}
        filteredLibs={filteredLibs}
        filteredVersions={filteredVersions}
        handleLibsFilter={handleLibsFilter}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AsyncDataTable<Library>
            title="My Libraries"
            options={{
              pageSize: pageSize,
              search: false,
              sorting: false,
              padding: 'dense',
            }}
            data={userLibs
              .sort((a, b) => b.applications.length - a.applications.length)
              .map((lib, index) => ({ ...lib, id: index }))}
            columns={columnsMyLibs}
            asyncPaginationProps={{
              currentPage,
              hasNextPage: !!next,
              hasPreviousPage: !!prev,
              onPageChange: handlePageChange,
            }}
          />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
