import React from 'react';
import { Typography } from '@material-ui/core';
import { Link, Header as BSHeader } from '@backstage/core-components';

export const Header = () => {
  return (
    <BSHeader
      title="Zalando SBOM Libraries"
      subtitle={
        <Typography style={{ color: 'whitesmoke' }}>
          Discover Zalando's software bill of materials. This page brings data
          resulted on the{' '}
          <Link
            style={{ textDecoration: 'underline', color: 'white' }}
            to="https://github.bus.zalan.do/builder-knowledge/sbom-scanner"
          >
            SBOM Scanner
          </Link>{' '}
          of Zalando's container images found active in production.
        </Typography>
      }
    />
  );
};
