import React from 'react';
import { Tooltip, styled, Theme } from '@material-ui/core';
import { colors, UserReference } from 'plugin-ui-components';
import * as S from '../../../styles/common';
import { Link } from '@backstage/core-components';
import { STATUS_COLORS } from '../../../constants/colors';

type StyledProps = {
  theme: Theme;
  color?: string;
  variant?: 'contained' | 'outlined';
  textTransform?: 'upper' | 'title' | 'lower';
};

export const Tag = styled('div')(
  ({
    theme,
    color = STATUS_COLORS.progress,
    variant = 'contained',
    textTransform = 'lower',
  }: StyledProps) => {
    const getTextTransform = (transform: string) => {
      switch (transform) {
        case 'upper':
          return 'uppercase';
        case 'title':
          return 'capitalize';
        case 'lower':
        default:
          return 'lowercase';
      }
    };

    return {
      position: 'relative',
      display: 'inline-block',
      letterSpacing: '0.6px',
      borderRadius: '3px',
      outline: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      transition: 'color 0.3s ease-in-out',
      background: 'transparent',
      fontWeight: 600,
      lineHeight: '35px',
      fontSize: '15px',
      padding: '0 14px',
      textTransform: getTextTransform(textTransform), // Applying the text transform
      border: '1px solid transparent',
      flexGrow: 0,
      flexShrink: 0,

      ...(variant === 'contained' && {
        color: theme.palette.background.paper,
        backgroundColor: color,
      }),

      ...(variant === 'outlined' && {
        color: color,
        borderColor: color,
      }),
    };
  },
);

export const TagSmall = styled(Tag)({
  lineHeight: '17px',
  fontSize: '11px',
  padding: '0 5px',
});

export function ApplicationTag({ name }: { name: string }) {
  return (
    <TextWithLinkTag
      text={name}
      link={`/catalog/default/Component/${name}`}
      title="KIO Application"
    />
  );
}

export function UserTag({ user }: { user: string }) {
  return (
    <S.Text>
      <UserReference
        user={user}
        displayType="login"
        header="Accountable user:"
      />
    </S.Text>
  );
}

export function TextWithLinkTag({
  text,
  title,
  link,
}: {
  text: string;
  link: string;
  title: string;
}) {
  return (
    <S.TextLabeled>
      <Tooltip title={title} placement="bottom-start">
        <Link to={link} target="_blank">
          {text}
        </Link>
      </Tooltip>
    </S.TextLabeled>
  );
}

export function TextTag({
  text,
  title,
  color,
  textTransform = 'lower',
}: {
  text: string;
  title: string;
  color?: string;
  textTransform?: 'upper' | 'title' | 'lower';
}) {
  return (
    <Tooltip title={title}>
      <TagSmall
        variant="outlined"
        color={color || colors.semantic.primaryDark}
        textTransform={textTransform}
      >
        {text}
      </TagSmall>
    </Tooltip>
  );
}
