import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';

import { Breadcrumbs, Layout, Tab, Tabs } from '../../components';
import { ImagOverview } from './Overview';
import { ImageTags } from './Tags';
import { IMAGE_VIEW_TABS } from '../../constants';

export function ImagePage() {
  const { name = '', name2 } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<string>(IMAGE_VIEW_TABS.overview);

  function handleTagClick(tag: string) {
    setSearchParams({ tag: tag });
    setTab(IMAGE_VIEW_TABS.overview);
  }

  function selectOverview() {
    setTab(IMAGE_VIEW_TABS.overview);
  }

  function selectTags() {
    setTab(IMAGE_VIEW_TABS.tags);
  }

  const title = `${name2 || name}:${searchParams.get('tag') || 'latest'}`;

  return (
    <Layout
      title={title}
      subtitle="RESOURCE • CONTAINER"
      breadcrumbs={
        <Breadcrumbs
          list={[
            {
              name: <HomeLink />,
              to: '/container-images',
            },
            { name: `${name2 || name}`, to: '' },
          ]}
        />
      }
      render={
        <>
          <Tabs>
            <Tab
              data-active={tab === IMAGE_VIEW_TABS.overview}
              onClick={selectOverview}
            >
              Overview
            </Tab>
            <Tab
              data-active={tab === IMAGE_VIEW_TABS.tags}
              onClick={selectTags}
            >
              Tags
            </Tab>
          </Tabs>

          {tab === IMAGE_VIEW_TABS.overview && <ImagOverview />}
          {tab === IMAGE_VIEW_TABS.tags && (
            <ImageTags tagAction={handleTagClick} />
          )}
        </>
      }
    />
  );
}

function HomeLink() {
  return (
    <>
      <HomeIcon
        fontSize="inherit"
        style={{ verticalAlign: 'middle', marginBottom: '2px' }}
      />
      &nbsp;home
    </>
  );
}
