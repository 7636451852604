import React, { useEffect, useRef, useState } from 'react';
import { Layout, PlotData } from 'plotly.js';
import Plot from 'react-plotly.js';
import {
  sortData,
  GRAPH_TYPE,
  SORT_ORDER,
  getDistinct,
  extractKeys,
  getDefault,
} from './helpers';
import { PlotSettings } from './PlotSettings';
import { Box, Grid } from '@material-ui/core';
import {
  MLPipelineRun,
  MLPipelineRunMetricsParams,
} from '../../../api/definitions';
import { usePluginTheme } from '../../../hooks/usePluginTheme';

const getMetricsAndParams = (runs: Array<MLPipelineRun>) =>
  runs.map((run: MLPipelineRun) => {
    const metricsAndParams: MLPipelineRunMetricsParams[] = run.metrics.concat(
      run.params,
    );
    return metricsAndParams.reduce(
      (acc, current) => {
        return {
          ...acc,
          [current.key]: current.value,
        };
      },
      { run_id: run.name },
    );
  });

export const getHoverTemplate = (metric: any, xAxisKey: string) => {
  const xAxisTemplate = xAxisKey === 'run_id' ? '' : `<b>${xAxisKey} %{x}</b>`;
  return getDistinct([
    xAxisTemplate,
    `<b>${metric}:</b> %{y}`,
    '<b>run_id:</b> %{text}',
    '<extra></extra>',
  ])
    .filter(x => x)
    .join('<br>');
};

export const getMetricGraphConfig = (
  metric: any,
  xAxisKey: string,
  graphType: string,
  metricsAndParamsData: Array<any>,
): Partial<PlotData> => ({
  type: graphType === GRAPH_TYPE.BAR ? 'bar' : 'scatter',
  mode: graphType === GRAPH_TYPE.LINE ? 'lines+markers' : 'markers',
  name: metric,
  text: metricsAndParamsData.map(x => `${x.run_id}`),
  hovertemplate: getHoverTemplate(metric, xAxisKey),
  x: metricsAndParamsData.map(x =>
    typeof x[xAxisKey] === 'number' ? x[xAxisKey].toFixed(4) : x[xAxisKey],
  ),
  y: metricsAndParamsData.map(x => x[metric]),
});

const getLayout = (graphType: string, pluginTheme: string): Partial<Layout> => {
  const isLight = pluginTheme === 'light';
  const colors = {
    text: '#aaa',
    line: isLight ? '#eee' : '#444',
    strongLine: '#aaa',
    bg: isLight ? '#fff' : '#272b30',
  };
  return {
    margin: {
      l: 40,
      r: 20,
      t: 20,
      b: 40,
      pad: 0,
    },
    font: { size: 11, color: colors.text },
    hovermode: 'x unified',
    paper_bgcolor: colors.bg,
    plot_bgcolor: colors.bg,
    xaxis: {
      type: graphType === GRAPH_TYPE.DOT ? '-' : 'category',
      tickformat: '.4f',
      automargin: true,
      gridcolor: colors.line,
    },
    yaxis: {
      automargin: true,
      gridcolor: colors.line,
      zerolinecolor: colors.strongLine,
    },
  };
};

const RunMetricsPlot = ({ runs }: { runs: Array<MLPipelineRun> }) => {
  const [allXAxisOptions, setAllXAxisOptions] = useState<Array<any>>([]);
  const [xAxisOption, setxAxisOption] = useState<any>({});
  const [allMetricKeys, setAllMetricKeys] = useState<Array<any>>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<Array<any>>([]);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.TIME);
  const [graphType, setGraphType] = useState(GRAPH_TYPE.LINE);
  const [plotData, setPlotData] = useState<any>([]);
  const metricsAndParams = useRef<Array<any>>([]);

  const pluginTheme = usePluginTheme();

  useEffect(() => {
    const defaultXAxisOption = { label: 'run_id', group: 'default' };
    const metricKeys = extractKeys(runs, 'metrics');
    setAllMetricKeys(metricKeys);
    const paramKeys = extractKeys(runs, 'params');

    const defaultMetric = getDefault(metricKeys);
    metricsAndParams.current = getMetricsAndParams(runs);
    const sortedData = sortData(
      defaultXAxisOption.label,
      SORT_ORDER.TIME,
      metricsAndParams.current,
    );
    const defaultPlotData = [defaultMetric].map(metric =>
      getMetricGraphConfig(
        metric,
        defaultXAxisOption.label,
        GRAPH_TYPE.LINE,
        sortedData,
      ),
    );

    setAllXAxisOptions([
      defaultXAxisOption,
      ...metricKeys.map(key => ({ label: key, group: 'metrics' })),
      ...paramKeys.map(key => ({ label: key, group: 'params' })),
    ]);
    setxAxisOption(defaultXAxisOption);
    setSelectedMetrics(defaultMetric);
    setPlotData(defaultPlotData);
  }, [runs]);

  useEffect(() => {
    const sortedData = sortData(
      xAxisOption.label,
      sortOrder,
      metricsAndParams.current,
    );
    const selectedPlotData = selectedMetrics.map(metric =>
      getMetricGraphConfig(metric, xAxisOption.label, graphType, sortedData),
    );
    setPlotData(selectedPlotData);
  }, [metricsAndParams, selectedMetrics, xAxisOption, graphType, sortOrder]);

  return allMetricKeys.length ? (
    <Grid container data-testid="run-metrics-plot">
      <Grid item xs={3}>
        <PlotSettings
          allXAxisOptions={allXAxisOptions}
          selectedXAxisOption={xAxisOption}
          setSelectedXAxisOption={setxAxisOption}
          graphType={graphType}
          setGraphType={setGraphType}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          allMetricKeys={allMetricKeys}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
        />
      </Grid>
      <Grid item xs={9}>
        <Box mb={2}>
          <Plot
            data={plotData}
            layout={getLayout(graphType, pluginTheme)}
            useResizeHandler
            style={{ width: '100%', maxHeight: '300px' }}
          />
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

export default RunMetricsPlot;
