import { Link } from '@backstage/core-components';
import { FormHelperText, TextFieldProps } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { FormHelperList } from './styles';

const ExternalLink = () => (
  <>
    &nbsp;
    <OpenInNewIcon style={{ width: '0.5em', height: '0.5em' }} />
  </>
);

export type TextFieldPropsExtended = TextFieldProps & {
  patternMessage?: string;
};

export type FormFields = Exclude<
  keyof IEntitySpec,
  | 'required_approvers'
  | 'publicly_accessible'
  | 'specification_type'
  | 'active'
  | 'last_modified'
  | 'last_modified_by'
  | 'created'
  | 'created_by'
>;

const variant: TextFieldProps['variant'] = 'outlined';

type FormConfig = Record<FormFields, TextFieldPropsExtended>;

export const idTakenErrorMessage =
  'ID is already taken, please choose another.';

export const config: FormConfig = {
  id: {
    label: 'ID',
    helperText: 'The unique identifier of the application. Cannot be changed.',
    inputProps: { pattern: '^[a-z]*[a-z-0-9]*$' },
    patternMessage:
      'Starts with character, may end with digit, may have dashes in between.',
    placeholder: 'todo-list-manager',
    required: true,
    variant,
  },
  name: {
    label: 'Application Name',
    helperText: (
      <>
        The human-readable name of the application. Should conform to the&nbsp;
        <Link
          to="https://engineering.docs.zalando.net/guidelines/naming-applications/"
          title="Open in a new tab"
        >
          naming guidelines
          <ExternalLink />
        </Link>
        .
      </>
    ),
    placeholder: 'To-Do List Manager',
    required: true,
    variant,
  },
  subtitle: {
    label: 'Subtitle',
    helperText: (
      <>
        The human-readable subtitle of the application which helps stakeholders
        to understand its purpose which will always to be displayed with the
        application name. It should be a short tagline, e.g. "
        <em>Checks customer addresses</em>" for the application Address Service.
      </>
    ),
    placeholder: 'Checks foo bar items',
    variant,
  },
  description: {
    multiline: true,
    rows: 4,
    label: 'Description',
    helperText:
      'Describe the purpose of this application so that your stakeholders can find it.',
    placeholder: 'This application provides the user...',
    variant,
  },
  service_url: {
    type: 'url',
    label: 'Service URL',
    helperText:
      "Optional: This application's user-facing URL: e.g. the root URL of the primary API or UI. Please note: An application can have any number of services URLs not registered here, and additional applications should NOT be registered for the purpose of providing URLs for other environments.",
    placeholder: 'e.g. https://todo.zalando.net',
    variant,
  },
  scm_url: {
    type: 'url',
    label: 'Source Control Manager URL',
    helperText: (
      <>
        The URL to the main source code (git) repository of the application. The
        SCM URL must point to a Zalando hosted git platform and have the form
        &nbsp;<code>{'https://{git - host}/{org}/{repo}'}</code>.
      </>
    ),
    placeholder: 'https://github.bus.zalan.do/pitchfork/sunrise',
    variant,
  },
  support_url: {
    label: 'Support contact',
    helperText: 'Please provide a URL or Email for support.',
    variant,
  },
  documentation_url: {
    type: 'url',
    label: 'Documentation URL',
    helperText: 'URL to your documentation page.',
    placeholder: 'e.g. https://sunrise.docs.zalando.net/',
    variant,
  },
  specification_url: {
    type: 'url',
    label: 'Specification URL',
    helperText: 'Where you manage your tickets.',
    placeholder: 'e.g. https://github.bus.zalan.do/pitchfork/sunrise/issues',
    variant,
  },
  incident_contact: {
    label: '24x7 Incident Contact',
    helperText:
      'The incident contact should be the responsible 24/7 on-call (OpsGenie) team. Leave this field empty if the application does not need 24/7 on-call support.',
    placeholder: 'My On-Call Team',
    variant,
  },
  team_id: {
    label: 'Name of Owning Team',
    helperText: (
      <FormHelperText>
        Only teams with a tech "Team ID" (alias) may own an application. You
        might need to{' '}
        <Link
          to="https://team-change.docs.zalando.net/team_changes/team_change/"
          title="Open in a new tab"
        >
          request one
          <ExternalLink />
        </Link>
        . The owning team should be composed of the people directly responsible
        for operating and maintaining the application, its metadata, and its
        resources. Since you can't create applications for teams you are not
        part of, this list can be limited to the teams you are member of or are
        leading.
      </FormHelperText>
    ),
    required: true,
    variant,
  },
  criticality_level: {
    label: 'Criticality Level / Application Tier',
    helperText: (
      <>
        <FormHelperText>How critical the application is</FormHelperText>
        <FormHelperList>
          <li>Tier-1: highest criticality, immediate impact to the business</li>
          <li>Tier-2: imminent impact to the business</li>
          <li>Tier-3: long-term or low impact to the business</li>
          <li>
            Not Relevant: application is not in production or a proof of concept
            ). See the&nbsp;
            <Link
              to="https://docs.google.com/document/d/1m7FVdyuE9nohKx4Sorn_Ww9yc9ecCn70ZgVyHWRzkfw/edit"
              title="Open in a new tab"
            >
              Application Tiers narrative
              <ExternalLink />
            </Link>
            &nbsp; for more information.
          </li>
        </FormHelperList>
      </>
    ),
    placeholder: 'Not Specified',
    variant,
  },
};
