import React from 'react';
import { useLocation } from 'react-router';
import { Header, Page } from '@backstage/core-components';
import { Box } from '@material-ui/core';
import PetsIcon from '@material-ui/icons/Pets';
import DescriptionIcon from '@material-ui/icons/Description';
import { ExternalTrackedButton } from 'plugin-ui-components';
import { MLBreadcrumbs, PageEntities } from '../Breadcrumbs/MLBreadcrumbs';
import {
  DEFAULT_HEADER_TITLE,
  DEFAULT_PAGE_TITLE,
} from '../../constants/PageTitles';

export const Layout = ({
  children,
  pageEntities,
  headerTitle,
  pageTitle,
  subTitle,
}: {
  children: React.ReactNode;
  pageEntities?: PageEntities;
  headerTitle?: string;
  pageTitle?: string;
  subTitle?: string;
}) => {
  const location = useLocation();

  const SUPPORT_STYLE = { width: '15px', height: '15px' };

  const ML_PLATFORM_SUPPORT_URLS = [
    {
      name: 'Docs',
      link: 'https://ml-platform.docs.zalando.net/',
      icon: <DescriptionIcon style={SUPPORT_STYLE} />,
    },
    {
      name: 'Users Chat',
      link: 'https://chat.google.com/room/AAAAxcSWJRg',
      icon: <PetsIcon style={SUPPORT_STYLE} />,
    },
  ];

  return (
    <Page themeId="ml">
      <Header
        title={headerTitle ?? DEFAULT_HEADER_TITLE}
        pageTitleOverride={pageTitle ?? DEFAULT_PAGE_TITLE}
        subtitle={
          subTitle ?? (
            <MLBreadcrumbs
              pathname={location.pathname}
              pageEntities={pageEntities}
            />
          )
        }
      >
        <Box color="white" display="flex" flexDirection="column">
          {ML_PLATFORM_SUPPORT_URLS.map(supportUrl => {
            return (
              <Box
                display="grid"
                marginTop="8px"
                key={`${supportUrl.name}-support-url`}
              >
                <ExternalTrackedButton
                  plugin="zflow"
                  size="small"
                  target="_blank"
                  href={supportUrl.link}
                  variant="outlined"
                  color="inherit"
                  eventCategory={`Navigation to ${supportUrl.name}`}
                  eventAction={`URL: ${supportUrl.link}`}
                  eventLabel={`Location: ${supportUrl.name}`}
                >
                  <span>{supportUrl.name}&nbsp;</span>
                  {supportUrl.icon}
                </ExternalTrackedButton>
              </Box>
            );
          })}
        </Box>
      </Header>
      {children}
    </Page>
  );
};
