import { BaseClient } from './BaseClient';
import { NotebookContent } from './types/queryParams';
import { NotebookContentResponse } from './types/responses/notebookContent';
import { NotebookRendererApi } from './NotebookRendererApi';

export class NotebookRendererApiClient
  extends BaseClient
  implements NotebookRendererApi
{
  apiBaseUrl = '/notebook-renderer/api';

  async getNotebookContent(
    s3Path: string,
    shouldHideCode: boolean,
    notebookFormat: string = 'html',
  ): Promise<NotebookContentResponse> {
    const s3ObjectPath = s3Path
      ? s3Path
          .split('/')
          .filter((x, i) => x && i > 2)
          .join('/')
      : '';
    const params: NotebookContent = {
      s3_key: s3ObjectPath,
      should_hide_code: shouldHideCode,
      notebook_format: notebookFormat,
    };
    return await this.get(`${this.apiBaseUrl}/notebooks`, params);
  }
}
