import { action, makeObservable, observable } from 'mobx';
import { StacksetModel } from '../models';
import { StacksetResponse } from '../api/types/responses';

export interface ITrafficService {
  set(id: string, stackName: string, value: StacksetResponse): void;
  stackset: StacksetModel;
  currentPercentage: number;
  loaded: boolean;
  id: string | null;
  versionNotFound: boolean;
  getCurrentPercentage(id: string): number;
  getVersionNotFound(id: string): boolean;
  getLoaded(id: string): boolean;
}

export class TrafficService implements ITrafficService {
  stackset: StacksetModel = new StacksetModel({});
  currentPercentage: number = 0;
  loaded: boolean = false;
  id: string | null = null;
  versionNotFound: boolean = false;

  constructor() {
    makeObservable(this, {
      stackset: observable,
      currentPercentage: observable,
      id: observable,
      loaded: observable,
      versionNotFound: observable,
      set: action,
      getCurrentPercentage: action,
      getVersionNotFound: action,
      getLoaded: action,
    });
  }

  set = (id: string, stackName: string, value: StacksetResponse) => {
    this.stackset = new StacksetModel(value);
    this.loaded = true;
    this.id = id;
    this.currentPercentage = this.stackset.traffic[stackName]?.actual || 0;
    this.versionNotFound = !this.stackset.traffic[stackName];
  };

  getCurrentPercentage(id: string): number {
    if (id !== this.id) return 0;
    return this.currentPercentage;
  }

  getVersionNotFound(id: string): boolean {
    if (id !== this.id) return false;
    return this.versionNotFound;
  }

  getLoaded(id: string): boolean {
    if (id !== this.id) return false;
    return this.loaded;
  }
}
