import React, { useState } from 'react';
import { Card, CardContent, makeStyles } from '@material-ui/core';

import { CIInfo } from './CIInfo';
import { ImagePlatforms } from './Platforms';
import { BaseImageInfo } from './BaseImageInfo';
import { Summary } from './Summary';
import { ImageModel } from '../../models';

type Props = {
  image: ImageModel;
};

const useStyles = makeStyles(theme => ({
  gird: {
    display: 'grid',
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    gridTemplateColumns: '1fr',

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '2fr 1.3fr',
    },
  },
}));

export function ImageDetails({ image }: Props) {
  const [platformIdx, setPlatformIdx] = useState<number>(0);
  const classes = useStyles();

  return (
    <section className={classes.gird}>
      <Card>
        <CardContent>
          <Summary platform={image.platforms[platformIdx]} image={image} />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <ImagePlatforms
            handleSelection={setPlatformIdx}
            options={image.platforms.map(p => p.platformType)}
            selected={platformIdx}
            platform={image.platforms[platformIdx]}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <BaseImageInfo image={image.baseImage} />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <CIInfo ci={image.ci} />
        </CardContent>
      </Card>
    </section>
  );
}
