import { makeStyles, type Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  lowCompletion: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  mediumCompletion: {
    borderColor: theme.palette.warning.light,
    color: theme.palette.warning.light,
  },
  highCompletion: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  completionLayer: {
    color: 'rgba(211, 211, 211, 0.3)',
  },
  completionProgress: {
    position: 'absolute',
    left: 0,
    zIndex: 1,
  },
  stepLabel: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  stepIcon: {
    '& svg': {
      fontSize: '1.6rem',
    },
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '&.fail': {
      color: theme.palette.text.disabled,
    },
  },
  stepExpandButton: {
    fontSize: '1rem',
    padding: 0,
    marginLeft: '4px',
  },
  responseBox: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    '& pre': {
      height: 300,
      overflow: 'scroll',
      margin: 0,
    },
  },
}));

export const getLinearCompletionStyles = (
  theme: Theme,
  variant: 'success' | 'warning' | 'error',
) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: `${theme.palette[variant].main}49`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette[variant].main,
  },
});

export default useStyles;
