import React from 'react';
import {
  Grid,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from '../styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { prettifyTime } from '../../../utils/time';
import { Text } from '../../../common';

interface Props {
  data: ICDPMonitoring.PipelineStats | ICDPMonitoring.StepInfo | undefined;
  loading: boolean;
  title: string;
}

function Title({ text }: { text: string }) {
  return (
    <span>
      <Text paddingRight="5px">{text}</Text>
      <Tooltip title="Execution time is measured from pipeline start to pipeline end which means that the time between step retries or waiting for a step approval within pipeline is included.">
        <InfoOutlined
          style={{ marginBottom: '-4px' }}
          className="header-info"
        />
      </Tooltip>
    </span>
  );
}

export function PipelineExecutionTime({ data, loading, title }: Props) {
  const styles = useStyles();

  return (
    <Card elevation={3} className={styles.card}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <Box display="flex" alignItems="center">
        <CardHeader title={<Title text={title} />} />
      </Box>
      <CardContent>
        <Grid container>
          <Grid item xs={4} className={styles.executionTimeStat}>
            <Typography>
              {prettifyTime(data?.execution_time_seconds?.min)}
            </Typography>
            <Typography variant="h4">Minimum</Typography>
          </Grid>
          <Grid item xs={4} className={styles.executionTimeStat}>
            <Typography>
              <b>{prettifyTime(data?.execution_time_seconds?.avg)}</b>
            </Typography>
            <Typography variant="h4">
              <b>Average</b>
            </Typography>
          </Grid>
          <Grid item xs={4} className={styles.executionTimeStat}>
            <Typography>
              {prettifyTime(data?.execution_time_seconds?.max)}
            </Typography>
            <Typography variant="h4">Maximum</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
