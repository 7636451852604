import { ToolCNCFCategories } from './enums';

const CNCFGroups = [
  'Application Definition and Development',
  'Observability and Analysis',
  'Orchestration and Management',
  'Provisioning',
  'Runtime',
];
export const CNCFCategoryGroupings = new Map<ToolCNCFCategories, string>([
  // Application Definition and Development
  [ToolCNCFCategories.ApplicationDefinitionAndImageBuild, CNCFGroups[0]],
  [ToolCNCFCategories.ContinuousIntegrationAndDelivery, CNCFGroups[0]],
  [ToolCNCFCategories.Database, CNCFGroups[0]],
  [ToolCNCFCategories.StreamingAndMessaging, CNCFGroups[0]],
  // Observability and Analysis
  [ToolCNCFCategories.ChaosEngineering, CNCFGroups[1]],
  [ToolCNCFCategories.ContinuousOptimization, CNCFGroups[1]],
  [ToolCNCFCategories.FeatureFlagging, CNCFGroups[1]],
  [ToolCNCFCategories.Observability, CNCFGroups[1]],
  // Orchestration and Management
  [ToolCNCFCategories.APIGateway, CNCFGroups[2]],
  [ToolCNCFCategories.CoordinationAndServiceDiscovery, CNCFGroups[2]],
  [ToolCNCFCategories.RemoteProcedureCall, CNCFGroups[2]],
  [ToolCNCFCategories.SchedulingAndOrchestration, CNCFGroups[2]],
  [ToolCNCFCategories.ServiceMesh, CNCFGroups[2]],
  [ToolCNCFCategories.ServiceProxy, CNCFGroups[2]],
  // Provisioning
  [ToolCNCFCategories.AutomationAndConfiguration, CNCFGroups[3]],
  [ToolCNCFCategories.ContainerRegistry, CNCFGroups[3]],
  [ToolCNCFCategories.KeyManagement, CNCFGroups[3]],
  [ToolCNCFCategories.SecurityAndCompliance, CNCFGroups[3]],
  // Runtime
  [ToolCNCFCategories.CloudNativeNetwork, CNCFGroups[4]],
  [ToolCNCFCategories.CloudNativeStorage, CNCFGroups[4]],
  [ToolCNCFCategories.ContainerRuntime, CNCFGroups[4]],
]);
