import { getAliasFromDomain, getDomainFromAlias } from '../../utils/domains';

export class RepositoryModel {
  alias: string;
  fullPath: string;
  fullAliasPath: string;
  fullOrganizationPath: string;

  static fromFullPath(path: string) {
    const [domain, organization, name] = path.split(/\//);
    return new this(name ?? '', organization ?? '', domain ?? '');
  }

  static fromFullAliasPath(path: string) {
    const [alias, organization, name] = path.split(/\//);
    return new this(name ?? '', organization ?? '', getDomainFromAlias(alias));
  }

  static fromUrl(url: string) {
    const { hostname, pathname } = new URL(url);
    const [, organization, name] =
      pathname?.match(/([^/]+)\/([^./]+)(.git)?/) ?? [];

    return new this(name ?? '', organization ?? '', hostname ?? '');
  }

  constructor(
    public name: string,
    public organization: string,
    public domain: string,
  ) {
    this.alias = getAliasFromDomain(this.domain);
    this.fullPath = `${this.domain}/${this.organization}/${this.name}`;
    this.fullAliasPath = `${this.alias}/${this.organization}/${this.name}`;
    this.fullOrganizationPath = `${this.domain}/${this.organization}`;
  }
}
