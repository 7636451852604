import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { cdpApiRef } from '../../../../api';
import { NotFoundException } from '../../../../api/exceptions';
import { STEP_PRE_IN_PROGRESS_STATUSES } from '../../../../constants';

export function useArtifacts(
  runId: string,
  stepId: string,
  stepRunId: string,
  params: { stepId: string },
  status: string,
) {
  const api = useApi(cdpApiRef);

  const getArtifacts = useCallback(async () => {
    if (!stepRunId || STEP_PRE_IN_PROGRESS_STATUSES.includes(status)) {
      return Promise.resolve();
    }

    try {
      const response = await api.getArtifacts(runId, stepId, stepRunId, params);
      return Promise.resolve(response.artifacts);
    } catch (error) {
      const e = error as Error;
      if (!(e instanceof NotFoundException)) {
        return Promise.reject(e);
      }

      return Promise.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepRunId, status]);

  const { loading, error, retry, value } = useAsyncRetry(
    () => getArtifacts(),
    [getArtifacts, status],
  );

  return [
    { loading, error, value },
    { getArtifacts, retry },
  ] as const;
}
