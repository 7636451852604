export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
};

export const savingResourceType = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Creating new resource type in access conrol',
  eventAction: `New resource type is being saved`,
});

export const editingResourceType = (name: string): ITrackingEvent => ({
  ...common,
  eventLabel: 'Editing new resource type in access conrol',
  eventAction: `Resource Type '${name}' is being updated`,
});

export const cancelResourceType = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Cancel action on resource types',
  eventAction: `Cancel button for resource types (edit/save) clicked`,
});
