import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const infrastructurePlugin = createPlugin({
  id: 'infrastructure',
  routes: {
    root: rootRouteRef,
  },
});

export const InfrastructurePage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'InfrastructurePage',
    component: () =>
      import('./components/InfrastructureHomePage').then(
        m => m.InfrastructureHomePage,
      ),
    mountPoint: rootRouteRef,
  }),
);
