import { Badge } from '@material-ui/core';
import React from 'react';

import { Tab, Tooltip } from '../../../../common';
import { RunStepRunModel, StepTabsEnum } from '../../../../models';
import { stepRoutes } from '../../../../router';
import { VALIDATION_SEVERITY } from '../../../../constants';
import { ValidationProblemType } from '../../../../models/helpers';

export function getTabsComponents(
  tabs: Array<StepTabsEnum>,
  stepProblemsInfo?: { severity: ValidationProblemType; count: number },
): React.ReactNode[] {
  return tabs.map(tab => {
    switch (tab) {
      case StepTabsEnum.Details:
        return (
          <Tab key="details" to=".">
            {!!stepProblemsInfo?.count ? (
              <Tooltip title="Step problems found">
                <Badge
                  color={
                    stepProblemsInfo.severity === 'error' ? 'error' : 'primary'
                  }
                  badgeContent={stepProblemsInfo.count}
                >
                  Details &nbsp;&nbsp;&nbsp;
                </Badge>
              </Tooltip>
            ) : (
              <span>Details</span>
            )}
          </Tab>
        );
      case StepTabsEnum.Artifacts:
        return (
          <Tab key="artifacts" to={`./${stepRoutes.artifacts}`}>
            Artifacts
          </Tab>
        );
      case StepTabsEnum.Logs:
        return (
          <Tab key="logs" to={`./${stepRoutes.logs}`}>
            Logs
          </Tab>
        );
      case StepTabsEnum.TestResults:
        return (
          <Tab key="test_results" to={`./${stepRoutes.tests}`}>
            Test results
          </Tab>
        );
      case StepTabsEnum.DeploymentStatus:
        return (
          <Tab key="deployment_status" to={`./${stepRoutes.deployment}`}>
            Deployment status
          </Tab>
        );
      default:
        return <></>;
    }
  });
}

export function getStepProblemsInfo(stepRun: RunStepRunModel) {
  let problemsCount = 0;
  let severity = VALIDATION_SEVERITY.WARNING;

  if (stepRun.hasValidationErrors || stepRun.hasValidationwarnings) {
    problemsCount += 1;
  }

  if (stepRun.hasValidationErrors) {
    severity = VALIDATION_SEVERITY.ERROR;
  }

  return {
    count: problemsCount,
    severity,
  };
}
