import React from 'react';
import { SearchConfigForGCS } from '../models';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import AppsIcon from '@mui/icons-material/Apps';
import { getTeamNameFromFullName } from 'plugin-core';
import {
  APIsIcon,
  EventTracker,
  HStack,
  TeamReference,
} from 'plugin-ui-components';
import { Link, LinkButton } from '@backstage/core-components';
import { AccentColors } from '../constants/AccentColors';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { ResultDataList } from '../components/ResultDataList';
import * as Tracking from '../common/tracking';

interface TeamMetadata {
  id: string;
  alias: string;
  costCenter: string;
  parentTeam: string;
  name: string;
  email: string;
}

const label = 'Teams';
const id = formatIndexLabelToID(label);
export const teamsIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'sunrise',
  icon: PeopleIcon,
  accentColor: AccentColors.Team,
  objectTypes: ['teams'],
  getTitle: hit => {
    const { name } = extractGCSFields<TeamMetadata>(hit);
    if (!name) return '';
    return getTeamNameFromFullName(name);
  },
  getUrl: hit => {
    const { id: sapId } = extractGCSFields<TeamMetadata>(hit);
    if (!sapId) return undefined;
    return `/catalog/default/group/${sapId}`;
  },
  render: hit => {
    const {
      id: sapId,
      name,
      email,
      alias,
      parentTeam,
    } = extractGCSFields<TeamMetadata>(hit);
    return (
      <>
        <ResultDataList
          multiColumn
          list={[
            {
              label: 'Sap ID',
              value: sapId,
            },
            {
              label: 'E-mail',
              value: (
                <EventTracker {...Tracking.teamEmailClick()}>
                  <Link display="block" to={`mailto:${email}`} key={email}>
                    {email}
                  </Link>
                </EventTracker>
              ),
            },
            {
              label: 'Parent Team',
              value: <TeamReference team={parentTeam} />,
            },
            {
              label: 'Alias(es)',
              value: alias?.replaceAll(',', ', '),
            },
          ]}
        />
        <HStack
          flexWrap="wrap"
          alignItems="center"
          spacing="1rem"
          marginTop={2}
        >
          <LinkButton
            to={`/docs?group=all&teams=${name}`}
            size="small"
            variant="outlined"
            color="primary"
          >
            <DescriptionIcon />
            &nbsp; Documentation
          </LinkButton>
          <LinkButton
            to={`/applications?group=all&teams=${name}&status=yes`}
            size="small"
            variant="outlined"
            color="primary"
          >
            <AppsIcon />
            &nbsp; Applications
          </LinkButton>
          <LinkButton
            to={`/apis?group=all&teams=${name}`}
            size="small"
            variant="outlined"
            color="primary"
          >
            <APIsIcon />
            &nbsp; APIs
          </LinkButton>
        </HStack>
      </>
    );
  },
});
