import React, { useMemo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { PathMatch } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Stack from '@mui/material/Stack';

export interface NamedEntities {
  modelName?: string;
  modelVersionName?: string;
  pipelineName?: string;
  runName?: string;
}

const paths = [
  '/mlp/models/:modelId',
  '/mlp/models/:modelId/card',
  '/mlp/models/:modelId/edit',
  '/mlp/models/:modelId/versions',
  '/mlp/models/:modelId/versions/:modelVersionId',
  '/mlp/models/:modelId/versions/:modelVersionId/overview',
  '/mlp/models/:modelId/versions/:modelVersionId/datasets',
  '/mlp/models/:modelId/versions/:modelVersionId/deployments',
  '/mlp/models/:modelId/versions/:modelVersionId/metrics-params',
];

const createBreadcrumbItems = (
  pm: PathMatch,
  namedEntities?: NamedEntities,
) => {
  const { modelId, modelVersionId } = pm.params;

  const items: Array<React.ReactNode> = [];

  const addItem = (path: string, text: string, isLast: boolean) => {
    if (isLast) {
      items.push(
        <Typography key={path} color="inherit">
          {text}
        </Typography>,
      );
    } else {
      items.push(
        <Link underline="hover" key={path} color="inherit" href={path}>
          {text}
        </Link>,
      );
    }
  };

  if (pm.pathname.includes('/models')) {
    const isLast = pm.pathname.endsWith('/models');
    addItem('/mlp/models?group=mine', 'Models', isLast);
    const modelName =
      (namedEntities?.modelName as string) || (modelId as string);
    addItem(`/mlp/models/${modelId}`, modelName, isLast);

    if (pm.pathname.includes('card')) {
      addItem(`/mlp/models/${modelId}/card`, 'card', true);
    }

    if (pm.pathname.includes('/versions')) {
      addItem(`/mlp/models/${modelId}/versions`, 'Versions', false);
      const modelVersionName = namedEntities?.modelVersionName as string;
      addItem(
        `/mlp/models/${modelId}/versions/${modelVersionId}`,
        modelVersionName,
        true,
      );

      const modelVersionTabs = [
        'overview',
        'datasets',
        'deployments',
        'metrics-params',
      ];
      for (const modelVersionTab of modelVersionTabs) {
        if (pm.pathname.includes(`/${modelVersionTab}`)) {
          addItem(
            `/mlp/models/${modelId}/versions/${modelVersionId}/${modelVersionTab}`,
            modelVersionTab,
            true,
          );
        }
      }
    }

    if (pm.pathname.endsWith('/register')) {
      addItem('/mlp/models/register', 'Register', true);
    }
  }

  return items;
};

export const MLBreadcrumbs = ({
  pathname,
  namedEntities,
}: {
  pathname: string;
  namedEntities?: NamedEntities;
}) => {
  const pathMatch = useMemo(() => {
    return paths.reduce((pathObject: PathMatch | null, current: string) => {
      const match = matchPath(current, pathname);
      return match || pathObject;
    }, null);
  }, [pathname]);

  if (!pathMatch) {
    return (
      <Typography style={{ color: 'white' }}>
        Discover the latest ML pipelines, models, experiments and datasets
        across Zalando.
      </Typography>
    );
  }

  const breadcrumbs = createBreadcrumbItems(pathMatch, namedEntities);

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </>
  );
};
