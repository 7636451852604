import React, { PropsWithChildren } from 'react';
import { ReviewContext } from '../context';
import { Issue } from './Issue';

interface Props extends PropsWithChildren {
  field: IAppReview.IssueCheckField;
}

export function WithIssue({ children, field }: Props) {
  const { issues } = React.useContext(ReviewContext);

  const issue = issues.find(i => i.field === field);

  if (!issue) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <Issue {...issue} />
    </>
  );
}
