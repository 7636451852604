import React, { useContext } from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  isCommentsField,
  isConfirmationField,
  isURLField,
} from '../../utils/fields';
import { config } from '../config';
import { ReviewSummaryContext } from '../context';
import * as S from '../styles';
import { createGrafanaApplicationDashboardUrlWithParameters } from './utils';
import { GRAFANA_DASHBOARDS } from './Dashboards';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SummaryCard } from '../common';

export const DashboardsSummary = () => {
  const category = 'Dashboards';
  const { entity } = useEntity();
  const { review } = useContext(ReviewSummaryContext);
  const api = useApi(applicationReviewApiRef);

  const categoryItems = Object.keys(review).filter(
    item =>
      config[item]?.category === category &&
      !isURLField(item) &&
      !isConfirmationField(item) &&
      !isCommentsField(item),
  );

  const { value } = useAsync(
    () => api.getGrafanaDashboards(entity.metadata.name),
    [entity],
  );

  // Add "cluster=*" to the Grafana dashboards url if cluster info is missing,
  // otherwise Grafana will use a default cluster (checkout) so dashboard will not work as expected
  GRAFANA_DASHBOARDS.forEach(item => {
    if (categoryItems.includes(item)) {
      review[`${item}_url`] =
        createGrafanaApplicationDashboardUrlWithParameters(
          review[`${item}_url`] as string,
        );
    }
  });

  return (
    <SummaryCard category={category}>
      <CardContent>
        {!!categoryItems.length && (
          <>
            <Typography>
              <b>Default Dashboards</b>
            </Typography>
            {categoryItems.map(item => (
              <Grid container>
                <Grid item xs={10}>
                  <S.Label>{config[item]?.label}</S.Label>
                </Grid>
                <Grid item xs={2}>
                  <S.Label align="right" style={{ fontWeight: 'bold' }}>
                    {review[item] === 'no' ? (
                      'No'
                    ) : (
                      <Link to={review[`${item}_url`]}>
                        View <OpenInNewIcon />
                      </Link>
                    )}
                  </S.Label>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        {!!value?.length && (
          <>
            <br />
            <Typography>
              <b>Custom Dashboards</b>
            </Typography>
            {value?.map(item => (
              <Grid container>
                <Grid item xs={10}>
                  <S.Label>{item.title}</S.Label>
                </Grid>
                <Grid item xs={2}>
                  <S.Label align="right" style={{ fontWeight: 'bold' }}>
                    <Link to={`https://grafana.zalando.net${item.url}`}>
                      View <OpenInNewIcon />
                    </Link>
                  </S.Label>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </CardContent>
    </SummaryCard>
  );
};
