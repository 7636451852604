import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { plural } from '../../utils/language';
import { Issue } from '../common/Issue';
import { ReviewContext } from '../context';

interface Props {
  deployments?: number;
  loading?: boolean;
}

export function StupsDeployments({ deployments, loading }: Props) {
  const { issues } = React.useContext(ReviewContext);

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  const issue = issues.find(i => i.field === 'stups_info');

  if (!deployments) {
    return (
      <>
        <Typography>
          This application seems to have no deployments in AWS/STUPS.
        </Typography>
        <Issue {...issue} />
      </>
    );
  }

  return (
    <>
      Found <b>{deployments}</b> deployment
      {plural(deployments)} in AWS/STUPS
      <Issue {...issue} />
    </>
  );
}
