import React, { ChangeEvent, useState, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useSearchParams } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import {
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  Button,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TrackedInteraction } from 'plugin-ui-components';
import { essentialsApiRef } from '../../../../../api';
import { ResourceOwnerToolTip, ApplicationScopeToolTip } from './ToolTips';

import * as S from './styles';
import { cancelScope, editingScope, savingScope } from './tracking';
import { useNavigationBack } from '../../../useNavigationBack';

interface ResourceTypeFormProps {
  edit?: boolean;
}

export const ScopeForm = ({ edit = false }: ResourceTypeFormProps) => {
  const essentialApi = useApi(essentialsApiRef);
  const [searchParams, _] = useSearchParams();
  const resourceId = searchParams.get('resourceId') ?? '';
  const scopeId = searchParams.get('scopeId') ?? '';
  const saveTrackingProps = edit ? editingScope(scopeId) : savingScope();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [id, setId] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [userInformation, setUserInformation] = useState('');
  const [isResourceOwnerScope, setIsResourceOwnerScope] = useState(false);
  const [resourceOwners, setResourceOwners] = useState<Array<string>>([]);
  const [resourceName, setResourceName] = useState('');
  const handleBack = useNavigationBack({
    page: edit ? 'resourceTypeScopeEditPage' : 'resourceTypeScopeCreatePage',
    searchParamsKeys: ['resourceId'],
  });

  useEffect(() => {
    if (edit) {
      essentialApi.getScopeDetails(resourceId, scopeId).then(result => {
        setId(result.id ?? '');
        setSummary(result.summary ?? '');
        setDescription(result.description ?? '');
        setUserInformation(result.user_information ?? '');
        setIsResourceOwnerScope(result.is_resource_owner_scope ?? false);
      });
    }
  }, [edit, essentialApi, resourceId, scopeId]);

  useEffect(() => {
    essentialApi.getResourceTypeDetails(resourceId).then(result => {
      setResourceOwners(result.resource_owners ?? []);
      setResourceName(result.name ?? '');
    });
  }, [essentialApi, resourceId]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    essentialApi
      .saveScope(
        resourceId,
        id,
        summary,
        description,
        isResourceOwnerScope,
        userInformation,
      )
      .then(response => {
        setIsLoading(false);
        if (response.ok) {
          handleBack();
        } else {
          setErrorMessage(response.message);
          setHasError(true);
        }
      });
  }

  const handleScopeTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if ((event.target as HTMLInputElement).value === 'resource-owner') {
      setIsResourceOwnerScope(true);
    } else {
      setIsResourceOwnerScope(false);
    }
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <Box marginBottom={2}>
            <Button
              onClick={handleBack}
              startIcon={<ArrowBackIosIcon />}
              size="small"
            >
              Back
            </Button>
          </Box>

          <Box paddingBottom={2}>
            <Typography variant="h4">
              {edit ? 'Edit' : 'Create'} Scope
            </Typography>
          </Box>

          {hasError && (
            <Box paddingBottom={2}>
              <Alert severity="error" variant="outlined">
                An Error occurred while saving the form.
                <br />
                {errorMessage}
              </Alert>
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            <S.StyledFormControl required>
              <Typography>
                <strong>Scope Type</strong>
              </Typography>
              <FormHelperText>
                Which of these scope types applies?
              </FormHelperText>
              <RadioGroup
                value={
                  isResourceOwnerScope ? 'resource-owner' : 'application-scope'
                }
                name="scope-type"
                onChange={handleScopeTypeChange}
              >
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    value="resource-owner"
                    control={<Radio color="primary" />}
                    label="Resource Owner Scope"
                  />
                  <ResourceOwnerToolTip
                    scopeId={id}
                    resourceName={resourceName}
                    resourceOwners={resourceOwners}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    value="application-scope"
                    control={<Radio color="primary" />}
                    label="Application Scope"
                  />
                  <ApplicationScopeToolTip
                    scopeId={id}
                    resourceName={resourceName}
                  />
                </Box>
              </RadioGroup>
            </S.StyledFormControl>

            <S.StyledFormControl required fullWidth>
              <TextField
                required
                label="ID"
                inputProps={{
                  'data-testid': 'scope-id',
                }}
                id="scope-id"
                value={id}
                disabled={isLoading || edit}
                variant="outlined"
                margin="dense"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setId(event.target.value)
                }
                helperText="Please enter the ID of the scope"
              />
            </S.StyledFormControl>
            <S.StyledFormControl required fullWidth>
              <TextField
                required
                label="Summary"
                inputProps={{
                  'data-testid': 'scope-summary',
                }}
                id="scope-summary"
                value={summary}
                disabled={isLoading}
                variant="outlined"
                margin="dense"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSummary(event.target.value)
                }
                helperText="Please enter a few words on what the scope grants"
              />
            </S.StyledFormControl>
            <S.StyledFormControl required fullWidth>
              <TextField
                label="User Information"
                inputProps={{
                  'data-testid': 'scope-user-information',
                }}
                id="scope-user-information"
                value={userInformation}
                disabled={isLoading}
                variant="outlined"
                margin="dense"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setUserInformation(event.target.value)
                }
                helperText="This will be shown to the user on the consent screen (ex: The application would like to...)"
              />
            </S.StyledFormControl>
            <S.StyledFormControl required fullWidth>
              <TextField
                multiline
                minRows={3}
                label="Scope Description"
                inputProps={{
                  'data-testid': 'scope-description',
                }}
                id="scope-description"
                value={description}
                disabled={isLoading}
                variant="outlined"
                margin="dense"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setDescription(event.target.value)
                }
                helperText="Please add an elaborate description"
              />
            </S.StyledFormControl>
            <Box paddingTop={1} display="flex" justifyContent="space-between">
              <TrackedInteraction interaction="onClick" {...cancelScope()}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </TrackedInteraction>
              <TrackedInteraction interaction="onClick" {...saveTrackingProps}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </TrackedInteraction>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};
