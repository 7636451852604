import React from 'react';
import { SearchConfigForGCS } from '../models';
import { APIsIcon, TeamReference } from 'plugin-ui-components';
import { AccentColors } from '../constants/AccentColors';
import { ResultDataList } from '../components/ResultDataList';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { Link } from '@backstage/core-components';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';

type APIMetadata = {
  team: string;
  application: string;
  name: string;
  revision: string;
  version: string;
};

const label = 'APIs';
const id = formatIndexLabelToID(label);
export const apisIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'sunrise',
  icon: APIsIcon as any,
  accentColor: AccentColors.Default,
  objectTypes: ['api'],
  getUrl: hit => {
    const { name, revision, version } = extractGCSFields<APIMetadata>(hit);
    return `/catalog/default/API/${name}-${revision}-${version}`;
  },
  getTitle: hit => hit.title,
  render: hit => {
    const { team, application } = extractGCSFields<APIMetadata>(hit);
    return (
      <>
        <ResultDataList
          dense
          list={[
            {
              label: 'Owner',
              value: team && <TeamReference team={team} />,
            },
            {
              label: 'Application',
              value: (
                <Link to={`/catalog/default/component/${application}`}>
                  {application}
                </Link>
              ),
            },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
      </>
    );
  },
});
