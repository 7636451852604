import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function Description({ value, issue }: FieldProps<'description'>) {
  const handleLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    if (target.tagName.toLowerCase() === 'a') {
      window.open(target.href, '_blank');
    }
  };

  return (
    <>
      <dt>Description</dt>
      <dd onClickCapture={handleLinkClick}>
        <Markdown>{value || ''}</Markdown>
        <Issue {...issue} />
      </dd>
    </>
  );
}
