import { DeploymentEnvironment, RunStatus } from '../../../api/definitions';
import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import {
  AVERAGE_METRIC_TOOLIP,
  DEFAULT_SELECT_OPTION,
} from '../../../constants/common';
import { RunStatusBadge } from '../../Runs/RunStatusBadge';

export const getOptions = (data: any, key?: string) => {
  let filteredData;
  if (key) {
    filteredData = data ? data.map((field: any) => field[key]) : [];
  }

  const options = Array.from(new Set(filteredData || data));

  options.unshift(DEFAULT_SELECT_OPTION);

  return options;
};

export const getStatusOptions = (runStatuses: RunStatus[] | undefined) => {
  const uniqueStatuses = Array.from(new Set(runStatuses));

  const options: any[] = uniqueStatuses
    ? uniqueStatuses.map(status => {
        return {
          label: <RunStatusBadge status={status} />,
          value: status,
        };
      })
    : [];
  options.unshift({
    label: DEFAULT_SELECT_OPTION,
    value: DEFAULT_SELECT_OPTION,
  });

  return options;
};

export const getDeploymentEnvironmentOptions = (
  deploymentEnvironments: DeploymentEnvironment[] | undefined,
) => {
  const options = deploymentEnvironments
    ? deploymentEnvironments.map(environment => {
        return {
          label: environment.name,
          value: environment.id,
        };
      })
    : [];
  options.unshift({
    label: DEFAULT_SELECT_OPTION,
    value: DEFAULT_SELECT_OPTION,
  });

  return options;
};

export const getMetricValueCellStyle = (rowData: any) => {
  return rowData.is_average ? { fontStyle: 'italic' } : {};
};

export const getMetricValueCell = (row: any) => {
  if (row.is_average) {
    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {row.value} (AVG){' '}
        <Tooltip
          title={
            <span style={{ fontSize: '0.875rem' }}>
              {AVERAGE_METRIC_TOOLIP}
            </span>
          }
        >
          <IconButton size="small">
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
  return row.value;
};
