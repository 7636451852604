import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsyncRetry } from 'react-use';
import { parseEntityRef } from '@backstage/catalog-model';

/**
 * Gets the current active user by default with the possibility to return another user if needed
 * @param {string} [userId] The user id that needs to be retrieved
 * @returns The user entity in useAsync format
 */
export function useUser(userId?: string) {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  return useAsyncRetry<IEntityUser>(async () => {
    const identity = await identityApi.getBackstageIdentity();
    return (await catalogApi.getEntityByRef({
      kind: 'User',
      namespace: 'default',
      name: userId || parseEntityRef(identity.userEntityRef).name,
    })) as IEntityUser;
  }, [catalogApi, identityApi, userId]);
}
