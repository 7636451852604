import React from 'react';
import { Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import { config, FormFields } from '../config';
import { ReviewContext } from '../context';

interface Props {
  name: FormFields;
}

export function SectionCheckbox({ name }: Props) {
  const { review, onReviewChange } = React.useContext(ReviewContext);
  return (
    <>
      <Divider style={{ marginBlock: '1rem' }} />
      <FormControlLabel
        style={{ display: 'block' }}
        control={
          <Checkbox
            required={config[name].required ?? true}
            name={name}
            checked={review[name] === 'yes'}
            onChange={ev =>
              onReviewChange(name, ev.target.checked ? 'yes' : '')
            }
          />
        }
        label={config[name]?.label || ''}
      />
    </>
  );
}
