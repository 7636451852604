import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Divider, Link, Typography } from '@material-ui/core';
import { RadioSelect } from '../common';
import { SectionCheckbox } from '../common/SectionCheckbox';
import { CyberWeekInScopeBanner } from './CyberWeekInScopeBanner';
import { steps } from '../steps';
import { ReviewContext } from '../context';
import { Alert } from '@material-ui/lab';

export const CYBERWEEK_FIELDS = [
  'cyberweek_higher_traffic',
  'cyberweek_application_change',
  'cyberweek_outage',
  'cyberweek_failure',
  'cyberweek_modify_data',
];

export const CYBERWEEK_SYSTEM_EVENT_FIELDS = [
  'cyberweek_pre_event_system',
  'cyberweek_event_system',
  'cyberweek_post_event_system',
];

export function CyberWeek() {
  const { entity } = useEntity<IEntityApp>();
  const { spec, metadata } = entity;
  const { review } = React.useContext(ReviewContext);
  const eventSystemRef = React.useRef<HTMLElement>(null);

  // check if error is from cyberweek validation
  const step = steps.find(s => s.field === 'cyberweek_ok');
  const hasError =
    step?.validate && !step.validate(review) && review.cyberweek_ok;

  return (
    <section>
      {hasError && (
        <Alert severity="error" style={{ marginBottom: 8 }}>
          This application is considered Cyber Week relevant, and therefore MUST
          have at least one{' '}
          <Link
            component="button"
            onClick={e => {
              e.preventDefault();
              // TODO: workaround because link with #hash is not working
              eventSystemRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Cyber Week System Event
          </Link>{' '}
          marked as <strong>"Yes"</strong>.
        </Alert>
      )}
      <Typography>
        To prepare for Cyber Week, mitigate operational risks, and apply
        adequate policies, applications must be classified as Cyber Week
        relevant. Answer the following questions carefully in order to assess
        the Cyber Week relevancy for this application.
      </Typography>
      {spec?.spec?.active && metadata.cyberweekInScope && (
        <CyberWeekInScopeBanner />
      )}
      <br />
      {CYBERWEEK_FIELDS.map(name => (
        <RadioSelect name={name} />
      ))}
      <Divider />
      <br />
      <Typography ref={eventSystemRef}>
        If any of the questions above were marked as <strong>"Yes"</strong> or{' '}
        <strong>"Not sure"</strong> then this application is considered{' '}
        <strong>Cyber Week relevant</strong>, and therefore one or more of the
        questions below <strong>MUST</strong> be answered as{' '}
        <strong>"Yes"</strong>:
      </Typography>
      <br />
      {CYBERWEEK_SYSTEM_EVENT_FIELDS.map(name => (
        <RadioSelect name={name} />
      ))}
      <SectionCheckbox name="cyberweek_ok" />
    </section>
  );
}
