import { TrackedInteractionProps } from 'plugin-ui-components';

export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'domains',
  eventCategory: 'Domains Catalog Page',
};

export const createDomain = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Creating new domain in index page',
  eventAction: `Create new domain button is clicked`,
});

export const discoverDomainWithDepth = (
  domainName: string,
  depth: number,
): ITrackingEvent => ({
  ...common,
  eventLabel: `Discovering domain "${domainName}" of depth ${depth} in index page`,
  eventAction: `Domain "${domainName}" of depth ${depth} is clicked`,
});

export const discoverDomain = (
  domainName: string,
  widgetName: string,
): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventLabel: `Discovering domain "${domainName}" in Connected Domains/${widgetName}`,
  eventAction: `Domain "${domainName}" is clicked`,
});

export const expandDomain = (
  domainName: string,
  depth: number,
): ITrackingEvent => ({
  ...common,
  eventLabel: `Expanding domain "${domainName}" of depth ${depth} in index page`,
  eventAction: `Domain "${domainName}" of depth ${depth} is expanded.`,
});

export const discoverDomainUser = (
  domainName: string,
  widgetName: string,
  relationToDomain: string,
): ITrackingEvent => ({
  ...common,
  eventLabel: `User Clicked on ${relationToDomain} of domain "${domainName}" in ${widgetName}`,
  eventAction: `Owner of "${domainName}" details link is clicked".`,
});

export const discoverApplicationViaDomain = (
  appName: string,
  domainName: string,
): ITrackingEvent => ({
  ...common,
  eventLabel: `Discovering application "${appName}" via domain "${domainName}"`,
  eventAction: `Application "${appName}" details link is clicked via domain "${domainName}"`,
});

export const discoverOwnerViaDomain = (
  ownerName: string,
  domainName: string,
): ITrackingEvent => ({
  ...common,
  eventLabel: `Discovering owner "${ownerName}" via domain "${domainName}" applications`,
  eventAction: `"${ownerName}" details link is clicked via domain "${domainName}" applications`,
});

export const attemptEditWhenDisabled = (
  widgetName: string,
): ITrackingEvent => ({
  ...common,
  eventLabel: `User attempted to edit the content in "${widgetName}" (Disabled, Coming Soon)`,
  eventAction: `"${widgetName}" edit button is clicked".`,
});

export const clickLinkToDiscover = (
  text: string,
  widgetName: string,
): TrackedInteractionProps => ({
  ...common,
  interaction: 'onClick',
  eventLabel: `Discovering ${text} in ${widgetName}`,
  eventAction: `User clicked on "${text}" link`,
});

export const clickOnResourceLink = (
  resourceType: string,
  domain: string,
): ITrackingEvent => ({
  ...common,
  eventLabel: `Discovering resource link "${resourceType}" on domain "${domain}"`,
  eventAction: `Resource Link "${resourceType}" is clicked on domain "${domain}".`,
});
