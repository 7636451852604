import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    top: 18,
    left: 12,
  },
  inputRoot: {
    '& label[data-shrink="false"]': {
      transform: 'translate(40px, 12px) scale(1)',
    },
    '& input': {
      paddingLeft: 40,
    },
  },
}));
