import React from 'react';
import { CardContent } from '@material-ui/core';
import { InfoCard } from 'plugin-ui-components';
import { AboutCardLinks } from './AboutCardLinks';
import { AboutCardMetadata } from './AboutCardMetadata';
import { useEntity } from '@backstage/plugin-catalog-react';

export function ApplicationAboutCard() {
  const { entity } = useEntity<IEntityApp>();

  return (
    <InfoCard title="About">
      <CardContent>
        <AboutCardLinks entity={entity} />
        <AboutCardMetadata entity={entity} />
      </CardContent>
    </InfoCard>
  );
}
