import {
  MLPipelineRun,
  MLPipelineRunStep,
  RunStatus,
  RunStepStatus,
} from '../../api/definitions';
import { AWS_CONSOLE_URLS, GOTO_LAMBDA_SUFFIXES } from '../../constants/common';
import { RunStatusColors } from '../../constants/colors';

export class RunHelpers {
  static getColor = (status: RunStatus | RunStepStatus) => {
    return RunStatusColors[status] || RunStatusColors.Default;
  };

  static getReactJSONTheme = (pluginTheme: string) => {
    return pluginTheme === 'dark' ? 'ocean' : 'rjv-default';
  };

  static getStepResources = (runStepDetails: MLPipelineRunStep) => {
    let stepResources: any[] = [];

    let possibleDataBrickRunPageUrl = false;
    const stepName =
      runStepDetails.step_type === 'Task' && runStepDetails.name.split(' ')[1];
    if (stepName) {
      possibleDataBrickRunPageUrl =
        runStepDetails.details?.input?.[stepName]?.run_page_url;
    } else {
      possibleDataBrickRunPageUrl =
        runStepDetails.details?.output?.run_page_url;
    }

    const possibleSMTrainingJobName =
      runStepDetails.details?.TrainingJobName ||
      runStepDetails.details?.output?.TrainingJobName;

    const possibleSMTransformJobName =
      runStepDetails.details?.TransformJobName ||
      runStepDetails.details?.output?.TransformJobName;

    const possibleSMProcessingJobName =
      runStepDetails.details?.ProcessingJobName ||
      runStepDetails.details?.output?.ProcessingJobName;

    const resourceTypes = [
      { type: 'databricks', name: possibleDataBrickRunPageUrl },
      { type: 'SMTraining', name: possibleSMTrainingJobName },
      { type: 'SMTransform', name: possibleSMTransformJobName },
      { type: 'SMProcessing', name: possibleSMProcessingJobName },
    ];

    resourceTypes.forEach(resource => {
      if (resource.name) {
        stepResources = stepResources.concat(
          getResources(resource.type, resource.name),
        );
      }
    });

    return stepResources;
  };
}

function getResources(jobType: string, jobName: string) {
  switch (jobType) {
    case 'databricks':
      return [
        {
          title: 'DataBricks Run Page URL',
          href: jobName,
        },
      ];
    case 'SMTraining':
      return [
        {
          title: 'SageMaker Training Job',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_TRAINING_JOB_URL}/${jobName}`,
        },
        {
          title: 'Logs',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_TRAINING_JOB_LOGS_URL}/${jobName}`,
        },
      ];
    case 'SMTransform':
      return [
        {
          title: 'SageMaker Batch Transform Job',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_BATCH_TRANSFORM_JOB_URL}/${jobName}`,
        },
        {
          title: 'Logs',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_BATCH_TRANSFORM_JOB_LOGS_URL}/${jobName}`,
        },
      ];
    case 'SMProcessing':
      return [
        {
          title: 'SageMaker Processing Job',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_PROCESSING_JOB_URL}/${jobName}`,
        },
        {
          title: 'Logs',
          href: `${AWS_CONSOLE_URLS.SAGEMAKER_PROCESSING_JOB_LOGS_URL}/${jobName}`,
        },
      ];
    default:
      return [];
  }
}

function checkIsGotoStep(
  goto_suffixes: Array<string>,
  name: string | undefined,
) {
  if (name === undefined) return false;
  return goto_suffixes.some(function hasGotoSuffix(suffix) {
    return name.endsWith(suffix);
  });
}

export class GotoHelpers {
  static getGotoEnabledStepFlag = (
    status: RunStatus | RunStepStatus | undefined,
    name: string | undefined,
  ) => {
    const isGotoStep = checkIsGotoStep(GOTO_LAMBDA_SUFFIXES, name);
    switch (status) {
      case RunStatus.Initialized:
      case RunStatus.Unknown:
        return false;
      case RunStatus.Running:
      case RunStatus.Failed:
      case RunStatus.Succeeded:
      case RunStepStatus.SUCCESS:
      case RunStatus.Aborted:
      case RunStatus.TimedOut:
        return true && !isGotoStep;
      default:
        return false;
    }
  };

  static getGotoEnabledPipelineFlag = (run: MLPipelineRun | undefined) => {
    if (run === undefined || run.steps[0] === undefined) return false;
    return run.steps.some(function stepIsGotoStep(step) {
      return checkIsGotoStep(GOTO_LAMBDA_SUFFIXES, step.name);
    });
  };
}
