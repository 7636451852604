import React from 'react';
import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
  StatusWarning,
} from '@backstage/core-components';
import { Tooltip, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { RunStatus, RunStepStatus } from '../../api/definitions';

export const RunStatusBadge = ({
  status,
}: {
  status: RunStatus | RunStepStatus | undefined;
}) => {
  switch (status) {
    case RunStatus.Initialized:
      return <StatusPending>{status}</StatusPending>;
    case RunStatus.Running:
      return <StatusRunning>{status}</StatusRunning>;
    case RunStatus.Failed:
      return <StatusError>{status}</StatusError>;
    case RunStatus.Succeeded:
    case RunStepStatus.SUCCESS:
      return <StatusOK>{status}</StatusOK>;
    case RunStatus.TimedOut:
    case RunStatus.Aborted:
      return <StatusAborted>{status}</StatusAborted>;
    case RunStatus.Unknown:
      return (
        <>
          <StatusWarning>{status}</StatusWarning>
          <Tooltip
            title="We couldn't access the pipeline to update this run's status. This could be because the stack or pipeline was deleted."
            placement="right"
          >
            <IconButton size="small">
              <HelpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      );
    default:
      return <>Unknown Status</>;
  }
};
