import { EventTrackerProps } from 'plugin-ui-components';

const common: Omit<EventTrackerProps, 'eventLabel'> = {
  plugin: 'catalog',
  eventCategory: 'Tooling Overview',
  eventAction: 'click',
  interaction: 'onClick',
};

export function clickLink(eventLabel: string) {
  return { ...common, eventLabel };
}
