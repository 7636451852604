import React from 'react';
import { Grid } from '@material-ui/core';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { DependabotAlerts } from './DependabotAlerts';

export const ApplicationVulnerabilities = () => {
  const useEntityState = useAsyncEntity<IEntityApp>();

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <DependabotAlerts useEntityState={useEntityState} />
      </Grid>
    </Grid>
  );
};
