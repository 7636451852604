import React from 'react';
import { Link } from 'react-router-dom';

import { Tooltip } from '../../../common';
import * as S from './styles';

type Props = {
  buildVersion: string;
  url: string;
};

export function BuildVersionTag({ buildVersion, url }: Props) {
  return (
    <S.TextLabeled>
      <Tooltip
        title={`Build version: ${buildVersion}`}
        placement="bottom-start"
      >
        <Link to={url}>{buildVersion}</Link>
      </Tooltip>
    </S.TextLabeled>
  );
}
