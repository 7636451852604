import React from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { opsgenieApiRef } from '../../api';
import { Alert } from '../../types';

export const AlertActionsMenu = ({
  alert,
  onAlertChanged,
}: {
  alert: Alert;
  onAlertChanged?: (alert: Alert) => void;
}) => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const alertApi = useApi(alertApiRef);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const callback = onAlertChanged || ((_: Alert): void => {});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAcknowledge = async (): Promise<void> => {
    try {
      await opsgenieApi.acknowledgeAlert(alert);
      handleCloseMenu();
      alertApi.post({ message: 'Alert acknowledged.' });

      alert.acknowledged = true;
      callback(alert);
    } catch (error) {
      const err = error as string;
      alertApi.post({ message: err, severity: 'error' });
    }
  };

  const handleCloseAlert = async (): Promise<void> => {
    try {
      await opsgenieApi.closeAlert(alert);
      handleCloseMenu();
      alertApi.post({ message: 'Alert closed.' });

      alert.status = 'closed';
      callback(alert);
    } catch (error) {
      const err = error as string;
      alertApi.post({ message: err, severity: 'error' });
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu-${alert.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: { maxHeight: 48 * 4.5 },
        }}
      >
        {!opsgenieApi.isReadOnly() &&
          !alert.acknowledged &&
          alert.status !== 'closed' && (
            <MenuItem key="ack" onClick={handleAcknowledge}>
              <ListItemIcon>
                <VisibilityIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Acknowledge
              </Typography>
            </MenuItem>
          )}

        {!opsgenieApi.isReadOnly() && alert.status !== 'closed' && (
          <MenuItem key="close" onClick={handleCloseAlert}>
            <ListItemIcon>
              <CheckCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Close
            </Typography>
          </MenuItem>
        )}

        <MenuItem key="details" onClick={handleCloseMenu}>
          <ListItemIcon>
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            <Link href={opsgenieApi.getAlertDetailsURL(alert)}>
              View in Opsgenie
            </Link>
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
