import useLocalStorageState from 'use-local-storage-state';
import { LocalStorageSearch } from '../types';

const SEARCH_STORAGE_KEY = 'sunriseSearch';

const localStoragePlaceholder = {
  recentSearchTerms: [],
  hotPageOpen: true,
};

export const useSearchLocalStorage = () => {
  const [searchLocalStorage, setSearchLocalStorage] =
    useLocalStorageState<LocalStorageSearch>(SEARCH_STORAGE_KEY, {
      defaultValue: localStoragePlaceholder,
    });

  const setTermLocalStorage = (term: string) => {
    // max of 10 search terms are stored in local storage
    setSearchLocalStorage({
      ...searchLocalStorage,
      recentSearchTerms: [
        ...new Set([
          term,
          ...(searchLocalStorage?.recentSearchTerms?.slice(0, 9) || []),
        ]),
      ],
    });
  };

  const removeTermLocalStorage = (term: string) => {
    setSearchLocalStorage({
      ...searchLocalStorage,
      recentSearchTerms: searchLocalStorage?.recentSearchTerms?.filter(
        t => t !== term,
      ),
    });
  };

  const setHotPageLocalStorage = (open: boolean) => {
    setSearchLocalStorage({
      ...searchLocalStorage,
      hotPageOpen: open,
    });
  };

  return {
    searchLocalStorage,
    setTermLocalStorage,
    removeTermLocalStorage,
    setHotPageLocalStorage,
  };
};
