import { useAsync } from 'react-use';
import {
  discoveryApiRef,
  useApi,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { useState } from 'react';

export const useAppData = (edit: boolean, entity?: IEntityApp) => {
  const discoveryApi = useApi(discoveryApiRef);
  const oauth2Api = useApi(oauth2ApiRef);
  const { value: proxyApi } = useAsync(() => discoveryApi.getBaseUrl('proxy'));
  const { value: token } = useAsync(() => oauth2Api.getAccessToken());
  const errorApi = useApi(errorApiRef);
  const [data, setData] = useState<IKioAppApiResponse>();

  useAsync(() => {
    if (!entity?.spec.name || !proxyApi || !token || !edit)
      return Promise.resolve([]);

    const { name } = entity.metadata;
    const kioUrl = `${proxyApi}/kio/apps`;
    const appUrl = `${kioUrl}/${name}`;

    return fetch(appUrl, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.json())
      .then(body => setData(body))
      .catch(errorApi.post);
  }, [entity, proxyApi, token]);

  return data;
};
