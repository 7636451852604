import React from 'react';
import { Snackbar as MSnackbar } from '@material-ui/core';

interface SnackbarProps {
  message: string;
  isErr?: boolean;
  onClose: React.Dispatch<React.SetStateAction<string>>;
}

export const Snackbar = (props: SnackbarProps) => {
  const message = props.isErr
    ? `Please try after sometime. ${props.message}`
    : props.message;
  return (
    <MSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.message ? true : false}
      autoHideDuration={2000}
      onClose={() => props.onClose('')}
      message={message}
    />
  );
};
