import {
  alpha,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

interface StylesProps {
  hasError: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      border: `1px solid ${theme.palette.divider}`,
      padding: '5px 0px 5px 15px',

      '& svg': {
        marginLeft: theme.spacing(1),
      },
    },
    popper: {
      minWidth: 350,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: `0 3px 12px ${theme.palette.divider}`,
      borderRadius: 3,
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
    },
    noOptions: (props: StylesProps) => ({
      color: props.hasError
        ? theme.palette.error.main
        : theme.palette.text.hint,
    }),
    inputBase: {
      padding: theme.spacing(1.2),
      width: '100%',
      '& input': {
        borderRadius: 4,
        backgroundColor: 'transparent',
        padding: theme.spacing(1),
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.divider}`,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    clearFilter: {
      display: 'flex',
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,

      '& > button, & > div': {
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
          marginRight: 5,
        },
      },

      '& > div': {
        color: theme.palette.text.hint,
        opacity: 0.5,
        fontStyle: 'italic',
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: theme.palette.text.secondary,
      fontSize: '0.85rem',
    },
    popperDisablePortal: {
      position: 'relative',
    },
    subheader: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'center',
      overflowX: 'hidden',
      padding: theme.spacing(0, 1),
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },

      '& > p': {
        flexGrow: 1,
        padding: theme.spacing(0, 1),
      },
    },
    svg: {
      width: 17,
      height: 17,
    },
  }),
);
