import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { SectionRadio } from '../common';
import { LoadTestStats } from './LoadTestStats';

export function LoadTests() {
  return (
    <section>
      <Typography>
        Tier-1 applications must have conducted load testing and Tier-2
        applications should be load tested (see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/engineering/guidelines/apec/"
        >
          APEC <OpenInNewIcon />
        </Link>
        ). Please follow the Load Testing Protocol and use the Load Test
        Reporting API, see&nbsp;
        <Link
          target="_blank"
          to="/docs/default/Documentation/cloud/howtos/load-test/"
        >
          How to Load Test Your Application <OpenInNewIcon />
        </Link>
        .
      </Typography>

      <dl>
        <dt>Time of last load test</dt>
        <dd>
          <LoadTestStats />
        </dd>
      </dl>

      <SectionRadio name="load_tests_ok" />
    </section>
  );
}
