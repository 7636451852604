import React, { useState } from 'react';
import { getFormattedDateTime } from 'plugin-ui-components';
import { Tooltip } from '@material-ui/core';
import { Dataset } from '../../api/interfaces/dataset';
import { ResourceTable } from '../tables/ResourceTable';
import { TextTag } from '../tables/columns/Tags';
import * as S from '../../styles/table';
import { MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE } from '../../constants/paging';
import { TableColumn } from '@backstage/core-components';
import Grid from '@mui/material/Grid';

export const Datasets = ({ datasets }: { datasets: Dataset[] }) => {
  const columns: TableColumn<Dataset>[] = [
    {
      field: 'name',
      title: 'Name',
      searchable: true,
      render: ({ uri, name }) => {
        return (
          <Tooltip title="Dataset name">
            <S.Title to={uri} target="_blank">
              {name}
            </S.Title>
          </Tooltip>
        );
      },
    },
    {
      field: 'source',
      title: 'Source',
      render: ({ source }) => {
        return (
          <TextTag
            text={source as string}
            title="Dataset Source"
            textTransform="upper"
          />
        );
      },
    },
    {
      field: 'type',
      title: 'Type',
      render: ({ type }) => {
        return <TextTag text={type as string} title="Dataset Type" />;
      },
    },
    {
      field: 'created_at',
      title: 'Added On',
      render: ({ created_at }) => {
        return getFormattedDateTime(created_at);
      },
    },
  ];

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    MODEL_VERSION_SUB_RESOURCE_LIST_PAGE_SIZE,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ResourceTable
          loading={false}
          columns={columns}
          entity="dataset"
          data={datasets as any}
          paginationConfig={{
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setRowsPerPage: setRowsPerPage,
            page: page || 0,
            total: datasets.length,
          }}
          displayOptions={{
            showTitle: true,
            search: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
