export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'credential-distribution',
  eventCategory: 'Access Control Page',
};

export const addBucket = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Adding new Mint Bucket in Access Control',
  eventAction:
    "'Add new Mint Bucket' button is clicked in Credential Distribution widget",
});

export const removeBucket = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Removing a Mint Bucket in Access Control',
  eventAction:
    "'Remove Mint Bucket' button is clicked in Credential Distribution widget",
});

export const addSuggestedBucket = (): ITrackingEvent => ({
  ...common,
  eventLabel: 'Adding a suggested Mint Bucket in Access Control',
  eventAction:
    "'Suggested Mint Bucket' button is clicked in Credential Distribution widget",
});
