export function createGrafanaApplicationDashboardUrlWithParameters(
  urlString: string,
) {
  const url = new URL(urlString);
  const search = new URLSearchParams(url.search);

  if (!search.has('var-cluster')) {
    search.set('var-cluster', '*');
    url.search = search.toString();
  }
  if (!search.has('var-namespace')) {
    search.set('var-namespace', 'All');
    url.search = search.toString();
  }
  return url.href;
}
