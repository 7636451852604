import ReactJson from 'react-json-view';
import React, { useState } from 'react';
import { MLPipelineRunStep } from '../../api/definitions';
import { RunStatusBadge } from './RunStatusBadge';
import { RunHelpers } from './helpers';
import { Link, StructuredMetadataTable } from '@backstage/core-components';
import { getDateTimeDiff } from '../../utils/time';
import { usePluginTheme } from '../../hooks/usePluginTheme';

export const RunStepDetails = ({
  stepDetails,
}: {
  stepDetails: MLPipelineRunStep;
}) => {
  const exceptions = stepDetails.details.error
    ? {
        error: stepDetails.details.error,
        cause: stepDetails.details.cause,
      }
    : {};

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const pluginTheme = usePluginTheme();
  const theme = RunHelpers.getReactJSONTheme(pluginTheme);

  const stepResources = RunHelpers.getStepResources(stepDetails);

  const stepMetadata = {
    'Step Name': stepDetails.name,
    'Run Status': <RunStatusBadge status={stepDetails.status} />,
    Resources: stepResources.length
      ? stepResources.map(stepResource => {
          if (typeof stepResource.href === 'string') {
            return <Link to={stepResource.href}>{stepResource.title}</Link>;
          }
          return 'N/A';
        })
      : 'N/A',
    'Step Runtime': getDateTimeDiff(
      stepDetails.started_at,
      stepDetails.updated_at,
    ),
    Input: (
      <ReactJson
        enableClipboard={false}
        name={null}
        src={stepDetails.details.input}
        indentWidth={2}
        displayDataTypes={false}
        theme={theme}
        collapsed={collapsed}
        onSelect={_ => setCollapsed(true)}
        style={{ wordBreak: 'break-all' }}
      />
    ),
    Output: (
      <ReactJson
        enableClipboard={false}
        name={null}
        src={stepDetails.details.output}
        indentWidth={2}
        displayDataTypes={false}
        theme={theme}
        collapsed={collapsed}
        onSelect={_ => setCollapsed(true)}
        style={{ wordBreak: 'break-all' }}
      />
    ),
    Exceptions: (
      <ReactJson
        enableClipboard={false}
        name={null}
        collapsed={collapsed}
        src={exceptions}
        indentWidth={2}
        displayDataTypes={false}
        theme={theme}
        onSelect={_ => setCollapsed(true)}
        style={{ wordBreak: 'break-all' }}
      />
    ),
  };
  return <StructuredMetadataTable metadata={stepMetadata} />;
};
