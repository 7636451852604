import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  mediumBold: {
    fontWeight: 500,
  },
  confidentialityItem: {
    display: 'flex',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    '& svg': {
      marginLeft: 3,
      marginTop: '-1px',
    },
  },
  urlInput: {
    marginTop: 8,
    marginBottom: 20,
    '& div': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  checkbox: {
    padding: '6px 9px',
  },
}));
