import { Theme, styled, List, ListItem, makeStyles } from '@material-ui/core';

export const RunListLayout = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const RunListContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const RunList = styled(List)({
  flex: 1,
  margin: 0,
  padding: 0,
});

export const StyledGridRow = styled(ListItem)(
  ({
    theme,
  }: any & {
    theme: Theme;
  }) => ({
    padding: theme.spacing(1, 0),
    display: 'grid',
    gridTemplateColumns: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'var(--grid-template)',
      gridGap: '5px',
      alignItems: 'flex-start',
    },
  }),
);

export const RunHeader = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'none',
  padding: '0',
  fontWeight: 600,
  color: theme.palette.text.secondary,
  borderBottom: `2px solid ${theme.palette.divider}`,
  borderTop: `2px solid ${theme.palette.divider}`,

  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'var(--grid-template)',
    gridGap: '10px',
    alignItems: 'flex-start',
  },
}));

export const StyledGridColumn = styled('div')(
  ({
    theme,
    header,
    item,
    spaced,
  }: {
    theme: Theme;
    header?: boolean;
    item?: boolean;
    spaced?: boolean;
  }) => ({
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...(item && { padding: theme.spacing(0, 1) }),
    ...(header && { padding: theme.spacing(1) }),
    ...(spaced && { marginLeft: theme.spacing(2.4) }),
  }),
);

export const Text = styled('p')(({ theme }: { theme: Theme }) => ({
  maxWidth: '100%',
  margin: '0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '.85rem',
  marginBottom: theme.spacing(1),

  ['& a:hover']: {
    color: theme.palette.link,
  },
}));

export const TextFlat = styled(Text)({
  marginBottom: 0,
});

export const useHighlightStyles = makeStyles(theme => ({
  '@keyframes highlight': {
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 0.1,
    },
    '40%': {
      opacity: 0,
    },
    '60%': {
      opacity: 0.1,
    },
    '80%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.1,
    },
  },
  container: {
    flex: 1,
    margin: 0,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: "' '",
      background: theme.palette.info.main,
      opacity: '0.1',
      pointerEvents: 'none',
      zIndex: 10,
      animation: `$highlight 2s ${theme.transitions.easing.easeInOut}`,
    },
  },
  container__element: {
    width: '100%',
    background: theme.palette.info.main,
    padding: theme.spacing(0.5),
    color: theme.palette.background.paper,
    fontWeight: 600,
    fontSize: '13px',
    textTransform: 'uppercase',
  },
}));
