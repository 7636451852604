import {
  Card,
  styled,
  Typography,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import OpenInNew from '@material-ui/icons/OpenInNew';

export const Wrapper = styled(Card)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
  boxShadow: 'none',
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0, 0, 1),
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 'bold',
}));

export const GroupWrapper = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1, 1, 2, 1),
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minHeight: theme.spacing(6),
}));

export const MenuTitle = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 30,
  color: theme.palette.text.primary,
}));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: 'left',
    },
    pagination: {
      marginTop: theme.spacing(2),
    },
    search: {
      marginBottom: theme.spacing(2),
    },
    onCallItemGrid: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(26em, .5fr))',
    },
    weekButton: {
      borderRadius: 4,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      marginTop: theme.spacing(),
    },
    chip: {
      marginRight: 0,
      marginBottom: 0,
    },
    week: {
      lineHeight: '24px',
    },
    arrow: { cursor: 'pointer', fontSize: '1em' },
  }),
);

export const InfoIcon = styled(InfoOutlined)(() => ({
  fontSize: '1em',
  verticalAlign: 'bottom',
}));

export const OpenInNewIcon = styled(OpenInNew)(() => ({
  fontSize: '.8em',
  verticalAlign: 'middle',
}));
