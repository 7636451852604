import { SuggestionEntry } from '../api/definitions';

export const SuggestionEntries: SuggestionEntry[] = [
  {
    field: 'projects',
    title: 'Projects',
    path: 'projects',
  },
  {
    field: 'pipelines',
    title: 'Pipelines',
    path: 'pipelines',
  },
  {
    field: 'runs',
    title: 'Runs',
    path: 'runs',
  },
  {
    field: 'ml_models',
    title: 'Models',
    path: 'models',
  },
  {
    field: 'ml_model_groups',
    title: 'Model Groups',
    path: 'model-groups',
  },
];

export const NotebookSuggestionEntries: SuggestionEntry[] = [
  {
    field: 'notebooks',
    title: 'Notebooks',
    path: 'notebooks',
  },
];
