import {
  ConfigFilter,
  ConfigFilterOption,
  SearchConfigForGCS,
} from '../../models';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import React, { type ReactNode, useMemo } from 'react';
import { useStyles } from '../../styles';
import { useSearchContext } from '../../SearchContext';
import { searchConfig } from '../../config';
import { TabFilterError } from './TabFilterError';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

interface Props {
  title?: ReactNode;
  filters: ConfigFilter[];
}

export function TabFilters({ title = '', filters: tabFilters }: Props) {
  const styles = useStyles();
  const {
    tab,
    filters,
    onFilterChange,
    onFilterRemove,
    filterOptionsInitialized,
    setFilterOptionsInitialized,
  } = useSearchContext();
  const catalogApi = useApi(catalogApiRef);

  const tabIndex = useMemo(
    () => searchConfig.indexes.findIndex(i => i.isMatchingQuery(tab)),
    [tab],
  );
  const searchIndex = searchConfig.indexes[tabIndex] as SearchConfigForGCS;

  const { value: listOfOptions, loading } = useAsync(async () => {
    const tabFiltersOptions = tabFilters.map(filter => filter.getOptions());
    return await Promise.all(tabFiltersOptions);
  }, [tabFilters, filterOptionsInitialized]);

  const isLoading = loading || !filterOptionsInitialized;

  if (!listOfOptions?.length) return <></>;

  return (
    <div className={styles.searchFilters}>
      {typeof title === 'string' ? <Typography>{title}</Typography> : title}
      {tabFilters.map((tabFilter, i) => {
        const options = tabFilter.getOptions();

        if (!options) return <></>;

        if (options instanceof Error) {
          return (
            <TabFilterError
              key={i}
              filterLabel={tabFilter.label}
              error={options}
              retry={async () => {
                setFilterOptionsInitialized(false);
                await tabFilter.initOptions({ catalogApi });
                setFilterOptionsInitialized(true);
              }}
              loading={isLoading}
            />
          );
        }

        const storedTabFilter = filters.find(
          f => f.indexId === tabFilter.indexId && f.field === tabFilter.field,
        );
        const filterValue: ConfigFilterOption[] = storedTabFilter
          ? options.filter(opts => storedTabFilter?.value.includes(opts.value))
          : [];

        return (
          <Autocomplete
            key={i}
            fullWidth
            size="small"
            options={options}
            value={
              tabFilter.isMultiple ? filterValue ?? [] : filterValue[0] ?? {}
            }
            loading={isLoading}
            disabled={isLoading}
            multiple={tabFilter.isMultiple}
            limitTags={3}
            onChange={(_, newValue) => {
              if (
                newValue === undefined ||
                newValue === null ||
                (Array.isArray(newValue) && !newValue?.length)
              ) {
                // Filter should be removed along with its query param
                onFilterRemove({
                  indexId: searchIndex.id,
                  field: tabFilter.field,
                  value: undefined as any,
                });
              } else {
                onFilterChange({
                  indexId: searchIndex.id,
                  field: tabFilter.field,
                  value: Array.isArray(newValue)
                    ? newValue.map(option => option.value)
                    : [newValue.value],
                });
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={tabFilter.label}
                variant="outlined"
                placeholder={tabFilter.label}
                disabled={isLoading}
                InputLabelProps={{
                  style: { textTransform: 'capitalize' },
                }}
              />
            )}
            getOptionLabel={option => option.label ?? ''}
            className={styles.searchFilterAutocomplete}
          />
        );
      })}
    </div>
  );
}
