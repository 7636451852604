import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { colors } from 'plugin-ui-components';
import { CostChange, Group, Maybe } from '../../types';
import { TrustedAdvisorData } from '../../types/TrustedAdvisorData';
import { useInsightsCardStyles } from '../../utils/styles';

export type InsightsCardProps = {
  group?: Group;
  trustedAdvisorData: Maybe<TrustedAdvisorData>;
  costChanges: Maybe<CostChange>;
};

export const InsightsCard = ({
  group,
  trustedAdvisorData,
  costChanges,
}: InsightsCardProps) => {
  const classes = useInsightsCardStyles();
  const twoWeeksChange = costChanges?.sixWeeks;
  const sixWeeksChange = costChanges?.twoWeeks;
  const savings = trustedAdvisorData?.savings;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box minHeight={40} marginBottom={1}>
          <Typography variant="h5" gutterBottom>
            Insights & Savings Opportunities
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" component="div">
            Total costs of the team{' '}
            <span
              className={classes.teamTitle}
            >{`${group?.name} (${group?.id})`}</span>
          </Typography>
        </Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow className={classes.tableTwoWeeksRow}>
                <TableCell>
                  <Typography variant="h4">last 2 weeks trend</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h4"
                    style={{
                      color:
                        twoWeeksChange && twoWeeksChange > 0
                          ? colors.semantic.errorBase
                          : colors.semantic.successBase,
                    }}
                  >
                    {twoWeeksChange && twoWeeksChange !== 0
                      ? `${twoWeeksChange.toFixed(1)} %`
                      : '—'}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h4">last 6 weeks trend</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h4"
                    style={{
                      color:
                        sixWeeksChange && sixWeeksChange > 0
                          ? colors.semantic.errorBase
                          : colors.semantic.successBase,
                    }}
                  >
                    {sixWeeksChange && sixWeeksChange !== 0
                      ? `${sixWeeksChange.toFixed(1)} %`
                      : '—'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        {savings && (
          <Typography style={{ marginTop: 10 }}>
            <span className={classes.boldText}>AWS Trusted Advisor</span>{' '}
            reports that the combined{' '}
            <span className={classes.boldText}>monthly potential savings</span>{' '}
            for all your cost center accounts is{' '}
            <span className={classes.boldText}>{`$${savings.toFixed()}`}</span>
            {' --'}
            <a
              href="https://us-east-1.console.aws.amazon.com/trustedadvisor/home"
              className={classes.primaryColorText}
            >
              check it out
            </a>
            .
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
