import React from 'react';
import { StepActionProps } from './types';
import { Button } from '@material-ui/core';
import { Tooltip } from '../../../../common';

export const StepAction: React.FC<StepActionProps> = ({
  action,
  actionProps,
  disabled = false,
}) => {
  if (actionProps.disabled && !!actionProps.disabledTooltip) {
    return (
      <Tooltip title={actionProps.disabledTooltip}>
        <div>
          <Button
            variant="outlined"
            size="small"
            onClick={action}
            disabled={disabled || actionProps.disabled}
          >
            {actionProps.title}
          </Button>
        </div>
      </Tooltip>
    );
  }
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={action}
      disabled={disabled || actionProps.disabled}
    >
      {actionProps.title}
    </Button>
  );
};
