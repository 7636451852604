import React from 'react';
import { CardContent } from '@material-ui/core';
import { SummaryCard } from '../common';
import { TechStack } from './TechStack';

export function TechStackSummary() {
  const category = 'Tech stack';
  return (
    <SummaryCard category={category}>
      <CardContent>
        <TechStack hideConfirmationSection />
      </CardContent>
    </SummaryCard>
  );
}
