import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import {
  TrackedInteraction,
  TrackedInteractionProps,
} from 'plugin-ui-components';

type Props = {
  children: React.ReactChild;
  hasEditPermission: boolean | undefined;
  trackingProps: Omit<TrackedInteractionProps, 'interaction'>;
  tooltipText?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ActionButton = ({
  children,
  hasEditPermission,
  trackingProps,
  tooltipText,
  onClick,
}: Props) => {
  const styles = useStyles();
  let tooltipTitle = '';

  if (!hasEditPermission) {
    tooltipTitle = 'Only the owner can edit the application';
  } else if (tooltipText) {
    tooltipTitle = tooltipText;
  }

  return (
    <TrackedInteraction interaction="onClick" {...trackingProps}>
      <Tooltip title={tooltipTitle} placement="top">
        <span>
          <IconButton
            disabled={!hasEditPermission}
            className={styles.cardIcon}
            onClick={onClick}
          >
            {children}
          </IconButton>
        </span>
      </Tooltip>
    </TrackedInteraction>
  );
};
