import { computed, observable, action, makeObservable } from 'mobx';

export interface IPersist {
  persistHelper: PersistHelper;
}

export interface IPersistHelper {
  enablePersist: () => void;
  disablePersist: () => void;
  readonly canPersist: boolean;
}

export class PersistHelper implements IPersistHelper {
  private state: boolean = false;

  constructor() {
    makeObservable<this, 'state'>(this, {
      state: observable,
      enablePersist: action,
      disablePersist: action,
      canPersist: computed,
    });
  }

  public enablePersist() {
    this.state = true;
  }

  public disablePersist() {
    this.state = false;
  }

  get canPersist(): boolean {
    return this.state;
  }
}
