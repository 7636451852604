import {
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { CatalogApi, GetEntitiesRequest } from '@backstage/catalog-client';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import axios from 'axios';

interface CommandOptions {
  username?: string;
  catalogApi: CatalogApi;
}

const sharedFilters: GetEntitiesRequest['filter'] = {
  kind: 'Group',
  'spec.type': 'team',
};

export function getMemberTeams({
  catalogApi,
  username,
}: CommandOptions): Promise<IEntityGroup[]> {
  if (!username) {
    return Promise.resolve([] as IEntityGroup[]);
  }

  return catalogApi
    .getEntities({
      filter: {
        ...sharedFilters,
        'relations.hasMember': stringifyEntityRef({
          kind: 'user',
          name: username,
        }),
      },
    })
    .then(res => res.items as IEntityGroup[]);
}

export function getLedTeams({
  catalogApi,
  username,
}: CommandOptions): Promise<IEntityGroup[]> {
  if (!username) {
    return Promise.resolve([] as IEntityGroup[]);
  }
  return catalogApi
    .getEntities({
      filter: {
        kind: 'Group',
        'spec.type': 'team',
        'spec.delivery_lead': username,
      },
    })
    .then(res => res.items as IEntityGroup[]);
}

interface AccountableTeamsArgs {
  entityCompoundName: CompoundEntityRef;
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;
}

export async function getAccountableTeams({
  entityCompoundName: { name, kind, namespace },
  discoveryApi,
  oauth2Api,
}: AccountableTeamsArgs): Promise<IEntityGroup[]> {
  const token = await oauth2Api.getAccessToken();
  const catalogUrl = await discoveryApi.getBaseUrl('catalog');
  const result = await axios.get<IEntityGroup[]>(
    `${catalogUrl}/hierarchy?ref=${stringifyEntityRef({
      name,
      kind,
      namespace,
    })}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return result.data;
}
