import React from 'react';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../../api';
import { IStep, steps } from '../../steps';
import { StepButtonStyles } from '../styles';

interface Props {
  toggle: boolean;
  name: string;
  formReview: IAppReview.Review;
  onClose: () => void;
  onSuccess: () => void;
  isAppReviewed: boolean;
  reviewId: string | undefined;
  setActiveStep: (step: number) => void;
}

export function SubmitDialog({
  formReview,
  onClose,
  onSuccess,
  toggle,
  isAppReviewed,
  name,
  setActiveStep,
  reviewId,
}: Props) {
  const api = useApi(applicationReviewApiRef);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const uncompleted = steps.filter(s => {
    if (s.validate) return !s.validate(formReview);
    return !s.optional && !formReview[s.field];
  });
  const completed = steps.filter(s => {
    if (s.optional) return false;
    return !uncompleted.find(_s => _s.field === s.field);
  });

  const handleStepClick = (step: IStep) => {
    setActiveStep(steps.indexOf(step));
    // Setting active step triggers a layout change, so we need to wait for it
    setTimeout(() => {
      document.getElementById(step.field)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 800);
    onClose();
  };

  const submitReview = () => {
    setLoading(true);
    if (isAppReviewed && uncompleted.length > 0) {
      api
        .patchExistingReview(name, reviewId as string, formReview)
        .then(onSuccess)
        .catch(setError)
        .finally(() => setLoading(false));
      return;
    }
    api
      .submitNewReview(name, formReview)
      .then(onSuccess)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    // Reset the dialog when it's toggled on again
    if (toggle && error) {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  return (
    <Dialog open={toggle}>
      <DialogTitle>
        {isAppReviewed
          ? 'Submit updated application review'
          : 'Submit new application review'}
      </DialogTitle>
      {loading && (
        <DialogContent style={{ textAlign: 'center' }}>
          <CircularProgress />
        </DialogContent>
      )}

      {!loading && !error && (
        <>
          <DialogContent>
            <Alert severity="warning" variant="outlined">
              <Typography variant="body2">
                {isAppReviewed && uncompleted.length > 0
                  ? `You have completed only a partial review and only the submitted
                sections will be updated. The sections you did not update will
                remain the same as the previous valid review.
                Keep in mind: the "review date" will only be changed once
                a full review is submitted. Are you sure you want to continue?`
                  : `Are you sure you want to submit a new review for
                ${name} ?`}
              </Typography>
            </Alert>
            {isAppReviewed && (
              <Box mt="1rem">
                <ul className="checklist success">
                  {completed.map(s => (
                    <li key={s.field}>
                      <Button
                        onClick={() => handleStepClick(s)}
                        style={StepButtonStyles}
                      >
                        {s.header}
                      </Button>
                    </li>
                  ))}
                </ul>
                <ul className="checklist idle">
                  {uncompleted.map(s => (
                    <li key={s.field}>
                      <Button
                        onClick={() => handleStepClick(s)}
                        style={StepButtonStyles}
                      >
                        {s.header}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={submitReview} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}

      {!loading && error && (
        <>
          <DialogContent>
            <Alert severity="error">
              <Typography>{error?.message}</Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Dismiss
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
