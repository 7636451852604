import React from 'react';

import { Theme, CircularProgress, styled, alpha } from '@material-ui/core';

const BackgroundBlocker = styled('div')((props: { theme: Theme }) => ({
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
  background: alpha(props.theme.palette.text.primary, 0.4),
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Blocker = () => {
  return (
    <BackgroundBlocker>
      <CircularProgress size="6rem" />
    </BackgroundBlocker>
  );
};
