import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({ id: 'support', params: [''] });

export const sectionRouteRef = createRouteRef({
  id: 'support',
  params: ['/:section'],
});

export const sectionSubjectRouteRef = createRouteRef({
  id: 'support',
  params: ['/:section/:subject'],
  // path: '/:section/:subject',
});
