import { EventTrackerProps } from '../../Tracking';

export const common: Pick<EventTrackerProps, 'plugin' | 'eventCategory'> = {
  plugin: 'plugin-catalog',
  eventCategory: 'Data classification chip',
};

export const clickDataClassification = (): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Data Classification chip',
});

export const hoverDataClassification = (): EventTrackerProps => ({
  ...common,
  interaction: 'onMouseEnter',
  eventAction: 'hover',
  eventLabel: `User hovered over the data classification chip in ${location.pathname}`,
});
