import React from 'react';

import { Tooltip, CommitIcon } from '../../../common';
import * as S from './styles';

type Props = {
  id: string;
  repo: string;
};

export function CommitTag({ id, repo }: Props) {
  return (
    <S.Text>
      <CommitIcon />

      <Tooltip title={`Commit ID: ${id}`} placement="bottom-start">
        <a
          href={`https://${repo}/commit/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {(id && id.slice(0, 6)) || 'n/a'}
        </a>
      </Tooltip>
    </S.Text>
  );
}
