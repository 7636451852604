import { useOrgReposAnalysisReports } from './useOrgReposAnalysisReports';

export const useAnalysisByOrgPercentage = (entity: IEntityGroup) => {
  const { reports } = useOrgReposAnalysisReports({ userTeams: [entity] });

  const entities = entity.relations?.filter(item => item.type === 'ownerOf');
  const entitiesNames = entities?.map(item => item.target.name);
  const total = entitiesNames?.length || 0;
  if (total === 0) return 0;

  const analysed = reports?.reduce((count, item) => {
    const orgAnalysis = Object.values(item.analysis || []).flat();
    const analysedRepositories = orgAnalysis.filter(analysis =>
      entitiesNames?.includes(analysis.repository.name),
    );
    return count + analysedRepositories.length;
  }, 0);

  return Math.floor((analysed / total) * 100);
};
