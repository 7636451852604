import React from 'react';
import { AboutField } from 'plugin-ui-components';
import { InfoCard } from '@backstage/core-components';
import { getFormattedDateTime } from 'plugin-ui-components';
import { ModelVersion } from '../../../api/interfaces/model_version';
import Grid from '@mui/material/Grid';
import { Box, Button, CardContent } from '@material-ui/core';
import { TextTag, TextWithLinkTag } from '../../tables/columns/Tags';
import ModelVersionDescription from '../ModelDetails/AbouFieldWithDialog';
import { Artifacts } from '../../ModelVersions/Artifacts';
import Stack from '@mui/material/Stack';

export interface BasicDetailsProps {
  modelVersion: ModelVersion;
  setSelectedVersionClicked: (value: boolean) => void;
}

export const ModelVersionOverview = ({
  modelVersion,
  setSelectedVersionClicked,
}: BasicDetailsProps) => {
  const lastUpdated = modelVersion?.updated_at
    ? modelVersion.updated_at
    : modelVersion.created_at;

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InfoCard
            title="Overview"
            action={
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => setSelectedVersionClicked(true)}
                >
                  Make Default For Model Card
                </Button>
              </Stack>
            }
          >
            <CardContent>
              <Grid container spacing={6}>
                <AboutField label="Name" xs={3} canCopy>
                  {modelVersion.name}
                </AboutField>
                <AboutField label="Last Updated" xs={3}>
                  {getFormattedDateTime(lastUpdated).replace(
                    'Europe/Berlin',
                    '',
                  )}
                </AboutField>
                <AboutField label="Stage" xs={3}>
                  <TextTag
                    text={modelVersion.stage}
                    title="Stage"
                    textTransform="upper"
                  />
                </AboutField>
                <AboutField label="Source Type" xs={3}>
                  <TextWithLinkTag
                    link={modelVersion.source_uri as string}
                    text={modelVersion.source_type || 'Not Available'}
                    title="Source Type"
                  />
                </AboutField>
                <ModelVersionDescription
                  label="Description"
                  description={modelVersion.description as string}
                  wordLimit={300}
                />
              </Grid>
            </CardContent>
          </InfoCard>
        </Grid>
        <Grid item xs={6}>
          <Artifacts artifacts={modelVersion.artifacts} />
        </Grid>
      </Grid>
    </Box>
  );
};
