import { Theme, styled } from '@material-ui/core';

export const ScrollableBox = styled('div')(
  (props: { theme: Theme; height?: string }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: props.height ?? '100%',
    overflow: 'auto auto',
    padding: props.theme.spacing(1, 0, 2, 1),

    scrollbarColor: props.theme.palette.background.default,

    ['&::-webkit-scrollbar']: {
      width: '8px',
    },

    ['&::-webkit-scrollbar-track']: {
      background: props.theme.palette.background.paper,
      borderLeft: `1px solid ${props.theme.palette.divider}`,
    },

    ['&::-webkit-scrollbar-thumb']: {
      backgroundColor: props.theme.palette.grey[400],
      width: '8px',
    },
  }),
);
