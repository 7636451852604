import React from 'react';
import { AutocompleteResourceFilter } from './FilterTypes';

export const EnumFieldFilter = React.memo(
  ({
    onChange,
    selected,
    enumField,
    label,
    id,
    loading,
  }: {
    onChange?: (value: string) => void;
    selected?: string;
    enumField?: any;
    label: string;
    id: string;
    loading: boolean;
  }) => {
    const enumOptions = Object.values(enumField).map(stage => ({
      label: stage,
      value: stage,
    }));

    enumOptions.unshift({ label: 'ALL', value: '' });

    return (
      <AutocompleteResourceFilter
        values={enumOptions as any}
        onChange={onChange}
        selected={selected}
        id={id}
        label={label}
        disabled={loading}
      />
    );
  },
);
