import React from 'react';
import {
  Box,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SummaryCard } from '../common';

export const PlaybooksSummary = () => {
  const category: IAppReview.ReviewCategory = 'Playbooks';
  const { entity } = useEntity();
  const api = useApi(applicationReviewApiRef);

  const { loading, value } = useAsync(
    () => api.getPlaybooks(entity.metadata.name),
    [entity],
  );

  return (
    <SummaryCard category={category}>
      <CardContent>
        {loading && <CircularProgress size={20} />}
        {!loading && !value?.length && (
          <Typography>
            No incident playbooks linked to application "
            {entity?.metadata?.name}" found.
          </Typography>
        )}
        {value?.map(item => (
          <Box mb={2}>
            <Typography>
              <Link to={item.url}>{item.name}</Link>
            </Typography>
            {item.expiry && (
              <Typography variant="body2">
                expires <time dateTime={item.expiry}>{item.expiry}</time>
              </Typography>
            )}
          </Box>
        ))}
      </CardContent>
    </SummaryCard>
  );
};
