import React from 'react';

type Setter = React.Dispatch<React.SetStateAction<IKioAppApiResponse>>;

interface FormContext {
  appForm: IKioAppApiResponse;
  setForm: Setter;
  disableForm: boolean;
}

export const ApplicationFormContext = React.createContext<FormContext>({
  appForm: {} as IKioAppApiResponse,
  setForm: () => {},
  disableForm: false,
});
