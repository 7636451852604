import { useEffect } from 'react';

export const NAME = 'name';
export const VERSION = 'version';

export const useQueryParams = ({
  queryParams,
  filteredLibs,
  filteredVersions,
}: {
  queryParams: URLSearchParams;
  filteredLibs: string[];
  filteredVersions: string[];
}) => {
  const updateQueryParams = (query: string, values: string[]) => {
    values.forEach(param => {
      if (!queryParams.getAll(query).includes(param)) {
        queryParams.append(query, param);
      }
    });

    window.history.replaceState({}, '', `?${queryParams.toString()}`);
  };

  useEffect(() => {
    updateQueryParams(NAME, filteredLibs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLibs]);

  useEffect(() => {
    updateQueryParams(VERSION, filteredVersions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredVersions]);
};
