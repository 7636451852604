import {
  discoveryApiRef,
  createApiFactory,
  createPlugin,
} from '@backstage/core-plugin-api';
import { CodacyApi, codacyApiRef } from './api';
import { oauth2ApiRef } from 'plugin-core';

export const plugin = createPlugin({
  id: 'plugin-code-quality',
  featureFlags: [{ name: 'code-quality' }],
  apis: [
    createApiFactory({
      api: codacyApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        authApi: oauth2ApiRef,
      },
      factory: ({ discoveryApi, authApi }) =>
        new CodacyApi({ discoveryApi, authApi }),
    }),
  ],
});
