import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/catalog-client';
import { ApplicationReview } from '../types';

export const applicationReviewApiRef = createApiRef<ApplicationReviewApi>({
  id: 'plugin.start-page.reviews',
});

export class ApplicationReviewApi {
  private readonly catalogApi: CatalogApi;

  constructor(options: {
    catalogApi: CatalogApi;
    userApi: Sunrise.ZalandoIdentityApi;
    configApi: ConfigApi;
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
  }) {
    this.catalogApi = options.catalogApi;
  }

  async getReviews(userTeams: IEntityGroup[]): Promise<ApplicationReview[]> {
    if (!userTeams.length) {
      return [];
    }

    const teamsIds = userTeams.map(t => t.spec.team_id);

    function makeFilter(team: string) {
      return {
        'metadata.lastReview.needsReview': 'true',
        'spec.owner': team,
        'spec.spec.active': 'true',
      };
    }

    const applications = await this.catalogApi
      .getEntities({
        filter: teamsIds.map(t => makeFilter(t)),
        limit: 3,
      })
      .then(d => d.items as IEntityApp[]);

    return applications.map((item): ApplicationReview => {
      const lastReview = item.metadata?.lastReview;
      return {
        applicationId: item.metadata.name,
        applicationName: item.spec?.name,
        lastReviewTime: lastReview?.time,
        lastReviewerName: lastReview?.reviewer?.name,
      };
    });
  }
}
