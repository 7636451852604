import React from 'react';
import { ScopeForm } from '../ScopeForm';

export function ScopeEditPage() {
  return (
    <article style={{ padding: '1.5rem 0rem' }}>
      <ScopeForm edit />
    </article>
  );
}
