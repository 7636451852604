import React from 'react';
import { Chip, Tooltip, useTheme } from '@material-ui/core';
import { hoverDataClassification } from './tracking';
import { EventTracker } from '../../Tracking';

export function DataClassificationChip({
  entity,
  clickable = false,
}: {
  entity: IEntityApp;
  clickable?: boolean;
}) {
  const theme = useTheme();

  function getValues(): [string, string, string] {
    const val = entity?.metadata?.lastReview?.dataClassification || '';
    switch (val) {
      case 'GREEN':
        return [
          val,
          theme.palette.success.main,
          'Public data: e.g. public website information, job descriptions, catalog info, press releases, etc.',
        ];
      case 'YELLOW':
        return [
          val,
          theme.palette.warning.light,
          'Internal Data: e.g. information in the corporate intranet, employee role, and organization details',
        ];
      case 'ORANGE':
        return [
          val,
          theme.palette.warning.dark,
          'Confidential Data: e.g. any personal or non-personal data (e.g. customer and/or employee data, live order/revenue statistics, financials, customer data shards/cubes, data lake)',
        ];
      case 'RED':
        return [
          val,
          theme.palette.error.main,
          'Critical Confidential Data: e.g. criminal conviction, ethnic/racial origin, health, religious, trade union membership, sexual orientation/activity, political opinions; customer payment details/instruments',
        ];
      default:
        return ['', theme.palette.success.main, ''];
    }
  }

  const [value, color, description] = getValues();

  if (!value) {
    return <></>;
  }

  return (
    <EventTracker {...hoverDataClassification()}>
      <Tooltip title={description}>
        <Chip
          label={value && `data:${value.toLowerCase()}`}
          size="small"
          variant="outlined"
          style={{ borderColor: color }}
          clickable={clickable}
        />
      </Tooltip>
    </EventTracker>
  );
}
