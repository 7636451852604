/**
 * ATTENTION: Due to errors while using same methods for
 * backend and frontend packages, this method is
 * duplicated in packages/backend/utils. So if changes
 * are needed in this method, please change it in
 * packages/backend/utils as well.
 *
 * Extracts the name of the team or its parent name from the team's full name
 * @example ```ts
 * // To return the team's name
 * getTeamNameFromFullName('Developer Productivity / Knowledge Management');
 * // returns "Knowledge Management"
 *
 * To return the team parent's name
 * getTeamNameFromFullName('Developer Productivity / Knowledge Management', 'parentName');
 * // returns "Developer Productivity"
 * ```
 * @param fullName The full name of the team
 * @param [type=teamName] Values can be "teamName" or "parentName". Defaults to "teamName"
 */
export function getTeamNameFromFullName(
  fullName: string,
  type?: 'teamName' | 'parentName',
): string {
  const [department, teamName] = fullName?.split('/') || [];
  if (type === 'parentName') {
    return (department || teamName || '').trim();
  }
  return (teamName || department || '').trim();
}

/**
 * Joins a array of strings by comma's and `and` keyword before the last
 * @param arr The array of strings
 */
export function joinWithAnd(arr: string[]): string {
  return arr.reduce((acc, item, index) => {
    if (index === 0) return item;
    else if (index === arr.length - 1) return `${acc} and ${item}`;
    return `${acc}, ${item}`;
  }, '');
}

export const slugify = (title: string) => {
  return (
    title
      .trim()
      .toLowerCase()
      // Replaces all non-alphanumeric characters with hyphens
      .replace(/[^a-zA-Z0-9]/g, '-')
      // Replaces all multiple hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Remove leading and trailing hyphens
      .replace(/^-|-$/g, '')
  );
};
