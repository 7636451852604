import React from 'react';
import { Progress } from '@backstage/core-components';
import { Link } from '@material-ui/core';
import { useLatestPipeline } from '../../../hooks';

type MsgProps = {
  msg?: string;
  domain: string;
  org: string;
  repo: string;
  branch: string;
};

const DEFAULT_MESSAGE =
  'Only one push pipeline can run at a time per protected branch.';

export function PendingStateMessage({
  msg = DEFAULT_MESSAGE,
  domain,
  org,
  repo,
  branch,
}: MsgProps) {
  const { loading, error, pipeline } = useLatestPipeline(
    domain,
    org,
    repo,
    branch,
    'push',
    'IN_PROGRESS',
  );

  if (loading) return <Progress />;

  if (!!error || !pipeline) return <div>{msg}</div>;

  return (
    <div>
      <span>&nbsp;Pipeline is waiting for&nbsp;</span>
      <Link href={`/cdp/pipeline/${pipeline.id}`}>
        {pipeline.build_version}
      </Link>
      <span>&nbsp;to finish.</span>
    </div>
  );
}
