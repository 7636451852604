import React from 'react';
import { ChatIcon } from '@backstage/core-components';
import { EventTracker } from 'plugin-ui-components';
import { Button, ButtonGroup, Tooltip } from '@material-ui/core';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';
import PreviewIcon from '@mui/icons-material/Preview';
import { useStyles } from './styles';
import { Tracking } from '../../tracking';
import { ProductOrService } from './types';

export function RowActions(props: ProductOrService) {
  const classes = useStyles();

  return (
    <ButtonGroup size="small" variant="text" className={classes.buttonIcon}>
      <EventTracker {...Tracking.viewLink(`${props.title} > Interface`)}>
        <Tooltip
          title={
            props.interface === ''
              ? "A link to the tooling's interface\n"
              : 'Interface'
          }
        >
          <span>
            <Button
              color="primary"
              disabled={props.interface === ''}
              target="_blank"
              href={props.interface || '#'}
              startIcon={<PreviewIcon fontSize="large" viewBox="0 0 22 22" />}
            />
          </span>
        </Tooltip>
      </EventTracker>
      <EventTracker {...Tracking.viewLink(`${props.title} > Training`)}>
        <Tooltip
          title={
            props.training === ''
              ? 'A link for Training has not been provided. You can contact the Owning Team\n' +
                'to request it via the Support link (if active) or via email (which is visible from their team page)'
              : 'Training'
          }
        >
          <span>
            <Button
              color="primary"
              disabled={props.training === ''}
              target="_blank"
              href={props.training || '#'}
              startIcon={<SchoolIcon fontSize="large" viewBox="0 0 22 22" />}
            />
          </span>
        </Tooltip>
      </EventTracker>
      <EventTracker {...Tracking.viewLink(`${props.title} > Contact support`)}>
        <Tooltip
          title={
            props.support === ''
              ? 'A link for Contact support has not been provided. You can contact the Owning Team\n' +
                'via email (which is visible from their team page)'
              : 'Contact support'
          }
        >
          <span>
            <Button
              color="primary"
              disabled={props.support === ''}
              target="_blank"
              href={props.support || '#'}
            >
              <ContactSupportIcon />
            </Button>
          </span>
        </Tooltip>
      </EventTracker>
      <EventTracker {...Tracking.viewLink(`${props.title} > Documentation`)}>
        <Tooltip
          title={
            props.url === ''
              ? 'A link for Documentation has not been provided. You can contact the Owning Team\n' +
                'to request it via the Support link (if active) or via email (which is visible from their team page)'
              : 'Documentation'
          }
        >
          <span>
            <Button
              color="primary"
              disabled={props.url === ''}
              target="_blank"
              href={props.url || '#'}
            >
              <LibraryBooksIcon />
            </Button>
          </span>
        </Tooltip>
      </EventTracker>
      <EventTracker {...Tracking.viewLink(`${props.title} > Community chat`)}>
        <Tooltip
          title={
            props.chat === ''
              ? 'A link for Community chat has not been provided. You can contact the Owning Team\n' +
                'to request it via the Support link (if active) or via email (which is visible from their team page)'
              : 'Community chat'
          }
        >
          <span>
            <Button
              color="primary"
              disabled={props.chat === ''}
              target="_blank"
              href={props.chat || '#'}
            >
              <ChatIcon />
            </Button>
          </span>
        </Tooltip>
      </EventTracker>
    </ButtonGroup>
  );
}
