import React from 'react';
import { createApp } from '@backstage/app-defaults';
import * as plugins from './plugins';
import { apis } from './apis';
import {
  ApplicationsScorecardsContextProvider,
  ReferenceContextProvider,
  themes,
} from 'plugin-ui-components';
import { routes } from './routes';
import Root from './components/Root';
import { WithSentry } from './tracking/WithSentry';
import { WithTracing } from './tracking/WithTracing';
import { OAuthRequestDialog } from '@backstage/core-components';
import CustomSignInPage from './components/CustomSignInPage';
import { oauth2ApiRef, queryClient } from 'plugin-core';
import { AppRouter } from '@backstage/core-app-api';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';
import { SearchContextProvider } from 'plugin-search';
import { QueryClientProvider } from 'react-query';

/**
 * @note: @Backstage/core-plugin-api 1.5.0 introduced an experimental feature called __experimentalConfigure,
 * that need a new parameter called __experimentalReconfigure,
 * but even if mentioned as optional and that "it will have breaking changes in the future",
 * Backstage require it as mandatory as parameter for all the plugins.
 * In order to avoid the error, as temporary solution we add "as any" to the parameter "plugins: Object.values(plugins)"
 * @link https://github.com/backstage/backstage/blob/67830969eb9af3dbc4b5149e21904b3b6f1f3f08/packages/core-plugin-api/CHANGELOG.md#105
 */

const app = createApp({
  apis,
  themes,
  plugins: Object.values(plugins) as any,
  components: {
    SignInPage: props => (
      <CustomSignInPage
        provider={{
          id: 'auth2-provider',
          title: 'Zalando',
          message: "Sign-in using Zalando's SSO",
          apiRef: oauth2ApiRef,
        }}
        {...props}
      />
    ),
  },
  featureFlags: [
    {
      pluginId: '',
      name: 'tech-insights',
    },
  ],
});

export default app.createRoot(
  <WithSentry>
    <WithTracing>
      <OAuthRequestDialog />
      <ReferenceContextProvider>
        <AppRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <SearchContextProvider>
              <QueryClientProvider client={queryClient}>
                <ApplicationsScorecardsContextProvider>
                  <Root>{routes}</Root>
                </ApplicationsScorecardsContextProvider>
              </QueryClientProvider>
            </SearchContextProvider>
          </QueryParamProvider>
        </AppRouter>
      </ReferenceContextProvider>
    </WithTracing>
  </WithSentry>,
);
