import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BarChartIcon from '@material-ui/icons/BarChart';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab';
import { useAutocompleteStyles } from '../../../styles';
import { GRAPH_TYPE, SORT_ORDER } from './helpers';
import { StyledToggleButton } from '../../../StyledToggleButton';

export const PlotSettings = ({
  allXAxisOptions,
  selectedXAxisOption,
  setSelectedXAxisOption,
  graphType,
  setGraphType,
  sortOrder,
  setSortOrder,
  allMetricKeys,
  selectedMetrics,
  setSelectedMetrics,
}: {
  allXAxisOptions: Array<any>;
  selectedXAxisOption: any;
  setSelectedXAxisOption: React.Dispatch<any>;
  graphType: GRAPH_TYPE;
  setGraphType: React.Dispatch<any>;
  sortOrder: SORT_ORDER;
  setSortOrder: React.Dispatch<any>;
  allMetricKeys: Array<any>;
  selectedMetrics: Array<any>;
  setSelectedMetrics: React.Dispatch<any>;
}) => {
  const autocompleteClasses = useAutocompleteStyles();

  const onAutocompleteChange = (
    _: React.ChangeEvent<{}>,
    options: Array<any> | null,
  ) => {
    const selectedOptions = options || [];
    setSelectedMetrics(selectedOptions);
  };

  const onXAxisAutocompleteChange = (
    _: React.ChangeEvent<{}>,
    option: Array<any> | null,
  ) => {
    setSelectedXAxisOption(option);
  };

  const onChangeGraphType = (
    _: React.ChangeEvent<{}>,
    newGraphType: GRAPH_TYPE,
  ) => {
    if (newGraphType !== null) {
      setGraphType(newGraphType);
    }
  };

  const onChangeSortOrder = (
    _: React.ChangeEvent<{}>,
    newSortOrder: SORT_ORDER,
  ) => {
    if (newSortOrder !== null) {
      setSortOrder(newSortOrder);
    }
  };

  return (
    <>
      <Typography variant="h4">Plot Settings</Typography>
      <Box mb={4}>
        <Autocomplete
          key={uuidv4()}
          value={selectedMetrics}
          options={allMetricKeys}
          onChange={onAutocompleteChange}
          multiple
          renderInput={props => (
            <TextField
              {...props}
              label="Y-axis"
              variant="outlined"
              margin="dense"
              placeholder={
                selectedMetrics.length ? '' : 'Select metrics to plot'
              }
            />
          )}
        />
      </Box>
      <Box mb={4}>
        <Autocomplete
          key={uuidv4()}
          disableClearable
          value={selectedXAxisOption}
          options={allXAxisOptions}
          getOptionLabel={(option: any) => option.label}
          groupBy={(option: any) => option.group}
          onChange={onXAxisAutocompleteChange}
          renderInput={inputParams => (
            <TextField
              {...inputParams}
              label="X-axis"
              variant="outlined"
              margin="dense"
            />
          )}
          classes={autocompleteClasses}
        />
      </Box>
      <Grid container>
        <Grid xs={6} item>
          <FormControl>
            <label htmlFor="graph-type-setting">Graph Type</label>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={graphType}
              id="graph-type-setting"
              onChange={onChangeGraphType}
            >
              <StyledToggleButton value={GRAPH_TYPE.LINE}>
                <Tooltip title="Line" placement="bottom">
                  <ShowChartIcon />
                </Tooltip>
              </StyledToggleButton>
              <StyledToggleButton value={GRAPH_TYPE.DOT}>
                <Tooltip title="Dot" placement="bottom">
                  <ScatterPlotIcon />
                </Tooltip>
              </StyledToggleButton>
              <StyledToggleButton value={GRAPH_TYPE.BAR}>
                <Tooltip title="Bar" placement="bottom">
                  <BarChartIcon />
                </Tooltip>
              </StyledToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
        <Grid xs={6} item>
          <FormControl>
            <label htmlFor="sort-order-setting">Sort Order</label>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={sortOrder}
              id="sort-order-setting"
              onChange={onChangeSortOrder}
            >
              <StyledToggleButton value={SORT_ORDER.TIME}>
                <Tooltip title="Chronological" placement="bottom">
                  <DoubleArrowIcon />
                </Tooltip>
              </StyledToggleButton>
              <StyledToggleButton value={SORT_ORDER.ASC}>
                <Tooltip title="Ascending" placement="bottom">
                  <ArrowUpwardIcon />
                </Tooltip>
              </StyledToggleButton>
              <StyledToggleButton value={SORT_ORDER.DESC}>
                <Tooltip title="Descending" placement="bottom">
                  <ArrowDownwardIcon />
                </Tooltip>
              </StyledToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
