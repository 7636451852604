import React from 'react';
import { Route, Routes } from 'react-router';
import { ApplicationAccessControlPage } from './components/ApplicationAccessControlPage';

export function ApplicationAccessControlPageRouter({
  appName,
}: {
  appName: string;
}) {
  return (
    <Routes>
      <Route
        path="/"
        element={<ApplicationAccessControlPage appName={appName} />}
      />
    </Routes>
  );
}
