import {
  Card,
  styled,
  Typography,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import OpenInNew from '@material-ui/icons/OpenInNew';

export const Wrapper = styled(Card)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
  boxShadow: 'none',
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0, 0, 1),
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 'bold',
}));

export const GroupWrapper = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1, 1, 2, 1),
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minHeight: theme.spacing(6),
}));

export const MenuTitle = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 30,
  color: theme.palette.text.primary,
}));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: 'left',
    },
    tableWrapper: {
      width: '100%',
    },
    columnHeader: {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    table: {
      '& th': {
        fontWeight: 700,
        color: 'inherit',
        paddingBlock: '1rem',
        wordBreak: 'keep-all',
        '& > button': {
          backgroundColor: 'transparent',
          padding: 0,
          border: 0,
          fontWeight: 'bold',
          display: 'inline-flex',
          color: theme.palette.text.primary,
          '&:active': {
            color: theme.palette.grey.A700,
          },
          '&:hover .sort-icon': {
            opacity: 1,
            '&:not(.sorted)': {
              color: theme.palette.text.disabled,
              fontWeight: 'normal',
            },
          },
          '&:not(:disabled)': {
            cursor: 'pointer',
          },
        },
      },
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        '&:nth-of-type(2n+1)': {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    tableHeader: {
      padding: theme.spacing(2),
    },
  }),
);

export const InfoIcon = styled(InfoOutlined)(() => ({
  fontSize: '1em',
  verticalAlign: 'bottom',
}));

export const OpenInNewIcon = styled(OpenInNew)(() => ({
  fontSize: '.8em',
  verticalAlign: 'middle',
}));
