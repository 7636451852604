import {
  Theme,
  styled,
  ListItem,
  Card as MaterialCard,
} from '@material-ui/core';

export const Card = styled(MaterialCard)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const StyledGridRow = styled(ListItem)(
  ({
    theme,
  }: any & {
    theme: Theme;
  }) => ({
    padding: theme.spacing(1, 0),
    display: 'grid',
    gridTemplateColumns: '100%',

    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'var(--grid-template)',
      gridGap: '5px',
      alignItems: 'flex-start',
    },
  }),
);

export const StyledGridColumn = styled('li')(
  ({
    theme,
    header,
    item,
    spaced,
  }: {
    theme: Theme;
    header?: boolean;
    item?: boolean;
    spaced?: boolean;
  }) => ({
    overflow: 'unset',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...(item && { padding: theme.spacing(0, 1) }),
    ...(header && { padding: theme.spacing(1) }),
    ...(spaced && { marginLeft: theme.spacing(2.4) }),
  }),
);
