import React from 'react';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SectionCaption } from '../common/SectionCaption';
import { SectionCheckbox } from '../common/SectionCheckbox';
import { ReviewContext } from '../context';
import {
  Criticality,
  Description,
  DocumentationUrl,
  IncidentContact,
  OwningTeam,
  ID,
  Name,
  ScmUrl,
  ServiceUrl,
  SpecificationUrl,
  Status,
  Subtitle,
  SupportUrl,
} from './fields';
import { FieldProps } from './types';

export function ApplicationMetadata() {
  const { entity } = useEntity<IEntityApp>();
  const { issues } = React.useContext(ReviewContext);

  function getProps<K extends keyof IEntitySpec>(name: K): FieldProps<K> {
    return {
      value: entity.spec?.spec?.[name] || (undefined as any),
      issue: issues.find(issue => issue.field === name),
    };
  }

  return (
    <section>
      <dl>
        <ID {...getProps('id')} />
        <Name {...getProps('name')} />
        <Subtitle {...getProps('subtitle')} />
        <Status {...getProps('active')} />
        <Criticality {...getProps('criticality_level')} />
        <OwningTeam {...getProps('team_id')} value={entity.spec?.owner} />
        <IncidentContact {...getProps('incident_contact')} />
        <Description {...getProps('description')} />
        <ServiceUrl {...getProps('service_url')} />
        <ScmUrl {...getProps('scm_url')} />
        <DocumentationUrl {...getProps('documentation_url')} />
        <SpecificationUrl {...getProps('specification_url')} />
        <SupportUrl {...getProps('support_url')} />
      </dl>

      <SectionCheckbox name="kio_ok" />

      <SectionCaption>
        You can edit these information in the <Link to="../edit">edit tab</Link>
      </SectionCaption>
    </section>
  );
}
