import { createApiRef } from '@backstage/core-plugin-api';
import { EventType, EventTypeStats } from '../../domain/EventTypes';
import { NakadiApiGateway } from '../../gateway/NakadiApiGateway/NakadiApiGateway';
import { NakadiReporterGateway } from '../../gateway/NakadiReporterGateway/NakadiReporterGateway';

export interface EventTypesManager {
  getEventTypes(applicationName: string): Promise<EventType[]>;
  getPublishingStats(eventType: EventType): Promise<EventTypeStats>;
}

export const eventTypesApiRef = createApiRef<EventTypesManager>({
  id: 'plugin.nakadi.nakadi-event-types-api',
});

export class EventTypesManagerImpl implements EventTypesManager {
  private readonly nakadiApiGateway;
  private readonly nakadiReporterGateway;

  constructor(options: {
    nakadiApiGateway: NakadiApiGateway;
    nakadiReporterGateway: NakadiReporterGateway;
  }) {
    this.nakadiApiGateway = options.nakadiApiGateway;
    this.nakadiReporterGateway = options.nakadiReporterGateway;
  }

  async getEventTypes(applicationName: string) {
    const [byWriter, byStupsOwningApp, byOwningApp] = await Promise.all([
      this.nakadiApiGateway.getEventTypesByWriter(`stups_${applicationName}`),
      this.nakadiApiGateway.getEventTypesByOwningApp(
        `stups_${applicationName}`,
      ),
      this.nakadiApiGateway.getEventTypesByOwningApp(applicationName),
    ]);

    return [...byWriter, ...byStupsOwningApp, ...byOwningApp]
      .filter((et, index, self) => {
        return self.findIndex(_et => _et.name === et.name) === index;
      })
      .sort((etA, etB) => {
        if (etA.name.toUpperCase() < etB.name.toUpperCase()) {
          return -1;
        }
        if (etA.name.toUpperCase() > etB.name.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }

  async getPublishingStats(eventType: EventType) {
    const THIRTY_ONE_DAYS = 31;
    const starTime = new Date();
    starTime.setDate(starTime.getDate() - THIRTY_ONE_DAYS);

    const publishingStatsRequest =
      this.nakadiReporterGateway.getEventTypePublishingStats(
        eventType.name,
        starTime,
      );

    const partitionsRequest = this.nakadiApiGateway.getPartitions(
      eventType.name,
    );

    const [publishingStats, partitions] = await Promise.all([
      publishingStatsRequest,
      partitionsRequest,
    ]);

    return {
      ...publishingStats,
      partition_count: partitions.length,
      costs: (publishingStats.total_events_count / 1e6) * 0.15,
    };
  }
}
