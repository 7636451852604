import React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { useServices } from '../../../../../services';
import { RepositoryModel } from '../../../../../models';

export const RepositoryFilter = observer(() => {
  const {
    repositoriesOrganizationPageService: repositoriesService,
    filteringOrganizationPageService: filteringService,
  } = useServices();

  const repositoriesToOptions = (repositories: Array<RepositoryModel>) =>
    repositories.map(item => ({ label: item.name, value: item.fullPath }));

  const optionsToRepositories = (repositories: Array<IOption>) =>
    repositories.map(item => RepositoryModel.fromFullPath(item.value));

  const getOptionSelected = (option: IOption, value: IOption) => {
    return option.value === value.value;
  };

  const handleChange = (
    selectedOptions: Array<{ label: string; value: string }>,
  ) => {
    filteringService.persistHelper.enablePersist();
    filteringService.repository.setUserSelectedRepositories(
      optionsToRepositories(selectedOptions),
    );
  };

  return (
    <AutoComplete
      onChange={handleChange}
      isLoading={repositoriesService.repositoryTreeState.isLoading}
      hasError={repositoriesService.repositoryTreeState.hasError}
      options={repositoriesToOptions(filteringService.repository.repositories)}
      defaultSelection={repositoriesToOptions(
        filteringService.repository.userSelectedRepositories,
      )}
      getOptionSelected={getOptionSelected}
      uniqueId="repositories-for-organisation"
      placeholder="Filter repositories"
      noOptionsText="No repositories"
      noOptionsErrorText="Error occurred while fetching repositories"
      inputPlaceholder="Filter repositories"
    />
  );
});
