import React from 'react';
import {
  EntityLoadingStatus,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync, useSearchParam } from 'react-use';
import { useAppData, useForm } from '../../hooks';
import { Team } from '../../types';
import {
  criticalityLevels,
  formatAllTeamsOuput,
  FormattedTeam,
  oauth2ApiRef,
  ValueLabelPair,
} from 'plugin-core';
import {
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { ApplicationFormContext } from './context';
import { FormState, FormStatus } from './FormStatus';
import { Input } from './Input';
import { Autocomplete } from './Autocomplete';
import { CompareChanges } from '../CompareChanges';
import { idTakenErrorMessage } from './config';
import * as S from './styles';
import { Link } from '@backstage/core-components';
import { catalogAdditionalApiRef } from 'plugin-catalog';
import {
  EventTracker,
  EventTrackerProps,
  PluginTracking,
  useAllTeams,
  useUser,
  useUserTeams,
} from 'plugin-ui-components';

interface ApplicationFormProps {
  useEntityState?: EntityLoadingStatus<IEntityApp>;
}

const SuccessMessage = (props: {
  completed: boolean;
  type: 'add' | 'edit';
  id: string;
}) => {
  const { completed, type, id } = props;
  const linkTracking: EventTrackerProps = {
    plugin: 'application-registry',
    eventCategory: 'Create Application form',
    interaction: 'onClick',
    eventAction: 'click',
    eventLabel: '',
  };
  if (completed) {
    const action = type === 'add' ? 'created' : 'updated';
    return (
      <Typography>
        Application was successfully {action}.
        <EventTracker {...linkTracking} eventLabel="View application details">
          <Link to={`/catalog/default/Component/${id}`} color="primary">
            View its details
          </Link>
        </EventTracker>{' '}
        or continue to{' '}
        <EventTracker {...linkTracking} eventLabel="Register ML Project">
          <Link to="/ml/register" color="primary">
            register a Machine Learning Project.
          </Link>
        </EventTracker>
        .
      </Typography>
    );
  }
  return (
    <Typography>
      {type === 'edit' && (
        <>
          Application was successfully edited but the synchronization in the
          catalog can take up to 10 minutes to finalize. Then refresh the{' '}
          <Link to={`/catalog/default/Component/${id}`} color="primary">
            Application details page
          </Link>{' '}
          to see if it's updated.
        </>
      )}
      {type === 'add' && (
        <>
          Application was successfully added but the creation in the catalog can
          take up to 10 minutes to finalize. Then refresh the{' '}
          <Link to="/applications" color="primary">
            Applications page
          </Link>{' '}
          to see if it's available.
        </>
      )}
      If it's not, please submit a{' '}
      <Link to="https://github.bus.zalan.do/pitchfork/issues/issues">
        support request
      </Link>
      .
    </Typography>
  );
};

export const ApplicationForm = ({ useEntityState }: ApplicationFormProps) => {
  const edit = !!useEntityState;
  const repoUrl = useSearchParam('repo_url');
  const { entity, refresh } =
    useEntityState || ({} as EntityLoadingStatus<IEntityApp>);
  const idWrapperRef = React.useRef<HTMLDivElement>();
  const discoveryApi = useApi(discoveryApiRef);
  const oauth2Api = useApi(oauth2ApiRef);
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);
  const { value: token } = useAsync(() => oauth2Api.getAccessToken());
  const [formState, setFormState] = React.useState<FormState>({ status: '' });
  const [dialogToggle, setDialogToggle] = React.useState(false);
  const { value: proxyApi } = useAsync(() => discoveryApi.getBaseUrl('proxy'));
  const spec = entity?.spec?.spec;
  const appData = useAppData(edit, entity);
  const [appForm, setForm] = useForm(appData);

  const { value: teams = [], loading: teamsLoading } =
    useAllTeams<FormattedTeam[]>(formatAllTeamsOuput);

  const {
    value: { memberTeams = [], ledTeams = [], accountableTeams = [] },
    loading: userTeamsLoading,
  } = useUserTeams({ include: { member: true, led: true, accountable: true } });

  // Check if user is owner of a Reporting Line
  const { value: user } = useUser();
  const isUserOwnerOfReportingLine =
    getEntityRelations(user, RELATION_OWNER_OF, {
      kind: 'reportingline',
    }).length > 0;

  const initialSupportType = spec?.support_url?.includes('@') ? 'email' : 'url';
  const [supportType, setSupportType] =
    React.useState<string>(initialSupportType);
  const styles = S.useStyles();
  const [idAvailable, setIdAvailable] = React.useState<boolean | undefined>();

  // Allows users who are accountable through reporting line to have edit access if they are accountable of owner team
  const isOwner = [
    ...memberTeams,
    ...ledTeams,
    ...(isUserOwnerOfReportingLine ? accountableTeams : []),
  ].some(team =>
    [team.spec.fullName, team.metadata.name].includes(
      entity?.spec?.owner || '',
    ),
  );

  const isLoading =
    formState?.status === 'loading' || userTeamsLoading || teamsLoading;
  const disableForm = isLoading || (edit && !isOwner);
  const teamChangeWarning = edit && spec?.team_id !== appForm.team_id;
  const deactivateWarning = spec?.active !== appForm.active && !appForm.active;

  const submitChanges = () => {
    setDialogToggle(false);
    setFormState({ status: 'loading' });

    // Remove owner_name property from IEntityAPPSpecSpec to obtain a clean IKioAppApiResponse form data
    const { id, owner_name, ...formBody } = appForm as IEntityAPPSpecSpec;

    // prevent Kio from create or update app with id undefined
    if (!id) {
      return;
    }

    const kioUrl = `${proxyApi}/kio/apps`;
    const appUrl = `${kioUrl}/${id}`;

    const app = Object.keys(formBody).reduce((acc, key) => {
      if ((formBody as any)[key] !== null || key === 'active') {
        return { ...acc, [key]: (formBody as any)[key] };
      }
      return acc;
    }, {});

    fetch(appUrl, {
      method: 'PUT',
      body: JSON.stringify(app),
      headers: new Headers({
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      }),
    }).then(async registerRes => {
      const entityRef = stringifyEntityRef({
        namespace: 'default',
        kind: 'Component',
        name: appForm.id,
      });
      if (registerRes.ok) {
        if (edit) {
          // Application was correctly edited so track the App edit
          PluginTracking.sendEvent({
            plugin: 'application-registry',
            eventCategory: `Application Edit`,
            eventLabel: `Application ${formBody.name}`,
            eventAction: `Changed application data`,
          });
          const refreshResponse = await catalogAdditionalApi.refreshEntity(
            entityRef,
            5,
          );

          const completed = !(
            !refreshResponse.refreshed || refreshResponse?.error
          );
          if (completed && refresh) {
            refresh();
          }
          setFormState({
            status: 'success',
            message: (
              <SuccessMessage completed={completed} type="edit" id={id} />
            ),
          });
        } else {
          // Application was correctly created so track the App creation
          PluginTracking.sendEvent({
            plugin: 'application-registry',
            eventCategory: `Application create`,
            eventLabel: `Application ${formBody.name}`,
            eventAction: `Created new application`,
          });

          // Force App Processor to try to immediately import the new created App.
          const importResponse = await catalogAdditionalApi.importEntity(
            entityRef,
          );
          const completed = !(!importResponse.added || importResponse?.error);
          setFormState({
            status: 'success',
            message: (
              <SuccessMessage completed={completed} type="add" id={id} />
            ),
          });
        }
      } else {
        if (registerRes.headers.get('content-type') === 'application/json') {
          const errorDetails = await registerRes.json();
          const details = [
            { name: 'Code', text: registerRes.status },
            { name: 'Type', text: registerRes.statusText },
            { name: 'Details', text: errorDetails?.message },
          ];
          setFormState({
            status: 'error',
            message: (
              <S.DetailsTable>
                <tbody>
                  {details
                    .filter(item => item.text)
                    .map(item => (
                      <tr key={item.name}>
                        <th>{item.name}</th>
                        <td>{item.text}</td>
                      </tr>
                    ))}
                </tbody>
              </S.DetailsTable>
            ),
          });
        } else {
          setFormState({ status: 'error' });
        }
        setFormState({ status: 'error', message: await registerRes.text() });
      }
    });
  };

  const checkIdAvailability = (): Promise<boolean> => {
    return new Promise(resolve => {
      const input = idWrapperRef.current?.querySelector('input');
      if (input) {
        if (input.checkValidity()) {
          const kioUrl = `${proxyApi}/kio/apps`;
          const appUrl = `${kioUrl}/${input.value}`;
          fetch(appUrl, {
            method: 'GET',
            headers: new Headers({
              authorization: `Bearer ${token}`,
            }),
          }).then(res => {
            setIdAvailable(!res.ok);
            if (res.ok) {
              input.setCustomValidity(idTakenErrorMessage);
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
        input.reportValidity();
      }
    });
  };

  React.useEffect(() => {
    if (repoUrl) setForm({ ...appForm, service_url: repoUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoUrl]);

  React.useEffect(() => {
    if (idAvailable !== undefined) {
      setIdAvailable(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appForm.id]);

  const onFormSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    if (edit) {
      setDialogToggle(true);
    } else {
      setFormState({ status: 'loading' });
      if (await checkIdAvailability()) {
        setFormState({ status: '' });
        setDialogToggle(true);
      } else {
        setFormState({
          status: 'error',
          message: idTakenErrorMessage,
        });
      }
    }
  };

  const teamIdValue =
    teams.find(team => [team.sapId, team.teamId].includes(appForm.team_id)) ||
    ({} as Team);

  const criticalityLevel =
    criticalityLevels.find(item => item.value === appForm.criticality_level) ||
    ({} as ValueLabelPair);

  return (
    <Container>
      <Card>
        <CardContent>
          {edit && !isOwner && (
            <Box marginBottom={2}>
              <Alert severity="warning">
                Only the owner can edit the application.
              </Alert>
            </Box>
          )}
          <S.Form onSubmit={onFormSubmit}>
            <ApplicationFormContext.Provider
              value={{ appForm, setForm, disableForm }}
            >
              <Typography variant="h3">
                Application Metadata
                <FormControlLabel
                  className={styles.fr}
                  control={
                    <Switch
                      checked={appForm.active}
                      onChange={ev =>
                        setForm({ ...appForm, active: ev.target.checked })
                      }
                      name="active"
                      disabled={
                        formState?.status === 'loading' || (edit && !isOwner)
                      }
                    />
                  }
                  label="Active"
                />
              </Typography>
              <Grid container spacing={3}>
                <Input name="name" />
                <Input
                  name="id"
                  disabled={edit || formState?.status === 'loading'}
                  InputProps={{
                    ref: idWrapperRef,
                    endAdornment: !edit && (
                      <InputAdornment position="end">
                        <Button
                          variant="outlined"
                          size="small"
                          className={styles.idCheckBtn}
                          onClick={checkIdAvailability}
                          disabled={idAvailable !== undefined}
                          data-available={idAvailable}
                        >
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {idAvailable === undefined ? (
                            'Check availability'
                          ) : idAvailable ? (
                            <CheckIcon />
                          ) : (
                            <CloseIcon />
                          )}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <Input name="subtitle" />
                <Autocomplete<Team>
                  data-testid="team-id-autocomplete"
                  name="team_id"
                  options={
                    !edit
                      ? formatAllTeamsOuput(memberTeams.concat(ledTeams))
                      : teams
                  }
                  value={teamIdValue}
                  getOptionLabel={(o: Team) =>
                    o.teamName && o.teamId
                      ? `${o.teamName} - ${o.teamId}`
                      : o.teamName || o.teamId || ''
                  }
                  getValueOnChange={v => v?.teamId || (undefined as any)}
                />
                <Input name="description" multiline />
              </Grid>
              <br />
              <br />
              <Typography variant="h3">
                Links to Resources related to this Application
              </Typography>
              <Grid container spacing={3}>
                <Input type="url" name="service_url" />
                <Input type="url" name="scm_url" />
                <Input
                  type={supportType}
                  name="support_url"
                  placeholder={
                    supportType === 'email'
                      ? 'e.g. pitchfork@zalando.de'
                      : 'e.g. https://github.bus.zalan.do/pitchfork/sunrise/issues/new/choose'
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        className={styles.adornmentNoMargin}
                      >
                        <Select
                          className={styles.selectNoBorder}
                          value={supportType}
                          onChange={ev =>
                            setSupportType(ev.target.value as string)
                          }
                          disabled={disableForm}
                        >
                          <MenuItem value="url">URL</MenuItem>
                          <MenuItem value="email">Email</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <Input type="url" name="documentation_url" />
                <Input type="url" name="specification_url" />
              </Grid>
              <br />
              <br />
              <Typography variant="h3" id="reliability">
                Reliability
              </Typography>
              <Grid container spacing={3}>
                <Autocomplete<ValueLabelPair>
                  name="criticality_level"
                  options={criticalityLevels}
                  value={criticalityLevel}
                  getOptionLabel={(o: ValueLabelPair) => o?.label || ''}
                  getValueOnChange={v => v?.value || (undefined as any)}
                />
                <Input name="incident_contact" />
              </Grid>
              <div className={styles.p_1_0}>
                <FormStatus {...formState} />
                <br />
                <div className={styles.center}>
                  <Button
                    disabled={isLoading || disableForm}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={styles.mr_1}
                  >
                    Submit
                    {formState?.status === 'loading' && (
                      <CircularProgress className={styles.ml_1} size="1em" />
                    )}
                  </Button>
                </div>
              </div>
            </ApplicationFormContext.Provider>
          </S.Form>
          <Dialog onClose={() => setDialogToggle(false)} open={dialogToggle}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              {deactivateWarning && (
                <Box paddingBottom={2}>
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <p>
                      Please confirm that you want to DEACTIVATE this
                      application.
                    </p>
                    <p>
                      Doing so will prevent any deployments to happen and all
                      access scopes will be removed from this application.
                    </p>
                  </Alert>
                </Box>
              )}
              {teamChangeWarning && (
                <Box paddingBottom={2}>
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    Please confirm that you want to TRANSFER the ownership of
                    this application. You will not be able to undo this. Once
                    ownership has been transferred, only members of that team
                    will be able to edit application metadata, activate or
                    deactivate it, or transfer ownership.
                  </Alert>
                </Box>
              )}
              <DialogContentText>
                Are you sure you want to submit your changes?
              </DialogContentText>
              {edit && spec && (
                <CompareChanges original={spec} updated={appForm} />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogToggle(false)}>Cancel</Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={submitChanges}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </Container>
  );
};
