import React, { useMemo } from 'react';

import { TestHeader } from './TestHeader';
import { TagSmall } from '../../../../../common';
import { TestFold } from './TestFold';
import {
  getColorByStatus,
  generateKey,
  getSummary,
  parseNanoTime,
} from './utils';
import { IJUnitTestResult } from '../../../../../api/types/payload';

import * as S from './styles';

type Props = {
  results: IJUnitTestResult[];
  stepId: string;
};

function areEqual(prevProps: Props, nextProps: Props) {
  return prevProps.stepId === nextProps.stepId;
}

function JUnitTestFoldsComponent({ results, stepId }: Props) {
  const { statuses, duration, total, length } = getSummary(results);

  const resultItems = useMemo(() => {
    return results.map((item, i) => (
      <TestFold data={item} key={`${item.name}_${item.totals.duration}_${i}`} />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  const tags = useMemo(() => {
    return Object.keys(statuses).reduce((acc, key) => {
      if (statuses[key]) {
        acc.push(
          <TagSmall color={getColorByStatus(key)} key={generateKey(key)}>
            {`${statuses[key]} ${key}`}
          </TagSmall>,
        );
      }

      return acc;
    }, [] as React.ReactNode[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  return (
    <S.JUnitTestFolds>
      <S.Title>
        <span>JUnit test suites</span>
        {tags}
      </S.Title>

      <S.Subtitle>
        <span>Tests ran in&nbsp;</span>
        <b>{parseNanoTime(duration)},&nbsp;</b>
        <b>{length}</b>
        <span>&nbsp;testsuites,&nbsp;</span>
        <b>{total}</b>
        <span>&nbsp;testcases</span>
      </S.Subtitle>

      <TestHeader statuses={statuses} total={total} />

      <S.TestFolds>{resultItems}</S.TestFolds>
    </S.JUnitTestFolds>
  );
}

export const JUnitTestFolds = React.memo(JUnitTestFoldsComponent, areEqual);
