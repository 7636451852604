import React, { useEffect } from 'react';
import { Model } from '../../../api/interfaces/model';
import { InfoCard } from '@backstage/core-components';
import {
  AboutField,
  colors,
  TeamReference,
  useUserTeams,
  useUserTeamsApps,
} from 'plugin-ui-components';
import {
  ApplicationTag,
  TextTag,
  TextWithLinkTag,
  UserTag,
} from '../../tables/columns/Tags';
import { Box, Button, CardContent, LinearProgress } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import AboutFieldWithDialog from './AbouFieldWithDialog';
import Grid from '@mui/material/Grid';
import { Link } from '../../../api/interfaces/model_card';
import { ModelVersion } from '../../../api/interfaces/model_version';
import { useApi } from '@backstage/core-plugin-api';
import { modelsApiRef } from '../../../api/services/models';

export interface BasicDetailsProps {
  model: Model;
  loading?: boolean;
  links?: Link[];
  inputFormat: string | undefined;
  outputFormat: string | undefined;
}

export const ModelOverview = ({
  model,
  loading = false,
  links,
  inputFormat,
  outputFormat,
}: BasicDetailsProps) => {
  const [modelVersion, setModelVersion] = React.useState<ModelVersion>();
  const modelsApi = useApi(modelsApiRef);

  const {
    value: { userTeams = [] },
  } = useUserTeams();
  const { value: userApps = [] } = useUserTeamsApps();

  const modelOwnersTeams = userTeams.filter(team =>
    userApps.some(
      app =>
        app.metadata.name === model.application_id &&
        app.spec.spec.team_id === team.spec.id,
    ),
  );

  useEffect(() => {
    if (model.selected_version_for_model_card.toLowerCase() === 'latest') {
      modelsApi
        .getLatestModelVersionForModel(String(model.model_id))
        .then(response => setModelVersion(response));
    } else {
      modelsApi
        .getModelVersionById(
          model.selected_version_for_model_card,
          String(model.model_id),
        )
        .then(response => setModelVersion(response));
    }
  }, [model.selected_version_for_model_card, model.model_id, modelsApi]);

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InfoCard
            title="Overview"
            action={
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled
                >
                  Export as PDF
                </Button>
              </Stack>
            }
          >
            <CardContent>
              {loading ? (
                <LinearProgress />
              ) : (
                <Grid container spacing={2}>
                  <AboutField
                    label="Model Name"
                    children={model.name}
                    xs={2}
                    canCopy
                  />
                  <AboutField
                    label="Created at"
                    children={model.created_at}
                    xs={2}
                  />
                  {model.updated_at && (
                    <AboutField
                      label="Updated at"
                      children={model.updated_at}
                      xs={2}
                    />
                  )}
                  <AboutField label="Owning Team" xs={2}>
                    {modelOwnersTeams.map(team => (
                      <TeamReference
                        key={team.metadata.name}
                        team={team as IEntityGroup}
                      />
                    ))}
                  </AboutField>
                  <AboutField
                    label="Created By"
                    children={<UserTag user={model.created_by.uid} />}
                    xs={2}
                  />
                  <AboutField label="Deployment Environment" xs={2}>
                    <TextWithLinkTag
                      text={model.deployment_environment.name as string}
                      link={`/infrastructure/${model.deployment_environment.cluster_registry_id}`}
                      title="License"
                    />
                  </AboutField>
                  <AboutField label="License" xs={2}>
                    <TextWithLinkTag
                      text={model.license_type as string}
                      link={model.license_link as string}
                      title="License"
                    />
                  </AboutField>
                  <AboutField
                    label="Application"
                    children={<ApplicationTag name={model.application_id} />}
                    xs={2}
                  />
                  <AboutField label="Origin" xs={2}>
                    <TextTag
                      text={model.origin}
                      title="Origin"
                      color={colors.semantic.infoDark}
                      textTransform="upper"
                    />
                  </AboutField>
                  <AboutField label="Input Format" xs={2}>
                    <TextTag
                      text={inputFormat as string}
                      title="Input Format"
                      color={colors.semantic.infoDark}
                      textTransform="upper"
                    />
                  </AboutField>
                  <AboutField label="Output Format" xs={2}>
                    <TextTag
                      text={outputFormat as string}
                      title="Output Format"
                      color={colors.semantic.infoDark}
                      textTransform="upper"
                    />
                  </AboutField>
                  <AboutField label="Selected Version" xs={3}>
                    {modelVersion ? (
                      <TextWithLinkTag
                        text={modelVersion.name}
                        link={`/mlp/models/${model.model_id}/versions/${modelVersion.model_version_id}?model=${model.name}`}
                        title="Selected Version"
                      />
                    ) : (
                      <TextTag
                        text={model.selected_version_for_model_card}
                        title="Selected Version"
                        color={colors.semantic.infoDark}
                        textTransform="upper"
                      />
                    )}
                  </AboutField>
                  {links && (
                    <AboutField label="Links" xs={3}>
                      {links.map((link, index) => (
                        <TextWithLinkTag
                          key={index}
                          text={link.title}
                          link={link.url}
                          title={link.title}
                        />
                      ))}
                    </AboutField>
                  )}
                  <AboutFieldWithDialog
                    label="Description"
                    description={model.description as string}
                  />
                </Grid>
              )}
            </CardContent>
          </InfoCard>
        </Grid>
      </Grid>
    </Box>
  );
};
