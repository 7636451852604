import React from 'react';
import isURL from 'validator/lib/isURL';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Switch,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import { useStyles } from './styles';
import { EditMessage } from '../AccessControlCard';
import { Alert } from '@material-ui/lab';

export const ConfidentialityItem = ({
  confidential,
}: {
  confidential: boolean | undefined;
  className?: string;
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.confidentialityItem}>
      {confidential ? (
        <>
          Private <LockIcon fontSize="small" />
        </>
      ) : (
        <>
          Public <PublicIcon fontSize="small" />
        </>
      )}
    </Box>
  );
};

export const OAuthExtraContent = ({
  redirect_url = '',
  is_client_confidential = false,
  editable,
  extraUpdatedData,
  setExtraUpdatedData,
  onEdit,
  isLoading,
  hasEditPermission,
  hasError,
}: Partial<IAccessControl.ApplicationType> & {
  editable: boolean;
  extraUpdatedData: any;
  setExtraUpdatedData: Function;
  onEdit: Function;
  isLoading: boolean;
  hasEditPermission: boolean | undefined;
  hasError: boolean;
}) => {
  const styles = useStyles();
  const [redirectUrl] = React.useState<string>(redirect_url);
  const [isConfidential, setIsConfidential] = React.useState<boolean>(
    is_client_confidential,
  );
  const [URLError, setURLError] = React.useState<boolean>(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsConfidential(checked);
    setExtraUpdatedData({
      ...extraUpdatedData,
      is_client_confidential: checked,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const error = value.length ? !isURL(value) : false;
    setURLError(error);
    setExtraUpdatedData({
      ...extraUpdatedData,
      redirect_url: value,
      error,
    });
  };

  return editable ? (
    <>
      <Typography variant="h6">Redirect URL</Typography>
      <Typography variant="body2">
        Where you expect users to come back to after logging in.
        <br />
        Note: This field is not relevant when using the Kubernetes OAuth/IAM
        integration (PlatformCredentialsSet).{' '}
        <FormControl
          variant="outlined"
          size="small"
          error={URLError}
          fullWidth
          className={styles.urlInput}
        >
          <InputLabel htmlFor="redirectUrl">Redirect URL</InputLabel>
          <OutlinedInput
            type="text"
            defaultValue={redirectUrl}
            id="redirectUrl"
            label="Redirect URL"
            placeholder="Enter a Redirect URL"
            margin="dense"
            onChange={handleInputChange}
          />
          {URLError && (
            <FormHelperText
              error
              id="redirectUrl-error"
              style={{ marginLeft: 0 }}
            >
              The value inserted is not a valid URL.
            </FormHelperText>
          )}
        </FormControl>
      </Typography>
      <Typography variant="h6">Client is</Typography>
      <Typography variant="body2">
        Public clients (e.g. native or SPAs) are only allowed to perform the
        OAuth2 Implicit Flow
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isConfidential}
            onChange={handleSwitchChange}
            name="isConfidential"
          />
        }
        label={
          isConfidential ? (
            <>
              Private{' '}
              <Typography component="span" color="textSecondary">
                (by default)
              </Typography>
            </>
          ) : (
            'Public'
          )
        }
        style={{ marginBottom: 20 }}
      />
      <Typography variant="h6">Resource Owner Scopes</Typography>
      <Typography variant="body2">
        This application can ask the resource owners for these scopes to be
        granted:
      </Typography>
      {isLoading && (
        <Box marginTop={1} marginBottom={1}>
          <Alert severity="info" variant="outlined">
            We're not going to lie, loading this data can take 2-3 minutes
            (because of how many scopes we need to filter through). Now is a
            great time to make yourself a cup of tea ☕️
          </Alert>
        </Box>
      )}
      {hasError && (
        <Box marginTop={1} marginBottom={1}>
          <Alert severity="error" variant="outlined">
            Some data could not be loaded. Please try again later.
          </Alert>
        </Box>
      )}
    </>
  ) : (
    <>
      <Box marginBottom="15px">
        <Typography variant="h6">Redirect URL</Typography>
        <Typography variant="body2">
          {redirect_url || (
            <>
              This application does not have a <strong>Redirect URL</strong>{' '}
              registered.{' '}
              <EditMessage
                hasEditPermission={hasEditPermission}
                onEdit={onEdit}
              />
            </>
          )}
        </Typography>
      </Box>
      <Box marginBottom="15px">
        <Typography variant="h6">Client is</Typography>
        <ConfidentialityItem confidential={is_client_confidential} />
      </Box>
    </>
  );
};
