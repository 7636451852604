import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';

export const zesApiRef = createApiRef<ZesClient>({
  id: 'plugin.cdp.zes.service',
});

export class ZesClient {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async getTaskDetails(cdp_deployment_id: string) {
    const baseUrl = await this.discoveryApi.getBaseUrl('zflow-executor');
    return await fetch(
      `${baseUrl}/v1/tasks?cdp_deployment_id=${cdp_deployment_id}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    ).then(x =>
      x.status === 200
        ? x.json()
        : [
            {
              task_logs: {
                status: x.status,
                details: '',
                title: 'Unreachable',
                message: `Unable to fetch data from ZES api status_code: ${x.status}`,
              },
            },
          ],
    );
  }
}
