import React from 'react';
import { MLResourceNotFound } from '../../api/definitions';
import logo480 from './logo480.png';
import { Grid, ThemeProvider, Typography } from '@material-ui/core';
import { Button } from '@backstage/core-components';
import { useNotFoundPageStyles } from '../../styles';
import { ButtonTheme } from './customTheme';

export const ResourceNotFound = ({ name, id }: MLResourceNotFound) => {
  const classes = useNotFoundPageStyles();

  const resourceLinkPath = decodeURIComponent(window.location.pathname)
    /** Split by the invalid resource id and select the path before it */
    .split(id)[0]
    /** Remove trailing slash */
    .replace(/\/$/, '');

  return (
    <Grid container className={classes.grid}>
      <div>
        <Typography variant="h4">
          No {name} found with ID {id}
        </Typography>
        <ThemeProvider theme={ButtonTheme}>
          <Button variant="contained" color="primary" to={resourceLinkPath}>
            Browse available {name}s
          </Button>
        </ThemeProvider>
      </div>
      <img src={logo480} alt="Illustration on entity not found page" />
    </Grid>
  );
};
