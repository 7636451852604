import React from 'react';
import { Tooltip } from '../Tooltip';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import * as S from './styles';

type Props = {
  link: string;
};

export function SourceNotebookLinkTag({ link }: Props) {
  return (
    <S.Text>
      <LibraryBooksIcon />

      <Tooltip title={`Source notebook link: ${link}`} placement="bottom-start">
        <a href={link} target="_blank" onClick={e => e?.stopPropagation()}>
          Notebook in Datalab
        </a>
      </Tooltip>
    </S.Text>
  );
}
