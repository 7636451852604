/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { PropsWithChildren } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Group } from '../../types';
import { CostInsightsTabs } from '../CostInsightsTabs';
import { Content, Header, Page } from '@backstage/core-components';
import DocsIcon from '@material-ui/icons/MenuBook';
import SupportIcon from '@material-ui/icons/ContactSupport';
import { EventTracker } from 'plugin-ui-components';

type CostInsightsLayoutProps = {
  title?: string;
  groups: Group[];
};

export const CostInsightsLayout = ({
  groups,
  children,
}: PropsWithChildren<CostInsightsLayoutProps>) => (
  <Page themeId="costs">
    <Header
      title="Cost Insights"
      subtitle="Explore the infrastructure costs of your team"
      pageTitleOverride="Cost Insights"
    >
      <Box color="white" display="flex" flexDirection="column">
        <Box display="grid" margin="4px">
          <EventTracker
            plugin="cost-insights"
            eventCategory="Cost Insights Documentation"
            eventAction="click"
            eventLabel="Click on cost documentation link"
            interaction="onClick"
          >
            <Button
              target="_blank"
              size="small"
              href="https://cloud-cost-efficiency.docs.zalando.net/"
              variant="outlined"
              color="inherit"
              endIcon={<DocsIcon style={{ marginTop: -4 }} />}
            >
              Docs
            </Button>
          </EventTracker>
        </Box>
        <Box display="grid" margin="4px">
          <EventTracker
            plugin="cost-insights"
            eventCategory="Cost Insights Feedback"
            eventAction="click"
            eventLabel="Click on cost feedback link"
            interaction="onClick"
          >
            <Button
              target="_blank"
              size="small"
              href="https://github.bus.zalan.do/zooport/issues/issues/new?assignees=&labels=Support+Request%2C+Zooport&template=support-request.md&title="
              variant="outlined"
              color="inherit"
              endIcon={<SupportIcon style={{ marginTop: -4 }} />}
            >
              Support
            </Button>
          </EventTracker>
        </Box>
      </Box>
    </Header>
    <Box gridArea="pageSubheader">
      <CostInsightsTabs groups={groups} />
    </Box>
    <Content>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Content>
  </Page>
);
