import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { useAsync } from 'react-use';

type PipelineResponse = {
  data?: {
    pipelines?: Array<{
      tags?: {
        repo?: string;
        application?: string;
        x_zalando_team?: string;
      };
      steps?: Array<{ id?: string; run?: { id?: string } }>;
    }>;
  };
};

export type PipelineData = {
  stepName: string;
  stepOrdinal: number;
  tags: {
    repo?: string;
    application?: string;
    x_zalando_team?: string;
  };
};

export function usePipelineInfo(
  pipelineID: string,
  buidID: string,
): {
  error: Error | undefined;
  loading: boolean;
  value: PipelineData;
} {
  const discoveryApi = useApi(discoveryApiRef);
  const authApi = useApi(oauth2ApiRef);

  const query = `{
    pipelines(id: "${pipelineID}") {
      tags
      steps {
        id
        run(id: "${buidID}") {
          id
        }
      }
    }
  }`;

  const { error, loading, value } = useAsync(async () => {
    const proxyUrl = await discoveryApi.getBaseUrl('proxy');
    const endpoint = `${proxyUrl}/cdp-graphql/api/graphql`;
    const token: string = await authApi.getAccessToken();
    const resp = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ query }),
      headers: new Headers({
        authorization: `Bearer ${token}`,
        accept: 'application/json',
        'content-type': 'application/json',
      }),
    });
    const data = (await resp.json()) as PipelineResponse;
    const tags = data.data?.pipelines?.[0]?.tags || {};
    const stepName =
      data.data?.pipelines?.[0]?.steps?.find(s => s.run?.id === buidID)?.id ||
      '';
    const stepOrdinal = data?.data?.pipelines?.[0].steps?.findIndex(
      s => s.run?.id === buidID,
    );

    return {
      stepName,
      stepOrdinal: stepOrdinal === undefined ? -1 : stepOrdinal,
      tags,
    };
  });

  return {
    error,
    loading,
    value: value as PipelineData,
  };
}
