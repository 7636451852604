import React, { useEffect } from 'react';
import cn from 'classnames';
import { SearchField } from './SearchField';
import { SearchFilters } from './SearchFilters';
import { SearchTabs } from './SearchTabs';
import { SearchResults } from './SearchResults';
import { useSearchContext } from '../SearchContext';
import { useStyles } from '../styles';
import { useMediaMobile } from 'plugin-ui-components';
import { searchConfig } from '../config';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { HotPages } from './SearchModal/HotPages';

interface Props {
  stripped?: boolean;
}

export function SearchContent({ stripped = false }: Props) {
  const styles = useStyles();
  const isMobile = useMediaMobile();
  const { query, results, filterOrientation, setFilterOptionsInitialized } =
    useSearchContext();
  const catalogApi = useApi(catalogApiRef);

  // Triggering the fetching and storing of options and default values
  useEffect(() => {
    const allFilterInitializers = searchConfig.indexes.flatMap(
      index =>
        index.filters?.map(filter => filter.initOptions({ catalogApi })) ?? [],
    );
    Promise.all(allFilterInitializers).finally(() =>
      setFilterOptionsInitialized(true),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogApi]);

  if (!(query && results?.length) && stripped) {
    return <HotPages modal />;
  }

  return (
    <div
      className={cn(styles.globalSearch, filterOrientation, {
        compact: isMobile,
        stripped,
      })}
    >
      {!stripped && (
        <>
          <SearchField />
          <SearchFilters />
        </>
      )}
      <SearchTabs overrideOrientation={stripped ? 'horizontal' : undefined} />
      <SearchResults />
    </div>
  );
}
