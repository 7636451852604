import React from 'react';

import { DeploymentResourceModel } from '../../../../models';
import { DeploymentStatusResource } from './DeploymentStatusResource';
import { getMessage } from './utils';

type Props = {
  status: string;
  resources: DeploymentResourceModel[];
};

export const Resources = ({ status, resources }: Props) => {
  if (!Boolean(resources.length)) {
    return <span>{status ? getMessage(status) : 'No data to display'}</span>;
  }

  return (
    <>
      {resources.map(resource => (
        <DeploymentStatusResource
          key={`${resource.kind}-${resource.name}`}
          resource={resource}
          stepStatus={status}
        />
      ))}
    </>
  );
};
