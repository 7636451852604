import { getAliasFromDomain, getDomainFromAlias } from '../../utils/domains';

export class OrganizationModel {
  alias: string;
  fullPath: string;
  fullAliasPath: string;

  static fromFullPath(path: string) {
    const [domain, name] = path.split(/\//);
    return new this(domain, name ?? '');
  }

  static fromFullAliasPath(path: string) {
    const [alias, name] = path.split(/\//);
    return new this(getDomainFromAlias(alias), name ?? '');
  }

  constructor(public domain: string, public name: string) {
    this.alias = getAliasFromDomain(this.domain) ?? '';
    this.fullPath = `${this.domain}/${this.name}`;
    this.fullAliasPath = `${this.alias}/${this.name}`;
  }
}
