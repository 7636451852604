import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import CustomizedDialogs from './Modal';
import { TableColumn } from '@backstage/core-components';
import { Library } from '../../types';

export const columns: TableColumn<Library>[] = [
  {
    field: 'name',
    title: 'NAME',
  },
  {
    field: 'version',
    title: 'VERSION',
  },
  {
    title: 'POPULARITY',
    field: 'applications',
    render: data => (
      <Typography component="span" variant="body2">
        used by {data?.applications.length} apps
      </Typography>
    ),
  },
  {
    title: 'CONTACT',
    render: data => <CustomizedDialogs library={data} />,
  },
];

export const columnsMyLibs: TableColumn<Library>[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'VERSION',
    field: 'version',
  },
  {
    title: 'POPULARITY',
    field: 'applications',
    render: data => (
      <Typography component="span" variant="body2">
        used by {data?.applications.length} apps
      </Typography>
    ),
  },
  {
    title: 'MY APPS USING THIS LIBRARY',
    field: 'applications',
    render: data => {
      const appNames = data?.applications.map(app => app.spec.name);
      return (
        <Tooltip
          title={
            appNames.length > 3 ? (
              <ul>
                {appNames.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            ) : (
              ''
            )
          }
        >
          <Typography component="span" variant="body2">
            <ul>
              {appNames.slice(0, 3).map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
            {appNames.length > 3 && '...'}
          </Typography>
        </Tooltip>
      );
    },
  },
];
