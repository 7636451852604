import { Theme, makeStyles, styled, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  m_1_0: {
    margin: theme.spacing(1, 0),
  },
  mb_1: {
    marginBottom: theme.spacing(1),
  },
  mr_1: {
    marginRight: theme.spacing(1),
  },
  ml_1: {
    marginLeft: theme.spacing(1),
  },
  p_1_0: {
    padding: theme.spacing(1, 0),
  },
  fr: {
    float: 'right',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  selectNoBorder: {
    '&::before': {
      display: 'none',
    },
  },
  adornmentNoMargin: {
    marginTop: '0px !important',
  },
  idCheckBtn: {
    transition: 'background-color 200ms, color 200ms, border-color 200ms',

    '&[data-available]': {
      color: 'white',
      maxWidth: 32,
      minWidth: 'inherit',
      borderRadius: '50%',

      '&[data-available=true]': {
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
      '&[data-available=false]': {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.light,
      },
    },
  },
}));

export const Header = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: `${theme.spacing()}px`,
}));

export const DetailsTable = styled('table')(({ theme }) => ({
  '& tr td': {
    wordBreak: 'break-word',
    verticalAlign: 'top',

    '&:last-child': {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const FormHelperList = styled('ul')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  textAlign: 'left',
  margin: '3px 0 0',
  '&$disabled': {
    color: theme.palette.text.disabled,
  },
  '&$error': {
    color: theme.palette.error.main,
  },
}));

export const Form = styled('form')(() => ({
  '& textarea': {
    minHeight: '4.5rem',
  },
}));
