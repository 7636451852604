import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

/**
 *
 * @param {object} entity - Object you want to set `undefined` or `null` values to empty string.
 * @returns {object} Passed object with `undefined` or `null` values set to empty string.
 */
const setDefault = (entity: any): any => {
  return mapValues(entity, val => {
    if (isObject(val) && !isArray(val)) {
      return {
        ...val,
        ...setDefault(val),
      };
    }
    return isNil(val) ? '' : val;
  });
};

/**
 *
 * @param {object} value - Object you want to sanitize (set default)
 * @returns {object} Sanitized object
 */
export function sanitizeData(value: any) {
  return setDefault(value);
}
