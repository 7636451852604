import React, { useEffect, useMemo, useState } from 'react';
import { useStyles } from '../../styles';
import { useSearchContext } from '../../SearchContext';
import { searchConfig } from '../../config';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InfoTooltip, useMediaMobile } from 'plugin-ui-components';
import { useAsync } from 'react-use';
import { ConfigFilter, SearchConfigForGCS } from '../../models';
import cn from 'classnames';
import {
  FilterOption,
  GCSDynamicFiltersOptions,
  GCSSearchResponse,
} from '../../types';
import { TabFilters } from './TabFilters';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

const defaultConfigFilters: ConfigFilter[] = [];

export function SearchFilters() {
  const isMobile = useMediaMobile();
  const [expanded, setExpanded] = useState(true);
  const { tab, results, query, filters } = useSearchContext();
  const styles = useStyles();
  const tabIndex = useMemo(
    () => searchConfig.indexes.findIndex(i => i.isMatchingQuery(tab)),
    [tab],
  );
  const searchIndex = searchConfig.indexes[tabIndex] as SearchConfigForGCS;
  const catalogApi = useApi(catalogApiRef);

  const dynamicFiltersInSearchParams = filters.filter(f => {
    const tabFilters = searchIndex.filters ?? [];
    const isTabFilter = tabFilters.find(
      tabFilter => tabFilter.field === f.field,
    );
    return !isTabFilter;
  });

  const searchParamsFilters: ConfigFilter[] = [];

  dynamicFiltersInSearchParams.map(f => {
    const newSearchParamsFilter = new ConfigFilter({
      indexId: f.indexId,
      field: f.field,
      label: f.field,
      getOptions: async () => {
        return [
          { label: (f.value[0] as string) ?? '', value: f.value[0] ?? '' },
        ];
      },
    });
    newSearchParamsFilter.initOptions({ catalogApi });
    searchParamsFilters.push(newSearchParamsFilter);
  });

  const newTabResults = results[tabIndex];
  const { value: dynamicFilters = defaultConfigFilters } =
    useAsync(async () => {
      if (!!newTabResults && searchIndex.engine === 'gcs') {
        const tabResults = await newTabResults.then(
          r => r.data as GCSSearchResponse,
        );
        if (tabResults?.facetResults) {
          const newDynamicFilters: ConfigFilter[] = [];
          tabResults.facetResults.forEach(facet => {
            const dynamicFilterOptions = facet.operatorName
              ? (searchIndex.dynamicFilters as GCSDynamicFiltersOptions)[
                  facet.operatorName
                ]
              : undefined;

            if (
              facet.buckets &&
              facet.operatorName &&
              !dynamicFilterOptions?.excluded
            ) {
              const options: FilterOption[] = facet.buckets
                .map(bucket => {
                  const value =
                    bucket.filter?.valueFilter?.value?.stringValue ?? '';
                  return {
                    label: dynamicFilterOptions?.optionsLabelMapper
                      ? dynamicFilterOptions.optionsLabelMapper(value)
                      : value,
                    value,
                  };
                })
                .filter(option => option.value);

              if (options.length) {
                const newDynamicFilter = new ConfigFilter({
                  indexId: searchIndex.id,
                  field: facet.operatorName,
                  label: capitalize(facet.operatorName),
                  getOptions: async () => [...options],
                });
                newDynamicFilter.initOptions({ catalogApi });
                newDynamicFilters.push(newDynamicFilter);
              }
            }
          });
          return newDynamicFilters;
        }
      }
      return undefined;
    }, [newTabResults]);

  const tabFilters = useMemo(() => {
    const newTabFilters: ConfigFilter[] = [];
    if (searchIndex?.filters) newTabFilters.push(...searchIndex.filters);
    return newTabFilters.length ? newTabFilters : defaultConfigFilters;
  }, [searchIndex?.filters]);

  useEffect(() => {
    setExpanded(!isMobile);
  }, [isMobile]);

  if (
    !tabFilters.length &&
    !dynamicFilters.length &&
    !dynamicFiltersInSearchParams.length
  ) {
    return <></>;
  }

  return (
    <Accordion
      className={cn(styles.searchFiltersWrapper, {
        compact: isMobile,
      })}
      expanded={expanded}
      onChange={(_, val) => setExpanded(val)}
      variant="outlined"
    >
      <AccordionSummary
        classes={{ root: styles.searchFiltersAccordionSummaryHeader }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="subtitle1">Search filters</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.searchFiltersAccordionSummary}>
        <TabFilters filters={tabFilters} />
        <TabFilters
          filters={dynamicFilters}
          title={
            <Box display="flex" justifyContent="space-between">
              <span className={styles.tabFilterTitle}>Dynamic Filters</span>
              <InfoTooltip>
                Dynamic filters are pulled from the search results returned from
                the search engine
              </InfoTooltip>
            </Box>
          }
        />
        {!dynamicFilters.length &&
          query.trim() &&
          !!dynamicFiltersInSearchParams?.length && (
            <TabFilters filters={searchParamsFilters} />
          )}
      </AccordionDetails>
    </Accordion>
  );
}
