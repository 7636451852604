/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext } from 'react';
import { Group } from '../types';

export type GroupsContextProps = {
  groups: Group[];
};

export const GroupsContext = React.createContext<
  GroupsContextProps | undefined
>(undefined);

export function useGroups(): Group[] {
  const context = useContext(GroupsContext);
  return context ? context.groups : assertNever();
}

function assertNever(): never {
  throw Error('Cannot use useGroups outside of GroupsProvider');
}
