import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import {
  BaseThemeOptionsInput,
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  PageTheme,
  UnifiedTheme,
  UnifiedThemeProvider,
} from '@backstage/theme';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { OptionalAppOptions } from '@backstage/app-defaults';
import { stylesOverrides } from './mui-overrides';

type PaletteType = { [key: string]: string };

export const shapes = {
  wave: `url("/shapes/wave.svg")`,
  wave2: `url("/shapes/wave2.svg")`,
  round: `url("/shapes/round.svg")`,
};

const COLORS = {
  semantic: {
    abortedDark: '#922657',
    abortedBase: '#D2377D',
    abortedLight: '#DF73A4',
    errorDark: '#9B2B30',
    errorBase: '#DF3E46',
    errorLight: '#E8787D',
    primaryDark: '#9A5601',
    primaryBase: '#DE7C02',
    primaryLight: '#E7A34E',
    secondaryDark: '#923E1F',
    secondaryBase: '#D25A2d',
    secondaryLight: '#DF8B6C',
    warningDark: '#846700',
    warningBase: '#BE9400',
    warningLight: '#DF8B6C',
    successDark: '#177830',
    successBase: '#21AC45',
    successLight: '#64C57D',
    runningDark: '#606C02',
    runningBase: '#8A9C04',
    runningLight: '#ADB94F',
    tealDark: '#006259',
    tealBase: '#008D80',
    tealLight: '#4DAFA6',
    infoBase: '#1364B0',
    infoDark: '#0D457A',
    infoLight: '#5A92C7',
    pendingDark: '#4E12A9',
    pendingBase: '#711AF3',
    pendingLight: '#9B5FF6',
    purpleDark: '#6B1778',
    purpleBase: '#9A22AD',
    purpleLight: '#B864C5',
    grayDark: '#272B30',
    grayBase: '#0F1317',
    grayLight: '#B5B5B5',
    white: '#FFFFFF',
  },
  brand: {
    basic: '#101419',
    primaryOrange: '#DE7C02',
    purple: '#9A22AD',
    secondaryOrange: '#D25A2D',
    teal: '#008D80',
  },
};

const legacyExploreTheme = genPageTheme({
  colors: [
    darken(COLORS.semantic.infoBase, 0.2),
    darken(COLORS.semantic.pendingBase, 0.2),
  ],
  shape: shapes.round,
});

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({
    colors: [COLORS.semantic.errorBase, COLORS.semantic.warningBase],
    shape: shapes.wave,
  }),

  tool: genPageTheme({
    colors: [COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),

  opsgenie: genPageTheme({
    colors: [COLORS.semantic.tealDark, COLORS.semantic.primaryBase],
    shape: shapes.wave,
  }),

  service: genPageTheme({
    colors: [
      COLORS.brand.primaryOrange,
      lighten(COLORS.brand.secondaryOrange, 0.2),
    ],
    shape: shapes.wave,
  }),
  website: genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.semantic.successBase],
    shape: shapes.wave,
  }),
  library: genPageTheme({
    colors: [
      COLORS.brand.primaryOrange,
      lighten(COLORS.brand.primaryOrange, 0.2),
    ],
    shape: shapes.wave,
  }),
  other: genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.semantic.runningBase],
    shape: shapes.wave,
  }),
  api: genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.brand.secondaryOrange],
    shape: shapes.wave,
  }),
  openapi: genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.brand.secondaryOrange],
    shape: shapes.wave,
  }),
  app: genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.brand.secondaryOrange],
    shape: shapes.wave,
  }),
  component: genPageTheme({
    colors: [COLORS.brand.secondaryOrange, COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),
  inactiveApp: genPageTheme({
    colors: [
      COLORS.brand.secondaryOrange,
      COLORS.brand.primaryOrange,
      '#000',
      '#000',
    ],
    shape: shapes.wave,
  }),
  documentation: genPageTheme({
    colors: [COLORS.semantic.infoBase, COLORS.brand.primaryOrange],
    shape: shapes.wave2,
  }),
  baseImages: genPageTheme({
    colors: [COLORS.semantic.warningDark, COLORS.brand.primaryOrange],
    shape: shapes.wave2,
  }),

  // repoman repository types
  'repo-service': genPageTheme({
    colors: [
      COLORS.brand.primaryOrange,
      lighten(COLORS.brand.secondaryOrange, 0.2),
    ],
    shape: shapes.wave,
  }),

  'repo-doc': genPageTheme({
    colors: [COLORS.semantic.infoBase, COLORS.brand.primaryOrange],
    shape: shapes.wave2,
  }),

  'repo-secrets': genPageTheme({
    colors: [
      COLORS.brand.primaryOrange,
      lighten(COLORS.brand.primaryOrange, 0.2),
    ],
    shape: shapes.wave,
  }),

  'repo-tools': genPageTheme({
    colors: [COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),

  'repo-config': genPageTheme({
    colors: [COLORS.brand.primaryOrange, COLORS.semantic.runningBase],
    shape: shapes.wave,
  }),
  complianceAndSecurity: genPageTheme({
    colors: [COLORS.semantic.warningLight, COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),

  // plugins
  techradar: legacyExploreTheme,
  teams: legacyExploreTheme,
  tooling: legacyExploreTheme,
  support: genPageTheme({
    colors: [
      COLORS.semantic.infoBase,
      darken(COLORS.semantic.pendingBase, 0.2),
    ],
    shape: shapes.round,
  }),
  cdp: genPageTheme({
    colors: [
      COLORS.brand.primaryOrange,
      darken(COLORS.semantic.pendingBase, 0.2),
    ],
    shape: shapes.round,
  }),
  create: genPageTheme({
    colors: [
      darken(COLORS.semantic.warningBase, 0.2),
      darken(COLORS.semantic.pendingBase, 0.2),
    ],
    shape: shapes.round,
  }),
  ml: genPageTheme({
    colors: [COLORS.brand.purple, darken(COLORS.semantic.pendingBase, 0.2)],
    shape: shapes.wave2,
  }),
  cyberweek: genPageTheme({
    colors: ['rgba(9 9 9)', 'rgb(50 50 50)'],
    shape: shapes.wave,
  }),
  domain: genPageTheme({
    colors: [COLORS.brand.primaryOrange, 'rgb(0,100,0)'],
    shape: shapes.wave,
  }),
  costs: genPageTheme({
    colors: [COLORS.semantic.runningBase, COLORS.brand.primaryOrange],
    shape: shapes.wave2,
  }),
  infra: genPageTheme({
    colors: [COLORS.semantic.errorDark, COLORS.brand.primaryOrange],
    shape: shapes.wave2,
  }),
  containerImage: genPageTheme({
    colors: ['#f76f78', COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),
  nakadi: genPageTheme({
    colors: [COLORS.semantic.abortedBase, COLORS.brand.primaryOrange],
    shape: shapes.wave,
  }),
  'nl-interface': genPageTheme({
    colors: [COLORS.brand.purple, darken(COLORS.semantic.pendingBase, 0.2)],
    shape: shapes.wave2,
  }),
};

export const PALETTE: Record<'light' | 'dark', PaletteType> = {
  light: {
    ...COLORS.semantic,
    ...COLORS.brand,
    theme: '#fff',
    divider: '#d5d5d5',
    contranst: COLORS.brand.basic,
    navbar: COLORS.brand.basic,
    type: 'light',
    mode: 'light',
  },
  dark: {
    ...COLORS.semantic,
    ...COLORS.brand,
    theme: COLORS.brand.basic,
    divider: '#565656',
    contranst: '#fff',
    navbar: lighten(COLORS.brand.basic, 0.1),
    type: 'dark',
    mode: 'dark',
  },
};

export const FONT_FAMILY = 'Roboto, sans-serif';

function createThemePalette(
  palette: PaletteType,
): PaletteOptions & { mode: PaletteType } {
  return {
    type: (palette as any).type,
    mode: (palette as any).mode,
    primary: {
      main: palette.primaryOrange,
    },

    secondary: {
      main: palette.secondaryOrange,
    },

    divider: palette.divider,

    background: {
      default: darken(palette.theme, 0.05),
      paper: lighten(palette.theme, 0.1),
    },

    border: darken(palette.contranst, 0.5),

    status: {
      ok: palette.successBase,
      warning: palette.warningBase,
      error: palette.errorBase,
      running: palette.runningBase,
      pending: palette.pendingBase,
      aborted: palette.abortedBase,
    },

    gold: 'gold', // TO DO

    // typography
    textContrast: palette.contranst,
    textSubtle: lighten(palette.contranst, 0.3),
    textVerySubtle: lighten(palette.contranst, 0.7),
    highlight: palette.primaryOrange,

    text: {
      primary: palette.contranst,
      secondary: lighten(palette.contranst, 0.3),
      hint: lighten(palette.contranst, 0.3),
      disabled: 'gray',
    },

    // semantic colors
    errorBackground: lighten(palette.errorBase, 0.4),
    errorText: palette.errorBase,

    warningBackground: lighten(palette.warningBase, 0.6),
    warningText: palette.warningBase,

    infoBackground: lighten(palette.infoBase, 0.4),
    infoText: palette.infoBase,

    // link
    link: palette.primaryOrange,
    linkHover: lighten(palette.primaryOrange, 0.6),

    // components
    navigation: {
      color: '#fff',
      selectedColor: palette.primaryOrange,
      background: palette.navbar,
      indicator: palette.primaryOrange,
      navItem: {
        hoverBackground: 'rgba(255,255,255,0.17)',
      },
    },

    banner: {
      info: palette.infoBase,
      error: palette.errorBase,
      text: palette.contranst,
      link: palette.infoBase,
    },

    bursts: {
      fontColor: '#FEFEFE', // TO DO
      slackChannelText: '#ddd', // TO DO
      backgroundColor: {
        default: palette.secondaryOrange,
      },
      gradient: { linear: '' },
    },

    pinSidebarButton: {
      icon: palette.theme,
      background: palette.primaryOrange,
    },

    tabbar: {
      indicator: palette.primaryOrange,
    },
  };
}

/**
 * Creates a Backstage theme
 * @description This is used as a replacement for backstage's `createTheme` method that implements a custom Mui theme override
 */
export function createTheme(
  options: BaseThemeOptionsInput<PaletteOptions>,
): UnifiedTheme {
  const themeOptions = createBaseThemeOptions(options);
  const baseTheme = createUnifiedTheme({
    ...themeOptions,
    pageTheme: pageTheme,
    fontFamily: FONT_FAMILY,
    defaultPageTheme: 'home',
    components: stylesOverrides,
  });
  return baseTheme;
}

export const lightPalette = createThemePalette(PALETTE.light);
const lightTheme = createTheme({
  palette: lightPalette,
  fontFamily: FONT_FAMILY,
});
export const darkPalette = createThemePalette(PALETTE.dark);
const darkTheme = createTheme({
  palette: darkPalette,
  fontFamily: FONT_FAMILY,
});

export const themes: OptionalAppOptions['themes'] = [
  {
    id: 'light',
    title: 'Light',
    variant: 'light',
    icon: <BrightnessHighIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={lightTheme}>
        <CssBaseline>{children}</CssBaseline>
      </UnifiedThemeProvider>
    ),
  },
  {
    id: 'dark',
    title: 'Dark',
    variant: 'dark',
    icon: <Brightness4Icon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={darkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </UnifiedThemeProvider>
    ),
  },
];

export const colors = COLORS;
