import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import axios from 'axios';
import { parseEntityRef } from '@backstage/catalog-model';

export type CatalogAdditionalApiType = {
  importEntity(ref: string): Promise<addEntityResponse>;
  refreshEntity(
    ref: string,
    maxToWait?: number,
  ): Promise<refreshEntityResponse>;
  getResourceReport(name: string): Promise<KubeResourceReport>;
  getClustersDetails(ids: string[]): Promise<ICluster[]>;
};

export const catalogAdditionalApiRef = createApiRef<CatalogAdditionalApiType>({
  id: 'plugin.catalog.catalog-additional',
});

interface Apis {
  discoveryApi: DiscoveryApi;
  oauth2Api: OAuthApi;
}

type addEntityResponse = {
  added: boolean;
  error?: string;
};
type refreshEntityResponse = {
  refreshed: boolean;
  error?: string;
};

export class CatalogAdditionalApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(apis: Apis) {
    this.discoveryApi = apis.discoveryApi;
    this.oauth2Api = apis.oauth2Api;
  }

  async importEntity(ref: string): Promise<addEntityResponse> {
    const { namespace, kind, name } = parseEntityRef(ref);
    const catalogUrl = await this.discoveryApi.getBaseUrl('catalog');
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = `${catalogUrl}/add_entity/${namespace}/${kind}/${name}`;
    const response = await axios.post(
      serviceUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 200) {
      return { added: false, error: response.statusText };
    }
    return response.data;
  }

  async refreshEntity(
    ref: string,
    maxToWait?: number,
    createEntity?: boolean,
  ): Promise<refreshEntityResponse> {
    const { namespace, kind, name } = parseEntityRef(ref);
    const catalogUrl = await this.discoveryApi.getBaseUrl('catalog');
    const token = await this.oauth2Api.getAccessToken();
    const serviceUrl = new URL(
      `${catalogUrl}/refresh_entity/${namespace}/${kind}/${name}`,
    );
    if (maxToWait) {
      // add wait=${maxToWait} to the url if defined
      serviceUrl.searchParams.append('wait', maxToWait.toString());
    }
    // add the create parameter to the nurl if defined
    if (createEntity) {
      serviceUrl.searchParams.append('create', createEntity.toString());
    }
    const response = await axios.post(
      serviceUrl.toString(),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 200) {
      return { refreshed: false, error: response.statusText };
    }
    return response.data;
  }

  async getResourceReport(name: string): Promise<KubeResourceReport> {
    const proxyURL = await this.discoveryApi.getBaseUrl('proxy');
    const token = await this.oauth2Api.getAccessToken();

    return await axios
      .get(`${proxyURL}/kube-resource-report/application-${name}.json`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.data);
  }

  async getClustersDetails(ids: string[]): Promise<ICluster[]> {
    const proxyURL = await this.discoveryApi.getBaseUrl('proxy');
    const token = await this.oauth2Api.getAccessToken();

    return await axios
      .all(
        ids.map(id =>
          axios.get(`${proxyURL}/cluster-registry/kubernetes-clusters/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ),
      )
      .then(axios.spread((...responses) => responses.map(res => res.data)));
  }
}
