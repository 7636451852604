import React from 'react';
import { PluginTracking } from 'plugin-ui-components';
import { Link, LinkProps } from '@backstage/core-components';

type Props = LinkProps & ITrackingEvent;

export const TrackedRouterLink: React.FC<Props> = (props: Props) => {
  const {
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
    ...linkProps
  } = props;

  const handleOnClick = () => {
    PluginTracking.sendEvent({
      plugin,
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
  };

  return <Link {...linkProps} onClick={handleOnClick} />;
};
