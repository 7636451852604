import { Theme, styled } from '@material-ui/core';

export const ActionPanel = styled('section')((props: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  padding: props.theme.spacing(2),

  [props.theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const Filters = styled('div')((props: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: props.theme.spacing(1),

  [props.theme.breakpoints.up('sm')]: {
    flexFlow: 'row wrap',

    ['& > *']: {
      marginTop: 0,
      marginRight: props.theme.spacing(1),
    },
  },
}));
