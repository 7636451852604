import React from 'react';
import { RefinedFilters } from './RefinedFilters';

interface FilterProps {
  handleVersionFilter: (v: string[]) => void;
  onClearFilters: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  filteredLibs: string[];
  filteredVersions: string[];
  handleLibsFilter: (value: string[]) => void;
}

export const Filters = ({
  handleVersionFilter,
  onClearFilters,
  filteredLibs,
  filteredVersions,
  handleLibsFilter,
}: FilterProps) => {
  return (
    <div>
      <RefinedFilters
        handleVersionFilter={handleVersionFilter}
        handleLibsFilter={handleLibsFilter}
        filteredVersions={filteredVersions}
        filteredLibs={filteredLibs}
        onClearFilters={onClearFilters}
      />
    </div>
  );
};
