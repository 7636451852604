import React, { useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { MLModelOwners } from '../../api/definitions';
import { Content, Table } from '@backstage/core-components';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { tableHeaderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { getModelOwnerColumns } from '../common/columns/ModelOwnerColumns';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

export const ModelOwners = ({ owners }: { owners: MLModelOwners[] | null }) => {
  const theme = useTheme();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const columns = getModelOwnerColumns();

  const tableData = {
    meta: {
      total: owners?.length,
    },
    owners: owners,
  };

  return (
    <Content>
      <Grid>
        {owners ? (
          <Table
            data={owners}
            columns={columns}
            options={{
              pageSize: PAGE_SIZE,
              search: false,
              filtering: false,
              padding: 'dense',
              showFirstLastPageButtons: true,
              emptyRowsWhenPaging: false,
              headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
            }}
            components={{
              Pagination: props => (
                <MLTablePagination
                  props={props}
                  page={page}
                  resource={tableData}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
                />
              ),
              Toolbar: props => <MLTableToolbar props={props} />,
            }}
          />
        ) : (
          <MissingFieldPlaceholder field="owner" type="model-card" />
        )}
      </Grid>
    </Content>
  );
};
