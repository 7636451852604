import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select, MenuItem } from '@material-ui/core';
import { TableColumn } from '@backstage/core-components';
import { DeploymentEnvironment } from '../../../api/definitions';
import { NOT_AVAILABLE } from '../../../constants/common';
import { getFormattedDateTime } from '../../../utils/time';
import { getDeploymentEnvironmentOptions } from './utils';

export const getModelGroupListColumns = (
  deploymentEnvironments: DeploymentEnvironment[] | undefined,
  selectedDeploymentEnvironment: string | undefined,
  setSelectedDeploymentEnvironment: React.Dispatch<any>,
): TableColumn[] => {
  const deploymentEnvironmentOptions = getDeploymentEnvironmentOptions(
    deploymentEnvironments,
  );

  function getEnvironmentName(environmentId: string) {
    const environment = deploymentEnvironments?.find(env => {
      return env.id === environmentId;
    });
    return environment?.name || '';
  }

  return [
    {
      title: 'Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Deployment Environment',
      field: 'deployment_environment',
      filterComponent: _ => {
        return (
          <div data-testid="model-group-environment-filter">
            <Select
              key={uuidv4()}
              value={selectedDeploymentEnvironment}
              onChange={ev => setSelectedDeploymentEnvironment(ev.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
              style={{ maxWidth: 200 }}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                getContentAnchorEl: null,
              }}
            >
              {deploymentEnvironmentOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      },
      render(row: any): React.ReactNode {
        return getEnvironmentName(row.deployment_environment);
      },
    },
    {
      title: 'Created at',
      field: 'created_at',
      filtering: false,
      render(row: any): React.ReactNode {
        return (
          <>
            {row.created_at
              ? `${getFormattedDateTime(row.created_at)}`
              : NOT_AVAILABLE}
          </>
        );
      },
    },
  ];
};
