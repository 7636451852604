import { createContext } from 'react';

const anyFunc: any = () => {};

/**
 * The context that maintains shared state for all visible filter groups.
 * @important It must be initialized in case a bug caused the context to not be set,
 * this will prevent a crash
 */
export const FilterGroupsContext = createContext<ICatalog.FilterGroupsContext>({
  teamsFilter: [],
  docsCategoryFilter: [],
  reviewFilter: [],
  apecFilter: [],
  tagsFilter: [],
  dataClassificationFilter: [],
  availableTeams: [],
  availableDocsCategory: [],
  availableToers: [],
  availableLifecycles: [],
  availableTypes: [],
  availableCostCenters: [],
  availableIdsAndAliases: [],
  availablestep: [],
  availableJourney: [],
  availableToolingOwners: [],
  availableToolingStatus: [],
  availableScorecards: [],
  availableSecurityTiers: [],
  cyberweekRelevantFilter: '',
  statusFilter: '',
  criticalityFilter: '',
  lifecycleFilter: '',
  typeFilter: '',
  costCenterFilter: [],
  idsAndAliasesFilter: [],
  ownerFilter: [],
  journeyFilter: [],
  stepFilter: [],
  toolingStatusFilter: [],
  scorecardsFilter: [],
  securityTierFilter: [],
  isCatalogEmpty: true,
  error: undefined,
  availableTags: [],
  reload: anyFunc,
  unregister: anyFunc,
  filterGroupStates: {},
  support247Filter: '',
  loading: false,
  register: anyFunc,
  matchingEntities: [],
  setTeamsFilter: anyFunc,
  setDocsCategoryFilter: anyFunc,
  setLifecycleFilter: anyFunc,
  setReviewFilter: anyFunc,
  setApecFilter: anyFunc,
  setDataClassificationFilter: anyFunc,
  setStatusFilter: anyFunc,
  setSupport247Filter: anyFunc,
  setTagsFilter: anyFunc,
  setCyberweekRelevantFilter: anyFunc,
  setCriticalityFilter: anyFunc,
  setTypeFilter: anyFunc,
  setCostCenterFilter: anyFunc,
  setIdsAndAliasesFilter: anyFunc,
  setGroupSelectedFilters: anyFunc,
  setScorecardsFilter: anyFunc,
  cyberWeekPhaseFilter: [],
  setCyberWeekPhaseFilter: anyFunc,
  cyberWeekToerFilter: [],
  setCyberWeekToerFilter: anyFunc,
  setOwnerFilter: anyFunc,
  setJourneyFilter: anyFunc,
  setStepFilter: anyFunc,
  setToolingStatusFilter: anyFunc,
  setSecurityTierFilter: anyFunc,
  prrFilter: '',
  setPrrFilter: anyFunc,
});
