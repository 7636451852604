import { TrackedInteractionProps } from 'plugin-ui-components';

export class Tracking {
  static viewLink(eventLabel: string): TrackedInteractionProps {
    return {
      interaction: 'onClick',
      plugin: 'application-review',
      eventCategory: 'Open tracing section',
      eventLabel,
      eventAction: 'click',
    };
  }
}
