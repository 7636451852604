import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  DiscoveryApi,
  IdentityApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { TechDocsApi } from '@backstage/plugin-techdocs-react';
import { Config } from '@backstage/config';
import { isMobileDevice } from './utils/browserUtils';

/** API to talk to techdocs-backend */
export class ZalandoDocsApi implements TechDocsApi {
  private readonly oauth2Api: OAuthApi;
  configApi: Config;
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;

  constructor({
    configApi,
    discoveryApi,
    oauth2Api,
    identityApi,
  }: {
    configApi: Config;
    discoveryApi: DiscoveryApi;
    oauth2Api: OAuthApi;
    identityApi: IdentityApi;
  }) {
    this.configApi = configApi;
    this.discoveryApi = discoveryApi;
    this.oauth2Api = oauth2Api;
    this.identityApi = identityApi;
  }

  /**
   * Retrieve TechDocs metadata.
   *
   * When docs are built, we generate a techdocs_metadata.json and store it along with the generated
   * static files. It includes necessary data about the docs site. This method requests techdocs-backend
   * which retrieves the TechDocs metadata.
   *
   * @param entityId Object containing entity data like name, namespace, etc.
   */
  async getTechDocsMetadata(entityId: CompoundEntityRef) {
    const { kind, namespace, name } = entityId;
    const accessToken = await this.oauth2Api.getAccessToken();
    const requestUrl = `${await this.getApiOrigin()}/metadata/techdocs/${namespace}/${kind}/${name}`;

    if (isMobileDevice()) {
      window.location.replace(`https://${name}.docs.zalando.net`);
    }

    return await fetch(requestUrl, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }).then(r => r.json());
  }

  /**
   * Retrieve metadata about an entity.
   *
   * This method requests techdocs-backend which uses the catalog APIs to respond with filtered
   * information required here.
   *
   * @param entityId Object containing entity data like name, namespace, etc.
   */
  async getEntityMetadata(entityId: CompoundEntityRef) {
    const { kind, namespace, name } = entityId;
    const accessToken = await this.oauth2Api.getAccessToken();
    const requestUrl = `${await this.getApiOrigin()}/metadata/entity/${namespace}/${kind}/${name}`;

    return await fetch(requestUrl, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }).then(r => r.json());
  }

  async getApiOrigin(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('techdocs');
  }

  // Implementation required but not used in the app, so return ever a valid value
  async getCookie(): Promise<{ expiresAt: string }> {
    const tenMinutesFromNow = new Date(Date.now() + 10 * 60 * 1000);
    return { expiresAt: tenMinutesFromNow.toISOString() };
  }
}
