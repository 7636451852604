import {
  IRunStepRun,
} from '../../api/types/payload';
import {
  STEP_FINISHED_STATUSES,
  STEP_PRE_IN_PROGRESS_STATUSES,
  STEP_STOPPED_STATUSES,
} from '../../constants';
import { StepRunProblemsModel } from '../common';
import { hasOnlySecurityDeploymentErrors, hasValidationErrors, hasValidationWarnings } from '../helpers';

export class RunStepRunModel {
  id: string;
  aborter: string;
  approver: string;
  created_at: string;
  finished_at: string;
  status: string;
  status_log: string[];
  error: string;
  problems: StepRunProblemsModel;
  internal_errors: Array<string>;
  user_errors: Array<string>;
  warnings: Array<string>;
  sleep_until: string;
  accountable_user: string;

  isFinished: boolean;
  hasStarted: boolean;
  isRunning: boolean;
  hasValidationErrors: boolean;
  hasValidationwarnings: boolean;
  hasOnlySecurityDeploymentErrors: boolean;

  constructor(json: IRunStepRun = {} as IRunStepRun) {
    this.id = json.id ?? '';
    this.aborter = json.aborter ?? '';
    this.approver = json.approver ?? '';
    this.created_at = json.created_at ?? '';
    this.finished_at = json.finished_at ?? '';
    this.status = json.status ?? '';
    this.status_log = json.status_log ?? [];
    this.error = json.error ?? '';
    this.problems = new StepRunProblemsModel(json.problems ?? {});
    this.internal_errors = json.internal_errors ?? [];
    this.user_errors = json.user_errors ?? [];
    this.warnings = json.warnings ?? [];
    this.sleep_until = json.sleep_until ?? '';
    this.accountable_user = json.accountable_user ?? '';

    this.hasStarted = !STEP_PRE_IN_PROGRESS_STATUSES.includes(this.status);
    this.isFinished =
      STEP_STOPPED_STATUSES.includes(this.status) ||
      STEP_FINISHED_STATUSES.includes(this.status);
    this.isRunning = this.hasStarted && !this.isFinished;
    this.hasValidationErrors = hasValidationErrors(json.problems ?? {});
    this.hasValidationwarnings = hasValidationWarnings(json.problems ?? {});
    this.hasOnlySecurityDeploymentErrors = hasOnlySecurityDeploymentErrors(json.problems ?? {})
  }
}
