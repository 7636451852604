import { TagSmall, Tooltip } from '../../../common';
import React from 'react';
import { useTagColorStyles } from './styles';

export function TagHead() {
  const classes = useTagColorStyles();
  return <TagSmall className={classes.head}>head</TagSmall>;
}

export function TagSchedule({ buildVersion }: { buildVersion: string }) {
  const classes = useTagColorStyles();
  return (
    <Tooltip title={`retriggered from: ${buildVersion}`}>
      <TagSmall className={classes.schedule}>schedule</TagSmall>
    </Tooltip>
  );
}

export function TagRetrigger({ buildVersion }: { buildVersion: string }) {
  const classes = useTagColorStyles();
  return (
    <Tooltip title={`retriggered from: ${buildVersion}`}>
      <TagSmall className={classes.retrigger}>retrigger</TagSmall>
    </Tooltip>
  );
}

export function TagDependency({
  buildVersion,
  dependency,
}: {
  buildVersion: string;
  dependency: string;
}) {
  const classes = useTagColorStyles();
  return (
    <Tooltip
      title={`Triggered by: ${dependency} dependency, from: ${buildVersion}`}
    >
      <TagSmall className={classes.dependency}>dependency</TagSmall>
    </Tooltip>
  );
}

export function GitHubTag({ name }: { name: string }) {
  const classes = useTagColorStyles();
  return (
    <Tooltip title="GitHub tag">
      <TagSmall className={classes.ghe}>{name}</TagSmall>
    </Tooltip>
  );
}
