import { IActivityPod } from '../../api/types/payload';
import { PodContainerModel } from './podContainer.model';

export class PodModel {
  created_at: string;
  id: string;
  containers: PodContainerModel[];

  constructor(json: IActivityPod = {} as IActivityPod) {
    this.created_at = json.created_at ?? '';
    this.id = json.id ?? '';
    this.containers = (json.containers ?? []).map(
      container => new PodContainerModel(container),
    );
  }
}
