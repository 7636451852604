import { createApiRef } from '@backstage/core-plugin-api';
import { Image, ImageTags } from './response';

export const containerImagesApiRef = createApiRef<ContainerImagesApi>({
  id: 'container-images-api',
});

export interface ContainerImagesApi {
  getImage(registry: string, path: string, tag: string): Promise<Image>;
  getImageTags(registry: string, path: string): Promise<ImageTags>;
}
