import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Home, Versions, Runs, RunDetails, VersionDetails } from './pages';
import { DashboardVersions } from './pages/Dashboards';

export const ml_experimentation_routes = [
  {
    path: '/projects/:projectId/notebooks/*',
    key: 'notebooks',
    element: <Home />,
  },
  {
    path: '/projects/:projectId/notebooks/shared_notebooks/:sharedNotebookId/versions',
    key: 'versions',
    element: <Versions />,
  },
  {
    path: '/projects/:projectId/notebooks/scheduled_notebooks/:scheduledNotebookId/runs',
    key: 'runs',
    element: <Runs />,
  },
  {
    path: '/projects/:projectId/notebooks/shared_notebooks/:sharedNotebookId/versions/:versionId',
    key: 'version_details',
    element: <VersionDetails />,
  },
  {
    path: '/projects/:projectId/notebooks/shared_notebooks/:sharedNotebookId/versions/:versionId/interactive',
    key: 'version_details_interactive',
    element: <VersionDetails interactive />,
  },
  {
    path: '/projects/:projectId/notebooks/scheduled_notebooks/:scheduledNotebookId/runs/:runId',
    key: 'run_details',
    element: <RunDetails />,
  },
  {
    path: '/projects/:projectId/notebooks/scheduled_notebooks/:scheduledNotebookId/runs/:runId/interactive',
    key: 'run_details_interactive',
    element: <RunDetails interactive />,
  },
  {
    path: '/projects/:projectId/notebooks/r_shiny_apps/:dashboardId/versions',
    key: 'notebooks',
    element: <DashboardVersions />,
  },
];

export const MLExperimentationRouter = () => {
  return (
    <Routes>
      {ml_experimentation_routes.map(props => (
        <Route {...props} />
      ))}
      <Route path="*" element={<Navigate to="/ml/projects" />} />
    </Routes>
  );
};
