import { IconButton } from '@material-ui/core';
import React from 'react';
import { Tooltip } from '../../../common';
import { useActionStyles } from './styles';

type Props = {
  text: string;
  color?: string;
  action: () => void;
  children: React.ReactElement;
  disabled: boolean;
};

export function QuickActionButton({
  text,
  children,
  color,
  action,
  disabled,
}: Props) {
  const classes = useActionStyles({ color: color });

  return (
    <IconButton
      className={classes.root}
      size="small"
      disabled={disabled}
      onClick={action}
    >
      <Tooltip title={text}>{children}</Tooltip>
    </IconButton>
  );
}
