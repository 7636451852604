import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import axios from 'axios';
import { DeploymentModel } from '../models';

interface ICyberweekApi {
  getDeployments: () => Promise<Array<DeploymentModel>>;
  getIncidents: (
    page: number,
    size: number,
  ) => Promise<ICyberweek.IncidentsResponse>;
}

export const cyberweekApiRef = createApiRef<ICyberweekApi>({
  id: 'plugin.cyberweek',
});

export class CyberweekApi implements ICyberweekApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async getDeployments() {
    const token = await this.oauth2Api.getAccessToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('cyberweek');
    const apiUrl = `${baseUrl}/recent-deployments`;

    const response = await axios.get<ICyberweek.DeploymentsResponse>(apiUrl, {
      headers: { authorization: `Bearer ${token}` },
    });

    return (response.data ?? []).map(item => new DeploymentModel(item));
  }

  async getIncidents(page: number, page_size: number) {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/incident-status-api/incidents`;

    const resp = await axios.get<ICyberweek.IncidentsResponse>(apiUrl, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        page,
        page_size,
        track_open_incidents: true,
        sort: '-detected',
        q: "detected>'2019-06-01'",
      },
    });

    return resp.data;
  }
}
