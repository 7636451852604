export function toHumanReadableDataSize(bytes: number): String {
  const KiB_BASE = 1024;
  const MiB_BASE = 1049e3;

  if (bytes >= MiB_BASE) return `${(bytes / MiB_BASE).toFixed(2)} MiB`;

  return `${(bytes / KiB_BASE).toFixed(2)} KiB`;
}

export function toISODateTime(date: Date): string {
  const [dateString, timestamp] = date.toISOString().split('T');
  return `${dateString} ${timestamp.split(':').slice(0, 2).join(':')}`;
}
