import { useAsync } from 'react-use';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserTeams } from 'plugin-ui-components';

import { getRepsitoriesFromEntities } from './utils';

export function useWidgetRepositories() {
  const {
    value: { userTeams },
  } = useUserTeams();
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);

  return useAsync(() => {
    if (userTeams?.length) {
      const filters = userTeams.map(team => ({
        'spec.owner': team.spec.team_id,
      }));
      return catalogApi
        .getEntities({ filter: filters })
        .then(res => getRepsitoriesFromEntities(res.items))
        .catch(errorApi.post);
    }
    return Promise.resolve([]);
  }, [catalogApi, errorApi, userTeams]);
}
