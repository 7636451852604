/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Typography } from '@material-ui/core';
import { useCostInsightsStyles } from '../../utils/styles';
import { Group } from '../../types';
import { Link } from '@backstage/core-components';

type CostInsightsHeaderProps = {
  owner: string;
  groups: Group[];
  hasCostData: boolean;
  alerts: number;
};

const CostInsightsHeaderNoData = ({
  owner,
  groups,
}: CostInsightsHeaderProps) => {
  const classes = useCostInsightsStyles();
  const hasMultipleGroups = groups.length > 1;

  return (
    <>
      <Typography className={classes.h6Subtle} align="center" gutterBottom>
        <b>{owner}</b> doesn't seem to have any costs.
      </Typography>
      {hasMultipleGroups && (
        <Typography align="center" gutterBottom>
          Maybe we picked the wrong team, choose another from the menu above?
        </Typography>
      )}
    </>
  );
};

const CostInsightsHeaderAlerts = ({
  owner,
  alerts,
}: CostInsightsHeaderProps) => {
  const classes = useCostInsightsStyles();

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        <span role="img" aria-label="magnifying-glass">
          🔎
        </span>{' '}
        You have {alerts} thing{alerts > 1 && 's'} to look into
      </Typography>
      <Typography className={classes.h6Subtle} align="center" gutterBottom>
        We've identified {alerts > 1 ? 'a few things ' : 'one thing '}
        <b>{owner}</b> should look into next.
      </Typography>
    </>
  );
};

export const CostInsightsHeaderNoGroups = () => {
  const classes = useCostInsightsStyles();
  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        <span role="img" aria-label="flushed-face">
          😳
        </span>{' '}
      </Typography>
      <Typography variant="h4" align="center" gutterBottom>
        It appears that the team you are a member of is not the owner of any
        infrastructure for which costs are currently reported.
      </Typography>
      <Typography className={classes.h6Subtle} align="center" gutterBottom>
        If you need to see costs of teams that you are responsible for, but
        aren't directly a member of, then update your{' '}
        <Link to="/settings/personalization">personalization settings</Link> to
        "watch" content from the delivery teams you collaborate with, and that
        will enable you to view it here.
      </Typography>
    </>
  );
};

export const CostInsightsHeader = (props: CostInsightsHeaderProps) => {
  if (!props.hasCostData) {
    return <CostInsightsHeaderNoData {...props} />;
  }
  if (props.alerts) {
    return <CostInsightsHeaderAlerts {...props} />;
  }
  return <></>;
};
