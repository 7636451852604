import {
  ApiRef,
  BackstageIdentityApi,
  createApiRef,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';

type OAuthDeps = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  SessionApi &
  BackstageIdentityApi;

/**
 * NOTE: This new set up is described in the deprecation log of Backstage in the link below
 * @link https://backstage.io/docs/api/deprecations#generic-auth-api-refs
 */
export const oauth2ApiRef: ApiRef<OAuthDeps> = createApiRef({
  id: 'internal.auth.oauth',
});
