import { action, makeObservable, observable } from 'mobx';
import { ErrorApi } from '@backstage/core-plugin-api';
import { ScalyrApi } from '../api';
import { LogsFoldModel, LogsModel, RunStepRunModel } from '../models';
import { BadRequestException } from '../api/exceptions';
import { addMinutesToDateString } from '../utils/time';
import { LOGS_LINES_LIMIT } from '../constants';
import { LogsState } from '../models/logs/logs.state';
import { State } from './helpers/state';

enum ResponseStatusEnum {
  Idle,
  Failed,
  Successful,
}

export interface ILogsService {
  logsState: LogsState;
  logs: LogsModel;
  downloadLinkState: State;
  downloadLink: string;
  updateFinalState: () => void;
  loadPendingLines: (run: RunStepRunModel) => void;
  getFolds: (run: RunStepRunModel) => void;
  getLines: (fold: LogsFoldModel, run: RunStepRunModel) => void;
  getDownloadLink: (run: RunStepRunModel) => Promise<void>;
  clean: () => void;
}

export class LogsService implements ILogsService {
  // Observables
  logsState: LogsState = new LogsState();
  logs: LogsModel = new LogsModel();
  downloadLinkState: State = new State();
  downloadLink: string = '';

  // Internals
  private lastFoldRequestStatus: ResponseStatusEnum = ResponseStatusEnum.Idle;

  constructor(private scalyrApi: ScalyrApi, private errorApi: ErrorApi) {
    makeObservable(this, {
      logs: observable,
      logsState: observable,
      downloadLink: observable,
      downloadLinkState: observable,
      updateFinalState: action,
      loadPendingLines: action,
      getFolds: action,
      getLines: action,
      getDownloadLink: action,
      clean: action,
    });
  }

  // Actions
  updateFinalState(): void {
    switch (this.lastFoldRequestStatus) {
      case ResponseStatusEnum.Successful:
        if (this.logs.folds.length === 0) {
          this.logsState.setNotAvailable();
        }
        break;
      case ResponseStatusEnum.Failed:
        // Don't override existing Un-recoverable message
        if (!this.logsState.isUnrecoverable && this.logs.folds.length === 0) {
          this.logsState.setUnrecoverable(
            'Unable to fetch folds from Scalyr due to a client side connectivity issue.',
          );
        }
        break;
      default:
    }
  }

  async loadPendingLines(run: RunStepRunModel): Promise<void> {
    const firstPendingFold = this.logs.folds.find(
      fold => fold.shouldPoll && !fold.warning,
    );

    if (firstPendingFold) {
      this.getLines(firstPendingFold, run);
    }
  }

  async getFolds(run: RunStepRunModel): Promise<void> {
    this.logsState.setLoading();

    try {
      const endTime = run.isFinished
        ? run.finished_at
        : new Date().toISOString();

      const data = await this.scalyrApi.getFolds(
        addMinutesToDateString(run.created_at, -1).toISOString(),
        addMinutesToDateString(endTime, 1).toISOString(),
        run.id,
      );

      this.logs.upsertFolds(data, run.isFinished);
      this.lastFoldRequestStatus = ResponseStatusEnum.Successful;
      this.logsState.setLoaded();
    } catch (error) {
      const e = error as Error;
      this.lastFoldRequestStatus = ResponseStatusEnum.Failed;
      if (e instanceof BadRequestException) {
        this.logsState.setUnrecoverable(e.message);
        return;
      }

      this.logsState.setRecoverable();
    }
  }

  async getLines(fold: LogsFoldModel, run: RunStepRunModel): Promise<void> {
    fold.linesState.setLoading();

    try {
      const data = await this.scalyrApi.getLines(
        fold.name,
        fold.getFromTimestamp,
        this.logs.getToTimestamp(fold, run.isFinished, run.finished_at),
        run.id,
        this.logs.getTotalDisplayedLines,
        LOGS_LINES_LIMIT,
      );

      data.forEach(response => fold.insertLines(response.matches, true));
      fold.linesState.setLoaded();
    } catch (e) {
      if (e instanceof BadRequestException) {
        fold.linesState.setUnrecoverable(e.message);

        return;
      }

      fold.linesState.setRecoverable();
    }
  }

  getDownloadLink = async (run: RunStepRunModel): Promise<void> => {
    this.downloadLinkState.setLoading();

    try {
      const endTime = run.isFinished
        ? run.finished_at
        : new Date().toISOString();

      this.downloadLink = await this.scalyrApi.getDownloadLink(
        addMinutesToDateString(run.created_at, -1),
        addMinutesToDateString(endTime, 1),
        run.id,
      );

      this.downloadLinkState.setLoaded();
    } catch (e) {
      this.errorApi.post(
        new Error(
          'An error occurred while trying to prepare the log file for download.',
        ),
      );
      this.downloadLinkState.setError();
    }
  };

  clean() {
    this.logs = new LogsModel();
    this.logsState = new LogsState();
    this.downloadLinkState = new State();
    this.lastFoldRequestStatus = ResponseStatusEnum.Idle;
  }
}
