import { IRunsMetadata } from '../../api/types/payload';

export class RunsMetadataModel {
  end_cursor: string;
  start_cursor: string;
  has_previous_page: boolean;
  has_next_page: boolean;

  constructor(json: IRunsMetadata = {} as IRunsMetadata) {
    this.end_cursor = json.end_cursor ?? '';
    this.start_cursor = json.start_cursor ?? '';
    this.has_previous_page = json.has_previous_page ?? false;
    this.has_next_page = json.has_next_page ?? false;
  }
}
