import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import thunderStormImage from './storm.gif';

const SUPPORT_URL =
  'https://github.bus.zalan.do/pitchfork/issues/issues/new?labels=bug-report,sunrise&template=bug_report.md&title=Bug+summary';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  textWrapper: {
    padding: theme.spacing(8),
  },
  micDrop: {
    maxWidth: '60%',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '96%',
      margin: `0 auto`,
    },
  },
}));

export function NotFoundPage() {
  const classes = useStyles();

  const goBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.history.back();
  };

  return (
    <div className={classes.container} data-testid="not-found">
      <div className={classes.textWrapper}>
        <Typography variant="body1">
          404 Page Not Found - Hmmmmm, that was unexpected.
        </Typography>
        <Typography variant="h2">
          We can't seem to find what you're looking for
        </Typography>
        <Typography variant="h4">
          <Link data-testid="go-back-link" href="#" onClick={goBack}>
            Go back
          </Link>
          ... or please&nbsp;
          <Link href={SUPPORT_URL} target="_blank" rel="noopener noreferrer">
            contact support
          </Link>
          &nbsp;if you think this is a bug.
        </Typography>
      </div>
      <img
        src={thunderStormImage}
        alt="An animated thunder storm"
        className={classes.micDrop}
      />
    </div>
  );
}
