import { IconComponent } from '@backstage/core-plugin-api';

export type MLResourceButtonGroup = {
  name: string;
  items: {
    id: string;
    label: string;
    icon?: IconComponent;
    count: number;
  }[];
};

export const getMLFilterGroups = (
  entityName: string,
  orgName: string,
  itemCount: {
    mine: number;
    all: number;
  },
) => [
  {
    name: 'Personal',
    items: [
      {
        id: 'mine',
        label: `My ${entityName}`,
        count: itemCount.mine,
      },
    ],
  },
  {
    name: orgName,
    items: [
      {
        id: 'all',
        label: `All ${entityName}`,
        count: itemCount.all,
      },
    ],
  },
];
