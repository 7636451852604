import React from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { Entity } from '@backstage/catalog-model';

interface Props extends IconButtonProps {
  entity: Entity;
}

export const FavouriteEntity: React.FC<Props> = props => {
  const { toggleStarredEntity, isStarredEntity } = useStarredEntities();
  const isStarred = isStarredEntity(props.entity);

  return (
    <IconButton
      {...props}
      color="inherit"
      data-testid="favorite-entity-btn"
      onClick={() => toggleStarredEntity(props.entity)}
      style={{ padding: 3 }}
    >
      <Tooltip
        title={isStarred ? 'Remove from favorites' : 'Add to favorites'}
        placement="top-end"
        arrow
      >
        {isStarred ? <StarIcon style={{ color: '#f3ba37' }} /> : <StarBorder />}
      </Tooltip>
    </IconButton>
  );
};
