import React from 'react';
import { getStatusIcon } from '../../components';

interface StatusTableCellProps {
  status: string;
}

export const StatusTableCell = (props: StatusTableCellProps) => {
  return (
    <>
      {getStatusIcon(props.status)} {props.status}
    </>
  );
};
