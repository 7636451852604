import React, { useState } from 'react';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Content, Table, TableColumn } from '@backstage/core-components';
import { tableHeaderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { sanitizeNulls } from '../../utils/sanitize';
import { MLModelTags } from '../../api/definitions';
import HelpIcon from '@material-ui/icons/Help';

const formatTags = (tags: any) => {
  delete tags.ZFLOW_TRAINING_JOB_IDS;
  return Object.keys(tags).map(key => {
    return {
      Key: key,
      Value: tags[key],
    };
  });
};

export const ModelTags = ({ tags }: { tags?: MLModelTags }) => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const sanitizedTags: Map<string, string>[] = tags ? sanitizeNulls(tags) : [];

  const formattedTags = formatTags(sanitizedTags);

  const tableData = {
    meta: {
      total: formattedTags.length,
    },
    tags: formattedTags,
  };

  const columns: TableColumn[] = [
    {
      title: (
        <>
          <Typography variant="subtitle2">
            Key
            <Tooltip title="Tags starting with ZFLOW are automatically added by zflow">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </>
      ),
      field: 'Key',
      filtering: false,
    },
    {
      title: 'Value',
      field: 'Value',
      filtering: false,
    },
  ];

  return (
    <Content>
      <Grid>
        <Table
          data={tableData.tags}
          columns={columns}
          options={{
            pageSize: PAGE_SIZE,
            filtering: false,
            padding: 'dense',
            showFirstLastPageButtons: true,
            emptyRowsWhenPaging: false,
            headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
          }}
          components={{
            Pagination: props => (
              <MLTablePagination
                props={props}
                page={page}
                resource={tableData}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
              />
            ),
            Toolbar: props => <MLTableToolbar props={props} />,
          }}
        />
      </Grid>
    </Content>
  );
};
