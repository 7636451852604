import React from 'react';
import { TablePagination } from '@material-ui/core';

export const MLTablePagination = ({
  props,
  page,
  resource,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
}: {
  props: any;
  page: number;
  resource: any;
  setPage: React.Dispatch<number>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<number>;
  rowsPerPageOptions: Array<number>;
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    event?.persist();
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    event?.persist();
    setPage(0);
    const newRowsPerPage = parseInt(event.target.value, 10) || 0;
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <TablePagination
      {...props}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      count={resource.meta.total}
      onPageChange={(event, newPage) => handleChangePage(event, newPage)}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export const MLTableToolbar = (_: any) => {
  return <div style={{ height: 0 }} />;
};
