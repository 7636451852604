import { Card as MaterialCard, styled } from '@material-ui/core';

export const Content = styled('div')({
  flex: 1,
});

export const Card = styled(MaterialCard)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
