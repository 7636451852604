import React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import HighlightIcon from '@mui/icons-material/Highlight';
import { useTheme } from '@material-ui/core';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { useLocalStorage } from 'react-use';
import { closeTour, completeTour, startTour } from './tracking';
import { FabStyles, getStyles } from './styles';
// These imports are specifically made this way because importing from the index file won't work in Storybook
import { PluginTracking } from '../Tracking/PluginTracking';
import { EventTracker } from '../Tracking/EventTracker';

interface TourComponentProps {
  steps: Step[];
  tourId: string;
}

export interface IStep {
  target: string;
  content: string;
  disableBeacon: string;
}

/**
 * A component that renders a tour of elements on the page for the user to go through
 */
export function TourComponent({ steps, tourId }: TourComponentProps) {
  const theme = useTheme();
  const [run, setRun] = React.useState(false);
  const [fabState, setFabState] = React.useState<'circular' | 'extended'>(
    'circular',
  );
  const [stepIndex, setStepIndex, _] = useLocalStorage<{
    tourId: string;
    value: number;
  }>('tourStep');

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, index, type } = data;
    const finishedStatus: string[] = [STATUS.FINISHED];
    const advanceEvent: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

    // closing the popup saves where user stopped the tour
    if (action === 'close' || finishedStatus.includes(status)) {
      setStepIndex({ tourId, value: +index.toString() });
      setRun(false);
      // send tracking event
      PluginTracking.sendEvent(
        closeTour(tourId, steps.length, index.toString()),
      );
    }

    // move to next / previous step
    if (advanceEvent.includes(type)) {
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (nextIndex === steps.length) {
        setRun(false);
        setStepIndex({ tourId, value: 0 });
        // tour is complete: send tracking event
        PluginTracking.sendEvent(completeTour(tourId, steps.length));
      } else {
        setStepIndex({ tourId, value: +nextIndex });
      }
    }
  };

  const getStepIndex = (): number => {
    if (stepIndex?.value === steps.length || !stepIndex) return 0;
    return stepIndex.value;
  };

  return (
    <>
      <>
        <Joyride
          callback={handleJoyrideCallback}
          steps={steps}
          stepIndex={getStepIndex()}
          continuous
          showProgress
          styles={getStyles(theme)}
          run={run}
          scrollToFirstStep
          disableScrollParentFix
        />
        <Box position="fixed" top="90%" right="3rem" zIndex="100">
          <EventTracker {...startTour(tourId, steps.length)}>
            <Fab
              color="primary"
              aria-label="add"
              data-testid="action-button"
              style={{ transition: '100ms' }}
              size="small"
              variant={fabState}
              onMouseEnter={() => setFabState('extended')}
              onMouseLeave={() => setFabState('circular')}
              id="floating-button-tour"
              onClick={() => {
                setRun(!run);
              }}
            >
              <HighlightIcon />
              {fabState === 'extended' && (
                <Typography style={FabStyles}>
                  Take me through the different elements of this page
                </Typography>
              )}
            </Fab>
          </EventTracker>
        </Box>
      </>
    </>
  );
}
