import { action, makeObservable, observable } from 'mobx';

export interface IMetaService {
  title: string;
  setTitle: (value: string) => void;
}

export class MetaService implements IMetaService {
  // Observables
  title: string = '';

  constructor() {
    makeObservable(this, {
      title: observable,
      setTitle: action,
    });
  }

  // Actions
  public setTitle = (value: string = '') => {
    this.title = `${value} | CDP`;
  };
}
