import { Button, styled } from '@material-ui/core';
import { fontSize } from '../../../../../common';

export const DockerArtifact = styled('div')(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexFlow: 'nowrap',
  width: '100%',

  ['&:not(:first-child)']: {
    marginTop: props.theme.spacing(1),
  },
}));

export const Content = styled('div')(props => ({
  lineHeight: 1,
  marginRight: props.theme.spacing(1),

  ['& > *']: {
    margin: props.theme.spacing(0.5, 0.5),
    verticalAlign: 'middle',
  },
}));

export const Action = styled(Button)(props => ({
  fontSize: fontSize.tiny,
  padding: props.theme.spacing(0, 1),
}));
