import {
  discoveryApiRef,
  configApiRef,
  createApiFactory,
  createPlugin,
} from '@backstage/core-plugin-api';
import {
  cdpApiRef,
  CDPClient,
  scalyrApiRef,
  ScalyrClient,
  zesApiRef,
  ZesClient,
} from './api';
import { GRAPH_FEATURE_FLAG } from './constants';
import { pipelineApiRef, PipelineClient } from './api';

export const plugin = createPlugin({
  id: 'cdp',
  featureFlags: [{ name: GRAPH_FEATURE_FLAG }],
  apis: [
    createApiFactory({
      api: cdpApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new CDPClient({ discoveryApi }),
    }),
    createApiFactory({
      api: scalyrApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new ScalyrClient({ discoveryApi }),
    }),
    createApiFactory({
      api: pipelineApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new PipelineClient({ discoveryApi }),
    }),
    createApiFactory({
      api: zesApiRef,
      deps: { discoveryApi: discoveryApiRef, configApi: configApiRef },
      factory: ({ discoveryApi }) => new ZesClient({ discoveryApi }),
    }),
  ],
});
