import { useEffect, useState } from 'react';
import { useTeamsContext } from '../context';
import cloneDeep from 'lodash/cloneDeep';

interface HookProps {
  user?: string;
  include?: {
    watched?: boolean;
    led?: boolean;
    accountable?: boolean;
    member?: boolean;
    unfollowed?: boolean;
  };
}

const initialValue: IUserTeamsValue = {
  userTeams: undefined,
  ledTeams: undefined,
  watchedTeams: undefined,
  accountableTeams: undefined,
  unfollowedTeams: undefined,
  memberTeams: undefined,
};

interface IState {
  retry: VoidFunction;
  loading: boolean;
  error?: Error;
  value: IUserTeamsValue;
}

/**
 * Get the teams that the user is part of
 * @returns A list of team entities in useAsync response format
 */
export function useUserTeams({
  user,
  include = {
    led: true,
    watched: true,
    accountable: true,
    member: true,
    unfollowed: false,
  },
}: HookProps = {}) {
  const [state, setState] = useState<IState>({
    loading: false,
    retry: () => {},
    value: initialValue,
  });

  const { currentUserTeams, customUserTeams, setCustomUser } =
    useTeamsContext();

  useEffect(() => {
    if (user) setCustomUser(user);
  }, [user, setCustomUser]);

  useEffect(() => {
    const newState = cloneDeep(
      user ? customUserTeams : currentUserTeams,
    ) as IState;
    if (!newState.value) {
      newState.value = initialValue;
    } else {
      newState.value.userTeams = [];
    }

    if (newState?.value) {
      // Eliminate duplicates
      const userTeamsMap = new Map();
      const newValue: IUserTeamsValue = {
        ...newState.value,
        userTeams: newState.value.memberTeams
          ? [...newState.value.memberTeams]
          : undefined,
      };

      // Merge lists based on the include spec
      const setter = (t: IEntityGroup) => userTeamsMap.set(t.metadata.uid, t);

      if (include?.member && newState.value.memberTeams)
        newState.value.memberTeams.forEach(setter);
      if (include?.led && newState.value.ledTeams)
        newState.value.ledTeams.forEach(setter);
      if (include?.watched && newState.value.watchedTeams)
        newState.value.watchedTeams.forEach(setter);
      if (include?.accountable && newState.value.accountableTeams) {
        if (include?.unfollowed) {
          newState.value.accountableTeams.forEach(setter);
        } else {
          newState.value.accountableTeams
            .filter(
              team =>
                newState.value.unfollowedTeams &&
                !newState.value.unfollowedTeams.find(
                  t => t.metadata.uid === team.metadata.uid,
                ),
            )
            .forEach(setter);
        }
      }

      newValue.userTeams = Array.from(userTeamsMap.values());
      setState({ ...newState, value: newValue });
    } else {
      setState({ ...newState, value: initialValue });
    }
  }, [
    user,
    include?.accountable,
    include?.led,
    include?.watched,
    include?.member,
    include?.unfollowed,
    currentUserTeams,
    customUserTeams,
  ]);

  return state;
}
