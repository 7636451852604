import React from 'react';
import { Grid } from '@material-ui/core';
import { ConsistsOfWidget } from './ConsistsOfWidget';
import { AboutWidget } from './AboutWidget';
import { ResourceLinksWidget } from './ResourceLinks';

export function DomainOverview() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <AboutWidget />
      </Grid>
      <Grid item xs={12} md={6}>
        <ResourceLinksWidget />
        <br />
        <ConsistsOfWidget />
      </Grid>
    </Grid>
  );
}
