import { makeStyles, type Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  errorAccordionRoot: {
    borderRadius: 4,
    border: '1px solid #ffe0dc',
    backgroundColor:
      theme.palette.type === 'light' ? 'rgb(253, 236, 234)' : 'rgb(24, 6, 5)',

    '&.Mui-expanded': {
      margin: 0,
      minHeight: 40,
    },

    '&::before': {
      display: 'none',
    },
  },
  errorSummaryRoot: {
    minHeight: 40,

    '&.Mui-expanded': {
      minHeight: 40,
    },
  },
  errorSummaryContent: {
    margin: `0 !important`,
  },
  errorSummaryExpandIcon: {
    paddingBlock: 0,
  },
}));
