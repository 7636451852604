import React from 'react';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface KubeDashboardDetailsProps {
  name: string;
  type: 'ingresses' | 'pods';
}

export function KubeDashboardDetailsLink({
  name,
  type,
}: KubeDashboardDetailsProps) {
  const kubeDashboardLink = `https://kube-resource-report.stups.zalan.do/application-${name}.html#${type}`;
  return (
    <>
      {kubeDashboardLink && (
        <>
          &nbsp;(
          <Link to={kubeDashboardLink}>
            view details <OpenInNewIcon />
          </Link>
          )
        </>
      )}
    </>
  );
}
