import React, { useState } from 'react';
import {
  PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { Grid, useTheme } from '@material-ui/core';
import { Content, Table, TableColumn } from '@backstage/core-components';
import { tableHeaderStyles } from '../../styles';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { MLModelEthicalConsiderations } from '../../api/definitions';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

export const ModelEthicalConsiderations = ({
  ethical_considerations,
}: {
  ethical_considerations: MLModelEthicalConsiderations[] | undefined;
}) => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(PAGE_SIZE);

  const tableData = {
    meta: {
      total: ethical_considerations?.length,
    },
    ethical_considerations: ethical_considerations,
  };

  const columns: TableColumn[] = [
    {
      title: 'Risk',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Mitigation Strategy',
      field: 'mitigation_strategy',
      filtering: false,
    },
  ];

  return (
    <Content>
      <Grid>
        {ethical_considerations ? (
          <Table
            data={ethical_considerations}
            columns={columns}
            options={{
              pageSize: PAGE_SIZE,
              filtering: false,
              showFirstLastPageButtons: true,
              emptyRowsWhenPaging: false,
              headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
            }}
            components={{
              Pagination: props => (
                <MLTablePagination
                  props={props}
                  page={page}
                  resource={tableData}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
                />
              ),
              Toolbar: props => <MLTableToolbar props={props} />,
            }}
          />
        ) : (
          <MissingFieldPlaceholder
            field="ethical consideration"
            type="model-card"
          />
        )}
      </Grid>
    </Content>
  );
};
