import React from 'react';

import { Tooltip, PRIcon } from '../../../common';
import * as S from './styles';

type Props = {
  prNumber: number;
  repo: string;
};

export function PullRequestTag({ prNumber, repo }: Props) {
  return (
    <S.Text>
      <PRIcon />

      <Tooltip
        title={`Pull request number: ${prNumber}`}
        placement="bottom-start"
      >
        <a
          href={`https://${repo}/pull/${prNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {prNumber}
        </a>
      </Tooltip>
    </S.Text>
  );
}
