import {
  observable,
  action,
  computed,
  IObservableArray,
  makeObservable,
} from 'mobx';

import { IDeliveryConfigurationParameter } from '../../../../../api/types/payload';
import { matchPattern, matchType } from './validation';

export class GenericFieldModel {
  name: string;
  valueType: 'string' | 'boolean';
  fieldType: 'string' | 'boolean' | 'select';
  default: boolean | string;
  pattern: string;
  options: Array<string>;

  value: boolean | string;
  warnings: IObservableArray<string>;
  errors: IObservableArray<string>;
  patternIsValid: boolean;
  touched: boolean;

  constructor(
    parameter: IDeliveryConfigurationParameter = {} as IDeliveryConfigurationParameter,
  ) {
    this.name = parameter.name ?? '';
    this.valueType = parameter.type ?? 'string';
    this.default = parameter.default ?? '';
    this.pattern = parameter.pattern ?? '';
    this.options = parameter.enum ?? [];

    this.value = this.default;
    this.warnings = observable<string>([]);
    this.errors = observable<string>([]);
    this.patternIsValid = true;
    this.touched = false;

    // Set type to `select` if enum contains an array of options
    this.fieldType = this.options.length > 0 ? 'select' : this.valueType;

    // Test validation regex
    try {
      // eslint-disable-next-line no-new
      new RegExp(this.pattern);
    } catch (e) {
      this.warnings.push(
        'Regex Error: client validation is disabled for this field.',
      );
      this.patternIsValid = false;
    }

    // Run Initial validation
    this.validate();

    makeObservable(this, {
      value: observable,
      touched: observable,
      errors: observable,
      warnings: observable,
      setValue: action,
      validate: action,
      isValid: computed,
    });
  }

  setValue = (value: string | boolean) => {
    this.value = value;
    this.touched = true;
  };

  validate = () => {
    const errors: Array<string> = [];

    const validations = this.patternIsValid
      ? [matchPattern(this.pattern), matchType(this.valueType)]
      : [matchType(this.valueType)];

    validations.forEach(func => {
      const error = func(this.value);
      if (error === undefined) return;
      errors.push(error);
    });

    this.errors.replace(errors);
  };

  get isValid() {
    return typeof this.value === this.valueType && this.errors.length === 0;
  }
}
