import yaml from 'js-yaml';

export function getApiDescription(definition: string = '') {
  let def;

  try {
    def = yaml.load(definition) || JSON.parse(definition);
  } catch (error) {
    return `Unfortunately we aren't able to parse the description of your API.`;
  }

  const description =
    def.info?.description ||
    def.info?.summary ||
    def.info?.['x-summary'] ||
    'No Description :(';

  return description;
}
