import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { notebooksApiRef } from '../../api';
import { ListEntities, ActionsTableCell } from '../../components';
import { NotebooksProps } from '../NotebooksProps';
import { constructLinkToDataLabNotebook } from '../../utils';
import { ScheduledNotebookNextRunDates } from '../../components/ScheduledNotebookNextRunDates';
import ReactTooltip from 'react-tooltip';
import { SubvalueCell } from '@backstage/core-components';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';
import { RuleSwitch } from '../../components/RuleSwitch';

export const ScheduledNotebooks = (props: NotebooksProps) => {
  const { projectId } = props;
  const notebookClient = useApi(notebooksApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [archiveNotebookError, setArchiveNotebookError] = useState<Error>();

  const onDelete = async (event: React.SyntheticEvent, id: string) => {
    event.stopPropagation();
    try {
      await notebookClient.deleteScheduledNotebook(id);
    } catch (err) {
      setArchiveNotebookError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const onSwitch = async (scheduledNotebookId: string, disabled: boolean) => {
    try {
      await notebookClient.updateScheduledNotebook(
        scheduledNotebookId,
        disabled,
      );
    } catch (err) {
      setArchiveNotebookError(err as Error);
    }
  };

  const COLUMNS = [
    {
      title: 'Rule Status',
      render: (row: any): React.ReactNode => (
        <RuleSwitch id={row.id} disabled={row.disabled} onSwitch={onSwitch} />
      ),
      width: '12%',
    },
    {
      title: 'Scheduled Notebook',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Cron Expression',
      field: 'cron_expression',
      render: (row: any) => (
        <div>
          <p data-tip data-for={row.id}>
            {row.cron_expression}
          </p>
          <ReactTooltip id={row.id}>
            {row.cron_expression && (
              <ScheduledNotebookNextRunDates
                cronExpression={row.cron_expression}
              />
            )}
          </ReactTooltip>
        </div>
      ),
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="scheduled_notebooks"
          id={row.id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="runs"
          onDelete={onDelete}
        />
      ),
    },
  ];
  return (
    <ListEntities
      getEntitiesPromise={notebookClient.getScheduledNotebooks(projectId, page)}
      entityKey="scheduled_notebooks"
      columns={COLUMNS}
      childEntityKey="runs"
      refreshList={refreshList}
      page={page}
      onPageChange={setPage}
      error={archiveNotebookError}
      setError={setArchiveNotebookError}
    />
  );
};
