import React from 'react';
import { MLModel } from '../../../api/definitions';
import { InfoCard } from 'plugin-ui-components';
import { ModelGraphic } from './ModelGraphic';

export const ModelGraphics = ({ model }: { model: MLModel }) => {
  return (
    <>
      <InfoCard
        title="Training Dataset Graphics"
        subheader={model.model_parameters?.data?.train?.graphics?.description}
      >
        <ModelGraphic
          model_set_graphic={model.model_parameters?.data?.train?.graphics}
          name="Training"
        />
      </InfoCard>

      <InfoCard
        title="Evaluation Dataset Graphics"
        subheader={model.model_parameters?.data?.eval?.graphics?.description}
      >
        <ModelGraphic
          model_set_graphic={model.model_parameters?.data?.eval?.graphics}
          name="Evaluation"
        />
      </InfoCard>

      <InfoCard
        title="Quantitative Analysis Graphics"
        subheader={model.quantitative_analysis?.graphics?.description}
      >
        <ModelGraphic
          model_set_graphic={model.quantitative_analysis?.graphics}
          name="Quantitative Analysis"
        />
      </InfoCard>
    </>
  );
};
