import { TableColumn } from '@backstage/core-components';
import { stringifyEntityRef } from '@backstage/catalog-model';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { applicationColumns } from 'plugin-ui-components';
import { getColor } from '../../utils';
import React from 'react';

function makeAssessmentColumn(
  scorecard: IEntityScorecard,
  assessments: IEntityScorecardAssessment[],
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Assessment',
    render: ({ entity }) => {
      const exclusion = scorecard.spec.exclusions.find(
        e => e.entity_ref === stringifyEntityRef(entity),
      );
      if (exclusion) {
        return (
          <Tooltip title={`Reason: ${exclusion.reason}`}>
            <Chip
              size="small"
              variant="outlined"
              className="info-chip"
              label="Skipped"
              sx={{ cursor: 'help' }}
            />
          </Tooltip>
        );
      }
      const assessment = assessments.find(
        a => a.spec.assessment.entityRef === stringifyEntityRef(entity),
      );
      if (!assessment) {
        return (
          <Chip
            size="small"
            variant="outlined"
            label="N/A"
            className="info-chip"
          />
        );
      }

      const formatter = new Intl.NumberFormat('en', {
        style: 'percent',
        maximumFractionDigits: 0,
      });
      const completion = assessment.spec.assessment.completionPercentage;

      return (
        <Chip
          size="small"
          variant="outlined"
          label={formatter.format(completion / 100)}
          className={`info-chip ${getColor(completion)}`}
        />
      );
    },
  };
}

interface MakeColumnsProps {
  scorecard: IEntityScorecard;
  assessments: IEntityScorecardAssessment[];
}

export const makeColumns = ({ scorecard, assessments }: MakeColumnsProps) => [
  ...applicationColumns('asc', ['prr', 'star', 'scorecards']),
  makeAssessmentColumn(scorecard, assessments),
];
