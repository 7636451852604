import { StatusAborted, StatusOK } from '@backstage/core-components';
import { Schedule } from '../../types';
import { ResponderTitleFormatter } from './OnCallList';
import React from 'react';
import { Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import { OnCallForScheduleList } from './OnCallScheduleList';

export const OnCallForScheduleCard = ({
  schedule,
  responderFormatter,
  date,
}: {
  schedule: Schedule;
  responderFormatter?: ResponderTitleFormatter;
  date?: string;
}) => {
  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={schedule.enabled ? 'Enabled' : 'Disabled'}>
        <div>{schedule.enabled ? <StatusOK /> : <StatusAborted />}</div>
      </Tooltip>
      {schedule.ownerTeam.name}
    </div>
  );

  return (
    <Card>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <OnCallForScheduleList
          schedule={schedule}
          responderFormatter={responderFormatter}
          date={date}
        />
      </CardContent>
    </Card>
  );
};
