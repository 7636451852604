export default `
query Meta {
  meta {
    application_ids
    deployment_environments {
      deployment_environment_id
      cluster_registry_id
      type
      production_status
      name
    }
    totals
  }
}
`;
