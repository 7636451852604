import React from 'react';
import { Divider, Typography, CardContent } from '@material-ui/core';
import * as S from '../styles';

export const CommentsCard = ({
  comments,
}: {
  comments: string | undefined;
}) => (
  <>
    {!!comments && (
      <S.StyledCard>
        <S.StyledCardHeader
          title="Comments"
          titleTypographyProps={{ component: 'h2' } as any}
        />
        <Divider />
        <CardContent>
          <Typography>{comments}</Typography>
        </CardContent>
      </S.StyledCard>
    )}
  </>
);
