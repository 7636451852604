import { useState, useCallback } from 'react';
import { Library } from '../types';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const useLibraryUsers = (library?: Library) => {
  const [libraryUsers, setLibraryUsers] = useState<IEntityGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const catalogApi = useApi(catalogApiRef);

  const fetchLibraryUsers = useCallback(async () => {
    setLoading(true);
    if (library) {
      const ownedByNames = library.applications.flatMap(app =>
        app !== undefined && app.relations
          ? app.relations
              .filter(relation => relation.type === 'ownedBy')
              .map(relation => relation.targetRef)
          : [],
      );

      const uniqueOwnedByNames = Array.from(new Set(ownedByNames));

      const entities = await catalogApi.getEntitiesByRefs({
        entityRefs: uniqueOwnedByNames,
      });

      setLibraryUsers(entities.items as IEntityGroup[]);
    }
    setLoading(false);
  }, [library, catalogApi]);

  return { libraryUsers, loading, fetchLibraryUsers };
};
