import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { colors, useHoverAndCopy } from 'plugin-ui-components';
import { TagMedium } from 'plugin-cdp';
import { MarkdownContent } from '@backstage/core-components';
import { Grid, Item } from '../Grid';
import { useStyles } from './styles';
import { Status } from './Status';
import { InfoButton } from '../InfoButton';
import { getRegistryName } from './repo';
import { ImageModel, ImagePlatformInfoModel } from '../../models';
import { IMAGE_APP_LABEL } from '../../constants';

type Props = {
  image: ImageModel;
  platform: ImagePlatformInfoModel;
};

export function Summary({ image, platform }: Props) {
  const { registry = '', namespace = '', name = '', name2 = '' } = useParams();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { copyText, copyToClipboard } = useHoverAndCopy();
  const classes = useStyles();

  function handleClose() {
    setIsModalOpen(false);
  }

  function handleOpen() {
    setIsModalOpen(true);
  }

  const tag = searchParams.get('tag') || image.tag;
  const application = platform.labels[IMAGE_APP_LABEL];

  const path = name2 ? `${namespace}/${name}/${name2}` : `${namespace}/${name}`;

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Image details
      </Typography>

      <Grid>
        <Item title="URL">
          <span>{`${registry}/${path}:${tag}`}</span>
          <Tooltip placement="top" title={copyText}>
            <FileCopyIcon
              className={classes.icon}
              onClick={() =>
                copyToClipboard(`${registry}/${path}:${image.tag}`)
              }
              fontSize="small"
              sx={{
                fontSize: 14,
                opacity: 0.5,
                cursor: 'pointer',
                marginLeft: 8,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </Tooltip>
        </Item>

        <Item title="Tag">
          <TagMedium color={colors.brand.primaryOrange}>{tag}</TagMedium>
        </Item>

        <Item title="Type">{image.mediaType}</Item>

        <Item title="Application">
          {application ? (
            <Link
              href={`/catalog/default/Component/${platform.labels[IMAGE_APP_LABEL]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {platform.labels[IMAGE_APP_LABEL]}
            </Link>
          ) : (
            <small className={classes.textLight}>
              <strong>{IMAGE_APP_LABEL}</strong> label is missing
            </small>
          )}
        </Item>

        <Item title="Registry">{getRegistryName(registry)}</Item>

        <Item title="Compliance status">
          <Box display="flex" alignItems="center">
            <Status success={image.compliance.status === 'production_ready'}>
              {image.compliance.status}
            </Status>
            <InfoButton action={handleOpen}>details</InfoButton>
          </Box>
        </Item>
      </Grid>

      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="Docker image status"
        aria-describedby="Markdown describing a status of a docker image"
      >
        <DialogContent>
          <MarkdownContent
            content={image.compliance.checker.details}
            dialect="gfm"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
