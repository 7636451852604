import React from 'react';
import { Chip, withStyles } from '@material-ui/core';
import { colors } from 'plugin-ui-components';

const P3Chip = withStyles({
  root: {
    backgroundColor: colors.semantic.warningBase,
    color: 'white',
  },
})(Chip);
const P2Chip = withStyles({
  root: {
    backgroundColor: colors.semantic.secondaryBase,
    color: 'white',
  },
})(Chip);
const P1Chip = withStyles({
  root: {
    backgroundColor: colors.semantic.errorBase,
    color: 'white',
  },
})(Chip);

export const SeverityChip = ({ severity }: { severity: string }) => {
  let chip = <></>;
  switch (severity) {
    case 'SEV3':
      chip = <P3Chip label={severity} size="small" />;
      break;
    case 'SEV2':
      chip = <P2Chip label={severity} size="small" />;
      break;
    case 'SEV1':
      chip = <P1Chip label={severity} size="small" />;
      break;
    default:
      chip = <span>N/A</span>;
  }

  return <div>{chip}</div>;
};
