import React, { useEffect, useRef } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useInterval } from 'react-use';
import { Blocker } from '../../common/Blocker';
import { Sidebar, MenuIcon, NotFound } from '../common';
import { RunDetailsPage } from '../RunDetailsPage';
import { StepDetailsPage } from '../StepDetailsPage';
import { useServices } from '../../services';
import { LIVE_TIMER } from '../../constants';
import * as S from './styles';
import { Progress } from '@backstage/core-components';
import { getAliasFromDomain } from '../../utils/domains';

export const RunPageContent = observer(() => {
  const { domain_alias, org_name, repo_name, runId = '' } = useParams();
  const navigate = useNavigate();
  const { runService } = useServices();
  const { target_repo, id } = runService.run;
  const scrollableRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    runService.getRun(runId);
  }, [runService, runId]);

  useEffect(() => {
    if (!target_repo || runId !== id) {
      return;
    }

    const [domain, org, repo] = target_repo.split('/');
    const domainAlias = getAliasFromDomain(domain);

    if (
      org_name !== org ||
      repo_name !== repo ||
      domainAlias !== domain_alias
    ) {
      navigate(`/cdp/${domainAlias}/${org}/${repo}/${id}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target_repo, id]);

  useInterval(
    runService.reload,
    runService.pollingState.isCurrentlyLive ? LIVE_TIMER : null,
  );

  const shouldDisplayBlocker = runService.actionState.isLoading;

  if (runService.runState.isLoading || runService.runState.isIdle) {
    return <Progress />;
  }

  if (runService.runState.isNotFound) {
    return <NotFound id={runId} />;
  }

  return (
    <S.RunDetails ref={scrollableRef}>
      <S.Content>
        <Routes>
          <Route path="run/*" element={<RunDetailsPage />} />
          <Route
            path="steps/:stepId/*"
            element={<StepDetailsPage scrollableRef={scrollableRef} />}
          />
        </Routes>
      </S.Content>

      <MenuIcon />
      <S.Sidebar>
        <Sidebar />
      </S.Sidebar>
      {shouldDisplayBlocker && <Blocker />}
    </S.RunDetails>
  );
});
