import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { ContainerImagesClient, containerImagesApiRef } from './api';
import { rootRouteRef } from './router';

export const containerImagesPlugin = createPlugin({
  id: 'container-images',
  apis: [
    createApiFactory({
      api: containerImagesApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new ContainerImagesClient({ discoveryApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});
