import React from 'react';
import { ConfigFilter, SearchConfigForGCS } from '../models';
import { MLProjectsIcon } from 'plugin-ui-components';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { ResultDataList } from '../components/ResultDataList';
import { AccentColors } from '../constants/AccentColors';
import { extractGCSFields, formatIndexLabelToID } from '../utils';

const MLEntitiesMap = new Map<string, string>([
  ['mlproject', 'Project'],
  ['mlmodel', 'Model'],
  ['mlmodelgroup', 'Model Group'],
  ['mlpipeline', 'Pipeline'],
  ['mlnotebook', 'Notebook'],
  ['mlexperiment', 'Experiment'],
]);
const MLEntitiesTypes = Array.from(MLEntitiesMap.entries()).map(
  ([key, value]) => ({ label: value, value: key }),
);

declare type MLNotebookType = 'SHARED' | 'SCHEDULED' | 'EXPERIMENT';

interface MlMetadata {
  id: string;
  projectId: string;
  modelGroupId: string;
  type: string;
}

function getUrl(hit: any) {
  if (!hit.metadata) return '';
  const { id, projectId, modelGroupId, type } =
    extractGCSFields<MlMetadata>(hit);
  const typeSlugMap: Record<MLNotebookType, string> = {
    SHARED: 'shared_notebooks',
    SCHEDULED: 'scheduled_notebooks',
    EXPERIMENT: 'experiment_notebooks',
  };
  switch (hit?.metadata?.objectType) {
    case 'mlproject':
      return `/ml/projects/${id}`;
    case 'mlmodel':
      return [
        '/ml/projects',
        projectId,
        'model-groups',
        modelGroupId,
        'models',
        id,
      ].join('/');
    case 'mlmodelgroup':
      return ['/ml/projects', projectId, 'model-groups', id].join('/');
    case 'mlpipeline':
      return ['/ml/projects', projectId, 'pipelines', id].join('/');
    case 'mlnotebook':
      return [
        '/ml/projects',
        projectId,
        `notebooks/${typeSlugMap[type as MLNotebookType]}`,
        id,
      ].join('/');
    case 'mlexperiment':
      return ['/ml/projects', projectId, 'notebooks/r_shiny_apps', id].join(
        '/',
      );
    default:
      return undefined;
  }
}

const label = 'ML Entities';
const id = formatIndexLabelToID(label);
export const zFlowIndex = new SearchConfigForGCS({
  id,
  label: 'ML Entities',
  datasource: 'zflow',
  icon: MLProjectsIcon,
  accentColor: AccentColors.ZFlow,
  filters: [
    new ConfigFilter({
      indexId: id,
      label: 'Kind',
      field: 'objectType',
      isMultiple: true,
      getOptions: async () => MLEntitiesTypes,
    }),
  ],
  getTitle: hit => hit.title,
  getUrl: hit => getUrl(hit),
  render: hit => {
    return (
      <>
        <ResultDataList
          dense
          list={[
            {
              label: 'Type',
              value: MLEntitiesMap.get(hit.metadata?.objectType ?? ''),
            },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
      </>
    );
  },
});
