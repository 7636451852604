import React, { PropsWithChildren } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Caption } from '../styles';
import { ReviewContext } from '../context';

export function SectionCaption({ children }: PropsWithChildren<{}>) {
  return (
    <ReviewContext.Consumer>
      {() => (
        <Caption variant="caption">
          <InfoIcon />
          {children}
        </Caption>
      )}
    </ReviewContext.Consumer>
  );
}
