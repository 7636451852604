import { lighten } from '@material-ui/core';
import { colors } from 'plugin-ui-components';

export const STATUS_COLORS = {
  default: lighten(colors.brand.basic, 0.6),
  skipped: lighten(colors.brand.basic, 0.8),
  success: colors.semantic.successBase,
  failed: colors.semantic.errorBase,
  info: colors.semantic.infoBase,
  progress: colors.semantic.pendingBase,
  warning: colors.semantic.warningBase,
  aborted: colors.brand.primaryOrange,
};
