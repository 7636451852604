import { action, makeObservable, observable } from 'mobx';
import isEqual from 'lodash/isEqual';
import { CDPApi } from '../api';
import { RunsMetadataModel, RunGroupsModel } from '../models';
import { State } from './helpers/state';
import { PollingState, IPoll } from './helpers/polling';
import {
  FetchTypeEnum,
  PaginationHelper,
  IPaginate,
} from './helpers/pagination';
import { GITHUB_DOMAINS, PIPELINE_RUNS_LIMIT } from '../constants';
import { AbortException, NotFoundException } from '../api/exceptions';
import { RunGroupsResponse } from '../api/types/responses';

enum FetchByEnum {
  Repositories,
  Organization,
  Organizations,
}

export interface IRunGroupsService {
  runGroupsState: State;
  runGroups: Array<RunGroupsModel>;
  getByRepositories: (
    repositories: Array<string>,
    events: string[],
  ) => Promise<void>;
  getByOrganization: (
    organization: string,
    alias: string,
    events: string[],
  ) => Promise<void>;
  getByOrganizations: (
    organization: Array<string>,
    events: string[],
  ) => Promise<void>;
  setRunGroups: (value: Array<RunGroupsModel>) => void;
}

export class RunGroupsService implements IRunGroupsService, IPaginate, IPoll {
  // Backstage Apis
  private cdpApi: CDPApi;

  // Observables
  runGroupsState: State;
  runGroups: Array<RunGroupsModel> = [];
  pollingState: PollingState;
  paginationHelper: PaginationHelper;

  // Internals
  private repositories: Array<string> = [];
  private organizations: Array<string> = [];
  private organization: string = '';
  private domain: string = '';
  private events: string[] = [];
  private type: FetchByEnum = FetchByEnum.Organization;

  constructor(cdpApi: CDPApi) {
    // Backstage Apis
    this.cdpApi = cdpApi;

    // States
    this.runGroupsState = new State();
    this.pollingState = new PollingState();
    this.paginationHelper = new PaginationHelper();

    makeObservable<this, 'fetch'>(this, {
      runGroups: observable,
      runGroupsState: observable,
      pollingState: observable,
      paginationHelper: observable,
      getByRepositories: action,
      getByOrganizations: action,
      reload: action,
      togglePolling: action,
      fetch: action,
      setRunGroups: action,
    });
  }

  // Actions
  getByRepositories(
    repositories: Array<string>,
    events: string[],
  ): Promise<void> {
    const type = FetchByEnum.Repositories;

    if (this.type !== type || !isEqual(this.repositories, repositories)) {
      this.setRunGroups([]);
    }

    this.repositories = repositories;
    this.events = events;
    this.type = type;

    this.runGroupsState.setLoading();
    return this.fetch();
  }

  async getByOrganizations(
    organizations: Array<string>,
    events: string[],
  ): Promise<void> {
    const type = FetchByEnum.Organizations;

    if (this.type !== type || !isEqual(this.organizations, organizations)) {
      this.setRunGroups([]);
    }

    this.organizations = organizations;
    this.events = events;
    this.type = type;

    this.runGroupsState.setLoading();
    return this.fetch();
  }

  async getByOrganization(
    organization: string,
    alias: string,
    events: string[],
  ): Promise<void> {
    const type = FetchByEnum.Organization;
    const domain = GITHUB_DOMAINS[alias];

    if (
      this.type !== type ||
      this.organization !== organization ||
      this.domain !== domain
    ) {
      this.setRunGroups([]);
    }

    this.organization = organization;
    this.domain = domain;
    this.events = events;
    this.type = type;

    this.runGroupsState.setLoading();
    return this.fetch();
  }

  getNextPage = async (): Promise<void> => {
    if (this.paginationHelper.hasNextPage) {
      this.runGroupsState.setLoading();
      this.fetch(FetchTypeEnum.Next);
    }
  };

  getPrevPage = async (): Promise<void> => {
    if (this.paginationHelper.hasPrevPage) {
      this.runGroupsState.setLoading();
      this.fetch(FetchTypeEnum.Prev);
    }
  };

  reload = async (): Promise<void> => {
    if (!this.pollingState.isOnHold) {
      this.runGroupsState.setReloading();
      this.fetch(FetchTypeEnum.Reload);
    }
  };

  togglePolling = (): void => {
    this.pollingState.toggle();
    if (this.pollingState.isCurrentlyLive) {
      this.reload();
    }
  };

  private async fetch(
    fetchType: FetchTypeEnum = FetchTypeEnum.FirstTime,
  ): Promise<void> {
    let response: RunGroupsResponse;

    try {
      this.pollingState.hold();

      switch (this.type) {
        case FetchByEnum.Organizations:
          response = await this.cdpApi.getRunGroupsByOrganizations(
            JSON.stringify(this.organizations),
            this.paginationHelper.getCursor(fetchType),
            PIPELINE_RUNS_LIMIT,
            this.events,
            this.pollingState.abortController.signal,
          );
          break;
        case FetchByEnum.Organization:
          response = await this.cdpApi.getRunGroupsByOrganization(
            JSON.stringify([this.organization]),
            this.domain,
            this.paginationHelper.getCursor(fetchType),
            PIPELINE_RUNS_LIMIT,
            this.events,
            this.pollingState.abortController.signal,
          );
          break;
        case FetchByEnum.Repositories:
        default:
          response = await this.cdpApi.getRunGroupsByRepositories(
            JSON.stringify(this.repositories),
            this.paginationHelper.getCursor(fetchType),
            PIPELINE_RUNS_LIMIT,
            this.events,
            this.pollingState.abortController.signal,
          );
      }

      this.setRunGroups(
        response.pipeline_groups.flatMap(runGroup => {
          return runGroup.pipelines.length > 0
            ? [new RunGroupsModel(runGroup.pipelines[0])]
            : [];
        }),
      );

      this.paginationHelper.update(
        fetchType,
        new RunsMetadataModel(response.metadata),
      );
      this.pollingState.resetConsecutiveFailedPolls();
      this.runGroupsState.setLoaded();
    } catch (error) {
      if (error instanceof AbortException) {
        return;
      }

      if (error instanceof NotFoundException) {
        this.runGroupsState.setNotFound();
        return;
      }

      this.pollingState.incrementConsecutiveFailedPolls();
      this.runGroupsState.setError();
    } finally {
      this.pollingState.resume();
    }
  }

  setRunGroups = (value: Array<RunGroupsModel>) => {
    this.runGroups = value;
  };
}
