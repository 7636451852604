import { EventTrackerProps } from 'plugin-ui-components';

export const common: Pick<ITrackingEvent, 'plugin' | 'eventCategory'> = {
  plugin: 'cdp',
  eventCategory: 'Pipeline Activity card',
};

export const watchTeamsLink: ITrackingEvent = {
  ...common,
  eventLabel: 'Redirect to watched teams',
  eventAction: `Watch teams link is clicked from empty pipeline activity`,
};

export const viewAllPipelines: EventTrackerProps = {
  interaction: 'onClick',
  plugin: 'cdp',
  eventCategory: "Start Page Widget 'Open view all Pipelines'",
  eventAction: 'click',
  eventLabel: 'Clicked on the view all Pull Requests',
};
