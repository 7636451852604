import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export type ComplianceAndSecurityCheckmarxData = {
  id: number;
  team_id: string;
  name: string;
  risk_score: number;
  criticality: number;
  last_updated: string;
  last_scan_id: number;
};

export class ComplianceAndSecurityCheckmarxApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchComplianceAndSecurityCheckmarxData(
    teamId: string,
  ): Promise<ComplianceAndSecurityCheckmarxData[]> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/compliance-and-security-checkmarx-api/projects/${teamId}`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return await response.json();
  }
}

export const complianceAndSecurityCheckmarxApiRef =
  createApiRef<ComplianceAndSecurityCheckmarxApi>({
    id: 'plugin.compliance-and-security.checkmarx',
  });
