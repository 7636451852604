import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Layout } from '../components/common/Layout';
import { RunDetails } from '../components/Runs/RunDetails';
import { RunMetricsAndParams } from '../components/Runs/RunMetricsAndParams';
import { zflowApiRef } from '../api/zflowApiClient';
import { MLPipelineRun, MLResourceNotFound } from '../api/definitions';
import { PLACEHOLDER_PIPELINE_RUN } from '../api/PlaceholderResponses';
import { TabbedLayout } from '@backstage/core-components';
import {
  DATA_REFRESH_FREQUENCY,
  IN_PROGRESS_RUN_STATUSES,
} from '../constants/common';
import { ResourceNotFound } from '../components/common/ResourceNotFound';
import { runPageTitle } from '../constants/PageTitles';

export const MLRunPage = () => {
  const { run_id = '' } = useParams();

  const zflowApi = useApi(zflowApiRef);

  const [run, setRun] = useState<MLPipelineRun>(PLACEHOLDER_PIPELINE_RUN);
  const [resourceNotFound, setResourceNotFound] =
    useState<MLResourceNotFound>();
  const [loading, setLoading] = useState<boolean>(true);

  const getRunDetails = useCallback(() => {
    zflowApi
      .getPipelineRun(run_id)
      .then(runResponse => {
        setRun(runResponse);
        setLoading(false);
      })
      .catch(_ => setResourceNotFound({ name: 'run', id: run_id }));
  }, [run_id, zflowApi]);

  useEffect(() => {
    getRunDetails();

    const interval = setInterval(() => {
      if (IN_PROGRESS_RUN_STATUSES.includes(run.status)) {
        getRunDetails();
      }
    }, DATA_REFRESH_FREQUENCY);

    return () => clearInterval(interval);
  }, [getRunDetails, run.status, run_id, zflowApi]);

  return (
    <Layout pageTitle={runPageTitle(run.name)}>
      {resourceNotFound ? (
        <ResourceNotFound {...resourceNotFound} />
      ) : (
        <TabbedLayout>
          <TabbedLayout.Route path="/" title="Details">
            <RunDetails run={run} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/params-metrics" title="Params and Metrics">
            <RunMetricsAndParams run={run} loading={loading} />
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
    </Layout>
  );
};
