import { Theme, styled } from '@material-ui/core';
import {
  compose,
  spacing,
  typography,
  palette,
  flexbox,
  display,
} from '@material-ui/system';

export const Text = styled('span')(
  compose(
    spacing,
    typography,
    palette,
    flexbox,
    display,
    (props: {
      theme?: Theme;
      bold?: boolean;
      italic?: boolean;
      capitalize?: boolean;
      uppercase?: boolean;
      breakAll?: boolean;
      ellipsis?: boolean;
      error?: boolean;
      success?: boolean;
      info?: boolean;
      shrink?: number;
      fullWidth?: boolean;
    }) => ({
      ...(props.bold && { fontWeight: 'bold' }),
      ...(props.italic && { fontStyle: 'italic' }),
      ...(props.capitalize && { textTransform: 'capitalize' }),
      ...(props.uppercase && { textTransform: 'uppercase' }),
      ...(props.breakAll && { wordBreak: 'break-all' }),
      ...(props.ellipsis && {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),
      ...(props.error && { color: props.theme?.palette.error.main }),
      ...(props.success && { color: props.theme?.palette.success.main }),
      ...(props.info && { color: props.theme?.palette.info.main }),
      ...(props.shrink && { flexShrink: props.shrink }),
      ...(props.fullWidth && { flex: 1 }),
    }),
  ),
);

export const fontSize = {
  tiny: '.75rem',
  small: '.8rem',
  default: '.85rem',
  medium: '1rem',
  large: '1.2rem',
  super: '1.5rem',
};
