import { InfoCard } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { useApiCall } from '../../hooks/useApiCall';
import { Database } from '../../domain/Databases';
import { DatabasesApi, databasesApiRef } from '../../api/DatabasesApi';
import { DatabasesList } from '../DatabasesList';

export function DatabasesPage() {
  const { entity } = useEntity();
  const application = entity.metadata.name;
  const {
    payLoad: databases = [],
    isLoading: isLoadingDatabases,
    status: loadingDatabasesStatus,
  } = useApiCall<DatabasesApi, Database[]>(databasesApiRef, api =>
    api.getDatabasesByApp(application),
  );

  return (
    <Grid container>
      <Grid item sm={12}>
        <InfoCard
          title="Databases"
          subheader={`All databases for ${application} application`}
        >
          <DatabasesList
            databases={databases}
            isLoading={isLoadingDatabases}
            status={loadingDatabasesStatus}
            application={application}
          />
        </InfoCard>
      </Grid>
    </Grid>
  );
}
