import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export function useDeleteDocEntity() {
  const catalogApi = useApi(catalogApiRef);

  function deleteDoc(entity: IEntity) {
    if (entity.kind !== 'Documentation') {
      return false;
    }
    return catalogApi.removeEntityByUid(entity.metadata.uid!);
  }
  return deleteDoc;
}
