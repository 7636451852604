import { observable, action, makeObservable, computed } from 'mobx';

export enum StatesEnum {
  Clean,
  Loading,
  Loaded,
  Unrecoverable,
  Recoverable,
  NotAvailable,
}

export class LogsState {
  state: StatesEnum;
  message: string;

  constructor() {
    this.state = StatesEnum.Clean;
    this.message = '';

    makeObservable(this, {
      state: observable,
      message: observable,
      setClean: action,
      setLoading: action,
      setLoaded: action,
      setRecoverable: action,
      setUnrecoverable: action,
      setNotAvailable: action,
      isClean: computed,
      isLoading: computed,
      isLoaded: computed,
      isRecoverable: computed,
      isUnrecoverable: computed,
      isNotAvailable: computed,
    });
  }

  public setClean() {
    this.state = StatesEnum.Clean;
  }

  public setLoading() {
    this.state = StatesEnum.Loading;
  }

  public setLoaded() {
    this.state = StatesEnum.Loaded;
  }

  public setRecoverable() {
    this.state = StatesEnum.Recoverable;
  }

  public setNotAvailable() {
    this.state = StatesEnum.NotAvailable;
  }

  public setUnrecoverable(message: string) {
    this.message = message;
    this.state = StatesEnum.Unrecoverable;
  }

  public get isClean() {
    return this.state === StatesEnum.Clean;
  }

  public get isLoading() {
    return this.state === StatesEnum.Loading;
  }

  public get isLoaded() {
    return this.state === StatesEnum.Loaded;
  }

  public get isRecoverable() {
    return this.state === StatesEnum.Recoverable;
  }

  public get isUnrecoverable() {
    return this.state === StatesEnum.Unrecoverable;
  }

  public get isNotAvailable() {
    return this.state === StatesEnum.NotAvailable;
  }
}
