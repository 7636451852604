import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { TabbedLayout } from '@backstage/core-components';
import { Layout } from '../components/common/Layout';
import { ModelList } from '../components/Models/ModelList';
import { ModelGroupDetails } from '../components/Models/ModelGroups/ModelGroupDetails';
import { zflowApiRef } from '../api/zflowApiClient';
import { MLResourceNotFound, ModelGroup } from '../api/definitions';
import { PLACEHOLDER_MODEL_GROUP } from '../api/PlaceholderResponses';
import { PageEntities } from '../components/Breadcrumbs/MLBreadcrumbs';
import { ResourceNotFound } from '../components/common/ResourceNotFound';
import { modelGroupPageTitle } from '../constants/PageTitles';

export const MLModelGroupPage = () => {
  const { model_group_id = '' } = useParams();

  const zflowApi = useApi(zflowApiRef);

  const [modelGroup, setModelGroup] = useState<ModelGroup>(
    PLACEHOLDER_MODEL_GROUP,
  );
  const [pageEntities, setPageEntities] = useState<PageEntities>({});
  const [resourceNotFound, setResourceNotFound] =
    useState<MLResourceNotFound>();

  useEffect(() => {
    zflowApi
      .getModelGroup(model_group_id)
      .then((mg: ModelGroup) => {
        setModelGroup(mg);
        setPageEntities({
          modelGroupName: mg.name,
        });
      })
      .catch(_ =>
        setResourceNotFound({ name: 'model group', id: model_group_id }),
      );
  }, [zflowApi, model_group_id]);

  return (
    <Layout
      pageEntities={pageEntities}
      pageTitle={modelGroupPageTitle(modelGroup.name)}
    >
      {resourceNotFound ? (
        <ResourceNotFound {...resourceNotFound} />
      ) : (
        <TabbedLayout>
          <TabbedLayout.Route path="/" title="Details">
            <ModelGroupDetails modelGroup={modelGroup} />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/models" title="Models">
            <ModelList modelGroupId={model_group_id} />
          </TabbedLayout.Route>
        </TabbedLayout>
      )}
    </Layout>
  );
};
