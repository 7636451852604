import React from 'react';
import { observer } from 'mobx-react-lite';
import Ansi from 'ansi-to-react';
import { LogsLineModel } from '../../../../../../models';

import 'style-loader!css-loader!sass-loader!./line.scss';

type Props = {
  index: number;
  line: LogsLineModel;
};

export const LogsLine = observer(({ index, line }: Props) => {
  return (
    <li key={`line-${index}`}>
      <span className="line-number">{index}</span>
      <pre>
        <Ansi linkify>{line.content}</Ansi>
      </pre>
    </li>
  );
});
