import {
  Theme,
  Card,
  CardContent,
  CardHeader,
  styled,
} from '@material-ui/core';

export const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(4),
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledCardHeader = styled(CardHeader)(
  (props: { theme: Theme }) => ({
    padding: props.theme.spacing(2),
    '& > div': {
      marginRight: 8,
    },
    '& > *': {
      margin: 0,
    },
    '& h3': {
      marginBottom: 0,
    },
    '& svg': {
      verticalAlign: 'middle',
    },
  }),
);

export const StyledCardContent = styled(CardContent)(
  (props: { theme: Theme; disabled?: boolean }) => ({
    padding: '0',
    '&:last-child': {
      padding: '0',
    },
    opacity: props.disabled ? '0.6' : '',
    filter: props.disabled ? 'blur(2px)' : '',
  }),
);
