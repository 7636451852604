import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { throwReadableError } from 'plugin-core';
import { LibraryResponse } from './types';

export type LibrariesResponse = {
  items: LibraryResponse[];
  self: string;
  total: number;
  prev: string;
  next: string;
  query: { [key: string]: string };
};

type LibrariesRequest = {
  limit: number;
  version?: string[];
  application?: string[];
  name?: string[];
  cursor?: string;
};
export type LibrariesApiType = {
  getLibraries({
    limit,
    name,
    version,
    application,
    cursor,
  }: LibrariesRequest): Promise<LibrariesResponse>;
};

export const librariesApiRef = createApiRef<LibrariesApiType>({
  id: 'libraries',
});

export class LibrariesApi implements LibrariesApiType {
  private readonly authApi: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { authApi: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.authApi = options.authApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getLibraries({
    limit = 50,
    name,
    version,
    application,
    cursor,
  }: LibrariesRequest): Promise<LibrariesResponse> {
    const nameQuery = name?.length ? `&names=${name.join(',')}` : '';
    const versionQuery = version?.length
      ? `&versions=${version.join(',')}`
      : '';
    const applicationQuery = application ? `&applications=${application}` : '';
    const page = cursor ? `&cursor=${cursor}` : '';

    const path = `libraries?limit=${limit}${page}${nameQuery}${versionQuery}${applicationQuery}`;

    return this.get(path);
  }

  private async get<T>(path: string): Promise<T> {
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url: string = `${baseUrl}/sbom/${path}`;
    const token: string = await this.authApi.getAccessToken();

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        credentials: 'include',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) throwReadableError(response);

    return await response.json();
  }
}
