import React from 'react';
import { DialogTitle, Box, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import * as S from './styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string | React.ReactElement;
  children?: React.ReactNode;
  testID?: string;
  classes?: Partial<ClassNameMap<any>>;
};

export function Dialog({
  open,
  onClose,
  title,
  children,
  testID,
  classes,
}: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <S.Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      data-testid={testID ?? 'dialog'}
      classes={classes}
    >
      {title && (
        <DialogTitle id="dialog-title">
          <Box fontSize={15} lineHeight={1.5}>
            {title}
          </Box>
        </DialogTitle>
      )}
      {!!onClose && (
        <S.CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </S.CloseButton>
      )}
      {children && children}
    </S.Dialog>
  );
}
