import { styled, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const CatalogTableWrapper = styled('div')(
  ({ theme, filterOpen }: { theme: Theme; filterOpen: boolean }) => ({
    display: 'grid',
    gridTemplateAreas: filterOpen ? "'filters' 'table'" : "'table'",
    gridTemplateColumns: filterOpen ? '250px 1fr' : '1fr',
    gridColumnGap: theme.spacing(2),
  }),
);

export const useStyles = makeStyles(() => ({
  exportLink: {
    display: 'none',
  },
}));
