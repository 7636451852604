import { CatalogApi } from '@backstage/plugin-catalog-react';
import { PersonalizationApi } from 'plugin-core';
import { getLedTeams, getMemberTeams } from '../hooks';

interface Props {
  username: string;
  catalogApi: CatalogApi;
  personalizationApi: PersonalizationApi;
  /** Meant to exclude the watched teams in case the user isn't the current logged-in user */
  includeWatched?: boolean;
}

export async function getUserTeams({
  username,
  catalogApi,
  personalizationApi,
  includeWatched = true,
}: Props): Promise<Omit<IUserTeams, 'accountableTeams'>> {
  const promises: Promise<IEntityGroup[]>[] = [
    getMemberTeams({ catalogApi, username }),
    getLedTeams({ catalogApi, username }),
    personalizationApi.getUnfollowedTeams(),
  ];
  if (includeWatched) promises.push(personalizationApi.getWatchedTeams());

  return await Promise.all(promises).then(res => {
    return {
      memberTeams: res[0] || [],
      ledTeams: res[1] || [],
      unfollowedTeams: res[2] || [],
      watchedTeams: res[3] || [],
    };
  });
}

export const mapFilterKeys = (key: string) => {
  switch (key) {
    case 'cyberweekRelevant':
      return 'cyberweek';
    case 'support247':
      return 'onCallSupport';
    default:
      return key;
  }
};
