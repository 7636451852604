import dayjs from 'dayjs';

const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // HH:MM
const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD

export function splitDateTime(timestamp: string | number | Date): {
  date: string;
  time: string;
} {
  const values = dayjs(timestamp, 'DD/MM/YYYY')
    .format('YYYY-MM-DDTHH:mm')
    .split('T');
  return { date: values[0], time: values[1] };
}

export function getDaysFromNow(days: number): string {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() - days);
  return date.toISOString();
}

export function getHoursFromNow(hours: number): string {
  const date = new Date();
  date.setUTCHours(date.getUTCHours() - hours);
  return date.toISOString();
}

export function getNextWeek(date: string): string {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() + 7);
  return newDate.toISOString();
}

export function getPreviousWeek(date: string): string {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() - 7);
  return newDate.toISOString();
}

export function getNow(): string {
  return new Date().toISOString();
}

export function getDateTime(date: string, time: string) {
  return dayjs(`${date} ${time}`).toDate().toISOString();
}

export function isValidTimestamp(timestampString: string): boolean {
  const timestamp = Date.parse(timestampString);
  return !isNaN(timestamp);
}

export function getDateTimeOrDefault(date: string, time: string): string {
  let newTime = '00:00';
  if (timeRegex.test(time)) {
    newTime = time;
  }

  let newDate = dayjs().format('YYYY-MM-DD');
  if (dateRegex.test(date)) {
    newDate = date;
  }

  return getDateTime(newDate, newTime);
}
