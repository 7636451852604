import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import SupportIcon from '@material-ui/icons/ContactSupport';
import PeopleIcon from '@material-ui/icons/People';
import SecurityIcon from '@material-ui/icons/Security';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Link } from '@backstage/core-components';
import { CICDPipelinesIcon, EventTracker } from 'plugin-ui-components';
import * as Tracking from '../../common/tracking';
import { clickHotPagesLink, useSearchLocalStorage } from '../../common';
import useStyles, { iconTitle } from './styles';

const hotPagesData = [
  {
    title: 'Operational Excellence',
    icon: SecurityIcon,
    links: [
      {
        text: "Who's on call?",
        url: 'https://docs.google.com/spreadsheets/d/1L_8D8Ecj_7Te96kvzJZAilTkwf2Qwm8gKR4tqUKdDVQ/edit#gid=0',
      },
      {
        text: 'Recent Incidents',
        url: '/cyberweek',
      },
      {
        text: 'Applications to Review',
        url: '/applications?review=needs%20review&status=yes',
      },
      {
        text: 'API Linter',
        url: '/apis/linter',
      },
    ],
  },
  {
    title: 'Productivity',
    icon: CICDPipelinesIcon,
    links: [
      {
        text: 'CI/CD Platform',
        url: '/cdp',
      },
      {
        text: 'ML Platform',
        url: '/ml',
      },
      {
        text: 'All APIs',
        url: '/apis/?group=all',
      },
      {
        text: 'My Applications',
        url: '/applications',
      },
    ],
  },
  {
    title: 'Organizational',
    icon: AccountTreeIcon,
    links: [
      {
        text: 'Teams',
        url: '/teams',
      },
      {
        text: 'Reporting Lines',
        url: '/teams/reporting-line',
      },
      {
        text: 'TOER Orgs',
        url: '/teams/toer-orgs',
      },
    ],
  },
  {
    title: 'Help',
    icon: SupportIcon,
    links: [
      {
        text: 'Engineering Docs',
        url: 'https://engineering.docs.zalando.net/',
      },
      {
        text: 'Tooling Catalog',
        url: '/tooling-catalog',
      },
      {
        text: 'Documentation',
        url: '/docs',
      },
      {
        text: 'Support links',
        url: '/support',
      },
      {
        text: 'Unified Search',
        url: 'https://cloudsearch.google.com/cloudsearch',
      },
    ],
  },
  {
    title: 'Employee Resource',
    icon: PeopleIcon,
    links: [
      {
        text: 'Compass',
        url: 'https://zalandoprod.service-now.com/',
      },
      {
        text: 'zLife',
        url: 'https://zlife.zalando.net/',
      },
      {
        text: 'My Workplace',
        url: 'https://myworkplace.zalando.net/',
      },
      {
        text: 'Training Platform',
        url: 'https://wd3.myworkday.com/zalando/learning',
      },
    ],
  },
];

const AccordionWrapper = ({
  children,
  classes,
}: {
  children: React.ReactNode;
  classes: ClassNameMap<string>;
}) => {
  const { searchLocalStorage, setHotPageLocalStorage } =
    useSearchLocalStorage();

  return (
    <Accordion
      defaultExpanded={searchLocalStorage?.hotPageOpen ?? true}
      onChange={(_, expanded) => {
        setHotPageLocalStorage(expanded);
        Tracking.expandHotPages(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ root: classes.accordionSummary, expanded: classes.expanded }}
      >
        <Typography variant="body1">
          <strong>Hot Pages</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const BoxWrapper = ({
  children,
  classes,
}: {
  children: React.ReactNode;
  classes: ClassNameMap<string>;
}) => (
  <Box paddingY={2}>
    <Box display="flex" alignItems="center" marginBottom={2}>
      <WhatshotIcon classes={{ root: classes.hotIcon }} />
      <Typography variant="h4" classes={{ root: classes.typography }}>
        Hot Pages
      </Typography>
    </Box>
    {children}
  </Box>
);

interface Props {
  onLinkClicked?: React.MouseEventHandler<HTMLElement>;
  modal?: boolean;
}

export const HotPages = ({ onLinkClicked, modal }: Props) => {
  const classes = useStyles();
  const Wrapper = modal ? BoxWrapper : AccordionWrapper;

  return (
    <Wrapper classes={classes}>
      <Grid container justifyContent="space-between" spacing={1}>
        {hotPagesData.map(({ title, icon: Icon, links }) => (
          <Grid key={title} item>
            <Box display="flex" alignItems="center">
              <Icon style={iconTitle} />
              <Typography
                variant="body2"
                classes={{ root: classes.typography }}
              >
                <strong>{title}</strong>
              </Typography>
            </Box>
            <List>
              {links.map(({ url, text }) => (
                <ListItem key={url} classes={{ gutters: classes.gutters }}>
                  <EventTracker {...clickHotPagesLink(text)}>
                    <Link
                      to={url}
                      onClick={e => onLinkClicked && onLinkClicked(e)}
                    >
                      {text}
                      {url.startsWith('http') && (
                        <OpenInNewIcon
                          classes={{ root: classes.openNewIcon }}
                        />
                      )}
                    </Link>
                  </EventTracker>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
};
