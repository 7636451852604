import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  NotebooksApiClient,
  notebooksApiRef,
  NotebookRendererApiClient,
  notebookRendererApiRef,
  DashboardsApiClient,
  dashboardsApiRef,
} from './api';

export const rootRouteRef = createRouteRef({ id: 'ml-experimentation' });

export const mlExperimentationApiRefs: any[] = [
  createApiFactory({
    api: notebooksApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ oauth2Api, discoveryApi }) =>
      new NotebooksApiClient(discoveryApi, oauth2Api),
  }),
  createApiFactory({
    api: notebookRendererApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ oauth2Api, discoveryApi }) =>
      new NotebookRendererApiClient(discoveryApi, oauth2Api),
  }),
  createApiFactory({
    api: dashboardsApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ oauth2Api, discoveryApi }) =>
      new DashboardsApiClient(
        // `${configApi.getString(
        //   'backend.baseUrl',
        // )}/api/proxy/zflow-dashboards/api`,
        discoveryApi,
        oauth2Api,
      ),
  }),
];

export const MLExperimentationPlugin = createPlugin({
  id: 'ml-experimentation',
  apis: mlExperimentationApiRefs,
});

export const MlExperimentationPage = MLExperimentationPlugin.provide(
  createRoutableExtension({
    name: 'plugin-ml-experimentation',
    component: () => import('./router').then(m => m.MLExperimentationRouter),
    mountPoint: rootRouteRef,
  }),
);
