import { useContext } from 'react';
import { FilterGroupsContext } from '../context';

/**
 * Hook that exposes the result of applying a set of filter groups.
 */
export function useFilteredEntities() {
  const context = useContext(FilterGroupsContext);
  if (!context) {
    throw new Error(`Must be used inside an EntityFilterGroupsProvider`);
  }
  return {
    loading: context.loading,
    error: context.error,
    matchingEntities: context.matchingEntities,
    availableTags: context.availableTags,
    isCatalogEmpty: context.isCatalogEmpty,
    reload: context.reload,
  };
}
