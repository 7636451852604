import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { ProjectForm } from '../../../hooks/useProjectForm';
import { SPECIAL_CHARACTERS } from '../../../constants/common';

export const ProjectNameInput = ({
  setForm,
  label,
}: {
  setForm: React.Dispatch<React.SetStateAction<ProjectForm>>;
  label: string | undefined;
}) => {
  const [error, setError] = useState<boolean>(false);

  const [projectNameLength, setprojectNameLength] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = event.target.value;
    setprojectNameLength(projectName.length);
    setError(projectName.length < 4 || SPECIAL_CHARACTERS.test(projectName));
    setForm(prevState => ({ ...prevState, name: projectName }));
  };

  const helperText = `The project name must not contain spaces, emoji, or special characters except for _ and -.\n It should have at least 4 characters. Current Length ${projectNameLength}`;

  return (
    <TextField
      fullWidth
      required
      label={label}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      variant="outlined"
      margin="dense"
      inputProps={{
        'aria-label': label,
      }}
    />
  );
};
