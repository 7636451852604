import React from 'react';
import {
  StatusOK,
  StatusRunning,
  StatusError,
} from '@backstage/core-components';
import { Box, Grid, Link } from '@material-ui/core';
import { AboutField } from 'plugin-ui-components';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import { KVTable } from '../KVTable';
import { constructLinkToDataLabNotebook } from '../../utils';
import ReactJson from 'react-json-view';
import { usePluginTheme } from 'plugin-ml-platform';

dayjs.extend(UTC);

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

type DateTimeObject = Date | string | number | undefined;

const getFormattedDateTime = (
  date: DateTimeObject,
  format: string = DEFAULT_DATE_TIME_FORMAT,
) => {
  const time = dayjs(date).utc().format(format);
  return `${time} (UTC)`;
};

interface DetailCardColumn {
  key: string;
  displayName: string;
  type?: string;
}

interface DetailsCardProps {
  columns: DetailCardColumn[];
  data: any;
}

const getReactJSONTheme = (pluginTheme: string) => {
  return pluginTheme === 'dark' ? 'ocean' : 'rjv-default';
};

export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'SUCCEEDED':
      return <StatusOK />;
    case 'RUNNING':
      return <StatusRunning />;
    case 'INITIALIZED':
      return <StatusRunning />;
    case 'FAILED':
      return <StatusError />;
    default:
      return <></>;
  }
};

const getChildComponent = (
  value: any,
  type?: string,
  theme: any = 'rjv-default',
  isCollapsed: boolean = true,
) => {
  const checkedValue = typeof value === 'object' ? value : { value: value };
  switch (type) {
    case 'status':
      return (
        <Box component="div">
          {getStatusIcon(value)} {value}
        </Box>
      );
    case 'notebook_path':
      return value ? (
        <Link
          href={constructLinkToDataLabNotebook(value)}
          color="inherit"
          target="_blank"
        >
          {value}
        </Link>
      ) : (
        ''
      );
    case 'archive':
      return value ? 'Yes' : 'No';
    case 'time':
      return value ? (
        <Box component="div">{getFormattedDateTime(value)}</Box>
      ) : null;
    case 'kv_table':
      return value?.length ? <KVTable data={value} /> : null;
    case 'kv_dict_table':
      return value?.key ? (
        <KVTable
          data={Object.keys(value).map(k => ({ key: k, value: value[k] }))}
        />
      ) : null;
    case 'error_message':
      return value ? (
        <ReactJson
          collapsed={isCollapsed}
          enableClipboard={false}
          name={null}
          theme={theme}
          src={checkedValue}
          displayDataTypes={false}
        />
      ) : null;
    default:
      return <Box component="div">{value}</Box>;
  }
};
export const DetailsCard = (props: DetailsCardProps) => {
  const { columns, data } = props;
  const pluginTheme = usePluginTheme();
  const theme = getReactJSONTheme(pluginTheme);
  return (
    <Grid container spacing={3}>
      {columns.map(({ key, displayName, type }) => {
        const child = getChildComponent(data[key], type, theme);
        return child ? (
          <Grid item key={key} xs={4} style={{ overflowWrap: 'break-word' }}>
            <AboutField
              label={displayName.toUpperCase()}
              children={child}
              xs={12}
            />
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};
