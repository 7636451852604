import React from 'react';
import { Link, Table, TableColumn } from '@backstage/core-components';
import {
  gitHubApiRef,
  IAppRepositoryVulnerability,
  sortByTotalCount,
} from '../api';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { getOpenVulnerabilities, updateVulnerabilitiesCount } from '../helpers';
import { DependabotAlertSeveritiesChip } from './DependabotAlertSeveritiesChip';
import { Tooltip, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const columns: TableColumn<IAppRepositoryVulnerability>[] = [
  {
    title: 'Repository',
    field: 'app',
    sorting: false,
    render: (row: any): React.ReactNode => (
      <Link to={`/catalog/default/Component/${row.app}/dependabot-alerts`}>
        {row.app}
      </Link>
    ),
  },
  {
    title: (
      <>
        <Tooltip
          placement="top"
          title="Number of packages with vulnerabilities"
        >
          <span>
            Vulnerable Packages{' '}
            <InfoOutlined
              style={{
                fontSize: 16,
                display: 'inline-block',
                verticalAlign: 'text-top',
              }}
            />
          </span>
        </Tooltip>
      </>
    ),
    field: 'openCounter',
    defaultSort: 'asc' as any,
    customSort: sortByTotalCount,
  },
  {
    title: (
      <Tooltip placement="top" title="Total number of vulnerabilities">
        <span>
          Vulnerabilities{' '}
          <InfoOutlined
            style={{
              fontSize: 16,
              display: 'inline-block',
              verticalAlign: 'text-top',
            }}
          />
        </span>
      </Tooltip>
    ),
    defaultSort: 'asc' as any,
    render: (row: IAppRepositoryVulnerability): React.ReactNode => {
      const count = getOpenVulnerabilities(row);
      return <Typography>{count.length}</Typography>;
    },
  },

  {
    title: 'Severities',
    sorting: false,
    render: (row: IAppRepositoryVulnerability): React.ReactNode => {
      const count = updateVulnerabilitiesCount(row.packages);
      return <DependabotAlertSeveritiesChip vulnerabilitiesCount={count} />;
    },
  },
];

export function DependabotAlertsTable() {
  const gitHubApi = useApi(gitHubApiRef);
  const navigate = useNavigate();

  const {
    value: vulnerableRepositories = [] as IAppRepositoryVulnerability[],
    loading,
  } = useAsync(async () => {
    const response = await gitHubApi.fetchVulnerabilities();
    return response.data;
  }, []);

  const repositoriesWithOpenVulnerabilities = vulnerableRepositories.filter(
    repository => (repository?.openCounter ? repository.openCounter > 0 : 0),
  );

  return (
    <Table
      title="Alerts"
      onRowClick={(_, rowData) =>
        navigate(`/catalog/default/Component/${rowData?.app}/dependabot-alerts`)
      }
      columns={columns}
      isLoading={loading}
      data={repositoriesWithOpenVulnerabilities}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
      }}
    />
  );
}
