import React from 'react';
import { useAsync } from 'react-use';
import { Progress } from '@backstage/core-components';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { Link } from '@material-ui/core';
import { IPipelineScopedArtifact } from '../../../../../api/types/payload';
import { InfoCard } from '../../../../../common';
import { STATUS_COLORS } from '../../../../../utils/colors';
import { bytesToSize } from '../../../../../utils/bytes';

import * as S from './styles';

type Props = {
  artifacts: IPipelineScopedArtifact[];
  runId: string;
  stepId: string;
  stepRunId: string;
};

export function PipelineScopedArtifacts({
  artifacts,
  runId,
  stepId,
  stepRunId,
}: Props) {
  const discoveryApi = useApi(discoveryApiRef);

  const { loading, value: artifactsUrl } = useAsync(async () => {
    const url = await discoveryApi.getBaseUrl('cdp');
    const path = `${url}/runs/${runId}/steps/${stepId}/runs/${stepRunId}/artifacts`;

    return path;
  }, []);

  if (loading) {
    return <Progress />;
  }

  return (
    <InfoCard title="📂 Pipeline scoped artifacts">
      <S.Artifact>
        {artifacts.map(artifact => (
          <li key={artifact.name}>
            <S.Title>{artifact.name}</S.Title>

            <S.List>
              {artifact.files.map(file => (
                <S.Item key={file.name}>
                  <S.Text title={file.name}>{file.name}</S.Text>

                  <S.Tag
                    color={STATUS_COLORS.skipped}
                    title={`${file.size} Bytes`}
                  >
                    {bytesToSize(file.size)}
                  </S.Tag>

                  <S.Action>
                    <Link
                      href={`${artifactsUrl}/${file.path}`}
                      download={file.path.split('/').pop() || 'artifact'}
                    >
                      download
                    </Link>
                  </S.Action>
                </S.Item>
              ))}
            </S.List>
          </li>
        ))}
      </S.Artifact>
    </InfoCard>
  );
}
