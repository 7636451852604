import dayjs from 'dayjs';
import * as I from '../api';

export class ImageCIInfoModel {
  pipelineId: string;
  buildId: string;
  revision: string;
  author: string;
  url: string;

  constructor(json: I.ImageCIInfo = {}) {
    this.pipelineId = json.pipeline_id ?? 'unknown';
    this.buildId = json.build_id ?? 'unknown';
    this.revision = json.revision ?? 'unknown';
    this.author = json.author ?? 'unknown';
    this.url = json.url ?? 'unknown';
  }
}

export class ImageBaseImageInfoModel {
  names: string[];
  allowed: boolean;
  validityDays: number;
  message: string;

  constructor(json: I.ImageBaseImageInfo = {}) {
    this.names = json.names && json.names.length ? json.names : ['unknown'];
    this.allowed = Boolean(json.allowed);
    this.validityDays = Number(json.validity_days);
    this.message = json.message ?? 'unknown';
  }
}

export class ComplianceCheckerInfoModel {
  status: string;
  reason: string;
  details: string;
  receivedAt: string;

  constructor(json: I.ImageComplianceCheckerInfo = {}) {
    this.status = json.status ?? 'unknown';
    this.reason = json.reason ?? 'unknown';
    this.details = json.details ?? 'unknown';
    this.receivedAt = json.received_at
      ? dayjs(json.received_at).format('DD-MM-YYYY hh:mm')
      : 'unknown';
  }
}

export class ImageComplianceInfoModel {
  status: string;
  reason: string;
  checker: ComplianceCheckerInfoModel;

  constructor(json: I.ImageComplianceInfo = {} as I.ImageComplianceInfo) {
    this.status = json.status ?? 'unknwon';
    this.reason = json.status ?? 'unknwon';
    this.checker = new ComplianceCheckerInfoModel(json.checker ?? {});
  }
}

export class ImagePlatformInfoModel {
  platformType: string;
  createdAt: string;
  size: number;
  labels: { [key: string]: string };

  constructor(json: I.ImagePlatformInfo = {}, name: string) {
    this.platformType = name;
    this.createdAt = json.created_at
      ? dayjs(json.created_at).format('DD-MM-YYYY hh:mm')
      : 'unknown';
    this.size = Number(json.size);
    this.labels = json.labels ?? {};
  }
}

export class ImageModel {
  originName: string;
  tag: string;
  mediaType: string;
  baseImage: ImageBaseImageInfoModel;
  compliance: ImageComplianceInfoModel;
  ci: ImageCIInfoModel;
  platforms: ImagePlatformInfoModel[];

  constructor(json: I.Image = {}) {
    this.originName = json.origin_name ?? 'unknown image name';
    this.tag = this.originName.split(':')[1] ?? 'missing';
    this.mediaType = json.media_type ?? 'unknown image type';
    this.baseImage = new ImageBaseImageInfoModel(json.base_image ?? {});
    this.compliance = new ImageComplianceInfoModel(json.compliance ?? {});
    this.ci = new ImageCIInfoModel(json.ci ?? {});
    this.platforms = Object.keys(json.platforms ?? {}).map(
      platform =>
        new ImagePlatformInfoModel((json.platforms || {})[platform], platform),
    );
  }
}
