import React from 'react';
import { List, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import * as S from '../../styles'; // Assuming the styles are in the same directory
import SettingsIcon from '@material-ui/icons/Settings';
import { useSearchParams } from 'react-router-dom';
import { ALL_LIBRARIES, MY_LIBRARIES } from '../../LibrariesTab';

export const LibraryOwnerFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const group = searchParams.get('group');
  const buttons = [
    {
      label: 'Personal',
      text: 'My Libraries',
      value: MY_LIBRARIES,
      icon: SettingsIcon,
    },
    { label: 'Zalando', text: 'All', value: ALL_LIBRARIES, icon: null },
  ];

  const handleClick = (value: string) => {
    setSearchParams({ group: value }, { replace: true });
  };

  return (
    <S.Wrapper id="catalog-filter">
      {buttons.map(button => (
        <>
          <S.Title variant="subtitle2">{button.label}</S.Title>
          <S.GroupWrapper>
            <List disablePadding dense>
              <S.MenuItem
                button
                divider
                onClick={() => handleClick(button.value)}
                selected={group === button.value}
                disableRipple
              >
                {button.icon && (
                  <S.ListItemIcon>
                    <button.icon fontSize="small" />
                  </S.ListItemIcon>
                )}
                <ListItemText>
                  <S.MenuTitle>{button.text}</S.MenuTitle>
                </ListItemText>
                <ListItemSecondaryAction />
              </S.MenuItem>
            </List>
          </S.GroupWrapper>
        </>
      ))}
    </S.Wrapper>
  );
};
