import React from 'react';

import {
  PullRequestTag,
  BranchTag,
  CommitTag,
  UserTag,
  TagGroup,
} from '../../../common';
import { RunsModel } from '../../../../models';

import * as S from './styles';
import {
  GitHubTag,
  TagDependency,
  TagHead,
  TagRetrigger,
  TagSchedule,
} from '../../../common/PipelineExecutionTags/PipelineExecutionTags';

// @TODO:
// - improve scaling and mobile styling

type Props = {
  item: RunsModel;
};

export function Commit({ item }: Props) {
  return (
    <S.Commit>
      <S.Title
        to={`./${item.id}/run`}
        title={`${item.commit_message || item.pr_title || 'n/a'}`}
      >
        {item.commit_message || item.pr_title}
      </S.Title>

      <TagGroup>
        {item.isSchedule && (
          <TagSchedule
            buildVersion={item.retriggered_from_pipeline_build_version}
          />
        )}

        {item.isRetriggered && (
          <TagRetrigger
            buildVersion={item.retriggered_from_pipeline_build_version}
          />
        )}

        {item.isTriggeredByDependency && (
          <TagDependency
            buildVersion={item.retriggered_from_pipeline_build_version}
            dependency={item.triggered_by_dependency}
          />
        )}

        {item.is_head && <TagHead />}

        {item.tags.labels.map(label => (
          <GitHubTag name={label} />
        ))}

        <BranchTag branch={item.tags.branch} repo={item.target_repo} />
        <UserTag user={item.tags.accountable_user} />
        {item.isPullRequest && (
          <PullRequestTag prNumber={item.pr_number} repo={item.target_repo} />
        )}
        <CommitTag id={item.commit_id} repo={item.target_repo} />
      </TagGroup>
    </S.Commit>
  );
}
