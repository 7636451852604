import React, { type PropsWithChildren } from 'react';
import { ConfigApi, configApiRef, useApi } from '@backstage/core-plugin-api';
import { Tracer, TracerOptions } from 'lightstep-tracer';
import { initGlobalTracer, Tags } from 'opentracing';

const isProduction = process.env.NODE_ENV === 'production';

function escapeRegExp(string: String) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function getTracerConfig(configApi: ConfigApi): TracerOptions {
  const defaultTags = {
    browser: true,
    application: 'sunrise',
    deployment_id:
      configApi.getOptionalString(
        'features.opentracing.lightstepDeploymentId',
      ) || '',
    [Tags.SPAN_KIND]: Tags.SPAN_KIND_RPC_CLIENT,
  };

  const backendUrlRegexp = new RegExp(
    `^${escapeRegExp(configApi.getString('backend.baseUrl'))}`,
  );

  return {
    access_token:
      configApi.getOptionalString(
        'features.opentracing.lightstepAccessToken',
      ) || '',
    collector_host: configApi.getOptionalString(
      'features.opentracing.lightstepCollectorHost',
    ),
    default_span_tags: defaultTags,
    collector_port: configApi.getOptionalNumber(
      'features.opentracing.lightstepCollectorPort',
    ),
    xhr_instrumentation: true,
    xhr_url_header_inclusion_patterns: [backendUrlRegexp],
    fetch_instrumentation: true,
    fetch_url_inclusion_patterns: [backendUrlRegexp],
    // for developer mode encryption must be disabled
    collector_encryption: isProduction ? 'tls' : 'none',
    component_name:
      configApi.getOptionalString(
        'features.opentracing.lightstepServiceName',
      ) || '',
    include_cookies: false,
    verbosity: configApi.getOptionalNumber(
      'features.opentracing.lightstepVerbosity',
    ),
  };
}

export function WithTracing(props: PropsWithChildren<{}>) {
  const configApi = useApi(configApiRef);
  const tracerConfig = React.useMemo(
    () => getTracerConfig(configApi),
    [configApi],
  );

  React.useEffect(() => {
    if (tracerConfig.access_token) {
      const tracer = new Tracer(tracerConfig);
      initGlobalTracer(tracer);
    }
  }, [tracerConfig]);

  return <>{props.children}</>;
}
