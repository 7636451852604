import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function SupportUrl({ value, issue }: FieldProps<'support_url'>) {
  return (
    <>
      <dt>Support URL</dt>
      <dd>
        {value ? (
          <Link to={value}>
            {value} <OpenInNewIcon />
          </Link>
        ) : (
          'No support URL defined.'
        )}
        <Issue {...issue} />
      </dd>
    </>
  );
}
