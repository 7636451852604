import React, { useCallback, useState } from 'react';
import { Grid, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { WeeklyIncidents } from './WeeklyIncidents';
import { WeeklyIncidentsSeverity } from './WeeklyIncidentsSeverity';
import { WeeklyIncidentsResponders } from './WeeklyIncidentsResponder';
import { QuarterlyIncidentsResponders } from './QuarterlyIncidentsResponder';
import { HourlyIncidents } from './HourlyIncidents';
import { MonthlyIncidentsResponders } from './MonthlyIncidentsResponder';
import { DailyIncidentsResponders } from './DailyIncidentsResponder';
import { DailyIncidents } from './DailyIncidents';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { opsgenieApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import {
  Context,
  DEFAULT_BUSINESS_HOURS_END,
  DEFAULT_BUSINESS_HOURS_START,
} from '../../analytics';
import { InfoPanel } from '../InfoPanel';
import { WeeklyImpactResponders } from './WeeklyImpactResponder';
import { useUserTeams } from 'plugin-ui-components';
import { Incident, Team } from '../../types';
import * as S from '../OnCallPage/styles';
import List from '@material-ui/core/List';
import SettingsIcon from '@material-ui/icons/Settings';
import { ContentWrapper } from 'plugin-ui-components';

dayjs.extend(localizedFormat);

export const Analytics = () => {
  const [userTeamIds, setUserTeamIds] = React.useState<string[] | undefined>(
    undefined,
  );
  const [opsgenieData, setOpsgenieData] = React.useState<
    [Incident[], Incident[], Team[]] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [returnedError, setReturnedError] = React.useState<Error | undefined>(
    undefined,
  );
  const [view, setView] = useState('mine');
  const configApi = useApi(configApiRef);
  const opsgenieApi = useApi(opsgenieApiRef);

  const fromMy = dayjs().subtract(1, 'year').startOf('quarter');
  const fromAll = dayjs().subtract(6, 'months').startOf('quarter');
  const to = dayjs();

  const {
    value: { userTeams },
    loading,
  } = useUserTeams();

  React.useEffect(() => {
    if (loading) return;
    if (userTeams?.length) {
      setUserTeamIds(
        userTeams.map((item: { spec: { id: any } }) => item.spec.id),
      );
    }
  }, [loading, userTeams]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const getOpsgenieData = useCallback(async () => {
    const myIncidents = await opsgenieApi.getIncidents({
      limit: 100,
      query: `createdAt < ${to.valueOf()} AND createdAt > ${fromMy.valueOf()}`,
      teams: userTeamIds,
    });
    const allIncidents = await opsgenieApi.getIncidents({
      limit: 100,
      query: `createdAt < ${to.valueOf()} AND createdAt > ${fromAll.valueOf()}`,
    });
    const teams = await opsgenieApi.getTeams();
    setOpsgenieData([
      myIncidents.filter(
        incident =>
          dayjs(incident.impactStartDate).isAfter(fromMy) &&
          !incident.tags.includes('test'),
      ),
      allIncidents.filter(
        incident =>
          dayjs(incident.impactStartDate).isAfter(fromAll) &&
          !incident.tags.includes('test'),
      ),
      teams,
    ]);
    setIsLoading(false);
  }, [userTeamIds]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!userTeamIds) return;
    getOpsgenieData().catch(err => {
      setReturnedError(err);
      setIsLoading(false);
    });
  }, [userTeamIds]);

  if (loading || isLoading) {
    return <Progress />;
  } else if (returnedError) {
    return <Alert severity="error">{returnedError.message}</Alert>;
  }

  const incidents = view === 'mine' ? opsgenieData![0] : opsgenieData![1];
  const from = view === 'mine' ? fromMy : fromAll;
  const title =
    view === 'mine'
      ? 'This Graphs cover one year worth of incidents, from the current quarter to the same quarter last year.'
      : 'This Graphs cover 6 months worth of incidents, from the current quarter to the same quarter 6 months ago.';

  const context: Context = {
    from: from,
    to: to,
    incidents: incidents,
    teams: opsgenieData![2],
  };

  const businessHours = {
    start:
      configApi.getOptionalNumber('opsgenie.analytics.businessHours.start') ||
      DEFAULT_BUSINESS_HOURS_START,
    end:
      configApi.getOptionalNumber('opsgenie.analytics.businessHours.end') ||
      DEFAULT_BUSINESS_HOURS_END,
  };

  const handleChange = (v: string) => {
    setView(v);
  };

  return (
    <ContentWrapper>
      {' '}
      <div>
        <Grid>
          <S.Wrapper id="category-filter">
            <React.Fragment key="Personal">
              <S.Title variant="subtitle2">Personal</S.Title>
              <S.GroupWrapper>
                <List disablePadding dense>
                  <S.MenuItem
                    key="myIncidents"
                    button
                    divider
                    onClick={() => handleChange('mine')}
                    selected={view === 'mine'}
                    disableRipple
                  >
                    <S.ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </S.ListItemIcon>
                    <ListItemText>
                      <S.MenuTitle>For my applications</S.MenuTitle>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {opsgenieData![0].length}
                    </ListItemSecondaryAction>
                  </S.MenuItem>
                </List>
              </S.GroupWrapper>
            </React.Fragment>
            <React.Fragment key="Zalando">
              <S.Title variant="subtitle2">Zalando</S.Title>
              <S.GroupWrapper>
                <List disablePadding dense>
                  <S.MenuItem
                    key="all"
                    button
                    divider
                    onClick={() => handleChange('all')}
                    selected={view === 'all'}
                    disableRipple
                  >
                    <ListItemText>
                      <S.MenuTitle>For All applications</S.MenuTitle>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {opsgenieData![1].length}
                    </ListItemSecondaryAction>
                  </S.MenuItem>
                </List>
              </S.GroupWrapper>
            </React.Fragment>
          </S.Wrapper>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InfoPanel
            title={title}
            message={
              <ul>
                <li>Incidents from {from.format('LL')} to now are used</li>
                <li>
                  Business hours are {businessHours.start} to{' '}
                  {businessHours.end}
                </li>
                <li>
                  Responders are read from the <code>responders</code> incident
                  extra property if defined, or from the "responders" section of
                  an incident.
                </li>
              </ul>
            }
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyIncidents context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyIncidentsSeverity context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <HourlyIncidents context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <DailyIncidents context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyIncidentsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <MonthlyIncidentsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <QuarterlyIncidentsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <DailyIncidentsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyImpactResponders context={context} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
