import { styled, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Text = styled('p')(({ theme }: { theme: Theme }) => ({
  maxWidth: '100%',
  margin: '0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '.85rem',

  ['& a:hover']: {
    color: theme.palette.link,
  },
}));

export const TextLight = styled(Text)((props: { theme: Theme }) => ({
  color: props.theme.palette.text.secondary,
  fontSize: '.8rem',
  marginBottom: 0,
}));

export const fontSize = {
  tiny: '.75rem',
  small: '.8rem',
  default: '.85rem',
  medium: '1rem',
  large: '1.2rem',
  super: '1.5rem',
};

export const Title = styled(Link)((props: { theme: Theme }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  fontSize: fontSize.small,
  color: props.theme.palette.text.hint,

  ['&, &:visited']: {
    color: props.theme.palette.text.primary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));

export const ErrorMessage = styled('div')((props: { theme: Theme }) => ({
  padding: '4px 6px',
  color: props.theme.palette.error.main,
  fontWeight: 600,
  fontSize: '.75rem',
  lineHeight: '1',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  background: 'rgb(253 59 92 / 0.1)',
  borderRadius: '4px',
}));
