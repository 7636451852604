import { DiscoveryApi } from '@backstage/core-plugin-api';

/**
 * Creates and return the proxy URL of a provided API path
 * @param discoveryInstance An instance of the discovery API
 * @param path The API path
 */
export async function buildProxyUrl(
  discoveryInstance: DiscoveryApi,
  path: string,
): Promise<string> {
  const proxyUrl = await discoveryInstance.getBaseUrl('proxy');
  return `${proxyUrl}/${path}`;
}
