import { action, computed, makeObservable, observable } from 'mobx';
import { LogsFoldModel, LogsFoldStatusEnum } from './fold.model';
import { ILogFolds } from '../../api/types/payload';
import {
  addMinutesToNanoTimestamps,
  dateStringToNanoTimestamp,
  getNowTimestampInNanoseconds,
} from '../../utils/time';

type NormalizedFold = {
  name: string;
  firstEventTimestamp: number;
  lastEventTimestamp: number;
};

export class LogsModel {
  folds: Array<LogsFoldModel> = [];

  constructor() {
    makeObservable(this, {
      folds: observable,
      upsertFolds: action,
      toggleOpen: action,
      wereAllLinesRetrieved: computed,
    });
  }

  upsertFolds = (folds: ILogFolds, isRunFinished: boolean) => {
    const normalizedFolds = this.normalizeFolds(folds);

    normalizedFolds.forEach((normalizedFold, index) => {
      const foldStatus = this.computeFoldStatus(
        normalizedFolds,
        index,
        isRunFinished,
      );

      const automatedIsOpenState = this.computeAutomatedIsOpenState(
        normalizedFolds,
        index,
      );

      if (index < this.folds.length) {
        this.folds[index].update(normalizedFold.lastEventTimestamp, foldStatus);

        if (!isRunFinished && !this.folds[index].manualModeEnabled) {
          this.folds[index].isOpen = automatedIsOpenState;
        }
      } else {
        this.folds.push(
          new LogsFoldModel(
            normalizedFold.name,
            normalizedFold.firstEventTimestamp,
            normalizedFold.lastEventTimestamp,
            foldStatus,
            automatedIsOpenState,
          ),
        );
      }
    });
  };

  toggleOpen = (selectedFold: LogsFoldModel) => {
    this.folds.forEach(fold => {
      if (fold === selectedFold) {
        fold.toggleOpen();
      } else {
        fold.close();
      }
    });
  };

  // Internals
  normalizeFolds = (folds: ILogFolds): Array<NormalizedFold> => {
    const values = folds?.values ?? [];

    return values.map(value => ({
      name: value[0],
      firstEventTimestamp: value[1],
      lastEventTimestamp: value[2],
    }));
  };

  computeFoldStatus = (
    normalizedFolds: Array<NormalizedFold>,
    currentFoldIndex: number,
    isRunFinished: boolean,
  ): LogsFoldStatusEnum => {
    return isRunFinished || currentFoldIndex < normalizedFolds.length - 1
      ? LogsFoldStatusEnum.Finished
      : LogsFoldStatusEnum.InProgress;
  };

  computeAutomatedIsOpenState = (
    normalizedFolds: Array<NormalizedFold>,
    currentFoldIndex: number,
  ) => {
    if (
      currentFoldIndex === normalizedFolds.length - 3 &&
      normalizedFolds[currentFoldIndex + 1].name === 'Uploading Cache' &&
      normalizedFolds[currentFoldIndex + 2].name === 'Tearing down environment'
    ) {
      return true;
    }

    if (
      currentFoldIndex === normalizedFolds.length - 2 &&
      normalizedFolds[currentFoldIndex + 1].name ===
        'Tearing down environment' &&
      normalizedFolds[currentFoldIndex].name !== 'Uploading Cache'
    ) {
      return true;
    }

    if (
      currentFoldIndex === normalizedFolds.length - 1 &&
      normalizedFolds[currentFoldIndex].name !== 'Tearing down environment'
    ) {
      return true;
    }

    return false;
  };

  public get wereAllLinesRetrieved() {
    return this.folds.some(fold => !fold.hasPendingLines);
  }

  public getToTimestamp = (
    fold: LogsFoldModel,
    isRunFinished: boolean,
    stepFinishedAt: string,
  ): number => {
    if (fold.status === LogsFoldStatusEnum.Finished) {
      const index = this.folds.indexOf(fold);

      if (index + 1 < this.folds.length) {
        return this.folds[index + 1].firstEventTimestamp;
      }

      if (isRunFinished) {
        return addMinutesToNanoTimestamps(
          dateStringToNanoTimestamp(stepFinishedAt),
          1,
        );
      }
    }

    return addMinutesToNanoTimestamps(getNowTimestampInNanoseconds(), 1);
  };

  public get getTotalLines(): number {
    return this.folds.reduce((sum, item) => sum + item.lines.length, 0);
  }

  public get getTotalDisplayedLines(): number {
    return this.folds.reduce(
      (sum, item) => sum + (item.isOpen ? item.lines.length : 0),
      0,
    );
  }
}
