import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CircularProgress, FormHelperText } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useAsync } from 'react-use';
import { applicationReviewApiRef } from '../../api';
import { RadioSelect, Issue } from '../common';
import { FormFields } from '../config';
import { ReviewContext } from '../context';

export function PlaybooksList() {
  const api = useApi(applicationReviewApiRef);
  const { entity } = useEntity();
  const { issues, review } = React.useContext(ReviewContext);
  const { loading, value } = useAsync(
    () => api.getPlaybooks(entity.metadata.name),
    [entity],
  );

  if (loading) {
    return <CircularProgress size="1.5em" />;
  }

  if (!value) {
    return (
      <>
        No incident playbooks linked to application "{entity?.metadata?.name}"
        found.
        {issues
          .filter(i => i.field === 'playbooks')
          .map((issue, i) => (
            <Issue key={i} {...issue} />
          ))}
      </>
    );
  }

  return (
    <>
      {value?.length ? (
        value.map(item => {
          const name = `playbook_${item.id}` as FormFields;
          const issue = issues.find(
            i => i.field === 'playbooks' && i.message.includes(item.id),
          );
          return (
            <>
              <RadioSelect
                required={false}
                disabled={review.playbooks_ok === 'n/a'}
                key={item.id}
                name={name}
                helperText={
                  item.expiry ? (
                    <>
                      expires <time dateTime={item.expiry}>{item.expiry}</time>
                    </>
                  ) : undefined
                }
              >
                <Link to={item.url}>
                  {item.id}: {item.name}
                </Link>
              </RadioSelect>
              <Issue
                {...issue}
                key={`${item.id}-issue`}
                className="top-arrow mb-2"
              />
            </>
          );
        })
      ) : (
        <FormHelperText>
          No incident playbooks linked to application "{entity?.metadata?.name}"
          found.
        </FormHelperText>
      )}
    </>
  );
}
