import { Content, Page } from '@backstage/core-components';
import { MyLibraries } from './MyLibraries';
import React from 'react';
import { SearchLibraryPage } from './SearchLibraries';
import { useSearchParams } from 'react-router-dom';
import { Header } from './Header';
import { FeatureFlagRibbon } from 'plugin-ui-components';

export const MY_LIBRARIES = 'mine';
export const ALL_LIBRARIES = 'all';

export const LibrariesTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const group = searchParams.get('group');

  React.useEffect(() => {
    const currentGroup = group ?? MY_LIBRARIES;
    setSearchParams({ group: currentGroup }, { replace: true });
  }, [group, searchParams, setSearchParams]);

  return (
    <FeatureFlagRibbon name="dependencies-plugin" theme="purple" page>
      <Page themeId="tool">
        <Header />
        <Content>
          {group === MY_LIBRARIES ? (
            <MyLibraries key="myLibraries" />
          ) : (
            <SearchLibraryPage key="allLibraries" />
          )}
        </Content>
      </Page>
    </FeatureFlagRibbon>
  );
};
