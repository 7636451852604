import {
  GetMLPipelinesResponse,
  GetMLProjectsResponse,
  GetModelGroupsResponse,
  GetModelMetrics,
  GetModelsResponse,
  GetPipelineRunsResponse,
  MLModel,
  MLPipeline,
  MLPipelineRun,
  ModelGroup,
  RunStatus,
  UserTeam,
} from './definitions';

export const PLACEHOLDER_ML_PIPELINES: GetMLPipelinesResponse = {
  meta: { total: 0, deployment_environments: [] },
  pipelines: [],
};

export const PLACEHOLDER_PIPELINE_RUNS: GetPipelineRunsResponse = {
  meta: { total: 0 },
  runs: [],
};

export const PLACEHOLDER_PIPELINE_RUN: MLPipelineRun = {
  archived: false,
  created_at: '',
  created_by: '',
  last_modified_at: '',
  metadata: {
    ZFLOW_CDP_ACCOUNTABLE_USER: '',
    ZFLOW_CDP_BUILD_VERSION: '',
    ZFLOW_CDP_CONFIGURATION_SOURCE: '',
    ZFLOW_PROJECT_ID: '',
    ZFLOW_VERSION: '',
  },
  metrics: [],
  name: '',
  params: [],
  pipeline_id: '',
  project_id: '',
  run_id: '',
  status: RunStatus.Succeeded,
  steps: [],
};

export const PLACEHOLDER_ML_PIPELINE: MLPipeline = {
  archived: false,
  created_by: '',
  deployment_environment: '',
  last_run: PLACEHOLDER_PIPELINE_RUN,
  name: '',
  id: '',
  project_id: '',
  created_at: '',
};

export const PLACEHOLDER_MODEL_GROUPS: GetModelGroupsResponse = {
  meta: { total: 0 },
  model_groups: [],
};

export const PLACEHOLDER_MODEL_GROUP: ModelGroup = {
  deployment_environment: '',
  description: '',
  name: '',
  model_group_id: '',
  project_id: '',
  created_at: '',
  tags: {},
};

export const PLACEHOLDER_ML_MODELS: GetModelsResponse = {
  meta: { total: 0 },
  models: [],
};

export const PLACEHOLDER_ML_METRICS: GetModelMetrics = {
  meta: { total: 0 },
  metrics: [],
};

export const PLACEHOLDER_ML_MODEL: MLModel = {
  deployment_environment: '',
  citation: '',
  considerations: {},
  license: '',
  model_parameters: null,
  overview: '',
  owners: [],
  quantitative_analysis: null,
  references: null,
  approval: null,
  created_at: '',
  created_by: '',
  deployment: null,
  model_group_id: '',
  containers: [],
  model_id: '',
  name: '',
  pipeline_id: '',
  project_id: '',
  run_id: '',
  step_id: '',
  tags: {
    ZFLOW_MODEL_TAG: '',
    ZFLOW_PIPELINE_NAME: '',
    ZFLOW_TRAINING_JOB_IDS: [''],
    MODEL_NAME: '',
  },
  updated_at: '',
  updated_by: '',
  version_id: '',
};

export const PLACEHOLDER_ML_PROJECTS: GetMLProjectsResponse = {
  meta: { total: 0 },
  projects: [],
};

export const PLACEHOLDER_USER_TEAM: UserTeam = {
  id: '',
  id_name: '',
  name: '',
  type: '',
  nickname: 'All',
  team_id: 'All',
};
