import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { zflowApiRef } from '../../api/zflowApiClient';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Button, ThemeProvider, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { success } from '../common/alerts';
import { PAGE_REFRESH_WAIT_TIME } from '../../constants/common';
import { ButtonTheme } from '../common/customTheme';

export const ArchiveProject = ({
  projectId,
  owner,
  archived,
  setRefreshPage,
}: {
  projectId: string;
  owner: boolean | undefined;
  archived: boolean | undefined;
  setRefreshPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [clicked, setClicked] = React.useState<boolean>(false);

  const zflowApi = useApi(zflowApiRef);

  const handleClick = () => {
    zflowApi
      .archiveProject(projectId)
      .then(() => {
        setClicked(true);
        setTimeout(() => {
          setRefreshPage(true);
        }, PAGE_REFRESH_WAIT_TIME);
      })
      .then(() => setOpenDialog(false));
  };

  return (
    <>
      {clicked && success('Successfully archived project')}
      <Dialog fullWidth open={openDialog}>
        <DialogTitle>Archive Project</DialogTitle>
        <DialogContent dividers>
          <Typography>
            You are about to archive this project.
            <ul>
              <li>Make sure your scheduled notebooks are disabled.</li>
              <li>
                Make sure your zflow pipelines are detached from this project.
              </li>
            </ul>
            Please, note that this is <b>irreversible</b>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            data-testid="archive-cancel"
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            data-testid="archive-proceed"
            color="primary"
          >
            Archive Project
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={ButtonTheme}>
        <Button
          variant="contained"
          color="primary"
          data-testid="archive-button"
          onClick={() => setOpenDialog(true)}
          disabled={clicked || !owner || archived}
          startIcon={<ArchiveIcon />}
        >
          Archive
        </Button>
      </ThemeProvider>
    </>
  );
};
