import { OrganizationModel } from './organization.model';
import { IRepositories } from '../../api/types/payload';
import { RepositoryModel } from './repository.model';

export class RepositoryTreeModel {
  static fromFullTree(data: IRepositories) {
    const organizations: Array<OrganizationModel> = [];
    let repositories: Array<RepositoryModel> = [];

    Object.keys(data ?? {}).forEach((domain: any) => {
      Object.keys(data[domain]).forEach((organization: any) => {
        organizations.push(new OrganizationModel(domain, organization));
        repositories = repositories.concat(
          data[domain][organization].map(
            name => new RepositoryModel(name, organization, domain),
          ),
        );
      });
    });

    return new this(organizations, repositories);
  }

  static fromBranchTree(
    names: Array<string>,
    organization: string,
    domain: string,
  ) {
    return new this(
      [],
      names.map(name => new RepositoryModel(name, organization, domain)),
    );
  }

  constructor(
    public organizations: Array<OrganizationModel>,
    public repositories: Array<RepositoryModel>,
  ) {}

  public setFilteredRepositories = (
    names: Array<string>,
    organization: string,
    domain: string,
  ) => {
    this.repositories = names.map(
      name => new RepositoryModel(name, organization, domain),
    );
  };
}
