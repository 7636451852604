import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Table } from '@backstage/core-components';
import { useTheme } from '@material-ui/core';
import { zflowApiRef } from '../../api/zflowApiClient';
import { useNavigate } from 'react-router';
import {
  SMALL_PAGE_SIZE,
  FIXED_PAGE_SIZE_OPTIONS,
} from '../../constants/PagingConstants';
import { PLACEHOLDER_ML_PIPELINES } from '../../api/PlaceholderResponses';
import { MLTablePagination, MLTableToolbar } from '../common/TableComponents';
import { tableHeaderStyles } from '../../styles';
import { getPipelineListColumns } from '../common/columns/PipelineListColumns';
import orderBy from 'lodash/orderBy';
import { GetMLPipelinesResponse, MLPipeline } from '../../api/definitions';
import filter from 'lodash/filter';
import { DEFAULT_SELECT_OPTION } from '../../constants/common';

export const PipelineList = ({ projectId }: { projectId: string }) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();
  const [selectedDeploymentEnvironment, setSelectedDeploymentEnvironment] =
    useState<string>(DEFAULT_SELECT_OPTION);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    DEFAULT_SELECT_OPTION,
  );
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(SMALL_PAGE_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tableData, setTableData] = useState<any>({
    pipelines: PLACEHOLDER_ML_PIPELINES,
    lastRunStatuses: [],
    deploymentEnvironments: [],
  });

  const filterPipelines = useCallback(
    (pipelines: MLPipeline[]) => {
      const filterConditions: any = {
        last_run: {
          status: selectedStatus,
        },
      };

      return filter(pipelines, { ...filterConditions });
    },
    [selectedStatus],
  );

  function sortPipelines(pipelinesResponse: GetMLPipelinesResponse) {
    return orderBy(
      pipelinesResponse.pipelines,
      [
        (pipeline: MLPipeline) =>
          new Date(pipeline.last_run?.last_modified_at ?? ''),
      ],
      ['desc'],
    );
  }

  const setupPipelines = useCallback(
    (pipelinesResponse: GetMLPipelinesResponse) => {
      const pipelinesTable: any = {};
      const sortedPipelines = sortPipelines(pipelinesResponse);
      if (selectedStatus === DEFAULT_SELECT_OPTION) {
        pipelinesTable.pipelines = {
          meta: {
            total: pipelinesResponse.meta.total,
          },
          pipelines: sortedPipelines,
        };
      } else {
        const filteredPipelines = filterPipelines(sortedPipelines);

        pipelinesTable.pipelines = {
          meta: {
            total: pipelinesResponse.meta.total,
          },
          pipelines: filteredPipelines,
        };
      }

      pipelinesTable.deploymentEnvironments =
        pipelinesResponse.meta.deployment_environments;
      pipelinesTable.lastRunStatuses = pipelinesResponse.pipelines.map(
        currentPipeline => {
          return currentPipeline.last_run?.status;
        },
      );
      setIsLoading(false);
      setTableData(pipelinesTable);
    },
    [filterPipelines, selectedStatus],
  );

  useEffect(() => {
    const offset = SMALL_PAGE_SIZE * page;
    zflowApi
      .getPipelines(
        projectId,
        SMALL_PAGE_SIZE,
        selectedDeploymentEnvironment,
        offset,
      )
      .then(setupPipelines);
  }, [
    zflowApi,
    projectId,
    selectedStatus,
    selectedDeploymentEnvironment,
    page,
    setupPipelines,
  ]);

  useEffect(() => {
    setIsLoading(true);
  }, [selectedStatus, selectedDeploymentEnvironment]);

  return (
    <Table
      data={tableData.pipelines.pipelines}
      columns={getPipelineListColumns(
        tableData.lastRunStatuses,
        selectedStatus,
        setSelectedStatus,
        tableData.deploymentEnvironments,
        selectedDeploymentEnvironment,
        setSelectedDeploymentEnvironment,
      )}
      options={{
        pageSize: SMALL_PAGE_SIZE,
        showTitle: false,
        filtering: true,
        search: false,
        draggable: false,
        showFirstLastPageButtons: true,
        emptyRowsWhenPaging: false,
        headerStyle: tableHeaderStyles(theme) as React.CSSProperties,
      }}
      onRowClick={(event, rowData: any) => {
        event?.stopPropagation();
        navigate(`${rowData.id}/runs`);
      }}
      isLoading={isLoading}
      components={{
        Pagination: props => (
          <MLTablePagination
            props={props}
            page={page}
            resource={tableData.pipelines}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={FIXED_PAGE_SIZE_OPTIONS}
          />
        ),
        Toolbar: props => <MLTableToolbar props={props} />,
      }}
    />
  );
};
