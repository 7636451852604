import React from 'react';
import { AutocompleteResourceFilter } from './FilterTypes';

const label = 'Application';
const id = 'application-filter';

export const ApplicationFilter = React.memo(
  ({
    onChange,
    selected,
    applicationIds,
    loading,
  }: {
    onChange?: (value: string) => void;
    selected?: string;
    applicationIds?: { label: string; value: string }[] | undefined;
    loading: boolean;
  }) => {
    return (
      <AutocompleteResourceFilter
        values={applicationIds}
        onChange={onChange}
        selected={selected}
        id={id}
        label={label}
        disabled={applicationIds?.length === 0 || loading}
      />
    );
  },
);
