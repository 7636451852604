import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AccessControlTableCardProps } from './types';
import { useStyles } from './styles';
import { addButton } from './tracking';
import { TrackedInteraction } from 'plugin-ui-components';
import { Alert } from '@material-ui/lab';

export const AccessControlTableCard = ({
  data,
  title,
  render,
  renderEmpty,
  onCreate,
  isLoading,
  trackingPlugin,
  hasEditPermission,
  isError,
}: AccessControlTableCardProps) => {
  const styles = useStyles();
  const trackingParams = {
    widget: title.toLowerCase(),
    plugin: trackingPlugin,
  };

  const handleCreate = async () => {
    if (onCreate) await onCreate();
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        title={
          <Box marginBottom={2}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        }
        action={
          <TrackedInteraction
            interaction="onClick"
            {...addButton(trackingParams)}
          >
            {hasEditPermission ? (
              <IconButton
                aria-label="settings"
                onClick={handleCreate}
                className={styles.cardIcon}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            ) : (
              <Tooltip
                title="Only the owner can edit the application"
                placement="top"
              >
                <span>
                  <IconButton
                    aria-label="settings"
                    className={styles.cardIcon}
                    disabled
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </TrackedInteraction>
        }
      />
      <Divider />
      <CardContent>
        {isError && (
          <Alert severity="error">
            An error occured while loading the Resource Types for this app. Try
            again.
          </Alert>
        )}
        {isLoading && <LinearProgress className={styles.loader} />}
        {!isLoading && !isError && (
          <Box
            display="flex"
            flexDirection="column"
            className={styles.dataWrapper}
          >
            {data.length === 0 ? (
              renderEmpty
            ) : (
              <table className={styles.stripedTable}>
                <thead>
                  <tr>
                    <td className={styles.firstColumn}>Title & Description</td>
                    <td className={styles.alignCenter}>Scopes</td>
                    <td className={styles.alignCenter}>Actions</td>
                  </tr>
                </thead>
                <tbody>{data.map((item, index) => render(item, index))}</tbody>
              </table>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
