import { getTeamNameFromFullName } from 'plugin-core';
import { parseEntityRef } from '@backstage/catalog-model';
import { TeamScorecardsTreeData } from './components/Overview/types';
import { UserTeamsScorecardsInfo } from './hooks';

export const calculateScorecardCompletion = (
  scorecards: ScorecardEntityWithAssessment[],
) => {
  if (!scorecards) return 0;
  const totalScorecards = scorecards.filter(
    sc => !!sc.spec.checks.length,
  ).length;
  const completedScorecards = scorecards
    .filter(sc => !!sc.spec.checks.length)
    .filter(scorecard => scorecard.spec.completionPercentage === 100).length;
  return Math.round((completedScorecards / totalScorecards) * 100) || 0;
};

/* Build a tree structure from a user's teams
 * containing each team's scorecard metrics
 */
export function transformUserTeamsToTree(
  userTeams: IEntityGroup[],
  userTeamsInfo: UserTeamsScorecardsInfo,
): TeamScorecardsTreeData[] {
  const { teamMetrics } = userTeamsInfo;

  // Create the initial team data structure
  const tree = userTeams.map((group, index) => {
    const { name } = group.metadata;
    const metrics = teamMetrics.get(name);

    return {
      parentId: 0,
      id: index + 1,
      name,
      fullName: getTeamNameFromFullName(group.spec.fullName),
      failing: metrics?.failing || 0,
      passing: metrics?.passing || 0,
      notApplicable: metrics?.notApplicable || 0,
      score: metrics?.score || 0,
      childTeams: userTeams
        .filter(t =>
          t.relations?.find(
            rel =>
              rel.type === 'childOf' &&
              parseEntityRef(rel.targetRef).name === name,
          ),
        )
        .map(t => getTeamNameFromFullName(t.spec.fullName)),
      relations: group.relations?.filter(({ type }) =>
        ['parentOf', 'childOf'].includes(type),
      ),
    } as TeamScorecardsTreeData;
  });

  // Assign parentId based on "childOf" relations
  tree.forEach(team => {
    const childRelation = team.relations?.find(
      ({ type }) => type === 'childOf',
    );
    if (childRelation) {
      const parentTeam = tree.find(
        t => t.name === parseEntityRef(childRelation.targetRef).name,
      );
      if (parentTeam) {
        team.parentId = parentTeam.id;
      }
    }
  });

  // Sort teams by parentId in descending order (process deeper children first)
  const sortedTeams = [...tree].sort((a, b) => b.parentId - a.parentId);

  // Aggregate scores for parents from their children
  sortedTeams.forEach(team => {
    if (team.parentId !== 0) {
      const parentTeam = tree.find(t => t.id === team.parentId);
      if (parentTeam) {
        parentTeam.failing += team.failing;
        parentTeam.passing += team.passing;
        parentTeam.notApplicable += team.notApplicable;

        const { failing, passing } = parentTeam;
        parentTeam.score =
          passing + failing > 0
            ? Math.round((passing / (passing + failing)) * 100)
            : 0;
      }
    }
  });

  return tree;
}

export const getColor = (
  completionPercentage: number,
): 'success' | 'warning' | 'error' => {
  if (completionPercentage >= 90) {
    return 'success';
  }
  if (completionPercentage >= 50) {
    return 'warning';
  }
  return 'error';
};
