import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { HTTPMethod } from './definitions';

class ResponseError extends Error {
  violations: string[];
  constructor(message = 'Something went wrong', violations = ['']) {
    super();
    this.message = message;
    this.violations = violations;
  }
}

interface Violation {
  field: string;
  message: string;
}

interface ErrorResponse {
  status: number;
  violations: Violation[];
  title: string;
  type: string;
  detail?: string;
}

interface HTTPRequestParameters {
  method: HTTPMethod;
  path: string;
  body?: {};
  params?: {};
}

export class HttpClient {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly pluginProxyEndpoint: string;

  constructor(options: {
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
    pluginProxyEndpoint: string;
  }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
    this.pluginProxyEndpoint = options.pluginProxyEndpoint;
  }

  private static buildHeaders(token: string): HeadersInit {
    return new Headers({
      authorization: `Bearer ${token}`,
      accept: 'application/json',
      'content-type': 'application/json',
    });
  }

  private async buildUrl(path: string): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}/${this.pluginProxyEndpoint}${path}`;
  }

  private static fetchToJson(res: Response) {
    return res.headers.get('content-type') === 'application/json'
      ? res.json()
      : res.text();
  }

  private static handleFailure(response: any) {
    return response.then((errorResponse: ErrorResponse) => {
      let violationMessages = [''];
      if (errorResponse.violations) {
        violationMessages = errorResponse.violations.map(
          error => error.message,
        );
      } else if (errorResponse.detail) {
        violationMessages = [errorResponse.detail];
      }
      if (errorResponse.title) {
        throw new ResponseError(errorResponse.title, violationMessages);
      }
      throw new ResponseError(`Please try again: ${errorResponse}`);
    });
  }

  private static handleResponse(response: Response) {
    const jsonResponse = HttpClient.fetchToJson(response);
    if (response.ok) {
      return jsonResponse;
    }
    return HttpClient.handleFailure(jsonResponse);
  }

  private async makeRequest({
    method,
    path,
    body,
    params,
  }: HTTPRequestParameters) {
    let url: string = await this.buildUrl(path);
    const token: string = await this.oauth2Api.getAccessToken();
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }

    return await fetch(url, {
      method: method,
      headers: HttpClient.buildHeaders(token),
      body: JSON.stringify(body),
      credentials: 'include',
    }).then(response => {
      return HttpClient.handleResponse(response);
    });
  }

  public get(path: string, params?: {}) {
    return this.makeRequest({
      method: HTTPMethod.GET,
      path: path,
      params: params,
    });
  }

  public patch(path: string, body: any = {}) {
    return this.makeRequest({
      method: HTTPMethod.PATCH,
      path: path,
      body: body,
    });
  }

  public post(path: string, body: any = {}) {
    return this.makeRequest({
      method: HTTPMethod.POST,
      path: path,
      body: body,
    });
  }

  public put(path: string, body: any = {}) {
    return this.makeRequest({ method: HTTPMethod.PUT, path: path, body: body });
  }
}
