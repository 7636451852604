import { lighten } from '@material-ui/core';
import { colors } from 'plugin-ui-components';

export const STATUS_COLORS = {
  default: lighten(colors.brand.basic, 0.6),
  skipped: lighten(colors.brand.basic, 0.8),
  success: colors.semantic.successBase,
  failed: colors.semantic.errorBase,
  info: colors.semantic.infoBase,
  progress: colors.semantic.pendingBase,
  warning: colors.semantic.warningBase,
  aborted: colors.brand.primaryOrange,
};

export const TERMINAL_COLORS = {
  hover: colors.brand.basic,
  background: lighten(colors.brand.basic, 0.07),
  text: '#fff',
  link: '#de7b02',
  backgroundError: '#f851491a',
};

const root = document.documentElement;
// eslint-disable-next-line guard-for-in
for (const key in TERMINAL_COLORS) {
  const value = TERMINAL_COLORS[key as keyof typeof TERMINAL_COLORS];
  root.style.setProperty(`--cdp-terminal-colors-${key}`, value);
}

// Pipeline Step Status Colors
root.style.setProperty(
  '--cdp-pipeline-step-status-color-not-started',
  STATUS_COLORS.default,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-skipped',
  STATUS_COLORS.skipped,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-succeeded',
  STATUS_COLORS.success,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-pending-approval',
  STATUS_COLORS.warning,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-failed',
  STATUS_COLORS.failed,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-error',
  STATUS_COLORS.failed,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-init',
  STATUS_COLORS.info,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-pending',
  STATUS_COLORS.info,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-in-progress',
  STATUS_COLORS.progress,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-aborted',
  STATUS_COLORS.aborted,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-aborting',
  STATUS_COLORS.aborted,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-timeout',
  STATUS_COLORS.aborted,
);
root.style.setProperty(
  '--cdp-pipeline-step-status-color-default',
  STATUS_COLORS.default,
);
