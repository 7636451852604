export interface ModelFilterStore {
  tag_name: string;
  deployment_environment: string;
  name: string;
  license_type: string;
  origin: string;
  application: string;
  [key: string]: string;
}

export const ModelFilterStoreDefault = {
  tag_name: '',
  deployment_environment: '',
  name: '',
  license_type: '',
  application: '',
  origin: '',
};

export interface ModelVersionFilterStore {
  stage: string;
  origin: string;
  status: string;
  name: string;
  tag_name: string;
  [key: string]: string;
}

export const ModelVersionFilterStoreDefault = {
  stage: '',
  origin: '',
  status: '',
  name: '',
  tag_name: '',
};

export interface SelectedModelVersionForCard {
  model_id: string;
  model_version_id: string;
}

export const SelectedModelVersionForCardDefaults = {
  model_id: '',
  model_version_id: '',
};
