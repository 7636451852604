import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { IconButton } from '@material-ui/core';

import { Tooltip } from '../../../../common';
import { TestResultsHeader as StyledHeader } from './styles';

type Props = {
  children?: React.ReactNode;
  back: () => void;
};

export function TestResultsHeader({ back, children }: Props) {
  return (
    <StyledHeader>
      <Tooltip title="Navigate back">
        <IconButton onClick={back}>
          <KeyboardArrowLeftIcon />
        </IconButton>
      </Tooltip>

      {children && children}
    </StyledHeader>
  );
}
