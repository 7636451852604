import { Content, Header, Page } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, Card, CardContent } from '@material-ui/core';
import React from 'react';
import { useMutation } from 'react-query';
import { ToolingForm, ToolingFormInput } from '../ToolingForm';
import { Alert } from '@material-ui/lab';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { catalogAdditionalApiRef } from 'plugin-catalog';
import { Tracking } from '../../tracking';
import { EventTracker } from 'plugin-ui-components';
import Container from '@mui/material/Container';
import axios from 'axios';

export const ToolingRegisterPage = () => {
  const configApi = useApi(configApiRef);
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);

  // * Create a mutation to send the tooling object to the API
  const createTool = useMutation({
    mutationFn: async (newTooling: ToolingFormInput) => {
      const formData = new FormData();
      // Clean empty fields to avoid validation error in backend
      for (const field in newTooling) {
        if (newTooling.hasOwnProperty(field)) {
          const value = (newTooling as any)[field];
          // compare value with an empty string
          if (!!value) {
            formData.append(field, value);
          }
        }
      }
      // Create a new tooling entry
      await axios
        .post(`${configApi.getString('backend.baseUrl')}/tooling`, formData, {
          withCredentials: true,
        })
        .catch(() => {
          throw new Error('Failed to create tooling');
        });
      // Request an import of the new tooling entity
      const refreshResp = await catalogAdditionalApi.importEntity(
        stringifyEntityRef({
          kind: 'Tooling',
          namespace: 'default',
          name: newTooling.name,
        }),
      );
      if (refreshResp.error || !refreshResp.added) {
        throw new Error(
          "Tooling was successfully added but the creation in the catalog can take up to 10 minutes to finalize. Then refresh the Toolings page to see if it's available.",
        );
      }
    },
  });

  return (
    <Page themeId="tooling">
      <Header
        title="Create a new Tooling entry"
        subtitle="Fill out this form to add a new entry to the tooling catalog."
      />
      <Content>
        <Container maxWidth="lg">
          <Card>
            <CardContent>
              {createTool.isSuccess && (
                <EventTracker {...Tracking.createTooling()}>
                  <Box marginBottom={2}>
                    <Alert severity="success">
                      Tooling was successfully added but the creation in the
                      catalog can take up to 10 minutes to finalize. Then
                      refresh the Tooling page to see if it's available.
                    </Alert>
                  </Box>
                </EventTracker>
              )}
              {createTool.isError && (
                <Box marginBottom={5}>
                  <Alert severity="error">
                    An error occurred while submitting the form:{' '}
                    {(createTool.error as Error).message}
                  </Alert>
                </Box>
              )}
              <ToolingForm
                mode="create"
                isLoading={createTool.isLoading}
                onSubmit={tooling => createTool.mutate(tooling)}
              />
            </CardContent>
          </Card>
        </Container>
      </Content>
    </Page>
  );
};
