import React from 'react';
import { PipelineItem } from './PipelineItem';
import { useWidgetRepositories } from './useWidgetRepositories';
import { useWidgetPipelines } from './useWidgetPipelines';
import { HomeWidget } from 'plugin-ui-components';
import { WidgetPipeline } from './utils';
import { viewAllPipelines } from './tracking';
import { EmptyState } from './EmptyState';

const defaultRepos: string[] = [];
const defaultValue: WidgetPipeline[] = [];
export const PipelinesWidget = () => {
  const { value: repos = defaultRepos, loading: reposLoading } =
    useWidgetRepositories();
  const [{ value = defaultValue, loading, error }] = useWidgetPipelines(
    repos || [],
  );

  return (
    <HomeWidget
      title="Pipeline Activity"
      value={value}
      render={item => <PipelineItem key={item.id} {...item} />}
      loading={loading || reposLoading}
      error={error}
      errorDescription="Failed to fetch pipelines"
      emptyState={<EmptyState />}
      footerLink={{
        text: 'See all pipelines',
        href: '/cdp',
        tracking: viewAllPipelines,
      }}
      id="pipelines-widget"
    />
  );
};
