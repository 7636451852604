import React from 'react';
import { InfoCard, List, ListItem } from '../../../common';
import { IValidationProblem } from '../../../api/types/payload';
import { ValidationProblem } from './ValidationProblem';
import { IconError, IconWarning } from './common';

type Props = {
  validationProblems: Array<IValidationProblem>;
};

function GetIcon(problem: IValidationProblem) {
  if (problem.severity === 'error') {
    return IconError();
  }
  return IconWarning();
}
export function ValidationProblems({ validationProblems }: Props) {
  return (
    <InfoCard
      title={<span>Validation problems</span>}
      subtitle={<span>Validation problems detected!</span>}
    >
      <List>
        {validationProblems.map(problem => (
          <ListItem icon={GetIcon(problem)}>
            <ValidationProblem validationProblem={problem} />
          </ListItem>
        ))}
      </List>
    </InfoCard>
  );
}
