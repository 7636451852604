import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Navigate, Route, Routes } from 'react-router';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { MissingAnnotationEmptyState } from '@backstage/core-components';
import { gitHubApiRef } from 'plugin-github';
import { cdpApiRef, scalyrApiRef, zesApiRef } from '../api';
import { Entity } from '@backstage/catalog-model';
import { CDP_ANNOTATION } from '../constants';
import {
  runRouteRef,
  catalogRouteRef,
  repositoryRouteRef,
  repositoriesRouteRef,
  organizationRouteRef,
  runShortRouteRef,
} from './routes';
import { MainPage } from '../components/MainPage';
import { ApplicationPage } from '../components/ApplicationPage';
import { RepositoryPage } from '../components/RepositoryPage';
import { RunPage } from '../components/RunPage';
import { OrganizationPage } from '../components/OrganizationPage';
import { PipelineRedirect } from '../components/PipelineRedirect';
import { InsightsPage } from '../components/InsightsPage';
import {
  ServiceProvider,
  NestedRouterServiceProvider,
  getServices,
  getNestedRouterServices,
} from '../services';

export const isPluginApplicableToEntity = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[CDP_ANNOTATION]);

export function CDPNestedRouter() {
  const { entity } = useEntity();
  const cdpApi = useApi(cdpApiRef);

  if (!isPluginApplicableToEntity(entity)) {
    return <MissingAnnotationEmptyState annotation={CDP_ANNOTATION} />;
  }

  const services = getNestedRouterServices(entity, cdpApi);

  return (
    <NestedRouterServiceProvider value={services}>
      <Routes>
        <Route
          path={`/${catalogRouteRef.params[0]}`}
          element={<ApplicationPage />}
        />
      </Routes>
    </NestedRouterServiceProvider>
  );
}

export const CDPStandaloneRouter = () => {
  const services = getServices(
    useApi(cdpApiRef),
    useApi(scalyrApiRef),
    useApi(errorApiRef),
    useApi(gitHubApiRef),
    useApi(zesApiRef),
  );

  return (
    <ServiceProvider value={services}>
      <Routes>
        <Route
          path={`/${runShortRouteRef.params[0]}`}
          element={<PipelineRedirect />}
        />

        <Route
          path={`/${repositoriesRouteRef.params[0]}`}
          element={<MainPage />}
        />

        <Route
          path={`/${organizationRouteRef.params[0]}`}
          element={<OrganizationPage />}
        />

        <Route
          path={`/${repositoryRouteRef.params[0]}`}
          element={<RepositoryPage />}
        />
        <Route
          path="/:domain_alias/:org_name/:repo_name/insights"
          element={<InsightsPage />}
        />

        <Route path={`/${runRouteRef.params[0]}`} element={<RunPage />} />

        <Route
          path="/:domain_alias/:org_name/:repo_name/:runId"
          element={<Navigate replace to="./run" />}
        />

        <Route
          path="/:domain_alias/:org_name/:repo_name/:runId/steps"
          element={<Navigate replace to="./0" />}
        />
      </Routes>
    </ServiceProvider>
  );
};
