const POLL_INTERVAL_IN_SECONDS = 10;

export const createPoller = (status: string, callback: () => any) => {
  const shouldPoll = ['INITIALIZED', 'RUNNING'].includes(status);
  let intervalId: any = null;
  intervalId =
    shouldPoll && callback
      ? setTimeout(() => {
          clearTimeout(intervalId);
          callback();
        }, POLL_INTERVAL_IN_SECONDS * 1000)
      : null;
  return shouldPoll;
};
