import React from 'react';
import { InfoCard, List } from '../../../../../common';
import { Element } from './Element';

import * as S from './styles';

type S3Artifact = {
  name: string;
  path: string;
  bucket: string;
};

export function S3Artifacts({ artifacts }: { artifacts: string[] }) {
  return (
    <InfoCard title="🗃️ S3">
      <List>
        {artifacts.map((record, i) => {
          const { name, path, bucket } = JSON.parse(record) as S3Artifact;

          return (
            <S.Artifact key={i}>
              <Element text={bucket} label="Bucket" />
              <Element text={path} label="Path" />
              <Element text={name} label="Name" />
            </S.Artifact>
          );
        })}
      </List>
    </InfoCard>
  );
}
