import {
  DiscoveryApi,
  createApiRef,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { Database } from '../domain/Databases';
import { http } from './http-client';

export const databasesApiRef = createApiRef<DatabasesApi>({
  id: 'plugin.databases.databases-api',
});

type DatabasesResponse = {
  databases: Database[];
};

export class DatabasesApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getDatabasesByApp(applicationName: string): Promise<Database[]> {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const serviceUrl = `${proxyUrl}/databases/api/application/${applicationName}`;

    return (
      await http<DatabasesResponse>(serviceUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      })
    ).databases;
  }
}
