import { IRunsRuntime } from '../../api/types/payload';

export class RuntimeModel {
  wall: number;
  total: number;
  idle: number;

  constructor(json: IRunsRuntime = {} as IRunsRuntime) {
    this.wall = json.wall ?? 0;
    this.total = json.total ?? 0;
    this.idle = json.idle ?? 0;
  }
}
