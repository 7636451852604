import React, { PropsWithChildren, ReactNode } from 'react';
import { Card, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useStyles } from '../../styles';
import { EventTracker } from 'plugin-ui-components';
import * as Tracking from '../../common/tracking';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface Props extends PropsWithChildren<any> {
  url?: string | null;
  title?: ReactNode;
  icon?: typeof SvgIcon;
  accentColor?: string;
}

function IconElement({
  icon: Icon,
  accentColor,
}: Pick<Props, 'icon' | 'accentColor'>) {
  if (!Icon) return null;
  // It is a rendered React component, return it as is
  if (React.isValidElement(Icon)) return Icon;
  return (
    <Icon
      style={{
        color: accentColor,
        fill: accentColor,
      }}
    />
  );
}

export function SearchResultCard({
  url,
  title,
  icon: Icon,
  accentColor,
  children,
}: Props) {
  const styles = useStyles();
  return (
    <Card className={styles.resultCard}>
      {title && (
        <Typography variant="h4" className="title">
          <div className="icon-holder">
            <IconElement icon={Icon} accentColor={accentColor} />
          </div>
          {url ? (
            <EventTracker {...Tracking.resultClick(url)}>
              <Link to={url}>
                {title}
                {url?.startsWith('http') && (
                  <OpenInNewIcon className="external-link-icon" />
                )}
              </Link>
            </EventTracker>
          ) : (
            <span className="no-link">{title}</span>
          )}
        </Typography>
      )}
      {children}
    </Card>
  );
}
