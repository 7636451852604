import {
  Theme,
  styled,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '400px',
}));

export const DialogContent = styled(MuiDialogContent)(
  (props: { theme: Theme }) => ({
    '& >:not(:last-child)': {
      marginBottom: props.theme.spacing(2),
    },
  }),
);

export const ErrorWrapper = styled('div')((props: { theme: Theme }) => ({
  borderBottom: `2px solid ${props.theme.palette.divider}`,
  marginBottom: props.theme.spacing(1),
  '& svg': {
    marginRight: props.theme.spacing(1),
  },
}));

export const ErrorDescription = styled('p')((props: { theme: Theme }) => ({
  paddingLeft: props.theme.spacing(4),
}));
