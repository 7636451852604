import React, { PropsWithChildren } from 'react';
import { matchRoutes, RouteObject, useLocation } from 'react-router';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useDebounce } from 'react-use';
import { SEARCH_DEBOUNCE_TIME, ZalandoIdentityApiRef } from 'plugin-core';
import { PluginTracking } from 'plugin-ui-components';
import { v5 as uuidv5 } from 'uuid';
import { trackedRoutes, TrackedRouteType } from '../routes';

async function initializeGoogleAnalytics(
  userApi: Sunrise.ZalandoIdentityApi,
  trackingId: string,
  namespaceUUID: string,
  debug: boolean = false,
) {
  const uuid = uuidv5(await userApi.getLogin(), namespaceUUID);
  const jobTitle = await userApi.getJobTitle();
  const department = await userApi.getDepartment();

  PluginTracking.initialize(trackingId, debug, uuid, { department, jobTitle });
}

interface WithAnalyticsProps {
  trackingId?: string;
}

export function WithAnalytics(props: PropsWithChildren<WithAnalyticsProps>) {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const location = useLocation();
  const configApi = useApi(configApiRef);
  const userApi = useApi(ZalandoIdentityApiRef);

  const trackingId = configApi.getOptionalString(
    'features.googleAnalytics.trackingId',
  );
  const debug = configApi.getOptionalBoolean('features.googleAnalytics.debug');
  const namespaceUUID = configApi.getOptionalString(
    'features.googleAnalytics.namespaceUUID',
  );

  React.useEffect(() => {
    if (trackingId && namespaceUUID) {
      if (!initialized) {
        initializeGoogleAnalytics(
          userApi,
          trackingId,
          namespaceUUID,
          debug,
        ).then(() => setInitialized(true));
      }
    }
  }, [initialized, trackingId, userApi, namespaceUUID, debug]);

  useDebounce(
    () => {
      if (trackingId && initialized) {
        const routes: RouteObject[] = trackedRoutes.map(
          ({ renderChildren: _, ...item }) => item,
        ) as RouteObject[];

        const matchingRoutes = matchRoutes(routes, location.pathname);
        if (matchingRoutes) {
          const currentRoute = matchingRoutes[0].route as TrackedRouteType;
          PluginTracking.setPlugin(currentRoute.plugin);
        }
      }
    },
    SEARCH_DEBOUNCE_TIME,
    [location.pathname + location.search, initialized],
  );

  return <>{props.children}</>;
}
