import React from 'react';
import { HeaderLabel } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { getLifecycleLabelByKind } from './utils';
import { LastSync, TeamReference } from 'plugin-ui-components';

export const Labels = () => {
  const { entity } = useEntity<IEntityApp>();
  const { spec = {} as any, kind } = entity;
  const isGroupEntity = entity?.kind?.toLowerCase() === 'group';
  const isToolingEntity = entity?.kind?.toLowerCase() === 'tooling';
  const lifecycleLabel = getLifecycleLabelByKind(kind);
  const isActive = spec?.spec?.active;
  const teamId = spec.spec?.team_id || spec.sapId || spec.owner;
  const alias = isGroupEntity && spec?.alias.join(', ');
  const lastSyncDate = entity.metadata.modifiedAt
    ? new Date(entity.metadata.modifiedAt).toISODateString()
    : undefined;
  return (
    <>
      {spec?.type === 'service' && (
        <HeaderLabel
          label="Status"
          value={
            <>
              <span>{isActive ? 'Active' : 'Inactive'}</span>
            </>
          }
        />
      )}
      {isToolingEntity && spec?.status && (
        <HeaderLabel
          label="Status"
          value={
            <>
              <span>{spec.status}</span>
            </>
          }
        />
      )}
      {teamId && !isGroupEntity && (
        <HeaderLabel
          label="Owner"
          value={
            <TeamReference team={teamId} format="nameOnly" color="inherit" />
          }
        />
      )}
      {isGroupEntity && <HeaderLabel label="Alias(es)" value={alias} />}
      {spec?.lifecycle && (
        <HeaderLabel label={lifecycleLabel} value={spec.lifecycle} />
      )}
      {lastSyncDate && <LastSync lastSyncDate={lastSyncDate} />}
    </>
  );
};
