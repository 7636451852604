import { IJUnitTest, IJUnitTestResult } from '../../../../../api/types/payload';
import { STATUS_COLORS } from '../../../../../utils/colors';
import { getDuration } from '../../../../../utils/time';
import { JUNIT_TEST_STATUS as STATUS } from '../../../../../constants';

export function sortByErrors(a: IJUnitTestResult, b: IJUnitTestResult) {
  if (a.totals.error > b.totals.error || a.totals.failed > b.totals.failed) {
    return -1;
  }
  return 1;
}

export function sortByOrder(
  array: IJUnitTest[],
  sortOrder: string[] = [
    STATUS.failed,
    STATUS.error,
    STATUS.skipped,
    STATUS.passed,
  ],
) {
  const arr = [...array];

  const ordering = sortOrder.reduce((acc, curr, i) => {
    acc[curr] = i;
    return acc;
  }, {} as Record<string, number>);

  return arr.sort((a, b) => ordering[a.status] - ordering[b.status]);
}

export function sortTestResults(
  results: IJUnitTestResult[],
): IJUnitTestResult[] {
  let tests = [...results].sort(sortByErrors);

  tests = tests.map(testsuites => {
    if (!testsuites.tests || !Array.isArray(testsuites.tests)) {
      return { ...testsuites, tests: [] };
    }

    return { ...testsuites, tests: sortByOrder(testsuites.tests) };
  });

  return tests;
}

export function getColorByStatus(status: string) {
  switch (status) {
    case STATUS.passed:
      return STATUS_COLORS.success;
    case STATUS.failed:
    case STATUS.error:
      return STATUS_COLORS.failed;
    case STATUS.skipped:
      return STATUS_COLORS.skipped;
    default:
      return STATUS_COLORS.info;
  }
}

export type Summary = {
  statuses: Record<string, number>;
  total: number;
  duration: number;
  length: number;
};

export function getSummary(results: IJUnitTestResult[]): Summary {
  let passed = 0;
  let skipped = 0;
  let failed = 0;
  let error = 0;
  let total = 0;
  let duration = 0;

  results.forEach(item => {
    passed += item.totals.passed;
    skipped += item.totals.skipped;
    failed += item.totals.failed;
    error += item.totals.error;
    total += item.totals.tests;
    duration += item.totals.duration;
  });

  return {
    statuses: {
      passed,
      skipped,
      failed,
      error,
    },
    total,
    duration,
    length: results.length,
  };
}

export function generateKey(pre: string) {
  return `${pre}_${new Date().getTime()}`;
}

export function parseNanoTime(nanoseconds: number): string {
  return getDuration(Math.round(nanoseconds / 1000000));
}
