import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import List from '@material-ui/core/List';
import { Progress } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import { IncidentsTable } from './IncidentsTable';
import { opsgenieApiRef } from '../../api';
import { ContentWrapper, useUserTeams } from 'plugin-ui-components';
import SettingsIcon from '@material-ui/icons/Settings';
import * as S from '../OnCallPage/styles';
import { Incident } from '../../types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { InfoPanel } from '../InfoPanel';

dayjs.extend(localizedFormat);

export const IncidentsList = () => {
  const [view, setView] = useState('mine');
  const [userTeamIds, setUserTeamIds] = React.useState<string[] | undefined>(
    undefined,
  );
  const [opsgenieData, setOpsgenieData] = React.useState<
    [Incident[], Incident[]] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [returnedError, setReturnedError] = React.useState<Error | undefined>(
    undefined,
  );
  const opsgenieApi = useApi(opsgenieApiRef);

  const fromMy = dayjs().subtract(1, 'year').startOf('quarter');
  const fromAll = dayjs().subtract(6, 'months').startOf('quarter');
  const to = dayjs();

  const {
    value: { userTeams },
    loading,
  } = useUserTeams();

  React.useEffect(() => {
    if (loading) return;
    if (userTeams?.length) {
      setUserTeamIds(
        userTeams.map((item: { spec: { id: any } }) => item.spec.id),
      );
    }
  }, [loading, userTeams]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const getOpsgenieData = useCallback(async () => {
    const myIncidents = await opsgenieApi.getIncidents({
      limit: 100,
      query: `createdAt < ${to.valueOf()} AND createdAt > ${fromMy.valueOf()}`,
      teams: userTeamIds,
    });
    const allIncidents = await opsgenieApi.getIncidents({
      limit: 100,
      query: `createdAt < ${to.valueOf()} AND createdAt > ${fromAll.valueOf()}`,
    });
    setOpsgenieData([
      myIncidents.filter(incident => !incident.tags.includes('test')),
      allIncidents.filter(incident => !incident.tags.includes('test')),
    ]);
    setIsLoading(false);
  }, [userTeamIds]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!userTeamIds) return;
    getOpsgenieData().catch(err => {
      setReturnedError(err);
      setIsLoading(false);
    });
  }, [userTeamIds]);

  if (loading || isLoading) {
    return <Progress />;
  } else if (returnedError) {
    return <Alert severity="error">{returnedError.message}</Alert>;
  }

  const handleChange = (v: string) => {
    setView(v);
  };

  const incidents = view === 'mine' ? opsgenieData![0] : opsgenieData![1];
  const from = view === 'mine' ? fromMy : fromAll;
  const title =
    view === 'mine'
      ? 'This Table cover one year worth of incidents, from the current quarter to the same quarter last year.'
      : 'This Table cover 6 months worth of incidents, from the current quarter to the same quarter 6 months ago.';

  return (
    <ContentWrapper>
      {' '}
      <div>
        <Grid>
          <S.Wrapper id="category-filter">
            <React.Fragment key="Personal">
              <S.Title variant="subtitle2">Personal</S.Title>
              <S.GroupWrapper>
                <List disablePadding dense>
                  <S.MenuItem
                    key="myIncidents"
                    button
                    divider
                    onClick={() => handleChange('mine')}
                    selected={view === 'mine'}
                    disableRipple
                  >
                    <S.ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </S.ListItemIcon>
                    <ListItemText>
                      <S.MenuTitle>For my applications</S.MenuTitle>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {opsgenieData![0].length}
                    </ListItemSecondaryAction>
                  </S.MenuItem>
                </List>
              </S.GroupWrapper>
            </React.Fragment>
            <React.Fragment key="Zalando">
              <S.Title variant="subtitle2">Zalando</S.Title>
              <S.GroupWrapper>
                <List disablePadding dense>
                  <S.MenuItem
                    key="all"
                    button
                    divider
                    onClick={() => handleChange('all')}
                    selected={view === 'all'}
                    disableRipple
                  >
                    <ListItemText>
                      <S.MenuTitle>For All applications</S.MenuTitle>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {opsgenieData![1].length}
                    </ListItemSecondaryAction>
                  </S.MenuItem>
                </List>
              </S.GroupWrapper>
            </React.Fragment>
          </S.Wrapper>
        </Grid>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InfoPanel
            title={title}
            message={
              <ul>
                <li>Incidents from {from.format('LL')} to now are used</li>
              </ul>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentsTable incidents={incidents as Incident[]} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
