import React, { useContext } from 'react';
import { Chip, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useCategoryKeys } from '../../hooks';
import { ReviewSummaryContext } from '../context';
import * as S from '../styles';

interface Props {
  category: IAppReview.ReviewCategory;
}

export function SummaryCard({
  children,
  category,
}: React.PropsWithChildren<Props>) {
  const { review } = useContext(ReviewSummaryContext);
  const { okKey, commentsKey } = useCategoryKeys(category);

  return (
    <S.StyledCard>
      <S.StyledCardHeader
        title={
          <div>
            <span>{category}</span>
            {review[okKey] === 'n/a' && review[commentsKey] && (
              <Chip
                size="small"
                style={{ margin: '0 0.5rem' }}
                label="Not applicable"
              />
            )}
          </div>
        }
        titleTypographyProps={{ component: 'h2' } as any}
      />
      <Divider />
      {commentsKey && review[commentsKey] ? (
        <Alert severity="info" style={{ margin: 0, borderRadius: 0 }}>
          <b>Rationale provided:</b> {review[commentsKey]}
        </Alert>
      ) : (
        children
      )}
    </S.StyledCard>
  );
}
