import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNestedRouterServices } from '../../../../services';
import {
  ActionPanelLayout,
  LiveAction,
  UserPreferencesAction,
} from '../../../common';

import * as S from './styles';

export const ActionPanel = observer(() => {
  const { runGroupsService, preferencesService } = useNestedRouterServices();

  return (
    <ActionPanelLayout>
      <S.Actions>
        <LiveAction service={runGroupsService} />
        <UserPreferencesAction service={preferencesService} />
      </S.Actions>
    </ActionPanelLayout>
  );
});
