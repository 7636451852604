import { TableColumn } from '@backstage/core-components';
import React from 'react';
import { UserTeam } from '../../../api/definitions';
import { v4 as uuidv4 } from 'uuid';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { PLACEHOLDER_USER_TEAM } from '../../../api/PlaceholderResponses';

export const getProjectListColumns = (
  teamsWithProjects: any,
  selectedTeam: any | undefined,
  setSelectedTeam: React.Dispatch<any | undefined>,
  classes: any,
  setPage: any,
): TableColumn[] => {
  const styles: { width: string; backgroundColor?: string } = {
    width: '350px',
  };

  function getAllTeamNickNames() {
    const sortedTeamsWithProject = teamsWithProjects
      ?.map((currentTeam: UserTeam) => {
        return currentTeam.nickname;
      })
      .sort();
    sortedTeamsWithProject?.unshift(PLACEHOLDER_USER_TEAM.nickname);
    return sortedTeamsWithProject || [PLACEHOLDER_USER_TEAM.nickname];
  }

  function getSelectedTeamNickName(selectedTeamID: string) {
    if (selectedTeamID === PLACEHOLDER_USER_TEAM.team_id) {
      return PLACEHOLDER_USER_TEAM.nickname;
    }
    return teamsWithProjects?.find((currentTeam: UserTeam) => {
      return currentTeam.team_id === selectedTeamID;
    })?.nickname;
  }

  function setTeamID(selected: any) {
    if (selected === PLACEHOLDER_USER_TEAM.nickname) {
      return setSelectedTeam(PLACEHOLDER_USER_TEAM.team_id);
    }
    const selectedTeamID = teamsWithProjects?.find(
      (userSelectedTeam: UserTeam) => {
        return userSelectedTeam.nickname === selected;
      },
    )?.team_id;
    setPage(0);
    return setSelectedTeam(selectedTeamID);
  }

  return [
    {
      title: 'Team',
      field: 'team.nickname',
      filterComponent: _ => {
        return (
          <Autocomplete
            key={uuidv4()}
            disableClearable
            onChange={(__, selected) => setTeamID(selected)}
            style={styles}
            classes={classes}
            renderInput={params => (
              <TextField
                {...params}
                label={getSelectedTeamNickName(selectedTeam) || 'Select Team'}
                variant="outlined"
                margin="dense"
                data-testid="projects-autocomplete-text-input"
              />
            )}
            data-testid="projects-autocomplete-select"
            options={getAllTeamNickNames()}
          />
        );
      },
    },
    {
      title: 'Project ID',
      field: 'id',
      filtering: false,
    },
    {
      title: 'Project Name',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Description',
      field: 'description',
      filtering: false,
    },
  ];
};
