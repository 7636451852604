import React from 'react';
import { InfoTooltip } from 'plugin-ui-components';
import { Issue } from '../../common/Issue';
import { FieldProps } from '../types';

export function Name({ value, issue }: FieldProps<'name'>) {
  return (
    <>
      <dt>Name</dt>
      <dd>
        {value}
        <InfoTooltip>The human-readable name of the application.</InfoTooltip>
        <Issue {...issue} />
      </dd>
    </>
  );
}
