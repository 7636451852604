import React from 'react';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Toolbar,
  Grid,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';
import { pipelineApiRef } from '../../api';
import { Layout } from '../../common';
import { getDomainFromAlias } from '../../utils/domains';
import { useStyles } from './styles';
import {
  PerformanceOverTime,
  PerformanceStatusOverTime,
  PipelineExecutionTime,
  PipelineStatus,
  FailureRate,
  StepsFailRanking,
} from './widgets';

const eventTypeOptions: Array<{
  event: ICDPMonitoring.EventType;
  label: string;
}> = [
  { event: '', label: 'All' },
  { event: 'push', label: 'Push' },
  { event: 'pull_request', label: 'Pull Request' },
  { event: 'schedule', label: 'Schedule' },
];

function get30DaysFromNow(): string {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date.toISODateString('short');
}

function getNow(): string {
  return new Date().toISODateString('short');
}

export const InsightsPage = () => {
  const errorApi = useApi(errorApiRef);
  const pipelineMonitoringClient = useApi(pipelineApiRef);
  const { domain_alias = '', org_name, repo_name } = useParams();
  const [event, setEvent] = React.useState<ICDPMonitoring.EventType>('');
  const [step, setStep] = React.useState<string>('');
  const [dateFrom, setDateFrom] = React.useState<string>(get30DaysFromNow());
  const [dateTo, setDateTo] = React.useState<string>(getNow());
  const styles = useStyles();

  const {
    value: data,
    error,
    loading,
  } = useAsync(() => {
    const url = new URL(
      `${getDomainFromAlias(domain_alias)}/${org_name}/${repo_name}/stats`,
      new URL(document.baseURI).origin,
    );
    const search = new URLSearchParams();
    if (event) search.set('event', event);
    if (dateFrom) search.set('from', dateFrom);
    if (dateTo) search.set('to', dateTo);
    url.search = search.toString();

    return pipelineMonitoringClient.getPipelineStats(url.pathname + url.search);
  }, [event, dateFrom, dateTo]);

  React.useEffect(() => {
    if (error) errorApi.post(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const {
    value: performanceData,
    error: performanceError,
    loading: performanceLoading,
  } = useAsync(() => {
    const url = new URL(
      `${getDomainFromAlias(domain_alias)}/${org_name}/${repo_name}/timeseries`,
      new URL(document.baseURI).origin,
    );
    const search = new URLSearchParams();
    if (event) search.set('event', event);
    if (dateFrom) search.set('from', dateFrom);
    if (dateTo) search.set('to', dateTo);
    url.search = search.toString();

    return pipelineMonitoringClient.getPipelineTimeseries(
      url.pathname + url.search,
    );
  }, [event, dateFrom, dateTo]);

  React.useEffect(() => {
    if (performanceError) errorApi.post(performanceError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceError]);

  const steps = data?.step_info ? data.step_info.map(s => s.step_name) : [];
  const stepInfo: ICDPMonitoring.StepInfo | undefined = data?.step_info?.find(
    s => s.step_name === step,
  );

  return (
    <Layout
      render={
        <>
          <Container maxWidth="lg">
            <Toolbar className={styles.toolbar}>
              <FormControl
                variant="outlined"
                size="small"
                className={styles.select}
              >
                <InputLabel htmlFor="filter-event-type" shrink>
                  Event type
                </InputLabel>
                <Select
                  native
                  value={event}
                  onChange={ev =>
                    setEvent(ev.target.value as ICDPMonitoring.EventType)
                  }
                  variant="outlined"
                  margin="dense"
                  label="Event type"
                  inputProps={{ id: 'filter-event-type' }}
                  placeholder="All"
                >
                  {eventTypeOptions.map((item, i) => (
                    <option key={i} value={item.event}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="date"
                value={dateFrom}
                onChange={ev => setDateFrom(ev.target.value)}
                label="From"
                variant="outlined"
                size="small"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                className={styles.dateInput}
              />

              <TextField
                type="date"
                value={dateTo}
                onChange={ev => setDateTo(ev.target.value)}
                label="To"
                variant="outlined"
                size="small"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                className={styles.dateInput}
              />

              <FormControl
                variant="outlined"
                size="small"
                className={styles.select}
              >
                <InputLabel htmlFor="filter-step" shrink>
                  Step
                </InputLabel>
                <Select
                  native
                  value={step}
                  onChange={ev => setStep(ev.target.value as string)}
                  label="Step"
                  variant="outlined"
                  margin="dense"
                  inputProps={{ id: 'filter-step' }}
                  placeholder="All"
                >
                  {!!steps.length && (
                    <option key="all" value="">
                      All
                    </option>
                  )}
                  {steps.map((s, i) => (
                    <option key={i} value={s}>
                      {s}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Toolbar>

            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <PipelineStatus
                      title={`${step ? 'Step' : 'Pipeline'} statuses`}
                      data={stepInfo || data}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item md={8} xs={12} style={{ display: 'flex' }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <PipelineExecutionTime
                          data={stepInfo || data}
                          loading={loading}
                          title={`${step ? 'Step' : 'Pipeline'} execution time`}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <FailureRate
                          data={stepInfo || data}
                          loading={loading}
                          title={`${step ? 'Step' : 'Pipeline'} failure rate`}
                        />
                      </Grid>
                      <Grid item lg={8} md={6} xs={12}>
                        <StepsFailRanking data={data} loading={loading} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PerformanceOverTime
                  loading={performanceLoading}
                  data={performanceData}
                />
              </Grid>

              <Grid item xs={12}>
                <PerformanceStatusOverTime
                  loading={performanceLoading}
                  data={performanceData}
                />
              </Grid>
            </Grid>
          </Container>
        </>
      }
    />
  );
};
