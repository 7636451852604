import React, { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { PathMatch } from 'react-router';
import { Link } from '@backstage/core-components';
import * as S from './styles';

export interface PageEntities {
  modelGroupName?: string;
  modelName?: string;
}

const paths = [
  '/ml',
  '/ml/projects',
  '/ml/projects/:project_id',
  '/ml/projects/:project_id/:group_type',
  '/ml/projects/:project_id/:group_type/:group_id',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id/params-metrics',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id/architecture-and-data',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id/performance-metrics',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id/use-cases-and-considerations',
  '/ml/projects/:project_id/:group_type/:group_id/:item_type/:item_id/graphics',
];

const typeIdToTitle = (id: string) => {
  return id
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
};

const formatId = (id: string) => {
  return id.split(':').pop() || '';
};

const formatModelGroupName = (name: string) => {
  if (name.startsWith('arn:')) {
    return formatId(name).split('/').pop() || name;
  }
  return name;
};

const createItems = (pm: PathMatch, pageEntities?: PageEntities) => {
  const { project_id, group_type, group_id, item_type, item_id } = pm.params;

  const items: Array<React.ReactNode> = [];
  const addItem = (path: string, text: string) => {
    items.push(
      <Link to={path} key={path} title={text}>
        {text}
      </Link>,
    );
  };

  let path = '/ml/projects';
  let text: string | undefined = 'Projects';
  addItem(path, text);

  if (project_id) {
    path = `/ml/projects/${project_id}`;
    text = project_id;
    addItem(path, text);
  }
  if (group_type) {
    path = `/ml/projects/${project_id}/${group_type}`;
    text = typeIdToTitle(group_type);
    addItem(path, text);
  }
  if (group_id) {
    path = `/ml/projects/${project_id}/${group_type}/${group_id}`;
    if (pageEntities?.modelGroupName) {
      text = formatModelGroupName(pageEntities.modelGroupName);
    } else {
      text = formatId(group_id);
    }
    addItem(path, text);
  }
  if (item_type) {
    path = `/ml/projects/${project_id}/${group_type}/${group_id}/${item_type}`;
    text = typeIdToTitle(item_type);
    addItem(path, text);
  }
  if (item_id) {
    path = `/ml/projects/${project_id}/${group_type}/${group_id}/${item_type}/${item_id}`;
    if (pageEntities?.modelName) {
      text = pageEntities.modelName;
    } else {
      text = formatId(item_id);
    }
    addItem(path, text);
  }
  return items;
};

export const MLBreadcrumbs = ({
  pathname,
  pageEntities,
}: {
  pathname: string;
  pageEntities?: PageEntities;
}) => {
  const pathMatch = useMemo(() => {
    return paths.reduce((pathObject: PathMatch | null, current: string) => {
      const match = matchPath(current, pathname);
      return match || pathObject;
    }, null);
  }, [pathname]);

  if (!pathMatch) {
    return null;
  }

  const items = createItems(pathMatch, pageEntities);
  return <S.Breadcrumbs>{items}</S.Breadcrumbs>;
};
