import { useApi } from '@backstage/core-plugin-api';
import React, { useState } from 'react';
import { dashboardsApiRef } from '../../api';
import { ListEntities } from '../../components';
import { NotebooksProps } from '../NotebooksProps';
import { SubvalueCell } from '@backstage/core-components';
import { TagGroup, UserTag } from '../../components/Tags';

export const Dashboards = (props: NotebooksProps) => {
  const { projectId } = props;
  const [page, setPage] = useState(0);
  const [error, setError] = useState<Error>();

  const COLUMNS = [
    {
      title: 'R Shiny Apps',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
  ];

  return (
    <ListEntities
      getEntitiesPromise={useApi(dashboardsApiRef).getDashboards(
        projectId,
        page,
      )}
      entityKey="dashboards"
      columns={COLUMNS}
      childEntityKey="versions"
      page={page}
      onPageChange={setPage}
      error={error}
      setError={setError}
    />
  );
};
