import { styled } from '@material-ui/core';

export const IframeContainer = styled('div')(props => ({
  position: 'relative',
  backgroundColor: '#fff',
  flex: 1,

  [props.theme.breakpoints.down('sm')]: {
    height: '100vh',
  },
}));

export const Actions = styled('div')(props => ({
  position: 'absolute',
  top: `calc(${props.theme.spacing(3)}px + 30px)`,
  right: props.theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',

  ['& button, & a']: {
    marginBottom: props.theme.spacing(1),
  },

  [`& button, & button:hover, & button:active,
     & a, & a:hover, & a:active`]: {
    backgroundColor: props.theme.palette.divider,
  },
}));

export const Iframe = styled('iframe')({
  border: 'none',
  position: 'absolute',
  inset: 0,
});
