import { useEffect, useState } from 'react';
import { ApiRef, useApi } from '@backstage/core-plugin-api';

export type Status = {
  status: {
    isError: boolean;
    error?: string;
  };
};

export type ApiResponse<J> = {
  payLoad: J | undefined;
  isLoading: boolean;
} & Status;

export function useApiCall<T, J>(
  apiRef: ApiRef<T>,
  callback: (api: T) => Promise<J>,
): ApiResponse<J> {
  const apiInstance = useApi(apiRef);
  const [payLoad, setPayload] = useState<J>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    callback(apiInstance)
      .then(setPayload)
      .catch((reason: string) => {
        setError(reason.toString());
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    payLoad,
    isLoading,
    status: {
      isError,
      error,
    },
  };
}
